import { Subscription } from 'rxjs';
import { CommonStoreManager } from './../../../common/store/common.store-manager';
import { OCL_EVENT_MODAL_TOKEN } from './../ocl-event-modal/ocl-event-modal-tokens';
import { OclNotificationsService } from 'src/app/ocl/services/ocl-notifications-service/ocl-notifications.service';
import { HolContext } from './../../../common/models/hol-context.model';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { TranslatePipe } from 'src/app/common/pipes/translate/translate.pipe';
import * as moment from 'moment';
import { OclEvent } from '../../models/ocl-event.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { OCL_DECISIONS_MODAL_TOKEN } from '../ocl-decisions-modal/ocl-decisions-modal.token';
import { OclLinkedItemsStoreManager } from '../../store/linked-items/ocl-linked-items-store-manager.service';
import { OclLogbookService } from '../../services/ocl-logbook-service/ocl-logbook.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclLinksService } from '../../services/ocl-links-service/ocl-links.service';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { cloneDeep, difference, intersectionWith } from 'lodash';
import { HolObject } from '../../../common/models/hol-object';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclLogbookDecisionService } from '../../services/ocl-logbook-decision-service/ocl-logbook-decision.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';

@Component({
  selector: 'app-ocl-logbook-modal',
  templateUrl: './ocl-logbook-modal.component.html',
  styleUrls: ['./ocl-logbook-modal.component.scss'],
})
export class OclLogbookModalComponent implements OnInit, OnDestroy {
  public logbook: OclLogbook = new OclLogbook();

  public isCreate: boolean;
  public isReadOnly: boolean;
  public events: OclEvent[] = null;
  public selectedItem = undefined;
  public selectedTags = [];
  public module: string;
  public action: string;
  public isUtc: boolean;
  public loading = false;
  public loadingFlight = false;
  public selectedFlight = undefined;
  public searchText: string;
  public searchData = {
    date: undefined,
    flights: [],
    flightsFiltered: [],
    flightNumber: null,
  };
  public autocompletePlaceHolder: string;
  public decisionItems: any[] = [];
  public linkedDecisions: string[];
  public linkedDecisionsDisplay: any[] = [];
  public linkedDecisionsCopy: string[] = [];
  public linkedEvents: string[];
  public linkedEventsDisplay: any[] = [];
  public logbookGroup: OclLogBookGroup[] = [];
  public linkedGroup: string[] = [];
  public linkedEventsCopy: string[] = [];
  public linkedEventsArchived = [];
  public linkedDecisionsArchived = [];
  public notifications: HolNotification[];
  public isFromDecision = false;
  public disabledLinks = {};
  modeLinkIsActivate;
  public airportsCode: Array<string>;
  public applicabilityIsReadOnly: boolean;
  public context: HolContext;

  public duplicateToOtherModule = false;
  public oldDuplicateToOtherModuleValue = null;
  public newDuplicateToOtherModuleValue = null;
  public updateDuplicateToOtherModuleFlightValue = false;

  public isHistory;
  public fromGOC = false;
  public fromCREW = false;
  public fromERP = false;
  public changeToDecision = false;
  public canUpdateFromOtherModule = false;
  public hasCrisisInProgress = false;
  public toERP = false;
  public hasCrisisInProgressSub: Subscription;
  public hasErpAccess: boolean;

  public isInSummary = false;
  public isInBriefing = false;

  constructor(
    protected dialog: MatDialog,
    protected translatePipe: TranslatePipe,
    public moduleConfig: ModuleConfigService,
    public flightService: FltFlightService,
    public logbookService: OclLogbookService,
    protected notificationsService: OclNotificationsService,
    protected dialogRef: MatDialogRef<OclLogbookModalComponent>,
    protected occLinkItemsStoreManager: OclLinkedItemsStoreManager,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(OCL_DECISIONS_MODAL_TOKEN) protected occDecisionsModal,
    protected linksService: OclLinksService,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
    protected commonStoreManager: CommonStoreManager,
    @Inject('$rootScope') public $rootScope,
    protected groupStoreManager: OclGroupsStoreManager,
    protected groupService: OclGroupService,
    protected oclLogbookDecisionService: OclLogbookDecisionService,
    protected oclOptionsService: OclOptionsService,
  ) {
    this.notificationsService.getAll().then(notif => (this.notifications = notif));
    this.hasErpAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
  }

  get limitTo(): HolObject | undefined {
    return undefined;
  }

  ngOnInit(): void {
    this.module = this.translatePipe.transform('PDF_TITLE.OCC');
    this.action = this.translatePipe.transform('PDF_TITLE.LOGBOOK');
    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE',
    );

    this.isUtc = this.data.isUtc;
    this.modeLinkIsActivate = this.data.modeLinkIsActivate;

    this.events = cloneDeep(this.data.events) || [];
    this.decisionItems = cloneDeep(this.data.decisionItems) || [];

    this.airportsCode = this.data.airportsCode;

    this.isHistory = this.data.isHistory ? this.data.isHistory : false;

    this.hasCrisisInProgressSub = this.commonStoreManager.crisis.subscribe(crisis => {
      if (crisis) this.hasCrisisInProgress = crisis.inProgress;
    });

    if (this.data.logbook) {
      this.initLinks();

      this.isReadOnly =
        this.data.isReadOnly ||
        this.logbook.readOnly ||
        this.data.logbook.fromGOC ||
        this.data.logbook.fromCREW ||
        this.data.logbook.fromERP ||
        this.isHistory;
      this.isCreate = false;
      this.applicabilityIsReadOnly = true;

      this.logbook = this.data.logbook;

      this.isInSummary =
        this.data.logbook &&
        this.data.logbook.summary &&
        this.data.logbook.summary.logBook &&
        this.data.logbook.summary.logBook.objectId !== undefined;
      if (this.isInSummary) {
        this.isInBriefing = this.data.logbook.summary.isDisplayInBriefing;
      }

      if (this.logbook.flight && this.logbook.flight.flightNumber) {
        this.selectedFlight = this.logbook.flight;
        this.initSearchDataFlight();
      }
    } else {
      this.isCreate = true;
      this.applicabilityIsReadOnly = false;
      this.linkedEvents = [];
      this.linkedDecisions = [];
      this.logbook.text = '';
      this.logbook.attachments = {};
      this.logbook.tags = [];
      this.logbook.contentText = '';

      if (this.data.fromDecision) {
        this.isFromDecision = true;
        this.logbook.contentText = this.data.fromDecision.message;
        this.logbook.tags = cloneDeep(this.data.fromDecision.tags);
        this.logbook.attachments = cloneDeep(this.data.fromDecision.attachments);
        this.logbook.acl = this.data.fromDecision.acl;
        if (this.data.fromDecision.flight && this.data.fromDecision.flight.flightNumber) {
          this.selectedFlight = this.data.fromDecision.flight;
          this.initSearchDataFlight();
        }
      }
    }

    if (this.logbook.fromGOC) {
      this.fromGOC = this.logbook.fromGOC ? true : false;
    }

    if (this.logbook.fromCREW) {
      this.fromCREW = this.logbook.fromCREW ? true : false;
    }

    if (this.logbook.fromERP) {
      this.fromERP = this.logbook.fromERP ? true : false;
    }

    if (this.moduleConfig.config.displayToERP) {
      this.toERP = this.logbook.toERP ? true : false;
    }

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'LOGBOOK',
      htmlTitle: this.logbook.contentText,
      htmlDate: this.logbook.createdAt,
      htmlTemplate: 'A',
    };

    this.canUpdateFromOtherModule = !this.isHistory && !this.logbook.readOnly ? this.logbook.fromGOC || this.logbook.fromCREW : false;

    // Groups
    this.groupStoreManager.groupsState.subscribe(g => {
      this.logbookGroup = g.logbooks;
    });
  }

  public initSearchDataFlight(): void {
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
    this.searchData.date = moment.utc(this.selectedFlight.sta).toDate();
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD',
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
    });
  }

  public initLinks(): void {
    this.linksService.getLinkLogBookEventById(this.data.logbook.objectId).then(evts => {
      this.linkedEvents = [];
      if (!evts) {
        return;
      }
      evts.forEach(evt => {
        if (this.events.findIndex(el => el.objectId === evt.objectId) === -1) {
          this.disabledLinks[evt.objectId] = true;
          this.linkedEventsArchived.push(evt);
        } else {
          this.linkedEvents.push(evt.objectId);
          this.linkedEventsCopy.push(evt.objectId);
        }

        this.linkedEventsDisplay.push(evt);
      });
      // tslint:disable-next-line: max-line-length
      if (
        this.data.logbook.event &&
        this.data.logbook.event.objectId &&
        evts.findIndex(el => el === this.data.logbook.event.objectId) === -1
      ) {
        this.linkedEvents.push(this.data.logbook.event.objectId);
        this.linkedEventsCopy.push(this.data.logbook.event.objectId);
        this.linkedEventsDisplay.push(this.data.logbook.event);
      }
    });
    this.linksService.getLinkDecisionForLogBookById(this.data.logbook.objectId).then(dcns => {
      if (!dcns) {
        this.linkedDecisions = [];
        return;
      }
      this.linkedDecisions = [];
      dcns.forEach(dcn => {
        if (this.decisionItems.findIndex(el => el.objectId === dcn.objectId) === -1) {
          this.disabledLinks[dcn.objectId] = true;
          this.linkedDecisionsArchived.push(dcn);
        } else {
          this.linkedDecisions.push(dcn.objectId);
          this.linkedDecisionsCopy.push(dcn.objectId);
        }
        this.linkedDecisionsDisplay.push(dcn);
      });
    });
  }

  public changeDate(event): void {
    this.searchData.date = new Date(event);
    if (!this.searchData.date) {
      this.selectedFlight = undefined;
      this.searchText = undefined;
      this.selectedFlight = undefined;
      this.searchData.flights = [];
      this.autocompletePlaceHolder = this.translatePipe.transform(
        this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE',
      );
      return;
    }
    this.searchText = undefined;
    this.selectedFlight = undefined;
    this.searchData.flights = [];
    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_FLIGHT_LOADING',
    );
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD',
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
      if (!res.length) {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_NO_FLIGHT_FOUND',
        );
      } else {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_EXAMPLE',
        );
      }
    });
    this.querySearch('');
  }

  public querySearch(query): void {
    this.selectedFlight = null;
    this.searchData.flightNumber = null;
    this.searchData.flightsFiltered = cloneDeep(this.searchData.flights);
    if (!query || query.trim().length === 0) {
      this.searchData.flightsFiltered = cloneDeep(this.searchData.flights);
    } else {
      query = query.toLowerCase();
      this.searchData.flightsFiltered = cloneDeep(
        this.searchData.flights.filter(flight => {
          const dataToFind = flight.flightNumber.toLowerCase();
          if (dataToFind.includes(query)) {
            return flight;
          }
        }),
      );
    }
  }

  public resetFlight(): void {
    this.selectedFlight = null;
    this.searchData = {
      date: undefined,
      flights: [],
      flightsFiltered: [],
      flightNumber: null,
    };
  }

  public selectedItemChange(value: { option: { value: string } }): void {
    this.selectedFlight = this.searchData.flights.find(f => f.objectId === value.option.value);
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
  }

  addEvent(applicability = null): void {
    const dialogRef = this.dialog.open(this.eventModal, {
      data: {
        notifications: this.notifications,
        fromLogbook: {
          attachments: this.logbook.attachments,
          tags: this.logbook.tags,
          text: this.logbook.contentText,
          applicability: applicability,
          acl: this.logbook.acl,
        },
        fromDecision: undefined,
        isUtc: this.isUtc,
        airportsCode: this.airportsCode,
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(newEvent => {
        if (newEvent) {
          const bufferEvent = cloneDeep(newEvent);
          this.events.push(bufferEvent);
          this.linkedEvents.push(bufferEvent.objectId);
          this.linkedEventsDisplay.push(bufferEvent);
        }
      });
  }

  public changelinkedEvent(selectedValues) {
    this.linkedEventsDisplay = [...intersectionWith(this.events, selectedValues, (i, j) => i.objectId === j), ...this.linkedEventsArchived];
  }

  public removeEvent(ev): void {
    this.linkedEvents = this.linkedEvents.filter(event => event !== ev.objectId);
    this.linkedEventsDisplay = this.linkedEventsDisplay.filter(event => event.objectId !== ev.objectId);
  }

  public updateLinkedGroup(e) {
    this.linkedGroup = e;
  }

  public addDecision(event, applicability = null): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.occDecisionsModal, {
      data: {
        notifications: this.notifications,
        events: this.events,
        isUtc: this.isUtc,
        fromLogBook: {
          attachments: this.logbook.attachments,
          tags: this.logbook.tags,
          message: this.logbook.contentText,
          flight: this.selectedFlight,
          applicability: applicability,
          acl: this.logbook.acl,
        },
        airportsCode: this.airportsCode,
      },
    });
    dialogRef.afterClosed().subscribe(newDecision => {
      if (newDecision) {
        const bufferDecision = cloneDeep(newDecision);
        this.decisionItems.push(bufferDecision);
        this.linkedDecisions.push(bufferDecision.objectId);
        this.linkedDecisionsDisplay.push(bufferDecision);
      }
    });
  }

  public removeDecision(dcn): void {
    this.linkedDecisions = this.linkedDecisions.filter(decision => decision !== dcn.objectId);
    this.linkedDecisionsDisplay = this.linkedDecisionsDisplay.filter(decision => decision.objectId !== dcn.objectId);
  }

  public changelinkedDecision(selectedValues) {
    this.linkedDecisionsDisplay = [
      ...intersectionWith(this.decisionItems, selectedValues, (i, j) => i.objectId === j),
      ...this.linkedDecisionsArchived,
    ];
  }

  public saveLogbook(event?: { contentTextChanged: boolean; notifications: HolNotification[] }): void {
    // return;
    this.loading = true;
    if (this.logbook.gocLogbook && !this.fromGOC) {
      this.deleteGocLogbook();
    }
    if (this.logbook.crewLogbook && !this.fromCREW) {
      this.deleteCrewLogbook();
    }
    if (this.logbook.erpLogbook && !this.fromERP) {
      this.deleteErpLogbook();
    }
    if (this.isCreate) {
      this.logbook.text = this.logbook.contentText;
      this.logbook.flight = this.selectedFlight;
      if (this.moduleConfig.config.displayToGoc) {
        const toGOCValue = this.selectedFlight ? true : false;
        this.logbook.toGOC = toGOCValue;
        this.duplicateToOtherModule = toGOCValue;
      }
      if (this.moduleConfig.config.displayToERP) {
        this.duplicateToOtherModule = this.toERP;
        this.logbook.toERP = this.toERP;
      }
      this.logbookService
        .create(this.logbook, event.notifications, this.context, this.duplicateToOtherModule)
        .then(async newLogbook => {
          if (this.isInSummary) {
            (newLogbook as EclLogbook).summary = (this.logbook as EclLogbook).summary;
            await this.saveSummary(newLogbook, this.isInBriefing, this.isInSummary);
          }
          Promise.all([
            this.linksService.saveLinkLogBookEvent(newLogbook.objectId, this.linkedEvents),
            this.linksService.saveLinkLogBookDecision(newLogbook.objectId, 'logBook', this.linkedDecisions),
            this.updateGroupSelected(newLogbook),
          ])
            .then(() => {
              this.linksService
                .checkLinks(this.linkedDecisions, [newLogbook.objectId], this.linkedEvents, true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  this.dialogRef.close(newLogbook);
                })
                .catch(error => {
                  this.dialogRef.close();
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.groupService.fetchNewDataLogBookGroup();
                  this.loading = false;
                });
            })
            .catch(error => {
              this.dialogRef.close();
              console.error('Error creating LogBook link', error);
            });
        })
        .catch(error => {
          this.dialogRef.close();
          console.error('Error creating OCCLogBook', error);
        });
    } else {
      if (event && event.contentTextChanged) {
        this.logbook.text = this.logbook.contentText;
        this.logbook.customCreatedAt = new Date();
      }
      if (this.moduleConfig.config.displayToGoc) {
        this.oldDuplicateToOtherModuleValue = this.logbook.toGOC;
        const toGOCValue = this.selectedFlight ? true : false;
        this.logbook.toGOC = toGOCValue;
        this.newDuplicateToOtherModuleValue = toGOCValue;
        this.updateDuplicateToOtherModuleFlightValue = this.logbook.flight !== this.selectedFlight;
      }
      if (this.moduleConfig.config.displayToERP) {
        this.oldDuplicateToOtherModuleValue = this.logbook.toERP;
        this.newDuplicateToOtherModuleValue = this.toERP;
        this.duplicateToOtherModule = this.toERP;
        this.logbook.toERP = this.toERP;
      }
      this.logbook.flight = this.selectedFlight;
      const linkedEventsToUpdate = difference(this.linkedEvents, this.linkedEventsCopy);
      const linkedEventsToDelete = difference(this.linkedEventsCopy, this.linkedEvents);
      const linkedDecisionsToUpdate = difference(this.linkedDecisions, this.linkedDecisionsCopy);
      const linkedDecisionToDelete = difference(this.linkedDecisionsCopy, this.linkedDecisions);
      if (this.changeToDecision) {
        this.oclLogbookDecisionService
          .transformLogbookToDecision(this.logbook, event.notifications, this.context)
          .catch(e => {
            console.error(e);
          })
          .finally(async () => {
            this.loading = false;
            this.dialogRef.close();
            await this.groupService.fetchNewDataLogBookGroup();
          });
        return;
      }
      this.logbookService
        .update(
          this.logbook,
          event.notifications,
          this.context,
          this.oldDuplicateToOtherModuleValue,
          this.newDuplicateToOtherModuleValue,
          this.updateDuplicateToOtherModuleFlightValue,
        )
        .then(async newLogBook => {
          if (newLogBook instanceof EclLogbook) {
            (newLogBook as EclLogbook).summary = (this.logbook as EclLogbook).summary;
            await this.saveSummary(newLogBook, this.isInBriefing, this.isInSummary);
          }
          Promise.all([
            this.linksService.saveLinkLogBookEvent(this.logbook.objectId, linkedEventsToUpdate),
            this.linksService.saveLinkLogBookDecision(this.logbook.objectId, 'logBook', linkedDecisionsToUpdate),
            this.linksService.deleteLinkLogBookEventById(this.logbook.objectId, linkedEventsToDelete),
            this.linksService.deleteLinkDecisionForLogBookById(this.logbook.objectId, linkedDecisionToDelete),
            this.updateGroupSelected(),
          ])
            .then(() => {
              this.linksService
                .checkLinks([], [], [], true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  this.dialogRef.close(newLogBook);
                })
                .catch(error => {
                  this.dialogRef.close();
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.groupService.fetchNewDataLogBookGroup();
                  this.loading = false;
                });
            })
            .catch(error => {
              console.log(error);
              this.dialogRef.close();
            });
        })
        .catch(error => {
          console.log(error);
          this.dialogRef.close();
        });
    }
  }

  async saveSummary(savedLogbook: OclLogbook, isInBriefing: boolean, isInSummary?: boolean): Promise<void> {
    return;
  }

  deleteGocLogbook(): void {
    this.logbookService.deleteGocLogbook(this.logbook);
    this.dialogRef.close();
  }

  deleteCrewLogbook(): void {
    this.logbookService.deleteCrewLogbook(this.logbook);
    this.dialogRef.close();
  }

  deleteErpLogbook(): void {
    this.logbookService.deleteErpLogbook(this.logbook);
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.hasCrisisInProgressSub.unsubscribe();
  }

  public isFastTyping() {
    return this.oclOptionsService.getFastTypingModal();
  }

  protected updateGroupSelected(logBook?: OclLogbook): Promise<any>[] {
    const listGroupPromise = [];

    // First we remove this item from all groups & then we add the item to the list of groups.
    this.logbookGroup.forEach(lgr => {
      if (this.data.logbook) lgr.items = lgr.items.filter(lg => lg.objectId !== this.data.logbook.objectId);
      if (this.linkedGroup.includes(lgr.objectId)) {
        if (logBook) {
          lgr.items.push(logBook);
        } else {
          lgr.items.push(this.data.logbook);
        }
      }
      listGroupPromise.push(this.groupService.save(lgr, 'LOGBOOK'));
    });
    return listGroupPromise;
  }
}
