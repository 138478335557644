import { RequestService } from '../request.service';
import { HolCrisisType } from '../../models/hol-crisis';
import { EclCrisis, EclCrisisType } from '../../../ecl/models/ecl-crisis';
import { HolCrisisParameter } from '../../models/hol-crisis-parameter';

export class CrisisTypeService<T extends HolCrisisType = HolCrisisType> {
  // To be defined in constructor subclasses
  protected ParseCrisisType;
  protected ParseCrisisTypeParameter;

  constructor(protected requestService: RequestService) {}

  async getAll(): Promise<T[]> {
    try {
      const crisisQuery = new Parse.Query(this.ParseCrisisType);
      crisisQuery.ascending('title');
      crisisQuery.includeAll();
      const parseResult = await this.requestService.performFindAllQuery(crisisQuery);
      const result = parseResult ? parseResult.map(r => this.newCrisisTypeObject(r)) : [];
      await this.fetchCrisisParameters(result);
      return result;
    } catch (e) {
      return [];
    }
  }

  public async getCrisisTypeParameter(eclCrisisType: EclCrisisType) {
    const paramQuery = new Parse.Query(this.ParseCrisisTypeParameter);
    paramQuery.equalTo('crisisTypeId', eclCrisisType.crisisTypeId);
    paramQuery.ascending('order');

    const parseResult = await this.requestService.performFindAllQuery(paramQuery);
    const list = parseResult ? parseResult.map(r => new HolCrisisParameter(r)) : [];
    return list;
  }

  async getCrisisTypeByCrisisTypeId(crisisTypeId): Promise<T> {
    try {
      const crisisTypeQuery = new Parse.Query(this.ParseCrisisType);
      crisisTypeQuery.include('ACL');
      crisisTypeQuery.equalTo('crisisTypeId', crisisTypeId);
      const result = await this.requestService.performFirstQuery(crisisTypeQuery);
      const crisisType = this.newCrisisTypeObject(result);
      await this.fetchCrisisParameters([crisisType]);
      return crisisType;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  protected newCrisisTypeObject(parser: Parse.Object): T {
    return new HolCrisisType(parser) as T;
  }

  protected async fetchCrisisParameters(crisisTypes: T[]): Promise<void> {
    return Promise.resolve();
  }
}
