<mat-expansion-panel
  *ngIf="groupedEvents.length > 1"
  class="crew-event-group-item"
  [class.active]="isExpanded"
  (click)="panelClick()"
  [expanded]="isExpanded"
>
  <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto">
    <div class="event-item__header">
      <div (click)="onEditTitle()">
        <img class="icon" src="/assets/images/plane-mcc.svg" alt="plane icon" />
        <span *ngIf="!isOnEdit; else onEdit" class="event-item-title-big">
          {{ groupedEvents[0].impact }}
        </span>
        <ng-template #onEdit>
          <input autofocus matInput class="impact-name" placeholder="Impact name" type="text" [(ngModel)]="newName" />
          <button (click)="valide()" [disabled]="newName.length === 0" class="btn-cta" color="primary" mat-button>
            <i class="icon icon-check"></i>
          </button>
          <button (click)="cancel()" class="btn-cta" color="primary" mat-button>
            <i class="icon icon-quit"></i>
          </button>
        </ng-template>
        <strong class="event-item-title-date" [date]="groupedEvents[0].date" appFilterColor>
          {{ groupedEvents[0].date | date: 'dd MMM' }}
        </strong>
      </div>
      <span class="event-count">{{ groupedEvents.length }} events</span>
    </div>
  </mat-expansion-panel-header>
  <ul class="hol-list">
    <li *ngFor="let event of groupedEvents; trackBy: trackByFunction" class="hol-clickable hol-animate-enter-left hol-animate-leave-right">
      <app-crew-event-item [event]="event"></app-crew-event-item>
    </li>
  </ul>
</mat-expansion-panel>

<div *ngIf="groupedEvents.length === 1">
  <app-crew-event-item [event]="groupedEvents[0]"></app-crew-event-item>
</div>
