export const emailProviders = JSON.parse(`{
  "aerosureteconsulting.fr": "ASC",
  "afraa.org": "AFRAA",
  "aimsatis.com": "AIM",
  "airbus.com": "AIRBUS",
  "aircalin.nc": "ACI",
  "aircaraibes.com": "FWI",
  "aircorsica.com": "XK",
  "airfrance.fr": "AF",
  "ania.net": "ANIA",
  "arkema.com": "ARK",
  "aslairlines.com": "FPO",
  "aviapartner.aero": "AVP",
  "axa.fr": "AXA",
  "bintercanarias.com": "IBB",
  "bm.com": "BM",
  "bouygues-construction.com": "BYCO",
  "bouygues-es.com": "BES",
  "bouygues.com": "BYSA",
  "bouyguestelecom.fr": "BYTL",
  "bt.com": "BRITEL",
  "candw.lc": "CAN",
  "cgi.com": "CGI",
  "ch.abb.com": "ABB",
  "chalair.fr": "CLG",
  "chu-brest.fr": "CHUBREST",
  "cma-cgm.com": "CMA",
  "cmacgm-aircargo.com": "CCAC",
  "cmaships.com": "CMAS",
  "cpc-aero.fr ": "CPC",
  "cpmediation.com ": "CPM",
  "cre.fr": "CRE",
  "danone.com": "DANONE",
  "def.gouv.fr": "DEF",
  "deloitte.fr": "DELOITTE",
  "dentsuaegis.com": "DAC",
  "developpement-durable.gouv.fr": "DEVDUR",
  "digitalmediacom.fr": "DM",
  "eaudeparis.fr": "EDP",
  "eem.cedre.fr": "CEDRE",
  "eha-consulting.com": "EHA",
  "engie.com": "ENGIE",
  "enterair.pl": "ENT",
  "eramet.com": "ERAMET",
  "finances.gouv.fr": "MINFIN",
  "flyuia.com": "UIA",
  "free.fr": "FREE",
  "frenchbee.com": "FBU",
  "fticonsulting.com": "FTI",
  "ge.com": "GE",
  "geh.aero": "GEH",
  "gendarmerie.defense.gouv.fr": "GEND",
  "gendarmerie.interieur.gouv.fr": "GEND",
  "generalairservices.com": "GAS",
  "gmail.com": "GM",
  "groupeavril.com": "AVRIL",
  "groupedubreuil.com": "GD",
  "halleyaviationservices.com": "HLY",
  "holis-airline.com": "HOL",
  "holis-apps.com": "HOLIS",
  "hotmail.co.uk": "HOT",
  "hotmail.fr": "HOT",
  "igo-solutions.com": "IGO",
  "in-tact.fr": "IT",
  "inextenso.fr": "IXO",
  "intradef.gouv.fr": "INTRADEF",
  "irsn.fr": "IRSN",
  "jetcom.fr": "JC",
  "keolis.com": "KEOLIS",
  "lactalis.fr": "LACTAL",
  "laposte.fr": "POSTE",
  "liatairline.com": "LIAT",
  "loreal.com": "OREAL",
  "mbda-systems.com": "MBDA",
  "mer.gouv.fr": "MER",
  "michelin.com": "MICHELN",
  "naval-group.com": "NAVAL",
  "nouvelair.com.tn": "NVLR",
  "octo.uk.com": "OCTO",
  "orano.group": "ORANO",
  "premar-mediterranee.gouv.fr": "PREMARMED",
  "premar-atlantique.gouv.fr": "PREMARATL",
  "protonmail.com": "PROTON",
  "samsic-aeroportuaire.fr": "SAMSIC",
  "samsic.aero ": "SAMSIC",
  "servair.com.do": "SERVAIR",
  "sicovad.fr": "SCV",
  "sintmaarteen.net": "SXM",
  "slava-projects.com": "SP",
  "spie.com": "SPIE",
  "teleperformance.com": "TP",
  "tf1.fr": "TF1",
  "total.com": "TOTAL",
  "tts.com.tn": "TTS",
  "wamosair.com": "PLM",
  "yahoo.com": "YAHOO",
  "yopmail.fr": "YOP"
}`);
