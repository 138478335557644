import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-and-image-display-full',
  templateUrl: './file-and-image-display-full.component.html',
  styleUrls: ['./file-and-image-display-full.component.scss'],
})
export class FileAndImageDisplayFullComponent {
  @Input() url: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() name: string;
  @Input() isPdf: boolean;
  @Input() isNote: boolean;
}
