import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ColorPickerModule } from 'ngx-color-picker';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { NgxMdModule } from 'ngx-md';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { DynamicModule } from 'ng-dynamic-component';
import { FormioModule } from 'angular-formio';

import { ChatChannelComponentDirective } from './angularJSComponents/chatChannelComponent.directive';
import { ChatComponentDirective } from './angularJSComponents/chatComponent.directive';
import { TeamComponentDirective } from './angularJSComponents/teamComponent.directive';
import { AclFilterComponent } from './components/acl-filter/acl-filter.component';
import { AclIndicatorComponent } from './components/acl-indicator/acl-indicator.component';
import { AclSelectorComponent } from './components/acl-selector/acl-selector.component';
import { ArchiveModalComponent } from './components/archive-modal/archive-modal.component';
import { ChecklistByFunctionComponent } from './components/checklist-event/checklist-by-function/checklist-by-function.component';
import { ChecklistByScenarioComponent } from './components/checklist-event/checklist-by-scenario/checklist-by-scenario.component';
import { ChecklistEventComponent } from './components/checklist-event/checklist-event.component';
import { ChecklistTaskComponent } from './components/checklist-event/checklist-item/checklist-task.component';
import { ChecklistItemComponent } from './components/checklist-vacation/checklist-item/checklist-item.component';
import { ChecklistVacationComponent } from './components/checklist-vacation/checklist-vacation.component';
import { ChecklistComponent } from './components/checklist-vacation/checklist/checklist.component';
import { ChecklistsItemEditComponent } from './components/checklist-vacation/checklists-item-edit/checklists-item-edit.component';
import { VacationComponent } from './components/checklist-vacation/vacation/vacation.component';
import { ClockComponent } from './components/clock/clock.component';
import { CustomTabComponent } from './components/custom-tabs/custom-tab.component';
import { CustomTabsComponent } from './components/custom-tabs/custom-tabs.component';
import { FileAndImageDisplayFullComponent } from './components/file-and-image-display-full/file-and-image-display-full.component';
import { FileAndImageDisplayComponent } from './components/file-and-image-display/file-and-image-display.component';
import { FileAndImageIconsDisplayComponent } from './components/file-and-image-icons-display/file-and-image-icons-display.component';
import { FileAndImageUploadComponent } from './components/file-and-image-upload/file-and-image-upload.component';
import { FileUploadAttachmentModalComponent } from './components/file-upload-attachment-modal/file-upload-attachment-modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HeaderComponent } from './components/header/header.component';
import { NgMarqueeModule } from './components/header/ng-marquee/ng-marquee.module';
import { HolListItemComponent } from './components/hol-list-item/hol-list-item.component';
import { HolNextInfoDisplayComponent } from './components/hol-next-info-display/hol-next-info-display.component';
import { HolNextInfoItemComponent } from './components/hol-next-info-item/hol-next-info-item.component';
import { HolNextInfoComponent } from './components/hol-next-info/hol-next-info.component';
import { HolsterStopoverComponent } from './components/holster-stopover/holster-stopover.component';
import { IndicatorStatusItemComponent } from './components/indicator/indicator-status/indicator-status-item/indicator-status-item.component';
import { IndicatorStatusComponent } from './components/indicator/indicator-status/indicator-status.component';
import { IndicatorStopoverTimelineComponent } from './components/indicator/indicator-stopover-timeline/indicator-stopover-timeline.component';
import { IndicatorTimelineComponent } from './components/indicator/indicator-timeline/indicator-timeline.component';
import { IndicatorsPanelComponent } from './components/indicator/indicators-panel/indicators-panel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MarkdownEditorModalComponent } from './components/markdown-editor/markdown-editor-modal.component';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { NotificationTableComponent } from './components/notification-table/notification-table.component';
import { SampleModalComponent } from './components/sample-modal/sample-modal.component';
import { TagChipsComponent } from './components/tag-chips/tag-chips.component';
import { TagFilterComponent } from './components/tag-filter/tag-filter.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { TaskFormIoComponent } from './components/task-details/task-form-io/task-form-io.component';
import { TaskHistoryComponent } from './components/task-details/task-history/task-history.component';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { HolFilterPanelComponent } from './components/hol-filter-panel/hol-filter-panel.component';
import { HolSwitchToggleComponent } from './components/ui-kit/hol-switch-toggle/hol-switch-toggle.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { HorizontalScrollDirective } from './directives/horizontal-scroll/horizontal-scroll.directive';
import { IndicatorTimelineColorDirective } from './directives/indicator-timeline-color.directive';
import { AddNextInfoModalComponent } from './modals/add-next-info-modal/add-next-info-modal.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { ChatModalComponent } from './modals/chat-modal/chat-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { DetailNextInfoModalComponent } from './modals/detail-next-info-modal/detail-next-info-modal.component';
import { HolHistoryItemsModalComponent } from './modals/hol-history-items-modal/hol-history-items-modal.component';
import { HolModalComponent } from './modals/hol-modal/hol-modal.component';
import {
  AclFilterArrayFunctionPipe,
  AclFilterEclDisplayLineFunctioPipe,
  AclFilterFunctionPipe,
  AclFilterPipe,
  AclFilterUserPipe,
  AclGroupFilterPipe,
  AclStopoverFilterPipe,
} from './pipes/acl-filter/acl-filter.pipe';
import { CallbackPipe } from './pipes/callback/callback.pipe';
import { FilterChecklistEventPipe } from './pipes/filter-checklist-event/filter-checklist-event.pipe';
import { FilterFlightListPipe } from './pipes/filterFlightList/filter-flight-list.pipe';
import { GroupByPipe } from './pipes/group-by/group-by.pipe';
import { NextInfoTimePipe } from './pipes/nextInfoTime/next-info-time.pipe';
import { OccSearchDashboardPipe } from './pipes/occ-search-dashboard/occ-search-dashboard.pipe';
import { TagsFilterPipe } from './pipes/tags-filter/tags-filter.pipe';
import { SearchEventChecklistPipe } from './pipes/search-event-checklist/search-event-checklist.pipe';
import { TimeOnlyDayPipe } from './pipes/time-only-day/time-only-day.pipe';
import { TimeOrDayPipe } from './pipes/time-or-day/time-or-day.pipe';
import { TimePipe } from './pipes/time/time.pipe';
import { TranslatePipe } from './pipes/translate/translate.pipe';
import { VacationChecklistSortPipe } from './pipes/vacation-checklist-sort/vacation-checklist-sort.pipe';
import { NoteTemplateService } from './services/note-template.service';
import { TagChangeService } from './services/tag-change.service';
import { HolAddonsPanelComponent } from './components/hol-addons-panel/hol-addons-panel.component';
import { BreakingNewsComponent } from './components/breaking-news/breaking-news.component';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErpTaskVisibilityComponent } from '../erp/components/erp-task-visibility/erp-task-visibility.component';
import { MentionModule } from 'angular-mentions';
import { CrisisModalComponent } from './modals/crisis-modal/crisis-modal.component';
import { GroupSelectorComponent } from './components/group-selector/group-selector.component';
import { HolSnackbarComponent } from './components/hol-snackbar/hol-snackbar.component';
import { SafePipe } from './pipes/safe/safe.pipe';
import { TagsStylePipe } from './pipes/tags-style/tags-style.pipe';
import { IsDefaultTagPipe } from './pipes/tags-style/is-default-tag.pipe';
import { HolModalPromptComponent } from './modals/hol-modal-prompt/hol-modal-prompt.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchVacationsPipe } from './pipes/search-vacations/search-vacations.pipe';
import { AclIndicatorEclComponent } from './components/acl-indicator-ecl/acl-indicator-ecl.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { TagComponent } from './components/tag/tag.component';
import { TagSelectorComponent } from './components/tag-selector/tag-selector.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormModalComponent } from './modals/form-modal/form-modal.component';
import { EllipsePipe } from './pipes/ellipse/ellipse.pipe';
import { RemoveRolePrefixPipe } from './pipes/tag-admin/remove-role-prefix.pipe';
import { AddPrefixPipe } from './pipes/tag-admin/add-prefix.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { PasswordStrengthComponent } from '../admin/components/admin-password/password-strength.component';
import { AutoLinkDirective } from './directives/autolink.directive';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    ChatComponentDirective,
    TeamComponentDirective,
    ChatChannelComponentDirective,
    IndicatorStatusComponent,
    IndicatorTimelineComponent,
    TimePipe,
    LoadingComponent,
    CallbackPipe,
    GroupByPipe,
    TranslatePipe,
    FileUploadComponent,
    MarkdownEditorComponent,
    MarkdownEditorModalComponent,
    FileAndImageDisplayComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageUploadComponent,
    IndicatorsPanelComponent,
    IndicatorStatusItemComponent,
    IndicatorTimelineColorDirective,
    AutoLinkDirective,
    SampleModalComponent,
    NotificationTableComponent,
    ArchiveModalComponent,
    NextInfoTimePipe,
    ClockComponent,
    TimeOrDayPipe,
    FilterFlightListPipe,
    TimeOnlyDayPipe,
    TagFilterComponent,
    TagSelectorComponent,
    TagChipsComponent,
    TagsFilterPipe,
    SearchEventChecklistPipe,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HorizontalScrollDirective,
    HolsterStopoverComponent,
    HolNextInfoItemComponent,
    OccSearchDashboardPipe,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistEventComponent,
    ChecklistVacationComponent,
    VacationChecklistSortPipe,
    ChecklistComponent,
    ChecklistItemComponent,
    VacationComponent,
    ChecklistsItemEditComponent,
    ChecklistEventComponent,
    ChecklistByFunctionComponent,
    ChecklistByScenarioComponent,
    ChecklistTaskComponent,
    FilterChecklistEventPipe,
    FileAndImageIconsDisplayComponent,
    FileUploadAttachmentModalComponent,
    AclFilterComponent,
    AclSelectorComponent,
    AclIndicatorComponent,
    AclIndicatorEclComponent,
    AclFilterPipe,
    AclFilterUserPipe,
    AclGroupFilterPipe,
    AclStopoverFilterPipe,
    HolListItemComponent,
    HeaderComponent,
    ChatModalComponent,
    AlertModalComponent,
    ConfirmationModalComponent,
    DisableControlDirective,
    IndicatorStopoverTimelineComponent,
    HolSwitchToggleComponent,
    HolModalComponent,
    HolModalPromptComponent,
    HolHistoryItemsModalComponent,
    HolNextInfoComponent,
    HolNextInfoDisplayComponent,
    TaskFormIoComponent,
    TaskDetailsComponent,
    ErpTaskVisibilityComponent,
    TaskHistoryComponent,
    TaskItemComponent,
    ToasterComponent,
    HolFilterPanelComponent,
    HolAddonsPanelComponent,
    BreakingNewsComponent,
    CrisisModalComponent,
    GroupSelectorComponent,
    HolSnackbarComponent,
    SafePipe,
    TagsStylePipe,
    IsDefaultTagPipe,
    AclFilterFunctionPipe,
    AclFilterArrayFunctionPipe,
    AclFilterEclDisplayLineFunctioPipe,
    SearchBarComponent,
    SearchVacationsPipe,
    ColorPickerComponent,
    TagComponent,
    FormModalComponent,
    EllipsePipe,
    RemoveRolePrefixPipe,
    AddPrefixPipe,
    PasswordStrengthComponent,
  ],
  imports: [
    CommonModule,
    LMarkdownEditorModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatSnackBarModule,
    MatExpansionModule,
    NgxHmCarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMdModule.forRoot(),
    MatTooltipModule,
    ColorPickerModule,
    NgMarqueeModule,
    MatTabsModule,
    MatSlideToggleModule,
    DynamicModule,
    MatDividerModule,
    FormioModule,
    MentionModule,
    TooltipModule,
    MatStepperModule,
  ],
  entryComponents: [
    CrisisModalComponent,
    IndicatorsPanelComponent,
    SampleModalComponent,
    MarkdownEditorModalComponent,
    ArchiveModalComponent,
    TagFilterComponent,
    TagSelectorComponent,
    TagChipsComponent,
    ToasterComponent,
    FileAndImageUploadComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageDisplayComponent,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HolNextInfoItemComponent,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistVacationComponent,
    ChecklistEventComponent,
    ChecklistsItemEditComponent,
    FileAndImageIconsDisplayComponent,
    FileUploadAttachmentModalComponent,
    AclFilterComponent,
    AclSelectorComponent,
    HeaderComponent,
    ChatModalComponent,
    AlertModalComponent,
    ConfirmationModalComponent,
    HolHistoryItemsModalComponent,
    HolModalPromptComponent,
    LoadingComponent,
    ColorPickerComponent,
    TagComponent,
    FormModalComponent,
  ],
  exports: [
    MentionModule,
    CrisisModalComponent,
    CommonModule,
    LMarkdownEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMdModule,
    NotificationTableComponent,
    FileAndImageDisplayComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageUploadComponent,
    FileUploadComponent,
    MarkdownEditorComponent,
    MarkdownEditorModalComponent,
    LoadingComponent,
    IndicatorsPanelComponent,
    IndicatorStatusComponent,
    IndicatorTimelineComponent,
    ArchiveModalComponent,
    ChatComponentDirective,
    TeamComponentDirective,
    ChatChannelComponentDirective,
    IndicatorTimelineColorDirective,
    SafePipe,
    TimePipe,
    TimeOrDayPipe,
    NextInfoTimePipe,
    CallbackPipe,
    GroupByPipe,
    TranslatePipe,
    HttpClientModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatCardModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgxHmCarouselModule,
    ClockComponent,
    FilterFlightListPipe,
    TimeOnlyDayPipe,
    TagFilterComponent,
    TagSelectorComponent,
    TagChipsComponent,
    ToasterComponent,
    TagsFilterPipe,
    SearchEventChecklistPipe,
    HorizontalScrollDirective,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HolsterStopoverComponent,
    HolNextInfoItemComponent,
    OccSearchDashboardPipe,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistVacationComponent,
    ChecklistEventComponent,
    VacationChecklistSortPipe,
    ChecklistsItemEditComponent,
    FilterChecklistEventPipe,
    FileAndImageIconsDisplayComponent,
    AclSelectorComponent,
    AclIndicatorComponent,
    AclIndicatorEclComponent,
    AclFilterComponent,
    AclFilterPipe,
    AclFilterUserPipe,
    AclGroupFilterPipe,
    AclStopoverFilterPipe,
    HolListItemComponent,
    MatTooltipModule,
    HeaderComponent,
    AlertModalComponent,
    MatTabsModule,
    DisableControlDirective,
    MatSlideToggleModule,
    IndicatorStopoverTimelineComponent,
    HolSwitchToggleComponent,
    HolModalComponent,
    HolModalPromptComponent,
    HolHistoryItemsModalComponent,
    MatDividerModule,
    HolNextInfoComponent,
    HolNextInfoDisplayComponent,
    TaskFormIoComponent,
    TaskDetailsComponent,
    ErpTaskVisibilityComponent,
    SearchBarComponent,
    TaskHistoryComponent,
    TaskItemComponent,
    HolFilterPanelComponent,
    HolAddonsPanelComponent,
    BreakingNewsComponent,
    GroupSelectorComponent,
    HolSnackbarComponent,
    ChecklistTaskComponent,
    AclFilterFunctionPipe,
    AclFilterArrayFunctionPipe,
    AclFilterEclDisplayLineFunctioPipe,
    SearchVacationsPipe,
    ColorPickerComponent,
    TagComponent,
    EllipsePipe,
    RemoveRolePrefixPipe,
    AddPrefixPipe,
    PasswordStrengthComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    TagChangeService,
    ToasterComponent,
    TranslatePipe,
    DatePipe,
    TimeOrDayPipe,
    DecimalPipe,
    NoteTemplateService,
    EllipsePipe,
  ],
})
export class CommonComponentsModule {
  constructor() {}
}
