<div>
  <div class="panel-section">
    <h5>{{ 'BREAKING_NEWS.MODAL.TITLE' | translate }}</h5>
    <app-loading *ngIf="loading" class="full-loader"></app-loading>

    <div *ngIf="!breakingNewsList">
      <p class="text-muted">{{ 'BREAKING_NEWS.MODAL.EMPTY' | translate }}</p>
    </div>

    <ul class="newsList" *ngIf="breakingNewsList.length">
      <li *ngFor="let newsItem of breakingNewsList">
        <div (mouseenter)="newsItem.showEditIcon = true" (mouseleave)="newsItem.showEditIcon = false" (click)="newsItem.edit = true">
          <div class="news-edit" *ngIf="newsItem.edit; else notEdition">
            <mat-form-field>
              <input matInput type="text" maxlength="100" [(ngModel)]="newsItem.news" [ngModelOptions]="{ standalone: true }" />
            </mat-form-field>
            <div class="buttons">
              <button mat-stroked-button (click)="closeItem(newsItem)">
                {{ 'BREAKING_NEWS.MODAL.CANCEL' | translate }}
              </button>
              &nbsp;
              <button mat-stroked-button (click)="updateItem(newsItem)">
                {{ 'BREAKING_NEWS.MODAL.UPDATE' | translate }}
              </button>
            </div>
          </div>
          <ng-template #notEdition>
            <div class="news-text">
              <mat-icon *ngIf="newsItem.showEditIcon" class="hol-icon hol-icon-edit"></mat-icon>&nbsp;
              <span>{{ newsItem.news }}</span>
            </div>
          </ng-template>
        </div>
        <div class="deleteButton" (click)="deleteItem(newsItem, $event)">
          <mat-icon>delete</mat-icon>
        </div>
      </li>
    </ul>

    <button mat-raised-button color="primary" [disabled]="addFormEnabled" (click)="addFormEnabled = true">
      {{ 'BREAKING_NEWS.MODAL.ADD_FORM' | translate }}
    </button>

    <div class="newsForm" *ngIf="addFormEnabled">
      <button mat-icon-button tabindex="-1" style="float: right" (click)="addFormEnabled = false">
        <mat-icon>close</mat-icon>
      </button>

      <form [formGroup]="addForm">
        <div class="panel-section">
          <h6>{{ 'BREAKING_NEWS.MODAL.ADD_NEWS' | translate }}</h6>
          <div>
            <mat-form-field class="input-description">
              <input
                matInput
                formControlName="textContent"
                required
                autofocus
                maxlength="100"
                placeholder="{{ 'BREAKING_NEWS.MODAL.PLACEHOLDER.NEWS' | translate }}"
              />
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" [disabled]="addForm.invalid" (click)="addItem()">
                {{ 'COMMON.BUTTON.ADD' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
