import { MccAircraft } from './mcc-aircraft.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import { HolObject } from '../../common/models/hol-object';

export class MccMel extends HolObject {
  aircraft: string;
  dueDate: Date;
  foundDate: Date;
  faultType: string;
  reference: string;
  barcode: string;
  faultName: string;
  infos: HolNextInfo[];
  constructor(parseObject?: Parse.Object, infos?: HolNextInfo[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.aircraft = parseObject.get('aircraft');
    this.dueDate = parseObject.get('dueDate');
    this.foundDate = parseObject.get('foundDate');
    this.faultType = parseObject.get('faultType');
    this.reference = parseObject.get('reference');
    this.barcode = parseObject.get('barcode');
    this.faultName = parseObject.get('faultName');
    this.infos = infos;
  }
}
