import { HolObject } from './hol-object';
import { HolCrisisParameter, HolCrisisParameterValue } from './hol-crisis-parameter';

export class HolCrisis extends HolObject {
  objectId: string;
  crisisTypeId: string;
  isTraining: boolean;
  isInPreparation: boolean;
  inProgress: boolean;
  clockDeparture: string;
  clockArrival: string;
  clockOnSite: string;
  mainTitle: string;
  subTitle: string;
  params: HolCrisisParameterValue[] = [];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.crisisTypeId = parseObject.get('crisisTypeId');
    this.isTraining = parseObject.get('isTraining') ? parseObject.get('isTraining') : false;
    this.isInPreparation = parseObject.get('isInPreparation');
    this.inProgress = parseObject.get('inProgress');
    this.clockDeparture = parseObject.get('clock_departure');
    this.clockArrival = parseObject.get('clock_arrival');
    this.clockOnSite = parseObject.get('clock_onSite');
    this.mainTitle = parseObject.get('mainTitle');
    this.subTitle = parseObject.get('subtitle');
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('mainTitle');
  }
}

export class HolCrisisType extends HolObject {
  public crisisTypeId: string;
  public title: string;
  public descriptionTitle: string;
  public parameters: HolCrisisParameter[] = [];
  public functionsToNotify: string[];
  public isDeactivated: boolean = false;

  public constructor(parseObject: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.crisisTypeId = parseObject.get('crisisTypeId');
      this.title = parseObject.get('title');
      this.descriptionTitle = parseObject.get('descriptionTitle');
      this.functionsToNotify = parseObject.get('functionIdsToNotify') ? parseObject.get('functionIdsToNotify').split('|') : [];
      this.isDeactivated = parseObject.get('isDeactivated') ? parseObject.get('isDeactivated') : false;
    }
  }
}
