<span
  *ngIf="universeRight.right === 'READ'"
  [class.indicator-color-0]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_4"
  [class.indicator-color-1]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_3"
  [class.indicator-color-2]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_2"
  [class.indicator-color-3]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_1"
  [class.indicator-color-4]="flight[universeRight.universe] === IndicatorStatusType.NO_INFO"
  [class.indicator-color-6]="flight[universeRight.universe] === IndicatorStatusType.UNDEFINED"
  class="indicator-label"
>
  {{ universeToName(universeRight.universe) }}
</span>

<mat-menu #appMenu="matMenu" class="indicator-menu">
  <button class="indicator-button indicator-color-invert-0" mat-menu-item (click)="valueChanged(IndicatorStatusType.THRESHOLD_4)">
    > 3 heures
  </button>
  <button class="indicator-button indicator-color-invert-1" mat-menu-item (click)="valueChanged(IndicatorStatusType.THRESHOLD_3)">
    <span>> 2 heures</span>
  </button>
  <button class="indicator-button indicator-color-invert-2" mat-menu-item (click)="valueChanged(IndicatorStatusType.THRESHOLD_2)">
    <span>> 1 heure</span>
  </button>
  <button class="indicator-button indicator-color-invert-3" mat-menu-item (click)="valueChanged(IndicatorStatusType.THRESHOLD_1)">
    <span>> 30 min</span>
  </button>
  <button class="indicator-button indicator-color-invert-4" mat-menu-item (click)="valueChanged(IndicatorStatusType.NO_INFO)">
    <span>> 15 min</span>
  </button>
  <button class="indicator-button indicator-color-invert-5" mat-menu-item (click)="valueChanged(IndicatorStatusType.UNDEFINED)">
    <span>{{ 'COMMON.CLEAR' | translate }}</span>
  </button>
</mat-menu>

<mat-button-toggle-group
  *ngIf="universeRight.right === 'WRITE'"
  appearance="legacy"
  class="indicator-toggle-group"
  (click)="$event.stopPropagation()"
>
  <mat-button-toggle
    [matMenuTriggerFor]="appMenu"
    class="indicator-button"
    [class.indicator-color-0]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_4"
    [class.indicator-color-1]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_3"
    [class.indicator-color-2]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_2"
    [class.indicator-color-3]="flight[universeRight.universe] === IndicatorStatusType.THRESHOLD_1"
    [class.indicator-color-4]="flight[universeRight.universe] === IndicatorStatusType.NO_INFO"
    [class.indicator-color-6]="flight[universeRight.universe] === IndicatorStatusType.UNDEFINED"
  >
    {{ universeToName(universeRight.universe) }}
  </mat-button-toggle>
</mat-button-toggle-group>
