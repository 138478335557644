'use strict';

/**
 * @ngdoc service
 * @name CrewLogsService
 * @description
 * # CrewLogsService
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('CrewLogsService', function ($q, RequestService, ParseMapper) {
    var CrewLog = Parse.Object.extend('CrewLog');

    /**
     * @name postLog
     * @param {string} type Le type d'action
     * @param {object?} situation La situation a loguer
     * @param {object?} event L'evenement à loguer
     * @description Enregistre un log crew
     * @return {Promise}
     */
    this.postLog = function (type, situation, event, vacation) {
      var deferred = $q.defer();
      situation = angular.copy(situation); // copy situation to remove $$properties
      event = angular.copy(event); // copy event to remove $$properties
      var log = new CrewLog();
      log.set('situation', situation);
      log.set('type', type);
      if (event) {
        var eventCopy = _.cloneDeep(event);
        eventCopy.createdBy = ParseMapper.userToObject(Parse.User.current());
        log.set('event', eventCopy);
      }
      if (vacation) {
        var vacationCopy = _.cloneDeep(vacation);
        vacationCopy.createdBy = ParseMapper.userToObject(Parse.User.current());
        log.set('vacation', vacationCopy);
      }
      RequestService.performSaveQuery(
        log,
        null,
        function (parseLog) {
          deferred.resolve(ParseMapper.crewLogToObject(parseLog));
        },
        deferred.reject
      );

      return deferred.promise;
    };

    /**
     * @name getAllLogs
     * @param startDate
     * @param endDate
     * @return {Promise}
     */
    this.getAllLogs = function (startDate, endDate) {
      var deferred = $q.defer();
      var query = new Parse.Query(CrewLog);
      query.lessThanOrEqualTo('createdAt', startDate);
      query.greaterThan('createdAt', endDate);
      query.descending('createdAt');
      RequestService.performCountQuery(query, function (count) {
        query.limit(count);
        RequestService.performFindQuery(
          query,
          function (parseLogs) {
            var logs = _.map(parseLogs, l => ParseMapper.crewLogToObject(l));
            deferred.resolve(logs);
          },
          deferred.reject
        );
      });
      return deferred.promise;
    };
  })
  /**
   * @name CrewLogsTypes
   */
  .constant('CrewLogsTypes', {
    SITUATION_CREATE: 'SITUATION_CREATE',
    SITUATION_UPDATE: 'SITUATION_UPDATE',
    SITUATION_CLOSE: 'SITUATION_CLOSE',
    EVENT_CREATE: 'EVENT_CREATE',
    EVENT_UPDATE: 'EVENT_UPDATE',
    EVENT_CLOSE: 'EVENT_CLOSE',
    EVENT_REOPEN: 'EVENT_REOPEN',
  });
