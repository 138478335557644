import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-modal',
  templateUrl: './sample-modal.component.html',
  styleUrls: ['./sample-modal.component.sass'],
})
export class SampleModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
