<div class="attachment-container">
  <div class="attachment-container" *ngIf="data?.attachments && !isEmptyAttachment()">
    <span class="title"> {{ data?.outputDataLabel || data?.outputTitle | uppercase}} </span>
    <div class="section">
      <ul>
        <ng-container *ngIf="data?.attachments?.noteFile">
          <li>
            <a [href]="data?.attachments.noteFile?.url" target="_blank">
              <span>Note</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="data?.attachments?.link">
          <li class="d-flex justify-content-between">
            <a [href]="data?.attachments.link.url" target="_blank">
              <span>{{data?.attachments.link.title ? data?.attachments.link.title : data?.attachments.link.url}}</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="data?.attachments?.files?.length" class="d-flex justify-content-between">
          <li *ngFor="let item of data?.attachments.files">
            <a [href]="item.url" target="_blank">
              <span>{{item.title ? item?.title : item.fileName}}</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="data?.attachments?.image">
          <div class="file-and-image-upload__row file-and-image-upload__row--center">
            <a [href]="data?.attachments.image.url" target="_blank">
              <figure>
                <img [src]="data?.attachments.image.url" />
              </figure>
            </a>
          </div>
        </ng-container>
      </ul>
    </div>
  </div>
  <ng-container
    class="title no-attachment__text"
    *ngIf="data && data?.outputDataLabel !== undefined && (data?.attachments === undefined || isEmptyAttachment())"
  >
    <div>
      <span class="no-attachment__title"> {{data?.outputDataLabel | uppercase}} </span>
    </div>
    <div>
      <span class="no-attachment">{{ moduleConfig.config.translateKey + '.TASKS.NO-ATTACHMENTS' | translate }}</span>
    </div>
  </ng-container>
</div>
