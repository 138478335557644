import { Injectable } from '@angular/core';
import { OclEventTagService } from '../../../ocl/services/ocl-event-tag-service/ocl-event-tag.service';

@Injectable({
  providedIn: 'root',
})
export class EclEventTagService extends OclEventTagService {
  // tslint:disable:variable-name
  protected ParseEventTag = Parse.Object.extend('ECLEventTag');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseTag = Parse.Object.extend('ECLTag');
  // tslint:enable
}
