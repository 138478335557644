export enum HolUTCDisplay {
  FORCE_UTC = 'FORCE_UTC',
  FORCE_LOCAL = 'FORCE_LOCAL',
  LOCAL_IF_READONLY = 'LOCAL_IF_READONLY',
}

export function utcEval(utcDisplay: HolUTCDisplay, isReadOnly: boolean = false): boolean {
  switch (utcDisplay) {
    case HolUTCDisplay.FORCE_UTC:
      return true;
    case HolUTCDisplay.FORCE_LOCAL:
      return false;
    case HolUTCDisplay.LOCAL_IF_READONLY:
      return !isReadOnly;
    default:
      return false;
  }
}
