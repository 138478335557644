import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclOptionsService } from '../../../ocl/services/ocl-options-service/ocl-options.service';

@Injectable({
  providedIn: 'root',
})
export class GocOptionsService extends OclOptionsService {
  // tslint:disable-next-line:variable-name
  protected ParseOptions = Parse.Object.extend('GOCOptions');
}
