import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { RequestService } from '../../common/services/request.service';
import { MccAtaCode } from '../models/mcc-ata-code.model';

@Injectable({
  providedIn: 'root',
})
export class MccAtaCodeService {
  // tslint:disable-next-line:variable-name
  ParseMCCATACode = Parse.Object.extend('MCCATACode');
  private ataCodes = undefined; // @cache : ata Codes

  constructor(private requestService: RequestService) {}

  // @hasCache options
  getAll(): Promise<MccAtaCode[]> {
    if (this.ataCodes !== undefined) {
      return Promise.resolve(cloneDeep(this.ataCodes));
    } else {
      const query = new Parse.Query(this.ParseMCCATACode);
      query.addAscending('code');
      return this.requestService.performFindAllQuery(query).then(results => {
        if (results) {
          this.ataCodes = results.map(r => new MccAtaCode(r));
        }
        return cloneDeep(this.ataCodes);
      });
    }
  }
}
