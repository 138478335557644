import { HolVacation } from 'src/app/common/models/hol-vacation.model';
import { Injectable } from '@angular/core';
import { MccEvent } from '../models/mcc-event.model';
import { RequestService } from 'src/app/common/services/request.service';
import { MccCabin } from '../models/mcc-cabin.model';
import { MccMel } from '../models/mcc-mel.model';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { MccAircraft } from '../models/mcc-aircraft.model';

@Injectable({
  providedIn: 'root',
})
export class MccHistoryService {
  // tslint:disable:variable-name
  private ParseMccLog = Parse.Object.extend('MCCLog');
  private ParseMccMel = Parse.Object.extend('MCCMEL');
  private ParseMccCabin = Parse.Object.extend('MCCCabin');
  private ParseMccEvent = Parse.Object.extend('MCCEvent');
  private ParseMccAircraft = Parse.Object.extend('MCCAirCraft');
  private ParseHolVacation = Parse.Object.extend('HOLVacation');
  // tslint:enable

  constructor(protected requestService: RequestService) {}

  public postMccLog(
    comment: string,
    type: string,
    subType: string,
    acl: Parse.ACL,
    aircraft: MccAircraft,
    event?: MccEvent,
    cabin?: MccCabin,
    mel?: MccMel,
    attachments?: HolAttachments,
    vacation?: HolVacation
  ): Promise<Parse.Object> {
    if (!vacation) {
      aircraft = aircraft || (event as any).originalEvent.aircraft;
    }
    const parseUser = Parse.User.current();
    const dataToJson = event || cabin || mel || vacation;
    if (parseUser) {
      const log = new this.ParseMccLog();
      if (event) {
        const parseEvent = new this.ParseMccEvent();
        parseEvent.id = event.objectId;
        log.set('event', parseEvent);
      }
      if (cabin) {
        const parseCabin = new this.ParseMccCabin();
        parseCabin.id = cabin.objectId;
        log.set('cabin', parseCabin);
      }
      if (mel) {
        const parseMel = new this.ParseMccMel();
        parseMel.id = mel.objectId;
        log.set('mel', parseMel);
      }

      if (aircraft) {
        const parseAircraft = new this.ParseMccAircraft();
        parseAircraft.id = aircraft.objectId;
        log.set('aircraft', parseAircraft);
      }

      if (vacation) {
        const parseHolVacation = new this.ParseHolVacation();
        parseHolVacation.id = parseHolVacation.objectId;
        log.set('holVacation', parseHolVacation);
      }

      log.set('comment', comment);
      log.set('type', type);
      log.set('subType', subType);
      log.set('user', parseUser);
      log.set('attachments', attachments);
      log.set('jsonObject', { ...dataToJson });
      if (acl) {
        log.setACL(acl);
      }
      return this.requestService.performSaveQuery(log);
    } else {
      return Promise.resolve(null);
    }
  }

  public postImportMelLog(type: string, subType: string, aircrafts: MccAircraft[], mels: MccMel[], isDelete: boolean): Promise<any> {
    const parseObjects = mels.map(mel => {
      const log = new this.ParseMccLog();
      const parseUser = Parse.User.current();
      const parseMel = new this.ParseMccMel();
      if (!isDelete) {
        parseMel.id = mel.objectId;
        log.set('mel', parseMel);
      }
      const aircraftId = aircrafts.find(aircraft => aircraft.registration === mel.aircraft).objectId;

      if (aircraftId) {
        const parseAircraft = new this.ParseMccAircraft();
        parseAircraft.id = aircraftId;
        log.set('aircraft', parseAircraft);
      }

      log.set('comment', mel.reference);
      log.set('type', type);
      log.set('subType', subType);
      log.set('user', parseUser);
      log.set('jsonObject', { ...mel });
      log.set('attachments', mel.attachments);
      if (mel.acl) {
        log.setACL(mel.acl);
      }
      return log;
    });

    return this.requestService.performSaveAllQuery(parseObjects);
  }
}
