import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ocl-criticality-loading',
  templateUrl: './ocl-criticality-loading.component.html',
  styleUrls: ['./ocl-criticality-loading.component.scss'],
})
export class OclCriticalityLoadingComponent implements OnInit {
  @Input() readonly;
  @Input() flightStatus;
  @Input() noColor;

  constructor() {}

  ngOnInit() {}
}
