import { Injectable } from '@angular/core';
import { OclEventTagService } from '../../../ocl/services/ocl-event-tag-service/ocl-event-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OpsEventTagService extends OclEventTagService {
  // tslint:disable:variable-name
  protected ParseEventTag = Parse.Object.extend('OPSEventTag');
  protected ParseEvents = Parse.Object.extend('OPSEvents');
  protected ParseTag = Parse.Object.extend('OPSTag');
  // tslint:enable
}
