import { ModuleConfigService } from './../../services/module-config/module-config.service';
import { HolHistoryLog } from './../../models/hol-history-log.model';
import { HistoryService } from 'src/app/common/services/history.service';
import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-hol-history-items-modal',
  templateUrl: './hol-history-items-modal.component.html',
  styleUrls: ['./hol-history-items-modal.component.scss'],
})
export class HolHistoryItemsModalComponent {
  itemsData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<HolHistoryItemsModalComponent>,
    public historyService: HistoryService,
    public moduleConfig: ModuleConfigService
  ) {
    if (this.data) {
      this.historyService
        .getHistoryByObjectIdAndType(this.data.historyItemObjectId, this.data.historyItemType)
        .then((i: HolHistoryLog[]) => {
          if (this.data.historyItemListMode) {
            this.itemsData = i.map(log => {
              return {
                ['item']: log.jsonObject,
                ['isReadOnly']: false,
                ['isHistory']: true,
              };
            });
          } else {
            this.itemsData = [
              {
                ['logs']: i,
                ['filterStatus']: '',
                ['functionSelected']: 'ALL',
                ['displayTitle']: false,
              },
            ];
          }
        });
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  valid(): void {
    this.dialogRef.close(true);
  }
}

export interface DialogHistoryData {
  title: string;
  historyItemComponent: ComponentType<any>;
  historyItemObjectId: string;
  historyItemType: string;
  historyTitle: string;
  historyItemListMode: boolean;
}
