import { Inject, Injectable } from '@angular/core';
import { OclSmsService } from '../../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { SmsSenderService } from 'src/app/common/services/sms/sms-sender.service';

@Injectable({
  providedIn: 'root',
})
export class OccSmsService extends OclSmsService {
  constructor(
    protected readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('$translate') protected $translate,
    protected moduleConfig: OccModuleConfigService,
  ) {
    super(smsSenderService, CONSTANTS, $translate, moduleConfig);

    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.$translate.instant('SMS.OCC.SENDER_SUFFIX') || 'OCC';
    });
  }
}
