<div class="hol-dialog-wrapper" style="position: relative">
  <app-loading *ngIf="loading" class="full-loader"></app-loading>

  <mat-toolbar>
    <div mat-dialog-title>
      <i class="fa fa-plus"></i>
      {{ modalTitle }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form name="markdownForm">
      <md-editor #mdEditor [(ngModel)]="note" [options]="options" mode="editor" name="Content"> </md-editor>
    </form>
    <div class="checkbox" *ngIf="toOccEnabled">
      <label>
        <mat-checkbox [(ngModel)]="toOCC.checkboxValue" aria-label="To OCC logbook">
          {{ 'CREW.MODALS.EVENT.TO_OCC' | translate }}
          <img class="icon" src="/assets/images/icon_paperplan.svg" />
        </mat-checkbox>
      </label>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="validateNote()" [disabled]="!note" class="hol-validate-btn" mat-raised-button>
      <i class="fa fa-check"></i> {{ 'COMMON.BUTTON.VALIDATE' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
