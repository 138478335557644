import { cloneDeep, values, merge, keyBy, isEqual, extend, find, intersectionWith } from 'lodash';
import { OclLogBooksActionTypes, LogBooksActions } from './ocl-logbooks.actions';
import { OclLogbook } from '../../models/ocl-logbook.model';

const logBookInitialState: Array<OclLogbook> = [];

export function oclLogBooksReducer(state: OclLogbook[] = cloneDeep(logBookInitialState), action: LogBooksActions): OclLogbook[] {
  switch (action.type) {
    case OclLogBooksActionTypes.INIT_LOGBOOKS:
      return action.payload;
    case OclLogBooksActionTypes.ADD_MANY_LOGBOOK:
      const bufferStateForAddMany = cloneDeep(state);
      action.payload.forEach(logbook => {
        if (bufferStateForAddMany.findIndex(el => el.objectId === logbook.objectId) === -1) {
          bufferStateForAddMany.push(logbook);
        }
      });
      return bufferStateForAddMany;
    case OclLogBooksActionTypes.UPDATE_MANY_LOGBOOKS:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclLogBooksActionTypes.UPDATE_LOGBOOKS_FROM_LINKED_VALUE:
      const bufferStateForAddLinkedValue = cloneDeep(state);
      action.payload.forEach(item => {
        const bufferIndex = bufferStateForAddLinkedValue.findIndex(el => el.objectId === item.objectId);
        if (bufferIndex === -1) {
          if (!item.displayFromLinkedValues) {
            item.displayFromLinkedValues = [];
          }
          item.displayFromLinkedValues.push(action.linkedUid);
          bufferStateForAddLinkedValue.push(item);
        } else {
          // tslint:disable-next-line: max-line-length
          if (
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues &&
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.length
          ) {
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.push(action.linkedUid);
          }
        }
      });
      return bufferStateForAddLinkedValue;
    case OclLogBooksActionTypes.REMOVE_LOGBOOKS_FROM_LINKED_VALUE:
      const bufferStateForRemoveLinkedValue = cloneDeep(state);
      bufferStateForRemoveLinkedValue.map(el => {
        if (el.displayFromLinkedValues && el.displayFromLinkedValues.includes(action.linkedUid)) {
          el.displayFromLinkedValues.splice(el.displayFromLinkedValues.indexOf(action.linkedUid), 1);
        }
        return el;
      });
      return bufferStateForRemoveLinkedValue.filter(el => !el.displayFromLinkedValues || el.displayFromLinkedValues.length !== 0);
    case OclLogBooksActionTypes.REMOVE_ALL_LOGBOOKS_LINKED:
      return [];
    case OclLogBooksActionTypes.UPDATE_LOGBOOKS_FROM_POOLING:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        // console.log('UPDATE NEEDED : POOL AND STORE ARE DIFFERENT');
        // console.log('state', state);
        // console.log('payload', action.payload);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclLogBooksActionTypes.ADD_ONE_LOGBOOK:
      const bufferState = cloneDeep(state);
      bufferState.unshift(action.payload);
      return bufferState;
    case OclLogBooksActionTypes.UPDATE_ONE_LOGBOOK:
      return cloneDeep(state).map(logBook => (logBook.objectId === action.payload.objectId ? action.payload : logBook));
    case OclLogBooksActionTypes.RESET_LOGBOOKS:
      return cloneDeep(logBookInitialState);
    case OclLogBooksActionTypes.DELETE_ONE_LOGBOOK:
      if (action.logBookObjectId && cloneDeep(state).findIndex(logBook => logBook.objectId === action.logBookObjectId) !== -1) {
        return cloneDeep(state).filter(el => el.objectId !== action.logBookObjectId);
      } else {
        return cloneDeep(state);
      }
    default:
      return state;
  }
}
