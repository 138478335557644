import { HolObject } from './hol-object';

export class HolScenario extends HolObject {
  code: string;
  title: string;
  nextOrder: number;
  groupingCode?: string;
  notifications?: [] = [];
  notificationsEmailECL?: [] = [];
  notificationsSmsECL?: [] = [];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      this.code = 'XX';
      this.title = 'No applicable scenario';
      return;
    }
    this.code = parseObject.get('code') || '';
    this.title = parseObject.get('title') || '';
    this.nextOrder = parseObject.get('nextOrder');
    this.groupingCode = parseObject.get('groupingCode');
    this.notifications = parseObject.get('notifications') ? parseObject.get('notifications').split('|') : [];
    this.notificationsEmailECL = parseObject.get('notificationsEmailECL') ? parseObject.get('notificationsEmailECL').split('|') : [];
    this.notificationsSmsECL = parseObject.get('notificationsSmsECL') ? parseObject.get('notificationsSmsECL').split('|') : [];
  }

  protected getContentText(parseObject: Parse.Object): string {
    return (parseObject.get('code') || '') + ' - ' + (parseObject.get('title') || '');
  }
}
