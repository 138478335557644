import { HolObject } from 'src/app/common/models/hol-object';

export class ErpFunctionCategory extends HolObject {
  title: string;
  tagId: string;
  color: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.title = parseObject.get('title');
    this.tagId = parseObject.get('tagId');
    this.color = parseObject.get('color');
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return this.title;
  }
}
