<div *ngIf="expanded" [class.is-in-box]="isInBox" class="indicators-panel">
  <div class="title title-box-occ">{{ 'INDICATORS.TITLE' | translate }}</div>

  <div class="indicators-panel__header">
    <img src="../../../../../assets/images/MapPin.svg" />
    <div class="form-group departure">
      <mat-form-field class="mat-form-field">
        <mat-label>Escale départ</mat-label>
        <mat-select (selectionChange)="selectDepartureAirport($event)" matNativeControl>
          <mat-option class="custom-option-departure" value="All">All</mat-option>
          <mat-option *ngFor="let airport of airports" class="custom-option-departure" value="{{ airport }}">{{ airport }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group risk">
      <mat-form-field class="risk">
        <mat-label>Risque</mat-label>
        <mat-select
          (ngModelChange)="selectIndicatorFilter($event)"
          [ngClass]="'indicator-color-' + indicatorFilterSelected"
          [ngModel]="indicatorFilterSelected"
          matNativeControl
          name="indicatorFilters"
        >
          <mat-option
            *ngFor="let indicatorFilter of indicatorsStatusForFilter; trackBy: trackByFn"
            [innerHTML]="indicatorFilter.libelle | translate"
            [ngClass]="'indicator-color-' + indicatorFilter.value"
            [value]="indicatorFilter.value"
            class="custom-option-indicators"
          >
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-checkbox (change)="toggleStatusFilter($event)" *ngIf="!isInBox" color="primary"><span>Vols partis</span> </mat-checkbox>
  </div>

  <mat-checkbox (change)="toggleStatusFilter($event)" *ngIf="isInBox" color="primary"><span>Vols partis</span> </mat-checkbox>

  <div class="iscroll-wrapper" iscroll>
    <div class="iscroll-scroller">
      <!-- <div
        *ngIf="
          (yesterdayFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter) &&
          (
            yesterdayFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter
          ).length
        "
        class="indicators-panel__period"
      >
        <div class="day">
          <span>{{ 'INDICATORS.YESTERDAY' | translate: { date: yesterday | date: 'dd/MM':'utc' } }}</span>
        </div>

        <div
          *ngFor="
            let flight of yesterdayFlights
              | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
              | aclFilter: RolesService.companiesRolesFilter
          "
          class="flight-indicator"
          [ngClass]="{ 'hol-clickable': moduleConfig.config.canOpenFlight }"
          (click)="goToFlight(flight.objectId)"
        >
          <div class="flight-indicator__header">
            <div class="flight-code">
              <i class="fa fa-plane"></i>
              <span
                >{{ flight.flightNumber }} <small>/{{ flight.std | date: 'ddMMM':'utc' | uppercase }}</small></span
              >
            </div>
            <app-indicator-status [accessRights]="accessRights" [flight]="flight"></app-indicator-status>
          </div>
          <app-indicator-timeline [dayTargeted]="yesterday" [flight]="flight" [showInfo]="true"></app-indicator-timeline>
        </div>
      </div> -->

      <div
        *ngIf="
          (todayFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter) &&
          (
            todayFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter
          ).length
        "
        class="indicators-panel__period"
      >
        <div class="day today">
          <span>{{ 'INDICATORS.TODAY' | translate: { date: today | date: 'dd/MM':'utc' } }}</span>
        </div>

        <div
          *ngFor="
            let flight of todayFlights
              | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
              | aclFilter: RolesService.companiesRolesFilter;
            trackBy: trackByFunction
          "
          class="flight-indicator"
          [ngClass]="{ 'hol-clickable': moduleConfig.config.canOpenFlight }"
          (click)="goToFlight(flight.objectId)"
        >
          <div class="flight-indicator__header">
            <div class="flight-code">
              <i class="fa fa-plane"></i>
              <span
                >{{ flight.flightNumber }} <small>/{{ flight.std | date: 'ddMMM':'utc' | uppercase }}</small></span
              >
            </div>
            <app-indicator-status [accessRights]="accessRights" [flight]="flight"></app-indicator-status>
          </div>
          <app-indicator-timeline [dayTargeted]="today" [flight]="flight" [showInfo]="true"></app-indicator-timeline>
        </div>
      </div>

      <!-- <div
        *ngIf="
          (tomorrowFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter) &&
          (
            tomorrowFlights
            | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
            | aclFilter: RolesService.companiesRolesFilter
          ).length
        "
        class="indicators-panel__period"
      >
        <div class="day">
          <span>{{ 'INDICATORS.TOMORROW' | translate: { date: tomorrow | date: 'dd/MM':'utc' } }}</span>
        </div>
        <div
          *ngFor="
            let flight of tomorrowFlights
              | filterFlightList: departureSelected:indicatorFilterSelected:statusFilterActivated
              | aclFilter: RolesService.companiesRolesFilter;
            trackBy: trackByFunction
          "
          class="flight-indicator"
          [ngClass]="{ 'hol-clickable': moduleConfig.config.canOpenFlight }"
          (click)="goToFlight(flight.objectId)"
        >
          <div class="flight-indicator__header">
            <div class="flight-code">
              <i class="fa fa-plane"></i>{{ flight.flightNumber }}
              <small>/{{ flight.std | date: 'ddMMM':'utc' | uppercase }}</small>
            </div>
            <app-indicator-status [accessRights]="accessRights" [flight]="flight"></app-indicator-status>
          </div>
          <app-indicator-timeline [dayTargeted]="tomorrow" [flight]="flight" [showInfo]="true"></app-indicator-timeline>
        </div>
      </div> -->
    </div>
  </div>
</div>
