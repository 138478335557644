'use strict';

angular
  .module('gestiondecriseApp')
  .controller('CrewHistoryCtrl', function (
    $scope,
    CrewHistoryService,
    UserService,
    $mdDialog,
    CrewFunctionsService,
    CrewEventsService,
    CrewUsersService
  ) {
    $scope.tab = 'LOG';
    $scope.loading = false;
    $scope.fromDate = moment().startOf('day').toDate();
    const initFromDate = moment($scope.fromDate);
    let lastLoadedDate = null;
    let firstInit = true;
    let hasMoreLoaded = false;

    $scope.dateOptions = {
      maxDate: new Date(),
    };

    function init() {
      $scope.loading = true;
      if ($scope.tab === 'LOG') {
        $scope.logTimeSlots = [];
        let date;
        if (firstInit) {
          date = moment();
        } else {
          date = moment($scope.fromDate).endOf('day');
        }
        CrewHistoryService.getLogsByTimeSlots(true, date.toDate()).then(function (timeSlots) {
          lastLoadedDate = date;
          $scope.logTimeSlots = timeSlots;
          $scope.loading = false;
          firstInit = false;
        });
      } else if ($scope.tab === 'CALENDAR') {
        $scope.calendarDays = [];
        CrewHistoryService.getCalendarDay(true, $scope.fromDate).then(function (calendarDay) {
          lastLoadedDate = moment($scope.fromDate);
          $scope.calendarDays = [calendarDay];
          $scope.loading = false;
        });
      }
    }

    const unregisterWatchDate = $scope.$watch('fromDate', function () {
      init();
    });

    const unregisterWatchTab = $scope.$watch('tab', function () {
      if ($scope.tab === 'LOG') {
        $scope.dateOptions = {
          maxDate: new Date(),
        };
      } else if ($scope.tab === 'CALENDAR') {
        $scope.dateOptions = {
          minDate: new Date(),
        };
      }
    });

    const unregisterPoolServiceLogs = $scope.$on('crewPoolService-historyLogs', function (e, timeSlots) {
      if (initFromDate.isSame($scope.fromDate) && !hasMoreLoaded) {
        $scope.logTimeSlots = timeSlots;
      }
    });

    const unregisterPoolServiceCalendar = $scope.$on('crewPoolService-historyCalendar', function (e, calendarDay) {
      if ($scope.calendarDays) {
        for (let i = 0; i < $scope.calendarDays.length; i++) {
          const cd = $scope.calendarDays[i];
          if (moment(calendarDay.startDate).isSame(cd.startDate)) {
            $scope.calendarDays[i] = calendarDay;
          }
        }
      }
    });

    const unregisterSituationEvent = $scope.$on('crew-situation-updated', function (event, situation) {
      if (situation.closeReason !== undefined) {
        // It's and event closed, remove it
        _.each($scope.calendarDays, function (cd) {
          _.remove(cd.events, { objectId: situation.objectId });
        });
      } else if (situation.closeReasonText !== undefined || situation.status === 'NEUTRAL') {
        // It's a to-do done or archived, remove it from timeslot
        _.each($scope.calendarDays, function (cd) {
          _.remove(cd.todos, { objectId: situation.objectId });
        });
      } else if (situation.status === 'WAITING' && situation.statusDate) {
        // It's a backtodo, add it to the good day
        _.each($scope.calendarDays, function (cd) {
          if (moment(situation.statusDate).isSame(cd.startDate, 'day')) {
            cd.todos.push(situation);
          }
        });
      }
    });

    $scope.getUserInitials = UserService.getUserInitials;

    $scope.loadMoreCalendarDays = function () {
      $scope.loading = true;
      CrewHistoryService.getCalendarDay(true, lastLoadedDate.add(1, 'day').toDate()).then(function (timeSlots) {
        hasMoreLoaded = true;
        $scope.calendarDays = $scope.calendarDays.concat(timeSlots);
        $scope.loading = false;
      });
    };
    $scope.loadMoreLogs = function () {
      $scope.loading = true;
      CrewHistoryService.getLogsByTimeSlots(true, lastLoadedDate.subtract(1, 'day').toDate()).then(function (timeSlots) {
        hasMoreLoaded = true;
        $scope.logTimeSlots = $scope.logTimeSlots.concat(timeSlots);
        $scope.loading = false;
      });
    };

    $scope.openUserModal = function ($event, situation) {
      $mdDialog.show({
        controller: 'CrewUserModalCtrl',
        templateUrl: 'views/crew/modals/user.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          user: situation && situation.user,
          event: null,
          situation: situation,
          isReadOnly: $scope.isReadOnly,
          isArchivesOpened: true,
        },
      });
    };

    $scope.openEventModal = function ($event, event, skipExtend) {
      $mdDialog
        .show({
          controller: 'CrewEventModalCtrl',
          templateUrl: 'views/crew/modals/event.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            functions: CrewFunctionsService.getAll,
            event: event,
            isReadOnly: $scope.isReadOnly,
          },
        })
        .then(function (retVal) {
          if (retVal && !skipExtend) {
            CrewEventsService.getById(retVal.objectId).then(function (updatedEvent) {
              if (updatedEvent.length) {
                _.extend(event, updatedEvent[0]);
              }
            });
          }
        });
    };

    $scope.openClosedEvent = function ($event, event) {
      // Retrieve event from DB to have the actual event
      CrewEventsService.getById(event.objectId).then(function (actualEvent) {
        if (actualEvent.length) {
          $scope.openEventModal($event, actualEvent[0], true);
        }
      });
    };

    $scope.searchEvent = function ($event) {
      $mdDialog
        .show({
          controller: 'CrewSearchEventModalCtrl',
          templateUrl: 'views/crew/modals/searchEvent.modal.html',
          targetEvent: $event,
          locals: {
            isReadOnly: $scope.isReadOnly,
            functions: CrewFunctionsService.getAll(),
          },
        })
        .then(function (retVal) {
          if (retVal) {
            CrewEventsService.getById(retVal.objectId).then(function (updatedEvent) {
              if (updatedEvent.length) {
                _.extend(event, updatedEvent[0]);
              }
            });
          }
        });
    };

    $scope.reload = function () {
      hasMoreLoaded = false;
      $scope.fromDate = moment().startOf('day').toDate();
      lastLoadedDate = null;
      firstInit = true;
      //init();
    };

    $scope.getUserTitle = function (user) {
      return CrewUsersService.getUserTitle(user);
    };

    $scope.getImpactLength = function (item) {
      if (item.impactDate && item.validityDate) {
        return moment(item.validityDate).diff(item.impactDate, 'day') + 1 + ' jours';
      }
      return '';
    };

    $scope.$on('$destroy', function () {
      unregisterWatchDate();
      unregisterSituationEvent();
      unregisterPoolServiceLogs();
      unregisterPoolServiceCalendar();
      unregisterWatchTab();
    });

    $scope.getMonogram = function (firstName, lastName) {
      return (firstName && firstName.length ? firstName[0] : '') + (lastName && lastName.length ? lastName[0] : '');
    };
  });
