import { Injectable } from '@angular/core';
import { OclLinkedItemsStoreManager } from '../../../ocl/store/linked-items/ocl-linked-items-store-manager.service';
import { RequestService } from '../../../common/services/request.service';
import { OclLinksService } from 'src/app/ocl/services/ocl-links-service/ocl-links.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class EclLinksService extends OclLinksService {
  // tslint:disable:variable-name
  protected ParseOclLogBook = Parse.Object.extend('ECLLogBook');
  protected ParseOclEvents = Parse.Object.extend('ECLEvents');
  protected ParseOclDecisions = Parse.Object.extend('ECLDecisions');
  protected ParseOclLinkLogBookDecision = Parse.Object.extend('ECLLinkLogBookDecision');
  protected ParseOclLinkDecisionEvent = Parse.Object.extend('ECLLinkDecisionEvent');
  protected ParseOclLinkLogBookEvent = Parse.Object.extend('ECLLinkLogBookEvent');
  // tslint:enabled
  public constructor(
    protected requestService: RequestService,
    protected occLinkedItemsStoreManager: OclLinkedItemsStoreManager,
    moduleConfig: EclModuleConfigService
  ) {
    super(requestService, occLinkedItemsStoreManager, moduleConfig);
  }
}
