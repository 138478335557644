import { EclCrisis } from '../../ecl/models/ecl-crisis';
import { HolObject } from './hol-object';

export class HolTag extends HolObject {
  name: string;
  color: string;
  crisis?: EclCrisis;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.name = parseObject.get('name');
    this.color = parseObject.get('color');
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }
}
