import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclHistoryLog } from '../../models/ecl-history-log.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';

@Injectable({
  providedIn: 'root',
})
export class EclHistoryService extends OclHistoryService<EclHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('ECLLogs');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseDecisions = Parse.Object.extend('ECLDecisions');
  protected ParseLogbooks = Parse.Object.extend('ECLLogBook');
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  protected eclOpenCrisis: EclCrisis;

  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: EclOptionsService,
    protected parseMapper: ParseMapperService,
    protected moduleConfig: EclModuleConfigService,
    protected eclCrisisStore: EclCrisisStoreManager,
  ) {
    super(requestService, $rootScope, optionsService, parseMapper, moduleConfig);
    this.eclCrisisStore.$eclSelectedCrisis.subscribe(c => (this.eclOpenCrisis = c));
  }

  protected setAdditionalFields(inputLog: EclHistoryLog, parseObject: Parse.Object) {
    super.setAdditionalFields(inputLog, parseObject);
    parseObject.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );
  }

  protected setAdditionalFilters(query: Parse.Query) {
    super.setAdditionalFilters(query);
    const queryLinkedCrisis = new Parse.Query(this.ParseEclCrisis);
    queryLinkedCrisis.equalTo('objectId', this.eclOpenCrisis.objectId);

    query.matchesQuery('crisis', queryLinkedCrisis);
  }

  protected newHistoryLog(parseObject: Parse.Object): EclHistoryLog {
    return new EclHistoryLog(parseObject);
  }

  public async getLastHistoryLog(crisisId: string): Promise<Date> {
    const query = new Parse.Query(this.ParseHistory);
    const crisisQuery = new Parse.Query(this.ParseEclCrisis);
    crisisQuery.equalTo('objectId', crisisId);
    query.matchesQuery('crisis', crisisQuery);
    query.include('crisis');
    query.addDescending('updatedAt');
    const result = await this.requestService.performFirstQuery(query);
    if (result) {
      return result.get('updatedAt');
    }
    return null;
  }
}
