import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'nextInfoTime',
})
export class NextInfoTimePipe implements PipeTransform {
  transform(value: any, utc?: any): any {
    if (value) {
      let momentDate = moment(value);
      let dateFormat;

      if (utc) {
        momentDate = momentDate.utc();
      }

      if (!momentDate.isSame(moment(), 'days')) {
        dateFormat = '[Next info] ddd DD/MM [at] HH:mm';
      } else {
        dateFormat = '[Next info at] HH:mm';
      }

      if (utc) {
        dateFormat += '[Z]';
      }

      return momentDate.format(dateFormat);
    }

    return null;
  }
}
