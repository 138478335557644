// https://stackoverflow.com/questions/34164413/how-to-apply-filters-to-ngfor
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
  pure: false,
})
export class CallbackPipe implements PipeTransform {
  public transform(items: any[], callback: (item: any) => boolean): any {
    return !items || !callback ? items : items.filter(item => callback(item));
  }
}
