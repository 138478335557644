<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper timeline-instruction-details-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i class="fa fa-search"></i>
      <span>{{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.TITLE' | translate }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="hol-dialog-section">
          <div class="col col-xs-2 icon-container">
            <i class="fa fa-file-o"></i>
          </div>
          <div class="col-xs-10">
            <mat-form-field class="full-width">
              <label>
                {{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.SCENARIO' | translate }}
              </label>
              <select formControlName="scenario" matNativeControl>
                <option value=""></option>
                <option *ngFor="let s of scenarios" value="{{ s.objectId }}">
                  {{ s.code + ' - ' + s.title }}
                </option>
                <hr />
              </select>
            </mat-form-field>
            <mat-form-field class="full-width">
              <textarea
                class="description-textarea"
                formControlName="description"
                mat-autosize
                matInput
                placeholder="{{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.DESCRIPTION' | translate }}"
                type="text"
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="searchLaunched">
      <div class="text-center" *ngIf="!events.length">
        {{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.EMPTY_RESULT' | translate }}
      </div>

      <ul *ngIf="events.length" class="hol-list">
        <li *ngFor="let event of events" class="hol-animate-enter-left hol-animate-leave-right">
          <app-ocl-event-item [event]="event" (updatedEvent)="onEventUpdated($event)"> </app-ocl-event-item>
        </li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="fill-space"></div>
    <mat-checkbox [(ngModel)]="archivedEvents" [labelPosition]="'after'" color="primary">
      {{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.ARCHIVED_EVENTS' | translate }}
      <img alt="icon-interne" src="/assets/images/internal.svg" />
    </mat-checkbox>
    <button (click)="search()" [disabled]="form.invalid" color="primary" mat-raised-button type="submit">
      {{ moduleConfig.config.translateKey + '.MODALS.TIMELINE_SEARCH.TITLE' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
