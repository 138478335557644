import { Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { RequestService } from 'src/app/common/services/request.service';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import * as moment from 'moment';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';

import { MccAircraft } from '../models/mcc-aircraft.model';
import { MccCabin } from '../models/mcc-cabin.model';
import { MccAircraftsStoreManager } from '../store/aircrafts/mcc-aircrafts.store-manager';
import { MccHistoryService } from './mcc-history.service';

@Injectable({
  providedIn: 'root',
})
export class MccCabinService {
  // tslint:disable:variable-name
  ParseCabinInfos = Parse.Object.extend('MCCCabinInfos');
  ParseCabin = Parse.Object.extend('MCCCabin');
  ParseCabinType = Parse.Object.extend('MCCCabinType');
  ParseCabinTag = Parse.Object.extend('MCCCabinTag');
  ParseUser = Parse.Object.extend('_User');
  // tslint:enable
  constructor(
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private aircraftsStoreManager: MccAircraftsStoreManager,
    private mccHistoryService: MccHistoryService,
    private userService: UserService,
  ) {}

  getAll(): Promise<MccCabin[]> {
    const query = new Parse.Query(this.ParseCabin);
    query.include('type');
    query.include('tags');
    query.notEqualTo('isArchive', true);

    return this.requestService.performFindAllQuery(query).then(parseMCCCabin => {
      const cabinInfoQuery = new Parse.Query(this.ParseCabinInfos);
      cabinInfoQuery.descending('createdAt');
      return this.requestService.performFindAllQuery(cabinInfoQuery).then(cabInfos => {
        return parseMCCCabin.map(cab => {
          const infos = cabInfos.filter(cabInfo => {
            return cabInfo.get('cabin').id === cab.id;
          });
          return new MccCabin(
            cab,
            infos.map(i => new HolNextInfo(i)),
          );
        });
      });
    });
  }

  save(cabin: Partial<MccCabin>, cabinTypeId: string, aircraft: MccAircraft, acl: Parse.ACL): Promise<MccCabin> {
    let parseCabin;

    if (cabin.objectId) {
      parseCabin = new this.ParseCabin({ id: cabin.objectId });
    } else {
      parseCabin = new this.ParseCabin();
      parseCabin.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
      parseCabin.set('aircraft', cabin.aircraft);
    }
    if (acl) {
      parseCabin.setACL(acl);
    }

    parseCabin.set('type', new this.ParseCabinType({ id: cabinTypeId }));
    parseCabin.set('position', cabin.position);
    parseCabin.set('isArchive', cabin.isArchive);
    parseCabin.set('attachments', JSON.stringify(cabin.attachments));

    if (cabin.tags && cabin.tags.length) {
      const parseTags = cabin.tags.map(tag => new this.ParseCabinTag({ id: tag.objectId }));
      parseCabin.set('tags', parseTags);
    }

    return this.requestService.performSaveQuery(parseCabin).then(m => {
      if (cabin.objectId) {
        const newCabin = new MccCabin(m);
        newCabin.infos = cabin.infos;

        if (cabin.isArchive) {
          this.mccHistoryService.postMccLog(
            newCabin.type + ' ' + newCabin.position,
            'cabin',
            'archive',
            newCabin.acl,
            aircraft,
            null,
            newCabin,
            null,
            cabin.attachments,
          );
          this.aircraftsStoreManager.removeItemCabin(newCabin);
        } else {
          this.aircraftsStoreManager.updateItemCabin(newCabin);
          this.mccHistoryService.postMccLog(
            newCabin.type + ' ' + newCabin.position,
            'cabin',
            'update',
            newCabin.acl,
            aircraft,
            null,
            newCabin,
            null,
            cabin.attachments,
          );
        }
        return newCabin;
      }
      const parseInfos = cabin.infos.map(info => {
        const parseCabinInfos = new this.ParseCabinInfos({
          cabin: parseCabin,
          message: info.message,
          nextInfoTime: info.nextInfoTime,
          createdBy: Parse.User.current(),
          attachments: JSON.stringify(info.attachments),
        });
        if (acl) {
          parseCabinInfos.setACL(acl);
        }
        return parseCabinInfos;
      });
      return this.requestService.performSaveAllQuery(parseInfos).then(pInfo => {
        const newCabin = new MccCabin(
          parseCabin,
          pInfo.map(i => new HolNextInfo(i)),
        );
        this.mccHistoryService.postMccLog(
          newCabin.type + ' ' + newCabin.position,
          'cabin',
          'create',
          newCabin.acl,
          aircraft,
          null,
          newCabin,
          null,
          cabin.attachments,
        );
        this.aircraftsStoreManager.addItemCabin(newCabin);

        return newCabin;
      });
    });
  }

  addInfo(
    cabin: MccCabin,
    message: string,
    nextInfoTime: Date,
    notifications: HolNotification[],
    attachments: HolAttachments,
    aircraft: MccAircraft,
    acl: Parse.ACL,
  ): Promise<HolNextInfo> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
    const cabinInfo = new this.ParseCabinInfos();
    const cabinParse = new this.ParseCabin();
    cabinParse.id = cabin.objectId;
    cabinInfo.set('cabin', cabinParse);
    cabinInfo.set('message', message);
    cabinInfo.set('nextInfoTime', nextInfoTime);
    cabinInfo.set('createdBy', Parse.User.current());
    if (acl) {
      cabinInfo.setACL(acl);
    }
    if (attachments) {
      cabinInfo.set('attachments', JSON.stringify(attachments));
    }

    return this.requestService.performSaveQuery(cabinInfo).then(parseData => {
      const infosToMarkAsDone = cabin.infos.filter(info => {
        return !info.done && moment(info.nextInfoTime).isBefore(nextInfoTime);
      });

      const promises = infosToMarkAsDone.map(info => {
        return this.markInfoAsDone(info, cabin, true, false, aircraft);
      });

      return Promise.all(promises).then(() => {
        const newInfo = new HolNextInfo(parseData);
        if (!cabin.infos) {
          cabin.infos = [];
        }
        cabin.infos.unshift(newInfo);

        this.mccHistoryService.postMccLog(
          cabin.type + ' ' + cabin.position,
          'cabin',
          'info',
          cabin.acl,
          aircraft,
          null,
          cabin,
          null,
          newInfo.attachments,
        );
        this.aircraftsStoreManager.updateItemCabin(cabin);

        return newInfo;
      });
    });
  }

  updateInfo(info: HolNextInfo, eventId?, acl?: Parse.ACL): Promise<HolNextInfo> {
    const parseCabinInfo = new this.ParseCabinInfos();
    parseCabinInfo.id = info.objectId;
    if (eventId) {
      parseCabinInfo.set('cabin', new this.ParseCabinInfos({ id: eventId }));
    }
    if (acl) {
      parseCabinInfo.setACL(acl);
    }
    parseCabinInfo.set('attachments', JSON.stringify(info.attachments));
    return this.requestService.performSaveQuery(parseCabinInfo).then(res => {
      return new HolNextInfo(res);
    });
  }

  markInfoAsDone(info: HolNextInfo, cabin: MccCabin, done: boolean, manual: boolean, aircraft: MccAircraft): Promise<HolNextInfo> {
    const cabinInfo = new this.ParseCabinInfos();
    cabinInfo.id = info.objectId;
    cabinInfo.set('done', done);
    cabinInfo.set('manual', manual);

    return this.requestService.performSaveQuery(cabinInfo).then(parseData => {
      const newInfo = new HolNextInfo(parseData);

      this.aircraftsStoreManager.updateItemCabinInfo(newInfo);
      Object.assign(info, newInfo);

      cabin.infos = cabin.infos.map(inf => {
        if (inf.objectId === newInfo.objectId) {
          return newInfo;
        }
        return inf;
      });

      this.mccHistoryService.postMccLog(
        cabin.type + ' ' + cabin.position,
        'cabin',
        done ? 'info-done' : 'info-backtodo',
        cabin.acl,
        aircraft,
        null,
        cabin,
        null,
        cabin.attachments,
      );
      return info;
    });
  }
}
