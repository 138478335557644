import { GocDecision } from './goc-decision.model';
import { GocLogbook } from './goc-logbook.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { FltFlightLogbook } from '../../flt/models/flt-flight-logbook';

export class GocFlightLogbook extends FltFlightLogbook {
  logbook: GocLogbook;
  decision: GocDecision;
  isPrivate: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.isPrivate = parseObject.get('isPrivate');
  }

  // public toLog(): GocLog {
  //   const log = new GocLog();
  //   log.attachments = this.attachments;
  //   log.comment = this.text;
  //   log.type = 'logbook';
  //   log.jsonObject = this;
  //   return log;
  // }

  // protected getContentText(parseObject: Parse.Object) {
  //   return parseObject.get('text');
  // }
}
