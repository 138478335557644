'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.component:MenuSideNav
 * @description
 * # MenuSideNav
 * sidenav menu of the gestiondecriseApp
 */

angular.module('gestiondecriseApp').component('menuSideNav', {
  templateUrl: 'scripts/components/menuSideNav/menuSideNav.template.html',
  controller: 'MenuSideNavController',
});
