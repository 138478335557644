import { HolTag } from 'src/app/common/models/hol-tag';
import { Action } from '@ngrx/store';

export enum OclTagsActionTypes {
  INIT_TAGS = '[OCL Tags] Init Tags',
  ADD_ONE_TAG = '[OCL Tags] Add One Tag',
  UPDATE_ALL_TAGS = '[OCL Tags] Update all Tags',
}

export class InitTags implements Action {
  readonly type = OclTagsActionTypes.INIT_TAGS;

  constructor(public tags: HolTag[]) {}
}

export class AddOneTag implements Action {
  readonly type = OclTagsActionTypes.ADD_ONE_TAG;

  constructor(public tag: HolTag) {}
}

export class UpdateTags implements Action {
  readonly type = OclTagsActionTypes.UPDATE_ALL_TAGS;

  constructor(public tags: HolTag[]) {}
}

export type TagsActions = InitTags | AddOneTag | UpdateTags;
