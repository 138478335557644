'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewTeamService
 * @description
 * # CrewTeamService
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('CrewTeamService', function (
    $q,
    CrewUsersService,
    CrewFunctionsService,
    CrewUsersFunctionsService,
    CrewEventsService,
    $rootScope
  ) {
    this.getAllFunctionsWithUsers = function (dateFilter) {
      var deferred = $q.defer();
      $q.all([
        CrewUsersService.getAll(),
        CrewFunctionsService.getAll(),
        CrewUsersFunctionsService.getAll(),
        CrewEventsService.getAll(dateFilter),
      ])
        .then(function (results) {
          console.log(results);
          var users = results[0];
          var functions = results[1];
          var usersFunctions = results[2];
          var events = results[3];
          _.each(users, function (user) {
            user.events = _.map(
              _.filter(events, function (event) {
                return _.some(event.users, { objectId: user.objectId });
              }),
              function (event) {
                var userEvent = _.clone(event);
                delete userEvent.users;
                userEvent.lastSituation = _.find(event.users, { objectId: user.objectId }).lastSituation;
                return userEvent;
              }
            );
          });

          if (dateFilter) {
            // only users with events
            _.remove(users, function (user) {
              return !user.events || user.events.length === 0;
            });
          }

          _.each(functions, function (func) {
            func.users = _.filter(users, function (user) {
              return _.find(usersFunctions, { code: func.code, userId: user.userId });
            });
          });
          deferred.resolve(functions);
        })
        .catch(function (error) {
          deferred.reject(error);
        });
      return deferred.promise;
    };

    //region Pool functions

    this.fetchAllFunctions = function () {
      var deferred = $q.defer();

      this.getAllFunctionsWithUsers($rootScope.crewEventFilter).then(function (functions) {
        $rootScope.$broadcast('crewPoolService-functions', functions);
        deferred.resolve(functions);
      }, deferred.reject);

      return deferred.promise;
    };

    //endregion
  });
