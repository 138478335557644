export class HolVacationRef {
  vacationId?: string;
  functionId?: string;
  checklistsRef?: HolVacationChecklistRef[];
  checklistItemsRef?: HolVacationChecklistItemRef[];
  modules?: string[];

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.vacationId = parseObject.get('vacationId');
    this.functionId = parseObject.get('functionId');
    this.modules = parseObject.get('modules') ? parseObject.get('modules').split('|') : [];
  }
}

export class HolVacationChecklistRef {
  vacationId?: string;
  vacationChecklistId?: string;
  dueDate?: string;
  title?: string;
  checklistItemsRef: HolVacationChecklistItemRef[];

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.vacationId = parseObject.get('vacationId');
    this.vacationChecklistId = parseObject.get('vacationChecklistId');
    this.dueDate = parseObject.get('dueDate');
    this.title = parseObject.get('title');
  }
}

export class HolVacationChecklistItemRef {
  vacationChecklistId?: string;
  label?: string;
  order: number;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.vacationChecklistId = parseObject.get('vacationChecklistId');
    this.label = parseObject.get('label');
    this.order = parseObject.get('order');
  }
}
