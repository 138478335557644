<div class="hol-dialog-wrapper vacation-checklist-item-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <span>{{ modalTitle }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content>
    <p>{{ item.label }}</p>
    <div class="hol-dialog-section">
      <app-file-and-image-upload [attachments]="item.attachments" [context]="context" [disabled]="isReadOnly"></app-file-and-image-upload>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isReadOnly" align="end">
    <button class="hol-validate-btn-secondary" mat-raised-button (click)="save()">
      {{ 'DASHBOARD.MODAL.UPDATE_BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
</div>
