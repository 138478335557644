export class OclHelperLink{
  objectId: string;
  name: string;
  link: string;
  
  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.name = parseObject.get('name');
    this.link = parseObject.get('link');
  }
}
