import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolTag } from '../models/hol-tag';
import { HolCrisis } from '../models/hol-crisis';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class TagsService {
  public _tags: HolTag[];
  // tslint:disable-next-line:variable-name
  protected abstract ParseTag;

  public constructor(protected requestService: RequestService) {}

  public getAll(force?: boolean): Promise<HolTag[]> {
    if (this._tags && !force) {
      return Promise.resolve(this._tags);
    } else {
      const query = new Parse.Query(this.ParseTag);
      this.setAdditionalFieldsToFilter(query, null);
      // query.limit(1000);
      query.ascending('name');
      return this.requestService.performFindAllQuery(query).then(parseTags => {
        this._tags = parseTags.map(parseTag => new HolTag(parseTag));
        return this._tags;
      });
    }
  }

  public getAllDataFromTable(tableName: string): Observable<HolTag[]> {
    const query = new Parse.Query(tableName);
    query.includeAll();
    return from(
      this.requestService.performFindAllQuery(query).then(parseTags => {
        return parseTags.map(parseTag => new HolTag(parseTag));
      }) as Promise<HolTag[]>,
    );
  }

  public create(color: string, name: string, crisis: HolCrisis, withRefresh: boolean = true): Promise<HolTag> {
    const dataToCreate = new this.ParseTag({ color, name });
    dataToCreate.setACL(crisis.acl);
    this.setAdditionalFields(crisis, dataToCreate);
    return this.requestService.performSaveQuery(dataToCreate).then(parseTag => {
      const newTag = new HolTag(parseTag);
      if (withRefresh) {
        this._tags.push(newTag);
      }
      return newTag;
    });
  }

  public async createTagFromAdmin(color: string, name: string, acl: Parse.ACL, table: string): Promise<HolTag> {
    if (!color || !name || !acl || !table) {
      throw new Error('Invalid input parameters');
    }
    const tableName = Parse.Object.extend(table);

    //  const dataToCreate = new tableName({color, name});
    let dataToCreate = new tableName();
    dataToCreate.set('color', color);
    dataToCreate.set('name', name);
    dataToCreate.setACL(acl);

    const value = await this.requestService.performSaveQuery(dataToCreate);
    return new HolTag(value);
  }

  public async updateTagFromAdmin(objectId: string, color: string, name: string, table: string, acl: Parse.ACL): Promise<HolTag> {
    const tableName = Parse.Object.extend(table);

    let objectToUpdate = new tableName({ objectId: objectId });
    objectToUpdate.set('color', color);
    objectToUpdate.set('name', name);
    objectToUpdate.setACL(acl);

    const value = await this.requestService.performSaveQuery(objectToUpdate);
    return new HolTag(value);
  }

  public deleteTagFromAdmin(objectId: string, table: string): Promise<void> {
    const Table = Parse.Object.extend(table);
    return this.requestService.performDestroyQuery(new Table({ objectId: objectId }));
  }

  protected setAdditionalFieldsToFilter(parseTag: Parse.Query, crisis: HolCrisis) {}

  protected setAdditionalFields(tag: HolCrisis, parseTag: Parse.Object) {}
}
