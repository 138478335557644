import { Injectable } from '@angular/core';
import { TaskTagService } from '../../common/services/task-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OccTaskTagService extends TaskTagService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('OCCTag');
  protected ParseTaskTag = Parse.Object.extend('OCCTaskTag');
  // tslint:enabled
}
