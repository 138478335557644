angular.module('gestiondecriseApp').directive('situationItem', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/situationItem/situationItem.html',
    scope: {
      situation: '=',
      isSelected: '=?',
    },
    controller: function ($scope, $mdDialog, CrewSituationsService) {
      init();

      $scope.newData = null;

      function init() {
        $scope.newData = {
          statusText: $scope.situation.statusText,
          statusDate: $scope.situation.statusDate,
        };
      }

      $scope.$watch('isSelected', function () {
        init();
      });

      $scope.getImpactLength = function (item) {
        if (item.impactDate && item.validityDate) {
          return moment(item.validityDate).diff(item.impactDate, 'day') + 1 + ' jours';
        }
        return '';
      };

      //Archiving a situation
      /*$scope.closeSituation = function (ev) {
          var holConfirm = $mdDialog.holConfirm({
            title: 'Attention',
            htmlContent: '<p>Êtes-vous sûr de vouloir archiver la situation?</p>',
            targetEvent: ev,
            skipHide: true,
            hasBackdrop: false
          });
          $mdDialog.show(holConfirm).then(function (result) {
            if (result === true) {
              if ($scope.situation.objectId) {

                //only modify when archived or saved
                $scope.situation.statusText = $scope.newData.statusText;
                $scope.situation.statusDate = $scope.newData.statusDate;

                CrewSituationsService.close($scope.situation).then(function (newSituation) {
                  $scope.$root.$broadcast('crew-situation-updated', newSituation);
                });
              }
            }
          });

        };*/

      $scope.closeSituation = function (ev) {
        $scope.situation.statusDate = $scope.newData.statusDate;
        $scope.situation.statusText = $scope.newData.statusText;

        $mdDialog.show({
          controller: 'CrewSituationCloseModalCtrl',
          templateUrl: 'views/crew/modals/situation-close.modal.html',
          clickOutsideToClose: true,
          targetEvent: ev,
          skipHide: true,
          hasBackDrop: false,
          locals: {
            situations: $scope.situations,
            situation: $scope.situation,
          },
        });
      };

      $scope.updateSituation = function () {
        $scope.situation.statusText = $scope.newData.statusText;
        $scope.situation.statusDate = $scope.newData.statusDate;

        CrewSituationsService.updateStatus($scope.situation, true).then(function (newSituation) {
          $scope.$root.$broadcast('crew-situation-updated', newSituation, true);
        });
      };

      $scope.isDataChanged = function () {
        if ($scope.situation.statusText === $scope.newData.statusText && $scope.situation.statusDate === $scope.newData.statusDate) {
          return false;
        }

        return true;
      };
    },
  };
});
