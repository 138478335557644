<div class="file-image-display-full">
  <a *ngIf="isNote" class="external-attachment" href="{{ url }}" target="_blank" title="{{ name }}">
    <span>
      <i *ngIf="isPdf" class="icon icon-{{ icon }}"></i>
      <span>{{ 'COMMON.CONSULT' | translate }}</span>
    </span>
    <figure *ngIf="icon === 'picture'">
      <img [src]="url" />
    </figure>
  </a>
  <a *ngIf="!isNote" class="external-attachment" href="{{ url }}" target="_blank" title="{{ name }}">
    <span>
      <i *ngIf="isPdf" class="icon icon-{{ icon }}"></i>
      <span *ngIf="title">{{ title }}</span>
      <span *ngIf="!title">{{ 'COMMON.CONSULT' | translate }}</span>
    </span>
    <figure *ngIf="icon === 'picture'">
      <img [src]="url" />
    </figure>
  </a>
</div>
