import { GocDecision } from './goc-decision.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { FltFlightInstruction } from '../../flt/models/flt-flight-instruction';

export class GocFlightInstruction extends FltFlightInstruction {
  decision: GocDecision;
  isPrivate: boolean;
  fromOCC?: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    if (parseObject && parseObject.get('decision')) {
      this.decision = new GocDecision(parseObject.get('decision'));
    }
    this.isPrivate = parseObject.get('isPrivate');
  }

  // toLog(): GocLog {
  //   const log = new GocLog();
  //   log.attachments = this.attachments;
  //   log.comment = this.message
  //   log.type = 'instruction';
  //   log.jsonObject = this;

  //   return log;
  // }
}
