import { OclEvent } from '../../ocl/models/ocl-event.model';
import { HolTag } from '../../common/models/hol-tag';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import { EclFunction } from './ecl-function';
import { EclCrisis } from './ecl-crisis';

export class EclEvent extends OclEvent {
  public function: EclFunction;
  public crisis: EclCrisis;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], infos?: HolNextInfo[], scenariosFromOtherModule?: Parse.Object[]) {
    super(parseObject, tags, infos, scenariosFromOtherModule);
    if (!parseObject) {
      return;
    }
    this.function = new EclFunction(parseObject.get('function'));
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }
}
