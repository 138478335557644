import { Inject, Injectable } from '@angular/core';
import { OclSmsService } from '../../../ocl/services/ocl-sms-service/ocl-sms.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';
import { SmsSenderService } from 'src/app/common/services/sms/sms-sender.service';

@Injectable({
  providedIn: 'root',
})
export class GocSmsService extends OclSmsService {
  constructor(
    protected readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('$translate') protected $translate,
    protected moduleConfig: GocModuleConfigService,
  ) {
    super(smsSenderService, CONSTANTS, $translate, moduleConfig);

    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.$translate.instant('SMS.GOC.SENDER_SUFFIX') || 'GOC';
    });
  }
}
