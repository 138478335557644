import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';
import { OclChecklistFunction } from '../../../ocl/store/ocl-tasks.selectors';

@Pipe({
  name: 'filterChecklistEvent',
})
export class FilterChecklistEventPipe implements PipeTransform {
  public transform(checklistsGroupByFunction: OclChecklistFunction[], selectedEventId?: string, filterStatus?: string[]): any {
    if (!selectedEventId && !filterStatus.length) {
      return checklistsGroupByFunction;
    }
    const bufferChecklistsGroupByFunction = cloneDeep(checklistsGroupByFunction);
    if (!selectedEventId && !!filterStatus.length) {
      return this.filterScenarioByStatus(bufferChecklistsGroupByFunction, filterStatus);
    }
    const dataFiltered = bufferChecklistsGroupByFunction.map(checklistGroupByFunction => {
      checklistGroupByFunction.events = checklistGroupByFunction.events.filter(e => e.event && e.event.objectId === selectedEventId);
      return checklistGroupByFunction;
    });
    if (!filterStatus.length) {
      return dataFiltered;
    }
    if (!!filterStatus.length) {
      return this.filterScenarioByStatus(dataFiltered, filterStatus);
    }
  }

  public filterScenarioByStatus(arr: OclChecklistFunction[], filterStatus): any[] {
    return (
      arr &&
      arr.map(group => {
        group.events = cloneDeep(group.events).map(event => {
          event.tasks = cloneDeep(event.tasks).filter(task => {
            if (filterStatus.findIndex(el => el === task.status) !== -1) {
              return task;
            }
          });
          return event;
        });
        return group;
      })
    );
  }
}
