import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-and-image-icons-display',
  templateUrl: './file-and-image-icons-display.component.html',
  styleUrls: ['./file-and-image-icons-display.component.scss'],
})
export class FileAndImageIconsDisplayComponent {
  @Input() attachments;
}
