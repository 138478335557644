'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewOptionsService
 * @description
 * # CrewOptionsService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewOptionsService', function ($q, RequestService) {
  var CrewOptions = Parse.Object.extend('CrewOptions');

  var options = null; // @cache : options crew

  // @hasCache options
  this.get = function () {
    var deferred = $q.defer();

    if (options !== null) {
      deferred.resolve(options);
    } else {
      var query = new Parse.Query(CrewOptions);
      RequestService.performFirstQuery(
        query,
        function (result) {
          if (result) {
            options = {
              filters: [
                { threshold: result.get('filter1') + 1 },
                { threshold: result.get('filter2') + 1 },
                { threshold: result.get('filter3') + 1 },
                { threshold: result.get('filter4') + 1 },
              ],
              todoAdditionalDaysToDisplay: result.get('todoAdditionalDaysToDisplay'),
              historyTimeSlot: result.get('historyTimeSlot'),
              tirednessThresholds: [
                result.get('tirednessThreshold1'),
                result.get('tirednessThreshold2'),
                result.get('tirednessThreshold3'),
              ],
              lastArchivedDoneEventsAt: result.get('lastArchivedDoneEventsAt'),
              dayTodoAcceptation: result.get('dayTodoAcceptation'),
            };
          }
          deferred.resolve(options);
        },
        function (error) {
          deferred.reject(options, error);
        }
      );
    }

    return deferred.promise;
  };

  this.getFilters = function () {
    if (options && options.filters) {
      if (
        !_.find(options.filters, function (filter) {
          return filter.threshold === 1;
        })
      ) {
        var filters = [{ threshold: 1 }];
        filters = filters.concat(options.filters);
        options.filters = filters;
      }
    }

    return options && options.filters;
  };

  this.getTodoAdditionalDaysToDisplay = function () {
    return options && options.todoAdditionalDaysToDisplay;
  };

  this.getHistoryTimeSlot = function () {
    return options && options.historyTimeSlot;
  };

  this.getTirednessThresholds = function () {
    return options && options.tirednessThresholds;
  };

  this.getLastArchivedDoneEventsAt = function () {
    return options && options.lastArchivedDoneEventsAt;
  };

  this.getdayTodoAcceptation = function () {
    return options && options.dayTodoAcceptation;
  };

  this.setLastArchivedDoneEventsAt = function (lastDate) {
    var deferred = $q.defer();
    var query = new Parse.Query(CrewOptions);

    RequestService.performFindQuery(
      query,
      function (crewOptions) {
        //only one item should exists
        var option = crewOptions[0].set('lastArchivedDoneEventsAt', lastDate);
        RequestService.performSaveQuery(
          option,
          null,
          function (updatedCrewOption) {
            deferred.resolve(updatedCrewOption);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      function (error) {
        deferred.reject(error);
      }
    );

    return deferred.promise;
  };
});
