import { HolUser } from '../../common/models/hol-user.model';
import { environment as env } from '@env/environment';
import { HolUserWithFunctions } from '../../erp/services/erp-users.service';

export function isUserActivated(user: HolUser): boolean {
  return user && user.lastSeenAt ? new Date().getTime() - user.lastSeenAt.getTime() < 1000 * env.userConnectedStatusMaxTime : false; // 5min
}

export function isUserActivatedOffline(user: HolUser): boolean {
  return user && user.isAvailableOffline && !isUserActivated(user);
}

export function isUserDeactivated(user: HolUser): boolean {
  return !(user && user.isAvailableOffline) && !isUserActivated(user);
}

export function mergeDirectorsAndUsers(directors: HolUserWithFunctions[], users: HolUserWithFunctions[]): HolUserWithFunctions[] {
  const map = new Map<string, HolUserWithFunctions>(directors.map(dir => [dir.userId, dir]));
  for (const user of users) {
    const existingUser = map.get(user.userId);
    if (existingUser) {
      map.set(user.userId, {
        ...existingUser,
        functions: [...existingUser.functions, ...user.functions],
      });
    } else {
      map.set(user.userId, user);
    }
  }
  return [...map.values()];
}
