import { HolHistoryLog } from './../models/hol-history-log.model';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class HistoryService<T extends HolHistoryLog = HolHistoryLog> {
  // tslint:disable-next-line:variable-name
  protected abstract ParseHistory;
  logs: Subject<T> = new Subject<T>();

  protected constructor(protected requestService: RequestService) {}

  getHistoryByObjectIdAndType(objectId: string, type: string): Promise<HolHistoryLog[]> {
    return Promise.all([]);
  }

  abstract postLog(log: T, ...params);

  deleteAllTaskLogs() {}

  getLogsByTimeSlots() {}
}
