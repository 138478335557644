import { HolFlight } from '../../common/models/hol-flight.model';
import { OpsFlightLogbook } from './ops-flight-logbook.model';
import { OpsFlightInstruction } from './ops-flight-instruction.model';
import { OpsOfficer } from './ops.officer.model';
import { HolTag } from '../../common/models/hol-tag';

export class OpsFlight extends HolFlight<OpsFlightLogbook, OpsFlightInstruction> {
  mostCriticalInstructionTime: Date;
  hasUncheckedInstructions: boolean;
  flightType: string;
  crewNbPnt: number;
  crewNbPnc: number;
  configuration: string;
  owner: string;
  legVipComments: string;
  crewBriefing: any;
  naturalKey: string;
  fuelEco: string;
  fuelPolicy: string;
  loadForecast: string;
  opsOfficer: OpsOfficer;
  arrAptCategory: string;
  depAptCategory: string;
  primaryArrAlternateAptIataCode: string;
  arrAlternateAptIataCode: string;
  primaryArrAlternateAptIcaoCode: string;
  arrAlternateAptIcaoCode: string;
  depAptRunway: string;
  arrAptRunway: string;
  takeoffEstimatedWeight: string;
  landingEstimatedWeight: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.crewBriefing = parseObject.get('crewBriefing') ? JSON.parse(parseObject.get('crewBriefing')) : {};
    this.flightType = parseObject.get('flightType');
    this.crewNbPnt = parseObject.get('crewNbPnt');
    this.crewNbPnc = parseObject.get('crewNbPnc');
    this.configuration = parseObject.get('configuration');
    this.owner = parseObject.get('owner');
    this.legVipComments = parseObject.get('legVipComments');
    this.naturalKey = parseObject.get('naturalKey');
    this.fuelEco = parseObject.get('fuelEco');
    this.fuelPolicy = parseObject.get('fuelPolicy');
    this.loadForecast = parseObject.get('loadForecast');
    this.opsOfficer = parseObject.get('opsOfficer') ? new OpsOfficer(parseObject.get('opsOfficer')) : undefined;
    this.arrAptCategory = parseObject.get('arrAptCategory');
    this.depAptCategory = parseObject.get('depAptCategory');
    this.primaryArrAlternateAptIataCode = parseObject.get('primaryArrAlternateAptIataCode');
    this.arrAlternateAptIataCode = parseObject.get('arrAlternateAptIataCode');
    this.primaryArrAlternateAptIcaoCode = parseObject.get('primaryArrAlternateAptIcaoCode');
    this.arrAlternateAptIcaoCode = parseObject.get('arrAlternateAptIcaoCode');
    this.depAptRunway = parseObject.get('depAptRunway');
    this.arrAptRunway = parseObject.get('arrAptRunway');
    this.takeoffEstimatedWeight = parseObject.get('takeoffEstimatedWeight');
    this.landingEstimatedWeight = parseObject.get('landingEstimatedWeight');
  }
}
