<img
  *ngIf="attachments?.note?.length > 0 && !attachments?.noteFile"
  [title]="attachments?.note"
  class="icon"
  src="/assets/images/note.svg"
/>
<app-file-and-image-display *ngIf="attachments?.link" [url]="attachments.link.url" icon="hol-icon-link"> </app-file-and-image-display>
<app-file-and-image-display *ngIf="attachments?.noteFile" [icon]="'hol-icon-document'" [url]="attachments?.noteFile?.url">
</app-file-and-image-display>
<app-file-and-image-display *ngIf="attachments?.file" [url]="attachments.file.url" icon="hol-icon-file"> </app-file-and-image-display>
<app-file-and-image-display
  *ngIf="attachments?.files?.length >= 1"
  [url]="(attachments?.files)[0]?.url"
  [count]="attachments.files.length"
  icon="hol-icon-file"
>
</app-file-and-image-display>
<app-file-and-image-display *ngIf="attachments?.image" [url]="attachments.image.url" icon="hol-icon-picture"> </app-file-and-image-display>
