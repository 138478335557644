(function () {
  'use strict';

  angular.module('gestiondecriseApp').controller('CrewChooseFunctionModalCtrl', function ($scope, user, $mdDialog) {
    $scope.user = user;

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.chooseFunction = function (f) {
      $mdDialog.hide(f);
    };
  });
})();
