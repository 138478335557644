import { Injectable } from '@angular/core';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RequestService } from 'src/app/common/services/request.service';
import { UserService } from 'src/app/common/services/user.service';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpHistoryService } from './erp-history.service';
import { ErpMailService } from './erp-mail.service';
import { ErpSmsService } from './erp-sms.service';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisAnnouncementService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseCrisisAnnouncement = Parse.Object.extend('GDCAnnouncement');
  ParseGDCTag = Parse.Object.extend('GDCTag');
  ParseUser = Parse.Object.extend('_User');
  // tslint:enabled

  constructor(
    private userService: UserService,
    private crisisStoreManager: CrisisStoreManager,
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private erpMailService: ErpMailService,
    private erpSmsService: ErpSmsService,
    private historyService: ErpHistoryService,
  ) {}

  getCrisisAnnouncementByCrisisObjectId(crisisObjectId): Promise<ErpCrisisAnnouncement[]> {
    let crisisAnnouncements: ErpCrisisAnnouncement[];
    return new Promise((resolve, reject) => {
      const crisis = new this.ParseCrisis();
      crisis.set('objectId', crisisObjectId);
      const crisisAnnouncementQuery = new Parse.Query(this.ParseCrisisAnnouncement);
      crisisAnnouncementQuery.include('ACL');
      crisisAnnouncementQuery.descending('createdAt');
      crisisAnnouncementQuery.equalTo('crisis', crisis);
      crisisAnnouncementQuery.include('createdBy');
      crisisAnnouncementQuery.include('tags');
      this.requestService.performFindQuery(
        crisisAnnouncementQuery,
        crisisAnnouncementsFromApi => {
          if (crisisAnnouncementsFromApi) {
            // tslint:disable-next-line: max-line-length
            crisisAnnouncements = crisisAnnouncementsFromApi.map(
              crisisAnnouncementFromApi => new ErpCrisisAnnouncement(crisisAnnouncementFromApi),
            );
            resolve(crisisAnnouncements);
          } else {
            resolve([]);
          }
        },
        reject,
      );
    });
  }

  // tslint:disable-next-line: max-line-length
  saveCrisisAnnouncement(
    crisisAnnouncement: Partial<ErpCrisisAnnouncement>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
  ): void {
    let parseObject;
    let isCreate = false;
    if (crisisAnnouncement.objectId) {
      parseObject = new this.ParseCrisisAnnouncement({ id: crisisAnnouncement.objectId });
    } else {
      isCreate = true;
      parseObject = new this.ParseCrisisAnnouncement();
      parseObject.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
      parseObject.set('crisis', new this.ParseCrisis({ id: crisisObjectId }));
      parseObject.set('createdByFunctionId', crisisAnnouncement.createdByFunctionId);
    }
    if (crisisAnnouncement.acl) {
      parseObject.setACL(crisisAnnouncement.acl);
    }
    if (crisisAnnouncement.message) {
      parseObject.set('message', crisisAnnouncement.message);
    }

    if (crisisAnnouncement.visibilityByFunctionId) {
      parseObject.set('visibilityByFunctionId', crisisAnnouncement.visibilityByFunctionId);
    }

    if (crisisAnnouncement.attachments) {
      parseObject.set('attachments', crisisAnnouncement.attachments);
    }

    if (crisisAnnouncement.tags && crisisAnnouncement.tags.length) {
      const parseTags = crisisAnnouncement.tags.map(tag => new this.ParseGDCTag({ id: tag.objectId }));
      parseObject.set('tags', parseTags);
    } else {
      parseObject.set('tags', []);
    }

    if (isArchiveAction) {
      parseObject.set('isArchived', true);
    }

    if (crisisAnnouncement.customCreatedAt) {
      parseObject.set('customCreatedAt', crisisAnnouncement.customCreatedAt);
    }

    this.requestService.performSaveQuery(
      parseObject,
      null,
      parseData => {
        const bufferCrisisAnnouncement: ErpCrisisAnnouncement = new ErpCrisisAnnouncement(parseData);
        if (isCreate) {
          //  this.crisisStoreManager.createOneCrisisNew(bufferCrisisAnnouncement);
          if (notifications && notifications.length) {
            const addressMailToSend: string[] = this.notificationsService.getAddressMailToSend(notifications);
            const phoneNumbersToSend: string[] = this.notificationsService.getPhoneNumbersToSend(notifications);

            if (addressMailToSend && addressMailToSend.length) {
              this.erpMailService.sendCrisisAnnouncementMail(
                bufferCrisisAnnouncement,
                this.userService.getCurrentUserObject(),
                addressMailToSend,
              );
            }

            if (phoneNumbersToSend && phoneNumbersToSend.length) {
              this.erpSmsService.sendCrisisAnnouncementSms(
                bufferCrisisAnnouncement,
                this.userService.getCurrentUserObject(),
                phoneNumbersToSend,
              );
            }
          }
          this.crisisStoreManager.createOneCrisisAnnouncement(bufferCrisisAnnouncement);
          this.historyService
            .postLog(bufferCrisisAnnouncement.toLog(historyLogComment, parseData, crisisObjectId))
            .catch(error => console.log(error));
          return;
        }
        this.crisisStoreManager.updateOneCrisisAnnouncement(bufferCrisisAnnouncement);
        this.historyService
          .postLog(bufferCrisisAnnouncement.toLog(historyLogComment, parseData, crisisObjectId))
          .catch(error => console.log(error));
      },
      error => {
        console.log(error);
      },
    );
  }

  deleteAllAnnouncements(): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseCrisisAnnouncement);

    return this.requestService.performFindQuery(query).then(results => {
      return this.requestService.performDestroyAllQuery(results);
    });
  }

  public announcementReaded(announcement: ErpCrisisAnnouncement): Promise<void> {
    const parseAnnouncement = new this.ParseCrisisAnnouncement({ id: announcement.objectId });
    parseAnnouncement.set('readed', true);
    return this.requestService.performSaveQuery(parseAnnouncement);
  }
}
