import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { OptionsService } from 'src/app/common/services/options.service';
import { ErpCrisisTask } from 'src/app/erp/models/erp-crisisTask';
import * as moment from 'moment';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';

import { HolUser } from '../../common/models/hol-user.model';
import { HelperService } from '../../common/services/helper.service';
import { ErpCrisis } from '../models/erp-crisis';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { ErpFunctionsService } from './erp-functions.service';
import { ErpUsersService, HolUserWithFunctions } from './erp-users.service';
import { map } from 'lodash';
import { MailTemplateService } from '../../common/services/mail/mail-template.service';
import { ErpMailTemplateService } from './erp-mail-template.service';
import { TranslatePipe } from '../../common/pipes/translate/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export class ErpMailService {
  constructor(
    private readonly mailSenderService: MailSenderService,
    private readonly mailTemplateService: ErpMailTemplateService,
    @Inject('CONSTANTS') private CONSTANTS,
    @Inject('$translate') private $translate,
    private translatePipe: TranslatePipe,
    private optionsService: OptionsService,
    private helperService: HelperService,
    private usersService: ErpUsersService,
    private commonStoreManager: CommonStoreManager,
    private erpFunctionsService: ErpFunctionsService,
  ) {}

  sendCrisisNewMail(crisisNew: ErpCrisisNew, mailsToSend: string[]) {
    if (!crisisNew) {
      console.warn('ErpMailService::sendcrisisNew: crisisNew empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendcrisisNew: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_NEW.SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisNewMail(crisisNew),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisAnnouncementMail(crisisAnnouncement: ErpCrisisAnnouncement, user: any, mailsToSend: string[]) {
    if (!crisisAnnouncement) {
      console.warn('ErpMailService::sendCrisisAnnouncement: crisisAnnouncement empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisAnnouncement: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_ANNOUNCEMENT.SUBJECT', { user }),
        contentHtml: this.mailTemplateService.getCrisisAnnouncementMail(crisisAnnouncement),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisTaskDoneMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_DONE.SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisTaskDoneMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisTaskFrozenMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_FROZEN.SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisTaskFrozenMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisTaskBackTodoMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_TODO.SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisTaskBackTodoMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisTaskMail(isCreate: boolean, crisisTask: ErpCrisisTask, mailsToSend: string[], functionToNotify?: string) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }
    if (isCreate && !functionToNotify) {
      console.warn('ErpMailService::sendCrisisTask: functionToNotify empty on creation');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.translatePipe.transform('MAIL.CRISIS.CRISIS_NEW_TASK.SUBJECT')
          : this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK.SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisTaskMail(isCreate, crisisTask, functionToNotify),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisDocumentMail(isCreate: boolean, crisisDocument: ErpCrisisTask, mailsToSend: string[], functionToNotify?: string) {
    if (!crisisDocument) {
      console.warn('ErpMailService::sendCrisisDocument: crisisDocument empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisDocument: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.NEW_ENTRY_SUBJECT')
          : this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.UPDATE_ENTRY_SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisDocumentMail(isCreate, crisisDocument, functionToNotify),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendCrisisDecisionMail(isCreate: boolean, crisisDecision: ErpCrisisDecision, mailsToSend: string[]) {
    if (!crisisDecision) {
      console.warn('ErpMailService::sendCrisisDecisionMail: crisisDecision empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisDecisionMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.translatePipe.transform('MAIL.CRISIS.CRISIS_DECISION.NEW_ENTRY_SUBJECT')
          : this.translatePipe.transform('MAIL.CRISIS.CRISIS_DECISION.UPDATE_ENTRY_SUBJECT'),
        contentHtml: this.mailTemplateService.getCrisisDecisionMail(isCreate, crisisDecision),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true,
    );
  }

  sendNewCrisisInPreparationEmails(crisis: ErpCrisis) {
    Promise.all([
      this.usersService.getAllCrisisDirectorsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(([crisisDirectorsToNotify, currentUser]) => {
      this.mailSenderService.sendMail(
        {
          recipients: map(crisisDirectorsToNotify, cd => {
            return { email: cd.email, fields: { '<USERNAME>': cd.username } };
          }),
          subject: crisis.isTraining
            ? this.translatePipe.transform('MAIL.CRISIS.EXERCICE_HEADER')
            : this.translatePipe.transform('MAIL.CRISIS.NOT_EXERCICE_HEADER'),
          contentHtml:
            this.translatePipe.transform('MAIL.CRISIS.CRISIS_PREPARED') +
            '<br/><br/>' +
            this.translatePipe.transform('MAIL.CRISIS.NEW_CRISIS_PREPARED', {
              crisisCreatorName: currentUser.fullName,
              crisisMainTitle: crisis.mainTitle,
              crisisSubtitle: crisis.subTitle,
              crisisDirectorShortTitle: this.optionsService.getCrisisDirectorShortTitlesList().join(', '),
            }) +
            '<br/><br/>' +
            this.translatePipe.transform('MAIL.CRISIS.PLEASE_LOGIN', {
              appUrl: location.origin,
            }) +
            '<br/><br/>' +
            this.translatePipe.transform('MAIL.CRISIS.CONTACT_CREATOR', {
              crisisCreatorName: currentUser.fullName,
              crisisCreatorPhone: currentUser.phone || '',
              crisisCreatorEmail: currentUser.email || '',
            }) +
            '<br/><br/>' +
            this.translatePipe.transform('MAIL.CRISIS.ERP_MARKETING') +
            '<br/><br/>' +
            this.translatePipe.transform('MAIL.CRISIS.USE_IT') +
            '<br/><br/>',
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      );
    });
  }

  sendNewCrisisEmails(crisis: ErpCrisis, functionsIdToNotify?: string[], usersToNotify?: HolUser[], companies?: string[]) {
    return Promise.all([
      this.usersService.getUsersWithFunctionsForCrisis(crisis, functionsIdToNotify, usersToNotify, companies),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(([usersWithFunctions, currentUser]) => {
      let sendedNewEmail = 0;
      for (const user of usersWithFunctions.users) {
        this.sendNewCrisisEmailToUser(crisis, user, currentUser);
        sendedNewEmail += 1;
      }
      if (sendedNewEmail === usersWithFunctions.users.length) {
        return this.erpFunctionsService.markAsNotified(usersWithFunctions.functionsIdsNotified);
      }
    });
  }

  private sendNewCrisisEmailToUser(crisis: ErpCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    if (user.email) {
      const msg = this.mailTemplateService.getNewCrisisMessage(crisis, user, crisisCreator);
      const subject = crisis.isTraining
        ? this.translatePipe.transform('MAIL.CRISIS.EXERCICE_HEADER')
        : this.translatePipe.transform('MAIL.CRISIS.NOT_EXERCICE_HEADER');
      this.mailSenderService.sendMail(
        {
          recipients: [{ email: user.email }],
          subject,
          contentHtml: msg,
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      );
    }
  }

  sendCrisisOverMail(crisis: ErpCrisis) {
    this.usersService.getUsersToSendMail().then(usersToNotify => {
      const companies = this.helperService.parseACL(crisis.acl);
      usersToNotify = usersToNotify.filter(d => !!d.companies.find(c => companies.includes(c.name)));

      this.mailSenderService.sendMail(
        {
          recipients: map(usersToNotify, user => {
            return { email: user.email };
          }),
          subject: this.translatePipe.transform('MAIL.CRISIS.CRISIS_OVER.SUBJECT'),
          contentText: this.translatePipe.transform('MAIL.CRISIS.CRISIS_OVER.CONTENT_TEXT'),
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true,
      );
    });
  }
}
