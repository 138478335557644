<div class="new-holis-theme">
  <button
    type="button"
    [disabled]="disabled"
    (click)="openMarkdownEditor()"
    class="hol-attachment-btn"
    mat-stroked-button
    color="primary"
    [ngClass]="{ disabled: disabled, 'attachment-btn': column }"
  >
    <span class="hol-icon-document"></span>
    <span class="btn-label">{{ label }}</span>
    <i (click)="removeNote($event)" *ngIf="attachment && !disabled" class="icon icon-quit"></i>
  </button>
</div>
