import { ErpHistoryLog } from './../../models/erp-historyLog';
import { Action } from '@ngrx/store';
import { ErpCrisis } from '../../models/erp-crisis';
import { ErpCrisisNew } from '../../models/erp-crisisNew';
import { ErpCrisisTask } from '../../models/erp-crisisTask';
import { ErpCrisisData } from '../../models/erp-crisisData';
import { ErpCrisisAnnouncement } from '../../models/erp-crisisAnnouncement';
import { ErpCrisisDecision } from '../../models/erp-crisisDecision';

export enum CrisisActionTypes {
  INIT_CRISIS = '[CRISIS] Init Crisis',
  RESET_CRISIS = '[CRISIS] Reset Crisis',
  UPDATE_CRISIS = '[CRISIS] Update Crisis',
  UPDATE_CRISIS_FROM_POOLING = '[CRISIS] Update Crisis From Pooling',
  UPDATE_ONE_CRISIS_NEW = '[CRISIS] Update One Crisis New',
  UPDATE_ONE_CRISIS_TASK = '[CRISIS] Update One Crisis Task',
  UPDATE_ONE_CRISIS_ANNOUNCEMENT = '[CRISIS] Update One Crisis Announcement',
  UPDATE_ONE_CRISIS_DATA = '[CRISIS] Update One Crisis Data',
  UPDATE_MANY_CRISIS_DATA = '[CRISIS] Update Many Crisis Data',
  CREATE_ONE_CRISIS_NEW = '[CRISIS] Create One Crisis New',
  CREATE_ONE_CRISIS_TASK = '[CRISIS] Create One Crisis Task',
  CREATE_ONE_CRISIS_ANNOUNCEMENT = '[CRISIS] Create One Crisis Announcement',
  CREATE_ONE_CRISIS_DATA = '[CRISIS] Create One Crisis Data',
  CREATE_MANY_CRISIS_DATA = '[CRISIS] Create Many Crisis Data',
  CREATE_MANY_CRISIS_TASKS = '[CRISIS] Create Many Crisis Tasks',
  REMOVE_ONE_CRISIS_NEW = '[CRISIS] Remove One Crisis New',
  DELETE_ONE_CRISIS_DATA = '[CRISIS] Delete One Crisis Data',
  REMOVE_ONE_CRISIS_TASK = '[CRISIS] Remove One Crisis Task',
  CREATE_ONE_CRISIS_HISTORY_LOG = '[CRISIS] Create One Crisis History Log',
  CREATE_ONE_CRISIS_DECISION = '[CRISIS] Create One Crisis Decision',
  UPDATE_ONE_CRISIS_DECISION = '[CRISIS] Update One Crisis Decision',
  REMOVE_ONE_CRISIS_DECISION = '[CRISIS] Remove One Crisis Decision',
}

export class InitCrisis implements Action {
  readonly type = CrisisActionTypes.INIT_CRISIS;

  constructor(public payload: Partial<ErpCrisis>) {}
}

export class ResetCrisis implements Action {
  readonly type = CrisisActionTypes.RESET_CRISIS;
}

export class UpdateCrisis implements Action {
  readonly type = CrisisActionTypes.UPDATE_CRISIS;

  constructor(public payload: Partial<ErpCrisis>) {}
}

export class UpdateCrisisFromPooling implements Action {
  readonly type = CrisisActionTypes.UPDATE_CRISIS_FROM_POOLING;

  constructor(public payload: Partial<ErpCrisis>) {}
}

export class UpdateOneCrisisNew implements Action {
  readonly type = CrisisActionTypes.UPDATE_ONE_CRISIS_NEW;

  constructor(public crisisNew: ErpCrisisNew) {}
}

export class UpdateOneCrisisTask implements Action {
  readonly type = CrisisActionTypes.UPDATE_ONE_CRISIS_TASK;

  constructor(public crisisTask: ErpCrisisTask) {}
}

export class UpdateOneCrisisAnnouncement implements Action {
  readonly type = CrisisActionTypes.UPDATE_ONE_CRISIS_ANNOUNCEMENT;

  constructor(public crisisAnnouncement: ErpCrisisAnnouncement) {}
}

export class UpdateOneCrisisData implements Action {
  readonly type = CrisisActionTypes.UPDATE_ONE_CRISIS_DATA;

  constructor(public crisisData: ErpCrisisData) {}
}

export class UpdateOneCrisisDecision implements Action {
  readonly type = CrisisActionTypes.UPDATE_ONE_CRISIS_DECISION;

  constructor(public crisisDecision: ErpCrisisDecision) {}
}

export class UpdateManyCrisisData implements Action {
  readonly type = CrisisActionTypes.UPDATE_MANY_CRISIS_DATA;

  constructor(public crisisDataArray: ErpCrisisData[]) {}
}

export class CreateOneCrisisNew implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_NEW;

  constructor(public crisisNew: ErpCrisisNew) {}
}

export class CreateOneCrisisTask implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_TASK;

  constructor(public crisisTask: ErpCrisisTask) {}
}

export class CreateOneCrisisData implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_DATA;

  constructor(public crisisData: ErpCrisisData) {}
}

export class CreateOneCrisisAnnouncement implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_ANNOUNCEMENT;

  constructor(public crisisAnnouncement: ErpCrisisAnnouncement) {}
}

export class CreateOneCrisisHistoryLog implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_HISTORY_LOG;

  constructor(public crisisHistoryLog: ErpHistoryLog) {}
}

export class CreateOneCrisisDecision implements Action {
  readonly type = CrisisActionTypes.CREATE_ONE_CRISIS_DECISION;

  constructor(public crisisDecision: ErpCrisisDecision) {}
}

export class CreateManyCrisisData implements Action {
  readonly type = CrisisActionTypes.CREATE_MANY_CRISIS_DATA;

  constructor(public crisisDataArray: ErpCrisisData[]) {}
}

export class CreateManyCrisisTasks implements Action {
  readonly type = CrisisActionTypes.CREATE_MANY_CRISIS_TASKS;

  constructor(public crisisDataArray: ErpCrisisTask[]) {}
}

export class RemoveOneCrisisNew implements Action {
  readonly type = CrisisActionTypes.REMOVE_ONE_CRISIS_NEW;

  constructor(public crisisNewObjectId: string) {}
}

export class DeleteOneCrisisData implements Action {
  readonly type = CrisisActionTypes.DELETE_ONE_CRISIS_DATA;

  constructor(public crisisDataObjectId: string) {}
}

export class RemoveOneCrisisTask implements Action {
  readonly type = CrisisActionTypes.REMOVE_ONE_CRISIS_TASK;

  constructor(public crisisTaskObjectId: string) {}
}

export class RemoveOneCrisisDecision implements Action {
  readonly type = CrisisActionTypes.REMOVE_ONE_CRISIS_DECISION;

  constructor(public crisisDecisionObjectId: string) {}
}

export type CrisisActions =
  | InitCrisis
  | ResetCrisis
  | UpdateCrisis
  | UpdateCrisisFromPooling
  | UpdateOneCrisisNew
  | UpdateOneCrisisTask
  | UpdateOneCrisisAnnouncement
  | UpdateOneCrisisData
  | UpdateManyCrisisData
  | CreateOneCrisisNew
  | CreateOneCrisisTask
  | CreateOneCrisisData
  | CreateOneCrisisAnnouncement
  | CreateManyCrisisData
  | CreateManyCrisisTasks
  | RemoveOneCrisisNew
  | DeleteOneCrisisData
  | RemoveOneCrisisTask
  | CreateOneCrisisHistoryLog
  | UpdateOneCrisisDecision
  | CreateOneCrisisDecision
  | RemoveOneCrisisDecision;
