import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclDecisionTagService } from '../../../ocl/services/ocl-decision-tag-service/ocl-decision-tag.service';

@Injectable({
  providedIn: 'root',
})
export class EclDecisionTagService extends OclDecisionTagService {
  // tslint:disable:variable-name
  protected ParseDecisionTag = Parse.Object.extend('ECLDecisionTag');
  protected ParseDecision = Parse.Object.extend('ECLDecisions');
  protected ParseTag = Parse.Object.extend('ECLTag');
  // tslint:enabled
}
