import { ErpHistoryLog } from './erp-historyLog';
import { HolObject } from 'src/app/common/models/hol-object';
import { HolTag } from 'src/app/common/models/hol-tag';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { ErpCrisis } from './erp-crisis';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';

export class ErpCrisisNew extends HolObject {
  objectId?: string;
  content: string;
  crisisObjectId: string;
  isNotOnDashboard: boolean;
  isArchived: boolean;
  tags: HolTag[];
  fromECL?: boolean;
  eclLogbook?: any;
  toECL?: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.content = parseObject.get('content');
    const crisis = new ErpCrisis(parseObject.get('crisis'));
    this.crisisObjectId = crisis.objectId;
    this.isArchived = parseObject.get('isArchived');
    this.isNotOnDashboard = parseObject.get('isNotOnDashboard');
    this.tags = parseObject.get('tags') ? parseObject.get('tags').map(tag => new HolTag(tag)) : [];
    this.fromECL = parseObject.get('fromECL');
    this.eclLogbook = parseObject.get('eclLogbook') && new ErpCrisisNew(parseObject.get('eclLogbook'));
    this.toECL = parseObject.get('toECL');

    if (parseObject && parseObject.get('eclLogbook')) {
      // this.eclLogbook = new ErpCrisisNew(parseObject.get('eclLogbook'));
      this.createdBy = new HolUser(parseObject.get('eclLogbook').get('createdBy'));
      let jsonAttachments = parseObject.get('eclLogbook').get('attachments');
      if (typeof jsonAttachments === 'string') {
        jsonAttachments = JSON.parse(jsonAttachments);
      }
      if (jsonAttachments) {
        this.attachments = new HolAttachments(
          jsonAttachments.note,
          jsonAttachments.file,
          jsonAttachments.image,
          jsonAttachments.noteFile,
          jsonAttachments.link,
          jsonAttachments.files
        );
      } else {
        this.attachments = new HolAttachments();
      }
      this.fromECL = true;
      this.toECL = false;
    }
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('eclLogbook') // if
      ? parseObject.get('eclLogbook').get('text')
      : parseObject.get('content'); // else if
  }

  toLog?(action: string, parseObject: Parse.Object, crisisObjectId): ErpHistoryLog {
    const log = new ErpHistoryLog();
    log.type = 'crisisNew';
    log.subType = action;
    log.jsonObject = this;
    log.acl = this.acl;
    log.crisisNew = parseObject;
    log.crisis = new Parse.Object('GDCCrisis', { id: crisisObjectId });
    return log;
  }
}
