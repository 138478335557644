'use strict';

angular.module('gestiondecriseApp').controller('CrewEventCloseModalCtrl', function ($scope, $mdDialog, event, CrewEventsService) {
  $scope.reason = undefined;
  $scope.reasonText = undefined;
  $scope.event = event;

  $scope.cancel = () => $mdDialog.cancel();

  $scope.validateClose = () =>
    CrewEventsService.close(event, $scope.reason, $scope.reasonText).then(() => {
      $scope.$root.$broadcast('crew-situation-updated', event);
      $mdDialog.hide();
    });
});
