import { HolNotifyFunction } from './../models/hol-notification.model';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolNotification } from '../models/hol-notification.model';
import { isEmpty } from 'lodash';
import { HolTag } from '../models/hol-tag';
import { HolTask } from '../models/hol-task';
import { HistoryService } from './history.service';
import { TagsService } from './tags.service';
import { TaskTagService } from './task-tag.service';

@Injectable({
  providedIn: 'root',
})
export abstract class TasksService {
  // tslint:disable:variable-name
  protected abstract ParseTask;
  protected abstract ParseTaskTag;
  protected abstract ParseTag;

  // tslint:enabled

  protected constructor(
    protected requestService: RequestService,
    protected historyService: HistoryService,
    protected tagService: TagsService,
    protected taskTagService: TaskTagService,
  ) {}

  save(
    task: HolTask,
    notifications: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction } = {
      notifications: [],
      functionToNotify: undefined,
    },
    historyLogComment: string,
    changeStatusNotification?: {
      canSendNotification: boolean;
      comment: string;
      type: string;
      functionTitle: string;
    },
  ): Promise<HolTask> {
    const isCreate = !task.objectId;
    const parseObject = this.taskToParseObject(task);

    return this.requestService.performSaveQuery(parseObject).then(async parseData => {
      let parseTags;
      if (task.tags) {
        if (isCreate) {
          const crisisTaskTags = task.tags.map(tag => {
            return new this.ParseTaskTag({
              task: parseData,
              tag: new this.ParseTag({ id: tag && tag.objectId }),
            });
          });
          parseTags = await this.requestService.performSaveAllQuery(crisisTaskTags);
        } else {
          parseTags = await this.taskTagService.updateTags(task);
        }
      }

      const tags = this.getTagsForTask(parseTags, parseData);
      const bufferCrisisTask = this.newTask(parseData, tags && tags.map(t => new HolTag(t.get('tag'))), task.defaultTags);

      this.afterSave(bufferCrisisTask, isCreate, notifications, historyLogComment, parseData, changeStatusNotification).then();

      return bufferCrisisTask;
    });
  }

  public getDefaultTagsForTask(tagsCodes: string[], allTags: HolTag[]): HolTag[] {
    return allTags.filter(tag => tag.name && tag.name.trim() === tagsCodes[tagsCodes.findIndex(tg => tag.name.trim() === tg.trim())]);
  }

  abstract newTask(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]): HolTask;

  abstract isAttachmentsMandatory(task: HolTask);

  abstract canChangeAcl(task: HolTask);

  protected taskToParseObject(task: HolTask): Parse.Object {
    let parseObject;
    if (task.objectId) {
      parseObject = new this.ParseTask({ id: task.objectId });
    } else {
      parseObject = new this.ParseTask();
      parseObject.set('createdBy', Parse.User.current());
    }
    if (task.acl) {
      if (isEmpty(task.acl.permissionsById)) {
        task.acl.setPublicReadAccess(true);
        task.acl.setPublicWriteAccess(true);
      }
      parseObject.setACL(task.acl);
    }
    parseObject.set('outputTitle', task.outputTitle);
    parseObject.set('code', task.code);
    parseObject.set('order', task.order);
    parseObject.set('subOrder', task.subOrder);
    parseObject.set('status', task.status);
    parseObject.set('functionId', task.functionId);
    parseObject.set('updatedBy', Parse.User.current());
    parseObject.set('taskTitle', task.taskTitle);
    parseObject.set('taskDescription', task.taskDescription);
    parseObject.set('formIoFormRef', task.formIoFormRef);
    parseObject.set('formIoFormSubmission', task.formIoFormSubmission);
    parseObject.set('attachments', task.attachments);
    // parseObject.set('visibilityByFunctionId', crisisTask.visibilityByFunctionId);
    // parseObject.set('createdByFunctionId', crisisTask.createdByFunctionId);
    parseObject.set('nextInfoTime', task.nextInfoTime);
    parseObject.set('nextInfoDone', task.nextInfoDone);
    parseObject.set('comment', task.comment);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('isFunctionNotified', task.isFunctionNotified);
    if (task.customCreatedAt) {
      parseObject.set('customCreatedAt', task.customCreatedAt);
    }

    return parseObject;
  }

  protected async afterSave(
    task: HolTask,
    isCreate: boolean,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction },
    historyLogComment,
    parseData,
    changeStatusNotification,
  ) {}

  protected getTagsForTask(tasksTags: Parse.Object[], task: Parse.Object): Parse.Object[] {
    return tasksTags
      ? tasksTags.filter(tasksTag => {
          return tasksTag.get('task').id === task.id;
        })
      : [];
  }
}
