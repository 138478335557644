import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { Injectable } from '@angular/core';
import { OclGroup } from '../../models/ocl-group.model';
import { OclGroupsStoreState } from '../../ocl.model';
import { InitGroups, UpdateGroupsFromPooling, UpdateOneGroup } from './ocl-groups.actions';

@Injectable({
  providedIn: 'root',
})
export class OclGroupsStoreManager {
  protected $currentOclModule: Observable<string>;

  protected constructor(protected store: Store<AppState>) {
    this._groupsState = store.select('ocl', 'groups');
    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  protected _groupsState: Observable<OclGroupsStoreState>;

  get groupsState(): Observable<OclGroupsStoreState> {
    return this._groupsState;
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  initGroups(groups: OclGroup<any>[], typeKey: 'events' | 'logbooks' | 'decisions' | 'globalInstructions'): void {
    this.store.dispatch(new InitGroups(groups, typeKey));
  }

  updateOneGroup(group: OclGroup<any>, typeKey: 'events' | 'logbooks' | 'decisions' | 'globalInstructions', currentModule: string) {
    this.store.dispatch(new UpdateOneGroup(group, typeKey, currentModule));
  }

  updateGroupsFromPooling(
    groups: OclGroup<any>[],
    typeKey: 'events' | 'logbooks' | 'decisions' | 'globalInstructions',
    currentModule,
  ): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateGroupsFromPooling(groups, typeKey));
    }
    this.initGroups(groups, typeKey);
  }
}
