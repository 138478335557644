import { Pipe, PipeTransform } from '@angular/core';
import { cloneDeep } from 'lodash';
import { OclChecklistFunction } from 'src/app/ocl/store/ocl-tasks.selectors';

@Pipe({
  name: 'searchEventChecklist',
})
export class SearchEventChecklistPipe implements PipeTransform {
  public transform(items: OclChecklistFunction[], searchCriteria?: string): any {
    if (!searchCriteria) {
      return items;
    }
    const searchTerm = this.sanitize(searchCriteria);

    const filteredItems = cloneDeep(items).filter(item => {
      return item.events.some(oclEvent => oclEvent.tasks.some(task => task.taskTitle.toLowerCase().includes(searchTerm)));
    });

    return filteredItems;
  }

  private sanitize(input: string): string {
    return input.trim().toLowerCase();
  }
}
