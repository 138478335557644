import { Injectable } from '@angular/core';
import { OclGlobalInstructionTagService } from '../../ocl/services/ocl-global-instruction-tag-service/ocl-global-instruction-tag.service';

@Injectable({
  providedIn: 'root',
})
export class CrewGlobalInstructionTagService extends OclGlobalInstructionTagService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('CrewGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('CrewGlobalInstruction');
  protected ParseTag = Parse.Object.extend('CrewTag');
  // tslint:enable
}
