import { Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';
import { MccImpactFilter } from '../models/mcc-impact-filter.model';

export class MccOptions {
  filters: MccImpactFilter[];
  defaultOCCScenario: string;
}

@Injectable({
  providedIn: 'root',
})
export class MccOptionsService {
  // tslint:disable-next-line:variable-name
  ParseMCCOptions = Parse.Object.extend('MCCOptions');

  private options: MccOptions = null; // @cache : options mcc

  constructor(private requestService: RequestService) {}

  // @hasCache options
  public get(): Promise<MccOptions> {
    if (this.options !== null) {
      return Promise.resolve(this.options);
    } else {
      const query = new Parse.Query(this.ParseMCCOptions);
      return this.requestService.performFirstQuery(query).then(result => {
        if (result) {
          this.options = {
            filters: [
              new MccImpactFilter(result.get('filter1') + 1),
              new MccImpactFilter(result.get('filter2') + 1),
              new MccImpactFilter(result.get('filter3') + 1),
              new MccImpactFilter(result.get('filter4') + 1),
            ],
            defaultOCCScenario: result.get('defaultOCCScenario'),
          };
        }
        return this.options;
      });
    }
  }

  public getFilters(): MccImpactFilter[] {
    return this.options && this.options.filters;
  }

  public getDefaultOCCScenario(): string {
    return this.options && this.options.defaultOCCScenario;
  }
}
