export class HolNoteTemplate {
  objectId?: string;
  name: string;
  template: string;
  createdAt?: Date;
  templateUrl?: string;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.name = parseObject.get('name');
    this.createdAt = parseObject.get('createdAt');
    this.templateUrl = parseObject.get('template').url();
  }
}
