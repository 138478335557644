import { HolTag } from 'src/app/common/models/hol-tag';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { InitTags, AddOneTag, UpdateTags } from './ocl-tags.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OclTagsStoreManager {
  $tagsState: Observable<HolTag[]>;

  protected constructor(private store: Store<AppState>) {
    this.$tagsState = store.select('ocl', 'tags');
  }

  get tagsState(): Observable<HolTag[]> {
    return this.$tagsState;
  }

  initTags(tags: HolTag[]): void {
    this.store.dispatch(new InitTags(tags));
  }

  addOneTag(tag: HolTag): void {
    this.store.dispatch(new AddOneTag(tag));
  }

  updateTags(tags: HolTag[]): void {
    this.store.dispatch(new UpdateTags(tags));
  }
}
