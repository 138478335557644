import { Inject, Injectable } from '@angular/core';

import { RequestService } from 'src/app/common/services/request.service';
import { UserService } from 'src/app/common/services/user.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';

import { GocGlobalInstructionTagService } from '../goc-global-instruction-tag-service/goc-global-instruction-tag.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';
import { OclSmsService } from 'src/app/ocl/services/ocl-sms-service/ocl-sms.service';

@Injectable({
  providedIn: 'root',
})
export class GocGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('GOCGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('GOCGlobalInstruction');
  protected ParseTag = Parse.Object.extend('GOCTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected globalInstructionTagService: GocGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected notificationsService: NotificationsService,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    public moduleConfig: GocModuleConfigService,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      globalInstructionTagService,
      globalInstructionsStoreManager,
      notificationsService,
      mailService,
      smsService,
      moduleConfig,
    );
  }
}
