<div [departureDate]="flight.std" [status]="flight.status" appIndicatorTimelineColor class="indicators-timeline">
  <div class="timeline">
    <div
      [ngClass]="{ dashed: !showMarker.arrival || !showMarker.departure }"
      [ngStyle]="{ left: departurePosition + '%', width: intervalWidth + '%' }"
      class="timeline__range"
    ></div>
  </div>
  <div class="info-container">
    <div [ngClass]="{ hide: !showMarker.departure }" [ngStyle]="{ left: 'calc(' + departurePosition + '% - 8px)' }" class="info departure">
      <div *ngIf="showInfo" [ngClass]="{ 'align-right': intervalWidth <= 15 }" class="info-label">
        <strong [ngClass]="{ selected: departureSelected === flight.departure }">{{ flight.departure }}</strong>
        <div>{{ flight.std | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
    <div
      [ngClass]="{ hide: !showMarker.arrival }"
      [ngStyle]="{ left: 'calc(' + arrivalPosition + '% - ' + arrivalOffset + 'px)' }"
      class="info arrival"
    >
      <div *ngIf="showInfo" [ngClass]="{ 'align-left': intervalWidth <= 15 }" class="info-label">
        <strong [ngClass]="{ selected: departureSelected === flight.destination }">{{ flight.destination }}</strong>
        <div>{{ flight.sta | date: "HH:mm'Z'":'UTC' }}</div>
      </div>
    </div>
  </div>
</div>
