<div class="hol-spaced-line">
  <div class="col-at">
    <span>{{ item.createdAt | time: isUtc }}</span>
  </div>
  <div *ngIf="item.createdBy" class="col-at">
    <strong [title]="item.createdBy.firstName + ' ' + item.createdBy.lastName">{{ item.createdBy?.monogram() }}</strong>
  </div>
  <div class="col-info" title="{{ item.message }}">{{ item.message }}</div>

  <div *ngIf="item.attachments" class="col-attachements next-info">
    <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
  </div>
  <div
    *ngIf="!isReadOnly && canBeDone"
    [class.hol-affected]="item.done && !item.manual"
    [class.hol-done]="item.done && item.manual"
    class="col-done"
  >
    <button (click)="triggerMarkAsDone($event)" aria-label="mark as done" mat-icon-button type="button">
      <i class="icon icon-check"></i>
    </button>
  </div>
  <div
    [class.almost-elapsed]="isAlmostElapsed(item.nextInfoTime)"
    [class.elapsed]="isElapsed(item.nextInfoTime)"
    [class.hol-affected]="item.done && !item.manual"
    [class.hol-done]="item.done && item.manual"
    class="col-next-info"
  >
    <span>{{ item.nextInfoTime | time: isUtc }}</span>
  </div>
</div>
