import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { UserService } from 'src/app/common/services/user.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { EclGlobalInstructionTagService } from '../ecl-global-instruction-tag-service/ecl-global-instruction-tag.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { EclGlobalInstruction } from '../../models/ecl-global-instruction';
import { HolTag } from '../../../common/models/hol-tag';
import { OclGlobalInstruction } from '../../../ocl/models/ocl-global-instruction.model';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunctionState } from '../../ecl.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';
import { OclSmsService } from 'src/app/ocl/services/ocl-sms-service/ocl-sms.service';

@Injectable({
  providedIn: 'root',
})
export class EclGlobalInstructionService extends OclGlobalInstructionService<EclGlobalInstruction> {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('ECLGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('ECLGlobalInstruction');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseEclFunction = Parse.Object.extend('ECLFunction');
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  protected eclFunctionState: EclFunctionState;
  protected eclOpenCrisis: EclCrisis;

  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected globalInstructionTagService: EclGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected notificationsService: NotificationsService,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    public moduleConfig: EclModuleConfigService,
    protected eclFunctionStoreManager: EclFunctionStoreManager,
    public commonStoreManager: CommonStoreManager,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      globalInstructionTagService,
      globalInstructionsStoreManager,
      notificationsService,
      mailService,
      smsService,
      moduleConfig,
    );
    eclFunctionStoreManager.$eclFunctionState.subscribe(eclFs => (this.eclFunctionState = eclFs));
    this.commonStoreManager.eclCrisis.subscribe(c => (this.eclOpenCrisis = c));
  }

  protected newOclGlobalInstruction(parseObject?: Parse.Object, tags?: HolTag[]): EclGlobalInstruction {
    return new EclGlobalInstruction(parseObject, tags);
  }

  protected setAdditionalFields(globalInstruction: OclGlobalInstruction, parseGlobalInstruction: Parse.Object) {
    const gi = globalInstruction as EclGlobalInstruction;
    // Set existing function or new from selected user function
    if (this.eclFunctionState.functions.length) {
      const objectId =
        (gi.function && gi.function.objectId) ||
        (this.eclFunctionState.selectedUserFunction && this.eclFunctionState.selectedUserFunction.objectId);
      parseGlobalInstruction.set(
        'function',
        new this.ParseEclFunction({
          id: objectId,
        }),
      );
    }
    /*parseGlobalInstruction.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );*/
  }

  protected getAdditionnalQueries(query: Parse.Query): Parse.Query {
    query.include('function');
    return super.getAdditionnalQueries(query);
  }
}
