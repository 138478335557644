import { ComponentType } from '@angular/cdk/portal';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';

import { MatDialog } from '@angular/material';

import { cloneDeep } from 'lodash';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { HolObject } from 'src/app/common/models/hol-object';

import { nextInfoType } from '../../components/hol-next-info/hol-next-info.component';
import { HolNotification, HolNotifyFunction } from '../../models/hol-notification.model';
import { DialogHistoryData, HolHistoryItemsModalComponent } from '../hol-history-items-modal/hol-history-items-modal.component';
import { HolTag } from '../../models/hol-tag';
import { MatDialogRef } from '@angular/material/dialog';
import { ErpCrisisTask } from '../../../erp/models/erp-crisisTask';
import { HolOptions } from '../../models/hol-options';
import { HolOptionsService } from '../../services/hol-options.service';

@Component({
  selector: 'app-hol-modal',
  templateUrl: './hol-modal.component.html',
  styleUrls: ['./hol-modal.component.scss'],
})
export class HolModalComponent implements OnInit {
  @Input() item: HolObject | ErpCrisisTask;
  @Input() isReadOnly = false;
  @Input() isCreate = true;
  @Input() type: string;
  @Input() form: FormGroup;
  @Input() contentTextLength = 160;
  @Input() limitTo: HolObject;
  @Input() context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlDate: string;
    htmlTemplate: string;
  };
  @Input() canArchive = false;
  @Input() canSave = true;
  @Input() nextInfo: nextInfoType = undefined;
  @Input() hideSecondaryColumn = false;
  @Input() hasAttachments = true;
  @Input() hasContentText = true;
  @Input() hasAttachmentsMandatory = false;
  @Input() addButtonTrad = 'DASHBOARD.MODAL.ADD_BUTTON';
  @Input() updateButtonTrad = 'DASHBOARD.MODAL.UPDATE_BUTTON';
  @Input() canUpdate = true;
  @Input() notifications: HolNotification[];
  @Input() historyItemComponent: ComponentType<any>;
  @Input() historyItemObjectId: string;
  @Input() historyItemType: string;
  @Input() historyTitle: string;
  @Input() historyItemListMode = false;
  @Input() canEditTitle = false;
  @Input() isHistory = false;
  @Input() isUTC = true;
  @Input() extendACLOnly;
  @Input() disabledValidateActions = false;
  @Input() notifyFunction: HolNotifyFunction = undefined;
  @Input() applicability = undefined;
  @Input() canUpdateFromOtherModule = false;
  @Input() fastMode = false;
  selectedTag: HolTag[];

  initialContentText: string;

  @Output() saveItem = new EventEmitter<{
    contentTextChanged?: boolean;
    notifications?: HolNotification[];
    nextInfo?: nextInfoType;
    notifyFunction?: HolNotifyFunction;
  }>();
  @Output() archiveItem = new EventEmitter();
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  protected readonly undefined = undefined;
  protected readonly module = module;

  protected readonly ErpCrisisTask = ErpCrisisTask;

  public holOptions: HolOptions;

  constructor(
    public moduleConfig: ModuleConfigService,
    private _ngZone: NgZone,
    public matDialog: MatDialog,
    dialogRef: MatDialogRef<HolModalComponent>,
    private cdr: ChangeDetectorRef,
    public holOptionsService: HolOptionsService,
  ) {
    dialogRef.disableClose = true;
  }

  get isErpCrisisTask() {
    return this.item instanceof ErpCrisisTask ? !this.item.isTemporary : false;
  }

  get showNotifications(): boolean {
    return !this.isReadOnly && this.canUpdate && ((this.notifications && this.notifications.length !== 0) || !!this.notifyFunction);
  }

  onPanelOpened() {
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    // this.moduleConfig.config.displayToGoc
    if (!this.item) {
      throw new Error('HolModalComponent::item is required');
    }
    if (this.item.contentText === undefined) {
      throw new Error('HolModalComponent::item.contentText is required');
    }
    if (!this.type) {
      throw new Error('HolModalComponent::type is required');
    }
    if (!this.form) {
      this.form = new FormGroup({});
    }

    this.initialContentText = cloneDeep(this.item.contentText);
    if (this.notifications) {
      this.notifications = this.notifications.sort();
    }

    this.holOptions = await this.holOptionsService.get(false);

    this.contentTextLength = this.holOptions.fieldDataLimit;
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.nextInfo.nextInfoTime = nextInfo.nextInfoTime;
    this.nextInfo.nextInfoDone = nextInfo.nextInfoDone;
  }

  saveNotifications(notifications: HolNotification[]) {
    this.notifications = cloneDeep(notifications);
  }

  saveNotifyFunction(notifyFunction: HolNotifyFunction) {
    this.notifyFunction = cloneDeep(notifyFunction);
  }

  openHistory() {
    const data: DialogHistoryData = {
      title: 'Dialog Title',
      historyItemComponent: this.historyItemComponent,
      historyItemObjectId: this.historyItemObjectId,
      historyItemType: this.historyItemType,
      historyTitle: this.historyTitle,
      historyItemListMode: this.historyItemListMode,
    };
    this.matDialog.open(HolHistoryItemsModalComponent, {
      data,
    });
  }

  save() {
    let contentText = false;
    if (
      this.canEditTitle &&
      this.initialContentText &&
      this.initialContentText.trim().length > 0 &&
      this.initialContentText !== this.item.contentText
    ) {
      contentText = true;
    }
    const objectToEmit: {
      contentTextChanged?: boolean;
      notifications?: HolNotification[];
      nextInfo?: nextInfoType;
      notifyFunction?: HolNotifyFunction;
    } = { contentTextChanged: contentText, notifications: this.notifications };
    if (this.notifyFunction) {
      objectToEmit.notifyFunction = this.notifyFunction;
    }
    if (!this.nextInfo) {
      this.saveItem.emit(objectToEmit);
    } else {
      if (!this.form.invalid && this.canSave) {
        objectToEmit.nextInfo = this.nextInfo;
        this.saveItem.emit(objectToEmit);
      }
    }
  }

  archive() {
    if (this.canArchive) {
      this.archiveItem.emit();
    }
  }
  updateTags(item) {
    const tags = item.isTemporary ? item['tags'] : item['defaultTags'];
    if (tags.length > 0) {
      this.disabledValidateActions = false;
    }
  }
  updatedAttachment() {
    if (this.context.module === 'ERP' && this.context.category === 'DOCUMENT') {
      const cond =
        !this.item.attachments ||
        (this.item.attachments &&
          !this.item.attachments.note &&
          !this.item.attachments.noteFile &&
          !this.item.attachments.file &&
          this.item.attachments.files.length === 0 &&
          !this.item.attachments.image &&
          !this.item.attachments.link);
      this.canSave = !cond;
    }
  }
}
