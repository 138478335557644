<div [ngClass]="{ disabled: checklist.disabled || !isHolder }" class="checklist">
  <div class="checklist__top">
    <div class="checklist__top__row">
      <span class="title-checklist-vacation">
        {{ 'TASKS.CHECKLIST_LABEL' | translate }} {{ index + 1 }}
        &nbsp;-&nbsp;
        {{ checklist.title }}
      </span>
      <span
        (click)="enabledOrDisabledChecklistUpdate(checklist)"
        *ngIf="!isReadOnly && isHolder"
        aria-label="Disabled checklist"
        class="btn-action"
        [ngClass]="[checklist.disabled || !isHolder ? 'hol-icon-refresh' : 'hol-icon-close']"
      >
      </span>
    </div>
    <div class="checklist__top__row">
      <span class="subtitle-checklist-vacation">{{ checklist.dueDate }}</span>
    </div>
  </div>
  <div class="checklist__middle">
    <ng-container *ngFor="let checklistItem of checklist.checklistItems; trackBy: trackByFunction">
      <app-checklist-item-vac
        [checklistId]="checklist.objectId"
        [checklistItem]="checklistItem"
        [checklistTitleForLog]="checklistTitle"
        [checklistTitle]="checklist.title"
        [isReadOnly]="isReadOnly || !isHolder"
        [mod]="mod"
        [vacationId]="vacationId"
      ></app-checklist-item-vac>
    </ng-container>
  </div>
  <div *ngIf="checklist.completedAt" class="checklist__bottom hol-done">
    <span>{{ 'TASKS.COMPLETED_AT' | translate }} {{ checklist.completedAt | time: !isReadOnly }}</span>
  </div>
</div>
