import { HolTag } from 'src/app/common/models/hol-tag';
import { cloneDeep } from 'lodash';
import { OclTagsActionTypes, TagsActions } from './ocl-tags.actions';

const tagInitialState: HolTag[] = [];

export function oclTagsReducer(tags: HolTag[] = cloneDeep(tagInitialState), action: TagsActions): HolTag[] {
  switch (action.type) {
    case OclTagsActionTypes.INIT_TAGS:
      return action.tags;
    case OclTagsActionTypes.ADD_ONE_TAG:
      const tempTasks = cloneDeep(tags);
      tempTasks.unshift(action.tag);
      return tempTasks;
    case OclTagsActionTypes.UPDATE_ALL_TAGS:
      return action.tags;
    default:
      return tags;
  }
}
