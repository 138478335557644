import { HolObject } from '../../common/models/hol-object';

export class ErpFunctionUser extends HolObject {
  functionId: string;
  userId: string;
  isHolder: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.functionId = parseObject.get('functionId');
    this.userId = parseObject.get('userId');
    this.isHolder = parseObject.get('isHolder');
  }
}
export interface FunctionUserForCrisis {
  functionId: string;
  title: string;
  shortTitle: string;
  isHolder: boolean;
  categoryDocumentId: string;
}
