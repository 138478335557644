'use strict';

/**
 * @ngdoc site
 * @name gestiondecriseApp.FilesService
 * @description
 * # FilesService
 * Services for uploading files
 */
angular.module('gestiondecriseApp').service('FilesService', function ($http, $q, CONSTANTS) {
  this.uploadFile = function (fileName, file) {
    var deferred = $q.defer();

    var parseFile = new Parse.File(fileName, file);
    parseFile.save().then(function (savedFile) {
      deferred.resolve(savedFile.url());
    }, deferred.reject);

    return deferred.promise;
  };

  this.get = function (url) {
    const deferred = $q.defer();
    $http.get(url).then(retour => deferred.resolve(retour), deferred.reject);
    return deferred.promise;
  };
});
