import { OclTagsStoreManager } from './../../ocl/store/tags/ocl-tags.store-manager';
import { HolNotifyFunction } from './../../common/models/hol-notification.model';
import { ErpHistoryService } from './erp-history.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ErpCrisisTaskTagService } from './erp-crisis-task-tag.service';
import { HolNotification } from '../../common/models/hol-notification.model';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { ErpMailService } from './erp-mail.service';
import { ErpSmsService } from './erp-sms.service';
import { Injectable } from '@angular/core';
import { orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisTask, ErpCrisisTaskStream, ErpCrisisUserTask } from '../models/erp-crisisTask';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { RolesService } from '../../common/services/roles.service';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';
import { ErpTagsService } from './erp-tag.service';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { TasksService } from '../../common/services/tasks.service';
import { take } from 'rxjs/operators';
import { UserService } from 'src/app/common/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisTaskService extends TasksService {
  // tslint:disable:variable-name
  protected ParseCrisis = Parse.Object.extend('GDCCrisis');
  protected ParseTask = Parse.Object.extend('GDCTask');
  protected ParseUser = Parse.Object.extend('_User');
  protected ParseTag = Parse.Object.extend('GDCTag');
  protected ParseTaskTag = Parse.Object.extend('GDCTaskTag');
  private ParseCrisisDecision = Parse.Object.extend('GDCDecisions');
  private ParseDecisionTag = Parse.Object.extend('GDCDecisionTag');

  // tslint:enabled

  constructor(
    protected userService: UserService,
    protected crisisStoreManager: CrisisStoreManager,
    protected functionsStoreManager: FunctionsStoreManager,
    protected requestService: RequestService,
    protected notificationsService: NotificationsService,
    protected erpMailService: ErpMailService,
    protected erpSmsService: ErpSmsService,
    protected crisisTaskTagService: ErpCrisisTaskTagService,
    protected historyService: ErpHistoryService,
    protected rolesService: RolesService,
    protected erpTagsService: ErpTagsService,
    protected tagsStoreManager: OclTagsStoreManager,
  ) {
    super(requestService, historyService, erpTagsService, crisisTaskTagService);
  }

  get crisisTasksStream(): Observable<ErpCrisisTaskStream[]> {
    const $crisisTasksStreamSubject: BehaviorSubject<Array<ErpCrisisTaskStream>> = new BehaviorSubject([]);
    combineLatest(
      this.functionsStoreManager.functionsAllUserErpState,
      this.functionsStoreManager.functionsCrisisErpState,
      this.functionsStoreManager.allTasks,
      this.functionsStoreManager.allUsers,
      this.rolesService.$companiesRolesFilter,
    ).subscribe(([functionsAllUser, functionsCrisis, erpCrisisTasks, users]) => {
      // tslint:disable-next-line: max-line-length
      if (
        functionsAllUser &&
        functionsAllUser.length &&
        functionsCrisis &&
        functionsCrisis.length &&
        erpCrisisTasks &&
        erpCrisisTasks.length
      ) {
        functionsAllUser = this.rolesService.filterFromCompanyRoles(functionsAllUser);
        erpCrisisTasks = this.rolesService.filterFromCompanyRoles(erpCrisisTasks).filter(t => !t.isDocumentOnly);
        const bufferErpCrisisTaskStream: ErpCrisisTaskStream[] = functionsCrisis.map(fCrisis => {
          const tasksForFunction = erpCrisisTasks.filter(crisisTask => crisisTask.functionId === fCrisis.functionId);
          const bufferTotal = erpCrisisTasks.filter(crisisTask => crisisTask.functionId === fCrisis.functionId).length;
          const bufferDone = tasksForFunction.filter(crisisTask => !crisisTask.status || crisisTask.status === 'DONE').length;

          const holder = functionsAllUser.find(fAllUser => fAllUser.functionId === fCrisis.functionId && fAllUser.isHolder);
          const userHolder = (holder && users.find(u => u.userId === holder.userId)) || null;

          return {
            shortTitle: fCrisis.shortTitle,
            title: fCrisis.title,
            hasHolder: functionsAllUser.some(fAllUser => fAllUser.functionId === fCrisis.functionId && fAllUser.isHolder),
            holder: userHolder,
            tasksDetails: {
              total: bufferTotal,
              done: bufferDone,
              frozen: tasksForFunction.filter(crisisTask => crisisTask.status === 'FROZEN').length,
              notApplicable: tasksForFunction.filter(crisisTask => crisisTask.status === 'NOT_APPLICABLE').length,
              todo: tasksForFunction.filter(crisisTask => !crisisTask.status || crisisTask.status === 'TODO').length,
              completionRate: (100 * bufferDone) / bufferTotal,
            },
          };
        });
        // tslint:disable-next-line: max-line-length
        $crisisTasksStreamSubject.next(
          orderBy(
            bufferErpCrisisTaskStream,
            [item => item.tasksDetails.total === 0, 'hasHolder', 'tasksDetails.frozen', 'tasksDetails.completionRate', 'shortTitle'],
            ['asc', 'asc', 'desc', 'asc', 'asc'],
          ),
        );
      }
    });
    return $crisisTasksStreamSubject;
  }

  getTasksByUser(): Observable<ErpCrisisUserTask> {
    const $crisisTasksByUserSubject: BehaviorSubject<ErpCrisisUserTask> = new BehaviorSubject(undefined);
    combineLatest(
      this.rolesService.$companiesRolesFilter,
      this.functionsStoreManager.functionsUserErpState,
      this.functionsStoreManager.functionsCrisisErpState,
      this.functionsStoreManager.allTasks,
    ).subscribe(([, functionsUser, functionsCrisis, erpCrisisTasks]) => {
      // tslint:disable-next-line: max-line-length
      if (functionsUser && functionsUser.length && functionsCrisis && functionsCrisis.length && erpCrisisTasks && erpCrisisTasks.length) {
        erpCrisisTasks = this.rolesService.filterFromCompanyRoles(erpCrisisTasks.filter(t => t && !t.isDocumentOnly));
        functionsUser = this.rolesService.filterFromCompanyRoles(functionsUser);
        functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsCrisis);
        const buffercrisisTasksByUser: ErpCrisisUserTask = {
          // OrderBy
          // todo with next info => recent to older
          // code order
          // task done
          tasks: orderBy(
            erpCrisisTasks.filter(
              cTask => !cTask.isDocumentOnly && functionsUser.findIndex(fUser => fUser.functionId === cTask.functionId) >= 0,
            ),
            [item => (item.status && item.status !== 'DONE' ? 0 : 1), 'nextInfoTime', 'order', 'subOrder', 'code'],
            ['asc', 'asc', 'asc', 'asc', 'asc'],
          ),
          functionInfos: functionsUser
            .map(fUser => {
              const tempFCrisis = functionsCrisis.find(fCrisis => fCrisis.functionId === fUser.functionId);
              if (tempFCrisis && fUser) {
                return {
                  shortTitle: tempFCrisis.shortTitle,
                  title: tempFCrisis.title,
                  functionId: tempFCrisis.functionId,
                  isHolder: fUser.isHolder,
                };
              }
            })
            .filter(el => el !== undefined),
        };
        $crisisTasksByUserSubject.next(buffercrisisTasksByUser);
      } else {
        $crisisTasksByUserSubject.next({ tasks: [], functionInfos: [] });
      }
    });
    return $crisisTasksByUserSubject;
  }

  async getCrisisTasksByCrisisTypeId(crisisTypeId): Promise<ErpCrisisTask[]> {
    const crisisTaskQuery = new Parse.Query(this.ParseTask);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.equalTo('crisisTypeId', crisisTypeId);
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);
    const crisisTaskTagsQuery = new Parse.Query(this.ParseTaskTag);
    crisisTaskTagsQuery.include('tag');
    crisisTaskTagsQuery.descending('createdAt');
    crisisTaskTagsQuery.matchesQuery('task', crisisTaskQuery);
    const [crisisTasksFromApi, crisisTasksTags, crisisTags] = await Promise.all([
      this.requestService.performFindAllQuery(crisisTaskQuery),
      this.requestService.performFindQuery(crisisTaskTagsQuery),
      this.erpTagsService.getAll(false),
    ]);
    if (crisisTasksFromApi) {
      return crisisTasksFromApi.map(crisisTaskFromApi => {
        const tags = this.getTagsForTask(crisisTasksTags, crisisTaskFromApi);
        const defaultTagCodes: string[] = crisisTaskFromApi.get('defaultTags') ? crisisTaskFromApi.get('defaultTags').split('|') : [];
        const defaultTags: HolTag[] = this.getDefaultTagsForTask(defaultTagCodes, crisisTags);
        return new ErpCrisisTask(crisisTaskFromApi, tags && tags.map(t => new HolTag(t.get('tag'))), defaultTags);
      });
    } else {
      return [];
    }
  }

  removeCrisisTask(crisisTask: Partial<ErpCrisisTask>, crisisObjectId: string): void {
    if (crisisTask.objectId) {
      const parseObject = new this.ParseTask({ id: crisisTask.objectId });
      parseObject.set('isArchived', true);

      this.requestService.performSaveQuery(
        parseObject,
        null,
        parseData => {
          const bufferCrisisTask: ErpCrisisTask = new ErpCrisisTask(parseData);
          this.crisisStoreManager.removeOneCrisisTask(crisisTask.objectId);
          this.historyService
            .postLog(
              bufferCrisisTask.toLog(bufferCrisisTask.isDocumentOnly ? 'CLOSE_DOCUMENT_ONLY' : 'CLOSE_TASK', parseData, crisisObjectId),
            )
            .catch(error => console.log(error));
        },
        error => {
          console.log(error);
        },
      );
    }
  }

  async sendStatusChangeNotification(
    changeStatusNotification: {
      canSendNotification: boolean;
      comment: string;
      type: string;
      functionTitle: string;
    },
    bufferCrisisTask: ErpCrisisTask,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction },
  ) {
    let addressMailToSend: string[] = [];
    let phoneNumbersToSend: string[] = [];
    let userToNotify;
    if (notifs.notifications && notifs.notifications.length) {
      addressMailToSend = this.notificationsService.getAddressMailToSend(notifs.notifications);
      phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifs.notifications);
    }
    if (notifs.functionToNotify && (notifs.functionToNotify.sendByMail || notifs.functionToNotify.sendBySms)) {
      userToNotify = await this.functionsStoreManager.getUsersByFunction(bufferCrisisTask.functionId, bufferCrisisTask.acl);
      if (notifs.functionToNotify.sendByMail) {
        addressMailToSend = [...addressMailToSend, ...userToNotify.map(user => user.email)];
      }
      if (notifs.functionToNotify.sendBySms) {
        phoneNumbersToSend = [...phoneNumbersToSend, ...userToNotify.map(user => user.phone).filter(el => el !== undefined)];
      }
    }

    if (addressMailToSend.length) {
      if (changeStatusNotification.type === 'DONE') {
        this.erpMailService.sendCrisisTaskDoneMail(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
      if (changeStatusNotification.type === 'FROZEN') {
        this.erpMailService.sendCrisisTaskFrozenMail(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
      if (changeStatusNotification.type === 'TODO') {
        this.erpMailService.sendCrisisTaskBackTodoMail(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
    }

    if (phoneNumbersToSend.length) {
      if (changeStatusNotification.type === 'DONE') {
        this.erpSmsService.sendCrisisTaskDoneSms(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
      if (changeStatusNotification.type === 'FROZEN') {
        this.erpSmsService.sendCrisisTaskFrozenSms(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
      if (changeStatusNotification.type === 'TODO') {
        this.erpSmsService.sendCrisisTaskBackTodoSms(
          bufferCrisisTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
    }
  }

  async sendNotifications(
    isCreate: boolean,
    bufferCrisisTask: ErpCrisisTask,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction },
  ) {
    let functionToNotifyTitle: string;
    let addressMailToSend: string[] = this.notificationsService.getAddressMailToSend(notifs.notifications);
    let phoneNumbersToSend: string[] = this.notificationsService.getPhoneNumbersToSend(notifs.notifications);
    if (notifs.functionToNotify && (notifs.functionToNotify.sendByMail || notifs.functionToNotify.sendBySms)) {
      functionToNotifyTitle = notifs.functionToNotify.functionTitle;
      const userToNotify = await this.functionsStoreManager.getUsersByFunction(bufferCrisisTask.functionId, bufferCrisisTask.acl);
      if (notifs.functionToNotify.sendByMail) {
        addressMailToSend = [...addressMailToSend, ...userToNotify.map(user => user.email)];
      }
      if (notifs.functionToNotify.sendBySms) {
        phoneNumbersToSend = [...phoneNumbersToSend, ...userToNotify.map(user => user.phone).filter(el => el !== undefined)];
      }
    }

    if (addressMailToSend && addressMailToSend.length) {
      if (bufferCrisisTask.isDocumentOnly) {
        this.erpMailService.sendCrisisDocumentMail(isCreate, bufferCrisisTask, addressMailToSend, functionToNotifyTitle);
      } else {
        this.erpMailService.sendCrisisTaskMail(isCreate, bufferCrisisTask, addressMailToSend, functionToNotifyTitle);
      }
    }

    if (phoneNumbersToSend && phoneNumbersToSend.length) {
      if (bufferCrisisTask.isDocumentOnly) {
        this.erpSmsService.sendCrisisDocumentSms(isCreate, bufferCrisisTask, phoneNumbersToSend, functionToNotifyTitle);
      } else {
        this.erpSmsService.sendCrisisTaskSms(isCreate, bufferCrisisTask, phoneNumbersToSend, functionToNotifyTitle);
      }
    }
  }

  isAttachmentsMandatory(task: ErpCrisisTask) {
    return task.isOnDashboard;
  }

  canChangeAcl(task: ErpCrisisTask) {
    return task.isTemporary;
  }

  newTask(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]): ErpCrisisTask {
    return new ErpCrisisTask(parseObject, tags, defaultTags);
  }

  protected async afterSave(
    task: ErpCrisisTask,
    isCreate: boolean,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction },
    historyLogComment,
    parseData,
    changeStatusNotification,
  ) {
    const crisis = await this.crisisStoreManager.crisisErpState.pipe(take(1)).toPromise();
    const crisisObjectId = crisis.objectId;
    const hasToNotifiy =
      notifs &&
      ((notifs.notifications && notifs.notifications.length) ||
        (notifs.functionToNotify && notifs.functionToNotify.sendByMail) ||
        (notifs.functionToNotify && notifs.functionToNotify.sendBySms));
    if (isCreate) {
      this.crisisStoreManager.createOneCrisisTask(task);

      if (hasToNotifiy) {
        this.sendNotifications(isCreate, task, notifs);
      }

      this.historyService
        .postLog(task.toLog(historyLogComment ? historyLogComment : 'CREATE', parseData, crisisObjectId))
        .catch(error => console.log(error));

      if (task.decision) {
        this.historyService
          .postLog(
            task.decision.toLog(
              'DECISION_TASK_LINKED_ADDED',
              new this.ParseCrisisDecision({ id: task.decision.objectId }),
              crisisObjectId,
              task,
            ),
          )
          .catch(error => console.log(error));
      }
      return;
    }

    this.crisisStoreManager.updateOneCrisisTask(task);

    if (changeStatusNotification && changeStatusNotification.canSendNotification) {
      this.sendStatusChangeNotification(changeStatusNotification, task, notifs);
    }

    if (task.isDocumentOnly && hasToNotifiy) {
      this.sendNotifications(isCreate, task, notifs);
    }

    this.historyService
      .postLog(task.toLog(historyLogComment ? historyLogComment : 'UPDATE', parseData, crisisObjectId))
      .catch(error => console.log(error));

    if (task.decision && historyLogComment === 'UPDATE_TASK_STATUS') {
      const parseDecision = new this.ParseCrisisDecision({ id: task.decision.objectId });
      const crisisTasksQuery = new Parse.Query(this.ParseTask);
      crisisTasksQuery.equalTo('decision', parseDecision);
      crisisTasksQuery.notEqualTo('archived', true);

      const crisisDecisionTagsquery = new Parse.Query(this.ParseDecisionTag);
      crisisDecisionTagsquery.equalTo('decision', parseDecision);

      Promise.all([
        this.requestService.performFindQuery(crisisDecisionTagsquery),
        this.requestService.performFindAllQuery(crisisTasksQuery),
      ]).then(([tags, tasks]) => {
        const bufferCrisisDecision: ErpCrisisDecision = new ErpCrisisDecision(
          parseDecision,
          tags && tags.map(t => new HolTag(t.get('tag'))),
          tasks && tasks.map(t => new ErpCrisisTask(t)),
        );
        this.historyService
          .postLog(bufferCrisisDecision.toLog('DECISION_TASK_LINKED_CHANGED_STATUS', parseDecision, crisisObjectId, task))
          .catch(error => console.log(error));
      });
    }
    return;
  }

  protected taskToParseObject(task: ErpCrisisTask): Parse.Object {
    const parseObject = super.taskToParseObject(task);
    parseObject.set('outputDataLabel', task.outputDataLabel);
    parseObject.set('customOutputDataLabel', task.customOutputDataLabel);
    parseObject.set('isTemporary', task.isTemporary);
    parseObject.set('isOnDashboard', task.isOnDashboard);
    parseObject.set('customIsOnDashboard', task.customIsOnDashboard);
    parseObject.set('crisisTypeId', task.crisisTypeId);
    parseObject.set('isDocumentOnly', task.isDocumentOnly);
    parseObject.set('isArchived', task.isArchived);
    parseObject.set('frozenByErd', task.frozenByErd);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('visibleBy', task.visibleBy);
    if (task.decision) {
      parseObject.set('decision', new this.ParseCrisisDecision({ id: task.decision.objectId }));
    }
    return parseObject;
  }
}
