<div class="hol-dialog-wrapper">
  <mat-toolbar>
    <div mat-dialog-title>
      <span>{{ title }}</span>
    </div>
    <button [mat-dialog-close]="null" mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="hol-dialog-section">
        <mat-radio-group formControlName="reason">
          <div>
            <mat-radio-button color="primary" value="0">{{ 'GOC.MODALS.ARCHIVE_MODAL.CLOSE_REASON_1' | translate }} </mat-radio-button>
          </div>

          <div>
            <mat-radio-button color="primary" value="1">{{ 'GOC.MODALS.ARCHIVE_MODAL.CLOSE_REASON_2' | translate }} </mat-radio-button>
          </div>

          <div>
            <mat-radio-button color="primary" value="2">{{ 'GOC.MODALS.ARCHIVE_MODAL.CLOSE_REASON_3' | translate }} </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
      <div class="hol-dialog-section">
        <textarea class="form-control" formControlName="reasonText" maxlength="200" placeholder=""></textarea>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button (click)="submit()" [disabled]="!form.valid" class="hol-validate-btn" mat-raised-button type="submit">
      <mat-icon>done</mat-icon>
      {{ 'COMMON.BUTTON.ARCHIVE' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
