import { Injectable } from '@angular/core';
import { HolNoteTemplate } from '../models/hol-note-template';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class NoteTemplateService {
  parseHolNoteTemplate = Parse.Object.extend('HOLNoteTemplate');

  constructor(private requestService: RequestService) {}

  getAllNoteTemplate(): Promise<HolNoteTemplate[]> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(this.parseHolNoteTemplate);
      query.addAscending('name');
      this.requestService.performFindAllQuery(
        query,
        result => {
          if (!result) {
            resolve(undefined);
          } else {
            const bufferNoteTemplates: HolNoteTemplate[] = result.map(el => {
              return new HolNoteTemplate(el);
            });
            resolve(bufferNoteTemplates);
          }
        },
        reject
      );
    });
  }
}
