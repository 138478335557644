import { HolCrisis, HolCrisisType } from '../../common/models/hol-crisis';

export class EclCrisis extends HolCrisis {
  public type: EclCrisisType;
  public closedAt: Date;
  functionsToNotify: string[];

  public constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.type = new EclCrisisType(parseObject.get('crisisType'));
      let value = parseObject.get('functionsToNotify');
      this.functionsToNotify = value ? value.trim().split('|') : [];
      this.closedAt = parseObject.get('closedAt');
    }
  }
}

export class EclCrisisType extends HolCrisisType {
  functionsToNotify: string[];

  public constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      let value = parseObject.get('functionIdsToNotify');
      this.functionsToNotify = value ? value.trim().split('|') : [];
    }
  }
}
