import { BreakingNewsService } from '../../common/services/breaking-news.service';
import { ErpCrisisTypeService } from './erp-crisis-type.service';
import { ErpSmsService } from './erp-sms.service';
import { ErpMailService } from './erp-mail.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisis } from '../models/erp-crisis';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpCrisisDataService } from './erp-crisis-data.service';
import { OptionsService } from '../../common/services/options.service';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CrisisService } from '../../common/services/crisis/crisis.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisService extends CrisisService<ErpCrisis> {
  constructor(
    requestService: RequestService,
    private crisisStoreManager: CrisisStoreManager,
    private commonStoreManager: CommonStoreManager,
    private functionsStoreManager: FunctionsStoreManager,
    private crisisDataService: ErpCrisisDataService,
    private erpMailService: ErpMailService,
    private erpSmsService: ErpSmsService,
    private optionsService: OptionsService,
    private breakingNewsService: BreakingNewsService,
    private erpCrisisTypeService: ErpCrisisTypeService,
  ) {
    super(requestService);
    this.ParseCrisis = Parse.Object.extend('GDCCrisis');
  }

  newCrisisObject(parser: Parse.Object): ErpCrisis {
    return new ErpCrisis(parser);
  }

  protected afterSave(inputCrisis: Partial<ErpCrisis>, savedCrisis: ErpCrisis): ErpCrisis {
    if (inputCrisis.type) {
      savedCrisis.type = inputCrisis.type;
    }
    if (inputCrisis.objectId) {
      this.crisisStoreManager.updateCrisis(savedCrisis);
    }
    return savedCrisis;
  }

  protected async afterActivate(inputCrisis: ErpCrisis, savedCrisis: ErpCrisis): Promise<ErpCrisis> {
    if (!savedCrisis.type) {
      savedCrisis.type = await this.erpCrisisTypeService.getCrisisTypeByCrisisTypeId(savedCrisis.crisisTypeId);
    }
    await this.erpMailService.sendNewCrisisEmails(savedCrisis);
    await this.erpSmsService.sendNewCrisisSms(savedCrisis);
    return savedCrisis;
  }

  protected async afterClose(crisis: ErpCrisis, closedCrisis: ErpCrisis): Promise<ErpCrisis> {
    if (this.optionsService.hasToSendMails()) {
      this.erpMailService.sendCrisisOverMail(crisis);
    }
    if (this.optionsService.hasToSendSms()) {
      this.erpSmsService.sendCrisisOverSms(crisis);
    }
    await this.breakingNewsService.deleteAllBreakingNews();
    this.crisisStoreManager.updateCrisis(closedCrisis);
    return closedCrisis;
  }

  protected beforeCreate(crisis: ErpCrisis): BehaviorSubject<string> {
    return this.resetCurrentCrisis();
  }

  protected async afterCreate(
    inputCrisis: ErpCrisis,
    newCrisis: ErpCrisis,
    createCrisisProgress: BehaviorSubject<string>,
  ): Promise<ErpCrisis> {
    createCrisisProgress.next('✅<br/>Create crisis data...');
    try {
      // Create crisis data
      newCrisis.params = await this.crisisDataService.saveAllCrisisParameters(inputCrisis.params, newCrisis);
      // Finish crisis creation
      createCrisisProgress.next('✅<br/>');
      createCrisisProgress.next('<strong>✅ Crisis created !</strong>');
      createCrisisProgress.complete();
      // Send mails and sms to stakeholders
      if (newCrisis.isInPreparation) {
        this.erpMailService.sendNewCrisisInPreparationEmails(newCrisis);
        this.erpSmsService.sendNewCrisisInPreparationSms(newCrisis);
      } else {
        await this.erpMailService.sendNewCrisisEmails(newCrisis);
        await this.erpSmsService.sendNewCrisisSms(newCrisis);
      }
    } catch (e) {
      createCrisisProgress.error(e);
    }
    return newCrisis;
  }

  private resetCurrentCrisis(): BehaviorSubject<string> {
    const resetCrisisProgress = new BehaviorSubject<string>('');
    this.commonStoreManager.crisis.pipe(take(1)).subscribe(async crisis => {
      try {
        if (crisis && crisis.createdAt) {
          resetCrisisProgress.next('Delete previous crisis data...');
          await this.requestService.performCloudCode('purgeCrisisData', null);
          resetCrisisProgress.next('✅<br/>Reset functions...');
          await this.requestService.performCloudCode('resetFunctions', { createdAt: crisis.createdAt });
          resetCrisisProgress.next('✅<br/>Reset tasks...');
          await this.requestService.performCloudCode('resetTasks', { crisisTypeId: crisis.crisisTypeId, createdAt: crisis.createdAt });
          resetCrisisProgress.next('✅<br/>Reset users...');
          await this.requestService.performCloudCode('resetUsers', null);
          resetCrisisProgress.next('✅<br/>Reset users functions...');
          await this.requestService.performCloudCode('resetUserFunctions', null);
          resetCrisisProgress.next('✅<br/>Reset logbook linked to ECL...');
          await this.requestService.performCloudCode('deleteECLLogbookLinked', null);
          resetCrisisProgress.next('✅<br/>Reset decisions linked to ECL...');
          await this.requestService.performCloudCode('deleteECLDecisionsLinked', null);
          resetCrisisProgress.next('✅<br/>');
          resetCrisisProgress.complete();
        } else {
          resetCrisisProgress.next('No crisis to reset<br/>');
          resetCrisisProgress.complete();
        }
      } catch (e) {
        resetCrisisProgress.error(e);
      }
    });
    return resetCrisisProgress;
  }

  updateCrisisAcl(crisis: ErpCrisis): Promise<ErpCrisis> {
    const oldCrisis = new ErpCrisis(new this.ParseCrisis({ id: crisis.objectId }));
    const oldCompanies = oldCrisis.companies;
    oldCrisis.parseACL(crisis.acl);
    const newCompanies = oldCrisis.companies.filter(c => !oldCompanies.includes(c));
    if (newCompanies.length) {
      return this.requestService
        .performCloudCode<Parse.Object>('updateCrisisACL', { crisisId: crisis.objectId, acl: crisis.acl })
        .then(parseCrisis => {
          this.functionsStoreManager.functionsCrisisErpState
            .pipe(
              take(1),
              map(fns => fns.filter(f => f.notified).map(f => f.functionId)),
            )
            .toPromise()
            .then(functionsIdToNotify => {
              this.erpMailService.sendNewCrisisEmails(crisis, functionsIdToNotify, null, newCompanies);
              this.erpSmsService.sendNewCrisisSms(crisis, functionsIdToNotify, null, newCompanies);
            });
          return new ErpCrisis(parseCrisis);
        });
    } else {
      return Promise.resolve(crisis);
    }
  }
}
