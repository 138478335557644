import { HelperService } from './../../common/services/helper.service';
import { RolesService } from './../../common/services/roles.service';
import { ErpHistoryService } from './erp-history.service';
import { Inject, Injectable } from '@angular/core';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpMailService } from './erp-mail.service';
import { ErpSmsService } from './erp-sms.service';
import { isEqual } from 'lodash';
import { UserService } from 'src/app/common/services/user.service';
import { EclCrisis } from '../../ecl/models/ecl-crisis';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisNewsService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseECLCrisis = Parse.Object.extend('ECLCrisis');
  ParseCrisisNews = Parse.Object.extend('GDCCrisisNews');
  ParseGDCTag = Parse.Object.extend('GDCTag');
  ParseUser = Parse.Object.extend('_User');
  ParseEclLogbook = Parse.Object.extend('ECLLogBook');
  // tslint:enable

  constructor(
    private userService: UserService,
    private crisisStoreManager: CrisisStoreManager,
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private erpMailService: ErpMailService,
    private erpSmsService: ErpSmsService,
    private historyService: ErpHistoryService,
    private helperService: HelperService,
    private rolesService: RolesService,
  ) {}

  getCrisisNewsByCrisisObjectId(crisisObjectId): Promise<ErpCrisisNew[]> {
    let crisisNews: ErpCrisisNew[];
    return new Promise((resolve, reject) => {
      const crisis = new this.ParseCrisis();
      crisis.set('objectId', crisisObjectId);
      const crisisNewsQuery = new Parse.Query(this.ParseCrisisNews);
      crisisNewsQuery.include('ACL');
      crisisNewsQuery.descending('createdAt');
      crisisNewsQuery.equalTo('crisis', crisis);
      crisisNewsQuery.include('createdBy');
      crisisNewsQuery.include('tags');
      crisisNewsQuery.include('eclLogbook');
      this.requestService.performFindQuery(
        crisisNewsQuery,
        crisisNewsFromApi => {
          if (crisisNewsFromApi) {
            crisisNews = crisisNewsFromApi.map(crisisNewFromApi => new ErpCrisisNew(crisisNewFromApi));
            resolve(crisisNews);
          } else {
            resolve([]);
          }
        },
        reject,
      );
    });
  }

  // tslint:disable-next-line: max-line-length
  async saveCrisisNew(
    crisisNew: Partial<ErpCrisisNew>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean; eclCrisis: EclCrisis },
  ): Promise<void> {
    let parseObject;
    let isCreate;
    if (crisisNew.objectId) {
      parseObject = new this.ParseCrisisNews({ id: crisisNew.objectId });
    } else {
      isCreate = true;
      parseObject = new this.ParseCrisisNews();
      parseObject.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
      parseObject.set('crisis', new this.ParseCrisis({ id: crisisObjectId }));
    }
    if (crisisNew.acl) {
      parseObject.setACL(crisisNew.acl);
    }
    if (crisisNew.content) {
      parseObject.set('content', crisisNew.content);
    }
    if (crisisNew.isNotOnDashboard !== undefined) {
      parseObject.set('isNotOnDashboard', crisisNew.isNotOnDashboard);
    }

    if (crisisNew.attachments) {
      parseObject.set('attachments', crisisNew.attachments);
    }

    if (crisisNew.tags && crisisNew.tags.length) {
      const parseTags = crisisNew.tags.map(tag => new this.ParseGDCTag({ id: tag.objectId }));
      parseObject.set('tags', parseTags);
    } else {
      parseObject.set('tags', []);
    }

    if (isArchiveAction) {
      parseObject.set('isArchived', true);
    }

    if (crisisNew.customCreatedAt) {
      parseObject.set('customCreatedAt', crisisNew.customCreatedAt);
    }

    parseObject.set('toECL', crisisNew.toECL);

    await this.requestService.performSaveQuery(
      parseObject,
      null,
      parseData => {
        const bufferCrisisNew: ErpCrisisNew = new ErpCrisisNew(parseData);
        if (isCreate) {
          this.crisisStoreManager.createOneCrisisNew(bufferCrisisNew);
          if (notifications && notifications.length) {
            const addressMailToSend: string[] = this.notificationsService.getAddressMailToSend(notifications);
            const phoneNumbersToSend: string[] = this.notificationsService.getPhoneNumbersToSend(notifications);

            if (addressMailToSend && addressMailToSend.length) {
              this.erpMailService.sendCrisisNewMail(bufferCrisisNew, addressMailToSend);
            }

            if (phoneNumbersToSend && phoneNumbersToSend.length) {
              this.erpSmsService.sendCrisisNewSms(bufferCrisisNew, phoneNumbersToSend);
            }
          }
          if (duplicateToOtherModule.newValue) {
            this.duplicateLogbookToOtherModule(bufferCrisisNew, duplicateToOtherModule.eclCrisis);
          }
          this.historyService
            .postLog(bufferCrisisNew.toLog(historyLogComment, parseData, crisisObjectId))
            .catch(error => console.log(error));
          console.log('crisisNew created :', bufferCrisisNew);
          return;
        }

        if (!isEqual(duplicateToOtherModule.newValue, duplicateToOtherModule.oldValue)) {
          // Supprimer
          if (!duplicateToOtherModule.newValue && duplicateToOtherModule.oldValue) {
            this.deleteDuplicateLogbookFromModule(bufferCrisisNew);
          }
          // Ajouter
          if (duplicateToOtherModule.newValue && !duplicateToOtherModule.oldValue) {
            this.duplicateLogbookToOtherModule(bufferCrisisNew, duplicateToOtherModule.eclCrisis);
          }
        }
        this.crisisStoreManager.updateOneCrisisNew(bufferCrisisNew);
        this.historyService
          .postLog(bufferCrisisNew.toLog(historyLogComment, parseData, crisisNew.crisisObjectId))
          .catch(error => console.log(error));
        console.log('crisisNew updated :', bufferCrisisNew);
      },
      error => {
        console.log(error);
      },
    );
  }

  private async duplicateLogbookToOtherModule(logbook: ErpCrisisNew, eclCrisis: EclCrisis) {
    const parseEclLogbook = new this.ParseEclLogbook();
    parseEclLogbook.set('erpLogbook', new this.ParseCrisisNews({ id: logbook.objectId }));
    parseEclLogbook.set('crisis', new this.ParseECLCrisis({ id: eclCrisis.objectId }));
    const companies = this.helperService.parseACL(logbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ECL'], companies);
    parseEclLogbook.setACL(acl);
    this.requestService.performSaveAllQuery(parseEclLogbook).then();
  }

  private deleteDuplicateLogbookFromModule(logbook: ErpCrisisNew) {
    const parseLogbook = new this.ParseCrisisNews({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseEclLogbook);
    query.equalTo('erpLogbook', parseLogbook);
    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseEclLogbook = new this.ParseEclLogbook({ id: result.id });
        this.requestService.performDestroyQuery(parseEclLogbook).then();
      }
    });
  }

  deleteEclLogbook(crisisNew: ErpCrisisNew) {
    const parseLogbook = new this.ParseCrisisNews({ id: crisisNew.objectId });
    const parseEclLogbook = new this.ParseEclLogbook({ id: crisisNew.eclLogbook.objectId });
    parseEclLogbook.set('toERP', false);
    this.requestService.performSaveQuery(parseEclLogbook).then(() => {
      this.requestService.performDestroyQuery(
        parseLogbook,
        () => {
          this.crisisStoreManager.removeOneCrisisNew(crisisNew.objectId);
        },
        error => {
          console.log(error);
        },
      );
    });
  }

  unArchiveCrisisNew(crisisObjectId: string, crisisNewObjectId: string) {
    let parseObject;

    if (crisisObjectId && crisisNewObjectId) {
      parseObject = new this.ParseCrisisNews({ id: crisisNewObjectId });
      parseObject.set('isArchived', false);
      this.requestService.performSaveQuery(parseObject, null, parseData => {
        const bufferCrisisNew: ErpCrisisNew = new ErpCrisisNew(parseData);
        this.historyService.postLog(bufferCrisisNew.toLog('UNCLOSE', parseData, crisisObjectId)).catch(error => console.log(error));
        this.crisisStoreManager.updateOneCrisisNew(bufferCrisisNew);
      });
    } else {
      throw new Error('crisisObjectId and crisisNewObjectId are required');
    }
  }
}
