'use strict';

angular.module('gestiondecriseApp').directive('todoColor', function () {
  return {
    restrict: 'A',
    scope: {
      date: '=todoColor',
    },
    link: function (scope, elem) {
      var unregisterWatchDate = scope.$watch('date', function () {
        elem.removeClass('hol-error');
        elem.removeClass('hol-warning');
        if (moment(scope.date).isBefore(moment().startOf('day'))) {
          elem.addClass('hol-error');
        } else if (moment(scope.date).isBefore(moment().endOf('day'))) {
          elem.addClass('hol-warning');
        }
      });

      scope.$on('$destroy', function () {
        unregisterWatchDate();
      });
    },
  };
});
