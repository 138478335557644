'use strict';

angular
  .module('gestiondecriseApp')
  .controller('CrewSearchEventModalCtrl', function ($scope, functions, $mdDialog, CrewEventsService, isReadOnly) {
    $scope.searchData = {
      text: '',
      date: null,
      endDate: null,
      onlyClosed: false,
      function: null,
    };
    $scope.searchResults = [];
    $scope.isLoading = false;
    $scope.hasSearched = false;
    $scope.functions = functions;
    $scope.functions.unshift(null);

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.dateOptions = {
      formatYear: 'yyyy',
      startingDay: 1,
      showWeeks: false,
    };

    $scope.changeDate = function () {
      console.log($scope.searchData.Enddate);
      if (!$scope.searchData.endDate || $scope.searchData.date > $scope.searchData.endDate || !$scope.searchData.date) {
        $scope.searchData.endDate = _.cloneDeep($scope.searchData.date);
      }
    };

    $scope.changeEndDate = function () {
      console.log($scope.searchData.Enddate);
      if (!$scope.searchData.date || $scope.searchData.date > $scope.searchData.endDate || !$scope.searchData.endDate) {
        $scope.searchData.date = _.cloneDeep($scope.searchData.endDate);
      }
    };

    $scope.search = function () {
      $scope.isLoading = true;
      CrewEventsService.search($scope.searchData)
        .then(events => {
          $scope.searchResults = events;
          $scope.hasSearched = true;
        })
        .finally(() => ($scope.isLoading = false));
    };

    $scope.openEventModal = function ($event, event) {
      $mdDialog
        .show({
          controller: 'CrewEventModalCtrl',
          templateUrl: 'views/crew/modals/event.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          skipHide: true,
          hasBackdrop: false,
          locals: {
            functions: functions,
            event: event,
            isReadOnly: isReadOnly,
          },
        })
        .then(() => $scope.search());
    };
  });
