import { HolObject } from 'src/app/common/models/hol-object';

export class ErpFunctionCrisis extends HolObject {
  objectId: string;
  functionId: string;
  phone: string;
  tagId: string;
  tasksSummary: string;
  title: string;
  shortTitle: string;
  email: string;
  notified: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.functionId = parseObject.get('functionId');
    this.phone = parseObject.get('phone');
    this.tagId = parseObject.get('tagId');
    this.tasksSummary = parseObject.get('tasksSummary');
    this.title = parseObject.get('title');
    this.shortTitle = parseObject.get('shortTitle');
    this.email = parseObject.get('email');
    this.notified = parseObject.get('notified');
  }
}
