import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { RequestService } from 'src/app/common/services/request.service';

import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import { OclGlobalInstructionsStoreManager } from '../../store/global-instructions/ocl-global-instructions-store-manager.service';
import { OclGlobalInstructionTagService } from '../ocl-global-instruction-tag-service/ocl-global-instruction-tag.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclMailService } from '../ocl-mail-service/ocl-mail.service';
import { OclSmsService } from '../ocl-sms-service/ocl-sms.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OclGlobalInstructionService<T extends OclGlobalInstruction = OclGlobalInstruction> {
  // tslint:disable:variable-name
  protected abstract ParseGlobalInstruction;
  protected abstract ParseGlobalInstructionTag;
  protected abstract ParseTag;
  private ParseUser = Parse.Object.extend('_User');
  // tslint:enabled
  public _data: T[] = []; // @cache

  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected globalInstructionTagService: OclGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected notificationsService: NotificationsService,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    public moduleConfig: ModuleConfigService,
  ) {}

  /**
   * get all the logbooks
   * @param forceRefresh if refresh is forced, to nut use cash
   */

  public getAll(forceRefresh: boolean, isFromPooling?): Promise<T[]> {
    return new Promise((resolve, reject) => {
      if (this._data !== undefined && this._data.length && !forceRefresh) {
        resolve(this._data);
      } else {
        let query = new Parse.Query(this.ParseGlobalInstruction);
        query.notEqualTo('archived', true);
        query.addDescending('createdAt');
        query.include('createdBy');
        query = this.getAdditionnalQueries(query);
        return this.requestService.performFindQuery(query).then(
          parseGlobalInstructions => {
            const tagsQuery = new Parse.Query(this.ParseGlobalInstructionTag);
            tagsQuery.containedIn('globalInstruction', parseGlobalInstructions);
            tagsQuery.include('tag');
            tagsQuery.descending('createdAt');
            return this.requestService.performFindQuery(tagsQuery).then(
              gins => {
                const globalInstructions = parseGlobalInstructions.map(gin => this._newOclGlobalInstruction(gin, gin.get('tag')));
                globalInstructions.forEach(globalInstruction => {
                  globalInstruction.tags = gins
                    .filter(gin => gin.get('globalInstruction').id === globalInstruction.objectId && gin.get('tag'))
                    .map(gin => new HolTag(gin.get('tag')));
                });
                resolve(globalInstructions);
              },
              error => reject(error),
            );
          },
          error => reject(error),
        );
      }
    });
  }

  public save(globalInstruction: OclGlobalInstruction, notifications?: HolNotification[]): Promise<T> {
    const parseObject = globalInstruction.objectId
      ? new this.ParseGlobalInstruction({ id: globalInstruction.objectId })
      : new this.ParseGlobalInstruction();
    parseObject.setACL(globalInstruction.acl);
    parseObject.set('description', globalInstruction.description);
    parseObject.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
    this.setAdditionalFields(globalInstruction, parseObject);
    if (globalInstruction.attachments) {
      parseObject.set('attachments', JSON.stringify(globalInstruction.attachments));
    }
    return new Promise((resolve, reject) => {
      return this.requestService.performSaveQuery(parseObject).then(
        globalInstructionParseSaved => {
          let newglobalInstruction;
          if (globalInstruction.objectId) {
            this.globalInstructionTagService.updateGlobalInstructionWithTags(globalInstruction).then(
              async globalInstructionTags => {
                const tags = await this.globalInstructionTagService.getTags(globalInstructionTags, globalInstructionParseSaved);
                newglobalInstruction = this._newOclGlobalInstruction(globalInstructionParseSaved, tags);
                // STORE
                this.globalInstructionsStoreManager.updateOneGlobalInstruction(newglobalInstruction);
                resolve(newglobalInstruction);
              },
              error => reject(error),
            );
          } else {
            this.globalInstructionTagService.createTagFromParseData(globalInstruction, globalInstructionParseSaved).then(
              async globalInstructionTags => {
                const tags = await this.globalInstructionTagService.getTags(globalInstructionTags, globalInstructionParseSaved);
                newglobalInstruction = this._newOclGlobalInstruction(globalInstructionParseSaved, tags);
                // STORE
                this.globalInstructionsStoreManager.addOneGlobalInstruction(newglobalInstruction);
                resolve(newglobalInstruction);
              },
              error => reject(error),
            );
          }
          if (notifications) {
            const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
            const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
            if (addressMailToSend.length) {
              this.mailService.sendNewGlobalInstructionMail(globalInstruction, addressMailToSend);
            }
            if (phoneNumbersToSend.length) {
              this.smsService.sendNewGlobalInstructionSMS(globalInstruction, phoneNumbersToSend);
            }
          }
        },
        error => reject(error),
      );
    });
  }

  public fetchNewData(isFromPooling: boolean = false): Promise<void | never> {
    return this.getAll(true, isFromPooling).then(globalInsttructions => {
      // STORE
      this.globalInstructionsStoreManager.updateGlobalInstructionsFromPooling(globalInsttructions, this.moduleConfig.config.moduleName);
    });
  }
  private _newOclGlobalInstruction(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return this.newOclGlobalInstruction(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  protected newOclGlobalInstruction(parseObject?: Parse.Object, tags?: HolTag[]): T {
    return new OclGlobalInstruction(parseObject, tags) as T;
  }

  public archive(globalInstruction: OclGlobalInstruction): Promise<T> {
    const parseGlobalInstruction = new this.ParseGlobalInstruction({ id: globalInstruction.objectId });
    parseGlobalInstruction.set('archived', true);
    parseGlobalInstruction.set('archivedDate', new Date());
    return this.requestService.performSaveQuery(parseGlobalInstruction).then(async savedGlobalInstruction => {
      // STORE
      this.globalInstructionsStoreManager.deleteOneGlobalInstruction(savedGlobalInstruction.id);

      return this.newOclGlobalInstruction(savedGlobalInstruction, globalInstruction.tags);
    });
  }

  protected setAdditionalFields(globalInstruction: OclGlobalInstruction, parseGlobalInstruction: Parse.Object) {
    return;
  }

  protected getAdditionnalQueries(query: Parse.Query): Parse.Query {
    return query;
  }
}
