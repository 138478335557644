import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModuleConfigService } from '../../services/module-config/module-config.service';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public moduleConfig: ModuleConfigService,
    public dialogRef: MatDialogRef<FormModalComponent>,
  ) {}

  ngOnInit() {
    if (!this.data) {
      throw new Error('FormModalComponent::data is required');
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  valid(): void {
    this.dialogRef.close(this.data);
  }
}
