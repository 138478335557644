import { HolTag } from 'src/app/common/models/hol-tag';
import { FltDecision } from '../../flt/models/flt-decision';

export class GocDecision extends FltDecision {
  isPrivate: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.isPrivate = parseObject.get('isPrivate');
  }
}
