import { Inject, Injectable } from '@angular/core';
import { HolUser } from '../models/hol-user.model';
import { TirednessIndicator } from '../../crew/models/tiredness-indicator.model';
import { OclEvent } from '../../ocl/models/ocl-event.model';
import { HolNextInfo } from '../models/hol-next-info.model';
import { HolTag } from '../models/hol-tag';
import { MccAircraft } from '../../mcc/models/mcc-aircraft.model';
import { MccAtaCode } from '../../mcc/models/mcc-ata-code.model';
import { map, orderBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ParseMapperService {
  constructor(@Inject('$translate') private $translate: any) {}

  public nextInfoToObject(parseObject: Parse.Object): HolNextInfo {
    return new HolNextInfo(parseObject);
  }

  public crewSituationToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      text: parseObject.get('text'),
      impact: parseObject.get('impact'),
      impactDate: parseObject.get('impactDate'),
      validityDate: parseObject.get('validityDate'),
      direction: parseObject.get('direction'),
      status: parseObject.get('status'),
      statusText: parseObject.get('statusText'),
      statusDate: parseObject.get('statusDate'),
      user: this.crewUserToObject(parseObject.get('user')),
      event: this.crewEventToObject(parseObject.get('event')),
      createdAt: parseObject.get('createdAt'),
      createdBy: this.userToObject(parseObject.get('createdBy')),
      isClosed: parseObject.get('isClosed'),
      closeReasonText: parseObject.get('closeReasonText'),
      closedBy: this.crewUserToObject(parseObject.get('closedBy')),
      closedAt: parseObject.get('closedAt'),
    };
  }

  public crewUserToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }

    return {
      objectId: parseObject.id,
      firstName: parseObject.get('firstName'),
      lastName: parseObject.get('lastName'),
      userId: parseObject.get('userId'),
      phone: parseObject.get('phone'),
      email: parseObject.get('email'),
      trigram: parseObject.get('trigram'),
      noteGlobal: parseObject.get('noteGlobal'),
      monogram() {
        return (
          (this.firstName && this.firstName.length ? this.firstName[0] : '') +
          (this.lastName && this.lastName.length ? this.lastName[0] : '')
        );
      },
    };
  }

  public crewEventToObject(parseObject, parseCrewEventUsers?, parseCrewNotes?): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      nbPersons: parseObject.get('nbPersons'),
      function: this.crewFunctionToObject(parseObject.get('function')),
      impact: parseObject.get('impact'),
      date: parseObject.get('date'),
      closeReason: parseObject.get('closeReason'),
      closeReasonText: parseObject.get('closeReasonText'),
      createdBy: this.userToObject(parseObject.get('createdBy')),
      notes: map(parseCrewNotes, this.crewEventNoteToObject.bind(this)),
      users: map(parseCrewEventUsers, ceu => {
        const user = this.crewUserToObject(ceu.get('user'));
        user.eventUserObjectId = ceu.id;
        return user;
      }),
      channelId: parseObject.get('channelId'),
    };
  }

  public crewFunctionToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      code: parseObject.get('code'),
      label: parseObject.get('label'),
      order: parseObject.get('order'),
      allowedFunctions: parseObject.get('allowedFunctions') && parseObject.get('allowedFunctions').split('|'),
    };
  }

  public userToObject(parseObject): HolUser {
    if (!parseObject) {
      return null;
    }
    return new HolUser(parseObject);
  }

  public crewEventNoteToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      text: parseObject.get('text'),
      createdBy: this.userToObject(parseObject.get('createdBy')),
      createdAt: parseObject.get('createdAt'),
      toOCC: parseObject.get('toOCC') ? parseObject.get('toOCC') : false,
    };
  }

  public crewLogToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      type: parseObject.get('type'),
      situation: parseObject.get('situation'),
      event: parseObject.get('event'),
      vacation: parseObject.get('vacation'),
      createdAt: parseObject.get('createdAt'),
    };
  }

  public crewSituationHelpTextToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      text: parseObject.get('text'),
      status: parseObject.get('status'),
    };
  }

  public crewSituationWaitingHelpTextToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      text: parseObject.get('text'),
    };
  }

  public crewTirednessIndicatorToObject(parseObject): TirednessIndicator {
    if (!parseObject) {
      return null;
    }
    return new TirednessIndicator(parseObject.get('tlc'), parseObject.get('noteGlobal'));
  }

  public occEventToObject(parseObject, infosToMap?, eventTags?): OclEvent {
    if (!parseObject) {
      return null;
    }
    const infos = map(infosToMap, info => new HolNextInfo(info));
    const tags = orderBy(
      map(eventTags, et => new HolTag(et.get('tag'))),
      'name',
    );
    return new OclEvent(parseObject, tags, infos);
  }

  public mccEventToOCCEventObject(mccEvent, defaultScenario): OclEvent {
    if (!mccEvent) {
      return null;
    }
    const occEvent = new OclEvent();
    occEvent.fromMCC = true;
    occEvent.scenario = defaultScenario || {
      code: this.$translate.instant('OCC.COMMON.DEFAULT_SCENARIO_CODE'),
      title: this.$translate.instant('OCC.COMMON.DEFAULT_SCENARIO_TITLE'),
    };
    let description = mccEvent.aircraft ? mccEvent.aircraft.registration : '';
    description += ' - ';
    description += mccEvent.ataCode.code;
    description += ' - ';
    description += mccEvent.localisation;
    description += ' - ';
    description += mccEvent.description;
    occEvent.description = description;
    occEvent.objectId = mccEvent.objectId;
    occEvent.infos = mccEvent.infos;
    occEvent.attachments = mccEvent.attachments;
    occEvent.createdAt = mccEvent.createdAt;
    occEvent.originalEvent = mccEvent;
    return occEvent;
  }

  public crisisNoteToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      text: parseObject.get('text'),
      infoNumber: parseObject.get('infoNumber'),
      createdAt: parseObject.get('createdAt'),
    };
  }

  public mccAirCraftToObject(parseObject, events?, mels?): MccAircraft {
    if (!parseObject) {
      return null;
    }
    const aircraft = new MccAircraft(parseObject);
    aircraft.events = events;
    aircraft.mels = mels;
    return aircraft;
  }

  public mccEventToObject(parseObject, parseInfos): any {
    if (!parseObject) {
      return null;
    }
    const infos = map(parseInfos, info => {
      return this.mccEventInfoToObject(info);
    });
    return {
      objectId: parseObject.id,
      createdAt: parseObject.get('createdAt'),
      localisation: parseObject.get('localisation'),
      description: parseObject.get('description'),
      attachments: parseObject.get('attachments') ? JSON.parse(parseObject.get('attachments')) : {},
      createdBy: this.userToObject(parseObject.get('createdBy')),
      aircraft: new MccAircraft(parseObject.get('aircraft')),
      ataCode: new MccAtaCode(parseObject.get('ataCode')),
      toOCC: parseObject.get('toOCC'),
      channelId: parseObject.get('channelId'),
      closeReason: parseObject.get('closeReason'),
      closeReasonText: parseObject.get('closeReasonText'),
      localisationUpdatedAt: parseObject.get('localisationUpdatedAt'),
      updatedAt: parseObject.get('updatedAt'),
      infos,
    };
  }

  public mccEventInfoToObject(parseObject): any {
    return !parseObject ? null : new HolNextInfo(parseObject);
  }

  public mccMELInfoToObject(parseObject): any {
    return !parseObject ? null : new HolNextInfo(parseObject);
  }

  public mccMELToObject(parseObject, parseInfos): any {
    if (!parseObject) {
      return null;
    }
    const infos = map(parseInfos, info => this.mccMELInfoToObject(info));
    return {
      objectId: parseObject.id,
      aircraft: parseObject.get('aircraft'),
      dueDate: parseObject.get('dueDate'),
      foundDate: parseObject.get('foundDate'),
      faultType: parseObject.get('faultType'),
      reference: parseObject.get('reference'),
      barcode: parseObject.get('barcode'),
      faultName: parseObject.get('faultName'),
      updatedAt: parseObject.get('updatedAt'),
      attachments: parseObject.get('attachments') ? JSON.parse(parseObject.get('attachments')) : {},
      infos,
    };
  }

  public flightToObject(parseObject): any {
    if (!parseObject) {
      return null;
    }
    return {
      objectId: parseObject.id,
      flightNumber: parseObject.get('flightNumber'),
      registration: parseObject.get('registration'),
      std: parseObject.get('std'),
      departure: parseObject.get('departure'),
      sta: parseObject.get('sta'),
      destination: parseObject.get('destination'),
      cdb: parseObject.get('cdb'),
      ccp: parseObject.get('ccp'),
      occ: parseObject.get('occ'),
      crew: parseObject.get('crew'),
      mcc: parseObject.get('mcc'),
      goc: parseObject.get('goc'),
    };
  }
}
