import { orderBy, sortBy, unionBy } from 'lodash';
import { Moment } from 'moment';
import { HolTag } from 'src/app/common/models/hol-tag';
import { GocFlightLogbook } from './goc-flight-logbook.model';
import { GocFlight } from './goc-flight.model';
import { GocOfficer } from './goc.officer.model';
export class GocStopover {
  inFlight: GocFlight;
  outFlight: GocFlight;
  airport: string;
  naturalKey: string;
  registration?: string;
  parkingArea?: string;
  inFlightCondensedInfos: {
    flightNumber: string;
    date: Date | Moment;
    officer: GocOfficer;
  };
  outFlightCondensedInfos: {
    flightNumber: string;
    date: Date | Moment;
    officer: GocOfficer;
  };
  tags?: HolTag[];
  lastLogbookEntry: GocFlightLogbook;
  mostCriticalInstructionTime: Date;
  hasAttachment: boolean;
  // events: FltEvent[];
  // logbooks: GocFlightLogbook[];
  // instructions: GocFlightInstruction[];

  constructor(inFlight: GocFlight, outFlight: GocFlight) {
    if (inFlight && !outFlight) {
      this.inFlight = inFlight;
      this.airport = inFlight.destination;
      this.registration = inFlight.registration;
      this.naturalKey = `${this.inFlight.objectId}-${this.airport}-xxx`;
      this.inFlightCondensedInfos = {
        flightNumber: inFlight.flightNumber,
        date: inFlight.sta,
        officer: inFlight.gocOfficer,
      };
      this.parkingArea = this.inFlight.parkingArea;
      this.tags = this.inFlight.tags;
    } else if (!inFlight && outFlight) {
      this.outFlight = outFlight;
      this.registration = outFlight.registration;
      this.airport = outFlight.departure;
      this.naturalKey = `xxx-${this.airport}-${this.outFlight.objectId}`;
      this.outFlightCondensedInfos = {
        flightNumber: outFlight.flightNumber,
        date: outFlight.std,
        officer: outFlight.gocOfficer,
      };
      this.parkingArea = this.outFlight.parkingArea;
      this.tags = this.outFlight.tags;
    } else if (inFlight.destination !== outFlight.departure) {
      throw new Error(
        `Stopover::Arriving destination different from departing departure (${inFlight.destination} != ${outFlight.departure})`,
      );
    } else {
      this.inFlight = inFlight;
      this.outFlight = outFlight;
      this.airport = inFlight.destination;
      this.registration = inFlight.registration;
      this.naturalKey = `${this.inFlight.objectId}-${this.airport}-${this.outFlight.objectId}`;
      this.parkingArea = this.inFlight.parkingArea;
      this.hasAttachment = Object.keys({ ...this.inFlight.opsAttachments, ...this.outFlight.opsAttachments }).length !== 0;
      this.inFlightCondensedInfos = {
        flightNumber: inFlight.flightNumber,
        date: inFlight.std,
        officer: inFlight.gocOfficer,
      };
      this.outFlightCondensedInfos = {
        flightNumber: outFlight.flightNumber,
        date: outFlight.std,
        officer: inFlight.gocOfficer,
      };
      this.tags = unionBy(
        [
          ...(this.inFlight.tags && this.inFlight.tags.length ? this.inFlight.tags : []),
          ...(this.outFlight.tags && this.outFlight.tags.length ? this.outFlight.tags : []),
        ],
        'name',
      );

      const tempInFlightlastLogbookEntry =
        this.inFlight && this.inFlight.lastLogbookEntry && this.inFlight.lastLogbookEntry.station !== 'DEP'
          ? this.inFlight.lastLogbookEntry
          : undefined;
      const tempOuFlightlastLogbookEntry =
        this.outFlight && this.outFlight.lastLogbookEntry && this.outFlight.lastLogbookEntry.station !== 'ARR'
          ? this.outFlight.lastLogbookEntry
          : undefined;

      if (tempInFlightlastLogbookEntry && tempOuFlightlastLogbookEntry) {
        this.lastLogbookEntry = tempInFlightlastLogbookEntry;
      } else if (!tempInFlightlastLogbookEntry && tempOuFlightlastLogbookEntry) {
        this.lastLogbookEntry = tempOuFlightlastLogbookEntry;
      } else if (tempInFlightlastLogbookEntry && tempOuFlightlastLogbookEntry) {
        this.lastLogbookEntry = orderBy([tempOuFlightlastLogbookEntry, tempInFlightlastLogbookEntry], 'createdAt')[0];
      } else {
        this.lastLogbookEntry = undefined;
      }

      if (this.inFlight.mostCriticalInstructionTime && !this.outFlight.mostCriticalInstructionTime) {
        this.mostCriticalInstructionTime = this.inFlight.mostCriticalInstructionTime;
      } else if (!this.inFlight.mostCriticalInstructionTime && this.outFlight.mostCriticalInstructionTime) {
        this.mostCriticalInstructionTime = this.outFlight.mostCriticalInstructionTime;
      } else if (this.inFlight.mostCriticalInstructionTime && this.outFlight.mostCriticalInstructionTime) {
        this.mostCriticalInstructionTime = sortBy([
          this.outFlight.mostCriticalInstructionTime,
          this.inFlight.mostCriticalInstructionTime,
        ])[0];
      } else {
        this.mostCriticalInstructionTime = undefined;
      }
    }

    // this.recomputeItems();
  }

  // public recomputeItems() {
  //   this.instructions = this.getInstructions();
  //   this.logbooks = this.getLogbooks();
  //   this.events = this.getEvents();
  // }

  // private getInstructions() {
  //   let instructions = [];
  //   if (this.departingFlight && this.departingFlight.instructions) {
  //     instructions = instructions.concat(
  //       this.departingFlight.instructions.filter((ins: GocFlightInstruction) => {
  //         return ins.stopoverName === this.airport;
  //       })
  //     );
  //   }
  //   if (this.arrivingFlight && this.arrivingFlight.instructions) {
  //     instructions = instructions.concat(
  //       this.arrivingFlight.instructions.filter((ins: GocFlightInstruction) => {
  //         return ins.stopoverName === this.airport;
  //       })
  //     );
  //   }
  //   return orderBy(uniqBy(instructions, 'objectId'), 'validFrom');
  // }

  // private getLogbooks() {
  //   let logbooks = [];
  //   if (this.departingFlight && this.departingFlight.logbooks) {
  //     logbooks = logbooks.concat(
  //       this.departingFlight.logbooks.filter((lb: GocFlightLogbook) => {
  //         return lb.airport1 === this.airport || lb.airport2 === this.airport;
  //       })
  //     );
  //   }
  //   if (this.arrivingFlight && this.arrivingFlight.logbooks) {
  //     logbooks = logbooks.concat(
  //       this.arrivingFlight.logbooks.filter((lb: GocFlightLogbook) => {
  //         return lb.airport1 === this.airport || lb.airport2 === this.airport;
  //       })
  //     );
  //   }
  //   return orderBy(uniqBy(logbooks, 'objectId'), 'createdAt', 'desc');
  // }

  // private getEvents() {
  //   let events = [];
  //   if (this.departingFlight && this.departingFlight.events) {
  //     events = events.concat(
  //       this.departingFlight.events.filter((ev: GocEvent) => {
  //         return ev.airport === this.airport;
  //       })
  //     );
  //   }
  //   if (this.arrivingFlight && this.arrivingFlight.events) {
  //     events = events.concat(
  //       this.arrivingFlight.events.filter((ev: GocEvent) => {
  //         return ev.airport === this.airport;
  //       })
  //     );
  //   }
  //   return orderBy(uniqBy(events, 'objectId'), 'createdAt', 'desc');
  // }
}
