import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UnitAndValue, Weather } from './weather.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const WINDY_API_URL = 'https://api.windy.com/api/point-forecast/v2';
const WINDY_POINT_API_KEY = 'PdDfdB9VBzuS6FpjBLornrGdAsOoujZX';
const WINDY_WEATHER_MODEL = 'gfs';
const WINDY_WEATHER_PARAMETERS = ['temp', 'precip', 'wind', 'windGust', 'lclouds', 'mclouds', 'hclouds', 'rh', 'pressure'];
const WINDY_SEA_MODEL = 'gfsWave';
const WINDY_SEA_PARAMETERS = ['waves', 'windWaves'];

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private httpClient: HttpClient, private decimalPipe: DecimalPipe, private datePipe: DatePipe) {}

  getCurrentWeather(lat: number, long: number): Observable<Weather> {
    return this.httpClient
      .post(
        WINDY_API_URL,
        {
          lat: lat,
          lon: long,
          model: WINDY_WEATHER_MODEL,
          parameters: WINDY_WEATHER_PARAMETERS,
          levels: ['surface'],
          key: WINDY_POINT_API_KEY,
        },
        {
          responseType: 'json',
        },
      )
      .pipe(map(v => this.windyResultToMap(v)[0]));
  }

  getWeatherForecast(lat: number, long: number): Observable<Weather[]> {
    const weather = this.httpClient.post(
      WINDY_API_URL,
      {
        lat: lat,
        lon: long,
        model: WINDY_WEATHER_MODEL,
        parameters: WINDY_WEATHER_PARAMETERS,
        levels: ['surface'],
        key: WINDY_POINT_API_KEY,
      },
      {
        responseType: 'json',
      },
    );
    const sea = this.httpClient.post(
      WINDY_API_URL,
      {
        lat: lat,
        lon: long,
        model: WINDY_SEA_MODEL,
        parameters: WINDY_SEA_PARAMETERS,
        levels: ['surface'],
        key: WINDY_POINT_API_KEY,
      },
      {
        responseType: 'json',
      },
    );
    return forkJoin([weather, sea]).pipe(map(v => this.windyResultToMap(v)));
  }

  private parseWeather(result): Weather {
    const date = result['ts'];
    const temp = result['temp-surface'];
    const prec = result['past3hprecip-surface'];
    const clouds = [result['lclouds-surface'], result['mclouds-surface'], result['hclouds-surface']].reduce(
      (oldV, v) => (v > oldV ? v : oldV), // max of the 3 values
      0.0,
    );
    const wind = [result['wind_u-surface'], result['wind_v-surface']];
    const gust = result['gust-surface'];
    const humid = result['rh-surface'];
    const press = result['pressure-surface'];
    const wave_direction = result['waves_direction-surface'];
    const wave_height = result['waves_height-surface'];
    const wave_period = result['waves_period-surface'];
    const wwave_direction = result['wwaves_direction-surface'];
    const wwave_height = result['wwaves_height-surface'];
    const wwave_period = result['wwaves_period-surface'];
    const datetime = new Date(date);

    return {
      date: datetime,
      temperature: new UnitAndValue(temp - 273.15, '\u00b0C'), // K to C
      precipitation: new UnitAndValue(prec * 1000.0, 'mm'), // m to mm
      clouds: new UnitAndValue(clouds, '%'),
      wind: new UnitAndValue(
        wind.map(w => w * 3.6), // m/s to km/h
        'km/h',
      ),
      gust: new UnitAndValue(gust * 3.6, 'km/h'),
      humidity: new UnitAndValue(humid, '%'),
      pressure: new UnitAndValue(press / 100.0, 'hPa'), // Pa to hPa
      waves: {
        wavesHeight: new UnitAndValue(wave_height, 'm'),
        wavesDirection: new UnitAndValue(wave_direction, 'deg'),
        wavesPeriod: new UnitAndValue(wave_period, 's'),
        wavesWindHeight: new UnitAndValue(wwave_height, 'm'),
        wavesWindDirection: new UnitAndValue(wwave_direction, 'deg'),
        wavesWindPeriod: new UnitAndValue(wwave_period, 's'),
      },
      isEndDate: datetime.getHours() === 22,
    };
  }

  private windyResultToMap(result): Weather[] {
    const [weather, sea] = result;
    const now = new Date().getTime();
    const getFieldNextIndex = (result: any): number => {
      // We will take the closest value to current UTC time
      return Math.max(result.ts.filter(t => t <= now).length - 1, 0);
    };

    const retWeather = [];

    for (let i = getFieldNextIndex(weather); i < weather['ts'].length; i++) {
      retWeather.push({
        ts: weather['ts'][i],
        'temp-surface': weather['temp-surface'][i],
        'past3hprecip-surface': weather['past3hprecip-surface'][i],
        'lclouds-surface': weather['lclouds-surface'][i],
        'mclouds-surface': weather['mclouds-surface'][i],
        'hclouds-surface': weather['hclouds-surface'][i],
        'wind_u-surface': weather['wind_u-surface'][i],
        'wind_v-surface': weather['wind_v-surface'][i],
        'gust-surface': weather['gust-surface'][i],
        'rh-surface': weather['rh-surface'][i],
        'pressure-surface': weather['pressure-surface'][i],
        'waves_direction-surface': sea['waves_direction-surface'][i],
        'waves_height-surface': sea['waves_height-surface'][i],
        'waves_period-surface': sea['waves_period-surface'][i],
        'wwaves_direction-surface': sea['wwaves_direction-surface'][i],
        'wwaves_height-surface': sea['wwaves_height-surface'][i],
        'wwaves_period-surface': sea['waves_period-surface'][i],
      });
    }

    return retWeather.map(v => {
      return this.parseWeather(v);
    });
  }
}
