import { EclCrisis } from './ecl-crisis';
import { OclEventGroup } from '../../ocl/models/ocl-event-group.model';
import { EclEvent } from './ecl-event.model';

export class EclEventGroup extends OclEventGroup<EclEvent> {
  crisis: EclCrisis;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }

  protected newItem(parseObject?: Parse.Object): EclEvent {
    return new EclEvent(parseObject);
  }
}
