import { Injectable } from '@angular/core';
import { OclGroupService, OclGroupType } from '../../../ocl/services/ocl-group-service/ocl-group.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { RequestService } from '../../../common/services/request.service';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { EclCrisis } from '../../models/ecl-crisis';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { EclEventGroup } from '../../models/ecl-event-group.model';
import { EclLogbookGroup } from '../../models/ecl-logbook-group.model';
import { EclDecisionGroup } from '../../models/ecl-decision-group.model';
import { EclGlobalInstructionGroup } from '../../models/ecl-global-instruction-group.model';
import { EclGroupsStoreManager } from '../../store/groups/ecl-groups.store-manager';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EclGroupService extends OclGroupService {
  // tslint:disable:variable-name
  ParseEventGroup = Parse.Object.extend('ECLEventGroup');
  ParseDecisionGroup = Parse.Object.extend('ECLDecisionGroup');
  ParseLogbookGroup = Parse.Object.extend('ECLLogBookGroup');
  ParseGlobalInstructionGroup = Parse.Object.extend('ECLGlobalInstructionGroup');
  ParseEvent = Parse.Object.extend('ECLEvents');
  ParseDecisions = Parse.Object.extend('ECLDecisions');
  ParseLogbook = Parse.Object.extend('ECLLogBook');
  ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  ParseGlobalInstruction = Parse.Object.extend('ECLGlobalInstruction');
  protected eclSelectedCrisis: EclCrisis;

  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: EclGroupsStoreManager,
    protected moduleConfig: EclModuleConfigService,
    public commonStoreManager: CommonStoreManager,
    public eclCrisisStoreManager: EclCrisisStoreManager,
  ) {
    super(requestService, groupStoreManager, moduleConfig);
    this.eclCrisisStoreManager.$eclSelectedCrisis.subscribe(c => (this.eclSelectedCrisis = c));
  }

  async getAll(type: OclGroupType, isFromPolling = false): Promise<any[]> {
    const { parseGroup, storeType } = this.groupTypeMap.get(type);
    let query = new Parse.Query(parseGroup);
    query.descending('createdAt');
    query = this.setAdditionalQueryFilters(query);

    const results = await this.requestService.performFindQuery(query);
    const groups = results.map(result => this.newItem(type, result));

    const summaries = await this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).toPromise();
    groups.forEach(group => {
      if (summaries && summaries.length > 0) {
        if (type == 'LOGBOOK') {
          const summary = summaries.find(s => s.logBookGroup && s.logBookGroup.objectId === group.objectId);
          group.summary = summary;
        } else if (type == 'DECISION') {
          const summary = summaries.find(s => s.decisionGroup && s.decisionGroup.objectId === group.objectId);
          group.summary = summary;
        }
      }
    });

    if (isFromPolling) {
      this.groupStoreManager.updateGroupsFromPooling(groups, storeType, 'ocl');
    } else {
      this.groupStoreManager.initGroups(groups, storeType);
    }
    return groups;
  }

  protected setAdditionalQueryFilters(query: Parse.Query): Parse.Query {
    // No need to filter by crisis. The filter is made in the store manager via observables
    /*
     if (this.eclSelectedCrisis) {
       const crisisSubQuery = new Parse.Query(this.ParseEclCrisis);
       crisisSubQuery.equalTo('objectId', this.eclSelectedCrisis.objectId);
       query.matchesQuery('crisis', crisisSubQuery);
     }
     */
    return query;
  }

  protected setAdditionalFields(parseGroup: Parse.Object, group: OclGroup<any>, type: OclGroupType) {
    if (parseGroup.className !== 'ECLGlobalInstructionGroup') {
      parseGroup.set(
        'crisis',
        new this.ParseEclCrisis({
          id: this.eclSelectedCrisis.objectId,
        }),
      );

      const { parseObject } = this.groupTypeMap.get(type);

      if (type === 'LOGBOOK') {
        if ((group as EclLogbookGroup).logbooksInSummary) {
          const parseItems = (group as EclLogbookGroup).logbooksInSummary.map(el => {
            return new parseObject({ id: el.objectId });
          });

          parseGroup.set('logbooksInSummary', parseItems);
        } else {
          parseGroup.set('logbooksInSummary', []);
        }
      }

      if (type === 'DECISION') {
        if ((group as EclDecisionGroup).decisionsInSummary) {
          const parseItems = (group as EclDecisionGroup).decisionsInSummary.map(el => {
            return new parseObject({ id: el.objectId });
          });
          parseGroup.set('decisionsInSummary', parseItems);
        } else {
          parseGroup.set('decisionsInSummary', []);
        }
      }
    }
  }

  protected newItem(type: OclGroupType, parseData?: Parse.Object): OclGroup<any> {
    switch (type) {
      case 'EVENT':
        return new EclEventGroup(parseData);
      case 'LOGBOOK':
        return new EclLogbookGroup(parseData);
      case 'DECISION':
        return new EclDecisionGroup(parseData);
      case 'GLOBAL_INSTRUCTION':
        return new EclGlobalInstructionGroup(parseData);
    }
    return null;
  }
}
