<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper group-details-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <i *ngIf="!isCreate" aria-hidden="true" class="fa fa-pencil"></i>
      <span>{{ modalTitle }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="col full-width">
        <mat-form-field *ngIf="isCreate" class="description-form-field full-width" tabindex="-1">
          <textarea
            [matAutocomplete]="auto"
            class="description-textarea"
            formControlName="titleForm"
            matInput
            matTextareaAutosize
            maxlength="64"
            placeholder="{{
              moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
            }}"
            type="text"
          >
          </textarea>
          <!--
          <textarea
            (ngModel)="(selectedTitle)"
            (ngModelChange)="onTitleChange($event)"
            [formControl]="myControl"
            [matAutocomplete]="auto"
            class="description-textarea"
            formControlName="titleForm"
            matInput
            matTextareaAutosize
            maxlength="64"
            placeholder="{{
              moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
            }}"
            type="text"
          >
          </textarea>

          -->
          <mat-autocomplete #auto="matAutocomplete" isOpen="filteredTitleList.length !== 0">
            <mat-option (onSelectionChange)="selectGroup(option)" *ngFor="let option of filteredTitleList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- <span class="info-msg-title">{{ infoMsgTitle }}</span> -->
        <mat-form-field *ngIf="!isCreate" class="description-form-field full-width" tabindex="-1">
          <textarea
            class="description-textarea"
            formControlName="titleForm"
            matInput
            matTextareaAutosize
            maxlength="64"
            placeholder="{{
              moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
            }}"
          >
          </textarea>
        </mat-form-field>

        <ng-container>
          <app-ocl-summary *ngIf="isSummaryActivated" [(isInSummary)]="isInSummary" [(isInBriefing)]="isInBriefing" [invertedStyle]="true">
          </app-ocl-summary>
        </ng-container>

        <div *ngIf="items.length" class="event-group">
          <div class="content-row">
            <div class="content-column">
              <span class="selection-text">{{
                moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.SELECT_TEXT' | translate
              }}</span>
            </div>
            <div *ngIf="isInSummary && anyItemSelected" class="content-column">
              <span class="selection-text">Publier en synthèse</span>
            </div>
          </div>
          <ul class="hol-list">
            <li *ngFor="let item of itemsForm.controls; let i = index">
              <div formArrayName="itemsForm">
                <mat-checkbox *ngIf="type === 'EVENT'" [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary">
                  <span *ngIf="items[i]" class="checkbox-label scenario">{{ items[i].scenario.code }}{{ items[i].order }}</span>
                  <span *ngIf="items[i]" class="checkbox-label description">{{ items[i].description }}</span>
                </mat-checkbox>
              </div>

              <div class="content-row" *ngIf="type === 'LOGBOOK'">
                <div class="content-column" formArrayName="itemsForm">
                  <mat-checkbox [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary">
                    <span *ngIf="items[i]" class="checkbox-label description">{{ items[i].text }}</span>
                  </mat-checkbox>
                </div>
                <div class="content-column" formArrayName="summaryItemsForm">
                  <mat-checkbox *ngIf="isInSummary && itemsForm.controls[i].value" [formControlName]="i" color="primary"> </mat-checkbox>
                </div>
              </div>

              <div class="content-row" *ngIf="type === 'DECISION'">
                <div class="content-column" formArrayName="itemsForm">
                  <mat-checkbox [formControlName]="i" [labelPosition]="'after'" class="checkbox" color="primary">
                    <span *ngIf="items[i]" class="checkbox-label description">{{ items[i].message }}</span>
                  </mat-checkbox>
                </div>
                <div class="content-column" formArrayName="summaryItemsForm">
                  <mat-checkbox *ngIf="isInSummary && itemsForm.controls[i].value" [formControlName]="i" color="primary"> </mat-checkbox>
                </div>
              </div>

              <div formArrayName="itemsForm">
                <mat-checkbox
                  *ngIf="type === 'GLOBAL_INSTRUCTION'"
                  [formControlName]="i"
                  [labelPosition]="'after'"
                  class="checkbox"
                  color="primary"
                >
                  <span *ngIf="items[i]" class="checkbox-label description">{{ items[i].description }}</span>
                </mat-checkbox>
              </div>

              <div *ngIf="getAlreadyInGroups(items[i]).length > 0" class="already-group-label">
                <p *ngFor="let g of getAlreadyInGroups(items[i])" class="">
                  <img src="../../../../assets/images/group-attach.svg" />
                  <span>{{ g.title }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isReadOnly">
    <div *ngIf="!isCreate" class="bottom-icon">
      <button (click)="onDeleteGroup()" class="hol-archive-btn" mat-button tabindex="-1">
        <img src="../../../../assets/images/group-delete.svg" />
        <span class="delete-text">{{
          moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.DELETE_GROUP_TEXT' | translate
        }}</span>
      </button>
    </div>

    <div class="fill-space"></div>

    <button
      (click)="isCreate ? onCreateGroup() : onUpdateGroup()"
      [disabled]="isDisableAddButton()"
      class="hol-validate-btn"
      mat-raised-button
    >
      <mat-icon>done</mat-icon>
      <span *ngIf="isCreate">{{ 'GOC.EVENT_MODAL.ADD' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
