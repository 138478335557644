import { HolScenario } from './../../common/models/hol-scenario';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { HolEvent } from '../../common/models/hol-event';
import { HolFlight } from 'src/app/common/models/hol-flight.model';
import { OclLinkedDisplayedItem } from './ocl-linked-displayed-items.model';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { HolUser } from 'src/app/common/models/hol-user.model';

export class OclEvent extends HolEvent {
  fromMCC: boolean;
  originalEvent?: any;
  flight?: HolFlight;

  fromGOC?: boolean;
  gocEvent?: any;
  toGOC?: boolean;
  fromOCC?: boolean;
  occEvent?: any;
  toOCC?: boolean;

  displayFromLinkedValues?: string[];
  linkedData?: OclLinkedDisplayedItem;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], infos?: HolNextInfo[], scenariosFromOtherModule?: Parse.Object[]) {
    super(parseObject, tags, infos);
    if (!parseObject) {
      return;
    }

    this.fromGOC = parseObject.get('fromGOC');
    this.gocEvent = parseObject.get('gocEvent') && new OclEvent(parseObject.get('gocEvent'));
    this.toGOC = parseObject.get('toGOC');
    this.fromOCC = parseObject.get('fromOCC');
    this.occEvent = parseObject.get('occEvent') && new OclEvent(parseObject.get('occEvent'));
    this.toOCC = parseObject.get('toOCC');

    if (parseObject && parseObject.get('occEvent')) {
      this.occEvent = new OclEvent(parseObject.get('occEvent'));
      this.createdBy = new HolUser(parseObject.get('occEvent').get('createdBy'));
      if (scenariosFromOtherModule) {
        this.scenario = new HolScenario(scenariosFromOtherModule.find(sc => sc.id === parseObject.get('occEvent').get('scenario').id));
      }
      const jsonAttachments = parseObject.get('occEvent').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromOCC = true;
      this.toOCC = false;
    }
    if (parseObject && parseObject.get('gocEvent')) {
      this.gocEvent = new OclEvent(parseObject.get('gocEvent'));
      this.createdBy = new HolUser(parseObject.get('gocEvent').get('createdBy'));
      if (scenariosFromOtherModule) {
        this.scenario = new HolScenario(scenariosFromOtherModule.find(sc => sc.id === parseObject.get('gocEvent').get('scenario').id));
      }
      const jsonAttachments = parseObject.get('gocEvent').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromGOC = true;
      this.toGOC = false;
    }
  }

  protected setAttachments(jsonAttachments) {
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(jsonAttachments);
    }
    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files,
      );
    } else {
      this.attachments = new HolAttachments();
    }
  }

  protected getContentText(parseObject: Parse.Object) {
    return parseObject.get('gocEvent')
      ? parseObject.get('gocEvent').get('description') // if
      : parseObject.get('occEvent')
      ? parseObject.get('occEvent').get('description') // else if
      : parseObject.get('description'); // else
  }
}
