<div
  [ngClass]="{
    onsummary: isOnSummary(),
    briefing: isOnSummaryBriefing(),
    summaryNotBrief: isOnSummary() && !isOnSummaryBriefing(),
    onbriefingGroup: isOnSummaryGroup()
  }"
  class="common-infos"
>
  <div class="create-infos">
    <app-acl-indicator [object]="item"></app-acl-indicator>
    <div *ngIf="displayUser">
      <div *ngIf="isDisplayDate()">{{ getDisplayDate() | time: isUtc }}</div>
      <div *ngIf="!hideCreatedBy" [matTooltip]="userTooltipContent" class="text-muted">
        <strong *ngIf="item.createdBy && item.createdBy.userMonogram; else updateByLine">{{ item.createdBy.userMonogram }} &nbsp; </strong>
        <ng-template #updateByLine
          ><strong>{{ item.updatedBy && item.updatedBy.userMonogram }} &nbsp; </strong></ng-template
        >
      </div>
    </div>
    <div>
      <span *ngIf="fromModule" class="status-icons">
        <img class="icon" src="/assets/images/icon_paperplan.svg" />
        <span>&nbsp;{{ fromModule }}</span>
      </span>
      <span *ngIf="toModule" class="status-icons">
        <span>{{ toModule }}&nbsp;</span>
        <img class="icon" src="/assets/images/icon_paperplan.svg" />
      </span>
      <span *ngIf="isRestricted" [matTooltip]="isRestrictedInfos" class="status-icons">
        <img class="icon small-icon" src="/assets/images/lock.svg" />
      </span>
    </div>
    <div>
      <ng-content select=".create-infos-addon"></ng-content>
    </div>
  </div>
  <div class="content">
    <ng-content select=".header-addon"></ng-content>
    <div [class.ellipsis]="!displayAllTextInItem && !moduleConfig.displayAllTextInItem" class="content-text font1_4">
      {{ item.contentText }}
    </div>
    <ng-content></ng-content>
    <div class="hol-spaced-line addon">
      <div class="col-attachements">
        <app-file-and-image-icons-display *ngIf="attachments" [attachments]="attachments"></app-file-and-image-icons-display>
      </div>
      <div class="hol-spaced-line">
        <app-hol-next-info-display
          (saved)="markAsDone()"
          *ngIf="nextInfo"
          [canUpdate]="canUpdateItem"
          [isReadOnly]="isReadOnly"
          [isUtc]="isUtc"
          [nextInfo]="nextInfo"
        >
        </app-hol-next-info-display>
      </div>
    </div>
  </div>
</div>
