export class HolOptions {
  objectId?: string;
  shortTitleForCrisisObservor?: string;
  shortTitleForCrisisDirector?: string;
  hasToSendMails?: boolean;
  hasToSendSms?: boolean;
  crisisInitializerCanActivate?: boolean;
  shortTitleForHumanResources?: string;
  shortTitleForOCC?: string;
  shortTitleForCrewManager?: string;
  shortTitleForChat?: string;
  shortTitleForMCC?: string;
  env?: string;
  nextInfoLimit?: number;
  fieldDataLimit?: number;
  shortTitleForGOC?: string;
  shortTitleForCrisisInitializer?: string;
  crisisTimeRemainingOpen?: number;
  defaultCrisisImageUrl?: string;
  companyLogoUrl?: string;
  defaultCrisisImageName?: string;
  companyLogoName?: string;
  formIoAdmin?: string;
  formIoPwd?: string;
  formIoBackEnd?: string;
  messageMaxLength: number;
  fastTypingModal?: boolean;
  passwordLength: number;
  passwordExpiredInDay: number;
  passwordSentBySMS: boolean;
  hideAttachmentLinksOnEmails: boolean;
  functionIdsForECLManagers: string[];
}
