import { HolRole } from '../../models/hol-role';

export class AclSelectorRole {
  initialWriteRoles: HolRole[];
  selected?: boolean;
  company: string;
  color: string;
  writeRoles: HolRole[];
  readRoles: HolRole[];
  userWriteRoles: HolRole[];
  userReadRoles: HolRole[];
  disabledFromLimit: boolean;

  constructor(props) {
    if (props) {
      this.company = props.company;
      this.writeRoles = props.writeRoles;
      this.readRoles = props.readRoles;
      this.userWriteRoles = props.userWriteRoles;
      this.userReadRoles = props.userReadRoles;
      this.color = props.color;
    }
    this.disabledFromLimit = false;
    this.selected = false;
  }
}
