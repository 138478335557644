<svg width="120px" height="120px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 93">
  <defs>
    <style>
      .cls-1,
      .cls-2 {
        fill: none;
        stroke-miterlimit: 10;
        stroke-width: 8px;
      }
      .cls-1 {
        stroke: #e0922a;
        stroke-linecap: round;
      }
      .cls-2 {
        stroke: #254886;
      }
      #Logo {
        animation: rotate 1s ease-in-out infinite;
        transform-origin: 50% 50%;
      }
      @keyframes rotate {
        from {
          transform: rotateZ(0deg);
        }
        to {
          transform: rotateZ(360deg);
        }
      }
    </style>
  </defs>
  <g id="Logo">
    <path class="cls-1" d="M25.25,9.68a42.56,42.56,0,0,1,42.5,0" />
    <path class="cls-1" d="M25.25,83.31A42.49,42.49,0,0,1,4,46.5" />
    <path class="cls-1" d="M89,46.5A42.49,42.49,0,0,1,67.75,83.31" />
    <circle class="cls-2" cx="46.5" cy="46.5" r="30" />
  </g>
</svg>
