'use strict';

angular.module('gestiondecriseApp').filter('aclFilter', function () {
  return function (values, companiesRolesFilter) {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return null;
  };
});
