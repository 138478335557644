import { RequestService } from '../../../common/services/request.service';
import { OclDecision } from '../../models/ocl-decision.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class OclDecisionTagService {
  // tslint:disable:variable-name
  protected ParseDecisionTag;
  protected ParseDecision;
  protected ParseTag;
  // tslint:enabled

  public constructor(protected requestService: RequestService) {}

  public updateTags(decision: OclDecision): Promise<Parse.Object[]> {
    return this.deleteTags(decision).then(() => {
      return this.setTags(decision);
    });
  }

  private setTags(decision: OclDecision): Promise<Parse.Object[]> {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });

    if (decision.tags) {
      const currentParseTags = decision.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseDecisionTag();
        joinTableRecord.set('decision', parseDecision);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  public deleteTags(decision: OclDecision): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseDecisionTag);
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    query.equalTo('decision', parseDecision);

    return this.requestService.performFindQuery(query).then(parseOccDecisionTags => {
      return this.requestService.performDestroyAllQuery(parseOccDecisionTags);
    });
  }
}
