'use strict';

angular.module('gestiondecriseApp').directive('todoBox', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/todoBox/todoBox.html',
    scope: {
      currentFilter: '=',
      isReadOnly: '='
    },
    controller: function (
      $scope,
      $mdDialog,
      CrewSituationsService,
      CrewSituationWaitingHelpTextService,
      $rootScope,
      CrewUsersService,
      $translate
    ) {
      $scope.todos = [];
      $scope.originalTodos = [];
      $scope.loading = false;
      $rootScope.userFilter = '';
      $scope.isPhone = $rootScope.isPhone;
      $scope.collapseText = $translate.instant('CREW.DASHBOARD.TODO.COLLAPSED');

      $rootScope.selctedHelpText = undefined;
      $scope.selectedHelpText = $rootScope.selectedHelpText;

      $scope.isTodoOpened = false;

      function init() {
        $scope.loading = true;

        CrewSituationsService.getAllTodos($scope.currentFilter).then(function (todos) {
          $scope.todos = todos;
          $scope.originalTodos = todos;
          $scope.loading = false;
          $scope.activateHelpTextFilter($scope.selectedHelpText);
        });

        CrewSituationWaitingHelpTextService.getAll().then(function (helpTexts) {
          _.map(helpTexts, function (helpText) {
            helpText.type = 'default';
          });

          helpTexts.unshift({ text: 'ALL', type: 'all' });
          helpTexts.push({ text: 'AUTRE', type: 'others' });
          $scope.helpTexts = helpTexts;
        });
      }

      let unregisterWatchFilters = $scope.$watch('currentFilter', init);
      let unregisterSituationEvent = $scope.$on('crew-situation-updated', init);
      let unregisterPoolService = $scope.$on('crewPoolService-todos', function (e, todos) {
        $scope.todos = todos;
        $scope.activateHelpTextFilter($scope.selectedHelpText);
      });

      //from add button
      $scope.openUserSearchModal = function ($event) {
        $mdDialog.show({
          controller: 'CrewUserModalCtrl',
          templateUrl: 'views/crew/modals/user.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            user: null,
            event: null,
            situation: null,
            isReadOnly: false,
            isArchivesOpened: false
          }
        });
      };

      //from TO-DO list
      $scope.openUserModal = function ($event, situation) {
        if ($rootScope.isPhone) {
          $mdDialog.show({
            controller: 'CrewUserModalCtrl',
            templateUrl: 'views/crew/modals/user.responsive.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
              user: situation.user,
              event: null,
              situation: situation,
              isReadOnly: $scope.isReadOnly,
              isArchivesOpened: false
            }
          });
        } else {
          $mdDialog.show({
            controller: 'CrewUserModalCtrl',
            templateUrl: 'views/crew/modals/user.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
              user: situation.user,
              event: null,
              situation: situation,
              isReadOnly: $scope.isReadOnly,
              isArchivesOpened: false
            }
          });
        }
      };

      $scope.isInFunction = function (situation) {
        return (
          !$rootScope.crewSelectedFunction ||
          (situation.user.functions && situation.user.functions.indexOf($rootScope.crewSelectedFunction) !== -1)
        );
      };

      $scope.matchSearchFilter = function (situation) {
        if (!situation.user) {
          return;
        }
        let userString = situation.user.trigram + ' ' + situation.user.firstName + ' ' + situation.user.lastName;
        return userString.toUpperCase().indexOf($rootScope.userFilter.toUpperCase()) > -1;
      };

      $scope.getUserTitle = function (user) {
        return CrewUsersService.getUserTitle(user);
      };

      $scope.getImpactLength = function (item) {
        if (item.impactDate && item.validityDate) {
          return moment(item.validityDate).diff(item.impactDate, 'day') + 1 + ' jours';
        }
        return '';
      };

      $scope.$on('$destroy', function () {
        unregisterWatchFilters();
        unregisterSituationEvent();
        unregisterPoolService();
      });

      $scope.activateHelpTextFilter = function (selectedHelpText) {
        if (!selectedHelpText) {
          return;
        }

        $rootScope.selectedHelpText = selectedHelpText;
        $scope.selectedHelpText = $rootScope.selectedHelpText;

        switch (selectedHelpText.type) {
          case 'default':
            $scope.todos = _.filter($scope.originalTodos, function (todo) {
              return todo.statusText.indexOf(selectedHelpText.text) !== -1;
            });
            break;

          case 'all':
            $scope.todos = $scope.originalTodos;
            break;

          case 'others':
            let toFilter = $scope.originalTodos.slice();
            $scope.todos = _.filter(toFilter, function (todo) {
              let foundMatches = _.filter($scope.helpTexts, function (helpText) {
                return helpText.text === todo.statusText;
              });
              return !foundMatches.length;
            });
            break;

          default:
            $scope.todos = $scope.originalTodos;
            break;
        }
      };

      $scope.isActiveHelpText = function (currentHelpText) {
        // when there's no selection yet all is the default
        if (!$scope.selectedHelpText) {
          return currentHelpText.type === 'all';
        }

        switch (currentHelpText.type) {
          case 'default':
            return currentHelpText.objectId === $scope.selectedHelpText.objectId;

          case 'others':
          case 'all':
            return currentHelpText.type === $scope.selectedHelpText.type;

          default:
            return currentHelpText.type === 'all';
        }
      };

      $scope.toggleTodoCollapse = function () {
        $scope.isTodoOpened = !$scope.isTodoOpened;
        $scope.changeCollapseText();
      };

      $scope.changeCollapseText = function () {
        if ($scope.collapseText === $translate.instant('CREW.DASHBOARD.TODO.COLLAPSED')) {
          $scope.collapseText = $translate.instant('CREW.DASHBOARD.TODO.OPENED');
        } else {
          $scope.collapseText = $translate.instant('CREW.DASHBOARD.TODO.COLLAPSED');
        }
      };
    }
  };
});
