import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() required: boolean = false;
  @Output() colorChange = new EventEmitter<string>();
  /*
  colors: string[] = [
    '#000000',
    '#FF0000',
    '#FF7F00',
    '#FFFF00',
    '#7FFF00',
    '#00FF00',
    '#00FF7F',
    '#00FFFF',
    '#007FFF',
    '#0000FF',
    '#7F00FF',
    '#FF00FF',
    '#FF007F',
    '#7F7F7F',
    '#B2B2B2',
    '#FFFFFF',
  ];
  */

  colors: string[] = [
    '#000000', // Noir
    '#9400D3', // Violet
    '#228B22', // Vert foncé
    '#00FF7F', // Vert clair
    '#40E0D0', // Turquoise
    '#ADD8E6', // Bleu clair
    '#0000CD', // Bleu
    '#8B4513', // Marron
    '#2F4F4F', // Gris foncé
    '#D3D3D3', // Gris clair
    '#FFA500', // Orange
    '#808080', // Gris moyen
  ];

  @Input() selectedColor: string = this.colors[0] || '#000000';
  showPicker: boolean = false;

  constructor() {}

  selectColor(color: string) {
    this.selectedColor = color;
    this.colorChange.emit(this.selectedColor);
    this.showPicker = false;
  }

  ngOnInit(): void {}
}
