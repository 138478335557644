import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolHelperUserFunction } from '../models/hol-helper-user-function';

@Injectable({
  providedIn: 'root',
})
export class HolHelperUserFunctionService {
  // tslint:disable:variable-name
  protected ParseHolHelperUserFunction = Parse.Object.extend('HOLHelperUserFunction');
  // tslint:enabled

  constructor(protected requestService: RequestService) {}

  public async getAll(): Promise<HolHelperUserFunction[]> {
    const query = new Parse.Query(this.ParseHolHelperUserFunction);
    const ufs = await this.requestService.performFindAllQuery(query);
    return ufs.map(uf => new HolHelperUserFunction(uf));
  }
}
