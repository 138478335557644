import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-crew-event-group-item',
  templateUrl: './crew-event-group-item.component.html',
  styleUrls: ['./crew-event-group-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CrewEventGroupItemComponent {
  @Input()
  public groupedEvents: Array<any>;

  public newName: string;
  public isExpanded = false;
  public isOnEdit = false;

  constructor(
    @Inject('$rootScope') private $rootScope,
    @Inject('$scope') private $scope,
    @Inject('CrewFunctionsService') private CrewFunctionsService,
  ) {}

  public cancel(): void {
    this.newName = null;
    this.isOnEdit = false;
    event.stopPropagation();
  }

  public onEditTitle(): void {
    if (!this.isOnEdit) {
      this.newName = this.groupedEvents[0].impact;
      this.isOnEdit = !this.isOnEdit;
    }
    event.stopPropagation();
  }

  public panelClick(): void {
    if (!this.isOnEdit) {
      this.isExpanded = !this.isExpanded;
    }
    event.stopPropagation();
  }

  public trackByFunction(event, item): any {
    return !item ? null : item.objectId;
  }

  public valide(): void {
    const CrewEvent = Parse.Object.extend('CrewEvent');
    this.groupedEvents.map(groupedEvent =>
      new Parse.Query(CrewEvent).get(groupedEvent.objectId).then(crewEvent => {
        crewEvent.set('impact', this.newName);
        groupedEvent.impact = this.newName;
        crewEvent.save();
      }),
    );
    this.isOnEdit = false;
    event.stopPropagation();
  }
}
