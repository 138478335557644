import { Action } from '@ngrx/store';
import { EclAssetType, EclAssetTypeParameter } from '../../models/ecl-asset-type';
import { EclAsset } from '../../models/ecl-asset';

export enum EclAssetActionTypes {
  INIT_ECL_ASSET = '[ECL ASSET] Init ECL Assets',
  INIT_ECL_ASSET_LIST_FROM_POLLING = '[ECL ASSET] Init ECL Asset list from polling',
  SELECT_ECL_CURRENT_ASSET_TYPE = '[ECL ASSET] Select current selected asset type',
  CREATE_ECL_ASSET = '[ECL ASSET] Create new asset',
  UPDATE_ECL_ASSET = '[ECL ASSET] Update existing asset',
}

export class InitEclAsset implements Action {
  readonly type = EclAssetActionTypes.INIT_ECL_ASSET;

  constructor(public assetTypes: EclAssetType[], public assets: EclAsset[], public assetTypeParameter: EclAssetTypeParameter[]) {}
}

export class InitEclAssetListFromPolling implements Action {
  readonly type = EclAssetActionTypes.INIT_ECL_ASSET_LIST_FROM_POLLING;

  constructor(public assetList: EclAsset[]) {}
}

export class SelectEclCurrentAssetType implements Action {
  readonly type = EclAssetActionTypes.SELECT_ECL_CURRENT_ASSET_TYPE;

  constructor(public assetType?: EclAssetType) {}
}

export class CreateEclAsset implements Action {
  readonly type = EclAssetActionTypes.CREATE_ECL_ASSET;

  constructor(public asset: EclAsset) {}
}

export class UpdateEclAsset implements Action {
  readonly type = EclAssetActionTypes.UPDATE_ECL_ASSET;

  constructor(public asset: EclAsset) {}
}

export type EclAssetAction = InitEclAsset | InitEclAssetListFromPolling | SelectEclCurrentAssetType | CreateEclAsset | UpdateEclAsset;
