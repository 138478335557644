<div *ngIf="checklist" class="checklist-group-by-scenario">
  <div class="checklist-group-by-scenario__top">
    <div class="checklist-group-by-scenario__title">
      <span *ngIf="checklist.event">
        {{ checklist.event.scenario.code }}{{ checklist.event.order }} - {{ checklist.event.scenario.title }} ({{ checklist.fromModule }})
      </span>
      <span *ngIf="!checklist.event">Unknown event from {{ checklist.fromModule }}</span>
    </div>
    <div class="checklist-group-by-scenario__subtitle" *ngIf="checklist.event">
      {{ checklist.event.contentText }}
    </div>
  </div>
  <div class="checklist-group-by-scenario__tasks-container" [class.not-holder]="!isHolder">
    <ng-container
      *ngFor="let task of checklist.tasks | aclFilter: RolesService.companiesRolesFilter; index as index; trackBy: trackByFunction"
    >
      <app-task-item (clickOnItem)="goToTask($event)" [task]="task" [isReadOnly]="isReadOnly" [isHolder]="isHolder"> </app-task-item>
    </ng-container>
    <span *ngIf="!(checklist.tasks?.length > 0)">{{
      moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.NO_RESULT_FILTER' | translate
    }}</span>
  </div>
</div>
