<div class="hol-dialog-section">
  <div class="linked-section">
    <div class="linked-section__top">
      <div class="left">
        <span class="title-read-only" *ngIf="!isReadOnly && !modeLinkIsActivate && !listGroup.length">{{
          moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.GROUP_LABEL' | translate
        }}</span>
        <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
          <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.GROUP_LABEL' | translate }}</mat-label>
          <mat-select
            (ngModelChange)="changelinkedGroup($event)"
            [(ngModel)]="linkedGroup"
            [ngModelOptions]="{ standalone: true }"
            multiple
            ngModel
            [disabled]="listGroup.length === 0"
          >
            <mat-select-trigger>
              <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.GROUP_SEARCH' | translate }}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let group of listGroup" [value]="group.objectId">
              {{ group.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="right" *ngIf="!isReadOnly && !modeLinkIsActivate">
        <button class="add-button mat-icon-button" color="primary" (click)="addGroup()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="linked-section__bottom">
      <div *ngFor="let group of linkedGroupDisplay" class="linked-section__bottom__row">
        <div class="left">
          <span>
            {{ group.title }}
          </span>
          <span *ngIf="disabledLinks[group.objectId]">{{
            moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
          }}</span>
        </div>
        <div class="right">
          <button *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[group.objectId]" (click)="removeGroup(group)" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #linkedEventLoadingTemplate>
    <div class="linked-section">
      <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LOADING' | translate }}</span>
    </div>
  </ng-template>
</div>
