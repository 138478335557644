(function () {
  'use strict';

  /**
   * @ngdoc function
   * @name gestiondecriseApp.component:ChatChannelController
   * @description
   * # ChatChannelController
   */

  angular
    .module('gestiondecriseApp')
    .controller(
      'ChatChannelController',
      function (
        $scope,
        $rootScope,
        ChatService,
        OccEventService,
        EclEventService,
        CrewEventsService,
        UserService,
        OptionsService,
        MCCEventsService
      ) {
        var $ctrl = this;
        this.newMessage = '';
        this.messages = [];
        this.channels = [];
        this.loading = false;
        this.chatOpened = false;
        this.openOnStart = false;

        this.$onInit = function () {};

        $scope.$watch('$ctrl.channel', function () {
          console.log('channel changed !');
          if ($ctrl.channel) {
            joinChannel();
          }
        });

        this.sendMessage = function () {
          if (this.newMessage.trim() !== '') {
            ChatService.sendMessage(this.newMessage, this.channel.channelId);
            this.newMessage = '';
          }
        };

        this.reconnect = function () {
          ChatService.reconnect();
        };

        this.checkInput = function ($event) {
          $ctrl.markChannelAsRead();
          if ($event.keyCode === 13) {
            $event.preventDefault();
            $ctrl.sendMessage();
          }
        };

        this.markChannelAsRead = function () {
          $rootScope.channelsUnreadCount[$ctrl.channel.channelId] = 0;
          _.filter($ctrl.messages, { new: true }).forEach(function (msg) {
            delete msg.new;
          });
        };

        var unregisterChatMessage = $rootScope.$on('chat:message', function (ev, message) {
          $rootScope.$applyAsync(function () {
            if ($ctrl.channel && message.channel === $ctrl.channel.channelId) {
              if (message.username === UserService.getCurrentUser()) {
                $rootScope.channelsUnreadCount[message.channel] = 0;
              } else {
                if (!_.find($ctrl.messages, { new: true })) {
                  message.new = true;
                }
              }
              $ctrl.messages.push(message);
            }
          });
        });

        var unregisterChatConnected = $rootScope.$on('chat:connected', function () {
          joinChannel();
        });

        var getChannelMessages = function (channelId) {
          ChatService.getChannelMessages(channelId)
            .then(function (messages) {
              var lastUnreadIndex = messages.length - $rootScope.channelsUnreadCount[channelId];
              if (lastUnreadIndex < messages.length) {
                messages[lastUnreadIndex].new = true;
              }
              $ctrl.messages = messages;
            })
            .catch(function () {
              $ctrl.error = "Impossible de récupérer l'historique des messages";
            })
            .finally(function () {
              $ctrl.loading = false;
            });
        };

        function joinChannel() {
          $ctrl.loading = true;
          $ctrl.messages = [];
          $ctrl.error = null;
          var channelPrefix = OptionsService.getEnv() === 'sandbox' ? '_' : '';
          var channelName = channelPrefix + $rootScope.companyName + '-' + $ctrl.context + '-' + $ctrl.channel.objectId;
          var channelTopic = '';
          if ($ctrl.context === 'occ') {
            channelTopic +=
              'OCC ' + ($ctrl.channel.scenario && $ctrl.channel.scenario.code) + $ctrl.channel.order + ' - ' + $ctrl.channel.description;
          } else if ($ctrl.context === 'crew') {
            channelTopic += 'Crew ' + $ctrl.channel.impact;
          } else if ($ctrl.context === 'mcc') {
            channelTopic += 'MCC ' + $ctrl.channel.description;
          }
          ChatService.joinChannel(channelName, channelTopic)
            .then(function (channel) {
              $ctrl.channel.channelId = channel.data.id;
              if ($ctrl.context === 'occ') {
                OccEventService.setChannelId($ctrl.channel);
              } else if ($ctrl.context === 'ecl') {
                EclEventService.setChannelId($ctrl.channel);
              } else if ($ctrl.context === 'crew') {
                CrewEventsService.setChannelId($ctrl.channel);
              } else if ($ctrl.context === 'mcc') {
                MCCEventsService.setChannelId($ctrl.channel);
              }
              getChannelMessages(channel.data.id);
            })
            .catch(function (e) {
              console.log('e', e);
              $ctrl.error = 'Impossible de se connecter au fil de discussion';
              $ctrl.loading = false;
            });
        }

        this.$onDestroy = function () {
          unregisterChatMessage();
          unregisterChatConnected();
        };
      }
    );
})();
