import { HolObject } from 'src/app/common/models/hol-object';

export class HolUserFunction extends HolObject {
  functionId: string;
  userId: string;
  isHolder: boolean;
  expiredAt: Date;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.functionId = parseObject.get('functionId');
    this.userId = parseObject.get('userId');
    this.isHolder = parseObject.get('isHolder');
    this.expiredAt = parseObject.get('expiredAt');
  }
}
