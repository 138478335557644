<!--
<a class="external-attachment" (click)="$event.stopPropagation()" href="{{ url }}" target="_blank">
  <i *ngIf="icon" class="icon {{ icon }}"></i>
</a>
<sup *ngIf="count && count - 1 !== 0" class="external-attachment-count">+ {{ count - 1 }}</sup>
-->
<a
  (click)="url ? $event.stopPropagation() : $event.preventDefault()"
  [attr.title]="!url ? 'Le fichier semble corrompu' : null"
  [href]="url || null"
  [ngClass]="{ 'not-allowed': !url }"
  class="external-attachment"
  target="_blank"
>
  <i *ngIf="icon" class="icon {{ icon }}"></i>
</a>
<sup *ngIf="count && count - 1 !== 0" class="external-attachment-count">+ {{ count - 1 }}</sup>
