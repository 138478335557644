import { MccMel } from './mcc-mel.model';
import { HolObject } from '../../common/models/hol-object';
import { MccEvent } from './mcc-event.model';
import { MccCabin } from './mcc-cabin.model';

export class MccAircraft extends HolObject {
  registration: string;
  type: string;
  etops: string;
  config: string;
  isOperational: boolean;
  events: MccEvent[];
  mels: MccMel[];
  cabins: MccCabin[];
  constructor(parseObject?: Parse.Object, events?: MccEvent[], mels?: MccMel[], cabins?: MccCabin[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.registration = parseObject.get('registration');
    this.type = parseObject.get('type');
    this.etops = parseObject.get('etops');
    this.config = parseObject.get('config');
    this.isOperational = parseObject.get('isOperational');
    this.events = events;
    this.mels = mels;
    this.cabins = cabins;
  }
}
