import { Pipe, PipeTransform } from '@angular/core';
import { HolVacation } from '../../models/hol-vacation.model';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'searchVacations',
})
export class SearchVacationsPipe implements PipeTransform {
  public transform(items: HolVacation[], searchCriteria?: string): any {
    if (!searchCriteria) {
      return items;
    }

    const searchTerm = this.sanitize(searchCriteria);

    const filteredItems = cloneDeep(items).filter(item => {
      return item.contentText.toLowerCase().includes(searchTerm);
    });
    return filteredItems;
  }

  private sanitize(input: string): string {
    return input.trim().toLowerCase();
  }
}
