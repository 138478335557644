import { HolObject } from 'src/app/common/models/hol-object';
import { HolTag } from 'src/app/common/models/hol-tag';
import { EclCrisis } from './ecl-crisis';
import { EclHistoryLog } from './ecl-history-log.model';

export class EclAnnouncement extends HolObject {
  objectId?: string;
  message: string;
  crisisObjectId: string;
  tags?: HolTag[];
  visibilityByFunctionId?: string[];
  createdByFunctionId?: string[];
  isArchived?: boolean;
  readed?: boolean;
  crisisMainTitle: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.message = parseObject.get('message');
    const crisis = new EclCrisis(parseObject.get('crisis'));
    this.crisisObjectId = crisis.objectId;
    this.crisisMainTitle = crisis.mainTitle;
    this.tags = parseObject.get('tags') ? parseObject.get('tags').map(tag => new HolTag(tag)) : [];
    this.visibilityByFunctionId = parseObject.get('visibilityByFunctionId') || [];
    this.createdByFunctionId = parseObject.get('createdByFunctionId') || [];
    this.isArchived = parseObject.get('isArchived');
    this.readed = parseObject.get('readed');
  }

  toLog?(action: string, parseObject: Parse.Object, crisisObjectId: string): EclHistoryLog {
    const log = new EclHistoryLog();
    log.type = 'eclAnnouncement';
    log.subType = action;
    log.jsonObject = this;
    log.acl = this.acl;
    log.eclAnnouncement = parseObject;
    log.crisis = new EclCrisis(new Parse.Object('ECLCrisis', { id: crisisObjectId }));
    return log;
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('message');
  }
}
