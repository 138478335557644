<div (click)="goToTask(task.objectId)" *ngIf="task" class="tasks-item hol-clickable">
  <app-hol-list-item
    (saveNextInfo)="markAsDone($event)"
    [attachments]="task.attachments"
    [canUpdateItem]="canMarkAsDone"
    [displayAllTextInItem]="true"
    [displayUser]="task.status !== 'TODO'"
    [item]="task"
    [nextInfo]="!displayFromHistory ? { nextInfoTime: task.nextInfoTime, nextInfoDone: task.nextInfoDone } : null"
    [ngClass]="task.status"
  >
    <div *ngIf="task.status === 'FROZEN'">
      <span class="comment-label">
        <strong>{{ task.comment }}</strong>
      </span>
    </div>
    <div class="create-infos-addon">
      <div *ngIf="displayFromHistory" class="function-short-title">
        <span [matTooltip]="functionShortTitle">{{ functionShortTitleToDisplay }}</span>
      </div>
      <div class="task-short-title">
        <span class="font14">{{ codeToDisplay }}</span>
        <span class="font14">{{ task.order }}</span>
        <span *ngIf="task.subOrder && task.subOrder.length > 0" class="font14">{{ task.subOrder }}</span>
      </div>
    </div>

    <div class="header-addon hol-spaced-line">
      <app-tag-chips [isShowName]="false" [tags]="tagsToChip"></app-tag-chips>
    </div>

    <div *ngIf="displayFromHistory && task.status === 'DONE'" class="output-title">
      <span>{{ task.outputTitle }}</span>
    </div>
  </app-hol-list-item>
</div>
