import { Pipe, PipeTransform } from '@angular/core';
import { HolFlight } from '../../models/hol-flight.model';
import { FltFlightService } from '../../../flt/services/flt-flight.service';

@Pipe({
  name: 'filterFlightList',
})
export class FilterFlightListPipe implements PipeTransform {
  public transform(
    flights: Array<HolFlight>,
    airportSelected?: string,
    indicatorFilterSelected?: string,
    statusFilterActivated?: boolean,
    filterText?: string,
  ): Array<HolFlight> {
    airportSelected = !airportSelected ? 'All' : airportSelected;
    indicatorFilterSelected = !indicatorFilterSelected ? 'All' : indicatorFilterSelected;
    const searchCriteriasArray = filterText && filterText.length > 0 ? this.sanitize(this.stringToArray(filterText)) : [];
    let flightFiltered: Array<HolFlight>;
    if (airportSelected === 'All' && indicatorFilterSelected === 'All') {
      flightFiltered = flights;
    } else {
      flightFiltered = flights.filter((flight: HolFlight) => {
        if (airportSelected !== 'All' && indicatorFilterSelected === 'All') {
          return flight.departure === airportSelected || flight.destination === airportSelected;
        }
        if (airportSelected === 'All' && indicatorFilterSelected !== 'All') {
          return indicatorFilterSelected === '0'
            ? flight.mcc === indicatorFilterSelected ||
                flight.occ === indicatorFilterSelected ||
                flight.crew === indicatorFilterSelected ||
                flight.goc === indicatorFilterSelected
            : +flight.mcc >= +indicatorFilterSelected ||
                +flight.occ >= +indicatorFilterSelected ||
                +flight.crew >= +indicatorFilterSelected ||
                +flight.goc >= +indicatorFilterSelected;
        }
        if (airportSelected !== 'All' && indicatorFilterSelected !== 'All') {
          return indicatorFilterSelected === '0'
            ? flight.departure === airportSelected &&
                (flight.mcc === indicatorFilterSelected ||
                  flight.occ === indicatorFilterSelected ||
                  flight.crew === indicatorFilterSelected ||
                  flight.goc === indicatorFilterSelected)
            : flight.departure === airportSelected &&
                (+flight.mcc >= +indicatorFilterSelected ||
                  +flight.occ >= +indicatorFilterSelected ||
                  +flight.crew >= +indicatorFilterSelected ||
                  +flight.goc >= +indicatorFilterSelected);
        }
      });
    }
    if (searchCriteriasArray.length) {
      searchCriteriasArray.forEach(searchTerm => {
        flightFiltered = flightFiltered.filter(flight => {
          const availableDataForSearch = `${flight.flightNumber} ${flight.departure} ${flight.registration} ${flight.destination}`;
          if (availableDataForSearch.toLowerCase().includes(searchTerm)) {
            return flight;
          }
        });
      });
    }
    return !statusFilterActivated && flightFiltered
      ? flightFiltered.filter((flight: HolFlight) => !FltFlightService.hasTakenOff(flight.status))
      : flightFiltered;
  }

  public stringToArray(string): any {
    return string.trim().split(' ');
  }

  public sanitize(terms: string[]): string[] {
    return terms.map(term => term.toLowerCase());
  }
}
