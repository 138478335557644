import { Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { OpsModuleConfigService } from 'src/app/ops/services/ops-module-config/ops-module-config.service';
import { groupBy, orderBy } from 'lodash';
import { FltUserAirportService } from 'src/app/flt/services/flt-user-airport.service';

import { HolTag } from '../../../common/models/hol-tag';
import { HelperService } from '../../../common/services/helper.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { RolesService } from '../../../common/services/roles.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { GocOptionsService } from '../../../goc/services/goc-options-service/goc-options.service';
import { OpsFlightInstruction } from '../../models/ops-flight-instruction.model';
import { OpsFlight } from '../../models/ops-flight.model';
import { OpsOfficer } from '../../models/ops.officer.model';
import { OpsFlightEventService } from '../ops-flight-event-service/ops-flight-event-service';
import { OpsFlightInstructionService } from '../ops-flight-instruction-service/ops-flight-instruction.service';
import { OpsFlightLogbookService } from '../ops-flight-logbook-service/ops-flight-logbook.service';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightService extends FltFlightService<OpsFlight> {
  // tslint:disable:variable-name
  protected ParseEvent = Parse.Object.extend('OPSEvents');
  protected ParseEventInfo = Parse.Object.extend('OPSEventInfos');
  protected ParseFlightInstruction = Parse.Object.extend('OPSFlightInstruction');
  protected ParseFlightLogbook = Parse.Object.extend('OPSFlightLogbook');
  protected ParseFlightTag = Parse.Object.extend('OPSFlightTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected userAirportService: FltUserAirportService,
    protected gocOptionService: GocOptionsService,
    protected commonStoreManager: CommonStoreManager,
    protected flightLogbookService: OpsFlightLogbookService,
    protected flightInstructionService: OpsFlightInstructionService,
    protected flightEventService: OpsFlightEventService,
    protected moduleConfig: OpsModuleConfigService,
    protected helperService: HelperService,
    protected rolesService: RolesService,
  ) {
    super(
      requestService,
      userService,
      parseMapper,
      userAirportService,
      gocOptionService,
      commonStoreManager,
      flightLogbookService,
      flightInstructionService,
      flightEventService,
      moduleConfig,
      helperService,
      rolesService,
    );
  }
  protected newFlight(parseObject: Parse.Object, tags?: HolTag[]): OpsFlight {
    return new OpsFlight(parseObject, tags);
  }

  public async retrieveSummary(flights: OpsFlight[]): Promise<OpsFlight[]> {
    if (flights.length && !flights[0].instructions) {
      const parseFlights = flights.map(f => {
        return new this.ParseFlight({ id: f.objectId });
      });
      const instructionsQuery = new Parse.Query(this.ParseFlightInstruction);
      instructionsQuery.containedIn('flight', parseFlights);
      instructionsQuery.notEqualTo('done', true);
      instructionsQuery.notEqualTo('archived', true);

      return this.requestService.performFindQuery(instructionsQuery).then(parseInstructions => {
        const groupedInstructions = groupBy<Parse.Object>(parseInstructions, pi => {
          return pi.get('flight').id;
        });
        flights.forEach(f => {
          if (groupedInstructions[f.objectId]) {
            const mostCritical = orderBy<Parse.Object>(groupedInstructions[f.objectId], i => i.get('nextInfoTime'))[0];
            f.mostCriticalInstructionTime = mostCritical.get('nextInfoTime');
            f.hasUncheckedInstructions = !!groupedInstructions[f.objectId].length;
          } else {
            f.mostCriticalInstructionTime = null;
            f.hasUncheckedInstructions = false;
          }
        });

        return Promise.resolve(flights);
      });
    } else {
      flights.forEach(f => {
        if (f.instructions && f.instructions.length) {
          const uncheckedInstructions = f.instructions.filter(i => !i.done);
          const mostCritical = orderBy(uncheckedInstructions, (i: OpsFlightInstruction) => i.nextInfoTime)[0];
          f.mostCriticalInstructionTime = mostCritical ? mostCritical.nextInfoTime : null;
          f.hasUncheckedInstructions = !!uncheckedInstructions.length;
        } else {
          f.mostCriticalInstructionTime = null;
          f.hasUncheckedInstructions = false;
        }
      });
    }
  }

  public saveOpsOfficer(flight: OpsFlight, opsOfficerId: string) {
    const parseObject = new this.ParseFlight({ id: flight.objectId });
    parseObject.set('opsOfficer', opsOfficerId ? new this.ParseOPSOfficer({ id: opsOfficerId }) : {});
    return this.requestService.performSaveQuery(parseObject).then(res => {
      flight.opsOfficer = new OpsOfficer(res.get('opsOfficer'));
      this.commonStoreManager.updateOneFlight(flight);
      this.commonStoreManager.updateCurrentFlight(flight);
      return flight;
    });
  }

  public saveCrewBriefing(flightsId: string[], briefing: any): Promise<Parse.Object[]> {
    flightsId = flightsId.filter(el => el !== undefined);
    const parseObjects = flightsId.map(id => {
      const parseFlight = new this.ParseFlight({ id });
      parseFlight.set('crewBriefing', JSON.stringify(briefing));
      return parseFlight;
    });

    return this.requestService.performSaveAllQuery(parseObjects);
  }
}
