<div *ngIf="!isShowName">
  <ng-container *ngFor="let tag of tags; trackBy: trackByFunction">
    <p
      *ngIf="tag && tag.name && tag.color && tag.name.length && tag.color.length"
      class="chips"
      [ngStyle]="{ 'background-color': tag.color }"
      [matTooltip]="tag.name"
    ></p>
  </ng-container>
</div>

<div *ngIf="isShowName">
  <mat-chip-list [selectable]="false">
    <ng-container *ngFor="let tag of tags; trackBy: trackByFunction">
      <mat-chip *ngIf="tag && tag.name && tag.color && tag.name.length && tag.color.length" [style.background]="tag.color">
        <strong>{{ tag.name }}</strong>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>
