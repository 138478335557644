import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hol-addons-panel',
  templateUrl: './hol-addons-panel.component.html',
  styleUrls: ['./hol-addons-panel.component.scss'],
})
export class HolAddonsPanelComponent implements OnInit {
  @Input() expanded: boolean;

  constructor() {}

  ngOnInit() {}
}
