import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolFunction } from '../models/hol-function';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HolFunctionService {
  // tslint:disable:variable-name
  protected ParseFunction = Parse.Object.extend('HOLFunction');
  // tslint:enable

  constructor(private requestService: RequestService) {}

  getAll(): Promise<HolFunction[]> {
    const query = new Parse.Query(this.ParseFunction);
    return this.requestService.performFindAllQuery(query).then(functions => {
      return functions.map(f => new HolFunction(f));
    });
  }

  getAllObs(): Observable<HolFunction[]> {
    const query = new Parse.Query(this.ParseFunction);
    return from(
      this.requestService.performFindAllQuery(query).then(functions => {
        return functions.map(f => new HolFunction(f));
      }),
    );
  }
}
