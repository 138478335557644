<div
  [style.border-style]="item.linkedData ? 'solid' : 'none'"
  [style.border-color]="!item.linkedData ? '' : item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]"
  [style.border-width]="!item.linkedData ? '' : item.linkedData?.isParent ? '4px' : '2px'"
  [style.background-color]="
    !item.linkedData ? '' : (item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]) + '52'
  "
  class="hol-clickable occ-decision-item"
  (click)="openDetailModal($event, item)"
>
  <app-hol-list-item
    [item]="item"
    [isReadOnly]="isReadOnly"
    [utcDisplay]="utcDisplay"
    [fromModule]="item.fromERP ? 'ERP' : null"
    [toModule]="item.toERP ? 'ERP' : null"
  >
    <button
      [ngClass]="{ selected: linkedUid }"
      class="occ-linked-button"
      (click)="showLinkedItems($event)"
      *ngIf="!isHistory && linkedIds[item.objectId]"
      [disabled]="linkedIds[item.objectId] === 'DISABLED_BUTTON'"
    >
      <img *ngIf="!linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-blue.svg" />
      <img *ngIf="linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-white.svg" />
    </button>
    <div class="header-addon">
      <div>
        <app-tag-chips [tags]="item.tags"></app-tag-chips>
      </div>
      <div *ngIf="item.flight" class="item-dasboard-occ-flight-linked">
        <span>{{ item.flight.flightNumber }}/{{ item.flight.std | date: 'ddMMM' }}</span>
      </div>
      <div class="header-right">
        <div *ngIf="item['function'] && item['function'].objectId" style="margin-bottom: 5px">
          <app-ecl-user-function-detail [function]="item['function']"></app-ecl-user-function-detail>
        </div>
        <div class="pinned" *ngIf="item.isPinned"><img class="pinned-item-icon" src="/assets/images/pin--on.png" /></div>
        <div class="un-pinned" *ngIf="!item.isPinned"></div>
      </div>
      <div class="todo" *ngIf="isTodoConfigActivated && item.isTodo"><mat-icon color="accent">playlist_add_check</mat-icon></div>
    </div>

    <div class="hol-spaced-line addon">
      <div class="col-attachements">
        <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
      </div>
      <div class="hol-spaced-line">
        <div *ngIf="!isReadOnly && !item.nextInfoTime" [ngClass]="{ 'hol-done': item.done }">
          <strong (click)="markAsDone(item, $event)" *ngIf="item.done">
            {{ item.doneBy.monogram() }} - {{ item.updatedAt | time: isUtc }}
          </strong>
          <button (click)="markAsDone(item, $event)" aria-label="mark as done" class="mat-icon-button">
            <i class="icon icon-check"></i>
          </button>
        </div>
        <div class="hol-done" *ngIf="(isReadOnly || item.readOnly) && item.done">
          <i class="icon icon-check"></i>
          <strong> {{ item.doneBy.monogram() }} - {{ item.updatedAt | time: isUtc }} </strong>
        </div>
        <div
          *ngIf="item.nextInfoTime"
          [ngClass]="{ elapsed: isElapsed(item.nextInfoTime), 'almost-elapsed': isAlmostElapsed(item.nextInfoTime), 'hol-done': item.done }"
          class="col-next-info"
        >
          <span>{{ item.nextInfoTime | time: isUtc }}</span>
        </div>
      </div>
    </div>
  </app-hol-list-item>
</div>
