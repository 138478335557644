import { Injectable } from '@angular/core';

import { HolScenario } from '../../../common/models/hol-scenario';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { RequestService } from '../../../common/services/request.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OclScenarioService {
  // tslint:disable-next-line:variable-name
  protected ParseScenario;

  public constructor(private requestService: RequestService, private translatePipe: TranslatePipe) {}

  public getAll(): Promise<HolScenario[]> {
    const query = new Parse.Query(this.ParseScenario);
    return this.requestService.performFindQuery(query).then(parseOccScenario => {
      return parseOccScenario.map(parseScenario => new HolScenario(parseScenario));
    });
  }

  public orderByGroupingCode(scenarios: HolScenario[]): { [key: string]: HolScenario[] } {
    const otherCodes = [];
    const scenariosByGroup = [];

    scenarios.map(s => {
      if (!s.groupingCode) {
        otherCodes.push(s);
      } else {
        const groupingCode = this.translatePipe.transform('GOC.MODALS.FLIGHT_DETAILS_MODAL.EVENT_DETAILS_MODAL.LABEL_SCENARIO_FAMILY', {
          index: s.groupingCode.charAt(3),
        });
        if (!scenariosByGroup[groupingCode]) {
          scenariosByGroup[groupingCode] = [];
        }
        scenariosByGroup[groupingCode].push(s);
      }
    });
    if (otherCodes.length) {
      scenariosByGroup[this.translatePipe.transform('GOC.MODALS.FLIGHT_DETAILS_MODAL.EVENT_DETAILS_MODAL.LABEL_SCENARIO_OTHER')] =
        otherCodes;
    }

    return Object.keys(scenariosByGroup)
      .sort()
      .reduce((r, k) => ((r[k] = scenariosByGroup[k]), r), {});
  }
}
