import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { OclTask } from '../../models/ocl-task';
import { InitTasks, UpdateOneTask } from './ocl-tasks.actions';

@Injectable({
  providedIn: 'root',
})
export class OclTasksStoreManager {
  constructor(private store: Store<AppState>) {}

  initTasks(tasks: OclTask[]): void {
    this.store.dispatch(new InitTasks(tasks));
  }

  updateOneTask(task: OclTask): void {
    this.store.dispatch(new UpdateOneTask(task));
  }
}
