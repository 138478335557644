import { Injectable } from '@angular/core';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { HolUTCDisplay } from '../../common/enums/hol-utc-display.enum';

@Injectable({
  providedIn: 'root',
})
export class CrewModuleConfigService extends ModuleConfigService {
  public constructor() {
    super();
    this.config.localHours = false;
    this.config.utcDisplay = HolUTCDisplay.LOCAL_IF_READONLY;
    this.config.showLinkToFlightOcc = false;
    this.config.moduleName = 'crew';
    this.config.forceEventChecklistActivation = false;
    this.config.canCreateItemsFromLogbook = true;
    this.config.canCreateTags = false;
    this.config.logbooksOrder = 1;
    this.config.eventsOrder = 2;
    this.config.decisionsOrder = 3;
    this.config.flightsOrder = 4;
    this.config.canActivateEclModule = false;
    this.config.translateKey = 'CREW';
    this.config.canEditNi = false;
    this.config.canOpenFlight = true;
    this.config.scenariosByGroup = true;
    this.config.rootPath = 'crew';
  }
}
