import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from './../ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { CrewHistoryComponent } from './pages/crew-history/crew-history.component';
import { CrewTeamComponent } from './pages/crew-team/crew-team.component';
import { CrewChecklistComponent } from './pages/crew-checklist/crew-checklist.component';
import { CrewDashboardComponent } from './pages/crew-dashboard/crew-dashboard.component';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { USER_RIGHTS } from '../app.module';
import { CrewComponent } from './crew.component';
import { VacationService } from '../common/services/vacation.service';
import { CrewGlobalInstructionService } from './services/crew-global-instruction.service';
import { CrewGroupService } from './services/crew-group.service';
import { FltFlightService } from '../flt/services/flt-flight.service';
import { BreakingNewsService } from '../common/services/breaking-news.service';
import { TagsService } from '../common/services/tags.service';
import { OclTagsStoreManager } from '../ocl/store/tags/ocl-tags.store-manager';

export function getOptions(crewOptionsService, $rootScope, crewEventsService) {
  return crewOptionsService.get(true).then(options => {
    if ($rootScope.accessRights.crew === USER_RIGHTS.WRITE) {
      crewEventsService.startArchivingTask();
    }
    return options;
  });
}

export function isReadOnly($rootScope) {
  switch ($rootScope.accessRights.crew) {
    case USER_RIGHTS.WRITE:
      $rootScope.isReadOnly = $rootScope.isPhone;
      return $rootScope.isPhone;
    case USER_RIGHTS.READ:
      $rootScope.isReadOnly = true;
      return true;
    case USER_RIGHTS.UNAUTHORIZED:
      throw USER_RIGHTS.UNAUTHORIZED;
    default:
      throw USER_RIGHTS.UNAUTHORIZED;
  }
}

export function getFlightsFromYesterday(flightService: FltFlightService) {
  return flightService.getAllFromYesterday();
}

export function initStore(
  vacationService: VacationService,
  globalInstructionService: CrewGlobalInstructionService,
  groupService: CrewGroupService,
  globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
  commonStoreManager: CommonStoreManager,
  breakingNewsService: BreakingNewsService,
  tagsService: TagsService,
  tagsStoreManager: OclTagsStoreManager,
) {
  commonStoreManager.updateCurrentOclModule('crew');
  return Promise.all([
    vacationService.initVacationsByModule('crew'),
    globalInstructionService.getAll(true),
    groupService.getAll('GLOBAL_INSTRUCTION'),
    breakingNewsService.getAllBreakingNews('crew'),
    tagsService.getAll(),
  ]).then(([vacation, globalInstructions, group, breakingNews, tags]) => {
    globalInstructionsStoreManager.initGlobalInstructions(globalInstructions);
    commonStoreManager.initBreakingNewsList(breakingNews);
    tagsStoreManager.initTags(tags);
  });
}

export const CREW_ROOT_STATE: Ng2StateDeclaration = {
  name: 'app.crew',
  url: '/crew',
  abstract: true,
  component: CrewComponent,
  resolve: [
    {
      provide: 'isReadOnly',
      useFactory: isReadOnly,
      deps: ['$rootScope'],
    },
    {
      provide: 'options',
      useFactory: getOptions,
      deps: ['CrewOptionsService', '$rootScope', 'CrewEventsService'],
    },
    {
      provide: 'flights',
      useFactory: getFlightsFromYesterday,
      deps: [FltFlightService],
    },
    {
      provide: 'store',
      useFactory: initStore,
      deps: [
        VacationService,
        OclGlobalInstructionService,
        CrewGroupService,
        OclGlobalInstructionsStoreManager,
        CommonStoreManager,
        BreakingNewsService,
        TagsService,
        OclTagsStoreManager,
      ],
    },
  ],
};

export const CREW_DASHBOARD_STATE: Ng2StateDeclaration = {
  name: 'app.crew.dashboard',
  url: '/dashboard',
  component: CrewDashboardComponent,
};

export const CREW_CHECKLIST_STATE: Ng2StateDeclaration = {
  name: 'app.crew.checklist',
  url: '/checklist',
  component: CrewChecklistComponent,
};

export const CREW_TEAM_STATE: Ng2StateDeclaration = {
  name: 'app.crew.team',
  url: '/team',
  component: CrewTeamComponent,
};

export const CREW_HISTORY_STATE: Ng2StateDeclaration = {
  name: 'app.crew.history',
  url: '/history',
  component: CrewHistoryComponent,
};
