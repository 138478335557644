import { Injectable } from '@angular/core';
import { OclNotificationsService } from '../../../ocl/services/ocl-notifications-service/ocl-notifications.service';

@Injectable({
  providedIn: 'root',
})
export abstract class EclNotificationsService extends OclNotificationsService {
  // tslint:disable:variable-name
  protected ParseNotifications = Parse.Object.extend('ECLNotification');
  // tslint:enabled
}
