<div [class.hidden]="!roles || roles.length < 0">
  <div *ngIf="mode === 'single'" [class.no-click]="disabled">
    <mat-select
      [(ngModel)]="selectedCompanyRole"
      [disabled]="roles?.length === 1"
      [style.background]="roles?.length === 1 ? '#808080' : selectedCompanyRole?.color"
    >
      <mat-select-trigger>
        <div class="company-content">
          <div>{{ selectedCompanyRole && selectedCompanyRole.company }}</div>
          <div
            *ngIf="isInLimitCompanies(selectedCompanyRole) && roleHasAnyPermission(selectedCompanyRole, false, true)"
            [ngClass]="['activated', circleClass]"
          ></div>
        </div>
      </mat-select-trigger>
      <mat-option
        (click)="refreshData(role)"
        *ngFor="let role of roles"
        [disabled]="!roleHasAnyPermission(role, false, true)"
        [style.background]="role.color"
        [value]="role"
      >
        <div class="company-content">
          <div>{{ role.company }}</div>
          <div *ngIf="isInLimitCompanies(role) && roleHasAnyPermission(role, false, true)" [ngClass]="['activated', circleClass]"></div>
        </div>
      </mat-option>
    </mat-select>
  </div>
  <div *ngIf="mode === 'multi'" [class.no-click]="disabled">
    <mat-button-toggle-group #group="matButtonToggleGroup" [multiple]="mode === 'multi'" appearance="legacy" class="roles-buttons">
      <mat-button-toggle
        (change)="roles.length > 1 && onChange()"
        *ngFor="let role of roles"
        [checked]="roleHasAnyPermission(role)"
        [disabled]="roles.length === 1 || !roleHasAnyPermission(role)"
        [ngStyle]="{
          color: isSelected(role) ? 'white' : role.color,
          'border-color': role.color,
          'background-color': isSelected(role) ? role.color : mixColors('#FFF', role.color, 90)
        }"
        [value]="role"
      >
        {{ role.company }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <!--
            <mat-checkbox
              class="select-all"
              *ngIf="availableCompaniesRoles.length > 3 && !disabled"
              [checked]="availableCompaniesRoles.length === btnGroup.value.length"
              (change)="toggleSelectAll()"
            >
              {{ 'COMMON.SELECT_ALL' | translate }}
            </mat-checkbox>
            -->
  </div>
</div>
