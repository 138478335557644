<span class="file-upload new-holis-theme" [ngClass]="{ disabled: disabled }">
  <div class="d-flex align-items-center">
    <button
      (click)="selectPdf()"
      [disabled]="disabled"
      [ngClass]="{ 'attachment-btn': column }"
      class="hol-attachment-btn"
      type="button"
      mat-stroked-button
      color="primary"
    >
      <!-- <span *ngIf="icon" [class]="icon"></span> -->
      <span class="label-upload">{{ 'COMMON.BROWSE' | translate }}</span>
    </button>
    <span *ngIf="uploading" class="info">
      <span class="spinner"></span>
    </span>
    <span *ngIf="!fileName && !uploading" class="hol-margin">
      {{ 'COMMON.MODALS.ATTACHMENT.NO_FILE' | translate }}
    </span>
    <span *ngIf="fileName && !uploading" class="filename hol-margin">
      {{ fileName }}
    </span>
  </div>
  <input #inputUpload (change)="onFileInputChange($event)" accept="{{ accept }}" style="display: none;" type="file" />
</span>
