<ul *ngIf="object && shouldDisplay" class="acl-list">
  <li
    *ngFor="let company of object.companies"
    [matTooltip]="company"
    [style.background-color]="companiesColors[company]"
    class="company"
  ></li>
  <li
    *ngFor="let company of object.companiesObj"
    [matTooltip]="company.name"
    [style.background-color]="companiesColors[company.name]"
    class="company"
  ></li>
</ul>
