import { Action } from '@ngrx/store';

export enum OclLinkedItemsActionTypes {
  INIT_LINKED_ITEMS = '[OCL LINKED ITEMS] Init Linked Items',
  UPDATE_LINKED_ITEMS = '[OCL LINKED ITEMS] Update Linked Items',
  UPDATE_LINKED_ITEMS_FROM_POOLING = '[OCL LINKED ITEMS] Update Linked From Pooling',
  RESET_LINKED_ITEMS = '[OCL LINKED ITEMS] RESET Linked Items',
}

export class InitLinkedItems implements Action {
  readonly type = OclLinkedItemsActionTypes.INIT_LINKED_ITEMS;
  constructor(public payload: any) {}
}
export class UpdateLinkedItems implements Action {
  readonly type = OclLinkedItemsActionTypes.UPDATE_LINKED_ITEMS;
  constructor(public payload: any) {}
}
export class UpdateLinkedItemsFromPooling implements Action {
  readonly type = OclLinkedItemsActionTypes.UPDATE_LINKED_ITEMS_FROM_POOLING;
  constructor(public payload: any) {}
}

export class ResetLinkedItems implements Action {
  readonly type = OclLinkedItemsActionTypes.RESET_LINKED_ITEMS;
}

export type LinkedItemsActions = InitLinkedItems | UpdateLinkedItems | UpdateLinkedItemsFromPooling | ResetLinkedItems;
