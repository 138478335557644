import { Injectable } from '@angular/core';
import { TaskTagService } from '../../common/services/task-tag.service';

@Injectable({
  providedIn: 'root',
})
export class GocTaskTagService extends TaskTagService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseTaskTag = Parse.Object.extend('GOCTaskTag');
  // tslint:enabled
}
