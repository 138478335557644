export enum HolIndicatorStatusTypeEnum {
  UNDEFINED = 'UNDEFINED',
  NO_INFO = '0',
  THRESHOLD_1 = '1',
  THRESHOLD_2 = '2',
  THRESHOLD_3 = '3',
  THRESHOLD_4 = '4',
}

export const HolIndicatorsStatusForFilter = [
  {
    libelle: 'All',
    value: 'All',
  },
  {
    libelle: '> 3 heures',
    value: HolIndicatorStatusTypeEnum.THRESHOLD_4,
  },
  {
    libelle: '> 2 heures',
    value: HolIndicatorStatusTypeEnum.THRESHOLD_3,
  },
  {
    libelle: '> 1 heure',
    value: HolIndicatorStatusTypeEnum.THRESHOLD_2,
  },
  {
    libelle: '> 30 min',
    value: HolIndicatorStatusTypeEnum.THRESHOLD_1,
  },
  {
    libelle: '> 15min',
    value: HolIndicatorStatusTypeEnum.NO_INFO,
  },
];
