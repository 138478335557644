import { pairwise } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { ErpFunctions } from '../../erp.model';
import { FunctionsActions, FunctionsActionTypes } from './functions.action';
import { HolUserWithCompanies } from '../../../common/models/hol-user.model';

const functionInitialState: Readonly<ErpFunctions> = {
  allUsers: [],
  functionsCrisis: [],
  functionsCategory: [],
  functionsAllUser: [],
};

export function functionsReducer(state: ErpFunctions = functionInitialState, action: FunctionsActions): ErpFunctions {
  switch (action.type) {
    case FunctionsActionTypes.INIT_FUNCTIONS:
      return {
        ...cloneDeep(state),
        ...action.payload,
      };
    case FunctionsActionTypes.UPDATE_FONCTIONS_FROM_POOLING:
      const bufferFonctionsState = cloneDeep(state);
      bufferFonctionsState.functionsAllUser = cloneDeep(action.payload.functionsAllUser);
      bufferFonctionsState.functionsCrisis = cloneDeep(action.payload.functionsCrisis);
      bufferFonctionsState.functionsCategory = cloneDeep(action.payload.functionsCategory);
      bufferFonctionsState.allUsers = cloneDeep(action.payload.allUsers);
      return bufferFonctionsState;
    case FunctionsActionTypes.UPDATE_ONE_FUNCTION_ALL_USER:
      return cloneDeep({
        ...state,
        functionsAllUser: state.functionsAllUser.map(el => {
          if (el.objectId === action.payload.objectId) {
            return action.payload;
          } else {
            return el;
          }
        }),
      });
    case FunctionsActionTypes.ADD_ONE_FUNCTION_ALL_USER:
      if (action.payload.objectId && state.functionsAllUser.findIndex(el => el.objectId === action.payload.objectId) === -1) {
        return cloneDeep({
          ...state,
          functionsAllUser: [...state.functionsAllUser, action.payload],
        });
      } else {
        return state;
      }
    case FunctionsActionTypes.REMOVE_ONE_FUNCTION_ALL_USER:
      return cloneDeep({
        ...state,
        functionsAllUser: [...state.functionsAllUser.filter(fu => fu.objectId !== action.payload.objectId)],
      });
    case FunctionsActionTypes.UPDATE_ONE_FUNCTION_CRISIS:
      return cloneDeep({
        ...state,
        functionsCrisis: state.functionsCrisis.map(el => {
          if (el.objectId === action.payload.objectId) {
            return action.payload;
          } else {
            return el;
          }
        }),
      });
    case FunctionsActionTypes.ADD_ONE_FUNCTION_CRISIS:
      if (action.payload.objectId && state.functionsCrisis.findIndex(el => el.objectId === action.payload.objectId) === -1) {
        return cloneDeep({
          ...state,
          functionsCrisis: [...state.functionsCrisis, action.payload],
        });
      } else {
        return state;
      }
    case FunctionsActionTypes.RESET_FUNCTIONS:
      Object.keys(state).forEach(key => {
        state[key] = [];
      });
      break;

    case FunctionsActionTypes.ADD_ONE_USER:
      if (action.payload.objectId && state.allUsers.findIndex(el => el.objectId === action.payload.objectId) === -1) {
        return cloneDeep({
          ...state,
          allUsers: [...state.allUsers, action.payload],
        });
      } else {
        return state;
      }

    case FunctionsActionTypes.UPDATE_ONE_USER:
      return cloneDeep({
        ...state,
        allUsers: state.allUsers.map(el => {
          if (el.objectId === action.payload.objectId) {
            (action.payload as HolUserWithCompanies).companies = el.companies;
            return action.payload;
          } else {
            return el;
          }
        }),
      });
    default:
      return state;
  }
}
