import { HolTag } from '../../common/models/hol-tag';
import { HolObject } from '../../common/models/hol-object';

export class OclGlobalInstruction extends HolObject {
  public description: string;
  public tags?: HolTag[];
  public isPinned?: boolean;
  public archived?: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.description = parseObject.get('description');
    this.isPinned = parseObject.get('isPinned');
    this.archived = parseObject.get('archived');
    this.tags = tags;
  }

  protected getContentText(parseObject: Parse.Object) {
    return parseObject.get('description');
  }
}
