<div class="hol-dialog-wrapper details-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span [innerHTML]="title"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="hol-dialog-section next-info-metadata">
      <span class="time">{{ info.createdAt | time: isUtc }} : </span>
      <strong class="text-muted">{{ info.createdBy?.monogram() }}</strong>
      <span class="message">{{ info.message }}</span>
    </div>

    <div class="hol-dialog-section full-width">
      <app-file-and-image-upload [attachments]="info.attachments" [disabled]="isReadOnly" [context]="context"> </app-file-and-image-upload>
    </div>
    <div *ngIf="!canEditNi || isReadOnly" class="hol-dialog-section full-width">
      <div class="hol-spaced-line">
        <strong>
          <span class="next-info" *ngIf="info.nextInfoTime" [ngClass]="helperService.generateWarningNextInfoClass(info)">
            <span>{{ 'COMMON.MODALS.NEXT_INFO.EXPECTED' | translate }}</span>
            <span>{{ info.nextInfoTime | time: isUtc }}</span>
          </span>
        </strong>
      </div>
    </div>
    <div *ngIf="canEditNi && !isReadOnly">
      <div class="hol-dialog-section inputs-date">
        <div class="hol-spaced-line" [ngClass]="helperService.generateWarningNextInfoClass(info)">
          <span class="next-info-libelle"
            ><strong>{{ 'COMMON.MODALS.NEXT_INFO.EXPECTED' | translate }}</strong></span
          >
          <app-hol-next-info
            [nextInfo]="{ nextInfoTime: nextInfoTime }"
            [hasDoneBtn]="false"
            [isUTC]="isUtc"
            (saveNextInfo)="saveNextInfo($event)"
          >
          </app-hol-next-info>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="fill-space"></div>

    <button (click)="save()" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      <span *ngIf="!isReadOnly">{{ 'DASHBOARD.MODAL.SUBMIT' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
