import { Action } from '@ngrx/store';
import { OclTask } from '../../models/ocl-task';

export enum OclTasksActionTypes {
  INIT_TASKS = '[OCL Tasks] Init Tasks',
  UPDATE_ONE_TASK = '[OCL Tasks] Update One Task',
}

export class InitTasks implements Action {
  readonly type = OclTasksActionTypes.INIT_TASKS;
  constructor(public tasks: OclTask[]) {}
}

export class UpdateOneTask implements Action {
  readonly type = OclTasksActionTypes.UPDATE_ONE_TASK;
  constructor(public task: OclTask) {}
}

export type TasksActions = InitTasks | UpdateOneTask;
