import { HolObject } from 'src/app/common/models/hol-object';

export class HolManagerUser extends HolObject {
  userId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phone: string;
  email: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.userId = parseObject.get('userId');
    this.firstName = parseObject.get('firstName');
    this.lastName = parseObject.get('lastName');
    this.phone = parseObject.get('phone');
    this.email = parseObject.get('email');
    this.fullName = (this.firstName ? this.firstName + ' ' : '') + (this.lastName ? this.lastName : '');
  }
}
