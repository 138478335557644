import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';

import { OccGlobalInstructionTagService } from '../occ-global-instruction-tag-service/occ-global-instruction-tag.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';
import { OclSmsService } from 'src/app/ocl/services/ocl-sms-service/ocl-sms.service';

@Injectable({
  providedIn: 'root',
})
export class OccGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('OCCGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('OCCGlobalInstruction');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected globalInstructionTagService: OccGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected notificationsService: NotificationsService,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    public moduleConfig: OccModuleConfigService,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      globalInstructionTagService,
      globalInstructionsStoreManager,
      notificationsService,
      mailService,
      smsService,
      moduleConfig,
    );
  }
}
