import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';

import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { HolNotifyFunction } from './../../models/hol-notification.model';
import { HelperService } from 'src/app/common/services/helper.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { HolNotification } from '../../models/hol-notification.model';

@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationTableComponent implements OnInit, OnChanges {
  RolesService = RolesService;

  @Input() notifications: HolNotification[] = [];
  @Input() notifyFunction: HolNotifyFunction = undefined;
  @Input() acl: Parse.ACL;
  @Input() unselectAll = false;
  @Input() color = 'primary';
  @Input() type = '';
  @Input() context?: any;
  @Output() saveNotifications = new EventEmitter<HolNotification[]>();
  @Output() saveNotifyFunction = new EventEmitter<HolNotifyFunction>();
  private companiesRolesFilter: string[];

  constructor(
    private readonly helperService: HelperService,
    public readonly moduleConfig: ModuleConfigService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.unselectAll) {
      this.unselectAllNotification();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.acl) {
      if (changes.acl.currentValue) {
        this.companiesRolesFilter = this.helperService.parseACL(changes.acl.currentValue);
      } else {
        this.companiesRolesFilter = RolesService.companiesRolesFilter;
      }
    }
    if (changes.notifications && changes.notifications.currentValue) {
      this.notifications = changes.notifications.currentValue;
    }
    if (
      !this.context ||
      (this.context && this.context.module !== 'OPS' && this.context.module !== 'CREW' && this.context.category !== 'FLIGHT_LOGBOOK')
    ) {
      this.buildNotifs();
    }
    this.changeDetector.detectChanges();
  }

  callSaveNotifications() {
    this.saveNotifications.emit(this.notifications);
  }

  callSaveNotifyFunction() {
    this.saveNotifyFunction.emit(this.notifyFunction);
  }

  private buildNotifs() {
    if (this.notifications) {
      this.notifications = this.notifications.map(notif => {
        if (!this.companiesRolesFilter || !this.companiesRolesFilter.length) {
          notif.disabled = true;
        } else {
          if (!notif.companies || !notif.companies.length) {
            notif.disabled = false;
          } else {
            const availableCompanies = notif.companies.filter(c => this.companiesRolesFilter.indexOf(c) > -1);
            notif.disabled = availableCompanies.length !== 0 ? false : true;
          }
        }
        if (notif.disabled) {
          notif.sendBySms = false;
          notif.sendByMail = false;
        }

        return notif;
      });
    }
  }

  private unselectAllNotification() {
    if (this.notifications) {
      this.notifications = this.notifications.map(notif => {
        notif.sendBySms = false;
        notif.sendByMail = false;
        return notif;
      });
    }
  }
}
