<ng-container *ngIf="!$rootScope.isPhone">
  <div>
    <div class="ocl-header">
      <div class="ocl-content">
        <div class="ocl-header-acl">
          <ng-content select=".header-acl"></ng-content>
        </div>
        <div class="ocl-header-filters">
          <ng-content select=".header-filters"></ng-content>
        </div>
        <div class="ocl-header-search">
          <ng-content select=".header-search"></ng-content>
        </div>
        <ng-content></ng-content>
      </div>
      <div class="ocl-header-button">
        <ng-content select=".buttons-addon"></ng-content>
        <ng-container *ngTemplateOutlet="indicatorsBtn"></ng-container>
        <ng-container *ngTemplateOutlet="breakingNewsBtn"></ng-container>
        <app-chat-component *ngIf="context && showChat" [context]="context"></app-chat-component>
        <ng-container class="buttons-addon">
          <ng-container *ngTemplateOutlet="helperBtn"></ng-container>
          <ng-container *ngTemplateOutlet="adminBtn"></ng-container>
          <ng-container *ngTemplateOutlet="logoutBtn"></ng-container>
        </ng-container>
      </div>
    </div>
    <app-hol-addons-panel *ngIf="canAddBreakingNews" [expanded]="breakingNewsPanelExpanded">
      <app-breaking-news [clear]="!breakingNewsPanelExpanded"></app-breaking-news>
    </app-hol-addons-panel>
    <app-ng-marquee *ngIf="breakingNews?.length" class="breaking-news-container" speed="slow">
      <span *ngFor="let bn of breakingNews; let i = index" class="news">
        <span *ngIf="i !== 0" class="separator">-</span>
        <span class="createdat">{{ bn.createdAt | date: "dd/MM/yy 'at' HH:mm UTC":'UTC' }}</span>
        <span> - </span>
        <span>{{ bn.news }}</span>
      </span>
    </app-ng-marquee>
  </div>
</ng-container>

<ng-container *ngIf="$rootScope.isPhone">
  <div>
    <div class="ocl-header">
      <button (click)="toggleSideNav()" *ngIf="$rootScope.isPhone" class="header-button hamburger-button">
        <i class="fa fa-bars fa-2x menu-icon"></i>
      </button>
      <div class="ocl-content">
        <div class="ocl-header-title-mob">
          <ng-content select=".header-title-mob"></ng-content>
        </div>
        <div class="ocl-header-acl">
          <ng-content select=".header-acl"></ng-content>
        </div>
        <ng-container *ngTemplateOutlet="adminBtn"></ng-container>
      </div>
      <div class="ocl-header-button">
        <ng-content select=".buttons-addon"></ng-content>
        <button
          (click)="toggleIndicatorPanel()"
          *ngIf="indicators"
          [class.header-button__deployed]="showIndicators"
          class="header-button"
          type="button"
        >
          <img alt="icon indicators" src="../../../assets/images/indicators.svg" />
        </button>
        <app-indicators-panel
          *ngIf="indicators"
          [accessRights]="accessRights"
          [expanded]="showIndicators"
          [isInBox]="false"
        ></app-indicators-panel>
        <button
          (click)="addBreakingNews()"
          *ngIf="canAddBreakingNews"
          [class.header-button__deployed]="breakingNewsPanelExpanded"
          class="header-button"
        >
          <img alt="icon info" src="../../../assets/images/flash-info.svg" />
        </button>
        <app-chat-component *ngIf="context && showChat" [context]="context"></app-chat-component>
      </div>
      <ng-content></ng-content>
      <div class="ocl-content">
        <div class="ocl-header-search mob">
          <ng-content select=".header-search"></ng-content>
        </div>
      </div>
    </div>
    <app-hol-addons-panel *ngIf="canAddBreakingNews" [expanded]="breakingNewsPanelExpanded">
      <app-breaking-news [clear]="!breakingNewsPanelExpanded"></app-breaking-news>
    </app-hol-addons-panel>
    <app-ng-marquee *ngIf="breakingNews?.length" class="breaking-news-container" speed="slow">
      <span *ngFor="let bn of breakingNews; let i = index" class="news">
        <span *ngIf="i !== 0" class="separator">-</span>
        <span class="createdat">{{ bn.createdAt | date: "dd/MM/yy 'at' HH:mm UTC":'UTC' }}</span>
        <span> - </span>
        <span>{{ bn.news }}</span>
      </span>
    </app-ng-marquee>
  </div>
</ng-container>

<ng-template #adminBtn>
  <button *ngIf="menuItem && menuItem.length > 0" [matMenuTriggerFor]="menu" class="header-button admin-btn" color="primary" type="button">
    <img alt="icon logout" src="../../../assets/images/header/screw.svg" />
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let but of menuItem">
      <button (click)="goToPage(but.click)" *ngIf="but.isVisible" mat-menu-item>
        <span>{{ but.label }}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-template>
<ng-template #helperBtn>
  <ng-container *ngIf="helperLinkList && helperLinkList?.length">
    <button [matMenuTriggerFor]="menu" aria-label="helper link list" class="header-button helper">
      <!--   <mat-icon>emoji_objects</mat-icon> -->
      <img alt="icon logout" src="../../../assets/images/header/light.svg" />
    </button>
    <mat-menu #menu="matMenu">
      <button *ngFor="let helper of helperLinkList" mat-menu-item>
        <span (click)="goToTemplateHelper(helper.link)">{{ helper.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>

<ng-template #logoutBtn>
  <button (click)="logout($event)" *ngIf="!$rootScope.isPhone" class="header-button" matTooltip="{{ username }}" type="button">
    <img alt="icon logout" src="../../../assets/images/header/logout.svg" />
  </button>
</ng-template>

<ng-template #breakingNewsBtn>
  <button
    (click)="addBreakingNews()"
    *ngIf="canAddBreakingNews"
    [class.header-button__deployed]="breakingNewsPanelExpanded"
    class="header-button"
  >
    <img alt="icon info" src="../../../assets/images/header/megaphone.svg" />
  </button>
</ng-template>
<ng-template #indicatorsBtn>
  <button
    (click)="toggleIndicatorPanel()"
    *ngIf="indicators"
    [class.header-button__deployed]="showIndicators"
    class="header-button"
    type="button"
  >
    <img alt="icon indicators" src="../../../assets/images/indicators.svg" />
  </button>
  <app-indicators-panel
    *ngIf="indicators"
    [accessRights]="accessRights"
    [expanded]="showIndicators"
    [isInBox]="false"
  ></app-indicators-panel>
</ng-template>
