import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HolTag } from '../../models/hol-tag';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() tag: HolTag;
  @Output() removeTag = new EventEmitter<void>();

  onRemove() {
    this.removeTag.emit();
  }
}
