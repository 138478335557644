'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.component:ChatComponent
 * @description
 * # ChatComponent
 */

angular.module('gestiondecriseApp').component('chatComponent', {
  templateUrl: 'scripts/components/chat/chat.template.html',
  controller: 'ChatController',
  bindings: {
    context: '<',
  },
});
