import { EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as moment from 'moment';

import { HolEvent } from '../../models/hol-event';
import { HolNextInfo } from '../../models/hol-next-info.model';
import { TimeOrDayPipe } from '../../pipes/time-or-day/time-or-day.pipe';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { CommonService } from '../../services/common.service';
import { SystemNotificationService } from '../../services/system-notification.service';
import { BaseComponent } from '../base/base.component';
import { HolUTCDisplay, utcEval } from '../../enums/hol-utc-display.enum';

export abstract class HolEventItemComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() eclInfos;
  @Input()
  public event: HolEvent;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public utcDisplay: HolUTCDisplay;
  @Input()
  public onlyHeader: boolean;
  @Output()
  public updatedEvent: EventEmitter<HolEvent> = new EventEmitter();

  public fromModule: string;
  public isNextInfoMandatory = true;
  public lastDisplayTime: Date;
  public timerId;
  public toModule: string;

  protected constructor(
    @Inject('CONSTANTS') protected CONSTANTS,
    protected readonly commonService: CommonService,
    protected translatePipe: TranslatePipe,
    protected systemNotificationService: SystemNotificationService,
    protected timeOrDayPipe: TimeOrDayPipe,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.lastDisplayTime = this.getLastDisplayTime();
    if (!this.isReadOnly) {
      this.timerId = setInterval(() => {
        this.lastDisplayTime = this.getLastDisplayTime();
        const ni = this.event.getNextInfo();
        if (ni && this.isElapsed(ni.nextInfoTime) && !this.isSeen(ni)) {
          this.sendNotification(this.event);
          this.setLastDisplayTime();
        }
      }, 5000);
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public isSeen(item: HolNextInfo): boolean {
    return item && item.createdAt ? this.commonService.isSeen(item, this.lastDisplayTime) : true;
  }

  public sendNotification(event: HolEvent): void {
    // sending system notification
    let title = 'Event ';
    title += event.scenario.code + (event.order || '') + ' ';
    title += this.timeOrDayPipe.transform(event.createdAt, this.isReadOnly) + ' ';
    title += event.scenario.title;
    const message = this.translatePipe.transform('COMMON.NOTIFICATIONS.REASON.NEXT_INFO_ELAPSED');
    this.systemNotificationService.tryToNotify(title, message, true);
  }

  public ngOnDestroy(): void {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  public get isUtc(): boolean {
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  abstract openEditModal($event, event);

  abstract getLastDisplayTime(): Date;

  abstract setLastDisplayTime(): void;
}
