import { Inject, Injectable } from '@angular/core';

import { CommonService } from './../common.service';
import { OptionsService } from './../options.service';
import { RequestService } from './../request.service';

export interface MailSenderData {
  sender?: string;
  recipients?: MailSenderRecipient[];
  ccRecipients?: MailSenderRecipient[];
  subject: string;
  contentText?: string;
  contentHtml?: string;
  attachements?: any[];
  variables?: any;
}

interface MailSenderRecipient {
  email: string;
  fields?: any;
}

@Injectable({
  providedIn: 'root',
})
export class MailSenderService {
  constructor(
    private readonly optionsService: OptionsService,
    private readonly requestService: RequestService,
    private readonly commonService: CommonService,
    @Inject('CONSTANTS') public CONSTANTS,
  ) {}

  public sendMail(data: MailSenderData, forceToSendMail: boolean, doNotSendCC: boolean, ccExcludeDirectors: boolean = false) {
    return new Promise((resolve, reject) => {
      // If we don't force to send mail
      // And if hasToSendMails flag is false,
      // ==> We don't send any mail
      if (forceToSendMail !== true && !this.optionsService.hasToSendMails()) {
        resolve(null);
      }

      data.sender = data.sender || this.CONSTANTS.COMPANY_NAME;

      if (doNotSendCC) {
        this.requestService.performCloudCode('sendMail', data, resolve, reject);
      } else {
        data.ccRecipients = data.ccRecipients || [];
        // add current user in copy
        data.ccRecipients.push({ email: Parse.User.current().get('email') || Parse.User.current().get('userEmail') });

        if (!ccExcludeDirectors) {
          // add erd in copy (if he is not the current user)
          this.commonService.getCrisisDirectors().then((crisisDirectors: any[]) => {
            for (let i = 0; i < crisisDirectors.length; i++) {
              const crisisDirector = crisisDirectors[i];
              if (crisisDirector && crisisDirector.id !== Parse.User.current().id) {
                data.ccRecipients.push({ email: crisisDirector.mail });
              }
            }
          });
        }

        this.requestService.performCloudCode('sendMail', data, resolve, reject);
      }
    });
  }
}
