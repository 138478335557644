import { Action } from '@ngrx/store';
import { MccImpactFilter } from '../../models/mcc-impact-filter.model';

export enum MccImpactFilterActionTypes {
  SELECT_FILTER = '[MCC IMPACT FILTER] Select',
}

export class SelectFilter implements Action {
  readonly type = MccImpactFilterActionTypes.SELECT_FILTER;
  constructor(public payload: MccImpactFilter) {}
}

export type ImpactFilterActions = SelectFilter;
