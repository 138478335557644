import { OclTagsStoreManager } from './../../ocl/store/tags/ocl-tags.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OccSmsService } from './occ-sms-service/occ-sms.service';
import { OccMailService } from './occ-mail-service/occ-mail.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OccHistoryService } from './occ-history-service/occ-history.service';
import { OccTagsService } from './occ-tags-service/occ-tags.service';
import { OccTaskTagService } from './occ-task-tag.service';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { OccModuleConfigService } from './occ-module-config/occ-module-config.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';

@Injectable({
  providedIn: 'root',
})
export class OccTasksService extends OclTasksService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('OCCTag');
  protected ParseTaskTag = Parse.Object.extend('OCCTaskTag');
  // tslint:enabled

  constructor(
    protected requestService: RequestService,
    protected taskTagService: OccTaskTagService,
    protected historyService: OccHistoryService,
    protected tagsService: OccTagsService,
    protected moduleConfig: OccModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected commonStoreManager: CommonStoreManager,
    protected mailService: OccMailService,
    protected smsService: OccSmsService,
    protected notificationsService: NotificationsService,
    protected tagsStoreManager: OclTagsStoreManager,
  ) {
    super(
      requestService,
      historyService,
      tagsService,
      taskTagService,
      moduleConfig,
      tasksStoreManager,
      commonStoreManager,
      mailService,
      smsService,
      notificationsService,
      tagsStoreManager,
    );
  }
}
