import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HolUTCDisplay } from '../../../common/enums/hol-utc-display.enum';

export class OCLOptions {
  objectId: string;
  decisionsToDisplay: number;
  logbooksToDisplay: number;
  flightsStatusHoursToDisplay: number;
  mailingList: string;
  historyTimeSlot: number;
  statusXAxis1: string;
  statusXAxis2: string;
  statusXAxis3: string;
  statusYAxis1: string;
  statusYAxis2: string;
  statusYAxis3: string;
  defaultScenario: string;
  decisionsTitle: string;
  logbooksTitle: string;
  eventsTitle: string;
  managersTitle: string;
  isNextInfoMandatory: boolean;
  canCheckLogbook: boolean;
  defaultNextInfoDelay: number;
  hiddenButtons: string;
  moduleName: string;
  moduleIsActivated: boolean;
  occEvent: Parse.Object;
  crewBriefingFormData: any;
  isGlobalInstructionActivated: boolean;
  isCrewBriefingActivated: boolean;
  stopoverThreshold: number;
  isFlightListActivated: boolean;
  utcDisplay: HolUTCDisplay;
  fastTypingModal: boolean;
  hideOptionStatus: boolean;
  public onlyChecklist: boolean = false;

  constructor(parseObject?: Parse.Object) {
    this.objectId = parseObject.id;
    this.decisionsToDisplay = parseObject.get('decisionsToDisplay');
    this.logbooksToDisplay = parseObject.get('logbooksToDisplay');
    this.flightsStatusHoursToDisplay = parseObject.get('flightsStatusHoursToDisplay');
    this.mailingList = parseObject.get('mailingList');
    this.historyTimeSlot = parseObject.get('historyTimeSlot');
    this.statusXAxis1 = parseObject.get('statusXAxis1');
    this.statusXAxis2 = parseObject.get('statusXAxis2');
    this.statusXAxis3 = parseObject.get('statusXAxis3');
    this.statusYAxis1 = parseObject.get('statusYAxis1');
    this.statusYAxis2 = parseObject.get('statusYAxis2');
    this.statusYAxis3 = parseObject.get('statusYAxis3');
    this.defaultScenario = parseObject.get('defaultScenario');
    this.decisionsTitle = parseObject.get('decisionsTitle');
    this.logbooksTitle = parseObject.get('logbooksTitle');
    this.eventsTitle = parseObject.get('eventsTitle');
    this.managersTitle = parseObject.get('managersTitle');
    this.isNextInfoMandatory = parseObject.get('isNextInfoMandatory');
    this.canCheckLogbook = parseObject.get('canCheckLogbook');
    this.defaultNextInfoDelay = parseObject.get('defaultNextInfoDelay');
    this.hiddenButtons = parseObject.get('hiddenButtons');
    this.moduleName = parseObject.get('moduleName'); // only ECL
    this.moduleIsActivated = parseObject.get('moduleIsActivated'); // only ECL
    this.occEvent = parseObject.get('occEvent'); // only ECL,
    this.isGlobalInstructionActivated = parseObject.get('isGlobalInstructionActivated');
    this.isCrewBriefingActivated = parseObject.get('isCrewBriefingActivated');
    this.stopoverThreshold = parseObject.get('stopoverThreshold');
    this.isFlightListActivated = parseObject.get('isFlightListActivated');
    this.utcDisplay = parseObject.get('utcDisplay');
    this.fastTypingModal = parseObject.get('fastTypingModal');
    this.hideOptionStatus = parseObject.get('hideOptionStatus');
    this.onlyChecklist = parseObject.get('isOnlyChecklist') ? parseObject.get('isOnlyChecklist') : false;
  }
}

@Injectable({
  providedIn: 'root',
})
export abstract class OclOptionsService<T extends OCLOptions = OCLOptions> {
  isF;
  // tslint:disable-next-line:variable-name
  protected abstract ParseOptions;
  protected options: T; // @cache : options occ

  public constructor(protected requestService: RequestService, protected httpclient: HttpClient) {}

  // @hasCache options
  get(forceRefresh): Promise<T> {
    return new Promise((resolve, reject) => {
      if (this.options !== undefined && !forceRefresh) {
        resolve(this.options);
      } else {
        const query = new Parse.Query(this.ParseOptions);
        this.requestService
          .performFirstQuery(query)
          .then(result => {
            if (result) {
              const optionObject = this.newOptionsObject(result);
              const crewBriefing = result.get('crewBriefingFormData');
              optionObject.crewBriefingFormData = crewBriefing ? this.httpclient.get(crewBriefing.url()).pipe(map(res => res)) : of(null);
              this.options = optionObject;
            } else {
              this.options = this.newOptionsObject(new Parse.Object());
            }
            resolve(this.options);
          })
          .catch(reject);
      }
    });
  }

  getDecisionsToDisplay() {
    return this.options && this.options.decisionsToDisplay;
  }

  getLogbooksToDisplay() {
    return this.options && this.options.logbooksToDisplay;
  }

  getFlightsStatusHoursToDisplay() {
    return this.options && this.options.flightsStatusHoursToDisplay;
  }

  getMailingLists() {
    return (this.options && this.options.mailingList && this.options.mailingList.split('|')) || [];
  }

  getHistoryTimeSlot() {
    return this.options && this.options.historyTimeSlot;
  }

  getXAxis1() {
    return this.options && this.options.statusXAxis1;
  }

  getXAxis2() {
    return this.options && this.options.statusXAxis2;
  }

  getXAxis3() {
    return this.options && this.options.statusXAxis3;
  }

  getYAxis1() {
    return this.options && this.options.statusYAxis1;
  }

  getYAxis2() {
    return this.options && this.options.statusYAxis2;
  }

  getYAxis3() {
    return this.options && this.options.statusYAxis3;
  }

  getDefaultScenario() {
    return this.options && this.options.defaultScenario;
  }

  getDecisionsTitle() {
    return this.options && this.options.decisionsTitle;
  }

  getLogbooksTitle() {
    return this.options && this.options.logbooksTitle;
  }

  getEventsTitle() {
    return this.options && this.options.eventsTitle;
  }

  getManagersTitle() {
    return this.options && this.options.managersTitle;
  }

  getIsNextInfoMandatory() {
    return this.options && this.options.isNextInfoMandatory;
  }

  getCanCheckLogbook() {
    return this.options && this.options.canCheckLogbook;
  }

  getDefaultNextInfoDelay() {
    return this.options && this.options.defaultNextInfoDelay;
  }

  getEclModuleInfos() {
    return {
      moduleName: this.options && this.options.moduleName,
      moduleIsActivated: this.options && this.options.moduleIsActivated,
      occEvent: this.options && this.options.occEvent,
    };
  }

  getCrewBriefingFormData(): Observable<any> {
    if (this.options && this.options.crewBriefingFormData) {
      return this.options.crewBriefingFormData;
    }
  }

  getIsGlobalInstructionActivated(): boolean {
    return this.options && this.options.isGlobalInstructionActivated;
  }

  getIsCrewBriefingActivated(): boolean {
    return this.options && this.options.isCrewBriefingActivated;
  }

  getStopoverThreshold() {
    return this.options && this.options.stopoverThreshold;
  }

  getIsFlightListActivated(): boolean {
    return this.options && this.options.isFlightListActivated;
  }

  getUtcDisplay(): HolUTCDisplay {
    return this.options && this.options.utcDisplay;
  }

  getFastTypingModal(): boolean {
    return this.options && this.options.fastTypingModal;
  }

  getHideOptionsStatus(): boolean {
    return this.options && !this.options.hideOptionStatus;
  }

  getOnlyCheckList() {
    return this.options && this.options.onlyChecklist;
  }

  protected newOptionsObject(parser: Parse.Object): T {
    return new OCLOptions(parser) as T;
  }
}
