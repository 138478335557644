<div class="hol-dialog-wrapper event-modal">
  <app-loading *ngIf="loading" class="full-loader"></app-loading>
  <mat-toolbar>
    <div *ngIf="isCreate" mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span class="title">{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.NEW_TITLE' | translate }}</span>
      <small *ngIf="!isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small>
      <small *ngIf="isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small>
    </div>
    <div *ngIf="!isCreate" mat-dialog-title>
      <span>{{ initialEvent.scenario?.code }}</span
      ><span>{{ initialEvent.order }}</span> -
      <span>{{ initialEvent.scenario?.title }}</span>
      <small *ngIf="!isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small>
      <small *ngIf="isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <!-- SCENARIO -->
      <div *ngIf="!initialEvent?.checklistActivated && !isReadOnly && !fromOtherUnivers">
        <mat-form-field class="full-width">
          <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.TYPE_LABEL' | translate }}</mat-label>
          <mat-select formControlName="scenario" required>
            <mat-option *ngFor="let scenario of scenarios" [value]="scenario">
              {{ scenario.code + ' - ' + scenario.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- DESCRIPTION -->
      <div class="section" [class.disabled]="!form.get('scenario').valid">
        <mat-form-field class="full-width description-textarea" *ngIf="!isReadOnly && !fromOtherUnivers">
          <textarea
            formControlName="description"
            maxlength="200"
            placeholder="{{ placeHolderDescription }}"
            type="text"
            mat-autosize
            required
            matInput
          >
          </textarea>
        </mat-form-field>
        <p class="description" *ngIf="isReadOnly || fromOtherUnivers">
          {{ this.initialEvent?.description }}
        </p>
      </div>

      <!-- LINKED GROUP -->
      <div class="section" [class.disabled]="!form.get('scenario').valid">
        <app-group-selector
          [type]="'event'"
          [isReadOnly]="isReadOnly"
          [listGroup]="eventGroup"
          (groupChange)="updateLinkedGroup($event)"
        ></app-group-selector>
      </div>

      <!-- FROMGOC -->
      <div *ngIf="initialEvent.fromGOC" class="hol-dialog-section">
        <mat-checkbox formControlName="fromGOC" [labelPosition]="'after'" color="primary">
          <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
          {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.FROM_GOC' | translate }}
        </mat-checkbox>
      </div>
      <!-- TOGOC -->
      <div *ngIf="moduleConfig.config.displayToGoc && !initialEvent.fromGOC" class="hol-dialog-section">
        <mat-checkbox formControlName="toGOC" [labelPosition]="'after'" color="primary">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.TO_GOC' | translate }}
          <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        </mat-checkbox>
      </div>

      <!-- ATTACHMENTS -->
      <div class="section" *ngIf="attachments" [class.disabled]="!form.get('scenario').valid">
        <app-file-and-image-upload
          [attachments]="attachments"
          [disabled]="isReadOnly || fromOtherUnivers"
          [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
          [context]="{
            module: moduleConfig.config.translateKey,
            category: 'EVENT',
            htmlTitle: form.value.description,
            htmlDate: initialEvent.createdAt,
            htmlScenario: getScenario(),
            htmlTemplate: 'B'
          }"
        ></app-file-and-image-upload>
      </div>

      <!--CheckList ATTACHMENTS -->
      <div class="section mt-5" [class.disabled]="!form.get('scenario').valid" *ngIf="checkListTasks.length > 0">
        <div class="occ-table next-infos-list">
          <ul class="hol-list-check-list">
            <ng-container *ngFor="let checkListTask of checkListTasks">
              <app-ocl-file-and-image-display-check-list-task *ngIf="checkListTask !== undefined" [data]="checkListTask">
              </app-ocl-file-and-image-display-check-list-task>
            </ng-container>
          </ul>
        </div>
      </div>

      <!-- ADD INFOS -->
      <div class="section" [class.disabled]="!form.get('scenario').valid" *ngIf="!isReadOnly">
        <div class="full-width">
          <div class="hol-centered-line">
            <button mat-raised-button color="primary" (click)="openAddNextInfoModal()" [disabled]="fromOtherUnivers">
              <mat-icon>add_circle_outline</mat-icon>
              <span> {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ADD_INFO' | translate | uppercase }}</span>
            </button>
          </div>
          <input type="hidden" formControlName="hasNI" [required]="!nextInfos.length && isNextInfoMandatory" />
          <div class="col-xs-12 hol-centered-line hol-error" *ngIf="!nextInfos.length && isNextInfoMandatory">
            {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ADD_INFO_ALERT' | translate }}
          </div>
        </div>
      </div>

      <!-- INFOS LIST -->
      <div class="section" [class.disabled]="!form.get('scenario').valid">
        <div class="occ-table next-infos-list top-space">
          <ul class="hol-list">
            <li *ngFor="let item of nextInfos" class="hol-clickable hol-animate-enter-left" (click)="openDetailsModal($event, item.info)">
              <app-hol-next-info-item
                (markAsDone)="markAsDone(item.info)"
                [isUtc]="isUtc"
                [item]="item.info"
                [canBeDone]="!isCreate && !fromOtherUnivers"
                [isReadOnly]="isReadOnly"
              ></app-hol-next-info-item>
            </li>
          </ul>
        </div>
      </div>

      <!-- NOTIFICATIONS -->
      <div class="section hol-dialog-section" [class.disabled]="!form.get('scenario').valid" *ngIf="!isReadOnly">
        <div class="full-width">
          <div class="hol-spaced-line">
            <div *ngIf="isCreate && notifications && notifications.length !== 0">
              <label>{{ 'COMMON.SEND_NOTIFICATIONS' | translate }}</label>
              <app-notification-table
                [notifications]="notifications"
                [acl]="initialEvent?.acl"
                (saveNotifications)="saveNotifications($event)"
              ></app-notification-table>
            </div>
            <div class="checkbox" *ngIf="showCheckboxes && !initialEvent?.checklistActivated && !fromOtherUnivers">
              <label>
                <mat-checkbox
                  color="primary"
                  formControlName="hasToActivateCheckLists"
                  [disabled]="moduleConfig.config.forceEventChecklistActivation"
                >
                  <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_CHECKLISTS' | translate }}</strong>
                </mat-checkbox>
              </label>
            </div>
            <div class="checkbox hol-primary" *ngIf="showCheckboxes && initialEvent?.checklistActivated">
              <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CHECKLISTS_ACTIVATED' | translate
              }}
            </div>
          </div>
          <div
            class="hol-spaced-line"
            *ngIf="
              showCheckboxes &&
              !isCreate &&
              !fromOtherUnivers &&
              initialEvent?.checklistActivated &&
              moduleConfig.config.activateEventNotification
            "
          >
            <div class="checkbox" *ngIf="!moduleConfig.config.forceEventNotification">
              <label>
                <mat-checkbox color="primary" formControlName="hasToActivateNotification">
                  <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate }}</strong>
                </mat-checkbox>
              </label>
            </div>
            <div class="checkbox hol-primary" *ngIf="moduleConfig.config.forceEventNotification">
              <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="section hol-dialog-section" *ngIf="isReadOnly">
        <div class="checkbox hol-primary" *ngIf="initialEvent?.checklistActivated">
          <i class="icon fa fa-check-square-o"></i>&nbsp;{{
            moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CHECKLISTS_ACTIVATED' | translate
          }}
        </div>
      </div>
    </form>
    <div class="has-to-activate-ecl" *ngIf="hasEclAccess && moduleConfig.config.canActivateEclModule && !isReadOnly && !fromOtherUnivers">
      <div class="activate-ecl" *ngIf="!eclInfos?.moduleIsActivated">
        <label>
          <mat-checkbox color="primary" [(ngModel)]="hasToActivateECL">
            <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_ECL' | translate }}</strong>
          </mat-checkbox>
        </label>
        <mat-form-field class="activate-ecl-title">
          <input
            type="text"
            maxlength="15"
            [(ngModel)]="eclName"
            placeholder="{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_ECL_PLACEHOLDER' | translate }}"
            matInput
          />
        </mat-form-field>
      </div>

      <div class="deactivate-ecl" *ngIf="eclInfos?.moduleIsActivated && initialEvent.objectId === eclInfos?.occEventId && !isCreate">
        <span class="ecl-activated"
          ><i class="icon fa fa-check-square-o"></i>&nbsp;{{
            moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ECL_ACTIVATED' | translate
          }}</span
        >
        <label>
          <mat-checkbox color="primary" [(ngModel)]="hasToDeactivateECL">
            <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DEACTIVATE_ECL' | translate }}</strong>
          </mat-checkbox>
        </label>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="bottom-modal">
      <app-acl-selector
        class="acl-full-width"
        *ngIf="!isReadOnly"
        [readOnly]="isReadOnly"
        [moduleName]="moduleConfig.config.moduleName"
        [limitTo]="limitTo && limitTo.acl"
        [object]="initialEvent"
        [formGroup]="form"
      ></app-acl-selector>
      <!-- TAGS -->
      <div class="hol-dialog-section" [class.disabled]="!form.get('scenario').valid">
        <ng-container *ngIf="selectedTags">
          <app-tag-selector
            class="tag-selector"
            *ngIf="!isReadOnly && !fromOtherUnivers"
            [readOnly]="isReadOnly"
            [(selectedTags)]="selectedTags"
          ></app-tag-selector>
          <app-tag-chips
            class="tag-selector"
            *ngIf="isReadOnly || fromOtherUnivers"
            isShowName="true"
            [tags]="selectedTags"
          ></app-tag-chips>
        </ng-container>
      </div>
    </div>
    <div class="hol-modal__actions">
      <button
        mat-stroked-button
        color="warn"
        *ngIf="!isReadOnly && !isCreate && !fromOtherUnivers && initialEvent.closeReason === undefined"
        (click)="archive($event)"
      >
        <i class="icon fa fa-archive"></i>
        {{ 'COMMON.BUTTON.ARCHIVE' | translate }}
      </button>
      <div class="actions-spacer"></div>
      <button *ngIf="isReadOnly && (!event || (event && !event.fromGOC))" class="hol-validate-btn" mat-dialog-close mat-raised-button>
        <mat-icon>done</mat-icon>
      </button>
      <button
        (click)="validate()"
        *ngIf="!isReadOnly || (isReadOnly && event && event.fromGOC)"
        [disabled]="form.invalid"
        class="hol-validate-btn"
        mat-raised-button
      >
        <mat-icon>done</mat-icon>
        <span *ngIf="isCreate">{{ 'GOC.EVENT_MODAL.ADD' | translate | uppercase }}</span>
        <span *ngIf="!isCreate">{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
