import { HelperService } from './../../common/services/helper.service';
import { HolNotifyFunction } from './../../common/models/hol-notification.model';
import { ErpSmsService } from './erp-sms.service';
import { ErpMailService } from './erp-mail.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { HolNotification } from '../../common/models/hol-notification.model';
import { map } from 'rxjs/operators';
import { ErpCrisisTaskService } from './erp-crisis-task.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { isEqual, orderBy } from 'lodash';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ErpHistoryService } from './erp-history.service';
import { ErpCrisisDecisionTagService } from './erp-crisis-decision-tag.service';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpCrisisDecision, ErpCrisisDecisionTaskToSave } from '../models/erp-crisisDecision';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { RequestService } from 'src/app/common/services/request.service';
import { Injectable, Inject } from '@angular/core';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';
import { Store } from '@ngrx/store';
import { AppState } from '../../store';
import { UserService } from 'src/app/common/services/user.service';
import { EclCrisis } from '../../ecl/models/ecl-crisis';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisDecisionService {
  private _erpCrisisDecisionsState: Observable<ErpCrisisDecision[]>;

  // tslint:disable:variable-name
  private ParseUser = Parse.Object.extend('_User');
  private ParseCrisis = Parse.Object.extend('GDCCrisis');
  private ParseECLCrisis = Parse.Object.extend('ECLCrisis');
  private ParseDecision = Parse.Object.extend('GDCDecisions');
  private ParseTag = Parse.Object.extend('GDCTag');
  private ParseDecisionTag = Parse.Object.extend('GDCDecisionTag');
  private ParseEclDecision = Parse.Object.extend('ECLDecisions');
  // tslint:enabled

  constructor(
    private userService: UserService,
    private crisisStoreManager: CrisisStoreManager,
    private functionsStoreManager: FunctionsStoreManager,
    private requestService: RequestService,
    private store: Store<AppState>,
    private crisisDecisionTagService: ErpCrisisDecisionTagService,
    private historyService: ErpHistoryService,
    private rolesService: RolesService,
    private erpCrisisTaskService: ErpCrisisTaskService,
    private notificationsService: NotificationsService,
    private erpMailService: ErpMailService,
    private erpSmsService: ErpSmsService,
    private helperService: HelperService,
  ) {
    this._erpCrisisDecisionsState = this.store.select('erp', 'crisis', 'decisions');
  }

  getCrisisDecisionByCrisisObjectId(crisisObjectId: string): Promise<ErpCrisisDecision[]> {
    let crisisDecisions: ErpCrisisDecision[] = [];
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    const crisisDecisionQuery = new Parse.Query(this.ParseDecision);
    crisisDecisionQuery.include('ACL');
    crisisDecisionQuery.include('eclDecision');
    crisisDecisionQuery.equalTo('crisis', crisis);
    crisisDecisionQuery.ascending('createdAt');
    const crisisDecisionTagsQuery = new Parse.Query(this.ParseDecisionTag);
    crisisDecisionTagsQuery.include('tag');
    crisisDecisionTagsQuery.descending('createdAt');
    crisisDecisionTagsQuery.matchesQuery('decision', crisisDecisionQuery);
    return Promise.all([
      this.requestService.performFindAllQuery(crisisDecisionQuery),
      this.requestService.performFindQuery(crisisDecisionTagsQuery),
    ]).then(([decisionsFromApi, decisionsTags]) => {
      if (decisionsFromApi) {
        crisisDecisions = decisionsFromApi.map(crisisDecisionFromApi => {
          const tags = this.getTagsForDecision(decisionsTags, crisisDecisionFromApi);
          return new ErpCrisisDecision(crisisDecisionFromApi, tags && tags.map(t => new HolTag(t.get('tag'))));
        });
      }
      return crisisDecisions;
    });
  }

  get crisisDecisionsStream(): Observable<ErpCrisisDecision[]> {
    const $crisisDecisionsStreamSubject: BehaviorSubject<Array<ErpCrisisDecision>> = new BehaviorSubject([]);
    combineLatest(
      this.functionsStoreManager.allTasks.pipe(map(tasks => this.rolesService.filterFromCompanyRoles(tasks))),
      this._erpCrisisDecisionsState,
      this.rolesService.$companiesRolesFilter,
    ).subscribe(([erpCrisisTasks, erpCrisisDecisions]) => {
      // tslint:disable-next-line: max-line-length
      if (erpCrisisDecisions && erpCrisisTasks) {
        erpCrisisDecisions = this.rolesService.filterFromCompanyRoles(erpCrisisDecisions);
        const bufferErpCrisisDecisionsStream: ErpCrisisDecision[] = erpCrisisDecisions.map((fDecision: ErpCrisisDecision) => {
          const newDecision: ErpCrisisDecision = fDecision;
          const tasksForDecision = erpCrisisTasks.filter(t => t.decision && t.decision.objectId === fDecision.objectId);
          newDecision.tasks = orderBy(
            tasksForDecision,
            [item => (item.status && item.status !== 'DONE' ? 0 : 1), 'nextInfoTime', 'order', 'code'],
            ['asc', 'asc', 'asc', 'asc'],
          );
          return newDecision;
        });

        // tslint:disable-next-line: max-line-length
        $crisisDecisionsStreamSubject.next(orderBy(bufferErpCrisisDecisionsStream, ['createdAt'], ['desc']));
      }
    });
    return $crisisDecisionsStreamSubject;
  }

  saveCrisisDecision(
    crisisDecision: Partial<ErpCrisisDecision>,
    notifications: HolNotification[] = [],
    crisisObjectId: string,
    historyLogComment: string,
    decisionTasksToAdd: ErpCrisisDecisionTaskToSave[],
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean; eclCrisis: EclCrisis },
  ): void {
    let parseObject;
    let isCreate;
    if (crisisDecision.objectId) {
      parseObject = new this.ParseDecision({ id: crisisDecision.objectId });
    } else {
      parseObject = new this.ParseDecision();
      parseObject.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
      parseObject.set('crisis', new this.ParseCrisis({ id: crisisObjectId }));
      isCreate = true;
    }
    if (crisisDecision.acl) {
      parseObject.setACL(crisisDecision.acl);
    }
    parseObject.set('title', crisisDecision.title);
    parseObject.set('functionId', crisisDecision.functionId);
    parseObject.set('updatedBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
    parseObject.set('attachments', crisisDecision.attachments);
    parseObject.set('nextInfoTime', crisisDecision.nextInfoTime);
    parseObject.set('nextInfoDone', crisisDecision.nextInfoDone);
    parseObject.set('isOnDashboard', crisisDecision.isOnDashboard);
    parseObject.set('isArchived', crisisDecision.isArchived);
    if (crisisDecision.customCreatedAt) {
      parseObject.set('customCreatedAt', crisisDecision.customCreatedAt);
    }

    parseObject.set('toECL', crisisDecision.toECL);

    this.requestService.performSaveQuery(
      parseObject,
      null,
      async parseData => {
        let parseTags;
        if (crisisDecision.tags) {
          if (isCreate) {
            const crisisDecisionTags = crisisDecision.tags.map(tag => {
              return new this.ParseDecisionTag({
                decision: parseData,
                tag: new this.ParseTag({ id: tag && tag.objectId }),
              });
            });
            parseTags = await this.requestService.performSaveAllQuery(crisisDecisionTags);
          } else {
            parseTags = await this.crisisDecisionTagService.updateTags(crisisDecision);
          }
        }

        const tags = this.getTagsForDecision(parseTags, parseData);
        const bufferCrisisDecision: ErpCrisisDecision = new ErpCrisisDecision(
          parseData,
          tags && tags.map(t => new HolTag(t.get('tag'))),
          crisisDecision.tasks,
        );

        if (decisionTasksToAdd && decisionTasksToAdd.length !== 0) {
          decisionTasksToAdd.forEach(taskToCreate => {
            const notifyFunction: HolNotifyFunction = { sendByMail: false, sendBySms: false, functionTitle: taskToCreate.functionToNotify };
            taskToCreate.crisisTask.decision = bufferCrisisDecision;
            this.erpCrisisTaskService.save(
              taskToCreate.crisisTask,
              { notifications: taskToCreate.notifications, functionToNotify: notifyFunction },
              taskToCreate.historyLogComment,
            );
          });
        }

        if (isCreate) {
          if (notifications && notifications.length) {
            this.sendNotifications(isCreate, bufferCrisisDecision, notifications);
          }
          console.log(1, duplicateToOtherModule.newValue);
          if (duplicateToOtherModule.newValue) {
            await this.duplicateDecisionToOtherModule(bufferCrisisDecision, duplicateToOtherModule.eclCrisis);
          }

          this.crisisStoreManager.createOneCrisisDecision(bufferCrisisDecision);

          this.historyService
            .postLog(bufferCrisisDecision.toLog(historyLogComment ? historyLogComment : 'CREATE', parseData, crisisObjectId))
            .catch(error => console.log(error));
          console.log('crisisDecision created :', bufferCrisisDecision);
          return;
        }

        if (notifications && notifications.length) {
          this.sendNotifications(isCreate, bufferCrisisDecision, notifications);
        }

        if (!isEqual(duplicateToOtherModule.newValue, duplicateToOtherModule.oldValue)) {
          // Supprimer
          if (!duplicateToOtherModule.newValue && duplicateToOtherModule.oldValue) {
            this.deleteDuplicateDecisionFromModule(bufferCrisisDecision);
          }
          // Ajouter
          if (duplicateToOtherModule.newValue && !duplicateToOtherModule.oldValue) {
            await this.duplicateDecisionToOtherModule(bufferCrisisDecision, duplicateToOtherModule.eclCrisis);
          }
        }

        this.crisisStoreManager.updateOneCrisisDecision(bufferCrisisDecision);
        this.historyService
          .postLog(bufferCrisisDecision.toLog(historyLogComment ? historyLogComment : 'UPDATE', parseData, crisisObjectId))
          .catch(error => console.log(error));
        console.log('crisisDecision updated :', bufferCrisisDecision);
      },
      error => {
        console.log(error);
      },
    );
  }

  getTagsForDecision(decisionTags: Parse.Object[], decision: Parse.Object): Parse.Object[] {
    return decisionTags
      ? decisionTags.filter(tag => {
          return tag.get('decision').id === decision.id;
        })
      : [];
  }

  sendNotifications(isCreate: boolean, bufferCrisisDecision: ErpCrisisDecision, notifications: HolNotification[]) {
    const addressMailToSend: string[] = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend: string[] = this.notificationsService.getPhoneNumbersToSend(notifications);

    if (addressMailToSend && addressMailToSend.length) {
      this.erpMailService.sendCrisisDecisionMail(isCreate, bufferCrisisDecision, addressMailToSend);
    }

    if (phoneNumbersToSend && phoneNumbersToSend.length) {
      this.erpSmsService.sendCrisisDecisionSms(isCreate, bufferCrisisDecision, phoneNumbersToSend);
    }
  }

  removeCrisisDecision(crisisDecision: Partial<ErpCrisisDecision>, crisisObjectId: string): void {
    if (crisisDecision.objectId) {
      const parseObject = new this.ParseDecision({ id: crisisDecision.objectId });
      parseObject.set('isArchived', true);

      this.requestService.performSaveQuery(
        parseObject,
        null,
        parseData => {
          const bufferCrisisDecision: ErpCrisisDecision = new ErpCrisisDecision(parseData);
          this.crisisStoreManager.removeOneCrisisDecision(crisisDecision.objectId);
          this.historyService
            .postLog(bufferCrisisDecision.toLog('CLOSE_DECISION', parseData, crisisObjectId))
            .catch(error => console.log(error));
          console.log('crisisDecision archived :', crisisDecision);
        },
        error => {
          console.log(error);
        },
      );
    }
  }

  private async duplicateDecisionToOtherModule(decision: ErpCrisisDecision, eclCrisis: EclCrisis) {
    const parseEclDecision = new this.ParseEclDecision();
    parseEclDecision.set('erpDecision', new this.ParseDecision({ id: decision.objectId }));
    parseEclDecision.set('crisis', new this.ParseECLCrisis({ id: eclCrisis.objectId }));
    const companies = this.helperService.parseACL(decision.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ECL'], companies);
    parseEclDecision.setACL(acl);
    this.requestService.performSaveAllQuery(parseEclDecision).then();
  }

  private deleteDuplicateDecisionFromModule(decision: ErpCrisisDecision) {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const query = new Parse.Query(this.ParseEclDecision);
    query.equalTo('erpDecision', parseDecision);
    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseEclDecision = new this.ParseEclDecision({ id: result.id });
        this.requestService.performDestroyQuery(parseEclDecision).then();
      }
    });
  }

  deleteEclDecision(decision: ErpCrisisDecision) {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const parseEclDecision = new this.ParseEclDecision({ id: decision.eclDecision.objectId });
    parseEclDecision.set('toERP', false);
    this.requestService.performSaveQuery(parseEclDecision).then(() => {
      this.requestService.performDestroyQuery(
        parseDecision,
        () => {
          this.crisisStoreManager.removeOneCrisisDecision(decision.objectId);
        },
        error => {
          console.log(error);
        },
      );
    });
  }
}
