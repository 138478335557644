import { OclHistoryLog } from './../../models/ocl-history-log.model';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { RequestService } from '../../../common/services/request.service';
import { groupBy, Dictionary } from 'lodash';
import { OclOptionsService } from '../ocl-options-service/ocl-options.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { HistoryService } from '../../../common/services/history.service';
import { HolManagerUser } from '../../../common/models/hol-manager-user';
import { HolUser } from '../../../common/models/hol-user.model';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';

export interface OclTimeSlot {
  timeStart: Moment;
  timeEnd: Moment;
  logs: Dictionary<OclHistoryLog[]>;
}
@Injectable({
  providedIn: 'root',
})
export abstract class OclHistoryService<T extends OclHistoryLog> extends HistoryService<T> {
  // tslint:disable:variable-name
  protected abstract ParseHistory;
  protected abstract ParseDecisions;
  protected abstract ParseLogbooks;
  protected abstract ParseEvents;
  protected ParseHolManagerUser = Parse.Object.extend('HOLManagerUsers');
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseVacation = Parse.Object.extend('HOLVacations');
  // tslint:enable
  private _logs;

  protected constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: OclOptionsService,
    protected parseMapper: ParseMapperService,
    protected moduleConfig: ModuleConfigService,
  ) {
    super(requestService);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected setAdditionalFields(inputLog: T, parseObject: Parse.Object) {}

  postLog(log: T): Promise<Parse.Object> {
    const parseLog = new this.ParseHistory();
    parseLog.set('comment', log.comment);
    parseLog.set('type', log.type);
    parseLog.set('subType', log.subType);
    parseLog.set('user', Parse.User.current());
    parseLog.set('attachments', log.attachments);
    parseLog.set('jsonObject', { ...log.jsonObject });
    if (log.acl) {
      parseLog.setACL(log.acl);
    }
    if (log.decision) {
      parseLog.set('decision', new this.ParseDecisions({ id: log.decision.objectId }));
    }
    if (log.logbook) {
      parseLog.set('logbook', new this.ParseLogbooks({ id: log.logbook.objectId }));
    }
    if (log.event) {
      parseLog.set('event', new this.ParseEvents({ id: log.event.objectId }));
    }
    if (log.task) {
      parseLog.set('holTask', new this.ParseTask({ id: log.task.objectId }));
    }
    if (log.vacation) {
      parseLog.set('holVacation', new this.ParseVacation({ id: log.vacation.objectId }));
    }
    this.setAdditionalFields(log, parseLog);
    return this.requestService.performSaveQuery(parseLog).then(savedLog => {
      this.logs.next(this.newHistoryLog(savedLog));
      return savedLog;
    });
  }
  protected newHistoryLog(parseObject: Parse.Object): T {
    return new OclHistoryLog(parseObject) as T;
  }

  public postShiftSupervisorLog(comment: string, newShiftSupervisor: HolManagerUser) {
    console.log('post shift', comment, newShiftSupervisor);
    const parseUser = Parse.User.current();
    if (parseUser) {
      const log = new this.ParseHistory();
      log.set('comment', comment);
      log.set('type', 'shift_supervisor');
      log.set('user', parseUser);

      if (newShiftSupervisor) {
        if (!(newShiftSupervisor instanceof HolUser)) {
          const user = new this.ParseHolManagerUser();
          user.id = newShiftSupervisor.objectId;
          log.set('shiftSupervisorManagerUser', user);
        } else {
          const user = new Parse.User();
          user.id = newShiftSupervisor.objectId;
          log.set('shiftSupervisorUser', user);
        }
      }

      return this.requestService.performSaveQuery(log);
    } else {
      return Promise.resolve();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected setAdditionalFilters(query: Parse.Query) {}

  public getLogsByTimeSlots(forceToRefresh?: boolean, fromDate?: Moment, toDate?: Moment): Promise<OclTimeSlot[]> {
    if (!!this._logs && this._logs.length && !forceToRefresh) {
      return Promise.resolve(this._logs);
    } else {
      const isUtc = this.moduleConfig.config.localHours ? false : true;
      let from = fromDate; // more far in the past
      let to = toDate; // closer in the past or today
      if (isUtc) {
        to = (to && to.clone().endOf('day')) || moment.utc();
        if (to.isAfter(moment().utc())) {
          to = moment.utc();
        }
      } else {
        to = (to && to.clone().endOf('day')) || moment();
        if (to.isAfter(moment())) {
          to = moment();
        }
      }

      from = (from && from.clone().startOf('day')) || to.clone().subtract(1, 'days').startOf('day');

      const timeSlotMinutes = this.optionsService.getHistoryTimeSlot() || 60; // default;

      const now = to.clone();
      now.seconds(0);
      now.millisecond(0);
      if (now.get('minutes') % timeSlotMinutes !== 0) {
        // Set now to a multiple of 'timeSlotMinutes' eg: 10h07 -> 10h30 with timeSlotMinutes = 30
        now.add(timeSlotMinutes - (now.get('minutes') % timeSlotMinutes), 'minutes');
      }

      to = now;

      const query = new Parse.Query(this.ParseHistory);
      query.greaterThan('createdAt', from.toDate());
      query.lessThanOrEqualTo('createdAt', to.toDate());
      query.containedIn('type', ['decision', 'event', 'logbook', 'shift_supervisor', 'task', 'holTask', 'holVacation']);
      query.include('event.scenario');
      query.include('shiftSupervisorManagerUser');
      query.include('shiftSupervisorUser');
      query.include('user');
      query.include('holTask');
      query.descending('createdAt');

      this.setAdditionalFilters(query);

      return this.requestService.performFindAllQuery(query).then(results => {
        const logs = results.map(r => this.newHistoryLog(r));
        const timeSlots: OclTimeSlot[] = [];
        let timeSlotDateStart = now;

        while (timeSlotDateStart.diff(from, 'minutes') > 0) {
          const timeSlotDateEnd = timeSlotDateStart.clone().subtract(timeSlotMinutes, 'minutes');
          const timeSlotLogs = logs.filter(log => {
            return moment(log.moment).isBetween(timeSlotDateEnd, timeSlotDateStart);
          });
          timeSlots.push({
            timeStart: timeSlotDateStart,
            timeEnd: timeSlotDateEnd,
            logs: groupBy(timeSlotLogs, ts => {
              if (ts.type === 'task' || ts.type === 'holTask') {
                return 'event';
              } else if (ts.type === 'holVacation') {
                return 'logbook';
              } else {
                return ts.type;
              }
            }),
          });
          timeSlotDateStart = timeSlotDateEnd;
        }

        if (!fromDate) {
          // save to cache only if no fromDate (save the firsts logs)
          this._logs = timeSlots;
        }
        return timeSlots;
      });
    }
  }

  public fetchNewData() {
    return this.getLogsByTimeSlots(true).then(logs => {
      this.$rootScope.$broadcast('occPoolService-logs', logs);
    });
  }

  getHistoryByObjectIdAndType(objectId: string, type: string): Promise<T[]> {
    let historyLogs: T[];
    let parseType;
    if (type === 'logbook') {
      parseType = new this.ParseLogbooks({ id: objectId });
    }
    if (type === 'decision') {
      parseType = new this.ParseDecisions({ id: objectId });
    }
    if (type === 'event') {
      parseType = new this.ParseEvents({ id: objectId });
    }
    if (type === 'holTask') {
      parseType = new this.ParseTask({ id: objectId });
    }

    const historyLogsQuery = new Parse.Query(this.ParseHistory);
    historyLogsQuery.include('ACL');
    historyLogsQuery.equalTo('type', type);
    historyLogsQuery.equalTo(type, parseType);
    historyLogsQuery.descending('createdAt');
    return this.requestService.performFindAllQuery(historyLogsQuery).then(historyLogsFromApi => {
      if (historyLogsFromApi) {
        historyLogs = historyLogsFromApi.map(historyLogFromApi => this.newHistoryLog(historyLogFromApi));
        return historyLogs;
      } else {
        return [];
      }
    });
  }
}
