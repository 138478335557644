import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-hol-modal-prompt',
  templateUrl: './hol-modal-prompt.component.html',
  styleUrls: ['./hol-modal-prompt.component.scss'],
})
export class HolModalPromptComponent implements OnInit {
  itemsData;
  // @Input() data;
  contentText: string;
  text = new FormControl('', [Validators.required]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('$translate') private $translate,
    public moduleConfig: ModuleConfigService,
    public dialogRef: MatDialogRef<HolModalPromptComponent>,
  ) {
    this.contentText = data.contentText;
  }

  /* statusControl: FormControl;
  commentControl: FormControl;
  */

  getErrorMessage() {
    return this.text.hasError('required') ? 'You must enter a value' : '';
  }

  ngOnInit(): void {
    if (!this.data) {
      throw new Error('HolModalComponent::data is required');
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  valid(): void {
    this.dialogRef.close(this.text.value);
  }
}
