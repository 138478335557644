import { OclEvent } from './ocl-event.model';
import { HolTask } from '../../common/models/hol-task';
import { HolTag } from '../../common/models/hol-tag';

export class OclTask extends HolTask {
  event: OclEvent;
  fromModule: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
    if (!parseObject) {
      return;
    }
    this.fromModule = parseObject.get('fromModule');
    if (parseObject.get('occEvent')) {
      this.event = new OclEvent(parseObject.get('occEvent'));
    } else if (parseObject.get('eclEvent')) {
      this.event = new OclEvent(parseObject.get('eclEvent'));
    } else if (parseObject.get('gocEvent')) {
      this.event = new OclEvent(parseObject.get('gocEvent'));
    } else if (parseObject.get('opsEvent')) {
      this.event = new OclEvent(parseObject.get('opsEvent'));
    }
  }
}

export class OclTaskRef extends HolTask {
  scenario: string;
  modules: string[];

  constructor(parseObject?: Parse.Object, defaultTags?: HolTag[]) {
    super(parseObject, null, defaultTags);
    if (!parseObject) {
      return;
    }
    this.scenario = parseObject.get('scenario');
    this.modules = parseObject.get('modules') ? parseObject.get('modules').split('|') : [];
  }
}
