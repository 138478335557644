import { HolCrisisType } from '../../common/models/hol-crisis';

export class ErpCrisisType extends HolCrisisType {
  info1Title: string;
  info1Hint: string;
  info2Title: string;
  info2Hint: string;
  info3Title: string;
  info3Hint: string;
  info4Title: string;
  info4Hint: string;
  descriptionHint: string;
  functionIdToNotify: string[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.info1Title = parseObject.get('info1Title');
    this.info1Hint = parseObject.get('info1Hint');
    this.info2Title = parseObject.get('info2Title');
    this.info2Hint = parseObject.get('info2Hint');
    this.info3Title = parseObject.get('info3Title');
    this.info3Hint = parseObject.get('info3Hint');
    this.info4Title = parseObject.get('info4Title');
    this.info4Hint = parseObject.get('info4Hint');
    this.descriptionHint = parseObject.get('descriptionHint');
    this.functionIdToNotify = parseObject.get('functionIdToNotify') ? parseObject.get('functionIdToNotify').split('|') : [];
  }
}
