angular.module('gestiondecriseApp').directive('filterColor', function (CrewOptionsService) {
  return {
    restrict: 'A',
    scope: {
      date: '=filterColor',
      isComplete: '=',
    },
    link: function (scope, elem) {
      var filters = CrewOptionsService.getFilters();

      var unregisterWatchIsComplete = scope.$watch('isComplete', function () {
        for (var i = 0; i < filters.length; i++) {
          elem.removeClass('filter-color-' + (i + 1));
        }
        if (scope.isComplete) {
          elem.addClass('filter-color-0');
        } else {
          for (var i = 0; i < filters.length; i++) {
            if (moment(scope.date).isBefore(moment().startOf('day').add(filters[i].threshold, 'day'))) {
              elem.addClass('filter-color-' + (i + 1));
              break;
            }
          }
        }
      });

      scope.$on('$destroy', function () {
        unregisterWatchIsComplete();
      });
    },
  };
});
