import { HolHistoryLog } from './../../common/models/hol-history-log.model';

export class ErpHistoryLog extends HolHistoryLog {
  crisis?: Parse.Object;
  crisisTask?: Parse.Object;
  crisisNew?: Parse.Object;
  crisisAnnouncement?: Parse.Object;
  crisisDecision?: Parse.Object;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = parseObject.get('crisis');
    this.crisisTask = parseObject.get('crisisTask');
    this.crisisNew = parseObject.get('crisisNew');
    this.crisisAnnouncement = parseObject.get('crisisAnnouncement');
    this.crisisDecision = parseObject.get('crisisDecision');
  }
}
