import { FltLogbook } from './../../flt/models/flt-logbook';
import { HolTag } from 'src/app/common/models/hol-tag';

export class GocLogbook extends FltLogbook {
  isPrivate: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.isPrivate = parseObject.get('isPrivate');
  }
}
