import { CrewGlobalInstructionTagService } from './crew-global-instruction-tag.service';
import { Inject, Injectable } from '@angular/core';
import { OclGlobalInstructionsStoreManager } from './../../ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { CrewModuleConfigService } from './crew-module-config.service';
import { RequestService } from '../../common/services/request.service';
import { OclGlobalInstructionService } from '../../ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { UserService } from './../../common/services/user.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclMailService } from 'src/app/ocl/services/ocl-mail-service/ocl-mail.service';
import { OclSmsService } from 'src/app/ocl/services/ocl-sms-service/ocl-sms.service';

@Injectable({
  providedIn: 'root',
})
export class CrewGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('CrewGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('CrewGlobalInstruction');
  protected ParseTag = Parse.Object.extend('CrewTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected globalInstructionTagService: CrewGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected notificationsService: NotificationsService,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    public moduleConfig: CrewModuleConfigService,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      globalInstructionTagService,
      globalInstructionsStoreManager,
      notificationsService,
      mailService,
      smsService,
      moduleConfig,
    );
  }
}
