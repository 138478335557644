import { HolObject } from 'src/app/common/models/hol-object';

export class HolHelperUserFunction extends HolObject {
  functionId: string;
  username: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.functionId = parseObject.get('functionId');
    this.username = parseObject.get('username');
  }
}
