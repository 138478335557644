import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash';

import { ErpFunctionCrisis } from '../../erp/models/erp-functionCrisis';
import { ErpFunctionUser } from '../../erp/models/erp-functionUser';
import { OptionsService } from './options.service';
import { RequestService } from './request.service';

export interface CrisisRolesRef {
  isInCrisisDirectorTeam: boolean;
  isInCrisisInitializerTeam: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  protected ParseUserFunction = Parse.Object.extend('GDCUserFunction_REF');
  protected ParseFunction = Parse.Object.extend('GDCFunction');

  private functions: any[]; // @cache : toutes les functions
  private mustRefresh_functions = false;

  constructor(protected requestService: RequestService, private optionsService: OptionsService) {}

  async getCurrentUserCrisisRightsRef(): Promise<CrisisRolesRef> {
    const fQuery = new Parse.Query(this.ParseFunction);
    fQuery.containedIn(
      'shortTitle',
      this.optionsService.getCrisisDirectorShortTitlesList().concat(this.optionsService.getCrisisInitializerShortTitlesList()),
    );

    const ufQuery = new Parse.Query(this.ParseUserFunction);
    ufQuery.equalTo('userId', Parse.User.current().get('userId'));

    return Promise.all([this.requestService.performFindAllQuery(fQuery), this.requestService.performFindAllQuery(ufQuery)]).then(
      ([pFunctions, pUserFunctions]) => {
        const functions = pFunctions.map(f => new ErpFunctionCrisis(f));
        const userFunctions = pUserFunctions.map(uf => new ErpFunctionUser(uf));
        const crisisDirectorFunctionsIds = functions
          .filter(f => this.optionsService.getCrisisDirectorShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);
        const crisisInitializerFunctionIds = functions
          .filter(f => this.optionsService.getCrisisInitializerShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);

        return {
          isInCrisisDirectorTeam: !!userFunctions.find(uf => crisisDirectorFunctionsIds.includes(uf.functionId)),
          isInCrisisInitializerTeam: !!userFunctions.find(uf => crisisInitializerFunctionIds.includes(uf.functionId)),
        };
      },
    );
  }

  async all(forceRefresh: boolean, withoutTagId: boolean): Promise<any> {
    if (this.mustRefresh_functions !== true && this.functions !== undefined && forceRefresh !== true) {
      return this.functions;
    } else {
      const fQuery = new Parse.Query(this.ParseFunction);
      if (!withoutTagId) {
        fQuery.exists('tagId');
        fQuery.notEqualTo('tagId', '');
      }
      return this.requestService.performFindAllQuery(fQuery).then(pFunctions => {
        const fs = [];
        for (let i = 0; i < pFunctions.length; i++) {
          fs.push(this.transformParseFunctionToObject(pFunctions[i]));
        }
        this.functions = fs;
        this.mustRefresh_functions = false;
        return cloneDeep(this.functions);
      });
    }
  }

  private transformParseFunctionToObject(parseObject: any) {
    return {
      title: parseObject.get('title'),
      shortTitle: parseObject.get('shortTitle'),
      id: parseObject.get('functionId'),
      tagId: parseObject.get('tagId'),
      objectId: parseObject.id,
      tasksSummary: parseObject.get('tasksSummary'),
      phone: parseObject.get('phone'),
      email: parseObject.get('email'),
      minutesBeforeAlert: parseObject.get('minutesBeforeAlert'),
      notified: parseObject.get('notified'),
    };
  }
}
