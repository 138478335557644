import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { OclScenariosStoreManager } from 'src/app/ocl/store/scenarios/ocl-scenarios.store-manager';
import { OclTagsStoreManager } from 'src/app/ocl/store/tags/ocl-tags.store-manager';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclEventService } from '../../../ocl/services/ocl-event-service/ocl-event.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclEventTagService } from '../ecl-event-tag-service/ecl-event-tag.service';
import { OclEventsStoreManager } from '../../../ocl/store/events/ocl-events-store-manager.service';
import { OclEvent } from '../../../ocl/models/ocl-event.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclTasksStoreManager } from '../../../ocl/store/tasks/ocl-tasks.store-manager';
import { EclTagsService } from '../ecl-tags-service/ecl-tags.service';
import { EclTasksService } from '../ecl-tasks.service';
import { UserService } from 'src/app/common/services/user.service';
import { FilesService } from 'src/app/common/services/files.service';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { EclEvent } from '../../models/ecl-event.model';
import { map, orderBy } from 'lodash';
import { HolTag } from '../../../common/models/hol-tag';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunctionState } from '../../ecl.model';

@Injectable({
  providedIn: 'root',
})
export class EclEventService extends OclEventService {
  // tslint:disable:variable-name
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseScenario = Parse.Object.extend('ECLScenario');
  protected ParseEventInfos = Parse.Object.extend('ECLEventInfos');
  protected ParseTaskRef = Parse.Object.extend('ECLTask_REF');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseEventTag = Parse.Object.extend('ECLEventTag');
  protected ParseEclFunction = Parse.Object.extend('ECLFunction');
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');

  // tslint:enable
  protected taskEventColumn = 'eclEvent';
  protected eclFunctionState: EclFunctionState;
  protected eclOpenCrisis: EclCrisis;

  public constructor(
    protected requestService: RequestService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: EclHistoryService,
    protected eventTagService: EclEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: EclMailService,
    protected smsService: EclSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: EclModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: EclTasksService,
    protected tagsService: EclTagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
    protected readonly filesService: FilesService,
    protected markdownService: MarkdownService,
    protected eclFunctionStoreManager: EclFunctionStoreManager,
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager,
      tagsStoreManager,
      scenariosStoreManager,
      filesService,
      markdownService,
    );
    eclFunctionStoreManager.$eclFunctionState.subscribe(eclFs => (this.eclFunctionState = eclFs));
    this.commonStoreManager.eclCrisis.subscribe(c => (this.eclOpenCrisis = c));
  }

  protected markPreviousInfosAsDone(event: OclEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    // Nothing to do for ECL
    return Promise.resolve([]);
  }

  public activateECLModule(eclOptionsId: string, moduleName: string, event: OclEvent, infos: any[]): void {
    return;
  }
  public deactivateECLModule(eclOptionsId: string): void {
    return;
  }

  // protected getAllGOCEvents(): Promise<any[]> {
  //   return Promise.resolve([]);
  // }

  protected getAllMCCEvents(): Promise<any[]> {
    return Promise.resolve([]);
  }

  protected setAdditionalFields(event: OclEvent, parseEvent: Parse.Object) {
    const e = event as EclEvent;
    // Set existing function or new from selected user function
    if (this.eclFunctionState.functions.length) {
      const objectId =
        (e.function && e.function.objectId) ||
        (this.eclFunctionState.selectedUserFunction && this.eclFunctionState.selectedUserFunction.objectId);
      parseEvent.set(
        'function',
        new this.ParseEclFunction({
          id: objectId,
        }),
      );
    }
    parseEvent.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );
  }

  protected getAdditionnalQueries(query, today): Parse.Query {
    query.include('function');
    return super.getAdditionnalQueries(query, today);
  }

  protected newEvent(
    parseObject?: Parse.Object,
    eventTags?: Parse.Object[],
    infosToMap?: [],
    scenariosFromOtherModule?: Parse.Object[],
  ): EclEvent {
    if (!parseObject) {
      return null;
    }
    const infos = map(infosToMap, info => new HolNextInfo(info));
    const tags = orderBy(
      map(eventTags, et => new HolTag(et.get('tag'))),
      'name',
    );
    return new EclEvent(parseObject, tags, infos, scenariosFromOtherModule);
  }
}
