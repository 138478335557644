'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewUsersService
 * @description
 * # CrewUsersService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CrewUsersService', function ($q, RequestService, ParseMapper, CONSTANTS) {
  var CrewUser = Parse.Object.extend('CrewUsers');
  var CrewUserFunction = Parse.Object.extend('CrewUserFunction');
  var that = this;
  this.getAll = function () {
    var deferred = $q.defer();

    RequestService.performCountQuery(
      new Parse.Query(CrewUser),
      function (count) {
        var query = new Parse.Query(CrewUser);
        query.limit(count);
        query.ascending('trigram');
        RequestService.performFindQuery(
          query,
          function (parseUsers) {
            deferred.resolve(_.map(parseUsers, u => ParseMapper.crewUserToObject(u)));
          },
          function (error) {
            deferred.reject(error);
          }
        );
      },
      deferred.reject
    );

    return deferred.promise;
  };

  this.search = function (term, functions) {
    term = term || '';
    var deferred = $q.defer();
    var query = new Parse.Query(CrewUser);
    term = term.split(' ');

    for (var i = 0; i < term.length; i++) {
      var termElement = term[i];
      var queryFirstName = new Parse.Query(CrewUser).matches('firstName', termElement, 'i');
      var queryLastName = new Parse.Query(CrewUser).matches('lastName', termElement, 'i');
      var queryUserId = new Parse.Query(CrewUser).matches('trigram', termElement, 'i');

      if (query) {
        queryFirstName = queryFirstName.matchesKeyInQuery('firstName', 'firstName', query);
        queryLastName = queryLastName.matchesKeyInQuery('lastName', 'lastName', query);
        queryUserId = queryUserId.matchesKeyInQuery('trigram', 'trigram', query);
      }

      query = Parse.Query.or(queryFirstName, queryLastName, queryUserId);
    }

    if (functions && functions.length) {
      var userFunctionQuery = new Parse.Query(CrewUserFunction);
      userFunctionQuery.containedIn('code', functions);
      query.matchesKeyInQuery('userId', 'userId', userFunctionQuery);
    }

    RequestService.performFindQuery(
      query,
      function (parseUsers) {
        that.getUsersFunctionsMap(parseUsers).then(function (ufMap) {
          var users = _.map(parseUsers, function (parseUser) {
            var user = ParseMapper.crewUserToObject(parseUser);
            user.functions = ufMap[user.userId];
            return user;
          });
          deferred.resolve(users);
        }, deferred.reject);
      },
      deferred.reject
    );
    return deferred.promise;
  };

  this.getUsersFunctionsMap = function (users) {
    var deferred = $q.defer();

    users = _.filter(users, function (u) {
      return !!u;
    });

    var crewUserFunctionQuery = new Parse.Query(CrewUserFunction);
    crewUserFunctionQuery.containedIn(
      'userId',
      _.uniq(
        _.map(users, function (user) {
          return user.userId || user.get('userId');
        })
      )
    );
    RequestService.performFindQuery(
      crewUserFunctionQuery,
      function (ufs) {
        var userFunctionsMap = {};
        for (var i = 0; i < ufs.length; i++) {
          var uf = ufs[i];
          if (!userFunctionsMap[uf.get('userId')]) {
            userFunctionsMap[uf.get('userId')] = [];
          }
          userFunctionsMap[uf.get('userId')].push(uf.get('code'));
        }
        deferred.resolve(userFunctionsMap);
      },
      deferred.reject
    );

    return deferred.promise;
  };

  this.create = function (user, func) {
    var deferred = $q.defer();
    getNewUserId(func).then(function (userId) {
      var parseUser = new CrewUser({
        trigram: user.trigram,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        userId: userId,
      });
      RequestService.performSaveQuery(
        parseUser,
        null,
        function (newUser) {
          if (func) {
            var parseUserFunction = new CrewUserFunction({
              userId: userId,
              code: func.code,
            });
            RequestService.performSaveQuery(
              parseUserFunction,
              null,
              function () {
                deferred.resolve(ParseMapper.crewUserToObject(newUser));
              },
              deferred.reject
            );
          } else {
            deferred.resolve(ParseMapper.crewUserToObject(newUser));
          }
        },
        deferred.reject
      );
    }, deferred.reject);
    return deferred.promise;
  };

  this.getUserTitle = function (user) {
    if (!user) {
      return '';
    }
    var functions = '';
    if (user.functions && user.functions.length) {
      functions = ' (' + user.functions.join(', ') + ')';
    }
    return user.firstName + ' ' + user.lastName + functions;
  };

  function getNewUserId(func) {
    var deferred = $q.defer();

    var userQuery = new Parse.Query(CrewUser);
    var regexp;
    if (func) {
      regexp = new RegExp('^' + func.code + '-(\\d+)$', 'i');
    } else {
      regexp = new RegExp('^' + CONSTANTS.COMPANY_NAME + '-(\\d+)$', 'i');
    }
    userQuery.matches('userId', regexp);
    userQuery.select(['userId']);
    RequestService.performFindAllQuery(
      userQuery,
      function (res) {
        var ids = _.map(res, function (user) {
          return parseInt(regexp.exec(user.get('userId'))[1]);
        });
        var max = ids.length ? _.max(ids) : 0;
        if (func) {
          deferred.resolve(func.code + '-' + (max + 1));
        } else {
          deferred.resolve(CONSTANTS.COMPANY_NAME + '-' + (max + 1));
        }
      },
      deferred.reject
    );

    return deferred.promise;
  }

  this.updateAll = function (crewUsers) {
    let deferred = $q.defer();
    let parseObjectsToUpdate = _.map(crewUsers, function (crewUser) {
      return new CrewUser({
        id: crewUser.objectId,
        firstName: crewUser.firstName,
        lastName: crewUser.lastName,
        userId: crewUser.userId,
        phone: crewUser.phone,
        email: crewUser.email,
        trigram: crewUser.trigram,
        noteGlobal: crewUser.noteGlobal,
      });
    });

    RequestService.performSaveAllQuery(
      parseObjectsToUpdate,
      function () {
        console.debug('crew users');
        deferred.resolve();
      },
      deferred.reject
    );

    return deferred.promise;
  };
});
