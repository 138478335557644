import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArchiveService } from '../../services/archive.service';

@Component({
  selector: 'app-archive-modal',
  templateUrl: './archive-modal.component.html',
  styleUrls: ['./archive-modal.component.scss'],
})
export class ArchiveModalComponent implements OnInit {
  public form: FormGroup;
  public item: any;
  public parseObjectClassName: string;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private archiveService: ArchiveService,
    public dialogRef: MatDialogRef<ArchiveModalComponent>
  ) {
    this.title = this.data.title;
    this.item = this.data.item;
    this.parseObjectClassName = this.data.parseObjectClassName;
    this.createForm();
  }

  get reason() {
    return this.form.get('reason') as FormControl;
  }

  get reasonText() {
    return this.form.get('reasonText') as FormControl;
  }

  public createForm(): void {
    this.form = new FormGroup({
      reason: new FormControl('', [Validators.required]),
      reasonText: new FormControl(false),
    });
  }

  public ngOnInit(): void {
    this.reasonText.disable();
    this.reasonText.setValue(null);
    this.reason.valueChanges.subscribe(result => {
      if (result === 2 || result === '2') {
        this.reasonText.enable();
        this.reasonText.setValidators([Validators.required]);
      } else {
        this.reasonText.setValidators(null);
        this.reasonText.disable();
      }
      this.reasonText.updateValueAndValidity();
    });
  }

  public submit(): void {
    this.archiveService
      .close(this.item, this.parseObjectClassName, this.reason.value, this.reasonText.value)
      .then(item => (this.item = item))
      .catch(error => console.log(error))
      .finally(() => this.dialogRef.close(this.item));
  }
}
