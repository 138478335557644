import { HolObject } from '../../common/models/hol-object';

export class EclAssetType extends HolObject {
  assetTypeId: string;
  title: string;
  description: string;
  allowCustomAttributes: boolean;
  dataTableName: string;
  order: number;
  enabled: boolean;
  default: boolean;
  parameters: EclAssetTypeParameter[];
  nameParameterId: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.assetTypeId = parseObject.get('assetTypeId');
      this.title = parseObject.get('title');
      this.description = parseObject.get('description');
      this.allowCustomAttributes = parseObject.get('allowCustomAttributes');
      this.dataTableName = parseObject.get('dataTableName');
      this.order = parseObject.get('order');
      this.default = parseObject.get('default');
      this.enabled = parseObject.get('enabled');
      this.nameParameterId = parseObject.get('nameParameterId');
    }
  }
}

export enum EclParameterType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DECIMAL = 'DECIMAL',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  SINGLE_CHOICE_LIST = 'SINGLE_CHOICE_LIST',
  MULTIPLE_CHOICE_LIST = 'MULTIPLE_CHOICE_LIST',
}

export class EclAssetTypeParameter extends HolObject {
  parameterId: string;
  assetTypeId: string;
  type: EclParameterType;
  title: string;
  description: string;
  placeholder: string;
  defaultValue: string;
  listValues: string[];
  required: boolean;
  order: number;
  dataFieldName: string;
  dataMasterField: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.parameterId = parseObject.get('parameterId');
      this.assetTypeId = parseObject.get('assetTypeId');
      this.type = parseObject.get('type');
      this.title = parseObject.get('title');
      this.description = parseObject.get('description');
      this.placeholder = parseObject.get('placeholder');
      this.defaultValue = parseObject.get('defaultValue');
      this.listValues = (parseObject.get('listValues') || '').split('|');
      this.required = parseObject.get('required');
      this.order = parseObject.get('order');
      this.dataFieldName = parseObject.get('dataFieldName');
      this.dataMasterField = parseObject.get('dataMasterField');
    }
  }
}
