import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { HolisFile } from '../models/hol-attachments.model';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public noteFile: HolisFile;
  public loading = false;
  async;

  constructor() {}

  public async uploadFile(fileName: string, file: any): Promise<string> {
    const parseFile = new Parse.File(fileName, file);
    if (file.base64) {
      const originalHash = CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(file.base64)).toString();
      return parseFile.save().then(savedFile => {
        return this.fetchFileContentBlob(savedFile.url()).then(distantHash => {
          if (originalHash === distantHash) {
            return savedFile.url();
          } else {
            return '';
          }
        });
      });
    } else {
      return parseFile.save().then(savedFile => {
        return savedFile.url();
      });
    }
  }

  public uploadBase64(fileName: string, base64: string): Promise<string> {
    return this.uploadFile(fileName, { base64: base64 });
  }

  async hashFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e: any) => {
        const fileContent = e.target.result;
        const hash = CryptoJS.SHA256(fileContent).toString();
        resolve(hash);
        reader.onerror = error => {
          reject(error);
        };
      };
    });
  }

  async fetchFileContent(url: string): Promise<string> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        return '';
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsBinaryString(blob);
      const base64 = await this.blobToBase64(blob);
      const hash = CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(base64)).toString();

      return hash;
    } catch (error) {
      throw new Error(`Failed to fetch and read the file: ${error.message}`);
    }
  }

  async fetchFileContentBlob(url: string): Promise<string> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        return '';
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsText(blob);
      const base64 = await this.blobToBase64(blob);
      const hash = CryptoJS.SHA256(CryptoJS.enc.Latin1.parse(base64)).toString();

      return hash;
    } catch (error) {
      console.log('error', error);
      throw new Error(`Failed to fetch and read the file: ${error.message}`);
    }
  }

  public blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data);
        reader.onerror = error => {
          reject(error);
        };
      };
      return reader;
    });
  }

  public removeFile(fileName: string, uri: string): Promise<any> {
    const parseFile = new Parse.File(fileName, { uri });
    return parseFile.destroy().then(f => {});
  }
}
