import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { RolesService } from 'src/app/common/services/roles.service';

import { OclEvent } from '../../../ocl/models/ocl-event.model';
import { OclChecklistFunction } from '../../../ocl/store/ocl-tasks.selectors';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-checklist-event',
  templateUrl: './checklist-event.component.html',
  styleUrls: ['./checklist-event.component.scss'],
})
export class ChecklistEventComponent extends BaseComponent implements OnInit, OnDestroy {
  private static storageFilterKey = 'checklist-event__filters';
  private static storageEventIdKey = 'checklist-event__selected-event-id';

  @Input()
  public mod: string;
  @Input()
  public searchCriteria: string;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public events: any;

  @Input()
  set checklistInput(value: OclChecklistFunction[]) {
    this._checklist.next(value);
  }

  get checklistInput(): OclChecklistFunction[] {
    return this._checklist.getValue();
  }

  public checklist: OclChecklistFunction[] = [];
  public checklistSub: Subscription;
  public filterStatus = [];
  public isFilterTodoActive = true;
  public isFilterCloseActive = true;
  public isFilterFrozenActive = true;
  public isFilterNotApplicableActive = true;
  public eventsList: OclEvent[];

  private _selectedEventId: string = null;
  get selectedEventId(): string {
    return this._selectedEventId;
  }
  set selectedEventId(value: string) {
    this._selectedEventId = value;

    localStorage.setItem(
      ChecklistEventComponent.storageEventIdKey,
      JSON.stringify({
        selectedEventId: value,
      }),
    );
  }

  RolesService = RolesService;

  private _checklist = new BehaviorSubject<OclChecklistFunction[]>(undefined);

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.checklistSub = this._checklist.subscribe(result => {
      if (!result) {
        return;
      }
      this.checklist = result;
      this.eventsList = Object.values(
        result.reduce((acc, next) => {
          next.events.forEach(e => {
            if (e.event) {
              acc[e.event.objectId] = e.event;
            }
          });
          return acc;
        }, {}),
      );

      this.eventsList = this.eventsList.filter(item => item.closeDate === null || item.closeDate === undefined);
    });

    const storeFiltersObj = JSON.parse(localStorage.getItem(ChecklistEventComponent.storageFilterKey) || '{}');
    if (Object.keys(storeFiltersObj).length > 0) {
      this.isFilterTodoActive = storeFiltersObj.isFilterTodoActive;
      this.isFilterCloseActive = storeFiltersObj.isFilterCloseActive;
      this.isFilterFrozenActive = storeFiltersObj.isFilterFrozenActive;
      this.isFilterNotApplicableActive = storeFiltersObj.isFilterNotApplicableActive;
    }

    const storeSelectedEventId = JSON.parse(localStorage.getItem(ChecklistEventComponent.storageEventIdKey));
    if (Object.keys(storeSelectedEventId).length > 0) {
      this._selectedEventId = storeSelectedEventId.selectedEventId;
    }

    this.computeKindFilter();
  }

  public ngOnDestroy(): void {
    this.checklistSub.unsubscribe();
  }

  computeKindFilter() {
    this.filterStatus = [];
    if (this.isFilterTodoActive) {
      this.filterStatus.push('TODO');
    }
    if (this.isFilterCloseActive) {
      this.filterStatus.push('DONE');
    }
    if (this.isFilterFrozenActive) {
      this.filterStatus.push('FROZEN');
    }
    if (this.isFilterNotApplicableActive) {
      this.filterStatus.push('NOT_APPLICABLE');
    }

    localStorage.setItem(
      ChecklistEventComponent.storageFilterKey,
      JSON.stringify({
        isFilterTodoActive: this.isFilterTodoActive,
        isFilterCloseActive: this.isFilterCloseActive,
        isFilterFrozenActive: this.isFilterFrozenActive,
        isFilterNotApplicableActive: this.isFilterNotApplicableActive,
      }),
    );
  }
}
