import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import { FltFlightService } from '../../flt/services/flt-flight.service';

@Directive({
  selector: '[appIndicatorTimelineColor]',
})
export class IndicatorTimelineColorDirective implements OnInit, OnChanges {
  @Input()
  public status: string;
  @Input()
  public departureDate: Date;

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.setColor();
  }

  public ngOnChanges(): void {
    this.setColor();
  }

  public setColor(): void {
    this.removeAllClass();

    if (this.isElapsed() && !this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'elapsed');
      return;
    } else if (this.isWithinFirstThreshold() && !this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'withinFirstThreshold');
      return;
    } else if (this.isWithinSecondThreshold() && !this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'withinSecondThreshold');
      return;
    } else if (this.isOverSecondThreshold() && !this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'overSecondThreshold');
      return;
    } else if (this.isWithinThirdThreshold() && !this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'isWithinThirdThreshold');
      return;
    } else if (this.hasTakenOff()) {
      this.renderer.addClass(this.elem.nativeElement, 'takeOff');
    }
  }

  public removeAllClass(): void {
    this.renderer.removeClass(this.elem.nativeElement, 'elapsed');
    this.renderer.removeClass(this.elem.nativeElement, 'withinFirstThreshold');
    this.renderer.removeClass(this.elem.nativeElement, 'withinSecondThreshold');
    this.renderer.removeClass(this.elem.nativeElement, 'overSecondThreshold');
    this.renderer.removeClass(this.elem.nativeElement, 'takeOff');
    this.renderer.removeClass(this.elem.nativeElement, 'isWithinThirdThreshold');
  }

  public isElapsed(): any {
    return moment(this.departureDate).utc(false).isBefore(moment());
  }

  // ( - 15 min]
  public isWithinFirstThreshold(): any {
    return moment(this.departureDate).utc(false).isSameOrBefore(moment().add(15, 'minutes'));
  }

  // (15min - 3 hours]
  public isWithinSecondThreshold(): any {
    return (
      moment(this.departureDate).utc(false).isAfter(moment().add(15, 'minutes')) &&
      moment(this.departureDate).utc(false).isSameOrBefore(moment().add(3, 'hours'))
    );
  }

  // (3hours -  6hours]
  public isWithinThirdThreshold(): any {
    return (
      moment(this.departureDate).utc(false).isAfter(moment().add(3, 'minutes')) &&
      moment(this.departureDate).utc(false).isSameOrBefore(moment().add(6, 'hours'))
    );
  }

  // (6 hours -
  public isOverSecondThreshold(): any {
    return moment(this.departureDate).utc(false).isAfter(moment().add(6, 'hours'));
  }

  public hasTakenOff(): any {
    return FltFlightService.hasTakenOff(this.status);
  }
}
