'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:CreateMemberModalCtrl
 * @description
 * # CreateMemberModalCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('CreateMemberModalCtrl', function (
    $scope,
    $mdDialog,
    $translate,
    ErpMailService,
    ErpSmsService,
    user,
    func,
    crisis,
    ErpFunctionsService,
    ErpUsersService
  ) {
    if (user) {
      $scope.newUser = angular.copy(user);
      $scope.mode = 'edit';
    } else {
      $scope.newUser = {
        email: undefined,
        lastName: undefined,
        firstName: undefined,
        phone: undefined,
        isExternal: false,
      };
      $scope.mode = 'create';
    }
    $scope.working = false;

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.validate = function (ev) {
      if ($scope.newUser.lastName) {
        $scope.newUser.lastName = $scope.newUser.lastName.toUpperCase();
      }
      if ($scope.newUser.firstName && $scope.newUser.firstName.length > 2) {
        $scope.newUser.firstName = _.capitalize($scope.newUser.firstName);
      }

      if ($scope.working === false) {
        $scope.working = true;
        if ($scope.mode === 'edit') {
          doUpdate();
        } else {
          doCreate(ev);
        }
      }
    };

    const addAffectation = function (user) {
      return ErpFunctionsService.addUserToFunction(user.userId, func.functionId).then(() => {
        if (func.notified) {
          ErpMailService.sendNewCrisisEmails(crisis, [func.functionId], [user]);
          ErpSmsService.sendNewCrisisSms(crisis, [func.functionId], [user]);
        }
      });
    };

    const doCreate = function (ev) {
      ErpUsersService.createMember($scope.newUser)
        .then(user => {
          const holConfirm = $mdDialog.holConfirm({
            title: $translate.instant('TEAM.MODAL.CONFIRM_ADD_TO_FUNCTION_TITLE'),
            htmlContent: $translate.instant('TEAM.MODAL.CONFIRM_ADD_TO_FUNCTION', func),
            targetEvent: ev,
          });
          return $mdDialog.show(holConfirm).then(result => {
            if (result) {
              return addAffectation(user);
            }
          });
        })
        .then(() => {
          $mdDialog.hide(true);
        })
        .catch(error => {
          $mdDialog.show(
            $mdDialog.holAlert({
              title: 'Error',
              textContent: error.message,
              ok: 'Ok',
            })
          );
        })
        .finally(() => {
          $scope.working = false;
        });
    };

    const doUpdate = function () {
      ErpUsersService.updateMember($scope.newUser)
        .then(() => {
          $mdDialog.hide(true);
        })
        .catch(error => {
          $mdDialog.show(
            $mdDialog.holAlert({
              title: 'Error',
              textContent: error.message,
              ok: 'Ok',
            })
          );
        })
        .finally(() => {
          $scope.working = false;
        });
    };
  });
