import { FltApplicability } from './flt-applicability';
import { HolTag } from '../../common/models/hol-tag';
import { OclEvent } from 'src/app/ocl/models/ocl-event.model';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';

export class FltEvent extends OclEvent {
  applicability: FltApplicability = new FltApplicability();

  constructor(parseObject?: Parse.Object, tags?: HolTag[], infos?: HolNextInfo[], scenariosFromOtherModule?: Parse.Object[]) {
    super(parseObject, tags, infos, scenariosFromOtherModule);
    if (!parseObject) {
      return;
    }
    this.applicability = new FltApplicability(parseObject);
  }
}
