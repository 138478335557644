import { RolesService } from 'src/app/common/services/roles.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HolVacation } from '../../models/hol-vacation.model';
import { BaseComponent } from '../base/base.component';
import { combineLatest, Subscription } from 'rxjs';
import { HolManagersSelectors, HolUserFunctionWithUser } from '../../store/hol-managers.selectors';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { CommonStoreManager } from '../../store/common.store-manager';
import { HolUser } from '../../models/hol-user.model';

@Component({
  selector: 'app-checklist-vacation',
  templateUrl: './checklist-vacation.component.html',
  styleUrls: ['./checklist-vacation.component.scss'],
})
export class ChecklistVacationComponent extends BaseComponent implements OnDestroy, OnInit {
  vacations: HolVacation[];
  @Input() mod: string;
  @Input() searchCriteria: string;
  @Input() isReadOnly: boolean;
  RolesService = RolesService;
  private vacationSub: Subscription;

  constructor(
    private managersSelectors: HolManagersSelectors,
    public moduleConfig: ModuleConfigService,
    private commonStoreManager: CommonStoreManager,
  ) {
    super();
  }

  ngOnInit(): void {
    this.vacationSub = combineLatest([
      this.commonStoreManager.holVacations,
      this.managersSelectors.managersFunctions,
      this.commonStoreManager.currentUser,
    ]).subscribe(([vacations, mfs, currentUser]) => {
      this.vacations = vacations.map(vac => {
        const managerVacation = mfs.find(mf => mf.functionId === vac.holFunction.functionId);
        vac.isHolder =
          (vac.user && vac.user.objectId === currentUser.objectId) ||
          (managerVacation && !!managerVacation.companies.find(c => this.isHolder(vac, c, currentUser))) ||
          !!mfs.find(mf => mf.isManagerFunction && !!mf.companies.find(c => this.isHolder(vac, c, currentUser)));
        return vac;
      });
    });
  }

  ngOnDestroy(): void {
    this.vacationSub.unsubscribe();
  }

  private isHolder(
    vac: HolVacation,
    company: { name: string; users: HolUserFunctionWithUser[]; holder: HolUserFunctionWithUser },
    currentUser: HolUser,
  ) {
    return vac.companies.includes(company.name) && company.holder && company.holder.user.userId === currentUser.userId;
  }
}
