import { Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { Dictionary } from 'lodash';
import { HolFlight } from 'src/app/common/models/hol-flight.model';
import { FltApplicabilityService } from 'src/app/flt/services/flt-applicability.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { USER_RIGHTS } from 'src/app/app.module';
import { FilesService } from 'src/app/common/services/files.service';

import { HolTag } from '../../../common/models/hol-tag';
import { RequestService } from '../../../common/services/request.service';
import { FltFlightInstructionService } from '../../../flt/services/flt-flight-instruction.service';
import { GocFlightInstruction } from '../../models/goc-flight-instruction.model';

@Injectable({
  providedIn: 'root',
})
export class GocFlightInstructionService extends FltFlightInstructionService<GocFlightInstruction> {
  // tslint:disable:variable-name
  protected ParseFlightInstruction = Parse.Object.extend('GOCFlightInstruction');
  protected ParseDecision = Parse.Object.extend('GOCDecisions');
  protected ParseFlightInstructionTag = Parse.Object.extend('GOCFlightInstructionTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseDecisionTag = Parse.Object.extend('GOCDecisionTag');
  // tslint:enable

  protected constructor(
    protected readonly filesService: FilesService,
    protected requestService: RequestService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    private readonly userService: UserService,
  ) {
    super(filesService, requestService, markdownService, applicabilityService);
  }

  public setAdditionalFields(flightInstruction: GocFlightInstruction, parseFlightInstruction: Parse.Object) {
    parseFlightInstruction.set('isPrivate', flightInstruction.isPrivate);
  }

  protected newFlightInstruction(parseObject?: Parse.Object, tags?: Parse.Object[]): GocFlightInstruction {
    return new GocFlightInstruction(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  public getForFlights(flights: HolFlight[]): Promise<Dictionary<GocFlightInstruction[]>> {
    return this.userService.getAccessRights().then(accessRights => {
      let isPrivate = false;

      // is external user, get only public elements
      if (accessRights.goc === USER_RIGHTS.EXTERNAL_WRITE || accessRights.goc === USER_RIGHTS.EXTERNAL_READ) {
        isPrivate = true;
      }

      return super.getForFlights(flights, isPrivate);
    });
  }
}
