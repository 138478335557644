import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { EclAssetState } from '../../ecl.model';
import { CreateEclAsset, InitEclAsset, InitEclAssetListFromPolling, SelectEclCurrentAssetType, UpdateEclAsset } from './asset.action';
import { EclAssetType, EclAssetTypeParameter } from '../../models/ecl-asset-type';
import { EclAsset } from '../../models/ecl-asset';

@Injectable({
  providedIn: 'root',
})
export class EclAssetStoreManager {
  $eclAssetState: Observable<EclAssetState>;

  constructor(private store: Store<AppState>) {
    this.$eclAssetState = store.select('ecl', 'assetState');
  }

  initState(assetTypeList: EclAssetType[], assetList: EclAsset[], assetTypeParameterList: EclAssetTypeParameter[]) {
    this.store.dispatch(new InitEclAsset(assetTypeList, assetList, assetTypeParameterList));
  }

  initAssetListFromPolling(assetList: EclAsset[]) {
    this.store.dispatch(new InitEclAssetListFromPolling(assetList));
  }

  selectCurrentAssetType(assetType: EclAssetType) {
    this.store.dispatch(new SelectEclCurrentAssetType(assetType));
  }

  createAsset(asset: EclAsset) {
    this.store.dispatch(new CreateEclAsset(asset));
  }

  updateAsset(asset: EclAsset) {
    this.store.dispatch(new UpdateEclAsset(asset));
  }
}
