<div>
  <div>
    <mat-checkbox
      class="reversed-checkbox"
      [disabled]="isReadOnly"
      (ngModelChange)="onModelChange($event, 'isInSummary')"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="isInSummary"
      [labelPosition]="invertedStyle ? 'before' : 'after'"
      aria-label="is in summary"
      color="primary"
    >
      {{ moduleConfig.config.translateKey + '.MODALS.ADD_TO_SUMMARY' | translate }}
    </mat-checkbox>
  </div>
  <div>
    <span></span>
    <mat-checkbox
      class="reversed-checkbox"
      [disabled]="isReadOnly || !isInSummary"
      (ngModelChange)="onModelChange($event, 'isInBriefing')"
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="isInBriefing"
      [labelPosition]="invertedStyle ? 'before' : 'after'"
      aria-label="is in briefing"
      color="primary"
    >
      {{ moduleConfig.config.translateKey + '.MODALS.ADD_TO_BRIEFING' | translate }}
    </mat-checkbox>
  </div>
</div>
