import { TranslatePipe } from '../../common/pipes/translate/translate.pipe';
import { HolOptionsService } from '../../common/services/hol-options.service';
import { Injectable } from '@angular/core';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import * as moment from 'moment/moment';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { ErpCrisis } from '../models/erp-crisis';
import { HolUserWithFunctions } from './erp-users.service';
import { HolUser } from '../../common/models/hol-user.model';
import { ErpCrisisTask } from '../models/erp-crisisTask';
import marked from 'marked';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { messageWithAttachments, timeCell } from '../../common/services/mail/mail-template.utils';

@Injectable({
  providedIn: 'root',
})
export class ErpMailTemplateService {
  constructor(private translatePipe: TranslatePipe, private holOptionsService: HolOptionsService) {}

  public getCrisisNewMail(crisisNew: ErpCrisisNew): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisNew.createdAt);
    msg += messageWithAttachments(crisisNew.content, crisisNew.attachments, this.hideLinks, this.translatePipe);
    msg += '</tr></table>';

    return msg;
  }

  public getCrisisAnnouncementMail(crisisAnnouncement: ErpCrisisAnnouncement): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisAnnouncement.createdAt);
    msg += messageWithAttachments(crisisAnnouncement.message, crisisAnnouncement.attachments, this.hideLinks, this.translatePipe);
    msg += '</tr></table>';

    return msg;
  }

  public getNewCrisisMessage(crisis: ErpCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    let msg = this.translatePipe.transform('MAIL.CRISIS.CRISIS_CREATED') + '<br/><br/>';

    msg += this.translatePipe.transform('MAIL.CRISIS.NEW_CRISIS_CREATED', {
      crisisCreatorName: crisisCreator.fullName,
      crisisMainTitle: crisis.mainTitle,
      crisisSubtitle: crisis.subTitle,
    });

    if (user.functions.length) {
      for (const func of user.functions) {
        try {
          msg += this.translatePipe.transform('MAIL.CRISIS.MEMBER_OF', { function: func.title, company: func.company });
          msg += this.translatePipe.transform('MAIL.CRISIS.FIRST_ACTIONS');
          msg += marked(func.tasksSummary, { breaks: true });
        } catch (error) {
          console.warn('Invalid taskSummary for function ' + func.title + ': ', func.tasksSummary);
        }

        if (func.otherUsers.length > 0) {
          msg += this.translatePipe.transform('MAIL.CRISIS.COORDINATION') + '<ul>';
          for (const u of func.otherUsers) {
            msg += `<li>${u}</li>`;
          }
          msg += '</ul>';
        } else {
          msg += '<br/>';
        }

        msg += '<hr/>';
      }
    } else {
      console.warn('no function for user ', user);
    }

    msg += this.translatePipe.transform('MAIL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin }) + '<br/><br/>';

    msg +=
      this.translatePipe.transform('MAIL.CRISIS.CONTACT_CREATOR', {
        crisisCreatorName: crisisCreator.fullName,
        crisisCreatorPhone: crisisCreator.phone || '',
        crisisCreatorEmail: crisisCreator.email || '',
      }) + '<br/><br/>';

    msg += this.translatePipe.transform('MAIL.CRISIS.ERP_MARKETING') + '<br/><br/>';

    msg += this.translatePipe.transform('MAIL.CRISIS.USE_IT');

    return msg;
  }

  public getCrisisTaskDoneMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisTask.createdAt);

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_DONE.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_DONE.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  getCrisisTaskFrozenMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisTask.createdAt);

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_FROZEN.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
      comment,
    });
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  getCrisisTaskBackTodoMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisTask.createdAt);

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_TODO.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.translatePipe.transform('MAIL.CRISIS.CRISIS_TASK_TO_TODO.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  getCrisisTaskMail(isCreate: boolean, crisisTask: ErpCrisisTask, functionToNotify?: string): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisTask.createdAt);

    if (isCreate) {
      msg += '<td>';
      msg += this.translatePipe.transform('MAIL.CRISIS.CRISIS_NEW_TASK.FISRT_PART', {
        firstName: crisisTask.createdBy.firstName,
        lastName: crisisTask.createdBy.lastName,
        taskTitle: crisisTask.taskTitle,
        functionToNotify,
      });
      if (crisisTask.nextInfoTime) {
        msg += '&nbsp;';
        msg += this.translatePipe.transform('MAIL.CRISIS.CRISIS_NEW_TASK.SECOND_PART') + '&nbsp;';
        msg += moment(crisisTask.nextInfoTime).utc().format('HH:mm[UTC]ddd[&nbsp;]DD');
      }
      msg += '.';
      msg += '</td>';
    } else {
      msg += '<td>' + crisisTask.taskTitle + '</td>';
    }
    msg += '</tr></table>';

    return msg;
  }

  getCrisisDocumentMail(isCreate: boolean, crisisDocument: ErpCrisisTask, functionToNotify?): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisDocument.createdAt);

    let content = isCreate
      ? this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.CREATED_BY', {
          firstName: crisisDocument.createdBy.firstName,
          lastName: crisisDocument.createdBy.lastName,
          title: crisisDocument.outputDataLabel,
        })
      : this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.UPDATED_BY', {
          firstName: crisisDocument.updatedBy.firstName,
          lastName: crisisDocument.updatedBy.lastName,
          title: crisisDocument.outputDataLabel,
        });
    if (functionToNotify) {
      content += '&nbsp;';
      content += this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.AFFECT_TO_FUNCTION', { functionToNotify });
    }
    if (crisisDocument.nextInfoTime) {
      content += '&nbsp;';
      content += this.translatePipe.transform('MAIL.CRISIS.CRISIS_DOCUMENT.NEXT_INFO') + '&nbsp;';
      content += moment(crisisDocument.nextInfoTime).utc().format('HH:mm[UTC][&nbsp;]ddd[&nbsp;]DD');
    }
    content += '.';
    msg += messageWithAttachments(content, crisisDocument.attachments, this.hideLinks, this.translatePipe);

    msg += '</tr></table>';

    return msg;
  }

  getCrisisDecisionMail(isCreate: boolean, crisisDecision: ErpCrisisDecision): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg += timeCell(crisisDecision.createdAt);

    let content = isCreate
      ? this.translatePipe.transform('MAIL.CRISIS.CRISIS_DECISION.CREATED_BY', {
          firstName: crisisDecision.createdBy.firstName,
          lastName: crisisDecision.createdBy.lastName,
          title: crisisDecision.title,
        })
      : this.translatePipe.transform('MAIL.CRISIS.CRISIS_DECISION.UPDATED_BY', {
          firstName: crisisDecision.updatedBy.firstName,
          lastName: crisisDecision.updatedBy.lastName,
          title: crisisDecision.title,
        });
    if (crisisDecision.nextInfoTime) {
      content += '&nbsp;';
      content += this.translatePipe.transform('MAIL.CRISIS.CRISIS_DECISION.NEXT_INFO') + '&nbsp;';
      content += moment(crisisDecision.nextInfoTime).utc().format('HH:mm[UTC][&nbsp;]ddd[&nbsp;]DD');
    }
    content += '.';
    msg += messageWithAttachments(content, crisisDecision.attachments, this.hideLinks, this.translatePipe);

    msg += '</tr></table>';

    return msg;
  }

  private get hideLinks(): boolean {
    return this.holOptionsService.getHideAttachmentLinksOnEmails();
  }
}
