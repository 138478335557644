import { SmsService } from './../../common/services/sms/sms.service';
import { MailService } from './../../common/services/mail/mail.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { MccEvent } from '../models/mcc-event.model';
import { HolNotification } from '../../common/models/hol-notification.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import * as moment from 'moment';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { MccAircraftsStoreManager } from '../store/aircrafts/mcc-aircrafts.store-manager';
import { MccHistoryService } from './mcc-history.service';
import { MccAircraft } from '../models/mcc-aircraft.model';
@Injectable({
  providedIn: 'root',
})
export class MccEventService {
  // tslint:disable:variable-name
  ParseMccEvent = Parse.Object.extend('MCCEvent');
  ParseMccEventInfo = Parse.Object.extend('MCCEventInfo');
  ParseMccAirCraft = Parse.Object.extend('MCCAirCraft');
  ParseMccATACode = Parse.Object.extend('MCCATACode');
  // tslint:enable

  constructor(
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private mailService: MailService,
    private smsService: SmsService,
    @Inject('ChatService') private chatService,
    private aircraftsStoreManager: MccAircraftsStoreManager,
    private mccHistoryService: MccHistoryService,
  ) {}

  public create(event: MccEvent, notifications: HolNotification[], aircraft: MccAircraft, acl: Parse.ACL): Promise<MccEvent> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);

    const parseEvent = new this.ParseMccEvent({
      aircraft: new this.ParseMccAirCraft({ id: event.aircraft.objectId }),
      ataCode: new this.ParseMccATACode({ id: event.ataCode.objectId }),
      localisation: event.localisation,
      description: event.description,
      attachments: JSON.stringify(event.attachments),
      createdBy: Parse.User.current(),
      toOCC: event.toOCC,
      localisationUpdatedAt: new Date(),
    });

    if (acl) {
      parseEvent.setACL(acl);
    }

    return this.requestService.performSaveQuery(parseEvent).then(parseObj => {
      const parseInfos = event.infos.map(info => {
        const parseMccEventInfo = new this.ParseMccEventInfo({
          event: parseObj,
          message: info.message,
          nextInfoTime: info.nextInfoTime,
          createdBy: Parse.User.current(),
          attachments: JSON.stringify(info.attachments),
        });
        if (acl) {
          parseMccEventInfo.setACL(acl);
        }
        return parseMccEventInfo;
      });

      return this.requestService.performSaveAllQuery(parseInfos).then(pInfo => {
        const newEvent = new MccEvent(
          parseObj,
          pInfo.map(i => new HolNextInfo(i)),
        );
        if (addressMailToSend.length) {
          this.mailService.sendNewMCCEventMail(newEvent, addressMailToSend);
        }
        if (phoneNumbersToSend.length) {
          this.smsService.sendNewMCCEventSMS(newEvent, phoneNumbersToSend);
        }
        this.aircraftsStoreManager.addEvent(newEvent);
        this.mccHistoryService.postMccLog(
          newEvent.description,
          'event',
          'create',
          newEvent.acl,
          aircraft,
          newEvent,
          null,
          null,
          newEvent.attachments,
        );

        return newEvent;
      });
    });
  }

  public update(event: MccEvent, aircraft: MccAircraft, acl: Parse.ACL): Promise<MccEvent> {
    const parseMCCEvent = new this.ParseMccEvent();
    parseMCCEvent.id = event.objectId;
    if (acl) {
      parseMCCEvent.setACL(acl);
    }

    parseMCCEvent.set('ataCode', new this.ParseMccATACode({ id: event.ataCode.objectId }));

    if (parseMCCEvent.get('localisation') !== event.localisation) {
      parseMCCEvent.set('localisationUpdatedAt', new Date());
    }

    parseMCCEvent.set('description', event.description);
    parseMCCEvent.set('localisation', event.localisation);
    parseMCCEvent.set('attachments', JSON.stringify(event.attachments));
    parseMCCEvent.set('toOCC', event.toOCC);

    return this.requestService.performSaveQuery(parseMCCEvent).then(res => {
      return this.updateInfos(event.infos, acl).then(pInfos => {
        const newEvent = new MccEvent(res, pInfos);
        this.aircraftsStoreManager.updateEvent(newEvent);
        this.mccHistoryService.postMccLog(
          newEvent.description,
          'event',
          'update',
          newEvent.acl,
          aircraft,
          event,
          null,
          null,
          newEvent.attachments,
        );

        return newEvent;
      });
    });
  }

  public getEvent(id: string): Promise<MccEvent> {
    const query = new Parse.Query(this.ParseMccEvent);
    query.equalTo('objectId', id);

    return this.requestService.performFirstQuery(query).then(parseMCCEvent => {
      const eventInfoQuery = new Parse.Query(this.ParseMccEventInfo);
      eventInfoQuery.equalTo('event', parseMCCEvent);
      eventInfoQuery.descending('createdAt');
      return this.requestService.performFindQuery(eventInfoQuery).then(eventInfos => {
        return new MccEvent(
          parseMCCEvent,
          eventInfos.map(i => new HolNextInfo(i)),
        );
      });
    });
  }

  public getAll(filters?: any): Promise<MccEvent[]> {
    const query = new Parse.Query(this.ParseMccEvent);
    query.doesNotExist('closeReason');
    if (filters) {
      Object.keys(filters).forEach(key => {
        query.equalTo(key, filters[key]);
      });
    }
    query.include('aircraft');

    return this.requestService.performFindAllQuery(query).then(parseEvents => {
      const eventInfoQuery = new Parse.Query(this.ParseMccEventInfo);
      eventInfoQuery.descending('createdAt');
      return this.requestService.performFindAllQuery(eventInfoQuery).then(parseEventInfos => {
        return parseEvents.map(ev => {
          const infos = parseEventInfos.filter(pei => {
            return pei.get('event') && pei.get('event').id === ev.id;
          });
          return new MccEvent(
            ev,
            infos.map(i => new HolNextInfo(i)),
          );
        });
      });
    });
  }

  public updateInfo(info: HolNextInfo, acl: Parse.ACL): Promise<HolNextInfo> {
    const parseMCCEventInfo = new this.ParseMccEventInfo();
    parseMCCEventInfo.id = info.objectId;
    if (acl) {
      parseMCCEventInfo.setACL(acl);
    }
    parseMCCEventInfo.set('attachments', JSON.stringify(info.attachments));
    return this.requestService.performSaveQuery(parseMCCEventInfo).then(res => {
      return new HolNextInfo(res);
    });
  }

  public updateInfos(infos: HolNextInfo[], acl: Parse.ACL): Promise<HolNextInfo[]> {
    return Promise.all(infos.map(i => this.updateInfo(i, acl)));
  }

  public addInfo(
    event: MccEvent,
    message: string,
    nextInfoTime: Date,
    notifications: HolNotification[],
    attachments: HolAttachments,
    aircraft: MccAircraft,
  ): Promise<HolNextInfo> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
    const eventInfo = new this.ParseMccEventInfo();
    const eventParse = new this.ParseMccEvent();
    eventParse.id = event.objectId;
    eventInfo.set('event', eventParse);
    eventInfo.set('message', message);
    eventInfo.set('nextInfoTime', nextInfoTime);
    eventInfo.set('createdBy', Parse.User.current());
    if (attachments) {
      eventInfo.set('attachments', JSON.stringify(attachments));
    }

    return this.requestService.performSaveQuery(eventInfo).then(parseData => {
      const infosToMarkAsDone = event.infos.filter(info => {
        return !info.done && moment(info.nextInfoTime).isBefore(nextInfoTime);
      });

      const promises = infosToMarkAsDone.map(info => {
        return this.markInfoAsDone(info, event, true, false, aircraft);
      });
      return Promise.all(promises).then(() => {
        const newInfo = new HolNextInfo(parseData);
        if (!event.infos) {
          event.infos = [];
        }
        event.infos.unshift(newInfo);

        if (addressMailToSend.length) {
          this.mailService.sendNewMCCEventInfoMail(event, newInfo, addressMailToSend);
        }

        if (phoneNumbersToSend.length) {
          this.smsService.sendNewMCCEventSMS(event, phoneNumbersToSend, newInfo);
        }

        this.aircraftsStoreManager.updateEvent(event);
        this.mccHistoryService.postMccLog(newInfo.message, 'event', 'info', event.acl, aircraft, event, null, null, newInfo.attachments);

        return newInfo;
      });
    });
  }

  public setChannelId(event: MccEvent): Promise<Parse.Object> {
    const parseEvent = new this.ParseMccEvent({ id: event.objectId });
    parseEvent.set('channelId', event.channelId);
    return this.requestService.performSaveQuery(parseEvent);
  }

  public close(event: MccEvent, reason: number, reasonText: string, aircraft: MccAircraft): Promise<MccEvent> {
    const parseEvent = new this.ParseMccEvent({
      id: event.objectId,
    });
    return this.requestService
      .performSaveQuery(parseEvent, {
        closeReason: reason,
        closeReasonText: reasonText,
        closeDate: new Date(),
      })
      .then(parseObject => {
        event.closeReason = reason;
        event.closeReasonText = reasonText;
        event.closeDate = parseObject.get('closeDate');

        if (event.channelId) {
          this.chatService.archiveChannel(event.channelId).then(
            () => {
              console.log('channel archived !');
            },
            error => {
              console.error('Error while archiving channel', error.data.error);
            },
          );
        }
        this.mccHistoryService.postMccLog(
          event.closeReasonText ? event.closeReasonText : event.description,
          'event',
          'archive',
          event.acl,
          aircraft,
          event,
          null,
          null,
          event.attachments,
        );

        this.aircraftsStoreManager.removeEvent(event);
        return event;
      });
  }

  public markInfoAsDone(info: HolNextInfo, event: MccEvent, done: boolean, manual: boolean, aircraft: MccAircraft): Promise<HolNextInfo> {
    const eventInfo = new this.ParseMccEventInfo();
    eventInfo.id = info.objectId;
    eventInfo.set('done', done);
    eventInfo.set('manual', manual);
    return this.requestService.performSaveQuery(eventInfo).then(parseData => {
      const newInfo = new HolNextInfo(parseData);

      this.aircraftsStoreManager.updateEventInfo(newInfo);
      Object.assign(info, newInfo);

      event.infos = event.infos.map(inf => {
        if (inf.objectId === newInfo.objectId) {
          return newInfo;
        }
        return inf;
      });

      this.mccHistoryService.postMccLog(
        newInfo.message,
        'event',
        done ? 'info-done' : 'info-backtodo',
        event.acl,
        aircraft,
        event,
        null,
        null,
        event.attachments,
      );

      return newInfo;
    });
  }
}
