import { Injectable } from '@angular/core';
import { map } from 'lodash';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';

@Injectable({
  providedIn: 'root',
})
export class OclGlobalInstructionTagService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag;
  protected ParseGlobalInstruction;
  protected ParseTag;
  // tslint:enable

  public constructor(private requestService: RequestService) {}

  public updateGlobalInstructionWithTags(globalInstruction: OclGlobalInstruction): Promise<Parse.Object[]> {
    return this.deleteGlobalInstructionTagsFor(globalInstruction).then(() => {
      return this.setTagsForGlobalInstruction(globalInstruction);
    });
  }

  private setTagsForGlobalInstruction(globalInstruction: OclGlobalInstruction): Promise<Parse.Object[]> {
    const parseGlobalInstruction = new this.ParseGlobalInstruction({ id: globalInstruction.objectId });

    if (globalInstruction.tags) {
      const currentParseTags = globalInstruction.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseGlobalInstructionTag();
        joinTableRecord.set('globalInstruction', parseGlobalInstruction);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private deleteGlobalInstructionTagsFor(globalInstruction: OclGlobalInstruction): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseGlobalInstructionTag);
    const parseGlobalInstruction = new this.ParseGlobalInstruction({ id: globalInstruction.objectId });
    query.equalTo('globalInstruction', parseGlobalInstruction);

    return this.requestService.performFindQuery(query).then(parseOccGlobalInstructionTags => {
      return this.requestService.performDestroyAllQuery(parseOccGlobalInstructionTags);
    });
  }

  public createTagFromParseData(globalInstruction: OclGlobalInstruction, parseData): Promise<Parse.Object[]> {
    return new Promise((resolve, reject) => {
      if (globalInstruction.tags && globalInstruction.tags.length) {
        const globalInstructionTags = map(globalInstruction.tags, tag => {
          return new this.ParseGlobalInstructionTag({
            globalInstruction: parseData,
            tag: new this.ParseTag({ id: tag && tag.objectId }),
          });
        });
        this.requestService.performSaveAllQuery(
          globalInstructionTags,
          res => resolve(res),
          error => reject(error),
        );
      } else {
        resolve();
      }
    });
  }

  public getTags(globalInstructionTags: Parse.Object[], globalInstruction: Parse.Object): Parse.Object[] {
    return globalInstructionTags
      ? globalInstructionTags.filter(dt => {
          return dt.get('globalInstruction').id === globalInstruction.id;
        })
      : [];
  }
}
