import { Injectable } from '@angular/core';
import { TagsService } from '../../common/services/tags.service';

@Injectable({
  providedIn: 'root',
})
export class MccCabinTagService extends TagsService {
  // tslint:disable-next-line:variable-name
  protected ParseTag = Parse.Object.extend('MCCCabinTag');
}
