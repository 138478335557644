import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { nextInfoType } from '../hol-next-info/hol-next-info.component';
import { HolAttachments } from '../../models/hol-attachments.model';
import { HolUTCDisplay, utcEval } from '../../enums/hol-utc-display.enum';
import { HolTask } from '../../models/hol-task';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { EclDecision } from '../../../ecl/models/ecl-decision';
import { EclSummary } from '../../../ecl/models/ecl-summary';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';
import { EclDecisionGroup } from '../../../ecl/models/ecl-decision-group.model';

@Component({
  selector: 'app-hol-list-item',
  templateUrl: './hol-list-item.component.html',
  styleUrls: ['./hol-list-item.component.scss'],
})
export class HolListItemComponent implements OnInit {
  @Input() item: HolObject;
  @Input() utcDisplay: HolUTCDisplay = HolUTCDisplay.LOCAL_IF_READONLY;
  @Input() isReadOnly = false;
  @Input() displayAllTextInItem = false;
  @Input() isHistory = false;
  @Input() fromModule: string;
  @Input() toModule: string;
  @Input() nextInfo: nextInfoType;
  @Input() canUpdateItem = false;
  @Input() userFunctionsList = [];
  @Input() displayUser = true;
  @Input() isRestricted = false;
  @Input() isRestrictedInfos = '';
  @Input() hideCreatedBy = false;
  @Input() public summaryGroup?: EclSummary;
  @Input() public group?: OclGroup<any>;
  @Input() attachments: HolAttachments = new HolAttachments();

  public userTooltipContent = '';
  @Output() saveNextInfo = new EventEmitter<nextInfoType>();

  constructor(public moduleConfig: ModuleConfigService) {}

  public get isUtc(): boolean {
    if (this.isHistory) {
      return true;
    }
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  ngOnInit() {
    this.userTooltipContent = this.item.createdBy ? this.item.createdBy.fullName : '';

    if (this.userFunctionsList) {
      let userFunctionTitles = '';
      userFunctionTitles += this.userFunctionsList.map(f => {
        if (f) {
          return f.shortTitle;
        }
      });
      this.userTooltipContent += userFunctionTitles.trim().length > 0 ? ' (' + userFunctionTitles + ')' : '';
    }

    if (!this.item) {
      throw new Error('HolListItemComponent::item is required');
    }
  }

  isOnSummary() {
    if (this.item instanceof EclLogbook) {
      if ((this.item as EclLogbook).summary) {
        return true;
      }
    } else if (this.item instanceof EclDecision) {
      if ((this.item as EclDecision).summary) {
        return true;
      }
    }
    return false;
  }

  isOnSummaryGroup() {
    if (this.group) {
      if (this.group instanceof EclLogbookGroup) {
        if (this.group.logbooksInSummary) {
          const logbook = this.group.logbooksInSummary.find(logbook => {
            return logbook.objectId === this.item.objectId;
          });
          if (logbook) {
            return true;
          }
        }
      } else if (this.group instanceof EclDecisionGroup) {
        if (this.group.decisionsInSummary) {
          const decision = this.group.decisionsInSummary.find(decision => {
            return decision.objectId === this.item.objectId;
          });
          if (decision) {
            return true;
          }
        }
      }
      return false;
    }
  }

  isOnSummaryBriefing() {
    if (this.item instanceof EclLogbook) {
      if ((this.item as EclLogbook).summary) {
        return this.item.summary.isDisplayInBriefing;
      }
    } else if (this.item instanceof EclDecision) {
      if ((this.item as EclDecision).summary) {
        return this.item.summary.isDisplayInBriefing;
      }
    }
    return false;
  }

  isTraining() {
    if (this.item instanceof EclLogbook) {
      if ((this.item as EclLogbook).summary) {
        return this.item.summary.crisis.isTraining;
      }
    } else if (this.item instanceof EclDecision) {
      if ((this.item as EclDecision).summary) {
        return this.item.summary.crisis.isTraining;
      }
    }
    return false;
  }

  isReal() {
    if (this.item instanceof EclLogbook) {
      if ((this.item as EclLogbook).summary) {
        return !this.item.summary.crisis.isTraining;
      }
    } else if (this.item instanceof EclDecision) {
      if ((this.item as EclDecision).summary) {
        return !this.item.summary.crisis.isTraining;
      }
    }
    return false;
  }

  markAsDone() {
    this.saveNextInfo.emit(this.nextInfo);
  }

  isDisplayDate(): boolean {
    if (this.moduleConfig.config.moduleName === 'erp' && this.item instanceof HolTask) {
      return (<HolTask>this.item).status !== 'TODO';
    } else if (
      this.moduleConfig.config.moduleName === 'erp' &&
      this.item.updatedAt &&
      this.item.updatedAt.getTime() !== this.item.createdAt.getTime()
    ) {
      return true;
    } else if (this.moduleConfig.config.moduleName !== 'erp' && this.item.customCreatedAt) {
      return true;
    } else if (this.moduleConfig.config.moduleName !== 'erp' && !this.item.customCreatedAt) {
      return true;
    }
    return false;
  }

  getDisplayDate(): Date {
    if (
      this.moduleConfig.config.moduleName === 'erp' &&
      this.item.updatedAt &&
      this.item.updatedAt.getTime() !== this.item.createdAt.getTime()
    ) {
      return this.item.updatedAt;
    } else if (this.moduleConfig.config.moduleName !== 'erp' && this.item.customCreatedAt) {
      return this.item.customCreatedAt;
    } else if (this.moduleConfig.config.moduleName !== 'erp' && !this.item.customCreatedAt) {
      return this.item.createdAt;
    }
    return this.item.createdAt;
  }
}
