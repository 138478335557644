import { Inject, Injectable } from '@angular/core';

import * as moment from 'moment';
import { identity, uniqBy } from 'lodash';

import { HolNextInfo } from '../models/hol-next-info.model';
import { HolRole } from '../models/hol-role';
import { Company } from '../models/hol-user.model';
import { RolesService } from './roles.service';
import { HolObject } from '../models/hol-object';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  getACLFromCompaniesRolesFilter;

  constructor(@Inject('CONSTANTS') public CONSTANTS) {}

  public static mixColors(color1: string = 'ffffff', color2: string = '000000', weight = 50) {
    color1 = color1.replace('#', '');
    color2 = color2.replace('#', '');
    if (color1.length === 3) {
      color1 = `${color1[0]}${color1[0]}${color1[1]}${color1[1]}${color1[2]}${color1[2]}`;
    }
    if (color2.length === 3) {
      color2 = `${color2[0]}${color2[0]}${color2[1]}${color2[1]}${color2[2]}${color2[2]}`;
    }

    let color = '#';

    for (let i = 0; i <= 5; i += 2) {
      // loop through each of the 3 hex pairs-red, green, and blue
      const v1 = HelperService.h2d(color1.substr(i, 2)); // extract the current pairs
      const v2 = HelperService.h2d(color2.substr(i, 2));

      // combine the current pairs from each source color, according to the specified weight
      let val = HelperService.d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

      while (val.length < 2) {
        val = '0' + val;
      } // prepend a '0' if val results in a single digit

      color += val; // concatenate val to our new color string
    }

    return color; // PROFIT!
  }

  private static d2h(d) {
    return d.toString(16);
  } // convert a decimal value to hex

  private static h2d(h) {
    return parseInt(h, 16);
  } // convert a hex value to decimal

  generateWarningNextInfoClass(info: HolNextInfo): string {
    if (info.done && info.manual) {
      return 'hol-done';
    }
    if (info.done && !info.manual) {
      return 'hol-affected';
    }
    if (moment(info.nextInfoTime).isBefore(moment())) {
      return 'elapsed';
    }
    if (moment(info.nextInfoTime).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING) {
      return 'almost-elapsed';
    }
  }

  parseACL(acl: Parse.ACL) {
    const companies = [];
    if (!acl) {
      return;
    }
    Object.entries(acl.permissionsById).forEach(([key, value]) => {
      const role: any = value;
      if ((key.startsWith('role:') && role.read) || role.write) {
        const roleName = key.replace('role:', '');
        if (HolRole.ROLE_PARTS_REGEXP.test(roleName)) {
          const parts = HolRole.ROLE_PARTS_REGEXP.exec(roleName);
          companies.push(parts[2]);
        }
      }
    });
    return uniqBy(companies, identity).sort((a, b) => (a < b ? -1 : 1));
  }

  parseACLByCompany(acl: Parse.ACL): Company[] {
    const companyMap = new Map<string, Company>();

    if (!acl) {
      return [];
    }

    Object.entries(acl.permissionsById).forEach(([key, value]) => {
      const role: any = value;
      if ((key.startsWith('role:') && role.read) || role.write) {
        const roleName = key.replace('role:', '');
        if (HolRole.ROLE_PARTS_REGEXP.test(roleName)) {
          const parts = HolRole.ROLE_PARTS_REGEXP.exec(roleName);
          const companyName = parts[2];
          const permissionType = parts[4];
          const existingCompany = companyMap.get(companyName);
          if (existingCompany) {
            // Update existing Company instance if it already exists in the Map
            existingCompany.read = existingCompany.read || permissionType === 'READ';
            existingCompany.write = existingCompany.write || permissionType === 'WRITE';
          } else {
            // Create new Company instance and add it to the Map
            companyMap.set(companyName, new Company(companyName, permissionType === 'READ', permissionType === 'WRITE'));
          }
        }
      }
    });

    // Convert the values in the Map into an array and sort them by name
    return Array.from(companyMap.values()).sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  allowedACL(acl1: Parse.ACL | string[] | null, acl2: Parse.ACL | string[] | null): boolean {
    const aclAllowed = (acl1: string[], acl2: string[]) => {
      return acl1 === null || acl2 === null || acl1.some(v => acl2.includes(v)) || acl1.length === 0 || acl2.length === 0;
    };
    const parseAcl1 = acl1 instanceof Parse.ACL ? this.parseACL(acl1) : acl1;
    const parseAcl2 = acl2 instanceof Parse.ACL ? this.parseACL(acl2) : acl2;
    return aclAllowed(parseAcl1, parseAcl2);
  }

  public mergeAcls(aclBase: Parse.ACL, aclToMerge: Parse.ACL): Parse.ACL {
    // Cloner l'ACL de base pour ne pas modifier l'original
    const mergedAcl = new Parse.ACL(aclBase.toJSON());

    // Obtenir les permissions de l'ACL à fusionner
    const aclToMergePermissions = aclToMerge.toJSON();

    // Itérer sur les permissions de l'ACL à fusionner
    for (const [key, permissions] of Object.entries(aclToMergePermissions)) {
      // Itérer sur les droits de permissions (read, write)
      for (const [permission, value] of Object.entries(permissions)) {
        if (value === true) {
          if (permission === 'read') {
            mergedAcl.setReadAccess(key, true);
          } else if (permission === 'write') {
            mergedAcl.setWriteAccess(key, true);
          }
        } else {
        }
        console.log(permissions);
        console.log(`permissions value ${value} `);
        // Si la valeur est false, vous pouvez choisir de l'ignorer ou de l'appliquer selon la logique métier
        // Par exemple, pour appliquer un 'false', décommentez les lignes suivantes :
        // else {
        //   if (permission === 'read') {
        //     mergedAcl.setReadAccess(key, false);
        //   } else if (permission === 'write') {
        //     mergedAcl.setWriteAccess(key, false);
        //   }
        // }
      }
    }

    return mergedAcl;
  }

  intersectACLs(acl1: Parse.ACL, acl2: Parse.ACL) {
    try {
      const permissions1 = acl1.permissionsById;
      const permissions2 = acl2.permissionsById;

      const newAcl = new Parse.ACL();

      // Vérifiez chaque clé dans permissions1
      for (const key1 in permissions1) {
        // Vérifiez chaque clé dans permissions2
        for (const key2 in permissions2) {
          // Si les clés correspondent, ajoutez-les au nouveau ACL
          if (key1 === key2) {
            const permissionTypes1 = permissions1[key1]; // ex: {read: true, write: true}
            const permissionTypes2 = permissions2[key2]; // ex: {read: true, write: true}

            // Vérifiez chaque type de permission dans permissionTypes1
            for (const type in permissionTypes1) {
              // S'il existe dans les deux et est true dans les deux, ajoutez-le au nouveau ACL
              if (permissionTypes1[type] && permissionTypes2[type]) {
                if (type === 'read') {
                  newAcl.setReadAccess(key1, true);
                } else if (type === 'write') {
                  //  newAcl.setReadAccess()
                  newAcl.setWriteAccess(key1, true);
                }
              }
            }
          }
        }
      }
      return newAcl;
    } catch (error) {
      //   console.error('An error occurred while intersecting ACLs: ', error);
      // Returning a new empty ACL instead of null to avoid further potential errors when using the ACL.
      return null;
    }
  }

  removeDiacritics(str: string): string {
    // Remove accents, apostrophe and replace all spaces by underscore
    return str
      .normalize('NFD')
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/ /gi, '_')
      .replace(/[^a-zA-Z0-9_.]/gi, '')
      .replace(/[_]{1,}/gi, '_')
      .replace(/^[^a-zA-Z0-9]*/gi, '') // trim start
      .replace(/[^a-zA-Z0-9]*$/gi, ''); // trim end
  }

  public generatePassword(passwordLength: number): string {
    return randomAccessCode(accessCodeType(passwordLength));
  }

  isDevMode() {
    return window.location.href.indexOf('localhost') !== -1;
  }
}

const alphaNum = 'abcdefghijkmnpqrstuvwxyz123456789ABCDEFGHJKLMNPQRSTUVWXYZ123456789';

enum AccessCodeDescriptor {
  small = 6,
  medium = 9,
  large = 12,
  huge = 16,
}

function groupLength(codeType: AccessCodeDescriptor): number {
  switch (codeType) {
    case AccessCodeDescriptor.small:
    case AccessCodeDescriptor.medium:
      return 3;
    case AccessCodeDescriptor.large:
    case AccessCodeDescriptor.huge:
      return 4;
  }
}

function randomAccessCode(codeType: AccessCodeDescriptor): string {
  let randomString = '';
  let bufferCount = 0;
  for (let i = 0; i < codeType; i++) {
    bufferCount += 1;
    const randomIndex = Math.floor(Math.random() * alphaNum.length);
    const randomCharacter = alphaNum.charAt(randomIndex);
    if (bufferCount === groupLength(codeType) + 1) {
      randomString += '-';
      bufferCount = 1;
    }
    randomString += randomCharacter;
  }
  return randomString;
}

function accessCodeType(passwordLength: number): AccessCodeDescriptor {
  if (passwordLength <= 6) {
    return AccessCodeDescriptor.small;
  } else if (passwordLength <= 9) {
    return AccessCodeDescriptor.medium;
  } else if (passwordLength <= 12) {
    return AccessCodeDescriptor.large;
  } else {
    return AccessCodeDescriptor.huge;
  }
}
