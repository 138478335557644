import { HolFlight } from 'src/app/common/models/hol-flight.model';
import { OclDecision } from '../../ocl/models/ocl-decision.model';
import { FltApplicability } from './flt-applicability';
import { HolTag } from '../../common/models/hol-tag';

export class FltDecision extends OclDecision {
  applicability: FltApplicability = new FltApplicability();
  toInstruction: boolean;
  isFromFlight?: HolFlight;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.applicability = new FltApplicability(parseObject);
    this.toInstruction = parseObject.get('toInstruction');
    this.isFromFlight = parseObject.get('isFromFlight') && new HolFlight(parseObject.get('isFromFlight'));
  }
}
