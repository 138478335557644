import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { RequestService } from '../../../common/services/request.service';
import { OclOptionsService } from '../ocl-options-service/ocl-options.service';
import { OclHistoryService } from '../ocl-history-service/ocl-history.service';
import { OclHistoryLog } from '../../models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export abstract class OclFlightStatusService {
  // tslint:disable:variable-name
  protected FlightsStatus;
  // tslint:enabled
  private _flightsStatus; // @cache

  protected constructor(
    protected optionsService: OclOptionsService,
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected historyService: OclHistoryService<OclHistoryLog>,
  ) {}

  private static _getLogMessage(flightsStatus) {
    if (!flightsStatus) {
      return;
    }
    return 'x: ' + flightsStatus.x + ' y: ' + flightsStatus.y + ' criticity: ' + flightsStatus.criticity;
  }

  private static _transformParseToObject(parseObject) {
    return {
      x: parseObject.get('x'),
      y: parseObject.get('y'),
      id: parseObject.id,
      criticity: parseObject.get('criticity'),
      createdAt: parseObject.get('createdAt'),
      needsUpdate: parseObject.get('needsUpdate'),
    };
  }

  public getHistory(forceToRefresh?: boolean): Promise<any> {
    if (this._flightsStatus !== undefined && this._flightsStatus.length && !forceToRefresh) {
      return Promise.resolve(this._flightsStatus);
    } else {
      const limitDate = moment().subtract(this.optionsService.getFlightsStatusHoursToDisplay(), 'hours');
      const query = new Parse.Query(this.FlightsStatus);
      query.ascending('createdAt');
      query.greaterThan('createdAt', limitDate.toDate());
      return this.requestService.performFindQuery(query).then(results => {
        const status = results.map(OclFlightStatusService._transformParseToObject);

        // get n-1 status
        const firstStatusQuery = new Parse.Query(this.FlightsStatus);
        firstStatusQuery.lessThanOrEqualTo('createdAt', limitDate.toDate());
        firstStatusQuery.descending('createdAt');

        return this.requestService.performFirstQuery(firstStatusQuery).then(firstStatus => {
          if (firstStatus) {
            status.unshift(OclFlightStatusService._transformParseToObject(firstStatus));
          }
          this._flightsStatus = status;
          return this._flightsStatus;
        });
      });
    }
  }

  public update(x, y, criticity): Promise<any> {
    const currentUser = Parse.User.current();

    const flightsStatus = new this.FlightsStatus();
    flightsStatus.set('x', x);
    flightsStatus.set('y', y);
    flightsStatus.set('criticity', criticity);
    flightsStatus.set('updatedBy', currentUser);
    return this.requestService.performSaveQuery(flightsStatus).then(parseData => {
      const newFlightsStatus = OclFlightStatusService._transformParseToObject(parseData);
      this._flightsStatus.push(newFlightsStatus);
      this.historyService.postLog(OclHistoryLog.create(OclFlightStatusService._getLogMessage(newFlightsStatus), 'flightsStatus')).then();
      return newFlightsStatus;
    });
  }

  public setNeedsUpdate(needsUpdate: boolean): Promise<any> {
    if (this._flightsStatus && this._flightsStatus.length) {
      const lastFlightStatus = this._flightsStatus[this._flightsStatus.length - 1];
      lastFlightStatus.needsUpdate = needsUpdate;
      const flightsStatus = new this.FlightsStatus(lastFlightStatus);
      return this.requestService.performSaveQuery(flightsStatus);
    } else {
      return Promise.resolve();
    }
  }

  public fetchNewData(): Promise<any> {
    return this.getHistory(true).then(flightStatus => {
      this.$rootScope.$broadcast('occPoolService-flight-status', flightStatus);
    });
  }
}
