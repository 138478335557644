import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { EclAnnouncement } from './ecl-annoucement';
import { EclCrisis } from './ecl-crisis';

export class EclHistoryLog extends OclHistoryLog {
  public crisis: EclCrisis;
  public eclAnnouncement: Parse.Object;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
    this.eclAnnouncement = parseObject.get('eclAnnouncement');
  }
}
