import { HolFunction } from './hol-function';
import { HolObject } from 'src/app/common/models/hol-object';
import { HolUser } from './hol-user.model';
import { HolAttachments } from './hol-attachments.model';
export class HolVacation extends HolObject {
  holFunction: HolFunction;
  user?: HolUser;
  checklists?: HolVacationChecklist[];
  startDate: Date;
  endDate?: Date;
  completed?: boolean;
  completedAt?: Date;
  closeReason?: number;
  closeReasonText?: string;
  closeDate?: Date;
  closeBy?: HolUser;
  isHolder?: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.holFunction = new HolFunction(parseObject.get('holFunction'));
    this.user = new HolUser(parseObject.get('user'));
    this.startDate = parseObject.get('startDate');
    this.endDate = parseObject.get('endDate');
    this.startDate = parseObject.get('startDate');
    this.completed = parseObject.get('completed');
    this.closeReason = parseObject.get('closeReason');
    this.closeReasonText = parseObject.get('closeReasonText');
    this.closeDate = parseObject.get('closeDate');
    this.closeBy = parseObject.get('closeBy');
    this.completedAt = parseObject.get('completedAt');
  }
}

export class HolVacationChecklist {
  objectId: string;
  vacation: HolVacation;
  checklistItems: HolVacationChecklistItem[];
  title: string;
  dueDate: string;
  completedAt?: Date;
  disabled?: boolean;
  checklistIdRef: string;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.vacation = parseObject.get('vacation');
    this.title = parseObject.get('title');
    this.dueDate = parseObject.get('dueDate');
    this.completedAt = parseObject.get('completedAt');
    this.disabled = parseObject.get('disabled');
    this.checklistIdRef = parseObject.get('checklistIdRef');
  }
}

export class HolVacationChecklistItem {
  objectId: string;
  vacationChecklist: HolVacationChecklist;
  label: string;
  status?: string;
  statusDate?: Date;
  updatedBy?: HolUser;
  attachments?: HolAttachments;
  order: number;
  createdAt: Date;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.label = parseObject.get('label');
    this.status = parseObject.get('status');
    this.updatedBy = new HolUser(parseObject.get('updatedBy'));
    this.statusDate = parseObject.get('statusDate');
    this.createdAt = parseObject.get('createdAt');
    let jsonAttachments = parseObject.get('attachments');
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(parseObject.get('attachments'));
    }
    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files
      );
    } else {
      this.attachments = new HolAttachments();
    }
    this.order = parseObject.get('order');
  }
}

export enum VACATION_CHECKLIST_ITEM_STATUS {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  DONE = 'DONE',
}

export enum VACATION_LOG_STATUS {
  DEACTIVATE_ITEM_CHECKLIST = 'DEACTIVATE_ITEM_CHECKLIST',
  CHECKED_ITEM_CHECKLIST = 'CHECKED_ITEM_CHECKLIST',
  UNCHECKED_ITEM_CHECKLIST = 'UNCHECKED_ITEM_CHECKLIST',
  UPDATE_ATTACHEMENT_ITEM_CHECKLIST = 'UPDATE_ATTACHEMENT_ITEM_CHECKLIST',
  START_VACATION_CHECKLIST = 'START_VACATION_CHECKLIST',
  END_VACATION_CHECKLIST = 'END_VACATION_CHECKLIST',
  ARCHIVE_VACATION_CHECKLIST = 'ARCHIVE_VACATION_CHECKLIST',
  DEACTIVATE_CHECKLIST = 'DEACTIVATE_CHECKLIST',
  REACTIVATE_CHECKLIST = 'REACTIVATE_CHECKLIST',
  COMPLETED_CHECKLIST = 'COMPLETED_CHECKLIST',
  UNCOMPLETED_CHECKLIST = 'UNCOMPLETED_CHECKLIST',
}
