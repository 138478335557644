import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-crew-history',
  templateUrl: './crew-history.component.html',
  styleUrls: ['./crew-history.component.sass'],
})
export class CrewHistoryComponent implements OnInit {
  constructor(@Inject('$rootScope') public $rootScope) {}

  ngOnInit() {}
}
