import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { delay, distinct, filter, map, skip } from 'rxjs/operators';

import { RolesService } from 'src/app/common/services/roles.service';
import { HolVacationChecklist, HolVacationChecklistItem } from 'src/app/common/models/hol-vacation.model';
import { AppState } from 'src/app/store';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';
import { ACL } from 'parse';
import { intersectionWith, isEqual } from 'lodash';

import { Store } from '@ngrx/store';

import { HolCrisis } from '../models/hol-crisis';
import { HolFlight } from '../models/hol-flight.model';
import { HolFunction } from '../models/hol-function';
import { HolUserFunction } from '../models/hol-user-function';
import { HolUser } from '../models/hol-user.model';
import { HolVacation } from '../models/hol-vacation.model';
import { HolBreakingNew } from '../models/hol-breaking-new.model';
import { CrisisRolesRef } from '../services/functions.service';
import { HelperService } from '../services/helper.service';
import { UserService } from '../services/user.service';
import {
  InitCommon,
  InitCrisisRolesRef,
  InitHolFunctions,
  InitHolVacations,
  InitUserFunctions,
  RemoveHolVacations,
  UpdateCurrentFlight,
  UpdateCurrentOclModule,
  UpdateCurrentStopover,
  UpdateCurrentUser,
  UpdateEclInfos,
  UpdateFlights,
  UpdateHolVacation,
  UpdateLayoutGroups,
  UpdateManyUserFunctions,
  UpdateOneFlight,
  UpdateOpsInfos,
  UpdateOpsLayoutInfos,
  UpdateStopovers,
  InitBreakingNews,
  AddBreakingNewsItem,
  UpdateBreakingNewsItem,
  RemoveBreakingNewsItem,
  InitErpCrisis,
  InitEclCrisis,
  InitLastEclCrisis,
} from './common.actions';
import { CommonStoreState, EclInfos, OpsInfos, OPSLayoutGroups, OPSLayoutInfos } from './common.model';
import { EclCrisis } from '../../ecl/models/ecl-crisis';

@Injectable({
  providedIn: 'root',
})
export class CommonStoreManager {
  protected _functionsAllUser: Observable<HolUserFunction[]>;
  private _flights: Observable<HolFlight[]>;
  private _erpCrisis: Observable<HolCrisis>;
  private _lastEclCrisisActivated: Observable<EclCrisis>;

  constructor(
    private store: Store<AppState>,
    private rolesService: RolesService,
    private helperService: HelperService,
    private userService: UserService,
  ) {
    this._commonState = this.store.select('common');
    this._currentFlightState = this.store.select('common', 'currentFlight');
    this._flights = this.store.select('common', 'flights');
    this._currentModule = this.store.select('common', 'currentOclModule');
    this._currentUser = this.store.select('common', 'currentUser');
    this._erpCrisis = this.store.select('common', 'erpCrisis');
    this._eclCrisis = this.store.select('common', 'eclCrisis');
    this._holVacations = this.store.select('common', 'holVacations');
    this._currentUserCrisisRolesRef = this.store.select('common', 'crisisRolesRef');
    this._currentUserEclCrisisRolesRef = this.store.select('common', 'eclCrisisRolesRef');
    this._filterDataStartDate = this.store.select('common', 'opsLayoutInfo', 'filterDataStartDate');
    this._layoutInfos = this.store.select('common', 'opsLayoutInfo');
    this._erpCrisisActivated = this.store.select('common', 'erpCrisis', 'inProgress');
    this._erpCrisisInPreparation = this.store.select('common', 'erpCrisis', 'isInPreparation');
    this._eclCrisisActivated = this.store.select('common', 'eclCrisis', 'inProgress');
    this._eclCrisisInPreparation = this.store.select('common', 'eclCrisis', 'isInPreparation');
    this._eclInfos = this.store.select('common', 'eclInfos');
    this._functionsAllUser = this.store.select('common', 'holUserFunctions');
    this._breakingNews = this.store.select('common', 'breakingNews');
    this._lastEclCrisisActivated = this.store.select('common', 'lastEclCrisis');
  }

  private _commonState: Observable<CommonStoreState>;

  get commonState(): Observable<CommonStoreState> {
    return this._commonState;
  }

  private _currentFlightState: Observable<HolFlight>;

  get currentFlightState(): Observable<HolFlight> {
    return this._currentFlightState;
  }

  private _currentModule: Observable<string>;

  get currentModule(): Observable<string> {
    return this._currentModule;
  }

  private _currentUser: Observable<HolUser>;

  get currentUser(): Observable<HolUser> {
    return this._currentUser;
  }

  private _eclCrisis: Observable<EclCrisis>;

  get eclCrisis(): Observable<EclCrisis> {
    return this._eclCrisis;
  }

  private _holVacations: Observable<HolVacation[]>;

  get holVacations(): Observable<HolVacation[]> {
    return combineLatest(this._holVacations, this.rolesService.$companiesRolesFilter).pipe(
      map(([vac]) => this.rolesService.filterFromCompanyRoles(vac)),
    );
  }

  private _currentUserCrisisRolesRef: Observable<CrisisRolesRef>;

  get currentUserCrisisRolesRef(): Observable<CrisisRolesRef> {
    return this._currentUserCrisisRolesRef;
  }

  private _currentUserEclCrisisRolesRef: Observable<CrisisRolesRef>;

  /**
   * @deprecated use EclFunctionStoreManager.currentUserEclCrisisRolesRef() method
   */
  get currentUserEclCrisisRolesRef(): Observable<CrisisRolesRef> {
    return this._currentUserEclCrisisRolesRef;
  }

  private _filterDataStartDate: Observable<Date>;

  get filterDataStartDate(): Observable<Date> {
    return this._filterDataStartDate;
  }

  private _layoutInfos: Observable<OPSLayoutInfos>;

  get layoutInfos(): Observable<OPSLayoutInfos> {
    return this._layoutInfos;
  }

  private _erpCrisisActivated: Observable<boolean>;

  get erpCrisisActivated(): Observable<boolean> {
    return this.wrapBooleanPipe(this._erpCrisisActivated);
  }

  private _erpCrisisInPreparation: Observable<boolean>;

  get erpCrisisInPreparation(): Observable<boolean> {
    return this.wrapBooleanPipe(this._erpCrisisInPreparation);
  }

  private _eclCrisisActivated: Observable<boolean>;

  get eclCrisisActivated(): Observable<boolean> {
    return this.wrapBooleanPipe(this._eclCrisisActivated);
  }

  private _eclCrisisInPreparation: Observable<boolean>;

  get eclCrisisInPreparation(): Observable<boolean> {
    return this.wrapBooleanPipe(this._eclCrisisInPreparation);
  }

  private _eclInfos: Observable<EclInfos>;

  get eclInfos(): Observable<EclInfos> {
    return this._eclInfos;
  }

  private _breakingNews: Observable<HolBreakingNew[]>;

  get breakingNews(): Observable<HolBreakingNew[]> {
    return this._breakingNews;
  }

  get flightList(): Observable<HolFlight[]> {
    return this._flights;
  }

  get crisis(): Observable<HolCrisis> {
    return this._erpCrisis;
  }

  get lastEclCrisis(): Observable<EclCrisis> {
    return this._lastEclCrisisActivated;
  }

  initCommon(linkedItems: { [key: string]: any }): void {
    this.store.dispatch(new InitCommon(linkedItems));
  }

  updateCurrentOclModule(moduleName: string): void {
    this.store.dispatch(new UpdateCurrentOclModule(moduleName));
  }

  updateEclInfos(eclInfos: EclInfos): void {
    this.store.dispatch(new UpdateEclInfos(eclInfos));
  }

  updateOpsLayoutInfos(opsLayoutInfos: Partial<OPSLayoutInfos>): void {
    this.store.dispatch(new UpdateOpsLayoutInfos(opsLayoutInfos));
  }

  updateOpsInfos(opsInfos: OpsInfos): void {
    this.store.dispatch(new UpdateOpsInfos(opsInfos));
  }

  updateLayoutGroups(groups: OPSLayoutGroups): void {
    this.store.dispatch(new UpdateLayoutGroups(groups));
  }

  updateFlights(flights: HolFlight[]): void {
    this.store.dispatch(new UpdateFlights(flights));
  }

  updateStopovers(stopovers: GocStopover[]): void {
    this.store.dispatch(new UpdateStopovers(stopovers));
  }

  updateOneFlight(flight: HolFlight): void {
    this.store.dispatch(new UpdateOneFlight(flight));
  }

  updateCurrentFlight(flight: HolFlight) {
    this.store.dispatch(new UpdateCurrentFlight(flight));
  }

  updateCurrentStopover(stopover: GocStopover) {
    this.store.dispatch(new UpdateCurrentStopover(stopover));
  }

  updateCurrentUser(user: HolUser) {
    this.store.dispatch(new UpdateCurrentUser(user));
  }

  initErpCrisis(erpCrisis: Partial<HolCrisis>) {
    this.store.dispatch(new InitErpCrisis(erpCrisis));
  }

  initEclCrisis(eclCrisis: Partial<EclCrisis>) {
    this.store.dispatch(new InitEclCrisis(eclCrisis));
  }

  initLastEclCrisis(eclCrisis: Partial<EclCrisis>) {
    this.store.dispatch(new InitLastEclCrisis(eclCrisis));
  }

  initCrisisRolesRef(crisisRoles: CrisisRolesRef, eclCrisisRoles: CrisisRolesRef) {
    this.store.dispatch(new InitCrisisRolesRef(crisisRoles, eclCrisisRoles));
  }

  initHolFunctions(holFunctions: HolFunction[]) {
    this.store.dispatch(new InitHolFunctions(holFunctions));
  }

  initHolVacations(holVacations: HolVacation[]) {
    this.store.dispatch(new InitHolVacations(holVacations));
  }

  updateHolVacation(
    vacationId: string,
    checklistId: string,
    checklistItemId: string,
    partial: Partial<HolVacation | HolVacationChecklist | HolVacationChecklistItem>,
  ) {
    this.store.dispatch(new UpdateHolVacation(vacationId, checklistId, checklistItemId, partial));
  }

  removeHolVacations(holVacationId: string) {
    this.store.dispatch(new RemoveHolVacations(holVacationId));
  }

  initHolUserFunctions(userFunctions: HolUserFunction[]): void {
    this.store.dispatch(new InitUserFunctions(userFunctions));
  }

  updateManyUserFunctions(userFunctions: HolUserFunction[]) {
    this.store.dispatch(new UpdateManyUserFunctions(userFunctions));
  }

  initBreakingNewsList(news: HolBreakingNew[]) {
    this.store.dispatch(new InitBreakingNews(news));
  }

  addBreakingNewsItem(breakingNews: HolBreakingNew) {
    this.store.dispatch(new AddBreakingNewsItem(breakingNews));
  }

  updateBreakingNewsItem(breakingNews: HolBreakingNew) {
    this.store.dispatch(new UpdateBreakingNewsItem(breakingNews));
  }

  removeBreakingNewsItem(breakinNews: HolBreakingNew) {
    this.store.dispatch(new RemoveBreakingNewsItem(breakinNews));
  }

  async getUsersByFunction(functionId: string, acl?: ACL) {
    let companies = [];
    if (acl) {
      companies = this.helperService.parseACL(acl);
    }

    const allUser = await this.userService.getAllUsers();
    const functionsAllUser = this.getValue(this._functionsAllUser);
    const usersIdToNotify = functionsAllUser.filter(func => func.functionId === functionId).map(el => el.userId);
    const usersToNotify = allUser.filter(user => usersIdToNotify.findIndex(userId => userId === user.userId) !== -1);

    if (acl) {
      const promises = usersToNotify.map(el => this.rolesService.getUserCompaniesByUniverse(el.objectId, 'ERP'));
      return Promise.all(promises).then(el => {
        usersToNotify.forEach((user, index) => {
          user['companies'] = el[index];
        });
        return usersToNotify.filter(user => intersectionWith(companies, user['companies'], isEqual).length > 0);
      });
    } else {
      return usersToNotify;
    }
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  private wrapBooleanPipe(observable: Observable<boolean>): Observable<boolean> {
    return observable.pipe(
      skip(1),
      distinct(),
      filter(b => b),
      delay(1000),
    );
  }
}
