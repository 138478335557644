import { HolNextInfo } from '../../common/models/hol-next-info.model';
import { HolObject } from '../../common/models/hol-object';
import { HolTag } from 'src/app/common/models/hol-tag';
import { MccCabinType } from './mcc-cabin-type.model';
import { find, orderBy } from 'lodash';

export class MccCabin extends HolObject {
  aircraft: string;
  position: string;
  infos: HolNextInfo[];
  type: string;
  tags: HolTag[];
  isArchive: boolean;

  constructor(parseObject?: Parse.Object, infos?: HolNextInfo[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.aircraft = parseObject.get('aircraft');
    this.position = parseObject.get('position');
    this.isArchive = parseObject.get('isArchive');
    this.type = new MccCabinType(parseObject.get('type')).name;
    this.infos = infos;

    this.tags = parseObject.get('tags') ? parseObject.get('tags').map(tag => new HolTag(tag)) : [];
  }

  getNextInfo() {
    if (!this.infos) {
      return null;
    }
    return find(orderBy(this.infos, 'nextInfoTime', 'asc'), i => !i.done);
  }
}
