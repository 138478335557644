<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span *ngIf="isCreate" class="title">
        {{ moduleConfig.config.translateKey + '.MODALS.' + type + '.NEW_TITLE' | translate }}
      </span>
      <span *ngIf="!isCreate" class="title">
        {{
          moduleConfig.config.translateKey + '.MODALS.' + type + '.EDIT_TITLE'
            | translate
              : {
                  title: item.contentText,
                  date: item.createdAt | time: isUTC
                }
        }}
      </span>
    </div>
    <button
      (click)="openHistory()"
      *ngIf="historyItemComponent && item.customCreatedAt && !isHistory"
      class="history-button"
      mat-raised-button
    >
      <span>{{ 'COMMON.BUTTON.HISTORY' | translate | uppercase }}</span>
    </button>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div *ngIf="hasContentText">
        <div class="hol-modal__content--title hol-modal-title">
          <!-- TITLE -->
          <ng-container *ngTemplateOutlet="titleContent"></ng-container>
        </div>
      </div>

      <mat-expansion-panel (opened)="onPanelOpened()" *ngIf="fastMode" class="expansion-option-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'COMMON.MODALS.MORE_OPTION' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="hol-modal__content">
            <div class="hol-modal__content__column">
              <!-- CONTENT -->
              <ng-container *ngTemplateOutlet="content"></ng-container>
              <ng-container *ngTemplateOutlet="attachments"></ng-container>
            </div>
            <div *ngIf="!hideSecondaryColumn" class="separator"></div>
            <div *ngIf="!hideSecondaryColumn" class="hol-modal__content__column">
              <!-- CONTENT TOP RIGHT -->
              <ng-container *ngTemplateOutlet="contentRightTop"></ng-container>

              <!-- OTHER ATTACHMENTS -->
              <div *ngIf="hasAttachments" class="hol-dialog-section">
                <app-file-and-image-upload
                  (onAttachmentUpdated)="updatedAttachment()"
                  [attachments]="item.attachments"
                  [context]="context"
                  [disabled]="isReadOnly || (!isCreate && !canUpdate)"
                  [hideNote]="true"
                  [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
                >
                </app-file-and-image-upload>
              </div>
              <!-- NOTIFS -->
              <div *ngIf="showNotifications" class="hol-dialog-section">
                <div class="modal-column">
                  <label>{{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}</label>
                  <app-notification-table
                    (saveNotifications)="saveNotifications($event)"
                    (saveNotifyFunction)="saveNotifyFunction($event)"
                    [acl]="item.acl"
                    [notifications]="notifications"
                    [notifyFunction]="notifyFunction"
                    [type]="type.toLowerCase()"
                    [unselectAll]="true"
                  ></app-notification-table>
                </div>
              </div>
              <!-- ECHEANCE -->
              <div *ngIf="nextInfo">
                <label>{{ 'DASHBOARD.MODAL.NEXT_INFO' | translate }}</label>
                <app-hol-next-info
                  (saveNextInfo)="saveNextInfo($event)"
                  [hasDoneBtn]="true"
                  [isReadOnly]="isReadOnly || (!isCreate && !canUpdate)"
                  [isUTC]="isUTC"
                  [nextInfo]="nextInfo"
                >
                </app-hol-next-info>
              </div>
              <!-- CONTENT RIGHT -->
              <ng-content select=".content-right-addon"></ng-content>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <div *ngIf="!fastMode">
        <div class="hol-modal__content">
          <div class="hol-modal__content__column">
            <!-- CONTENT -->
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <ng-container *ngTemplateOutlet="attachments"></ng-container>
          </div>
          <div *ngIf="!hideSecondaryColumn" class="separator"></div>
          <div *ngIf="!hideSecondaryColumn" class="hol-modal__content__column">
            <!-- CONTENT TOP RIGHT -->
            <ng-container *ngTemplateOutlet="contentRightTop"></ng-container>
            <!-- OTHER ATTACHMENTS -->
            <div *ngIf="hasAttachments" class="hol-dialog-section">
              <app-file-and-image-upload
                (onAttachmentUpdated)="updatedAttachment()"
                [attachments]="item.attachments"
                [context]="context"
                [disabled]="isReadOnly || (!isCreate && !canUpdate)"
                [hideNote]="true"
                [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
              >
              </app-file-and-image-upload>
            </div>
            <!-- NOTIFS -->
            <div *ngIf="showNotifications" class="hol-dialog-section">
              <div class="modal-column">
                <label>{{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}</label>
                <app-notification-table
                  (saveNotifications)="saveNotifications($event)"
                  (saveNotifyFunction)="saveNotifyFunction($event)"
                  [acl]="item.acl"
                  [notifications]="notifications"
                  [notifyFunction]="notifyFunction"
                  [type]="type.toLowerCase()"
                  [unselectAll]="true"
                ></app-notification-table>
              </div>
            </div>
            <!-- ECHEANCE -->
            <div *ngIf="nextInfo">
              <label>{{ 'DASHBOARD.MODAL.NEXT_INFO' | translate }}</label>
              <app-hol-next-info
                (saveNextInfo)="saveNextInfo($event)"
                [hasDoneBtn]="true"
                [isReadOnly]="isReadOnly || (!isCreate && !canUpdate)"
                [isUTC]="isUTC"
                [nextInfo]="nextInfo"
              >
              </app-hol-next-info>
            </div>
            <!-- CONTENT RIGHT -->
            <ng-content select=".content-right-addon"></ng-content>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <ng-container *ngTemplateOutlet="modalActions"></ng-container>
</div>

<ng-template #modalActions>
  <mat-dialog-actions>
    <div class="bottom-modal">
      <!-- ACL -->
      <ng-container *ngTemplateOutlet="aclSelector"></ng-container>
      <!-- TAGS -->
      <ng-container *ngTemplateOutlet="tagSelector"></ng-container>
    </div>
    <div class="hol-modal__actions">
      <div>
        <ng-content select=".actions-right-addon"></ng-content>
        <button (click)="archive()" *ngIf="!isReadOnly && !isCreate && canArchive" class="hol-archive-btn-secondary" mat-raised-button>
          {{ 'DASHBOARD.MODAL.ARCHIVE_BUTTON' | translate }}
        </button>
      </div>
      <div>
        <ng-content select=".actions-addon"></ng-content>
        <button
          (click)="save()"
          *ngIf="(!isReadOnly && ((!isCreate && canUpdate) || isCreate)) || (isReadOnly && canUpdateFromOtherModule); else okButton"
          [disabled]="form.invalid || (hasContentText && item.contentText?.trim().length === 0) || !canSave || disabledValidateActions"
          class="hol-validate-btn-secondary"
          mat-raised-button
        >
          {{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}
        </button>
        <ng-template #okButton>
          <button class="hol-validate-btn-secondary" mat-dialog-close mat-raised-button>
            {{ 'DASHBOARD.MODAL.OK_BUTTON' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </mat-dialog-actions>
</ng-template>
<ng-template #tagSelector>
  <div class="hol-dialog-section">
    <app-tag-selector
      [defaultTags]="item['defaultTags']"
      [readOnly]="isReadOnly"
      [selectedTags]="item['tags']"
      [showOnlyDefaultTags]="item['defaultTags'] !== undefined && item['defaultTags'].length > 0"
      class="tag-selector"
    ></app-tag-selector>
  </div>
</ng-template>
<ng-template #aclSelector>
  <app-acl-selector
    *ngIf="!isReadOnly && ((!isCreate && canUpdate) || isCreate)"
    [applicability]="applicability"
    [extendACLOnly]="extendACLOnly"
    [formGroup]="form"
    [limitTo]="limitTo && limitTo.acl"
    [moduleName]="moduleConfig.config.moduleName"
    [object]="item"
    alwaysOneSelected="true"
    class="acl-full-width"
  >
  </app-acl-selector>
</ng-template>
<ng-template #titleContent>
  <mat-form-field class="hol-modal-title-field">
    <mat-label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.TITLE_PLACEHOLDER' | translate }}</mat-label>
    <textarea
      #autosize="cdkTextareaAutosize"
      *ngIf="!canEditTitle"
      [(ngModel)]="item.contentText"
      [disabled]="isReadOnly || !isCreate"
      [maxlength]="contentTextLength"
      [ngModelOptions]="{ standalone: true }"
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="1"
      cdkTextareaAutosize
      class="hol-modal-title"
      matInput
    ></textarea>
    <textarea
      #autosize="cdkTextareaAutosize"
      *ngIf="canEditTitle"
      [(ngModel)]="item.contentText"
      [disabled]="isReadOnly || !canUpdate"
      [maxlength]="contentTextLength"
      [ngModelOptions]="{ standalone: true }"
      cdkAutosizeMaxRows="10"
      cdkAutosizeMinRows="1"
      cdkTextareaAutosize
      class="hol-modal-title"
      matInput
    ></textarea>
  </mat-form-field>
</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<ng-template #attachments>
  <ng-container *ngIf="hasAttachments">
    <div *ngIf="!isReadOnly && hasAttachmentsMandatory" class="hol-dialog-section">
      <label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.ATTACHMENTS_LABEL' | translate }}</label>
    </div>
  </ng-container>
  <div *ngIf="hasAttachments" class="hol-dialog-section">
    <app-file-and-image-upload
      (onAttachmentUpdated)="updatedAttachment()"
      [attachments]="item.attachments"
      [context]="context"
      [disabled]="isReadOnly || (!isCreate && !canUpdate)"
      [hideFile]="true"
      [hideImage]="true"
      [hideLink]="true"
      [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
    >
    </app-file-and-image-upload>
  </div>
</ng-template>
<ng-template #contentRightTop>
  <ng-content select=".content-right-top-addon"></ng-content>
</ng-template>
