import { Injectable } from '@angular/core';

import { ErpCrisis } from '../models/erp-crisis';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { ErpCrisisData } from '../models/erp-crisisData';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { ErpCrisisTask } from '../models/erp-crisisTask';
import { ErpHistoryLog } from '../models/erp-historyLog';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisLiveQueriesService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseCrisisNews = Parse.Object.extend('GDCCrisisNews');
  ParseCrisisTask = Parse.Object.extend('GDCTask');
  ParseCrisisData = Parse.Object.extend('GDCCrisisData');
  ParseCrisisAnnouncement = Parse.Object.extend('GDCAnnouncement');
  ParseCrisisDecisions = Parse.Object.extend('GDCDecisions');
  ParseCrisisHistoryLogs = Parse.Object.extend('GDCHistory');
  // tslint:enabled

  crisisSubscription: Parse.LiveQuerySubscription;
  crisisNewsSubscription: Parse.LiveQuerySubscription;
  crisisTasksSubscription: Parse.LiveQuerySubscription;
  crisisDataSubscription: Parse.LiveQuerySubscription;
  crisisAnnouncementSubscription: Parse.LiveQuerySubscription;
  crisisDecisionsSubscription: Parse.LiveQuerySubscription;
  crisisHistoryLogsSubscription: Parse.LiveQuerySubscription;

  constructor(private crisisStoreManager: CrisisStoreManager) {}

  async crisisLiveSubscription(crisisObjectId) {
    const crisisQuery = new Parse.Query(this.ParseCrisis);
    crisisQuery.equalTo('objectId', crisisObjectId);
    crisisQuery.include('ACL');
    this.crisisSubscription = await crisisQuery.subscribe();
    this.crisisLiveSubscriptionListenToUpdate();
    this.manageError(this.crisisSubscription);
  }

  async crisisNewsLiveSubscription(crisisObjectId) {
    const crisisNewsQuery = new Parse.Query(this.ParseCrisisNews);
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    crisisNewsQuery.include('ACL');
    crisisNewsQuery.descending('createdAt');
    crisisNewsQuery.equalTo('crisis', crisis);
    crisisNewsQuery.notEqualTo('isArchive', true);
    this.crisisNewsSubscription = await crisisNewsQuery.subscribe();
    this.crisisNewsLiveSubscriptionListenToUpdate();
    this.crisisNewsLiveSubscriptionListenToCreate();
    this.crisisNewsLiveSubscriptionListenToLeave();
    this.manageError(this.crisisNewsSubscription);
  }

  async crisisAnnouncementLiveSubscription(crisisObjectId) {
    const crisisAnnouncementQuery = new Parse.Query(this.ParseCrisisAnnouncement);
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    crisisAnnouncementQuery.equalTo('crisis', crisis);
    crisisAnnouncementQuery.include('ACL');
    this.crisisAnnouncementSubscription = await crisisAnnouncementQuery.subscribe();
    this.crisisAnnouncementLiveSubscriptionListenToUpdate();
    this.crisisAnnouncementLiveSubscriptionListenToCreate();
    this.manageError(this.crisisAnnouncementSubscription);
  }

  async crisisDecisionsLiveSubscription(crisisObjectId) {
    const crisisDecisionsQuery = new Parse.Query(this.ParseCrisisDecisions);
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    crisisDecisionsQuery.equalTo('crisis', crisis);
    crisisDecisionsQuery.include('ACL');
    this.crisisDecisionsSubscription = await crisisDecisionsQuery.subscribe();
    this.crisisDecisionsLiveSubscriptionListenToUpdate();
    this.crisisDecisionsLiveSubscriptionListenToCreate();
    this.manageError(this.crisisDecisionsSubscription);
  }

  async crisisTaskLiveSubscription(crisisTypeId) {
    const crisisTaskQuery = new Parse.Query(this.ParseCrisisTask);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.equalTo('crisisTypeId', crisisTypeId);
    this.crisisTasksSubscription = await crisisTaskQuery.subscribe();
    this.crisisTasksLiveSubscriptionListenToUpdate();
    this.crisisTasksLiveSubscriptionListenToCreate();
    this.crisisTasksLiveSubscriptionListenToLeave();
    this.manageError(this.crisisTasksSubscription);
  }

  async crisisDataLiveSubscription(crisisObjectId) {
    const crisisDataQuery = new Parse.Query(this.ParseCrisisData);
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    crisisDataQuery.equalTo('crisis', crisis);
    crisisDataQuery.include('ACL');
    this.crisisDataSubscription = await crisisDataQuery.subscribe();
    this.crisisDataLiveSubscriptionListenToUpdate();
    this.crisisDataLiveSubscriptionListenToCreate();
    this.crisisDataLiveSubscriptionListenToDelete();
    this.manageError(this.crisisDataSubscription);
  }

  async crisisHistoryLogsLiveSubscription(crisisObjectId) {
    const crisisHistoryLogsQuery = new Parse.Query(this.ParseCrisisHistoryLogs);
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    crisisHistoryLogsQuery.equalTo('crisis', crisis);
    crisisHistoryLogsQuery.include('ACL');
    this.crisisHistoryLogsSubscription = await crisisHistoryLogsQuery.subscribe();
    this.crisisHistoryLogsLiveSubscriptionListenToCreate();
    this.manageError(this.crisisHistoryLogsSubscription);
  }

  crisisLiveSubscriptionListenToUpdate(): void {
    this.crisisSubscription.on('update', obj => {
      const bufferCrisis: ErpCrisis = new ErpCrisis(obj);
      this.crisisStoreManager.updateCrisis(bufferCrisis);
    });
  }

  crisisNewsLiveSubscriptionListenToUpdate(): void {
    this.crisisNewsSubscription.on('update', obj => {
      const bufferCrisisNew: ErpCrisisNew = new ErpCrisisNew(obj);
      this.crisisStoreManager.updateOneCrisisNew(bufferCrisisNew);
    });
  }

  crisisAnnouncementLiveSubscriptionListenToUpdate(): void {
    this.crisisAnnouncementSubscription.on('update', obj => {
      const bufferCrisisAnnouncement: ErpCrisisAnnouncement = new ErpCrisisAnnouncement(obj);
      this.crisisStoreManager.updateOneCrisisAnnouncement(bufferCrisisAnnouncement);
    });
  }

  crisisTasksLiveSubscriptionListenToUpdate(): void {
    this.crisisTasksSubscription.on('update', obj => {
      const bufferCrisisTask: ErpCrisisTask = new ErpCrisisTask(obj);
      this.crisisStoreManager.updateOneCrisisTask(bufferCrisisTask);
    });
  }

  crisisDataLiveSubscriptionListenToUpdate(): void {
    this.crisisDataSubscription.on('update', obj => {
      const bufferCrisisData: ErpCrisisData = new ErpCrisisData(obj);
      this.crisisStoreManager.updateOneCrisisData(bufferCrisisData);
    });
  }

  crisisDecisionsLiveSubscriptionListenToUpdate(): void {
    this.crisisDecisionsSubscription.on('update', obj => {
      const bufferCrisisDecisions: ErpCrisisDecision = new ErpCrisisDecision(obj);
      this.crisisStoreManager.updateOneCrisisDecision(bufferCrisisDecisions);
    });
  }

  crisisNewsLiveSubscriptionListenToCreate(): void {
    this.crisisNewsSubscription.on('create', obj => {
      const bufferCrisisNew: ErpCrisisNew = new ErpCrisisNew(obj);
      this.crisisStoreManager.createOneCrisisNew(bufferCrisisNew);
    });
  }

  crisisAnnouncementLiveSubscriptionListenToCreate(): void {
    this.crisisAnnouncementSubscription.on('create', obj => {
      const bufferCrisisAnnouncement: ErpCrisisAnnouncement = new ErpCrisisAnnouncement(obj);
      this.crisisStoreManager.createOneCrisisAnnouncement(bufferCrisisAnnouncement);
    });
  }

  crisisTasksLiveSubscriptionListenToCreate(): void {
    this.crisisTasksSubscription.on('create', obj => {
      const bufferCrisisTask: ErpCrisisTask = new ErpCrisisTask(obj);
      this.crisisStoreManager.createOneCrisisTask(bufferCrisisTask);
    });
  }

  crisisDataLiveSubscriptionListenToCreate(): void {
    this.crisisDataSubscription.on('create', obj => {
      const bufferCrisisData: ErpCrisisData = new ErpCrisisData(obj);
      this.crisisStoreManager.createOneCrisisData(bufferCrisisData);
    });
  }

  crisisHistoryLogsLiveSubscriptionListenToCreate(): void {
    this.crisisHistoryLogsSubscription.on('create', obj => {
      const bufferCrisisHistoryLog: ErpHistoryLog = new ErpHistoryLog(obj);
      this.crisisStoreManager.createOneCrisisHistoryLog(bufferCrisisHistoryLog);
    });
  }

  crisisDecisionsLiveSubscriptionListenToCreate(): void {
    this.crisisDecisionsSubscription.on('create', obj => {
      const bufferCrisisDecision: ErpCrisisDecision = new ErpCrisisDecision(obj);
      this.crisisStoreManager.createOneCrisisDecision(bufferCrisisDecision);
    });
  }

  crisisNewsLiveSubscriptionListenToLeave(): void {
    this.crisisNewsSubscription.on('leave', obj => {
      const bufferCrisisNew: ErpCrisisNew = new ErpCrisisNew(obj);
      this.crisisStoreManager.removeOneCrisisNew(bufferCrisisNew.objectId);
    });
  }

  crisisDataLiveSubscriptionListenToDelete(): void {
    this.crisisDataSubscription.on('delete', obj => {
      const bufferCrisisData: ErpCrisisData = new ErpCrisisData(obj);
      this.crisisStoreManager.deleteOneCrisisData(bufferCrisisData.objectId);
    });
  }

  crisisTasksLiveSubscriptionListenToLeave(): void {
    this.crisisTasksSubscription.on('leave', obj => {
      const bufferCrisisTask: ErpCrisisTask = new ErpCrisisTask(obj);
      this.crisisStoreManager.removeOneCrisisTask(bufferCrisisTask.objectId);
    });
  }

  manageError(liveQuery: Parse.LiveQuerySubscription): void {
    // Argument of type '"error"' is not assignable to parameter
    // https://parseplatform.org/Parse-SDK-JS/api/v2.0.1/Parse.LiveQuerySubscription.html
    // liveQuery.on('error', error => {
    //  console.log('error Live Query', error);
    // });
  }

  closeAllCrisisLiveQuery(): void {
    if (this.crisisSubscription) {
      this.crisisSubscription.unsubscribe();
    }
    if (this.crisisNewsSubscription) {
      this.crisisNewsSubscription.unsubscribe();
    }
    if (this.crisisTasksSubscription) {
      this.crisisTasksSubscription.unsubscribe();
    }
    if (this.crisisAnnouncementSubscription) {
      this.crisisAnnouncementSubscription.unsubscribe();
    }
    if (this.crisisHistoryLogsSubscription) {
      this.crisisHistoryLogsSubscription.unsubscribe();
    }
    if (this.crisisDataSubscription) {
      this.crisisDataSubscription.unsubscribe();
    }
    if (this.crisisDecisionsSubscription) {
      this.crisisDecisionsSubscription.unsubscribe();
    }
  }
}
