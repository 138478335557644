(function () {
  var env = require('../../../environments/environment').environment;

  ('use strict');

  angular
    .module('gestiondecriseApp')

    /**
     * @ngdoc ChatService
     * @name ChatService
     * @description
     * Service in the gestiondecriseApp.
     */

    .service('ChatService', function ($q, $http, OptionsService, UserService, $rootScope) {
      var socket;
      var that = this;
      var reconnectTimeoutSeconds = 5;
      var reconnectTimeout;
      $rootScope.channelsUnreadCount = {};
      $rootScope.chatConnected = false;

      function onSocketOpen() {
        $rootScope.$applyAsync(function () {
          console.log('Connected to slack');
          reconnectTimeoutSeconds = 5;
          $rootScope.chatConnected = true;
          $rootScope.$emit('chat:connected');
        });
      }

      function onSocketMessage(event) {
        var data = JSON.parse(event.data);
        if (isRealMessage(data)) {
          $rootScope.$applyAsync(function () {
            if ($rootScope.channelsUnreadCount[data.channel] !== undefined) {
              $rootScope.channelsUnreadCount[data.channel]++;
            } else {
              $rootScope.channelsUnreadCount[data.channel] = 1;
            }
            $rootScope.$emit('chat:message', {
              ts: new Date(parseFloat(data.ts) * 1000),
              username: data.username,
              text: data.text,
              channel: data.channel
            });
          });
        }
      }

      function onSocketClose() {
        $rootScope.$applyAsync(function () {
          $rootScope.chatConnected = false;
          if (reconnectTimeoutSeconds < 1000) {
            console.warn('Socket closed, trying to reconnect in ' + reconnectTimeoutSeconds + 's');
            reconnectTimeout = setTimeout(that.connect, reconnectTimeoutSeconds * 1000);
            reconnectTimeoutSeconds *= 2;
          } else {
            reconnectTimeoutSeconds = 5;
          }
        });
      }

      this.connect = function () {
        if (socket) {
          socket.removeEventListener('open', onSocketOpen);
          socket.removeEventListener('message', onSocketMessage);
          socket.removeEventListener('close', onSocketClose);
        }
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
        }
        var companyName = (OptionsService.getEnv() === 'sandbox' ? '_' : '') + $rootScope.companyName;
        socket = new WebSocket(env.cockpitWSBaseUrl + '?company=' + companyName);
        socket.addEventListener('open', onSocketOpen);
        socket.addEventListener('message', onSocketMessage);
        socket.addEventListener('close', onSocketClose);
      };

      this.reconnect = function () {
        reconnectTimeoutSeconds = 5;
        that.connect();
      };

      this.sendMessage = function (message, channelId) {
        return $http({
          method: 'POST',
          url: env.cockpitBaseUrl + 'sendMessageToChannel',
          data: {
            channel: channelId,
            message: message,
            username: UserService.getCurrentUser()
          }
        });
      };

      this.joinChannel = function (channelName, channelTopic) {
        return $http({
          method: 'POST',
          url: env.cockpitBaseUrl + 'joinChannel',
          data: {
            channelName: channelName,
            channelTopic: channelTopic
          }
        });
      };

      this.archiveChannel = function (channelId) {
        return $http({
          method: 'POST',
          url: env.cockpitBaseUrl + 'archiveChannel',
          data: {
            channelId: channelId
          }
        });
      };

      this.unarchiveChannel = function (channelId) {
        return $http({
          method: 'POST',
          url: env.cockpitBaseUrl + 'unarchiveChannel',
          data: {
            channelId: channelId
          }
        });
      };

      this.getChannelMessages = function (channelId) {
        var deferred = $q.defer();

        $http({
          method: 'GET',
          url: env.cockpitBaseUrl + 'getChannelMessages?channelId=' + channelId,
          data: {
            channelId: channelId
          }
        }).then(function (resp) {
          if (resp.data) {
            deferred.resolve(
              _.reverse(
                _.map(_.filter(resp.data, isRealMessage), function (msg) {
                  return {
                    ts: new Date(parseFloat(msg.ts) * 1000),
                    username: msg.username,
                    text: msg.text,
                    channel: msg.channel
                  };
                })
              )
            );
          } else {
            deferred.reject('no results');
          }
        }, deferred.reject);

        return deferred.promise;
      };

      function isRealMessage(msg) {
        return msg.type === 'message' && (!msg.subtype || msg.subtype === 'bot_message');
      }

      this.notify = function (title, msg) {
        new Audio('assets/sounds/plucky.mp3').play();
        // Voyons si le navigateur supporte les notifications
        if (!('Notification' in window)) {
          console.warn('Ce navigateur ne supporte pas les notifications desktop');
          return;
        }

        function sendNotif() {
          var notif = new Notification(title, {
            body: msg,
            icon: 'assets/images/icons/icon-72.png'
          });
          notif.onclose = function () {
            console.log('closed', this);
          };
        }

        // Voyons si l'utilisateur est OK pour recevoir des notifications
        if (Notification.permission === 'granted') {
          // Si c'est ok, créons une notification
          sendNotif();
        }

        // Sinon, nous avons besoin de la permission de l'utilisateur
        // Note : Chrome n'implémente pas la propriété statique permission
        // Donc, nous devons vérifier s'il n'y a pas 'denied' à la place de 'default'
        else if (Notification.permission !== 'denied') {
          Notification.requestPermission(function (permission) {
            // Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
            if (!('permission' in Notification)) {
              Notification.permission = permission;
            }

            // Si l'utilisateur est OK, on crée une notification
            if (permission === 'granted') {
              sendNotif();
            }
          });
        }
      };
    });
})();
