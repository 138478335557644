import { Action } from '@ngrx/store';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';

export enum OclGlobalInstructionsActionTypes {
  INIT_GLOBALINSTRUCTIONS = '[OCL GLOBALINSTRUCTIONS] Init GlobalInstructions',
  RESET_GLOBALINSTRUCTIONS = '[OCL GLOBALINSTRUCTIONS] Reset GlobalInstructions',
  UPDATE_ONE_GLOBALINSTRUCTION = '[OCL GLOBALINSTRUCTIONS] Update One GlobalInstruction',
  UPDATE_MANY_GLOBALINSTRUCTIONS = '[OCL GLOBALINSTRUCTIONS] Update Many GlobalInstructions',
  UPDATE_GLOBALINSTRUCTIONS_FROM_POOLING = '[OCL GLOBALINSTRUCTIONS] Update GlobalInstructions From Pooling',
  ADD_ONE_GLOBALINSTRUCTION = '[OCL GLOBALINSTRUCTIONS] Add One GlobalInstruction',
  ADD_MANY_GLOBALINSTRUCTION = '[OCL GLOBALINSTRUCTIONS] Add Many GlobalInstruction',
  DELETE_ONE_GLOBALINSTRUCTION = '[OCL GLOBALINSTRUCTIONS] Delete One GlobalInstruction',
}

export class InitGlobalInstructions implements Action {
  readonly type = OclGlobalInstructionsActionTypes.INIT_GLOBALINSTRUCTIONS;
  constructor(public payload: Array<OclGlobalInstruction>) {}
}

export class UpdateOneGlobalInstruction implements Action {
  readonly type = OclGlobalInstructionsActionTypes.UPDATE_ONE_GLOBALINSTRUCTION;
  constructor(public payload: OclGlobalInstruction) {}
}
export class AddOneGlobalInstruction implements Action {
  readonly type = OclGlobalInstructionsActionTypes.ADD_ONE_GLOBALINSTRUCTION;
  constructor(public payload: OclGlobalInstruction) {}
}

export class AddManyGlobalInstructions implements Action {
  readonly type = OclGlobalInstructionsActionTypes.ADD_MANY_GLOBALINSTRUCTION;
  constructor(public payload: Array<OclGlobalInstruction>) {}
}

export class UpdateManyGlobalInstructions implements Action {
  readonly type = OclGlobalInstructionsActionTypes.UPDATE_MANY_GLOBALINSTRUCTIONS;
  constructor(public payload: Array<OclGlobalInstruction>) {}
}

export class UpdateGlobalInstructionsFromPooling implements Action {
  readonly type = OclGlobalInstructionsActionTypes.UPDATE_GLOBALINSTRUCTIONS_FROM_POOLING;
  constructor(public payload: Array<OclGlobalInstruction>) {}
}

export class ResetGlobalInstructions implements Action {
  readonly type = OclGlobalInstructionsActionTypes.RESET_GLOBALINSTRUCTIONS;
}

export class DeleteOneGlobalInstruction implements Action {
  readonly type = OclGlobalInstructionsActionTypes.DELETE_ONE_GLOBALINSTRUCTION;
  constructor(public globalInstructionObjectId: string) {}
}

export type GlobalInstructionsActions =
  | InitGlobalInstructions
  | ResetGlobalInstructions
  | UpdateOneGlobalInstruction
  | AddOneGlobalInstruction
  | AddManyGlobalInstructions
  | UpdateManyGlobalInstructions
  | UpdateGlobalInstructionsFromPooling
  | DeleteOneGlobalInstruction;
