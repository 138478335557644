angular.module('gestiondecriseApp').config(function ($mdDialogProvider) {
  'use strict';
  /**
   * @methodOf $mdDialog
   * @name holAlert
   * @param options Additional options for the modal
   * @description Creates a $mdDialog alert preset.
   * @return {object} An $mdDialog preset
   */
  $mdDialogProvider.addPreset('holAlert', {
    options: function () {
      return {
        templateUrl: 'views/common/gdc_alert.html',
        controllerAs: 'dialog',
        bindToController: true,
        clickOutsideToClose: true,
        controller: [
          '$mdDialog',
          function ($mdDialog) {
            this.close = $mdDialog.hide;
          }
        ]
      };
    }
  });

  /**
   * @methodOf $mdDialog
   * @name holConfirm
   * @param options Additional options for the modal
   * @description Create a $mdDialog confirm preset (with confirm and a cancel button).
   * @return {object} An $mdDialog preset
   */
  $mdDialogProvider.addPreset('holConfirm', {
    options: function () {
      return {
        templateUrl: 'views/common/gdc_confirm.html',
        controllerAs: 'dialog',
        bindToController: true,
        clickOutsideToClose: true,
        controller: [
          '$mdDialog',
          function ($mdDialog) {
            this.close = $mdDialog.hide;
          }
        ]
      };
    }
  });

  /**
   * @methodOf $mdDialog
   * @name holConfirm
   * @param options Additional options for the modal
   * @description Create a $mdDialog confirm preset (with confirm and a cancel button).
   * @return {object} An $mdDialog preset
   */
  $mdDialogProvider.addPreset('holMessageConfirm', {
    options: function () {
      return {
        templateUrl: 'views/common/gdc_message_confirm.html',
        controllerAs: 'dialog',
        bindToController: true,
        clickOutsideToClose: false,
        controller: [
          '$mdDialog',
          function ($mdDialog) {
            this.close = $mdDialog.hide;
          }
        ]
      };
    }
  });
});
