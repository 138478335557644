import { OclTagsStoreManager } from './../../store/tags/ocl-tags.store-manager';
import { OclTasksStoreManager } from './../../store/tasks/ocl-tasks.store-manager';
import { HolNotifyFunction } from './../../../common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclSmsService } from './../ocl-sms-service/ocl-sms.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { Injectable } from '@angular/core';
import { OclTask } from '../../models/ocl-task';
import { TasksService } from '../../../common/services/tasks.service';
import { RequestService } from '../../../common/services/request.service';
import { TagsService } from '../../../common/services/tags.service';
import { TaskTagService } from '../../../common/services/task-tag.service';
import { OclHistoryService } from '../ocl-history-service/ocl-history.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { HolTag } from '../../../common/models/hol-tag';
import { ErpCrisisTask } from '../../../erp/models/erp-crisisTask';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { OclMailService } from '../ocl-mail-service/ocl-mail.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class OclTasksService<T extends OclTask = OclTask> extends TasksService {
  protected constructor(
    protected requestService: RequestService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    protected tagService: TagsService,
    protected taskTagService: TaskTagService,
    protected moduleConfig: ModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected commonStoreManager: CommonStoreManager,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    protected notificationsService: NotificationsService,
    protected tagsStoreManager: OclTagsStoreManager,
  ) {
    super(requestService, historyService, tagService, taskTagService);
  }

  public initTasksStore(): Promise<OclTask[]> {
    const taskQuery = new Parse.Query(this.ParseTask);
    taskQuery.include('occEvent.scenario');
    taskQuery.include('eclEvent.scenario');
    taskQuery.include('gocEvent.scenario');
    taskQuery.include('opsEvent.scenario');
    const taskTagsQuery = new Parse.Query(this.ParseTaskTag);
    taskTagsQuery.include('tag');
    taskTagsQuery.matchesQuery('task', taskQuery);

    return Promise.all([
      this.requestService.performFindAllQuery(taskQuery),
      this.requestService.performFindAllQuery(taskTagsQuery),
      this.tagsStoreManager.tagsState.pipe(take(1)).toPromise(),
    ]).then(([parseTasks, parseTaskTags, allTags]) => {
      const tasks = parseTasks
        .map(pt => {
          const defaultTagCodes: string[] = pt.get('defaultTags') ? pt.get('defaultTags').split('|') : [];
          const tags = this.getTagsForTask(parseTaskTags, pt);
          const defaultTags = this.getDefaultTagsForTask(defaultTagCodes, allTags);
          return new OclTask(
            pt,
            tags.map(t => new HolTag(t.get('tag'))),
            defaultTags,
          );
        })
        .filter(t => t.aclModules.includes(this.moduleConfig.config.moduleName.toUpperCase()));
      this.tasksStoreManager.initTasks(tasks);
      return tasks;
    });
  }

  isAttachmentsMandatory(task: ErpCrisisTask) {
    return false;
  }

  canChangeAcl(task: ErpCrisisTask) {
    return false;
  }

  newTask(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]): OclTask {
    return new OclTask(parseObject, tags, defaultTags);
  }

  async sendStatusChangeNotification(
    changeStatusNotification: {
      canSendNotification: boolean;
      comment: string;
      type: string;
      functionTitle: string;
    },
    bufferTask: OclTask,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction },
  ) {
    let addressMailToSend: string[] = [];
    let phoneNumbersToSend: string[] = [];
    let userToNotify;
    if (notifs.notifications && notifs.notifications.length) {
      addressMailToSend = this.notificationsService.getAddressMailToSend(notifs.notifications);
      phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifs.notifications);
    }
    if (notifs.functionToNotify && (notifs.functionToNotify.sendByMail || notifs.functionToNotify.sendBySms)) {
      userToNotify = await this.commonStoreManager.getUsersByFunction(bufferTask.functionId, bufferTask.acl);
      if (notifs.functionToNotify.sendByMail) {
        addressMailToSend = [...addressMailToSend, ...userToNotify.map(user => user.email)];
      }
      if (notifs.functionToNotify.sendBySms) {
        phoneNumbersToSend = [...phoneNumbersToSend, ...userToNotify.map(user => user.phone).filter(el => el !== undefined)];
      }
    }

    if (addressMailToSend.length) {
      if (changeStatusNotification.type === 'DONE') {
        this.mailService.sendTaskDoneMail(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
      if (changeStatusNotification.type === 'FROZEN') {
        this.mailService.sendTaskFrozenMail(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
      if (changeStatusNotification.type === 'TODO') {
        this.mailService.sendTaskBackTodoMail(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          addressMailToSend,
        );
      }
    }

    if (phoneNumbersToSend.length) {
      if (changeStatusNotification.type === 'DONE') {
        this.smsService.sendTaskDoneSms(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
      if (changeStatusNotification.type === 'FROZEN') {
        this.smsService.sendTaskFrozenSms(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
      if (changeStatusNotification.type === 'TODO') {
        this.smsService.sendTaskBackTodoSms(
          bufferTask,
          changeStatusNotification.functionTitle,
          changeStatusNotification.comment,
          phoneNumbersToSend,
        );
      }
    }
  }

  protected async afterSave(
    task: OclTask,
    isCreate: boolean,
    notifs: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction } = {
      notifications: [],
      functionToNotify: undefined,
    },
    historyLogComment,
    parseData,
    changeStatusNotification,
  ): Promise<any> {
    this.tasksStoreManager.updateOneTask(task);

    if (changeStatusNotification && changeStatusNotification.canSendNotification) {
      this.sendStatusChangeNotification(changeStatusNotification, task, notifs);
    }

    this.historyService
      .postLog(
        OclHistoryLog.create(
          task.taskTitle,
          'holTask',
          historyLogComment ? historyLogComment : 'UPDATE',
          task.attachments,
          task,
          task.acl,
          new this.ParseTask({ id: task.objectId }),
        ),
      )
      .then();
  }
}
