(function () {
  'use strict';

  angular
    .module('gestiondecriseApp')
    .controller('CrewSimilarEventsModalCtrl', function ($scope, events, eventToCreate, isSelectable, $mdDialog) {
      $scope.events = events;
      $scope.eventToCreate = eventToCreate;
      $scope.isSelectable = isSelectable;

      $scope.cancel = function () {
        $mdDialog.cancel();
      };

      $scope.validate = function () {
        $mdDialog.hide(true);
      };

      $scope.chooseEvent = function (ev) {
        if ($scope.isSelectable) {
          $mdDialog.hide(ev);
        }
      };
    });
})();
