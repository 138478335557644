import { SafeResourceUrl } from '@angular/platform-browser';

export class HolAttachments {
  note?: string;
  noteFile?: HolisFile;
  file?: HolisFile;
  files?: HolisFile[];
  image?: HolisFile;
  link?: HolisLink;

  constructor(note?: string, file?: HolisFile, image?: HolisFile, noteFile?: HolisFile, link?: HolisLink, files?: HolisFile[]) {
    this.note = note;
    this.file = file;
    this.files = files && files.length ? files : [];
    this.image = image;
    this.link = link;
    this.noteFile = noteFile;
  }

  public isEmpty?(): boolean {
    return !(this.note || this.noteFile || this.file || this.image || this.link || (this.files && this.files.length));
  }
}

export class HolisFile {
  title?: string;
  fileName: string;
  // url: string | SafeResourceUrl; TODO A DECOMMENTER SI L'ON VEUT COMME LA PAGE HTML
  url: string;
  canBeDeleted?: boolean = true;
}
export class HolisLink {
  title?: string;
  url: string;
}
