import { cloneDeep, values, merge, keyBy, isEqual, intersectionWith } from 'lodash';
import { DecisionsActions, OclDecisionsActionTypes } from './ocl-decisions.actions';
import { OclDecision } from '../../models/ocl-decision.model';

const decisionInitialState: Array<OclDecision> = [];

export function OclDecisionsReducer(state: OclDecision[] = cloneDeep(decisionInitialState), action: DecisionsActions): OclDecision[] {
  switch (action.type) {
    case OclDecisionsActionTypes.INIT_DECISIONS:
      return action.payload;
    case OclDecisionsActionTypes.ADD_MANY_DECISIONS:
      const bufferStateForAddMany = cloneDeep(state);
      action.payload.forEach(decision => {
        if (bufferStateForAddMany.findIndex(el => el.objectId === decision.objectId) === -1) {
          bufferStateForAddMany.push(decision);
        }
      });
      return bufferStateForAddMany;
    case OclDecisionsActionTypes.UPDATE_MANY_DECISIONS:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclDecisionsActionTypes.UPDATE_DECISIONS_FROM_LINKED_VALUE:
      const bufferStateForAddLinkedValue = cloneDeep(state);
      action.payload.forEach(item => {
        const bufferIndex = bufferStateForAddLinkedValue.findIndex(el => el.objectId === item.objectId);
        if (bufferIndex === -1) {
          if (!item.displayFromLinkedValues) {
            item.displayFromLinkedValues = [];
          }
          item.displayFromLinkedValues.push(action.linkedUid);
          bufferStateForAddLinkedValue.push(item);
        } else {
          // tslint:disable-next-line: max-line-length
          if (
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues &&
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.length
          ) {
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.push(action.linkedUid);
          }
        }
      });
      return bufferStateForAddLinkedValue;
    case OclDecisionsActionTypes.REMOVE_DECISIONS_FROM_LINKED_VALUE:
      const bufferStateForRemoveLinkedValue = cloneDeep(state);
      bufferStateForRemoveLinkedValue.map(el => {
        if (el.displayFromLinkedValues && el.displayFromLinkedValues.includes(action.linkedUid)) {
          el.displayFromLinkedValues.splice(el.displayFromLinkedValues.indexOf(action.linkedUid), 1);
        }
        return el;
      });
      return bufferStateForRemoveLinkedValue.filter(el => !el.displayFromLinkedValues || el.displayFromLinkedValues.length !== 0);
    case OclDecisionsActionTypes.UPDATE_DECISIONS_FROM_POOLING:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        // console.log('UPDATE NEEDED : POOL AND STORE ARE DIFFERENT');
        // console.log('state', state);
        // console.log('payload', action.payload);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclDecisionsActionTypes.ADD_ONE_DECISION:
      const bufferState = cloneDeep(state);
      bufferState.unshift(action.payload);
      return bufferState;
    case OclDecisionsActionTypes.UPDATE_ONE_DECISION:
      return cloneDeep(state).map(decision => (decision.objectId === action.payload.objectId ? action.payload : decision));
    case OclDecisionsActionTypes.RESET_DECISIONS:
      return cloneDeep(decisionInitialState);
    case OclDecisionsActionTypes.DELETE_ONE_DECISION:
      if (action.decisionObjectId && cloneDeep(state).findIndex(decision => decision.objectId === action.decisionObjectId) !== -1) {
        return cloneDeep(state).filter(el => el.objectId !== action.decisionObjectId);
      } else {
        return cloneDeep(state);
      }
    default:
      return state;
  }
}
