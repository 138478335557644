import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisData } from '../models/erp-crisisData';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpCrisisLiveQueriesService } from '../live-queries/erp-crisis-live-queries.service';
import { ErpCrisis } from '../models/erp-crisis';
import { HolCrisisParameterValue } from '../../common/models/hol-crisis-parameter';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisDataService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseCrisisData = Parse.Object.extend('GDCCrisisData');
  ParseCrisisParameter = Parse.Object.extend('HolCrisisParameterValue');
  // tslint:enabled

  constructor(
    private erpCrisisLiveQueriesService: ErpCrisisLiveQueriesService,
    private crisisStoreManager: CrisisStoreManager,
    private requestService: RequestService,
  ) {}

  getCrisisParametersByCrisisObjectId(crisisObjectId): Promise<HolCrisisParameterValue[]> {
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    const crisisDataQuery = new Parse.Query(this.ParseCrisisData);
    crisisDataQuery.addAscending('order');
    crisisDataQuery.addDescending('updatedAt');
    crisisDataQuery.include('ACL');
    crisisDataQuery.equalTo('crisis', crisis);
    return this.requestService
      .performFindQuery(crisisDataQuery)
      .then(crisisDataFromApi => crisisDataFromApi.map(crisisDatumFromApi => this.mapParseCrisisDataToParameter(crisisDatumFromApi)));
  }

  getCrisisDataByCrisisObjectId(crisisObjectId): Promise<ErpCrisisData[]> {
    const crisis = new this.ParseCrisis();
    crisis.set('objectId', crisisObjectId);
    const crisisDataQuery = new Parse.Query(this.ParseCrisisData);
    crisisDataQuery.addAscending('order');
    crisisDataQuery.addDescending('updatedAt');
    crisisDataQuery.include('ACL');
    crisisDataQuery.equalTo('crisis', crisis);
    return this.requestService.performFindQuery(crisisDataQuery).then(crisisDataFromApi => {
      return crisisDataFromApi.map(crisisDatumFromApi => new ErpCrisisData(crisisDatumFromApi));
    });
  }

  private mapParseCrisisDataToParameter(parseObject: Parse.Object): HolCrisisParameterValue {
    const erpCrisisData = new ErpCrisisData(parseObject);
    const paramValue = new HolCrisisParameterValue(parseObject);
    paramValue.erpCrisis = new ErpCrisis(parseObject.get('crisis'));
    paramValue.crisisParameterId = parseObject.get('title');
    return paramValue;
  }

  saveCrisisData(crisisData: Partial<ErpCrisisData>, crisisObjectId?): Promise<ErpCrisisData> {
    let parseObject;
    let isCreate;
    if (crisisData.objectId) {
      parseObject = new this.ParseCrisisData({ id: crisisData.objectId });
    } else {
      const parseCrisis = new this.ParseCrisis({ id: crisisObjectId });
      parseObject = new this.ParseCrisisData();
      parseObject.set('crisis', parseCrisis);
      if (parseCrisis.getACL()) {
        parseObject.setACL(parseCrisis.getACL());
      }
      isCreate = true;
    }
    parseObject.set('order', crisisData.order);
    parseObject.set('value', crisisData.value);
    parseObject.set('title', crisisData.title);
    return this.requestService.performSaveQuery(parseObject).then(parseData => {
      const bufferCrisisData: ErpCrisisData = new ErpCrisisData(parseData);
      if (isCreate) {
        this.crisisStoreManager.createOneCrisisData(bufferCrisisData);
        return;
      } else {
        this.crisisStoreManager.updateOneCrisisData(bufferCrisisData);
      }
      return bufferCrisisData;
    });
  }

  // used for change the order in drag and drop or create data when a crisis is triggered, must be all the crisis data in the payload
  saveAllCrisisData(crisisDataArray: Partial<ErpCrisisData>[], crisisObjectId?, fromCrisisCreate = false): Promise<ErpCrisisData[]> {
    const crisis = new this.ParseCrisis({ id: crisisObjectId });
    const parseObjectArray = crisisDataArray.map(crisisData => {
      const data = new this.ParseCrisisData({
        id: crisisData.objectId,
        crisis,
        order: crisisData.order,
        value: crisisData.value,
        title: crisisData.title,
        isDefault: crisisData.isDefault,
      });
      if (crisis.getACL()) {
        data.setACL(crisis.getACL());
      }
      return data;
    });

    return this.requestService.performSaveAllQuery(parseObjectArray).then(parseData => {
      const bufferCrisisDataArray: ErpCrisisData[] = parseData.map(parseDatum => new ErpCrisisData(parseDatum));
      if (!fromCrisisCreate) {
        this.crisisStoreManager.updateManyCrisisData(bufferCrisisDataArray);
      }
      return bufferCrisisDataArray;
    });
  }

  saveAllCrisisParameters(crisisParameters: Partial<HolCrisisParameterValue>[], crisis: ErpCrisis) {
    const refCrisis = new this.ParseCrisis({ id: crisis.objectId });
    const parseObjectArray = crisisParameters.map(param => {
      const data = new this.ParseCrisisData({
        id: param.objectId,
        crisis: refCrisis,
        order: param.order,
        value: param.value,
        title: param.crisisParameterId,
        isDefault: param.isDefault,
      });
      if (refCrisis.getACL()) {
        data.setACL(refCrisis.getACL());
      }
      return data;
    });

    return this.requestService.performSaveAllQuery(parseObjectArray).then(parseData => {
      return parseData.map(parseDatum => this.mapParseCrisisDataToParameter(parseDatum));
    });
  }

  deleteCrisisData(crisisDataObjectId: string): void {
    const parseObject = new this.ParseCrisisData();
    parseObject.id = crisisDataObjectId;

    this.requestService.performDestroyQuery(
      parseObject,
      () => {
        this.crisisStoreManager.deleteOneCrisisData(crisisDataObjectId);
        console.log('crisisData deleted :', crisisDataObjectId);
      },
      error => {
        console.log(error);
      },
    );
  }
}
