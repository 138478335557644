import { Injectable } from '@angular/core';
import { OclGroupService } from '../../../ocl/services/ocl-group-service/ocl-group.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { RequestService } from '../../../common/services/request.service';

@Injectable({
  providedIn: 'root',
})
export class OccGroupService extends OclGroupService {
  // tslint:disable:variable-name
  ParseEventGroup = Parse.Object.extend('OCCEventGroup');
  ParseDecisionGroup = Parse.Object.extend('OCCDecisionGroup');
  ParseLogbookGroup = Parse.Object.extend('OCCLogBookGroup');
  ParseGlobalInstructionGroup = Parse.Object.extend('OCCGlobalInstructionGroup');
  ParseEvent = Parse.Object.extend('OCCEvent');
  ParseDecisions = Parse.Object.extend('OCCDecisions');
  ParseLogbook = Parse.Object.extend('OCCLogBook');
  ParseGlobalInstruction = Parse.Object.extend('OCCGlobalInstruction');
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: OccModuleConfigService
  ) {
    super(requestService, groupStoreManager, moduleConfig);
  }
}
