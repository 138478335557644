import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-and-image-display',
  templateUrl: './file-and-image-display.component.html',
  styleUrls: ['./file-and-image-display.component.scss'],
})
export class FileAndImageDisplayComponent {
  @Input() url = '';
  @Input() icon = '';
  @Input() count = null;
}
