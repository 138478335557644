import { Pipe, PipeTransform } from '@angular/core';
import { HolVacationChecklist } from '../../models/hol-vacation.model';

@Pipe({
  name: 'vacationChecklistSort',
})
export class VacationChecklistSortPipe implements PipeTransform {
  public transform(value: HolVacationChecklist[], args?: any): any {
    return this.sortTimes(value);
  }

  public sortTimes(array): any {
    return array.sort((a, b) => {
      if (a.dueDate === b.dueDate) {
        return 0;
      }
      if (a.dueDate.toLowerCase() === 'end') {
        return 1;
      }
      if (b.dueDate.toLowerCase() === 'end') {
        return -1;
      }
      if (a.dueDate.toLowerCase() === 'start') {
        return -1;
      }
      if (b.dueDate.toLowerCase() === 'start') {
        return 1;
      }
      if (parseInt(a.dueDate.split(':')[0], 10) - parseInt(b.dueDate.split(':')[0], 10) === 0) {
        return parseInt(a.dueDate.split(':')[1], 10) - parseInt(b.dueDate.split(':')[1], 10);
      } else {
        return parseInt(a.dueDate.split(':')[0], 10) - parseInt(b.dueDate.split(':')[0], 10);
      }
    });
  }
}
