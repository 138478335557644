import { HolScenario } from 'src/app/common/models/hol-scenario';
import { cloneDeep } from 'lodash';
import { OclScenariosActionTypes, ScenariosActions } from './ocl-scenarios.actions';

const tagInitialState: HolScenario[] = [];

export function oclScenariosReducer(scenarios: HolScenario[] = cloneDeep(tagInitialState), action: ScenariosActions): HolScenario[] {
  switch (action.type) {
    case OclScenariosActionTypes.INIT_TAGS:
      return action.scenarios;
    default:
      return scenarios;
  }
}
