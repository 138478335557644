import { Component, Input, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';

import { MatButtonToggleGroup, MatDialog } from '@angular/material';

import { HolObject } from '../../models/hol-object';
import { HelperService } from '../../services/helper.service';
import { AclFilterRole, RolesService } from '../../services/roles.service';
import { ErpCrisis } from '../../../erp/models/erp-crisis';
import { BehaviorSubject, interval } from 'rxjs';
import { ErpCrisisApiService } from '../../../erp/services/erp-crisis-api.service';
import { CrisisStoreManager } from '../../../erp/store/crisis/crisis.store-manager';
import { CurrentUserService } from '../../services/current-user.service';
import { ErpFunctionsService } from '../../../erp/services/erp-functions.service';
import { ErpUsersService } from '../../../erp/services/erp-users.service';
import { FunctionsStoreManager } from '../../../erp/store/functions/functions.store-manager';
import { cloneDeep } from 'lodash';
import { AlertModalComponent } from 'src/app/common/modals/alert-modal/alert-modal.component';
import { HolTask } from '../../models/hol-task';

@Component({
  selector: 'app-acl-filter',
  templateUrl: './acl-filter.component.html',
  styleUrls: ['./acl-filter.component.scss'],
})
export class AclFilterComponent implements OnInit, OnDestroy {
  @Input() mode: 'single' | 'multi' = 'multi';
  @Input() module: string = 'OCC';
  roles: AclFilterRole[];
  @ViewChild('group', { static: false }) btnGroup: MatButtonToggleGroup;
  RolesService = RolesService;
  availableCompaniesRoles: AclFilterRole[] = [];
  crisis: ErpCrisis;
  private previousValue: AclFilterRole[];

  private previousValueLimitTo: HolObject;

  constructor(
    @Inject('$state') public $state,
    private rolesService: RolesService,
    private erpCrisisApiService: ErpCrisisApiService,
    private crisisStoreManager: CrisisStoreManager,
    private currentUserService: CurrentUserService,
    private erpFunctionsService: ErpFunctionsService,
    private erpUsersService: ErpUsersService,
    private functionsStoreManager: FunctionsStoreManager,
    private dialog: MatDialog,
  ) {}

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
    if (this.btnGroup) {
      if (value) {
        this.previousValue = this.btnGroup.value;
        this.btnGroup.value = this.roles.filter(role => this.roleHasAnyPermission(role));
        this.onChange();
      } else if (this.previousValue) {
        this.btnGroup.value = this.previousValue;
        this.onChange();
      }
    }
  }

  private _limitTo: BehaviorSubject<HolObject> = new BehaviorSubject<HolObject>(null);

  @Input()
  get limitTo(): HolObject {
    return this._limitTo.value;
  }

  set limitTo(value: HolObject) {
    this._limitTo.next(value);
  }

  // For single mode
  private _selectedCompanyRole: AclFilterRole = null;
  @Input()
  get selectedCompanyRole(): AclFilterRole {
    return this._selectedCompanyRole;
  }

  set selectedCompanyRole(value: AclFilterRole) {
    this._selectedCompanyRole = value;
    if (value) {
      this.rolesService.setCompanyRolesFilter([value.company]);
    }
  }

  get circleClass(): string {
    const obj = this.limitTo;
    if (obj['isTraining'] !== undefined) {
      return (obj as ErpCrisis).isTraining ? 'training' : 'crisis';
    }
    return '';
  }

  async ngOnInit() {
    const rolesTemp = await this.rolesService.getUserCompanyRolesByUniverse(this.module);
    this.roles = rolesTemp.filter(role => this.roleHasAnyPermission(role));
    this._limitTo.subscribe(value => {
      if (value && value !== this.previousValueLimitTo) {
        this.previousValueLimitTo = value;
        if (this.mode === 'multi') {
          this.roles = rolesTemp.filter(role => this.roleHasAnyPermission(role) && value.companies.includes(role.company));
          this.availableCompaniesRoles = [...this.roles];
          this.rolesService.setCompanyRolesFilter(this.roles.map(r => r.company));
        } else {
          if (
            this.roles &&
            (!this.selectedCompanyRole ||
              (value && value.companies && value.companies.length && !value.companies.includes(this.selectedCompanyRole.company)))
          ) {
            this.selectedCompanyRole = this.roles.find(this.isDefaultRole.bind(this));
          }
        }
      }
    });
    if (this.mode === 'multi') {
      this.availableCompaniesRoles = this.roles.filter(role => this.roleHasAnyPermission(role));
      const availableCompanies = this.availableCompaniesRoles.map(role => role.company);
      this.rolesService.setCompanyRolesFilter(availableCompanies);
      this.hasAllAclsHasWriteRoles(this.availableCompaniesRoles, this.mode);
    } else {
      if (
        RolesService.companiesRolesFilter.length &&
        this.isDefaultRole(this.roles.find(r => RolesService.companiesRolesFilter[0] === r.company))
      ) {
        this.selectedCompanyRole = this.roles.find(r => RolesService.companiesRolesFilter[0] === r.company);
      } else {
        this.selectedCompanyRole = this.roles.find(this.isDefaultRole.bind(this));
      }
      this.hasAllAclsHasWriteRoles([this.selectedCompanyRole], this.mode);
    }
  }

  hasAllAclsHasWriteRoles(roles: AclFilterRole[], mode): void {
    if (mode === 'multi') {
      //If all the roles have write permission in userWriteRoles property user can create any logbook, event or decision on the ecl dashboard
      // const aclsWithWriteRole: boolean = roles.every(
      //   (item: AclFilterRole, i: number) => item.userWriteRoles && item.userWriteRoles[i] && item.userWriteRoles[i].write === true,
      // );
      const aclsWithWriteRole =
        cloneDeep(roles).filter(
          item => item.userWriteRoles && item.userWriteRoles.filter(it => it.write === true).length === item.userWriteRoles.length,
        ).length === roles.length;
      localStorage.setItem('useraclwrite', JSON.stringify(aclsWithWriteRole));
    } else {
      localStorage.setItem('useraclwrite', JSON.stringify(roles[0].userWriteRoles.length > 0));
    }
  }

  onChange(): void {
    if (this.mode === 'multi') {
      const companies = this.btnGroup.value && this.btnGroup.value.map(r => r.company);
      this.rolesService.setCompanyRolesFilter(companies);
    }
  }

  refreshData(selectedRole?: AclFilterRole) {
    if (this.isInLimitCompanies(selectedRole) && this.roleHasAnyPermission(selectedRole, false, true)) {
      this.currentUserService.updateLastSeenAt().then();
      this.erpCrisisApiService.getCurrentCrisis().then(crisisFromApi => {
        this.crisisStoreManager.updateCrisisFromPooling(crisisFromApi);
      });
      Promise.all([
        this.erpFunctionsService.getUserFunctionsCrisis(),
        this.erpFunctionsService.getAllUserFunctions(),
        this.erpFunctionsService.getAllFunctionsCategories(),
        this.erpUsersService.getAll(),
      ]).then(result => {
        this.functionsStoreManager.updateFunctionsFromPooling({
          functionsCrisis: result[0],
          functionsAllUser: result[1],
          functionsCategory: result[2],
          allUsers: result[3],
        });
      });
    }
  }

  // https://gist.github.com/jedfoster/7939513
  mixColors(color1, color2, weight) {
    return HelperService.mixColors(color1, color2, weight);
  }

  isSelected(role: AclFilterRole) {
    return this.btnGroup.value && this.btnGroup.value.indexOf(role) > -1;
  }

  toggleSelectAll() {
    if (this.btnGroup.value.length === this.availableCompaniesRoles.length) {
      this.btnGroup.value = [];
    } else {
      this.btnGroup.value = this.availableCompaniesRoles;
    }
    this.onChange();
  }

  roleHasAnyPermission(r: AclFilterRole, includeRead = true, includeWrite = true): boolean {
    return !!((includeRead && r.userReadRoles.length) || (includeWrite && r.userWriteRoles.length));
  }

  isInLimitCompanies(role: AclFilterRole): boolean {
    return this.limitTo && this.limitTo.companies && !!this.limitTo.companies.includes(role && role.company);
  }

  ngOnDestroy(): void {
    localStorage.removeItem('useraclwrite');
  }

  private isDefaultRole(r: AclFilterRole) {
    if (!r) return false;
    return (
      this.roleHasAnyPermission(r) &&
      (!this.limitTo || !this.limitTo.companies || !this.limitTo.companies.length || this.limitTo.companies.includes(r.company))
    );
  }
}
