import { HolObject } from './hol-object';

export class HolBreakingNew extends HolObject {
  context: string;
  news: string;
  edit?: boolean;
  showEditIcon?: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.news = parseObject.get('news');
    this.context = parseObject.get('context');
  }
}
