import { HolisFile } from './../../../common/models/hol-attachments.model';
import { FilesService } from './../../../common/services/files.service';
import { MarkdownService } from './../../../common/components/markdown-editor/markdown.service';
import { MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, AfterViewInit, Inject, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-crew-note-modal',
  templateUrl: './crew-note-modal.component.html',
  styleUrls: ['./crew-note-modal.component.scss'],
})
export class CrewNoteModalComponent implements AfterViewInit {
  public options: MdEditorOption = {
    resizable: false,
    showBorder: false,
    hideIcons: ['FullScreen', 'Image'],
    showPreviewPanel: false,
    markedjsOpt: {
      sanitize: true,
    },
  };
  public loading = false;
  public modalTitle: string;
  public note: string;
  public toOccEnabled: boolean;
  public toOCC: any;
  public htmlTemplate: string;
  public noteFile: HolisFile;
  public context: any;
  public today = moment();
  public dateFormat = 'DD/MM/YY[\r\n]HH:mm';

  @ViewChild('mdEditor', { static: false }) mdEditor: MarkdownEditorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<CrewNoteModalComponent>,
    private readonly filesService: FilesService,
    public markdownService: MarkdownService,
  ) {
    this.modalTitle = this.data.modalTitle;
    this.note = this.data.note;
    this.toOccEnabled = this.data.toOccEnabled;
    this.toOCC = {
      checkboxValue: false,
    };

    this.context = {
      module: 'CREW',
      category: 'EVENT',
      htmlTemplate: 'A',
      htmlDate: this.today.format(this.dateFormat),
    };
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.mdEditor.aceEditorContainer.nativeElement.getElementsByTagName('textarea')[0].focus(), 500);
  }

  cancel() {
    this.dialogRef.close();
  }

  validateNote() {
    if (this.toOccEnabled) {
      const nameFile = `note-${this.context.module.toLocaleLowerCase()}-${this.context.category
        .substring(0, 3)
        .toLocaleLowerCase()
        .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
      this.loading = true;
      let base64pdf;

      const htmlSource = document.getElementsByClassName('preview-panel')[0];

      // TODO: Don't use this jQuery selector!
      const $htmlContent = $(htmlSource.outerHTML);
      this.htmlTemplate = this.markdownService.createHtmlContent($htmlContent[0].outerHTML, this.context);

      setTimeout(() => {
        const blob = new Blob([this.htmlTemplate], { type: 'text/html' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          base64pdf = reader.result;
          this.sendHtlmToDatabase(base64pdf, nameFile);
        };
      }, 10);
    } else {
      this.dialogRef.close(this.note);
    }
  }

  public sendHtlmToDatabase(data: string, nameFile: string): void {
    this.filesService.uploadFile(nameFile, { base64: data }).then(
      url => {
        var noteAttachments = {
          note: this.note,
          noteFile: {
            url: url,
            fileName: nameFile,
          },
        };
        this.loading = false;
        this.dialogRef.close({ text: this.note, attachments: noteAttachments, toOCC: this.toOCC.checkboxValue });
      },
      err => {
        console.error(err);
        this.loading = false;
        this.dialogRef.close();
      },
    );
  }
}
