import { Inject, Injectable } from '@angular/core';
import { OptionsService } from 'src/app/common/services/options.service';
import { RequestService } from 'src/app/common/services/request.service';

export interface ISmsMessage {
  to: string;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class SmsSenderService {
  constructor(@Inject('CONSTANTS') private CONSTANTS, private optionsService: OptionsService, private requestService: RequestService) {}

  public sendSms(phoneNumber: string | string[], content: string, forceToSendSms: boolean, sender: string): Promise<any> {
    content = this.replaceSpecialChars(content);
    return new Promise((resolve, reject) => {
      // If we don't force to send sms
      // And if hasToSendSms flag is false,
      // ==> We don't send any sms
      if (forceToSendSms !== true && !this.optionsService.hasToSendSms()) {
        resolve(null);
      }
      const data = {
        phoneNumber: phoneNumber,
        content: content,
        sender: sender || this.CONSTANTS.COMPANY_NAME,
      };
      this.requestService.performCloudCode('sendSms', data, resolve, reject);
    });
  }

  public sendMultipleSms(messages: ISmsMessage[], forceToSendSms: boolean, sender: string): Promise<any> {
    messages = messages.map(m => {
      /* m.to = m.to.replace(/\D+/, '');
            if (m.to.startsWith('+')) {
              // Do nothing
            } else if (m.to.startsWith('0')) {
              // French phone
              m.to = `+33${m.to}`;
            } else {
              // ????
            } */
      m.text = this.replaceSpecialChars(m.text);
      return m;
    });

    return new Promise((resolve, reject) => {
      // If we don't force to send sms
      // And if hasToSendSms flag is false,
      // ==> We don't send any sms
      if (forceToSendSms !== true && !this.optionsService.hasToSendSms()) {
        resolve(null);
      }
      const data = {
        messages: messages,
        sender: sender,
      };
      this.requestService.performCloudCode('sendMultipleSms', data, resolve, reject);
    });
  }

  private replaceSpecialChars(text: string): string {
    // The translate function transforms "> < &" to "&lt; &lt; &amp;", it is logic.
    // For SMS, we can convert them to get back original character
    return text ? text.replace(/&lt;/g, '>').replace(/&gt;/g, '<').replace(/&amp;/g, '&') : '';
  }
}
