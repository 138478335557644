import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { startsWith } from 'lodash';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FilesService } from 'src/app/common/services/files.service';

import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { FltApplicabilityService } from '../../../flt/services/flt-applicability.service';
import { FltLogbookService } from '../../../flt/services/flt-logbook.service';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { GocFlight } from '../../models/goc-flight.model';
import { GocLogbook } from '../../models/goc-logbook.model';
import { GocFlightLogbookService } from '../../services/goc-flight-logbook-service/goc-flight-logbook.service';
import { GocFlightService } from '../goc-flight-service/goc-flight.service';
import { GocHistoryService } from '../goc-history-service/goc-history.service';
import { GocLogbookTagService } from '../goc-logbook-tag-service/goc-logbook-tag.service';
import { GocMailService } from '../goc-mail-service/goc-mail.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';
import { GocOptionsService } from '../goc-options-service/goc-options.service';
import { GocSmsService } from '../goc-sms-service/goc-sms.service';

@Injectable({
  providedIn: 'root',
})
export class GocLogbookService extends FltLogbookService<GocLogbook> {
  // tslint:disable:variable-name
  protected ParseLogbook = Parse.Object.extend('GOCLogBook');
  protected ParseLogbookTag = Parse.Object.extend('GOCLogBookTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseEvents = Parse.Object.extend('GOCEvents');
  protected ParseFlightLogbook = Parse.Object.extend('GOCFlightLogbook');
  // tslint:enabled

  public constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected readonly userService: UserService,
    protected readonly filesService: FilesService,
    protected optionsService: GocOptionsService,
    protected historyService: GocHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: GocLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected logBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: GocModuleConfigService,
    protected flightService: GocFlightService,
    protected flightLogbookService: GocFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected notificationsService: NotificationsService,
    protected smsService: GocSmsService,
    protected mailService: GocMailService,
    public commonStoreManager: CommonStoreManager,
  ) {
    super(
      $rootScope,
      requestService,
      filesService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      logBooksStoreManager,
      moduleConfig,
      flightService,
      flightLogbookService,
      markdownService,
      applicabilityService,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager,
    );
  }

  public setAdditionalFields(logbook: GocLogbook, parseLogbook: Parse.Object) {
    if (logbook.applicability) {
      logbook.applicability.updateParseObject(parseLogbook);
    }
    if (logbook.isFromFlight && logbook.isFromFlight.objectId !== null) {
      parseLogbook.set(
        'isFromFlight',
        new this.ParseFlight({
          id: logbook.isFromFlight.objectId,
        }),
      );
    }
    parseLogbook.set('isPrivate', logbook.isPrivate);
  }

  protected newLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): GocLogbook {
    return new GocLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  protected initFlightLogbookFromLogbook(flight: GocFlight, logbook: GocLogbook) {
    const flightLogbook = new this.ParseFlightLogbook();
    flightLogbook.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightLogbook.set('logbook', new this.ParseLogbook({ id: logbook.objectId }));
    flightLogbook.set('isPrivate', logbook.isPrivate);
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightLogbook.setACL(flight.acl);
    } else {
      flightLogbook.setACL(logbook.acl);
    }
    return flightLogbook;
  }
}
