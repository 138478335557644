<div class="hol-box crew-events-box">
  <div class="hol-box__header">
    <div>
      <span class="hol-event full icon glyphicon glyphicon-calendar"></span>
      <span class="title title-box-crew">{{ 'CREW.DASHBOARD.EVENTS.EVENTS_TITLE' | translate }}</span>
    </div>

    <div class="hol-search-box hol-box__subtitle md-inline-form">
      <input [(ngModel)]="eventFilter" placeholder="{{ 'CREW.DASHBOARD.SEARCH_EVENT' | translate }}" type="text" />
      <span class="form-control-feedback">
        <i (click)="eventFilter = ''" *ngIf="eventFilter.length" class="icon-cancel-circle"></i>
        <i class="icon-glass"></i>
      </span>
    </div>
  </div>
  <div class="hol-box__content">
    <div class="row">
      <div class="col-xs-6">
        <app-crew-events-list
          [currentFilter]="currentFilter"
          [eventFilter]="eventFilter"
          [isClosedEvents]="false"
          [isReadOnly]="isReadOnly"
        ></app-crew-events-list>
      </div>
      <div class="col-xs-6">
        <app-crew-events-list
          [currentFilter]="currentFilter"
          [eventFilter]="eventFilter"
          [isClosedEvents]="true"
          [isReadOnly]="isReadOnly"
        ></app-crew-events-list>
      </div>
    </div>
  </div>
</div>
