(function () {
  'use strict';

  /**
   * @ngdoc function
   * @name gestiondecriseApp.component:ChatController
   * @description
   * # ChatController
   */

  angular
    .module('gestiondecriseApp')
    .controller('ChatController', function (
      $rootScope,
      $timeout,
      ChatService,
      OccEventService,
      CrewEventsService,
      UserService,
      EclEventService
    ) {
      var $ctrl = this;
      this.newMessage = '';
      this.messages = [];
      this.channels = [];
      this.selectedChannel = null;
      this.loading = false;
      this.chatOpened = false;
      this.openOnStart = false;

      this.$onInit = function () {
        function affectChannels(events) {
          $ctrl.channels = events;
          $timeout(function () {
            $ctrl.openOnStart = true;
            $ctrl.loading = false;
          });
        }

        $ctrl.loading = true;
        if (this.context === 'occ') {
          OccEventService.getAll().then(function (events) {
            events = _.orderBy(events, function (event) {
              var nextInfo = _.find(_.orderBy(event.infos, 'nextInfoTime', 'asc'), { done: false });
              if (nextInfo) {
                return nextInfo.nextInfoTime;
              } else {
                return false;
              }
            });
            affectChannels(events);
          });
        } else if (this.context === 'ecl') {
          EclEventService.getAll().then(function (events) {
            events = _.orderBy(events, function (event) {
              var nextInfo = _.find(_.orderBy(event.infos, 'nextInfoTime', 'asc'), { done: false });
              if (nextInfo) {
                return nextInfo.nextInfoTime;
              } else {
                return false;
              }
            });
            affectChannels(events);
          });
        } else if (this.context === 'crew') {
          CrewEventsService.getAll().then(affectChannels);
        }
      };

      this.toggleChat = function () {
        this.chatOpened = !this.chatOpened;
        if (this.chatOpened) {
          this.selectedChannel = null;
          this.channels = [];
          this.$onInit();
        }
      };

      this.hasUnread = function () {
        for (var i = 0; i < $ctrl.channels.length; i++) {
          if ($rootScope.channelsUnreadCount[$ctrl.channels[i].channelId]) {
            return true;
          }
        }
        return false;
      };

      this.selectChannel = function (channel) {
        this.selectedChannel = channel;
      };

      var unregisterChatMessage = $rootScope.$on('chat:message', function (ev, message) {
        $rootScope.$applyAsync(function () {
          var channel = _.find($ctrl.channels, { channelId: message.channel });

          if (channel && message.username !== UserService.getCurrentUser()) {
            var channelTitle = '';
            if ($ctrl.context === 'occ') {
              channelTitle += 'OCC ' + (channel.scenario && channel.scenario.code) + channel.order + ' - ' + channel.description;
            } else if ($ctrl.context === 'crew') {
              channelTitle += 'Crew ' + channel.impact;
            }
            ChatService.notify('Nouveau message sur ' + channelTitle, message.text);
          }
        });
      });

      this.$onDestroy = function () {
        unregisterChatMessage();
      };
    });
})();
