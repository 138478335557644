import { ErpCrisisDecisionTaskToSave } from './../models/erp-crisisDecision';
import { ErpCrisis } from 'src/app/erp/models/erp-crisis';
import { ErpHistoryLog } from './../models/erp-historyLog';
import { ErpHistoryService } from './erp-history.service';
import { Injectable } from '@angular/core';
import { ErpCrisisTask } from '../models/erp-crisisTask';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { ErpCrisisType } from '../models/erp-crisisTypes';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { ErpCrisisData } from '../models/erp-crisisData';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { ErpCrisisNewsService } from './erp-crisis-news.service';
import { ErpCrisisService } from './erp-crisis.service';
import { ErpCrisisAnnouncementService } from './erp-crisis-announcement.service';
import { ErpCrisisDataService } from './erp-crisis-data.service';
import { ErpCrisisTaskService } from './erp-crisis-task.service';
import { ErpCrisisTypeService } from './erp-crisis-type.service';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { ErpCrisisDecisionService } from './erp-crisis-decision.service';
import { EclCrisis } from '../../ecl/models/ecl-crisis';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisApiService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseCrisisData = Parse.Object.extend('GDCCrisisData');
  ParseCrisisAnnouncement = Parse.Object.extend('GDCAnnouncement');
  ParseUser = Parse.Object.extend('_User');
  // tslint:enabled

  constructor(
    private erpCrisisNewsService: ErpCrisisNewsService,
    private erpCrisisService: ErpCrisisService,
    private erpCrisisDataService: ErpCrisisDataService,
    private erpCrisisTaskService: ErpCrisisTaskService,
    private erpCrisisTypeService: ErpCrisisTypeService,
    private erpCrisisAnnouncementService: ErpCrisisAnnouncementService,
    private erpCrisisDecisionService: ErpCrisisDecisionService,
    private erpHistoryService: ErpHistoryService,
  ) {}

  async getCurrentCrisis(): Promise<ErpCrisis> {
    const crisis = await this.erpCrisisService.get();
    if (!crisis) {
      throw Error('No crisis found');
    }
    const [news, tasks, type, data, announcement, decisions, historyLogs] = await Promise.all([
      this.getCrisisNewsByCrisisObjectId(crisis.objectId),
      this.getCrisisTasksByCrisisTypeId(crisis.crisisTypeId),
      this.getCrisisTypebyCrisisTypeId(crisis.crisisTypeId),
      this.getCrisisDataByCrisisObjectId(crisis.objectId),
      this.getCrisisAnnouncementByCrisisObjectId(crisis.objectId),
      this.getCrisisDecisionByCrisisObjectId(crisis.objectId),
      this.getCrisisHistoryLogsByCrisisObjectId(crisis.objectId),
    ]);
    crisis.news = news;
    crisis.tasks = tasks;
    crisis.type = type;
    crisis.data = data;
    crisis.announcements = announcement;
    crisis.decisions = decisions;
    crisis.historyLogs = historyLogs;
    return crisis;
  }

  saveCrisis(crisis: Partial<ErpCrisis>): void {
    this.erpCrisisService.saveCrisis(crisis);
  }

  getCrisisNewsByCrisisObjectId(crisisObjectId): Promise<ErpCrisisNew[]> {
    return this.erpCrisisNewsService.getCrisisNewsByCrisisObjectId(crisisObjectId);
  }

  // tslint:disable-next-line: max-line-length
  async saveCrisisNew(
    crisisNew: Partial<ErpCrisisNew>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean; eclCrisis: EclCrisis },
  ): Promise<void> {
    await this.erpCrisisNewsService.saveCrisisNew(
      crisisNew,
      crisisObjectId,
      notifications,
      historyLogComment,
      isArchiveAction,
      duplicateToOtherModule,
    );
  }

  getCrisisAnnouncementByCrisisObjectId(crisisObjectId): Promise<ErpCrisisAnnouncement[]> {
    return this.erpCrisisAnnouncementService.getCrisisAnnouncementByCrisisObjectId(crisisObjectId);
  }

  getCrisisDecisionByCrisisObjectId(crisisObjectId): Promise<ErpCrisisDecision[]> {
    return this.erpCrisisDecisionService.getCrisisDecisionByCrisisObjectId(crisisObjectId);
  }

  // tslint:disable-next-line: max-line-length
  saveCrisisAnnouncement(
    crisisAnnouncement: Partial<ErpCrisisAnnouncement>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
  ): void {
    this.erpCrisisAnnouncementService.saveCrisisAnnouncement(
      crisisAnnouncement,
      crisisObjectId,
      notifications,
      historyLogComment,
      isArchiveAction,
    );
  }

  getCrisisDataByCrisisObjectId(crisisObjectId): Promise<ErpCrisisData[]> {
    return this.erpCrisisDataService.getCrisisDataByCrisisObjectId(crisisObjectId);
  }

  saveCrisisData(crisisData: Partial<ErpCrisisData>, crisisObjectId?): Promise<ErpCrisisData> {
    return this.erpCrisisDataService.saveCrisisData(crisisData, crisisObjectId);
  }

  // used for change the order in drag and drop or create data when a crisis is triggered, must be all the crisis data in the payload
  saveAllCrisisData(crisisDataArray: Partial<ErpCrisisData>[], crisisObjectId?, fromCrisisCreate = false): void {
    this.erpCrisisDataService.saveAllCrisisData(crisisDataArray, crisisObjectId, fromCrisisCreate);
  }

  deleteCrisisData(crisisDataObjectId: string): void {
    this.erpCrisisDataService.deleteCrisisData(crisisDataObjectId);
  }

  getCrisisTypebyCrisisTypeId(crisisTypeId): Promise<ErpCrisisType> {
    return this.erpCrisisTypeService.getCrisisTypeByCrisisTypeId(crisisTypeId);
  }

  getCrisisTasksByCrisisTypeId(crisisTypeId): Promise<ErpCrisisTask[]> {
    return this.erpCrisisTaskService.getCrisisTasksByCrisisTypeId(crisisTypeId);
  }

  saveCrisisDecision(
    crisisDecision: Partial<ErpCrisisDecision>,
    notifications: HolNotification[] = [],
    crisisObjectId: string,
    historyLogComment: string,
    decisionTasksToAdd: ErpCrisisDecisionTaskToSave[] = [],
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean; eclCrisis: EclCrisis },
  ): void {
    this.erpCrisisDecisionService.saveCrisisDecision(
      crisisDecision,
      notifications,
      crisisObjectId,
      historyLogComment,
      decisionTasksToAdd,
      duplicateToOtherModule,
    );
  }

  removeCrisisTask(crisisTask: Partial<ErpCrisisTask>, crisisObjectId: string): void {
    this.erpCrisisTaskService.removeCrisisTask(crisisTask, crisisObjectId);
  }

  removeCrisisDecision(crisisDecision: Partial<ErpCrisisDecision>, crisisObjectId: string): void {
    this.erpCrisisDecisionService.removeCrisisDecision(crisisDecision, crisisObjectId);
  }

  getCrisisHistoryLogsByCrisisObjectId(crisisObjectId): Promise<ErpHistoryLog[]> {
    return this.erpHistoryService.getHistoryByCrisisObjectId(crisisObjectId);
  }

  deleteEclLogbook(crisisNew: ErpCrisisNew): void {
    this.erpCrisisNewsService.deleteEclLogbook(crisisNew);
  }

  deleteEclDecision(crisisDecision: ErpCrisisDecision): void {
    this.erpCrisisDecisionService.deleteEclDecision(crisisDecision);
  }

  unArchiveCrisisNew(crisisObjectId: string, crisisNewObjectId: string): void {
    this.erpCrisisNewsService.unArchiveCrisisNew(crisisObjectId, crisisNewObjectId);
  }

  updateCrisisAcl(crisis: ErpCrisis): Promise<ErpCrisis> {
    return this.erpCrisisService.updateCrisisAcl(crisis);
  }
}
