import { EclAssetType, EclAssetTypeParameter, EclParameterType } from '../../models/ecl-asset-type';
import { RequestService } from '../../../common/services/request.service';
import { Injectable } from '@angular/core';
import { EclAsset, EclAssetParameter } from '../../models/ecl-asset';
import { EclCrisis } from '../../models/ecl-crisis';
import { OclTagsStoreManager } from '../../../ocl/store/tags/ocl-tags.store-manager';
import { HolTag } from '../../../common/models/hol-tag';
import { EclAssetStoreManager } from '../../store/asset/asset.store-manager';
import { EclSummary } from '../../models/ecl-summary';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { take } from 'rxjs/operators';
import { EclSummaryService } from '../ecl-summary-service/ecl-summary-service';

@Injectable({
  providedIn: 'root',
})
export class EclAssetService {
  private ParseAsset = Parse.Object.extend('ECLAsset');
  private ParseAssetParameter = Parse.Object.extend('ECLAssetParameter');
  private ParseAssetTypeParameter = Parse.Object.extend('ECLAssetTypeParameter');
  private ParseCrisis = Parse.Object.extend('ECLCrisis');
  private ParseTag = Parse.Object.extend('ECLTag');
  private ParseAssetTag = Parse.Object.extend('ECLAssetTag');
  private tagMap: Map<string, HolTag> = new Map<string, HolTag>();

  constructor(
    protected requestService: RequestService,
    tagsStoreManager: OclTagsStoreManager,
    private eclAssetStoreManager: EclAssetStoreManager,
    private eclCrisisStoreManager: EclCrisisStoreManager, //private summaryService: EclSummaryService,
  ) {
    tagsStoreManager.tagsState.subscribe(tags => (this.tagMap = new Map<string, HolTag>(tags.map(t => [t.objectId, t]))));
  }

  private get currentUser(): Parse.Object {
    return Parse.User.current();
  }

  async getAll(byCrisis?: EclCrisis): Promise<EclAsset[]> {
    const assetQuery = new Parse.Query(this.ParseAsset);
    if (byCrisis) {
      const crisisQuery = new Parse.Query(this.ParseCrisis);
      crisisQuery.equalTo('objectId', byCrisis.objectId);
      assetQuery.matchesQuery('crisis', crisisQuery);
    }
    assetQuery.include('createdBy');
    assetQuery.include('crisis');

    const parseAssets = await this.requestService.performFindAllQuery(assetQuery);
    const assetIds = parseAssets.map(pa => pa.id);
    const parameters = await this.getAssetParameters(assetIds);
    const tags = await this.getTags(assetIds);
    return parseAssets.map(parseAsset => {
      const asset = new EclAsset(parseAsset);
      asset.parameters = parameters.get(asset.objectId) || [];
      asset.tags = tags.get(asset.objectId) || [];
      return asset;
    });
  }

  async getAllUpdated(lastWanted: Date): Promise<EclAsset[]> {
    const assetQuery = new Parse.Query(this.ParseAsset);
    assetQuery.include('createdBy');
    assetQuery.include('crisis');
    assetQuery.greaterThanOrEqualTo('updatedAt', lastWanted);

    //TODO voir pour optimiser

    const parseAssets = await this.requestService.performFindAllQuery(assetQuery);
    const assetIds = parseAssets.map(pa => pa.id);
    const parameters = await this.getAssetParameters(assetIds);
    const tags = await this.getTags(assetIds);
    return parseAssets.map(parseAsset => {
      const asset = new EclAsset(parseAsset);
      asset.parameters = parameters.get(asset.objectId) || [];
      asset.tags = tags.get(asset.objectId) || [];
      return asset;
    });
  }

  public fetchNewData(lastWanted: Date) {
    /*
    if (lastWanted) {
      this.getAllUpdated(lastWanted).then(assetList => {
        // STORE
        //todo update only what I want.
        this.eclAssetStoreManager.initAssetListFromPolling(assetList);
      });
    }
    */
    return this.getAll().then(assetList => {
      // STORE
      this.eclAssetStoreManager.initAssetListFromPolling(assetList);
    });
  }

  async saveAsset(asset: EclAsset): Promise<EclAsset> {
    const isCreate = !asset.objectId;
    let parseAsset = new this.ParseAsset({ id: asset.objectId });
    if (asset.acl) {
      parseAsset.setACL(asset.acl);
    }
    parseAsset.set('name', asset.name);
    parseAsset.set('lat', asset.lat);
    parseAsset.set('long', asset.long);
    parseAsset.set('latDirection', asset.latDirection);
    parseAsset.set('latDegrees', asset.latDegrees);
    parseAsset.set('latMinutes', asset.latMinutes);
    parseAsset.set('latSeconds', asset.latSeconds);
    parseAsset.set('longDirection', asset.longDirection);
    parseAsset.set('longDegrees', asset.longDegrees);
    parseAsset.set('longMinutes', asset.longMinutes);
    parseAsset.set('longSeconds', asset.longSeconds);
    parseAsset.set('assetTypeId', asset.assetTypeId);
    parseAsset.set('color', asset.color);
    if (asset.attachments) {
      parseAsset.set('attachments', JSON.stringify(asset.attachments));
    }
    if (isCreate) {
      parseAsset.set('createdBy', this.currentUser);
    }
    parseAsset.set(
      'crisis',
      new this.ParseCrisis({
        id: asset.crisis.objectId,
      }),
    );
    parseAsset = await this.requestService.performSaveQuery(parseAsset);
    const assetToReturn = new EclAsset(parseAsset);

    // If operation is an update, remove existing parameters from asset
    if (!isCreate) {
      await this.deleteAssetParameters(assetToReturn);
      await this.deleteTags(assetToReturn);
    }

    // Save parameter list
    let parseAssetParams = asset.parameters.map(assetParam => {
      const parseAssetParam = new this.ParseAssetParameter({ id: assetParam.objectId });
      parseAssetParam.set('parameterId', assetParam.parameterId);
      parseAssetParam.set('value', assetParam.value);
      parseAssetParam.set('label', assetParam.label);
      parseAssetParam.set('custom', assetParam.custom);
      parseAssetParam.set('asset', parseAsset);
      return parseAssetParam;
    });
    parseAssetParams = await this.requestService.performSaveAllQuery(parseAssetParams);
    assetToReturn.parameters = parseAssetParams.map(p => new EclAssetParameter(p));

    // save tags list and re-retrieve them
    const parseTags = await this.updateTags(asset);
    assetToReturn.tags = parseTags
      .map(parseTag => {
        const tagId = parseTag.get('tag').id;
        return this.tagMap.get(tagId);
      })
      .filter(t => !!t);

    if (isCreate) {
      // save on Summary
      this.eclCrisisStoreManager.$eclSummaries.pipe(take(1)).subscribe(async value => {
        let summary = new EclSummary();
        summary.asset = assetToReturn;
        summary.crisis = asset.crisis;
        summary.order = value.length;
        let summParse = await this.requestService.performSaveQuery(summary.parseToObject());

        const newSummary = await this.getSummaryByAsset(assetToReturn);
        this.eclCrisisStoreManager.createOneSummary(newSummary);
      });
    } else {
      const updatedSummary = await this.getSummaryByAsset(assetToReturn);
      this.eclCrisisStoreManager.upadteOneSummary(updatedSummary);
    }
    return assetToReturn;
  }

  async getDataByField(
    assetTypeWithParameters: EclAssetType,
    searchField: EclAssetTypeParameter,
    initialValue: string,
  ): Promise<Map<string, any>> {
    try {
      const targetTable = assetTypeWithParameters.dataTableName;
      const targetSearchField = searchField.dataFieldName;
      const isMaster = searchField.dataMasterField;
      const resultMap = new Map<string, any>();
      if (!targetTable || !targetSearchField || !isMaster) {
        return resultMap;
      }
      const parseQuery = new Parse.Query(Parse.Object.extend(targetTable));
      let value: any = initialValue;
      if (searchField.type === EclParameterType.NUMBER) {
        value = parseInt(initialValue);
      }
      parseQuery.equalTo(targetSearchField, value);
      const parseObject = await this.requestService.performFirstQuery(parseQuery);
      if (parseObject) {
        assetTypeWithParameters.parameters
          .filter(p => !!p.dataFieldName)
          .forEach(param => {
            const v = parseObject.get(param.dataFieldName);
            resultMap.set(param.parameterId, v);
          });
      }
      return resultMap;
    } catch (e) {
      return new Map<string, any>();
    }
  }

  async getAssetParamatersByAsset(asset: EclAsset): Promise<EclAssetParameter[]> {
    const mainQuery = new Parse.Query(this.ParseAssetParameter);

    const innerCrisisQuery = new Parse.Query(this.ParseAsset);
    innerCrisisQuery.equalTo('objectId', asset.objectId);
    mainQuery.matchesQuery('asset', innerCrisisQuery);

    const parseParameter = await this.requestService.performFindQuery(mainQuery);
    return parseParameter.map(params => new EclAssetParameter(params));
  }

  async getAssetTypeParameter(): Promise<EclAssetTypeParameter[]> {
    const mainQuery = new Parse.Query(this.ParseAssetTypeParameter);
    const parseParameter = await this.requestService.performFindQuery(mainQuery);
    return parseParameter.map(params => new EclAssetTypeParameter(params));
  }

  async getSummaryByAsset(asset: EclAsset) {
    const mainQuery = new Parse.Query(EclSummary.ParseSummary);

    const innerCrisisQuery = new Parse.Query(this.ParseAsset);
    innerCrisisQuery.equalTo('objectId', asset.objectId);
    innerCrisisQuery.includeAll();
    mainQuery.matchesQuery('asset', innerCrisisQuery);
    mainQuery.includeAll();
    const parseSummaries = await this.requestService.performFirstQuery(mainQuery);
    let summary = new EclSummary(parseSummaries);
    if (summary.asset) {
      const params = await this.getAssetParamatersByAsset(summary.asset);
      summary.asset.parameters = params;
    }
    return summary;
  }

  // endregion

  // region Asset Parameters
  public async getAssetParameters(assetIds: string[] = []): Promise<Map<string, EclAssetParameter[]>> {
    const assetParamQuery = new Parse.Query(this.ParseAssetParameter);
    if (assetIds.length) {
      const assetQuery = new Parse.Query(this.ParseAsset);
      assetQuery.containedIn('objectId', assetIds);
      assetParamQuery.matchesQuery('asset', assetQuery);
    }
    const parseParams = await this.requestService.performFindAllQuery(assetParamQuery);
    const result = new Map<string, EclAssetParameter[]>();
    parseParams.forEach(parseParam => {
      const assetParam = new EclAssetParameter(parseParam);
      const assetId = assetParam.asset.objectId;
      if (result.has(assetId)) {
        result.get(assetId).push(assetParam);
      } else {
        result.set(assetId, [assetParam]);
      }
    });
    return result;
  }

  private async deleteAssetParameters(asset: EclAsset) {
    const query = new Parse.Query(this.ParseAssetParameter);
    const parseAsset = new this.ParseAsset({ id: asset.objectId });
    query.equalTo('asset', parseAsset);
    const parseParams = await this.requestService.performFindQuery(query);
    await this.requestService.performDestroyAllQuery(parseParams);
  }

  // region Asset Tags
  private async getTags(assetIds: string[]): Promise<Map<string, HolTag[]>> {
    const assetTagQuery = new Parse.Query(this.ParseAssetTag);

    if (assetIds.length) {
      const assetQuery = new Parse.Query(this.ParseAsset);
      assetQuery.containedIn('objectId', assetIds);
      assetTagQuery.matchesQuery('asset', assetQuery);
    }
    const parseAssetTags = await this.requestService.performFindQuery(assetTagQuery);
    const result = new Map<string, HolTag[]>();
    parseAssetTags.forEach(parseAssetTag => {
      const tagId = parseAssetTag.get('tag').id;
      const tag = this.tagMap.get(tagId);
      const assetId = parseAssetTag.get('asset').id;
      if (result.has(assetId)) {
        result.get(assetId).push(tag);
      } else {
        result.set(assetId, [tag]);
      }
    });
    return result;
  }

  private async updateTags(asset: EclAsset): Promise<Parse.Object[]> {
    const parseAsset = new this.ParseAsset({ id: asset.objectId });

    if (asset.tags) {
      const currentParseTags = asset.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseAssetTag();
        joinTableRecord.set('asset', parseAsset);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return await this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private async deleteTags(asset: EclAsset): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseAssetTag);
    const parseAsset = new this.ParseAsset({ id: asset.objectId });
    query.equalTo('asset', parseAsset);

    const tags = await this.requestService.performFindQuery(query);
    return await this.requestService.performDestroyAllQuery(tags);
  }

  // endregion
}
