'use strict';

angular
  .module('gestiondecriseApp')
  .controller(
    'CrewUserModalCtrl',
    function (
      $scope,
      $q,
      $timeout,
      situation,
      user,
      event,
      CrewUsersService,
      $mdDialog,
      CrewSituationsService,
      CrewEventsService,
      CrewSituationHelpTextService,
      CrewSituationWaitingHelpTextService,
      isReadOnly,
      $translate,
      isArchivesOpened,
      CrewOptionsService
    ) {
      event = (situation && situation.event) || event;

      var emptySituation = null;

      $scope.dayTodoAcceptation = CrewOptionsService.getdayTodoAcceptation() || 2;
      $scope.isArchivesOpened = isArchivesOpened;
      $scope.isReadOnly = isReadOnly;
      $scope.searchData = {
        user: _.clone(user) || null,
        isAutoCompleteOpen: false
      };
      $scope.isLoadingUsers = false;
      $scope.noResults = false;
      $scope.notifications = {
        sendMail: null,
        sendSms: null
      };

      $scope.situations = [];
      $scope.events = [];
      $scope.newSituation = null;
      $scope.selectedSituation = null;
      $scope.helpTexts = [];
      $scope.waitingHelpTexts = [];

      $scope.displayToDo = { value: true };

      $scope.dateOptions = { minDate: moment().startOf('day').subtract(1, 'days') };

      $scope.getNextDay = function (date) {
        return date && moment(date).add(1, 'days');
      };

      $scope.collapseText = $translate.instant('CREW.MODALS.USER.COLLAPSED');

      function init() {
        if ($scope.searchData.user) {
          $scope.loading = true;
          $q.all([
            CrewSituationsService.getForUser($scope.searchData.user),
            CrewEventsService.getAllForUser($scope.searchData.user),
            CrewSituationHelpTextService.getAll(),
            CrewSituationWaitingHelpTextService.getAll()
          ])
            .then(function (datas) {
              $scope.situations = datas[0];
              $scope.events = datas[1];
              $scope.helpTexts = datas[2];
              $scope.waitingHelpTexts = datas[3];

              // Add event if not in list
              if (event && !_.find($scope.events, { objectId: event.objectId })) {
                $scope.events.unshift(event);
              }
              $scope.events.unshift({});

              emptySituation = {
                direction: null,
                text: null,
                impactDate: null,
                validityDate: null,
                status: null,
                statusText: null,
                statusDate: null
              };
              if (event) {
                emptySituation.linkedEvents = [{ selectedEvent: _.find($scope.events, { objectId: event.objectId }) }];
                emptySituation.linkedToEvent = true;
              } else {
                emptySituation.linkedEvents = [{ selectedEvent: $scope.events[0] }];
                emptySituation.linkedToEvent = false;
              }
              $scope.newSituation = _.clone(emptySituation);

              if (situation) {
                $scope.toggleSituation(situation);
              }
            })
            .finally(function () {
              $scope.loading = false;
            });
        } else {
          event = null;
          situation = null;
          $scope.events = [];
          $scope.situations = [];
          $scope.newSituation = null;
        }

        setCollapseText($scope.isArchivesOpened);
      }

      var unregisterWatchUser = $scope.$watch('searchData.user', function () {
        init();
      });

      var unregisterSituationEvent = $scope.$on('crew-situation-updated', function () {
        init();
      });

      $scope.getUsers = CrewUsersService.search;
      $scope.isActiveSituation = CrewSituationsService.isActiveSituation;
      $scope.isInactiveSituation = function (situation) {
        return !CrewSituationsService.isActiveSituation(situation);
      };

      $scope.getUserFullName = function (user) {
        if (!user) {
          return 'Unknown';
        }
        return user.firstName + ' ' + user.lastName + ' (' + user.trigram + ')';
      };

      $scope.changeUser = function () {
        $scope.searchData.user = null;
      };

      $scope.setDefaultTodoDate = function () {
        if ($scope.newSituation.status === 'WAITING') {
          if ($scope.newSituation.linkedToEvent && getDefaultTodoDate($scope.newSituation)) {
            $scope.newSituation.statusDate = moment(getDefaultTodoDate($scope.newSituation)).toDate();
          } else if ($scope.newSituation.impactDate) {
            $scope.newSituation.statusDate = moment($scope.newSituation.impactDate).toDate();
          } else {
            $scope.newSituation.statusDate = moment().toDate();
          }
        }
      };

      function getDefaultTodoDate(situation) {
        return (
          situation.linkedEvents &&
          situation.linkedEvents.length &&
          situation.linkedEvents[0].selectedEvent &&
          situation.linkedEvents[0].selectedEvent.date
        );
      }

      $scope.getEventDisplay = function (event) {
        if (event && event.objectId) {
          return moment(event.date).format('DD MMM') + ' - ' + event.impact + ' (' + event.function.code + ')';
        } else {
          return 'Sélectionner un événement existant, ou créer ci-dessous un nouvel événement';
        }
      };

      $scope.onEventSelected = function (event) {
        if (event.selectedEvent && event.selectedEvent.objectId) {
          event.impact = event.selectedEvent.impact;
          event.date = event.selectedEvent.date;
        } else {
          delete event.impact;
          delete event.date;
        }
      };

      $scope.cancel = $mdDialog.hide;

      function prepareSituationsList(situation, func) {
        var situationList = [];
        if (situation.linkedToEvent && situation.linkedEvents.length) {
          for (var i = 0; i < situation.linkedEvents.length; i++) {
            var linkedEvent = situation.linkedEvents[i];
            var s = _.cloneDeep(situation);
            if (linkedEvent.selectedEvent.objectId) {
              s.event = linkedEvent.selectedEvent;
            } else {
              s.event = {
                impact: linkedEvent.impact,
                date: linkedEvent.date,
                function: func
              };
            }
            if (s.status === 'ACCEPTED' && moment(s.event.date, 'MM-DD-YYYY').diff(moment.utc(), 'days') + 1 < $scope.dayTodoAcceptation) {
              delete s.statusDate;
              delete s.statusText;
            }

            delete s.linkedEvents;
            situationList.push(s);
          }
        } else {
          situationList.push(situation);
        }
        return situationList;
      }

      function chooseFunction() {
        var deferred = $q.defer();
        var eventsWithoutFunction = _.filter($scope.newSituation.linkedEvents, function (ev) {
          return ev.selectedEvent && !ev.selectedEvent.objectId;
        });
        if (eventsWithoutFunction.length) {
          if (!$scope.searchData.user.functions || !$scope.searchData.user.functions.length) {
            $mdDialog.show(
              $mdDialog.holAlert({
                skipHide: true,
                hasBackdrop: false,
                title: "Impossible de créer l'évènement lié à la situation",
                htmlContent: $scope.searchData.user.firstName + ' ' + $scope.searchData.user.lastName + " n'appartient à aucune fonction."
              })
            );
            deferred.reject('No function for user ' + $scope.searchData.user.firstName + ' ' + $scope.searchData.user.lastName);
          } else if ($scope.searchData.user.functions && $scope.searchData.user.functions.length === 1) {
            deferred.resolve($scope.searchData.user.functions[0]);
          } else {
            // Choose the function
            $mdDialog
              .show({
                templateUrl: 'views/crew/modals/chooseFunction.modal.html',
                controller: 'CrewChooseFunctionModalCtrl',
                hasBackdrop: false,
                skipHide: true,
                locals: {
                  user: $scope.searchData.user
                }
              })
              .then(function (func) {
                deferred.resolve(func);
              }, deferred.reject);
          }
        } else {
          deferred.resolve();
        }
        return deferred.promise;
      }

      $scope.createSituation = function () {
        if ($scope.newSituation.status !== 'WAITING' && !($scope.newSituation.status === 'ACCEPTED' && $scope.checkIfEventsInTwoDays())) {
          delete $scope.newSituation.statusText;
          delete $scope.newSituation.statusDate;
        }
        if ($scope.newSituation.linkedToEvent) {
          delete $scope.newSituation.impactDate;
          delete $scope.newSituation.validityDate;
        }
        if (!$scope.newSituation.linkedToEvent) {
          delete $scope.newSituation.linkedEvents;
        }

        chooseFunction().then(function (func) {
          var situationList = prepareSituationsList($scope.newSituation, func);

          for (var j = 0; j < situationList.length; j++) {
            var situation = situationList[j];
            CrewSituationsService.create(
              situation,
              $scope.searchData.user,
              $scope.notifications.sendMail,
              $scope.notifications.sendSms
            ).then(function (newSituation) {
              $scope.newSituation = _.clone(emptySituation);
              $scope.notifications.sendMail = null;
              $scope.notifications.sendSms = null;
              $scope.$root.$broadcast('crew-situation-updated', newSituation);
              newSituation.justCreated = true;
              $timeout(function () {
                newSituation.justCreated = false;
              }, 5000);
              if (newSituation.event) {
                // si lié à un event, on cherche la première situation liée au même event pour changer son status en neutre
                var situationToNeutralise = _.find($scope.situations, function (situation) {
                  return situation.event && situation.event.objectId === newSituation.event.objectId;
                });
                if (situationToNeutralise) {
                  situationToNeutralise.status = 'NEUTRAL';
                  CrewSituationsService.updateStatus(situationToNeutralise, true).then(function (newSituation) {
                    _.extend(_.find($scope.situations, { objectId: newSituation.objectId }), newSituation);
                    $scope.$root.$broadcast('crew-situation-updated', newSituation, true);
                    $mdDialog.hide();
                  });
                }
              }
              //$scope.situations.unshift(newSituation);
              if (newSituation.status === 'NEUTRAL') {
                $mdDialog.hide();
              }
            });
          }
        });
      };

      $scope.resetEventOrImpactDate = function () {
        if ($scope.newSituation.linkedToEvent) {
          $scope.newSituation.impactDate = null;
          $scope.newSituation.validityDate = null;
          if (event) {
            $scope.newSituation.linkedEvents = [{ selectedEvent: _.find($scope.events, { objectId: event.objectId }) }];
          } else {
            $scope.newSituation.linkedEvents = [{ selectedEvent: $scope.events[0] }];
          }
        } else {
          $scope.newSituation.linkedEvents = [null];
        }
      };

      $scope.toggleSituation = function (situation, $event) {
        if ($event && $event.type === 'keydown') {
          // reject keyboard events
          return;
        }
        if ($scope.selectedSituation && situation.objectId === $scope.selectedSituation.objectId) {
          $scope.selectedSituation = null;
        } else {
          $scope.selectedSituation = situation;
        }
      };

      $scope.checkStatus = function () {
        if (
          $scope.newSituation.direction === 'NOTE' &&
          ($scope.newSituation.status === 'ACCEPTED' || $scope.newSituation.status === 'REFUSED')
        ) {
          delete $scope.newSituation.status;
        }
      };

      $scope.setTextAndStatus = function (helpText) {
        $scope.newSituation.text = helpText.text;
        $scope.newSituation.status = helpText.status;
        $scope.checkStatus();
      };

      $scope.setWaitingText = function (waitingText) {
        $scope.newSituation.statusText = waitingText.text;
      };

      $scope.removeEvent = function (event) {
        _.remove($scope.newSituation.linkedEvents, event);
      };

      $scope.addEmptyEvent = function () {
        $scope.newSituation.linkedEvents.push({ selectedEvent: $scope.events[0] });
      };

      $scope.$on('$destroy', function () {
        unregisterWatchUser();
        unregisterSituationEvent();
      });

      $scope.checkIfEventsInTwoDays = function () {
        return $scope.newSituation && $scope.newSituation.linkedEvents
          ? $scope.newSituation.linkedEvents.findIndex(element => {
              if (element && element.selectedEvent && element.selectedEvent.date) {
                return moment(element.selectedEvent.date, 'MM-DD-YYYY').diff(moment.utc(), 'days') + 1 >= $scope.dayTodoAcceptation;
              }
              if (element && element.date) {
                return moment(element.date, 'MM-DD-YYYY').diff(moment.utc(), 'days') + 1 >= $scope.dayTodoAcceptation;
              }
            }) !== -1
          : false;
      };

      $scope.closeSituation = function (ev) {
        var holConfirm = $mdDialog.holConfirm({
          title: 'Attention',
          htmlContent: '<p>Êtes-vous sûr de vouloir archiver la situation?</p>',
          targetEvent: ev,
          skipHide: true,
          hasBackdrop: false
        });
        $mdDialog.show(holConfirm).then(function (result) {
          if (result === true) {
            if ($scope.newSituation.objectId) {
              $scope.newSituation.isClosed = true;
              CrewSituationsService.close($scope.newSituation).then(function (newSituation) {
                _.extend(_.find($scope.situations, { objectId: newSituation.objectId }), newSituation);
                $scope.$root.$broadcast('crew-situation-updated', newSituation);
              });
            }
          }
        });
      };

      $scope.changeCollapseText = function () {
        $scope.isArchivesOpened = !$scope.isArchivesOpened;
        if ($scope.collapseText === $translate.instant('CREW.MODALS.USER.COLLAPSED')) {
          $scope.collapseText = $translate.instant('CREW.MODALS.USER.OPENED');
        } else {
          $scope.collapseText = $translate.instant('CREW.MODALS.USER.COLLAPSED');
        }
      };

      function setCollapseText(isArchivesOpened) {
        if (isArchivesOpened) {
          $scope.collapseText = $translate.instant('CREW.MODALS.USER.OPENED');
        } else {
          $scope.collapseText = $translate.instant('CREW.MODALS.USER.COLLAPSED');
        }
      }
    }
  );
