import { cloneDeep, orderBy } from 'lodash';
import { CrisisActions, CrisisActionTypes } from './crisis.actions';
import { ErpCrisis } from '../../models/erp-crisis';

const crisisInitialState: Readonly<ErpCrisis> = undefined;

export function crisisReducer(state: ErpCrisis = crisisInitialState, action: CrisisActions): ErpCrisis {
  switch (action.type) {
    case CrisisActionTypes.INIT_CRISIS:
      return {
        ...cloneDeep(state),
        ...action.payload,
      };
    case CrisisActionTypes.RESET_CRISIS:
      return new ErpCrisis();
    case CrisisActionTypes.UPDATE_CRISIS:
      if (state) {
        action.payload.news = cloneDeep(state.news);
        action.payload.tasks = cloneDeep(state.tasks);
        action.payload.type = cloneDeep(state.type);
        action.payload.data = cloneDeep(state.data);
        action.payload.announcements = cloneDeep(state.announcements);
        action.payload.decisions = cloneDeep(state.decisions);
        action.payload.historyLogs = cloneDeep(state.historyLogs);
      }
      return { ...cloneDeep(state), ...action.payload };
    case CrisisActionTypes.UPDATE_CRISIS_FROM_POOLING:
      const bufferCrisisState = cloneDeep(state);
      bufferCrisisState.news = cloneDeep(action.payload.news);
      bufferCrisisState.tasks = cloneDeep(action.payload.tasks);
      bufferCrisisState.type = cloneDeep(action.payload.type);
      bufferCrisisState.data = cloneDeep(action.payload.data);
      bufferCrisisState.announcements = cloneDeep(action.payload.announcements);
      bufferCrisisState.decisions = cloneDeep(action.payload.decisions);
      bufferCrisisState.historyLogs = cloneDeep(action.payload.historyLogs);
      return { ...bufferCrisisState, ...action.payload };
    case CrisisActionTypes.UPDATE_ONE_CRISIS_NEW:
      return {
        ...cloneDeep(state),
        news: [
          ...state.news.map(el => {
            if (el.objectId === action.crisisNew.objectId) {
              el.tags = [];
              el.attachments = undefined;
              el = { ...el, ...action.crisisNew };
            }
            return el;
          }),
        ],
      };
    case CrisisActionTypes.UPDATE_ONE_CRISIS_TASK:
      return {
        ...cloneDeep(state),
        tasks: [
          ...state.tasks.map(el => {
            if (el.objectId === action.crisisTask.objectId) {
              return action.crisisTask;
            }
            return el;
          }),
        ],
      };
    case CrisisActionTypes.UPDATE_ONE_CRISIS_ANNOUNCEMENT:
      return {
        ...cloneDeep(state),
        announcements: [
          ...state.announcements.map(el => {
            if (el.objectId === action.crisisAnnouncement.objectId) {
              el = { ...el, ...action.crisisAnnouncement };
            }
            return el;
          }),
        ],
      };
    case CrisisActionTypes.UPDATE_ONE_CRISIS_DATA:
      return {
        ...cloneDeep(state),
        data: [
          ...state.data.map(el => {
            if (el.objectId === action.crisisData.objectId) {
              el = { ...el, ...action.crisisData };
            }
            return el;
          }),
        ],
      };
    case CrisisActionTypes.UPDATE_ONE_CRISIS_DECISION:
      return {
        ...cloneDeep(state),
        decisions: orderBy(
          [
            ...state.decisions.map(el => {
              if (el.objectId === action.crisisDecision.objectId) {
                el = { ...el, ...action.crisisDecision };
              }
              return el;
            }),
          ],
          ['updateAt'],
          ['desc']
        ),
      };
    case CrisisActionTypes.UPDATE_MANY_CRISIS_DATA:
      return {
        ...cloneDeep(state),
        data: orderBy(
          [
            ...state.data.map(el => {
              const bufferData = action.crisisDataArray.find(data => data.objectId === el.objectId);
              if (bufferData) {
                el = cloneDeep(bufferData);
              }
              return el;
            }),
          ],
          ['order', 'updateAt'],
          ['asc', 'desc']
        ),
      };
    case CrisisActionTypes.CREATE_MANY_CRISIS_DATA:
      return {
        ...cloneDeep(state),
        data: orderBy([...action.crisisDataArray, ...state.data], ['order', 'updateAt'], ['asc', 'desc']),
      };
    case CrisisActionTypes.CREATE_ONE_CRISIS_NEW:
      if (action.crisisNew.objectId && cloneDeep(state).news.findIndex(el => el.objectId === action.crisisNew.objectId) === -1) {
        return {
          ...cloneDeep(state),
          news: [action.crisisNew, ...state.news],
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.CREATE_ONE_CRISIS_ANNOUNCEMENT:
      // tslint:disable-next-line: max-line-length
      if (
        action.crisisAnnouncement.objectId &&
        state.announcements.findIndex(el => el.objectId === action.crisisAnnouncement.objectId) === -1
      ) {
        return {
          ...cloneDeep(state),
          announcements: [action.crisisAnnouncement, ...state.announcements],
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.CREATE_ONE_CRISIS_TASK:
      if (action.crisisTask.objectId && state.tasks.findIndex(el => el.objectId === action.crisisTask.objectId) === -1) {
        return {
          ...cloneDeep(state),
          tasks: [action.crisisTask, ...state.tasks],
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.CREATE_MANY_CRISIS_TASKS:
      return {
        ...cloneDeep(state),
        tasks: [...action.crisisDataArray, ...state.tasks],
      };
    case CrisisActionTypes.CREATE_ONE_CRISIS_DATA:
      if (action.crisisData.objectId && state.data.findIndex(el => el.objectId === action.crisisData.objectId) === -1) {
        return {
          ...cloneDeep(state),
          data: orderBy([action.crisisData, ...state.data], ['order', 'updateAt'], ['asc', 'desc']),
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.CREATE_ONE_CRISIS_DECISION:
      if (action.crisisDecision.objectId && state.decisions.findIndex(el => el.objectId === action.crisisDecision.objectId) === -1) {
        return {
          ...cloneDeep(state),
          decisions: [action.crisisDecision, ...state.decisions],
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.CREATE_ONE_CRISIS_HISTORY_LOG:
      if (
        state &&
        state.historyLogs &&
        action.crisisHistoryLog.objectId &&
        state.historyLogs.findIndex(el => el.objectId === action.crisisHistoryLog.objectId) === -1
      ) {
        return {
          ...cloneDeep(state),
          historyLogs: orderBy([action.crisisHistoryLog, ...state.historyLogs], ['createdAt'], ['desc']),
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.REMOVE_ONE_CRISIS_NEW:
      if (action.crisisNewObjectId && cloneDeep(state).news.findIndex(el => el.objectId === action.crisisNewObjectId) !== -1) {
        return {
          ...cloneDeep(state),
          news: state.news.filter(el => el.objectId !== action.crisisNewObjectId),
        };
      } else {
        return cloneDeep(state);
      }

    case CrisisActionTypes.DELETE_ONE_CRISIS_DATA:
      if (action.crisisDataObjectId && cloneDeep(state).data.findIndex(el => el.objectId === action.crisisDataObjectId) !== -1) {
        return {
          ...cloneDeep(state),
          data: state.data.filter(el => el.objectId !== action.crisisDataObjectId),
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.REMOVE_ONE_CRISIS_TASK:
      if (action.crisisTaskObjectId && cloneDeep(state).tasks.findIndex(el => el.objectId === action.crisisTaskObjectId) !== -1) {
        return {
          ...cloneDeep(state),
          tasks: state.tasks.filter(el => el.objectId !== action.crisisTaskObjectId),
        };
      } else {
        return cloneDeep(state);
      }
    case CrisisActionTypes.REMOVE_ONE_CRISIS_DECISION:
      if (
        action.crisisDecisionObjectId &&
        cloneDeep(state).decisions.findIndex(el => el.objectId === action.crisisDecisionObjectId) !== -1
      ) {
        return {
          ...cloneDeep(state),
          decisions: state.decisions.filter(el => el.objectId !== action.crisisDecisionObjectId),
        };
      } else {
        return cloneDeep(state);
      }
    default:
      return state;
  }
}
