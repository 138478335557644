<div class="new-holis-theme tag-container tag-in-modal">
  <div>
    <ng-container *ngIf="showTitle">
      <label *ngIf="!readOnly && !viewOnly" style="font-weight: normal">
        {{ 'COMMON.SELECT_TAGS' | translate }}
      </label>
    </ng-container>
  </div>
  <div *ngIf="eclTagsIsLoading" class="loading-container">
    <div class="loading-spinner"></div>
    <div class="loading-message">Chargement des tags...</div>
  </div>
  <div [ngStyle]="{ 'flex-direction': $rootScope.isPhone ? 'column' : 'row' }" class="tag-selector tag-in-modal">
    <div
      *ngIf="!defaultTags || defaultTags.length == 0"
      [ngClass]="{ 'mt-1': $rootScope.isPhone, 'no-expansion': !isExpanded }"
      class="filters-container"
      id="tagList"
    >
      <ng-container *ngFor="let tag of tags; trackBy: trackByFunction">
        <button
          (click)="toggleTag($event, tag)"
          *ngIf="tag.name && tag.name.length"
          [disabled]="readOnly || showOnlyDefaultTags"
          [ngClass]="{
            'mb-1': $rootScope.isPhone,
            'mb-0': !$rootScope.isPhone
          }"
          [ngStyle]="buttonStyle(tag)"
          aria-label="tag.name"
          class="btn"
          mat-raised-button
        >
          <span class="tag-name">{{ tag.name }}</span>
        </button>
      </ng-container>
    </div>
    <div
      *ngIf="defaultTags && defaultTags.length > 0"
      [ngClass]="{ 'mt-1': $rootScope.isPhone, 'no-expansion': !isExpanded }"
      class="filters-container"
      id="tagList"
    >
      <ng-container *ngFor="let tag of defaultTags; trackBy: trackByFunction">
        <button
          (click)="toggleTag($event, tag)"
          *ngIf="tag.name && tag.name.length"
          [disabled]="readOnly || showOnlyDefaultTags"
          [ngClass]="{
            'mb-1': $rootScope.isPhone,
            'mb-0': !$rootScope.isPhone
          }"
          [ngStyle]="buttonStyle(tag)"
          aria-label="tag.name"
          class="btn"
          mat-raised-button
        >
          <span class="tag-name">{{ tag.name }}</span>
        </button>
      </ng-container>
    </div>
    <div *ngIf="!showOnlyDefaultTags">
      <div *ngIf="!isExpanded && selectedTags.length > 0" class="selectedTags">{{ selectedTags.length }}</div>
      <button (click)="onClickExpand()" *ngIf="!isOverflowing" mat-icon-button>
        <mat-icon class="expansion-icon">{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </button>
    </div>
  </div>
</div>
