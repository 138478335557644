import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { Observable } from 'rxjs';
import { EclCrisis, EclCrisisType } from '../../models/ecl-crisis';
import {
  CloseEclCrisis,
  CreateEclCrisis,
  CreateOneEclAnnouncement,
  CreateOneEclSummary,
  DeleteOneEclSummary,
  InitEclAnnouncementList,
  InitEclAnnouncementListFromPolling,
  InitEclCrisis,
  InitEclCrisisListFromPolling,
  InitEclSummaryList,
  InitEclSummaryListFromPolling,
  InitEclUserFunctionList,
  SelectEclCurrentCrisis,
  UpdateManyEclCrisis,
  UpdateManyEclSummary,
  UpdateOneEclAnnouncement,
  UpdateOneEclCrisis,
  UpdateOneEclSummary,
} from './crisis.action';
import { EclAnnouncement } from '../../models/ecl-annoucement';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { EclSummary } from '../../models/ecl-summary';
import { EclUserFunction } from '../../models/ecl-function';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisStoreManager {
  $eclCrisisTypes: Observable<EclCrisisType[]>;
  $eclCrisisList: Observable<EclCrisis[]>;
  $eclSelectedCrisis: Observable<EclCrisis>;
  $eclAnnoucements: Observable<EclAnnouncement[]>;
  $eclSummaries: Observable<EclSummary[]>;
  $eclUserFunction: Observable<EclUserFunction[]>;

  constructor(private store: Store<AppState>, private commonStoreManager: CommonStoreManager) {
    this.$eclCrisisTypes = store.select('ecl', 'crisisState', 'crisisTypes');
    this.$eclCrisisList = store.select('ecl', 'crisisState', 'crisis');
    this.$eclSelectedCrisis = store.select('ecl', 'crisisState', 'selectedCrisis');
    this.$eclAnnoucements = store.select('ecl', 'crisisState', 'announcements');
    this.$eclSummaries = store.select('ecl', 'crisisState', 'summaries');
    this.$eclUserFunction = store.select('ecl', 'crisisState', 'userFunction');
  }

  initState(crisisList: EclCrisis[], typeList: EclCrisisType[], allCurrentUserFunction: EclUserFunction[], crisisIdSaved: string) {
    this.store.dispatch(
      new InitEclCrisis({
        crisisList: crisisList,
        crisisTypeList: typeList,
        allCurrentUserFunction: allCurrentUserFunction,
        crisisIdSaved: crisisIdSaved,
      }),
    );
  }

  createCrisis(newCrisis: EclCrisis) {
    this.store.dispatch(new CreateEclCrisis(newCrisis));
  }

  changeSelectedCrisis(crisis: EclCrisis) {
    this.store.dispatch(new SelectEclCurrentCrisis(crisis));
    this.commonStoreManager.initEclCrisis(crisis);
  }

  closeCrisis(crisis: EclCrisis) {
    this.store.dispatch(new CloseEclCrisis(crisis));
  }

  initCrisisFromDashboardPolling(crisisList: EclCrisis[]) {
    this.store.dispatch(new InitEclCrisisListFromPolling(crisisList));
  }

  createOneAnnouncement(annoucement: EclAnnouncement) {
    this.store.dispatch(new CreateOneEclAnnouncement(annoucement));
  }

  upadteOneAnnouncement(annoucement: EclAnnouncement) {
    this.store.dispatch(new UpdateOneEclAnnouncement(annoucement));
  }

  initEclAnnouncementList(annoucements: EclAnnouncement[]) {
    this.store.dispatch(new InitEclAnnouncementList(annoucements));
  }

  initEclUserFunctionList(userFunction: EclUserFunction[]) {
    this.store.dispatch(new InitEclUserFunctionList(userFunction));
  }

  initEclAnnouncementListFromDashboardPolling(annoucements: EclAnnouncement[]) {
    this.store.dispatch(new InitEclAnnouncementListFromPolling(annoucements));
  }

  createOneSummary(summary: EclSummary) {
    this.store.dispatch(new CreateOneEclSummary(summary));
  }

  deleteOneSummary(summary: EclSummary) {
    this.store.dispatch(new DeleteOneEclSummary(summary));
  }

  upadteOneSummary(summary: EclSummary) {
    this.store.dispatch(new UpdateOneEclSummary(summary));
  }

  updateManySummary(summaries: EclSummary[]) {
    this.store.dispatch(new UpdateManyEclSummary(summaries));
  }

  initEclSummaryList(summaries: EclSummary[]) {
    this.store.dispatch(new InitEclSummaryList(summaries));
  }

  initEclSummaryListFromDashboardPolling(summaries: EclSummary[]) {
    this.store.dispatch(new InitEclSummaryListFromPolling(summaries));
  }

  updateOneCrisis(openedCrisis: EclCrisis) {
    this.store.dispatch(new UpdateOneEclCrisis(openedCrisis));
  }

  updateCrisisFromDashboardPolling(changeCrisis: EclCrisis[]) {
    this.store.dispatch(new UpdateManyEclCrisis(changeCrisis));
  }
}
