'use strict';

angular
  .module('gestiondecriseApp')
  .controller(
    'CrewEventModalCtrl',
    function (
      $scope,
      moment,
      CrewUsersService,
      $mdDialog,
      CrewFunctionsService,
      CrewSituationsService,
      UserService,
      CrewEventsService,
      event,
      isReadOnly,
      $rootScope,
      ModalsService,
      $translate,
      NotificationsService
    ) {
      $scope.isReadOnly = isReadOnly;
      $scope.isCreating = !event;
      $scope.now = new Date();
      $scope.dateOptions = {
        formatYear: 'yyyy',
        startingDay: 1,
        showWeeks: false
      };
      $scope.searchData = {
        user: null,
        extendToAllowedFunctions: false
      };
      $scope.crewEvent = null;
      $scope.loading = false;
      $scope.functions = [];
      $scope.event = {
        function: null,
        nbPersons: 1,
        date: null,
        impact: null,
        users: [],
        notes: []
      };
      //Notification table data
      $scope.notifications = _.cloneDeep(NotificationsService);
      $scope.initialNotifs = _.cloneDeep(NotificationsService);

      $scope.warningUserSituation = {};
      var initEvent = null;

      function init() {
        $scope.loading = true;
        $scope.notifications = $scope.initialNotifs;
        CrewFunctionsService.getAll()
          .then(functions => {
            $scope.functions = functions;
            if (event) {
              $scope.event = _.cloneDeep(event);
              $scope.event.function = _.find(functions, { objectId: $scope.event.function.objectId });
              $scope.event.users.forEach(el => $scope.checkUserAvailability(el));
            }
            initEvent = _.cloneDeep($scope.event);
            if ($scope.event.objectId) {
              const CrewEvent = Parse.Object.extend('CrewEvent');
              new Parse.Query(CrewEvent).get($scope.event.objectId).then(crewEvent => {
                $scope.crewEvent = crewEvent;
              });
            }
          })
          .finally(() => ($scope.loading = false));
      }

      init();

      $scope.cancel = $mdDialog.hide;

      $scope.getUsers = function (term) {
        let functions = [];
        if ($scope.event.function) {
          functions.push($scope.event.function.code);
          if ($scope.searchData.extendToAllowedFunctions && $scope.event.function.allowedFunctions) {
            functions = functions.concat($scope.event.function.allowedFunctions);
          }
        }
        return CrewUsersService.search(term, functions);
      };

      $scope.getArchiveInfoName = () => {
        return $scope.crewEvent && $scope.crewEvent.get('closeBy')
          ? `${$scope.crewEvent.get('closeBy').get('firstName')} ${$scope.crewEvent.get('closeBy').get('lastName')}`
          : '';
      };

      $scope.hasArchiveInfo = () => {
        return $scope.crewEvent && $scope.crewEvent.get('closeAt') && $scope.crewEvent.get('closeBy');
      };

      $scope.getArchiveInfoDate = () => {
        return $scope.crewEvent && $scope.crewEvent.get('closeAt')
          ? moment($scope.crewEvent.get('closeAt')).format('DD/MM/YYYY HH:mm')
          : '';
      };

      $scope.getUserFullName = user => {
        return !user ? 'Unknown' : user.firstName + ' ' + user.lastName + ' (' + user.trigram + ')';
      };

      $scope.getLastSituationTooltipText = function (situation) {
        if (situation) {
          let text = situation.text;
          if (situation.status === 'WAITING') {
            text += ' (' + situation.statusText + ')';
          }
          return text;
        }
      };

      $scope.checkSameDay = function (dateA, dateB) {
        return dateA.getDate() === dateB.getDate() && dateA.getMonth() === dateB.getMonth() && dateA.getFullYear() === dateB.getFullYear();
      };

      $scope.checkUserAvailability = function (user) {
        CrewSituationsService.getForUser(user).then(situations => {
          let todaySituations = situations.filter(
            situation => situation && situation.event && $scope.checkSameDay(situation.event.date, $scope.event.date)
          );
          if (!todaySituations.length) {
            $scope.warningUserSituation[user.objectId] = 'NEUTRAL';
            return;
          }
          if ($scope.event.objectId) {
            todaySituations = todaySituations.filter(situation => situation.event.objectId !== $scope.event.objectId);
          }
          let findStatus = todaySituations.find(todaySituation => todaySituation.status === 'ACCEPTED');
          if (findStatus) {
            $scope.warningUserSituation[user.objectId] = findStatus.status;
          } else {
            findStatus = todaySituations.find(todaySituation => todaySituation.status === 'REFUSED');
            if (findStatus) {
              $scope.warningUserSituation[user.objectId] = findStatus.status;
            } else {
              findStatus = todaySituations.find(todaySituation => todaySituation.status === 'WAITING');
              if (findStatus) {
                $scope.warningUserSituation[user.objectId] = findStatus.status;
              } else {
                $scope.warningUserSituation[user.objectId] = 'NEUTRAL';
              }
            }
          }
        });
      };

      $scope.getUserInitials = UserService.getUserInitials;

      $scope.notInFunction = user =>
        $scope.event.function && (!user.functions || user.functions.indexOf($scope.event.function.code) === -1);

      $scope.addUser = $item => {
        $scope.searchData.user = null;
        if (!_.find($scope.event.users, $item)) {
          $scope.event.users.push($item);
          if ($scope.event.date) {
            $scope.checkUserAvailability($item);
          }
        } else {
          $mdDialog.show(
            $mdDialog.holAlert({
              title: 'Attention',
              textContent: "Cet utilisateur a déjà été ajouté à l'évènement",
              ok: 'OK',
              skipHide: true,
              hasBackdrop: false
            })
          );
        }
      };

      $scope.checkUserAvailabilityFromDate = () => {
        if ($scope.event.users.length) {
          $scope.event.users.forEach(user => $scope.checkUserAvailability(user));
        }
      };

      $scope.removeUser = user => _.remove($scope.event.users, user);

      $scope.isClosed = event => event.closeReason !== undefined;

      $scope.validate = () => {
        if (angular.equals($scope.event, initEvent)) {
          $mdDialog.hide();
          return;
        }
        //Added Notifications Array to Add event payload
        if ($scope.notifications && $scope.notifications.length) {
          $scope.event['notifications'] = $scope.notifications;
        }
        if ($scope.event.objectId) {
          CrewEventsService.update($scope.event).then(updatedEvent => $mdDialog.hide(updatedEvent));
        } else {
          CrewEventsService.create($scope.event, true).then(newEvent => $mdDialog.hide(newEvent));
        }
      };

      $scope.saveAndDuplicate = function () {
        if (angular.equals($scope.event, initEvent)) {
          $scope.event.function = null;
          $scope.event.nbPersons = 1;
          $scope.event.users = [];
          $scope.event.objectId = undefined;
          $scope.isCreating = true;
          return;
        }
        //Added Notifications Array to Add event payload
        if ($scope.notifications && $scope.notifications.length) {
          $scope.event['notifications'] = $scope.notifications;
        }
        if ($scope.event.objectId) {
          CrewEventsService.update($scope.event).then(updatedEvent => {
            $scope.event.function = null;
            $scope.event.nbPersons = 1;
            $scope.event.users = [];
            $scope.event.objectId = undefined;
            $scope.isCreating = true;
            $scope.event['notifications'] = $scope.initialNotifs;
            return;
          });
        } else {
          CrewEventsService.create($scope.event, true).then(() => {
            $scope.event.function = null;
            $scope.event.nbPersons = 1;
            $scope.event.users = [];
            $scope.isCreating = true;
            $scope.event['notifications'] = $scope.initialNotifs;
            return;
          });
        }
      };

      $scope.closeEvent = function ($event) {
        $mdDialog
          .show({
            templateUrl: 'views/crew/modals/event-close.modal.html',
            controller: 'CrewEventCloseModalCtrl',
            clickOutsideToClose: true,
            targetEvent: $event,
            skipHide: true,
            hasBackdrop: false,
            locals: {
              event: $scope.event
            }
          })
          .then(function () {
            $mdDialog.hide();
          });
      };

      $scope.reopenEvent = function ($event) {
        $mdDialog
          .show(
            $mdDialog.holConfirm({
              title: 'Réouverture',
              htmlContent: 'Etes-vous sûr de vouloir réouvrir cet évènement ?',
              targetEvent: $event,
              skipHide: true,
              hasBackdrop: false
            })
          )
          .then(function (res) {
            if (res) {
              CrewEventsService.reopen(event).then(function () {
                $mdDialog.hide();
                $rootScope.$broadcast('crew-situation-updated', event);
              });
            }
          });
      };

      $scope.getNbAccepted = event => CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED');

      $scope.getNbWaiting = event => CrewEventsService.getNbUserBySituationStatus(event, 'WAITING');

      $scope.getNbRefused = event => CrewEventsService.getNbUserBySituationStatus(event, 'REFUSED');

      const unregisterSituationEvent = $scope.$on('crew-situation-updated', function (e, situation, isNeutralizedSituation) {
        if (!isNeutralizedSituation && situation.closeReason === undefined) {
          const user = _.find($scope.event.users, { objectId: situation.user.objectId });
          if (user) {
            user.lastSituation = situation;
          }
        }
      });

      $scope.openUserModal = function ($event, user, event) {
        if ($rootScope.isPhone) {
          $mdDialog.show({
            controller: 'CrewUserModalCtrl',
            templateUrl: 'views/crew/modals/user.responsive.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            skipHide: true,
            hasBackdrop: false,
            locals: {
              user: user,
              event: event,
              situation: user.lastSituation,
              isReadOnly: isReadOnly,
              isArchivesOpened: false
            }
          });
        } else {
          $mdDialog.show({
            controller: 'CrewUserModalCtrl',
            templateUrl: 'views/crew/modals/user.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            skipHide: true,
            hasBackdrop: false,
            locals: {
              user: user,
              event: event,
              situation: user.lastSituation,
              isReadOnly: isReadOnly,
              isArchivesOpened: false
            }
          });
        }
      };

      $scope.$on('$destroy', () => unregisterSituationEvent());

      $scope.isHotEvent = event =>
        !$scope.isClosedEvent(event) &&
        CrewEventsService.getNbPotentiallyRemainingUser(event, 'WAITING') <=
          event.nbPersons - CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED');

      $scope.isClosedEvent = event => CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED') >= event.nbPersons;

      $scope.openCrewNoteModal = ev => {
        ModalsService.openCrewNoteModal({
          title: $translate.instant('CREW.DASHBOARD.EVENTS.ADD_NOTE'),
          toOccEnabled: true
        }).then(res => {
          if (res) {
            if ((res.text && res.text.trim()) || res.trim()) {
              $scope.event.notes.push(res);
            }
          }
        });
      };
    }
  );
