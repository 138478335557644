import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisType } from '../models/erp-crisisTypes';
import { CrisisTypeService } from '../../common/services/crisis/crisis-type.service';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisTypeService extends CrisisTypeService<ErpCrisisType> {
  constructor(requestService: RequestService) {
    super(requestService);
    this.ParseCrisisType = Parse.Object.extend('GDCCrisisTypes');
  }

  protected newCrisisTypeObject(parser: Parse.Object): ErpCrisisType {
    return parser ? new ErpCrisisType(parser) : undefined;
  }

  protected async fetchCrisisParameters(crisisTypes: ErpCrisisType[]): Promise<void> {
    crisisTypes.forEach(crisisType => {
      crisisType.parameters = [
        {
          crisisParameterId: crisisType.info1Title,
          crisisTypeId: crisisType.crisisTypeId,
          title: crisisType.info1Title,
          description: crisisType.info1Title,
          placeholder: crisisType.info1Hint,
          order: 1,
        },
        {
          crisisParameterId: crisisType.info2Title,
          crisisTypeId: crisisType.crisisTypeId,
          title: crisisType.info2Title,
          description: crisisType.info2Title,
          placeholder: crisisType.info2Hint,
          order: 2,
        },
        {
          crisisParameterId: crisisType.info3Title,
          crisisTypeId: crisisType.crisisTypeId,
          title: crisisType.info3Title,
          description: crisisType.info3Title,
          placeholder: crisisType.info3Hint,
          order: 3,
        },
        {
          crisisParameterId: crisisType.info4Title,
          crisisTypeId: crisisType.crisisTypeId,
          title: crisisType.info4Title,
          description: crisisType.info4Title,
          placeholder: crisisType.info4Hint,
          order: 4,
        },
      ].filter(p => !!p.crisisParameterId);
    });
  }
}
