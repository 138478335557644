<div class="hol-dialog-wrapper add-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span [innerHTML]="title"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="formGroup" class="full-width">
      <div class="hol-dialog-section full-width">
        <mat-form-field class="full-width description-textarea">
          <mat-label>{{ 'COMMON.MODALS.NEXT_INFO.DESCRIPTION' | translate }}</mat-label>
          <textarea class="description" formControlName="message" autofocus matInput [maxLength]="nextInfoLimit"
            type="text" mat-autosize></textarea>
        </mat-form-field>
      </div>
      <div class="hol-dialog-section full-width">
        <app-file-and-image-upload [attachments]="attachments" [context]="{
            module: context.module,
            category: context.category,
            htmlTitle: context.htmlTitle,
            htmlDate: context.htmlDate,
            htmlScenario: context.htmlScenario,
            htmlTemplate: 'C',
            htmlNextInfo: gethtmlNextInfo()
          }">
        </app-file-and-image-upload>
      </div>
      <div class="hol-dialog-section notifications" *ngIf="notifications.length">
        <label>{{ 'COMMON.SEND_NOTIFICATIONS' | translate }}</label>
        <app-notification-table [notifications]="notifications" [acl]="acl"></app-notification-table>
      </div>
      <div class="hol-dialog-section inputs-date">
        <div class="hol-spaced-line">
          <span class="next-info-libelle">
            <strong>{{ 'COMMON.MODALS.NEXT_INFO.EXPECTED' | translate }}</strong>
          </span>
          <app-hol-next-info [nextInfo]="{ nextInfoTime: nextInfoTime }" [hasDoneBtn]="false" [isUTC]="isUtc"
            (saveNextInfo)="saveNextInfo($event)">
          </app-hol-next-info>
        </div>
      </div>
    </form>
    <div></div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="fill-space"></div>

    <button disabled="{{ formGroup.status !== 'VALID' }}" (click)="save()" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      <span>{{ 'DASHBOARD.MODAL.SUBMIT' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>