import { HolBreakingNew } from '../../models/hol-breaking-new.model';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { CommonStoreManager } from '../../store/common.store-manager';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BreakingNewsService } from '../../services/breaking-news.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-breaking-news',
  templateUrl: './breaking-news.component.html',
  styleUrls: ['./breaking-news.component.scss'],
})
export class BreakingNewsComponent extends BaseComponent implements OnInit, OnDestroy {
  protected _clear = new BehaviorSubject<boolean>(false);
  @Input()
  set clear(value) {
    this._clear.next(value);
  }
  get clear() {
    return this._clear.getValue();
  }

  public addForm: FormGroup;
  public breakingNewsList: HolBreakingNew[] = [];
  public loading = false;
  public addFormEnabled = false;
  public showEditIcon = {};

  private breakingNewsSub: Subscription;
  private componentSub: Subscription;

  constructor(
    private breakingNewsService: BreakingNewsService,
    public commonStoreManager: CommonStoreManager,
    private moduleConfig: ModuleConfigService,
  ) {
    super();
  }

  ngOnInit() {
    this.addForm = new FormGroup({
      textContent: new FormControl('', [Validators.required]),
    });
    this.breakingNewsSub = this.commonStoreManager.breakingNews.subscribe(result => {
      if (!result) {
        return;
      }
      if (this.breakingNewsList.length) {
        this.breakingNewsList = result.map(el => {
          const bufferData = this.breakingNewsList.find(data => data.objectId === el.objectId);
          if (bufferData) {
            el.edit = bufferData.edit;
            el.showEditIcon = bufferData.showEditIcon;
          }
          return el;
        });
      } else {
        this.breakingNewsList = result;
      }
    });
    this.componentSub = this._clear.subscribe(value => {
      if (value) {
        this.clearComponent();
      }
    });
  }

  public updateItem(breakingNew: HolBreakingNew) {
    this.loading = true;

    return this.breakingNewsService.updateBreakingNews(breakingNew).finally(() => {
      this.closeItem(breakingNew);
      this.loading = false;
    });
  }

  public closeItem(breakingNew: HolBreakingNew) {
    breakingNew.edit = false;
    breakingNew.showEditIcon = false;
    this.breakingNewsList = this.breakingNewsList.map(el => {
      if (el.objectId === breakingNew.objectId) {
        el.edit = false;
        el.showEditIcon = false;
        return el;
      } else {
        return el;
      }
    });
    return this.commonStoreManager.updateBreakingNewsItem(breakingNew);
  }

  public addItem() {
    this.loading = true;
    const newsValue = this.addForm.get('textContent').value;
    if (newsValue) {
      return this.breakingNewsService.addBreakingNews(newsValue, this.moduleConfig.config.moduleName).finally(() => {
        this.addForm.controls['textContent'].setValue('');
        this.addFormEnabled = false;
        this.loading = false;
      });
    }
  }

  public deleteItem(breakingNew: HolBreakingNew, $event) {
    this.loading = true;
    return this.breakingNewsService.deleteBreakingNews(breakingNew).finally(() => {
      this.closeItem(breakingNew);
      this.loading = false;
    });
  }

  private clearComponent() {
    this.addFormEnabled = false;

    this.breakingNewsList.map(item => {
      item.edit = false;
      item.showEditIcon = false;
      return item;
    });
  }

  ngOnDestroy(): void {
    this.clearComponent();
    this.breakingNewsSub.unsubscribe();
    this.componentSub.unsubscribe();
  }
}
