import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { ErpCrisisTask } from 'src/app/erp/models/erp-crisisTask';
import { ErpHistoryLog } from './erp-historyLog';
import { HolObject } from 'src/app/common/models/hol-object';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ErpCrisis } from './erp-crisis';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { HolUser } from 'src/app/common/models/hol-user.model';

export class ErpCrisisDecision extends HolObject {
  title: string;
  tags: HolTag[];
  functionId: string;
  crisisObjectId: string;
  nextInfoTime?: Date;
  nextInfoDone?: boolean;
  isOnDashboard?: boolean;
  isArchived?: boolean;
  tasks?: ErpCrisisTask[];
  fromECL?: boolean;
  eclDecision?: any;
  toECL?: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], tasks?: ErpCrisisTask[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.title = parseObject.get('title');
    this.tags = tags ? tags : [];
    this.functionId = parseObject.get('functionId');
    const crisis = new ErpCrisis(parseObject.get('crisis'));
    this.crisisObjectId = crisis.objectId;
    this.nextInfoTime = parseObject.get('nextInfoTime');
    this.nextInfoDone = parseObject.get('nextInfoDone');
    this.isOnDashboard = parseObject.get('isOnDashboard');
    this.isArchived = parseObject.get('isArchived');
    this.tasks = tasks ? tasks : [];
    this.fromECL = parseObject.get('fromECL');
    this.eclDecision = parseObject.get('eclDecision') && new ErpCrisisDecision(parseObject.get('eclDecision'));
    this.toECL = parseObject.get('toECL');

    if (parseObject && parseObject.get('eclDecision')) {
      // this.eclDecision = new ErpCrisisDecision(parseObject.get('eclDecision'));
      this.createdBy = new HolUser(parseObject.get('eclDecision').get('createdBy'));
      let jsonAttachments = parseObject.get('eclDecision').get('attachments');
      if (typeof jsonAttachments === 'string') {
        jsonAttachments = JSON.parse(jsonAttachments);
      }
      if (jsonAttachments) {
        this.attachments = new HolAttachments(
          jsonAttachments.note,
          jsonAttachments.file,
          jsonAttachments.image,
          jsonAttachments.noteFile,
          jsonAttachments.link,
          jsonAttachments.files
        );
      } else {
        this.attachments = new HolAttachments();
      }
      this.fromECL = true;
      this.toECL = false;
    }
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('eclDecision') // if
      ? parseObject.get('eclDecision').get('message')
      : parseObject.get('title');
  }

  toLog?(action: string, parseObject: Parse.Object, crisisObjectId: string, decisionTask?: ErpCrisisTask): ErpHistoryLog {
    const log = new ErpHistoryLog();
    log.type = 'crisisDecision';
    log.subType = action;
    let json = this;
    if (decisionTask) {
      if (json.tasks.find(task => task.objectId === decisionTask.objectId)) {
        json.tasks = json.tasks.map(task => {
          if (task.objectId === decisionTask.objectId) {
            task = { ...task, ...decisionTask };
          }
          return task;
        });
      } else {
        json.tasks.unshift(decisionTask);
      }
    }
    json.contentText = json.title;
    log.jsonObject = json;
    log.acl = this.acl;
    log.crisisDecision = parseObject;
    log.crisis = new Parse.Object('GDCCrisis', { id: crisisObjectId });
    return log;
  }
}

export class ErpCrisisDecisionTaskToSave {
  crisisTask: ErpCrisisTask;
  notifications: HolNotification[] = [];
  functionToNotify;
  crisisObjectId: string;
  historyLogComment: string;
}
