import { Inject, Injectable } from '@angular/core';

import { find, map } from 'lodash';
import * as moment from 'moment';

import { OptionsService } from './options.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private readonly optionsService: OptionsService,
    private readonly requestService: RequestService,
    @Inject('CONSTANTS') public CONSTANTS,
  ) {}

  public getCrisisDirector() {
    return new Promise((resolve, reject) => {
      // Get Crisis director short title
      const shortTitleForCrisisDirector = this.optionsService.getCrisisDirectorShortTitlesList();
      if (shortTitleForCrisisDirector != null) {
        const query = new Parse.Query('GDCFunction');
        query.containedIn('shortTitle', shortTitleForCrisisDirector);

        // get crisis director function
        this.requestService.performFindQuery(
          query,
          functions => {
            if (functions && functions.length > 0) {
              const query = new Parse.Query('GDCUserFunction');
              query.containedIn(
                'functionId',
                map(functions, fn => {
                  return fn.get('functionId');
                }),
              );
              query.equalTo('isHolder', true);

              // Get crisis director user function
              this.requestService.performFindQuery(
                query,
                userFunctions => {
                  if (userFunctions.length > 0) {
                    let userId;
                    // On prend le premier crisisDirector dans l'ordre des shortTitles
                    for (let i = 0; i < shortTitleForCrisisDirector.length; i++) {
                      const shortTitle = shortTitleForCrisisDirector[i];

                      const func = find(functions, f => {
                        return f.get('shortTitle') === shortTitle;
                      });
                      const userFunction = find(userFunctions, uf => {
                        return uf.get('functionId') === func.get('functionId');
                      });
                      if (userFunction) {
                        userId = userFunction.get('userId');
                        break;
                      }
                    }

                    const query = new Parse.Query(Parse.User);
                    query.equalTo('userId', userId);
                    // Get crisis director user
                    this.requestService.performFindQuery(
                      query,
                      users => {
                        if (users.length > 0) {
                          const user = users[0];
                          resolve({
                            id: user.id,
                            name: user.get('firstName') + ' ' + user.get('lastName'),
                            phone: user.get('phone'),
                            mail: user.get('email') || user.get('userEmail'),
                          });
                        } else {
                          resolve(null);
                        }
                      },
                      () => {
                        reject('Error getting crisis director user');
                      },
                    );
                  } else {
                    resolve(null);
                  }
                },
                () => {
                  reject('Error getting crisis director user function');
                },
              );
            } else {
              resolve(null);
            }
          },
          () => {
            reject('Error getting crisis director function');
          },
        );
      } else {
        reject('Error getting crisis director short title');
      }
    });
  }

  // Used in "MailSenderService"
  public getCrisisDirectors() {
    return new Promise((resolve, reject) => {
      // Get Crisis director short title
      const shortTitleForCrisisDirector = this.optionsService.getCrisisDirectorShortTitlesList();
      if (shortTitleForCrisisDirector != null) {
        const query = new Parse.Query('GDCFunction');
        query.containedIn('shortTitle', shortTitleForCrisisDirector);

        // get crisis director function
        this.requestService.performFindQuery(
          query,
          functions => {
            if (functions && functions.length > 0) {
              const query = new Parse.Query('GDCUserFunction');
              query.containedIn(
                'functionId',
                map(functions, fn => {
                  return fn.get('functionId');
                }),
              );
              query.equalTo('isHolder', true);
              // Get crisis director user function
              this.requestService.performFindQuery(
                query,
                userFunctions => {
                  if (userFunctions.length > 0) {
                    const query = new Parse.Query(Parse.User);
                    query.containedIn(
                      'userId',
                      map(userFunctions, uf => {
                        return uf.get('userId');
                      }),
                    );
                    // Get crisis director user
                    this.requestService.performFindQuery(
                      query,
                      users => {
                        if (users.length > 0) {
                          resolve(
                            map(users, user => {
                              return {
                                id: user.id,
                                name: user.get('firstName') + ' ' + user.get('lastName'),
                                phone: user.get('phone'),
                                mail: user.get('email') || user.get('userEmail'),
                              };
                            }),
                          );
                        } else {
                          resolve(null);
                        }
                      },
                      () => {
                        reject('Error getting crisis director user');
                      },
                    );
                  } else {
                    resolve(null);
                  }
                },
                () => {
                  reject('Error getting crisis director user function');
                },
              );
            } else {
              resolve(null);
            }
          },
          () => {
            reject('Error getting crisis director function');
          },
        );
      } else {
        reject('Error getting crisis director short title');
      }
    });
  }

  public isSeen(item, lastTime) {
    return moment(item.createdAt).isBefore(lastTime) || moment(item.createdAt).add(this.CONSTANTS.TIME_TO_SEE, 'm').isBefore(moment());
  }
}
