'use strict';

angular.module('gestiondecriseApp').controller('CrewDashboardCtrl', function ($scope, $rootScope) {
  var $ctrl = this;
  $scope.isReadOnly = false;

  $rootScope.$watch(
    function () {
      return $rootScope.crewEventFilter;
    },
    function () {
      if ($rootScope.crewEventFilter) {
        $scope.currentFilter = $rootScope.crewEventFilter;
      }
    }
  );
});
