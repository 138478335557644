import { cloneDeep, intersectionWith, isEqual, keyBy, merge, orderBy, values, remove } from 'lodash';
import { AircraftsActions, MccAircraftsActionTypes } from './mcc-aircrafts.actions';
import { MccAircraft } from '../../models/mcc-aircraft.model';

const aircraftInitialState: Array<MccAircraft> = [];

export function mccAircraftsReducer(state: MccAircraft[] = cloneDeep(aircraftInitialState), action: AircraftsActions): MccAircraft[] {
  switch (action.type) {
    case MccAircraftsActionTypes.UPDATE_AIRCRAFTS:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        return [...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId')))];
      }
    case MccAircraftsActionTypes.UPDATE_OPE_STATUS:
      return cloneDeep(state).map(aircraft => {
        if(aircraft.objectId === action.payload.objectId){
          aircraft.isOperational = !aircraft.isOperational;
        }
        return aircraft;
      });
    case MccAircraftsActionTypes.UPDATE_MEL:
      return cloneDeep(state).map(aircraft => {
        aircraft.mels = aircraft.mels.map(mel => {
          return mel.objectId !== action.payload.objectId ? mel : action.payload;
        });
        return aircraft;
      });
    case MccAircraftsActionTypes.UPDATE_MEL_INFO:
      return cloneDeep(state).map(aircraft => {
        aircraft.mels = aircraft.mels.map(mel => {
          mel.infos = mel.infos.map(info => {
            return info.objectId !== action.payload.objectId ? info : action.payload;
          });
          return mel;
        });
        return aircraft;
      });
    case MccAircraftsActionTypes.ADD_EVENT:
      const bufferState = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferState.find(a => a.objectId === action.payload.aircraft.objectId);
        aircraft.events.unshift(action.payload);
        aircraft.events = orderBy(aircraft.events, event => {
          const nextInfo = orderBy(event.infos, 'nextInfoTime', 'asc').find(e => !e.done);
          return nextInfo ? nextInfo.nextInfoTime : null;
        });
      }
      return bufferState;
    case MccAircraftsActionTypes.UPDATE_EVENT:
      const bufferStateUpdateEvent = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferStateUpdateEvent.find(a => a.objectId === action.payload.aircraft.objectId);
        aircraft.events = aircraft.events.map(event => (event.objectId === action.payload.objectId ? action.payload : event));
      }
      return bufferStateUpdateEvent;
    case MccAircraftsActionTypes.UPDATE_EVENT_INFO:
      return cloneDeep(state).map(aircraft => {
        aircraft.events = aircraft.events.map(event => {
          event.infos = event.infos.map(info => {
            return info.objectId !== action.payload.objectId ? info : action.payload;
          });
          return event;
        });
        return aircraft;
      });
    case MccAircraftsActionTypes.REMOVE_EVENT:
      const bufferStateRemoveEvent = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferStateRemoveEvent.find(a => a.objectId === action.payload.aircraft.objectId);
        remove(aircraft.events, event => event.objectId === action.payload.objectId);
      }
      return bufferStateRemoveEvent;
    case MccAircraftsActionTypes.ADD_ITEM_CABIN:
      const bufferStateAddItemCabin = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferStateAddItemCabin.find(a => a.registration === action.payload.aircraft);
        aircraft.cabins.unshift(action.payload);
        aircraft.cabins = orderBy(aircraft.cabins, item => {
          const nextInfo = orderBy(item.infos, 'nextInfoTime', 'asc').find(e => !e.done);
          return nextInfo ? nextInfo.nextInfoTime : null;
        });
      }
      return bufferStateAddItemCabin;
    case MccAircraftsActionTypes.UPDATE_ITEM_CABIN:
      const bufferStateUpdateItemCabin = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferStateUpdateItemCabin.find(a => a.registration === action.payload.aircraft);
        aircraft.cabins = aircraft.cabins.map(cabin => (cabin.objectId === action.payload.objectId ? action.payload : cabin));
      }
      return bufferStateUpdateItemCabin;
    case MccAircraftsActionTypes.REMOVE_ITEM_CABIN:
      const bufferStateRemoveItemCabin = cloneDeep(state);
      if (action.payload.aircraft) {
        const aircraft = bufferStateRemoveItemCabin.find(a => a.registration === action.payload.aircraft);
        remove(aircraft.cabins, cabin => cabin.objectId === action.payload.objectId);
      }
      return bufferStateRemoveItemCabin;
    case MccAircraftsActionTypes.UPDATE_ITEM_CABIN_INFO:
      return cloneDeep(state).map(aircraft => {
        aircraft.cabins = aircraft.cabins.map(cabin => {
          cabin.infos = cabin.infos.map(info => {
            return info.objectId !== action.payload.objectId ? info : action.payload;
          });
          return cabin;
        });
        return aircraft;
      });
    default:
      return state;
  }
}
