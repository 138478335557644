<div class="hol-dialog-wrapper">
  <mat-toolbar>
    <div mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span>Title</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content>
    <div class="hol-dialog-section">
      First section
    </div>
    <div class="hol-dialog-section">
      Second section
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button [mat-dialog-close]="true" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      {{ 'GOC.EVENT_MODAL.ADD' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
