import { cloneDeep } from 'lodash';
import { OclGroupsActions, OclGroupsActionTypes } from './ocl-groups.actions';
import { OclGroupsStoreState } from '../../ocl.model';

const groupsInitialState: OclGroupsStoreState = {
  events: [],
  logbooks: [],
  decisions: [],
  globalInstructions: [],
};

export function oclGroupsReducer(
  state: OclGroupsStoreState = cloneDeep(groupsInitialState),
  action: OclGroupsActions,
): OclGroupsStoreState {
  switch (action.type) {
    case OclGroupsActionTypes.INIT_GROUPS:
      return {
        ...state,
        [action.typeKey]: action.groups,
      };
    case OclGroupsActionTypes.UPDATE_GROUPS_FROM_POOLING:
      return {
        ...state,
        [action.typeKey]: action.groups,
      };

    case OclGroupsActionTypes.UPDATE_ONE_GROUP:
      const index = state[action.typeKey].findIndex(group => group.objectId === action.group.objectId);
      if (index !== -1) {
        return {
          ...state,
          [action.typeKey]: [...state[action.typeKey].slice(0, index), action.group, ...state[action.typeKey].slice(index + 1)],
        };
      }
      return state;
    default:
      return state;
  }
}
