import { Action } from '@ngrx/store';
import { OclGroup } from '../../models/ocl-group.model';

export enum OclGroupsActionTypes {
  INIT_GROUPS = '[OCL GROUPS] Init Groups',
  UPDATE_GROUPS_FROM_POOLING = '[OCL GROUPS] Update Groups From Pooling',
  UPDATE_ONE_GROUP = '[OCL GROUPS] Update on Group',
}

export class InitGroups implements Action {
  readonly type = OclGroupsActionTypes.INIT_GROUPS;
  constructor(public groups: OclGroup<any>[], public typeKey: string) {}
}

export class UpdateGroupsFromPooling implements Action {
  readonly type = OclGroupsActionTypes.UPDATE_GROUPS_FROM_POOLING;
  constructor(public groups: OclGroup<any>[], public typeKey: string) {}
}

export class UpdateOneGroup implements Action {
  readonly type = OclGroupsActionTypes.UPDATE_ONE_GROUP;
  constructor(public group: OclGroup<any>, public typeKey: string, public currentModule: string) {}
}

export type OclGroupsActions = InitGroups | UpdateGroupsFromPooling | UpdateOneGroup;
