import { Inject, Injectable } from '@angular/core';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';

import * as moment from 'moment';
import { OclEventService } from 'src/app/ocl/services/ocl-event-service/ocl-event.service';
import { MccOptionsService } from 'src/app/mcc/services/mcc-options.service';
import { OclEventTagService } from 'src/app/ocl/services/ocl-event-tag-service/ocl-event-tag.service';
import { OclEventsStoreManager } from 'src/app/ocl/store/events/ocl-events-store-manager.service';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { EclInfos } from 'src/app/common/store/common.model';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { FilesService } from 'src/app/common/services/files.service';
import { cloneDeep, differenceBy, flatten, isEqual, map, orderBy, startsWith } from 'lodash';
import { OclScenariosStoreManager } from 'src/app/ocl/store/scenarios/ocl-scenarios.store-manager';
import { OclTagsStoreManager } from 'src/app/ocl/store/tags/ocl-tags.store-manager';
import { UserService } from 'src/app/common/services/user.service';

import { MarkdownService } from '../../common/components/markdown-editor/markdown.service';
import { HolFlight } from '../../common/models/hol-flight.model';
import { HolNotification } from '../../common/models/hol-notification.model';
import { HolTag } from '../../common/models/hol-tag';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { RequestService } from '../../common/services/request.service';
import { TagsService } from '../../common/services/tags.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { OclMailService } from '../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OclSmsService } from '../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { FltEvent } from '../models/flt-event';
import { FltApplicabilityService } from './flt-applicability.service';
import { FltFlightEventService } from './flt-flight-event.service';
import { FltFlightService } from './flt-flight.service';
import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { EventInfos } from '../../common/models/hol-event';

@Injectable({
  providedIn: 'root',
})
export abstract class FltEventService<T extends FltEvent = FltEvent> extends OclEventService<FltEvent> {
  // tslint:disable:variable-name
  protected ParseFlightEvents;
  // tslint:enabled

  protected constructor(
    protected requestService: RequestService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    protected eventTagService: OclEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: ModuleConfigService,
    public markdownService: MarkdownService,
    protected flightService: FltFlightService,
    protected readonly filesService: FilesService,
    protected applicabilityService: FltApplicabilityService,
    protected flightEventService: FltFlightEventService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: OclTasksService,
    protected tagsService: TagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager,
      tagsStoreManager,
      scenariosStoreManager,
      filesService,
      markdownService,
    );
  }

  public setAdditionalFields(event: FltEvent, parseEvent: Parse.Object) {
    if (event.applicability) {
      event.applicability.updateParseObject(parseEvent);
    }
  }

  public create(
    event: FltEvent,
    infos: EventInfos[],
    notifications: HolNotification[],
    hasToActivateECL: boolean = false,
    eclInfos?: EclInfos,
    eclName?: string,
    context?: HolContext,
    duplicateToOtherModule?: boolean,
  ): Promise<T> {
    let addBreakLinesBefore = false;
    if (event.attachments.note) {
      addBreakLinesBefore = true;
    }
    return super
      .create(event, infos, notifications, hasToActivateECL, eclInfos, eclName, context, duplicateToOtherModule)
      .then(savedEvent => {
        event = savedEvent;
        return this.flightService.getFlightsForApplicability(event.applicability, event.acl).then(applicableFlights => {
          const events = flatten(
            applicableFlights.map(f => {
              return this.getFlightEventFromEvent(f, event);
            }),
          );
          return this.requestService
            .performSaveAllQuery(events)
            .then(() => this.saveApplicabilityHistory(event, context, addBreakLinesBefore));
        });
      });
  }

  public async update(
    event: T,
    activateCheckLists?: boolean,
    context?: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean,
  ): Promise<T> {
    const query = new Parse.Query(this.ParseEvents);
    return await this.requestService.performFirstQuery(query).then(result => {
      const oldEvent = cloneDeep(new FltEvent(result));
      const oldApplicability = oldEvent.applicability;
      const newApplicability = event.applicability;

      return super.update(event, activateCheckLists, context, newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue).then(res => {
        return Promise.all([
          this.flightEventService.getAllFromEvent(event),
          this.flightService.getFlightsForApplicability(event.applicability, event.acl),
        ]).then(([oldFlightLogbooks, matchingFlights]) => {
          const newFlightLogbooks = flatten(
            matchingFlights.map(f => {
              return this.getFlightEventFromEvent(f, event);
            }),
          );
          const compareFlightLogbooks = flb => {
            return flb.get('flight').id + '#' + flb.get('station');
          };
          const toRemove = differenceBy(oldFlightLogbooks, newFlightLogbooks, compareFlightLogbooks);
          const toCreate = differenceBy(newFlightLogbooks, oldFlightLogbooks, compareFlightLogbooks);

          return Promise.all([
            this.requestService.performDestroyAllQuery(toRemove),
            this.requestService.performSaveAllQuery(toCreate),
          ]).then(() => {
            if (!isEqual(newApplicability, oldApplicability)) {
              return this.saveApplicabilityHistory(res, context);
            } else {
              return res as T;
            }
          });
        });
      });
    });
  }

  public close(event: FltEvent, reason: number, reasonText: string, notifications: HolNotification[]): Promise<void> {
    return super.close(event, reason, reasonText, notifications).then(() => {
      this.flightEventService.deleteAllFtlEventsFromEvent(event.objectId);
    });
  }

  private getFlightEventFromEvent(flight: HolFlight, event: FltEvent): Parse.Object[] {
    const flightEvent = this.initFlightEventFromEvent(flight, event);

    if (event.applicability.flightsDirection === 'DEP' && event.applicability.stationsDirection === 'OUT') {
      flightEvent.set('station', flight.departure);
    } else if (event.applicability.flightsDirection === 'ARR' && event.applicability.stationsDirection === 'IN') {
      flightEvent.set('station', flight.destination);
    } else if (!event.applicability.stationsDirection && event.applicability.stations) {
      const flightEvents = [];
      event.applicability.stations.forEach(station => {
        if (flight.departure === station || flight.destination === station) {
          const tempEvent = this.initFlightEventFromEvent(flight, event);
          tempEvent.set('station', station);
          flightEvents.push(tempEvent);
        }
      });
      return flightEvents;
    }
    return [flightEvent];
  }

  protected initFlightEventFromEvent(flight: HolFlight, event: FltEvent) {
    const flightEvent = new this.ParseFlightEvents();
    flightEvent.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightEvent.set('event', new this.ParseEvents({ id: event.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightEvent.setACL(flight.acl);
    } else {
      flightEvent.setACL(event.acl);
    }
    return flightEvent;
  }

  protected newEvent(parseObject?: Parse.Object, eventTags?: Parse.Object[], infosToMap?: []): T {
    if (!parseObject) {
      return null;
    }
    const infos = map(infosToMap, info => new HolNextInfo(info));
    const tags = orderBy(
      map(eventTags, et => new HolTag(et.get('tag'))),
      'name',
    );
    return new FltEvent(parseObject, tags, infos) as T;
  }

  protected markPreviousInfosAsDone(event: FltEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    const infosToMarkAsDone = event.infos.filter(info => !info.done && moment(info.nextInfoTime).isSameOrBefore(nextInfoTime));
    const promises = infosToMarkAsDone.map(info => this.markInfoAsDone(info, event, true, false));
    return Promise.all(promises);
  }

  protected getAdditionnalQueries(query: Parse.Query<Parse.Object<Parse.Attributes>>, today: moment.MomentInput) {
    // Filtrage du tableau de bord par date
    // - Soit T = date sélectionnée à 00:00Z ou date à 00:00Z si aucune date n’est sélectionnée
    // - Soit C = date de création d’une carte `createdAt`
    // - Soit A = date à laquelle l'événement est archivé
    // | Applicabilité EVENT | Visibilité       |
    // | ------------------- | ---------------- |
    // | ACTIF               | `T <= C`         |
    // | ARCHIVÉ             | `C ≤ T ≤ A`      |
    if (this.moduleConfig.config.canChooseDataStartDate && today instanceof Date && today !== undefined) {
      const todayStart = moment.utc(today).startOf('day');
      const todayEnd = moment.utc(today).endOf('day');

      query.doesNotExist('closeDate');
      query.lessThanOrEqualTo('createdAt', todayEnd.toDate());

      const queryArchived = new Parse.Query(this.ParseEvents);
      queryArchived.exists('closeDate');
      queryArchived.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryArchived.greaterThanOrEqualTo('closeDate', todayStart.toDate());

      return Parse.Query.or(query, queryArchived);
    } else {
      return query;
    }
  }

  private saveApplicabilityHistory(savedEvent: FltEvent, context: HolContext, addBreakLinesBefore = false) {
    return this.applicabilityService.applicabilityHistoryInNotes(savedEvent, context, addBreakLinesBefore).then(attachments => {
      const parseEvent = new this.ParseEvents();
      parseEvent.id = savedEvent.objectId;
      parseEvent.set('attachments', JSON.stringify(attachments));
      return this.requestService.performSaveQuery(parseEvent).then(l => {
        const newEvent = this.newEvent(l, l.tags);
        this.eventsStoreManager.updateOneEvent(newEvent);
        return newEvent;
      });
    });
  }
}
