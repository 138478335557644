import { HolObject } from '../../common/models/hol-object';

export class MccCabinType extends HolObject {
  name: string;
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.name = parseObject.get('name');
  }
}
