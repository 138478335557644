import { HolObject } from 'src/app/common/models/hol-object';
import { HolUser } from 'src/app/common/models/hol-user.model';

export class HolHistoryLog extends HolObject {
  user: HolUser;
  type: string; // Object
  subType: string; // Action
  jsonObject: any;
  hideInHistory?: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.user = new HolUser(parseObject.get('user'));
    this.type = parseObject.get('type');
    this.subType = parseObject.get('subType');
    this.jsonObject = parseObject.get('jsonObject');
    this.hideInHistory = parseObject.get('hideInHistory');
  }
}
