import { OclGlobalInstruction } from '../../ocl/models/ocl-global-instruction.model';
import { EclFunction } from './ecl-function';
import { HolTag } from '../../common/models/hol-tag';
import { EclCrisis } from './ecl-crisis';

export class EclGlobalInstruction extends OclGlobalInstruction {
  public function: EclFunction;
  //public crisis: EclCrisis;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.function = new EclFunction(parseObject.get('function'));
    //this.crisis = new EclCrisis(parseObject.get('crisis'));
  }
}
