import { Injectable } from '@angular/core';
import { union } from 'lodash';
import { RequestService } from '../../../common/services/request.service';
import { OclHelperLink } from '../../models/ocl-helper-link.model';

@Injectable({
  providedIn: 'root',
})
export abstract class OclHelperLinkService {
  private helpersIdsCache: string[] = [];
  protected abstract ParseHelperLink;
  protected constructor(protected requestService: RequestService) {}

  getLinks(helpersIds: string[] = [], isUpdate: boolean = false): Promise<any> {
    if (!isUpdate) {
      this.helpersIdsCache = helpersIds;
    } else {
      this.helpersIdsCache = union(this.helpersIdsCache, helpersIds);
    }

    const queryHelperLink = new Parse.Query(this.ParseHelperLink);

    // const parseHelperLinksObjectsToCheck = this.helpersIdsCache.map(id => {
    //   return new this.ParseHelperLink({ id });
    // });

    return this.requestService.performFindAllQuery(queryHelperLink).then(helpers => {
      const buffer = helpers.map(h => new OclHelperLink(h));
      return buffer;
    });
  }
}
