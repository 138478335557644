<div class="hol-dialog-wrapper details-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <i class="fa fa-plus"></i>
      <span [innerHTML]="title"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <app-chat-channel [context]="context" [channel]="event"></app-chat-channel>
  </mat-dialog-content>
</div>
