import { ErpVisiblityStatus } from 'src/app/erp/models/erp-crisisTask';
import { HolObject } from './hol-object';
import { HolTag } from './hol-tag';
import { HolAttachments } from './hol-attachments.model';

export class HolTask extends HolObject {
  code: string;
  order: number;
  status: string;
  outputTitle: string;
  functionId: string;
  taskTitle: string;
  nextInfoTime?: Date;
  nextInfoDone?: boolean;
  tags?: HolTag[];
  defaultTags?: HolTag[];
  comment?: string;
  formIoFormRef?: string;
  formIoFormSubmission?: string;
  taskDescription?: string;
  isConditionalTask?: boolean;
  frozenByErd?: boolean;
  visibleBy?: ErpVisiblityStatus;
  customVisibleBy?: ErpVisiblityStatus;
  isFunctionNotified?: boolean;
  outputDataLabel?: string;
  customOutputDataLabel?: string;
  subOrder?: string;
  outputDataHint?: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.code = parseObject.get('code');
    this.order = parseObject.get('order');
    this.status = parseObject.get('status');
    this.outputTitle = parseObject.get('outputTitle');
    this.functionId = parseObject.get('functionId');
    this.taskTitle = parseObject.get('taskTitle');
    this.nextInfoTime = parseObject.get('nextInfoTime');
    this.nextInfoDone = parseObject.get('nextInfoDone');
    this.tags = tags ? tags : [];
    this.defaultTags = defaultTags ? defaultTags : [];
    this.comment = parseObject.get('comment');
    this.formIoFormRef = parseObject.get('formIoFormRef');
    this.formIoFormSubmission = parseObject.get('formIoFormSubmission');
    this.isConditionalTask = parseObject.get('isConditionalTask');
    this.taskDescription = parseObject.get('taskDescription');
    this.visibleBy = parseObject.get('visibleBy');
    this.frozenByErd = parseObject.get('frozenByErd');
    this.customVisibleBy = parseObject.get('customVisibleBy');
    this.isFunctionNotified = parseObject.get('isFunctionNotified');
    this.outputDataLabel = parseObject.get('outputDataLabel');
    this.outputDataLabel = parseObject.get('outputDataLabel');
    this.customOutputDataLabel = parseObject.get('customOutputDataLabel');
    this.subOrder = parseObject.get('subOrder') ? parseObject.get('subOrder') : '';
    this.outputDataHint = parseObject.get('outputDataHint') ? parseObject.get('outputDataHint') : '';
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('taskTitle');
  }
}
