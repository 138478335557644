import { Component, Inject, OnInit } from '@angular/core';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Component({
  selector: 'app-ocl-criticality-matrix-modal',
  templateUrl: './ocl-criticality-matrix-modal.component.html',
  styleUrls: ['./ocl-criticality-matrix-modal.component.scss'],
})
export class OclCriticalityMatrixModalComponent implements OnInit {
  flightStatus;
  isReadOnly: boolean;

  flightStatusMatrix = [
    [
      {
        criticity: 3,
      },
      {
        criticity: 3,
      },
      {
        criticity: 3,
      },
      {
        criticity: 4,
      },
    ],
    [
      {
        criticity: 2,
      },
      {
        criticity: 2,
      },
      {
        criticity: 3,
      },
      {
        criticity: 3,
      },
    ],
    [
      {
        criticity: 1,
      },
      {
        criticity: 2,
      },
      {
        criticity: 2,
      },
      {
        criticity: 3,
      },
    ],
    [
      {
        criticity: 0,
      },
      {
        criticity: 1,
      },
      {
        criticity: 2,
      },
      {
        criticity: 3,
      },
    ],
  ];

  flightStatusAxis;
  currentFlightStatus;

  constructor(
    private flightsStatusService: OclFlightStatusService,
    private optionsService: OclOptionsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public moduleConfig: ModuleConfigService
  ) {
    this.flightStatus = data.flightStatus;
    this.isReadOnly = data.isReadOnly;
    this.flightStatusAxis = {
      xAxis1: optionsService.getXAxis1(),
      xAxis2: optionsService.getXAxis2(),
      xAxis3: optionsService.getXAxis3(),
      yAxis1: optionsService.getYAxis1(),
      yAxis2: optionsService.getYAxis2(),
      yAxis3: optionsService.getYAxis3(),
    };
  }

  ngOnInit() {}

  addFlightStatusHistory(x, y, criticity) {
    if (this.currentFlightStatus && this.currentFlightStatus.x === x && this.currentFlightStatus.y === y) {
      if (this.currentFlightStatus.needsUpdate) {
        this.flightsStatusService.setNeedsUpdate(false).then(this.flightsStatusService.fetchNewData);
        return;
      }
    }

    this.flightsStatusService.setNeedsUpdate(false).then(() => {
      this.flightsStatusService.update(x, y, criticity).then(data => {
        this.flightsStatusService.fetchNewData();
        this.flightStatus = data;
      });
    });
  }
}
