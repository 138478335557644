import { HolFlight } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { HolUser } from '../../common/models/hol-user.model';
import { FltEvent } from './flt-event';
import { OclEvent } from 'src/app/ocl/models/ocl-event.model';

export class FltFlightEvent extends OclEvent {
  event: FltEvent;
  flight: HolFlight;
  archived: boolean;
  station: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], scenariosFromOtherModule?: Parse.Object[]) {
    super(parseObject, tags, [], scenariosFromOtherModule);
    if (!parseObject) {
      return;
    }
    if (parseObject && parseObject.get('event')) {
      this.event = new FltEvent(parseObject.get('event'));
      this.createdBy = new HolUser(parseObject.get('event').get('createdBy'));
      let jsonAttachments = parseObject.get('event').get('attachments');
      if (typeof jsonAttachments === 'string') {
        jsonAttachments = JSON.parse(jsonAttachments);
      }
      if (jsonAttachments) {
        this.attachments = new HolAttachments(
          jsonAttachments.note,
          jsonAttachments.file,
          jsonAttachments.image,
          jsonAttachments.noteFile,
          jsonAttachments.link,
          jsonAttachments.files
        );
      } else {
        this.attachments = new HolAttachments();
      }
    }
    this.flight = parseObject.get('flight') && new HolFlight(parseObject.get('flight'));
    this.archived = parseObject.get('archived');
    this.station = parseObject.get('station');
  }
}
