'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.Function
 * @description
 * # Function
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('FunctionService', function ($q, RequestService) {
  const GDCFunction = Parse.Object.extend('GDCFunction');

  let functions; // @cache : toutes les functions
  let mustRefresh_functions = false;

  // @hasCache functions
  this.all = function (forceRefresh, withoutTagId) {
    const deferred = $q.defer();
    if (mustRefresh_functions !== true && functions !== undefined && forceRefresh !== true) {
      deferred.resolve(functions);
    } else {
      const query = new Parse.Query(GDCFunction);
      if (!withoutTagId) {
        query.exists('tagId');
        query.notEqualTo('tagId', '');
      }
      RequestService.performFindAllQuery(
        query,
        function (results) {
          const fs = [];
          for (let i = 0; i < results.length; i++) {
            fs.push(transformParseFunctionToObject(results[i]));
          }
          functions = fs;
          mustRefresh_functions = false;
          deferred.resolve(_.cloneDeep(functions));
        },
        function (error) {
          deferred.reject(error);
        }
      );
    }
    return deferred.promise;
  };

  const transformParseFunctionToObject = function (parseObject) {
    return {
      title: parseObject.get('title'),
      shortTitle: parseObject.get('shortTitle'),
      id: parseObject.get('functionId'),
      tagId: parseObject.get('tagId'),
      objectId: parseObject.id,
      tasksSummary: parseObject.get('tasksSummary'),
      phone: parseObject.get('phone'),
      email: parseObject.get('email'),
      minutesBeforeAlert: parseObject.get('minutesBeforeAlert'),
      notified: parseObject.get('notified'),
    };
  };

  this.emptyCache = function () {
    mustRefresh_functions = true;
  };
});
