import { Injectable } from '@angular/core';
import { OclLinkedItemsStoreManager } from '../../../ocl/store/linked-items/ocl-linked-items-store-manager.service';
import { RequestService } from '../../../common/services/request.service';
import { OclLinksService } from 'src/app/ocl/services/ocl-links-service/ocl-links.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OccLinksService extends OclLinksService {
  // tslint:disable:variable-name
  protected ParseOclLogBook = Parse.Object.extend('OCCLogBook');
  protected ParseOclEvents = Parse.Object.extend('OCCEvents');
  protected ParseOclDecisions = Parse.Object.extend('OCCDecisions');
  protected ParseOclLinkLogBookDecision = Parse.Object.extend('OCCLinkLogBookDecision');
  protected ParseOclLinkDecisionEvent = Parse.Object.extend('OCCLinkDecisionEvent');
  protected ParseOclLinkLogBookEvent = Parse.Object.extend('OCCLinkLogBookEvent');
  // tslint:enabled
  public constructor(
    protected requestService: RequestService,
    protected occLinkedItemsStoreManager: OclLinkedItemsStoreManager,
    public moduleConfig: OccModuleConfigService
  ) {
    super(requestService, occLinkedItemsStoreManager, moduleConfig);
  }
}
