import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-crew',
  templateUrl: './crew.component.html',
  styleUrls: ['./crew.component.scss'],
})
export class CrewComponent implements OnInit {
  constructor(@Inject('$rootScope') public $rootScope) {}

  ngOnInit() {}
}
