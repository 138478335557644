import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { HelperService } from 'src/app/common/services/helper.service';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';

import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { GocLogbook } from '../../../goc/models/goc-logbook.model';
import { GocFlightLogbookService } from '../../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclLogbookService } from '../../../ocl/services/ocl-logbook-service/ocl-logbook.service';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { OccHistoryService } from '../occ-history-service/occ-history.service';
import { OccLogbookTagService } from '../occ-logbook-tag-service/occ-logbook-tag.service';
import { OccMailService } from '../occ-mail-service/occ-mail.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { OccSmsService } from '../occ-sms-service/occ-sms.service';
import { FilesService } from 'src/app/common/services/files.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';

@Injectable({
  providedIn: 'root',
})
export class OccLogbookService extends OclLogbookService {
  // tslint:disable:variable-name
  protected ParseLogbook = Parse.Object.extend('OCCLogBook');
  protected ParseOccEvents = Parse.Object.extend('OCCEvents');
  protected ParseLogbookTag = Parse.Object.extend('OCCLogBookTag');
  protected ParseTag = Parse.Object.extend('OCCTag');
  protected ParseEvents = Parse.Object.extend('OCCEvents');

  protected ParseGocFlightLogBook;
  // tslint:enabled

  public constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected userService: UserService,
    protected optionsService: OccOptionsService,
    protected historyService: OccHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: OccLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: OccModuleConfigService,
    private rolesService: RolesService,
    private helperService: HelperService,
    protected notificationsService: NotificationsService,
    protected smsService: OccSmsService,
    protected mailService: OccMailService,
    public commonStoreManager: CommonStoreManager,
    protected readonly filesService: FilesService,
    protected markdownService: MarkdownService,
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      occLogBooksStoreManager,
      moduleConfig,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager,
      filesService,
      markdownService,
    );
  }

  public create(
    logbook: OclLogbook,
    notifications: HolNotification[],
    context: HolContext,
    duplicateToOtherModule?: boolean,
  ): Promise<OclLogbook> {
    return super.create(logbook, notifications, context).then(savedLogbook => {
      if (duplicateToOtherModule) {
        this.duplicateLogbookToOtherModule(savedLogbook);
      }
      return savedLogbook;
    });
  }

  public async update(
    logbook: OclLogbook,
    notifications: HolNotification[],
    context: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean,
    updateDuplicateToOtherModuleFlightValue?: boolean,
  ): Promise<OclLogbook> {
    return super.update(logbook, notifications).then(res => {
      if (updateDuplicateToOtherModuleFlightValue) {
        // Update flight
        if (logbook.flight) {
          this.updateDuplicateLogbookToModule(res);
        } else {
          // Supprimer
          this.deleteDuplicateLogbookFromModule(res);
        }
      }
      return res;
    });
  }

  protected async duplicateLogbookToOtherModule(logbook: OclLogbook) {
    const parseGocLogbook = new this.ParseGocFlightLogBook();
    parseGocLogbook.set('occLogbook', new this.ParseLogbook({ id: logbook.objectId }));
    parseGocLogbook.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
    const companies = this.helperService.parseACL(logbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['GOC'], companies);
    parseGocLogbook.setACL(acl);
    this.requestService.performSaveAllQuery(parseGocLogbook).then();
  }

  protected deleteDuplicateLogbookFromModule(logbook: OclLogbook) {
    const parseOccLogbook = new this.ParseLogbook({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseGocFlightLogBook);
    query.equalTo('occLogbook', parseOccLogbook);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const gocLogbook = new GocLogbook(result);
        const parseGocLogbook = new this.ParseGocFlightLogBook({ id: gocLogbook.objectId });
        this.requestService.performDestroyQuery(parseGocLogbook).then();
      }
    });
  }

  private updateDuplicateLogbookToModule(logbook: OclLogbook) {
    const parseOccLogbook = new this.ParseLogbook({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseGocFlightLogBook);
    query.equalTo('occLogbook', parseOccLogbook);
    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const gocLogbook = new GocLogbook(result);
        const parseGocLogbook = new this.ParseGocFlightLogBook({ id: gocLogbook.objectId });
        parseGocLogbook.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
        this.requestService.performSaveQuery(parseGocLogbook).then();
      }
    });
  }
}
