import { Component, Input, OnInit } from '@angular/core';

import { RolesService } from '../../services/roles.service';
import { Company } from '../../models/hol-user.model';

@Component({
  selector: 'app-acl-indicator',
  templateUrl: './acl-indicator.component.html',
  styleUrls: ['./acl-indicator.component.scss'],
})
export class AclIndicatorComponent implements OnInit {
  @Input() object: { companies: string[]; companiesObj: Company[] };
  companiesColors: any;
  shouldDisplay = false;

  constructor(private rolesService: RolesService) {}

  async ngOnInit() {
    this.companiesColors = await this.rolesService.getCompaniesColors();
    this.shouldDisplay = Object.keys(this.companiesColors).length > 1;
  }
}
