import * as moment from 'moment';
import { Injectable } from '@angular/core';

declare var Notification: any;

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService {
  public isWithinTimeFrame(nextInfoTime): boolean {
    // pulling has 11 sec timeFrame, alert user only once
    const startDate = new Date();
    const endDate = moment(new Date()).add('10', 'seconds').toDate();
    return moment(nextInfoTime).isAfter(startDate) && moment(nextInfoTime).isBefore(endDate);
  }

  public sendNotif(notif): void {
    notif.onclose = () => console.log('closed', this);
  }

  public playSound(): void {
    new Audio('assets/sounds/plucky.mp3').play();
  }

  public tryToNotify(title: string, msg: string, isPlaySound: boolean) {
    if (isPlaySound) {
      this.playSound();
    }
    // Voyons si le navigateur supporte les notifications
    if (!('Notification' in window)) {
      console.warn('Ce navigateur ne supporte pas les notifications desktop');
      return;
    }
    const notif = new Notification(title, {
      body: msg,
      icon: 'assets/images/icons/icon-72.png',
    });
    // Voyons si l'utilisateur est OK pour recevoir des notifications
    if (Notification.permission === 'granted') {
      // Si c'est ok, créons une notification
      this.sendNotif(notif);
      // Sinon, nous avons besoin de la permission de l'utilisateur
      // Note : Chrome n'implémente pas la propriété statique permission
      // Donc, nous devons vérifier s'il n'y a pas 'denied' à la place de 'default'
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission(permission => {
        // Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
        if (!('permission' in Notification)) {
          Notification.permission = permission;
        }
        // Si l'utilisateur est OK, on crée une notification
        if (permission === 'granted') {
          this.sendNotif(notif);
        }
      });
    }
  }
}
