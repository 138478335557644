'use strict';
/**
 * @ngdoc service
 * @name gestiondecriseApp.User
 * @description
 * # User
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('UserService', function (
    FunctionService,
    $q,
    $rootScope,
    RequestService,
    OptionsService,
    USER_RIGHTS,
    ParseMapper,
    RolesService
  ) {
    var _currentUserRights = null;
    var currentUserFunctions = []; // @cache functions of the current user
    var mustRefresh_currentUserFunctions = false;

    var users = []; // all users
    var mustRefresh_users = false;

    var mapUserFunctionsWithCriteria = {}; // @cache : map qui pour un JSON.stringify(criteria) donne des données cachées

    var UserFunction = Parse.Object.extend('GDCUserFunction');
    var GDCFunction = Parse.Object.extend('GDCFunction');
    var that = this;

    this.getCurrentUser = function () {
      return (Parse.User.current().get('firstName') || '') + ' ' + (Parse.User.current().get('lastName') || '');
    };

    this.getCurrentUserObject = function () {
      return transformParseUserToObject(Parse.User.current());
    };

    this.getCurrentUserFunctions = function (forceToRefresh) {
      var deferred = $q.defer();

      FunctionService.all(forceToRefresh).then(function (functions) {
        getCurrentUserFunctions(forceToRefresh).then(function (currentUserFunctions) {
          var ufs = [];
          _.each(currentUserFunctions, function (uf) {
            var theF = _.find(functions, { id: uf.functionId });
            if (theF) {
              ufs.push({
                id: uf.functionId,
                title: theF.title,
                shortTitle: theF.shortTitle,
                isHolder: uf.isHolder,
              });
            }
          });
          deferred.resolve(ufs);
        }, deferred.reject);
      }, deferred.reject);

      return deferred.promise;
    };

    this.isCurrentUserInTheCrewManagerTeam = function () {
      return that.isCurrentUserInTeam(OptionsService.getCrewManagerShortTitlesList());
    };

    this.isCurrentUserInTheChatTeam = function () {
      return that.isCurrentUserInTeam(OptionsService.getChatShortTitlesList());
    };

    this.isCurrentUserInTeam = function (teamShortTitles) {
      var deferred = $q.defer();
      var currentUser = Parse.User.current();
      if (!currentUser) {
        return $q.reject('No current user');
      }
      var functionsQuery = new Parse.Query(GDCFunction);
      functionsQuery.containedIn('shortTitle', teamShortTitles);

      var userFunctionQuery = new Parse.Query(UserFunction);
      userFunctionQuery.matchesKeyInQuery('functionId', 'functionId', functionsQuery);

      var userQuery = new Parse.Query(Parse.User);
      userQuery.matchesKeyInQuery('userId', 'userId', userFunctionQuery);
      userQuery.equalTo('objectId', currentUser.id);
      RequestService.performFindQuery(
        userQuery,
        function (users) {
          deferred.resolve(users.length > 0);
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.clearCache = function () {
      _currentUserRights = null;
    };

    this.getAccessRights = function (forceRefresh) {
      if (_currentUserRights && !forceRefresh) {
        return $q.resolve(_currentUserRights);
      }
      const deferred = $q.defer();
      RolesService.getCurrentUserRoles()
        .then(roles => {
          _currentUserRights = {
            occ: roles.find(r => r.universe === 'OCC' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'OCC' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            ecl: roles.find(r => r.universe === 'ECL' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'ECL' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            ops: roles.find(r => r.universe === 'OPS' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'OPS' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            crew: roles.find(r => r.universe === 'CREW' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'CREW' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            crisis: roles.find(r => r.universe === 'ERP' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'ERP' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            mcc: roles.find(r => r.universe === 'MCC' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'MCC' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
          };
          if (roles.find(r => r.universe === 'GOC' && r.write && !r.subCompany)) {
            _currentUserRights.goc = USER_RIGHTS.WRITE;
          } else if (roles.find(r => r.universe === 'GOC' && r.read && !r.subCompany)) {
            _currentUserRights.goc = USER_RIGHTS.READ;
          } else if (roles.find(r => r.universe === 'GOC' && r.write && !!r.subCompany)) {
            _currentUserRights.goc = USER_RIGHTS.EXTERNAL_WRITE;
          } else if (roles.find(r => r.universe === 'GOC' && r.read && !!r.subCompany)) {
            _currentUserRights.goc = USER_RIGHTS.EXTERNAL_READ;
          } else {
            _currentUserRights.goc = USER_RIGHTS.UNAUTHORIZED;
          }
          deferred.resolve(_currentUserRights);
        })
        .catch(deferred.reject);

      return deferred.promise;
    };

    // @cache users
    this.getAllUsers = function (forceRefresh) {
      var deferred = $q.defer();

      if (mustRefresh_users !== true && users.length != 0 && forceRefresh !== true) {
        deferred.resolve(users);
      } else {
        getAllUsers().then(function (data) {
          mustRefresh_users = false;
          deferred.resolve(data);
        }, deferred.reject);
      }

      return deferred.promise;
    };

    // ------------------------
    // private functions

    // @hasCache
    var getCurrentUserFunctions = function (forceToRefresh) {
      var currentParseUser = Parse.User.current();
      if (currentParseUser == null) {
        return $q.reject();
      }

      var deferred = $q.defer();

      if (
        mustRefresh_currentUserFunctions !== true &&
        (currentUserFunctions.length != 0 || Parse.User.current() == null) &&
        forceToRefresh !== true
      ) {
        deferred.resolve(currentUserFunctions);
      } else {
        getAllUserFunctions({ userId: currentParseUser.get('userId') }, forceToRefresh).then(function (retrievedUserFunctions) {
          currentUserFunctions = _.cloneDeep(retrievedUserFunctions);
          mustRefresh_currentUserFunctions = false;
          deferred.resolve(currentUserFunctions);
        }, deferred.reject);
      }
      return deferred.promise;
    };

    var getAllUsers = function () {
      var query = new Parse.Query(Parse.User);
      query.limit(1000);
      return RequestService.performFindQuery(query).then(parseUsers => {
        users = parseUsers.map(u => ParseMapper.userToObject(u));
        return users;
      });
    };

    // @hasCache
    var getAllUserFunctions = function (criterias, forceRefresh) {
      var deferred = $q.defer();

      if (
        Object.keys(mapUserFunctionsWithCriteria).length > 0 &&
        forceRefresh == undefined &&
        mapUserFunctionsWithCriteria[JSON.stringify(criterias)]
      ) {
        // console.log("using cache for criterias ", JSON.stringify(criterias))

        deferred.resolve(mapUserFunctionsWithCriteria[JSON.stringify(criterias)]);
      } else {
        var query = new Parse.Query(UserFunction);
        if (criterias) {
          if (criterias.onlyHolders) {
            query.equalTo('isHolder', true);
          }
          if (criterias.userId) {
            query.equalTo('userId', criterias.userId);
          }
          if (criterias.functionId) {
            query.equalTo('functionId', criterias.functionId);
          } else {
            var functionWithTagQuery = new Parse.Query(GDCFunction);
            functionWithTagQuery.exists('tagId');
            functionWithTagQuery.notEqualTo('tagId', '');
            query.matchesKeyInQuery('functionId', 'functionId', functionWithTagQuery);
          }
        }
        RequestService.performFindAllQuery(
          query,
          function (userFunctions) {
            var parsedUserFunctions = [];
            for (var i = 0; i < userFunctions.length; i++) {
              parsedUserFunctions.push(transformParseUserFunctionToObject(userFunctions[i]));
            }

            mapUserFunctionsWithCriteria[JSON.stringify(criterias)] = parsedUserFunctions;
            deferred.resolve(parsedUserFunctions);
          },
          function (error) {
            deferred.reject(error);
          }
        );
      }
      return deferred.promise;
    };

    var transformParseUserFunctionToObject = function (parseObject) {
      return {
        objectId: parseObject.id,
        userId: parseObject.get('userId'),
        functionId: parseObject.get('functionId'),
        isHolder: parseObject.get('isHolder'),
        since: parseObject.get('createdAt'),
        updatedAt: parseObject.get('updatedAt'),
      };
    };

    var transformParseUserToObject = function (parseObject) {
      if (parseObject == null) return null;

      var user = {
        objectId: parseObject.id,
        userId: parseObject.get('userId'),
        firstName: parseObject.get('firstName'),
        lastName: parseObject.get('lastName'),
        displayName: parseObject.get('firstName') + ' ' + parseObject.get('lastName'),
        fullName: parseObject.get('firstName') + ' ' + parseObject.get('lastName'),
        username: parseObject.get('username'),
        email: parseObject.get('email') || parseObject.get('userEmail'),
        phone: parseObject.get('phone'),
        role: parseObject.get('role'),
        lastConnected: parseObject.get('lastConnected'),
        monogram: function () {
          return (
            (this.firstName && this.firstName.length ? this.firstName[0] : '') +
            (this.lastName && this.lastName.length ? this.lastName[0] : '')
          );
        },
      };
      if (parseObject.get('createdBy') != null) {
        user.createdBy = transformParseUserToObject(parseObject.get('createdBy'));
      }

      return user;
    };

    this.emptyCache = function () {
      mustRefresh_currentUserFunctions = true;
      mustRefresh_users = true;

      mapUserFunctionsWithCriteria = {};
    };

    this.fetchNewData = function () {
      var promises = [];
      promises.push(
        this.getCurrentUserFunctions().then(function (ufs) {
          $rootScope.$broadcast('poolService-userFunctions', ufs);
        })
      );

      return $q.all(promises);
    };

    this.getUsersForFunctionId = function (functionId) {
      return new Promise((resolve, reject) => {
        const ufQuery = new Parse.Query(UserFunction);
        ufQuery.equalTo('functionId', functionId);
        RequestService.performFindQuery(ufQuery)
          .then(userFunctions => {
            const userIds = userFunctions.map(uf => uf.get('userId'));
            const userQuery = new Parse.Query(Parse.User);
            userQuery.containedIn('userId', userIds);
            userQuery.ascending('userId');
            return RequestService.performFindQuery(userQuery);
          })
          .then(users => {
            return resolve(users.map(u => ParseMapper.userToObject(u)));
          })
          .catch(reject);
      });
    };

    this.getUserInitials = function (user) {
      var initials = '';
      if (user && user.firstName) {
        initials += user.firstName.substring(0, 1);
      }
      if (user && user.lastName) {
        initials += user.lastName.substring(0, 1);
      }
      return initials;
    };
  })
  .constant('USER_RIGHTS', {
    READ: 'READ',
    WRITE: 'WRITE',
    UNAUTHORIZED: 'UNAUTHORIZED',
    EXTERNAL_READ: 'EXTERNAL_READ',
    EXTERNAL_WRITE: 'EXTERNAL_WRITE',
  });
