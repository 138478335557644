<button
  (click)="onRemove()"
  [style.backgroundColor]="tag.color"
  [style.border-color]="tag.color"
  aria-label="tag.name"
  class="btn"
  mat-raised-button
>
  <span class="tag-name">{{ tag.name }}</span>
</button>
