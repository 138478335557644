<div
  class="next-info"
  [ngClass]="{
    elapsed: isElapsed(nextInfo.nextInfoTime),
    'almost-elapsed': isAlmostElapsed(nextInfo.nextInfoTime),
    'hol-success': nextInfo.nextInfoDone
  }"
>
  <mat-form-field appearance="outline" class="date-input">
    <input
      [matDatepicker]="picker"
      [(ngModel)]="nextInfoDate"
      matInput
      placeholder="DD/MM/YYYY"
      (ngModelChange)="callSaveInfo()"
      [disabled]="isReadOnly"
    />
    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  &nbsp;&nbsp;
  <mat-form-field appearance="outline" class="time-input">
    <input [(ngModel)]="nextInfoTime" matInput type="time" (ngModelChange)="callSaveInfo()" [disabled]="isReadOnly" />
    <span *ngIf="isUTC" matSuffix>UTC</span>
  </mat-form-field>
  <div *ngIf="!isReadOnly && hasDoneBtn" class="col-done">
    &nbsp;&nbsp;
    <button
      [disabled]="isReadOnly || (!nextInfoTime && !nextInfoDate)"
      (click)="markAsDone($event)"
      aria-label="mark as done"
      class="mat-icon-button mark-done-button"
    >
      <i class="icon icon-check"></i>
    </button>
  </div>
</div>
