import { cloneDeep, isEqual } from 'lodash';
import { MccImpactFilter } from '../../models/mcc-impact-filter.model';
import { ImpactFilterActions, MccImpactFilterActionTypes } from './mcc-impact-filter.actions';

const initialFilter: MccImpactFilter = null;

export function mccImpactFilterReducer(state: MccImpactFilter = cloneDeep(initialFilter), action: ImpactFilterActions): MccImpactFilter {
  switch (action.type) {
    case MccImpactFilterActionTypes.SELECT_FILTER:
      if (isEqual(state, action.payload)) {
        return null;
      }
      return action.payload;
    default:
      return state;
  }
}
