import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';
import { OclLinksService } from '../ocl-links-service/ocl-links.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OclLinksHelperService {
  protected constructor(private linksService: OclLinksService) {}

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getLinkFromLogBook(logBookId): Promise<any> {
    let decisions = [];
    let events = [];
    const linkedItemsToDisplay = [];
    const randomColor = this.getRandomColor();
    return new Promise((resolve, reject) => {
      Promise.all([this.linksService.getLinkDecisionForLogBookById(logBookId), this.linksService.getLinkLogBookEventById(logBookId)]).then(
        data => {
          decisions = data[0];
          const decisionsLinkedId = data[0].map(el => el.objectId);
          this.linksService.getLinkDecisionEventByArrayId(decisionsLinkedId).then(evts => {
            events = [...data[1], ...evts];
            events = uniqBy(events, 'objectId');
            linkedItemsToDisplay.push({
              isParent: true,
              objectId: logBookId,
            });
            events.forEach(ev => {
              if (linkedItemsToDisplay.findIndex(el => el.objectId === ev.objectId) === -1) {
                linkedItemsToDisplay.push({
                  isParent: false,
                  objectId: ev.objectId,
                });
              }
            });
            decisions.forEach(dec => {
              if (linkedItemsToDisplay.findIndex(el => el.objectId === dec.objectId) === -1) {
                linkedItemsToDisplay.push({
                  isParent: false,
                  objectId: dec.objectId,
                });
              }
            });
            resolve([randomColor, linkedItemsToDisplay, decisions, events]);
          });
        }
      );
    });
  }

  getLinkFromDecision(decisionId): Promise<any> {
    let logBooks = [];
    let events = [];
    const linkedItemsToDisplay = [];
    const randomColor = this.getRandomColor();

    return new Promise((resolve, reject) => {
      Promise.all([
        this.linksService.getLinkLogBookForDecisionById(decisionId),
        this.linksService.getLinkDecisionEventById(decisionId),
      ]).then(data => {
        logBooks = data[0];
        events = data[1];

        linkedItemsToDisplay.push({
          isParent: true,
          objectId: decisionId,
        });
        events.forEach(ev => {
          if (linkedItemsToDisplay.findIndex(el => el.objectId === ev.objectId) === -1) {
            linkedItemsToDisplay.push({
              isParent: false,
              objectId: ev.objectId,
            });
          }
        });

        logBooks.forEach(lgk => {
          if (linkedItemsToDisplay.findIndex(el => el.objectId === lgk.objectId) === -1) {
            linkedItemsToDisplay.push({
              isParent: false,
              objectId: lgk.objectId,
            });
          }
        });
        resolve([randomColor, linkedItemsToDisplay, logBooks, events]);
      });
    });
  }

  getLinkFromEvent(eventId): Promise<any> {
    let logBooks = [];
    let decisions = [];
    const linkedItemsToDisplay = [];
    const randomColor = this.getRandomColor();

    return new Promise((resolve, reject) => {
      Promise.all([this.linksService.getLinkEventLogBookById(eventId), this.linksService.getLinkEventDecisionById(eventId)]).then(data => {
        logBooks = data[0];
        decisions = data[1];
        linkedItemsToDisplay.push({
          isParent: true,
          objectId: eventId,
        });

        decisions.forEach(dec => {
          if (linkedItemsToDisplay.findIndex(el => el.objectId === dec.objectId) === -1) {
            linkedItemsToDisplay.push({
              isParent: false,
              objectId: dec.objectId,
            });
          }
        });

        logBooks.forEach(lgk => {
          if (linkedItemsToDisplay.findIndex(el => el.objectId === lgk.objectId) === -1) {
            linkedItemsToDisplay.push({
              isParent: false,
              objectId: lgk.objectId,
            });
          }
        });
        resolve([randomColor, linkedItemsToDisplay, logBooks, decisions]);
      });
    });
  }
}
