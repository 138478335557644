import { Injectable } from '@angular/core';

import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { Dictionary, groupBy } from 'lodash';
import * as moment from 'moment';
import { FilesService } from 'src/app/common/services/files.service';

import { HolContext } from '../../common/models/hol-context.model';
import { ENDED_STATUS, HolFlight } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { RequestService } from '../../common/services/request.service';
import { FltApplicability } from '../models/flt-applicability';
import { FltDecision } from '../models/flt-decision';
import { FltFlightInstruction } from '../models/flt-flight-instruction';
import { FltApplicabilityService } from './flt-applicability.service';

@Injectable({
  providedIn: 'root',
})
export abstract class FltFlightInstructionService<T extends FltFlightInstruction = FltFlightInstruction> {
  // tslint:disable:variable-name
  protected abstract ParseFlightInstruction;
  protected abstract ParseFlightInstructionTag;
  protected abstract ParseDecision;
  protected abstract ParseDecisionTag;
  protected abstract ParseTag;
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  // tslint:enable

  protected constructor(
    protected readonly filesService: FilesService,
    protected requestService: RequestService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
  ) {}

  public create(flightInstruction: T, applicability?: FltApplicability, context?: HolContext): Promise<T> {
    let addBreakLinesBefore = false;
    if (flightInstruction.attachments && flightInstruction.attachments.note) {
      addBreakLinesBefore = true;
    }
    const parseFI = new this.ParseFlightInstruction();
    parseFI.set('message', flightInstruction.message);
    parseFI.set('station', flightInstruction.station);
    parseFI.set('attachments', flightInstruction.attachments);
    parseFI.set('nextInfoTime', flightInstruction.nextInfoTime);
    parseFI.set('createdBy', Parse.User.current());
    parseFI.setACL(flightInstruction.acl);
    parseFI.set('decision', flightInstruction.decision ? new this.ParseDecision({ id: flightInstruction.decision.objectId }) : null);
    if (flightInstruction.flight) {
      parseFI.set('flight', new this.ParseFlight({ id: flightInstruction.flight.objectId }));
    }
    if (applicability) {
      if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
        parseFI.set('station', flightInstruction.flight.departure);
      } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
        parseFI.set('station', flightInstruction.flight.destination);
      }
    }
    this.setAdditionalFields(flightInstruction, parseFI);
    return this.requestService.performSaveQuery(parseFI).then(async savedFlightInstruction => {
      const fltInstructionTags = await this.addTags(flightInstruction, savedFlightInstruction);
      const newFlightInstruction = this.newFlightInstruction(savedFlightInstruction, fltInstructionTags);
      if (applicability && !savedFlightInstruction.decision) {
        return this.saveApplicabilityHistory(newFlightInstruction, context, applicability, addBreakLinesBefore);
      } else {
        return this.newFlightInstruction(savedFlightInstruction, savedFlightInstruction.tags);
      }
    });
  }

  public markAsDone(flightInstruction: T, done: boolean): Promise<T> {
    const parseFlightInstruction = new this.ParseFlightInstruction();
    parseFlightInstruction.id = flightInstruction.objectId;
    parseFlightInstruction.set('done', done);
    if (done) {
      parseFlightInstruction.set('doneDateTime', moment.utc().toDate());
    } else {
      parseFlightInstruction.unset('doneDateTime');
    }

    return this.requestService.performSaveQuery(parseFlightInstruction).then(async parseData => {
      const newFlightInstruction = this.newFlightInstruction(parseData);
      newFlightInstruction.tags = flightInstruction.tags;

      Object.assign(flightInstruction, newFlightInstruction);

      return newFlightInstruction;
    });
  }

  public async update(flightInstruction: T, applicability?: FltApplicability, context?: HolContext): Promise<T> {
    const parseFlightInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
    let updateApplicabilityHistory = false;
    parseFlightInstruction.set('message', flightInstruction.message);

    parseFlightInstruction.set('nextInfoTime', flightInstruction.nextInfoTime);
    parseFlightInstruction.setACL(flightInstruction.acl);

    if (flightInstruction.decision) {
      parseFlightInstruction.unset('attachments');
    } else {
      parseFlightInstruction.set('attachments', flightInstruction.attachments);
    }

    if (applicability) {
      if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
        parseFlightInstruction.set('station', flightInstruction.flight.departure);
      } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
        parseFlightInstruction.set('station', flightInstruction.flight.destination);
        updateApplicabilityHistory = flightInstruction.station !== flightInstruction.flight.destination;
      } else {
        parseFlightInstruction.unset('station');
        updateApplicabilityHistory = flightInstruction.station !== undefined;
      }
    }

    this.setAdditionalFields(flightInstruction, parseFlightInstruction);
    const savedFlightInstruction = await this.requestService.performSaveQuery(parseFlightInstruction);
    flightInstruction.station = savedFlightInstruction.get('station');
    if (flightInstruction.decision) {
      // await this.updateDecisionTags(flightInstruction);
    } else {
      // remove old tags
      await this.removeTags(savedFlightInstruction);
      // add new tags
      await this.addTags(flightInstruction, savedFlightInstruction);

      if (applicability && updateApplicabilityHistory) {
        await this.saveApplicabilityHistory(flightInstruction, context, applicability);
      }
    }

    return flightInstruction;
  }

  public getForFlights(flights: HolFlight[], isPrivate?: boolean): Promise<Dictionary<T[]>> {
    const parseFlights = flights.map(f => new this.ParseFlight({ id: f.objectId }));
    const query = new Parse.Query(this.ParseFlightInstruction);
    query.containedIn('flight', parseFlights);
    query.notEqualTo('archived', true);
    query.include('decision');
    query.addDescending('createdAt');
    if (isPrivate) {
      query.notEqualTo('isPrivate', true);
    }
    return this.requestService.performFindQuery(query).then(parseInstructions => {
      const parseDecisions = parseInstructions.filter(pi => pi.get('decision')).map(pi => pi.get('decision'));
      const flightInstructionTagsQuery = new Parse.Query(this.ParseFlightInstructionTag);
      flightInstructionTagsQuery.containedIn('flightInstruction', parseInstructions);
      flightInstructionTagsQuery.include('tag');
      flightInstructionTagsQuery.descending('createdAt');
      const decisionTagsQuery = new Parse.Query(this.ParseDecisionTag);
      decisionTagsQuery.containedIn('decision', parseDecisions);
      decisionTagsQuery.include('tag');
      decisionTagsQuery.descending('createdAt');

      return Promise.all([
        this.requestService.performFindQuery(flightInstructionTagsQuery),
        this.requestService.performFindQuery(decisionTagsQuery),
      ]).then(([fits, dts]) => {
        const instructions = parseInstructions.map(log => this.newFlightInstruction(log));
        instructions.forEach(instruction => {
          if (instruction.decision) {
            instruction.tags = dts
              .filter(dt => dt.get('decision').id === instruction.decision.objectId)
              .map(dt => new HolTag(dt.get('tag')));
          } else {
            instruction.tags = fits
              .filter(fit => fit.get('flightInstruction').id === instruction.objectId)
              .map(flt => new HolTag(flt.get('tag')));
          }
        });
        return groupBy(instructions, l => l.flight.objectId);
      });
    });
  }

  public archive(flightInstruction: T): Promise<T> {
    const parseInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
    parseInstruction.set('archived', true);
    parseInstruction.set('archivedDate', new Date());
    return this.requestService.performSaveQuery(parseInstruction).then(async savedInstruction => {
      return this.newFlightInstruction(savedInstruction, savedInstruction.tags);
    });
  }

  private async addTags(flightInstruction: T, savedFlightInstruction): Promise<Parse.Object[]> {
    if (flightInstruction.tags) {
      const parseTags = flightInstruction.tags.map(tag => {
        return new this.ParseFlightInstructionTag({
          flightInstruction: savedFlightInstruction,
          tag: new this.ParseTag({ id: tag && tag.objectId }),
        });
      });
      return this.requestService.performSaveAllQuery(parseTags);
    }
  }

  private async removeTags(parseFI) {
    const oldTagQuery = new Parse.Query(this.ParseFlightInstructionTag);
    oldTagQuery.equalTo('flightInstruction', parseFI);
    const oldTags = await this.requestService.performFindQuery(oldTagQuery);
    await this.requestService.performDestroyAllQuery(oldTags);
  }

  // private async addDecisionTags(flightInstruction: T) {
  //   const parseDecision = new this.ParseDecision({ id: flightInstruction.decision.objectId });

  //   if (flightInstruction.tags) {
  //     const currentParseTags = flightInstruction.tags.map(tag => {
  //       return new this.ParseTag({ id: tag.objectId });
  //     });

  //     const savingList = currentParseTags.map(parseTag => {
  //       const joinTableRecord = new this.ParseDecisionTag();
  //       joinTableRecord.set('decision', parseDecision);
  //       joinTableRecord.set('tag', parseTag);
  //       return joinTableRecord;
  //     });

  //     return this.requestService.performSaveAllQuery(savingList);
  //   } else {
  //     return Promise.resolve([]);
  //   }
  // }

  // private async removeDecisionTags(decision: FltDecision) {
  //   const query = new Parse.Query(this.ParseDecisionTag);
  //   const parseDecision = new this.ParseDecision({ id: decision.objectId });
  //   query.equalTo('decision', parseDecision);

  //   return this.requestService.performFindQuery(query).then(parseTags => {
  //     return this.requestService.performDestroyAllQuery(parseTags);
  //   });
  // }

  // private updateDecisionTags(flightInstruction: T): Promise<Parse.Object[]> {
  //   return this.removeDecisionTags(flightInstruction.decision).then(() => {
  //     return this.addDecisionTags(flightInstruction);
  //   });
  // }

  public updateInstructionDecision(flightInstruction: T, flightDecision: FltDecision): Promise<T> {
    const parseFlightInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
    parseFlightInstruction.set('decision', new this.ParseDecision({ id: flightDecision.objectId }));
    parseFlightInstruction.unset('attachments');
    return this.requestService.performSaveQuery(parseFlightInstruction).then(async updatedFlightInstruction => {
      const fltInstructionTags = await this.addTags(flightInstruction, updatedFlightInstruction);
      return this.newFlightInstruction(updatedFlightInstruction, fltInstructionTags);
    });
  }

  public async deleteInstructionFromCancelAppl(decisionId) {
    const queryFlight = new Parse.Query(this.ParseFlight);
    queryFlight.greaterThanOrEqualTo('std', moment.utc().toDate());

    const query = new Parse.Query(this.ParseFlightInstruction);
    query.equalTo('decision', new this.ParseDecision({ id: decisionId }));
    query.matchesQuery('flight', queryFlight);

    const intructionsToDelete = await this.requestService.performFindAllQuery(query);

    return await this.requestService.performDestroyAllQuery(intructionsToDelete);
  }

  getAllFromDecision(decision: FltDecision): Promise<Parse.Object[]> {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.notContainedIn('status', ENDED_STATUS);
    flightQuery.greaterThan('std', yesterday);
    const flightInstructionQuery = new Parse.Query(this.ParseFlightInstruction);
    flightInstructionQuery.equalTo('decision', parseDecision);
    flightInstructionQuery.notEqualTo('archived', true);
    flightInstructionQuery.include('flight');
    flightInstructionQuery.matchesQuery('flight', flightQuery);

    return this.requestService.performFindAllQuery(flightInstructionQuery);
  }

  protected setAdditionalFields(flightInstruction: T, parseFlightInstruction: Parse.Object) {
    // TODO add missing implementation
  }

  protected newFlightInstruction(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltFlightInstruction(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  private saveApplicabilityHistory(
    savedFlightInstruction: FltFlightInstruction,
    context: HolContext,
    applicability: FltApplicability,
    addMdBreakLinesBefore = false,
  ) {
    return this.applicabilityService
      .applicabilityHistoryInNotes(savedFlightInstruction, context, addMdBreakLinesBefore, applicability)
      .then(attachments => {
        const parseFlightInstruction = new this.ParseFlightInstruction();
        parseFlightInstruction.id = savedFlightInstruction.objectId;
        parseFlightInstruction.set('attachments', attachments);
        return this.requestService.performSaveQuery(parseFlightInstruction).then(fi => {
          const newSavedFlightInstruction = this.newFlightInstruction(fi);
          newSavedFlightInstruction.tags = savedFlightInstruction.tags;
          return newSavedFlightInstruction;
        });
      });
  }
}
