'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.component:ChatChannelComponent
 * @description
 * # ChatChannelComponent
 */

angular.module('gestiondecriseApp').component('chatChannelComponent', {
  templateUrl: 'scripts/components/chat/chat-channel/chat-channel.template.html',
  controller: 'ChatChannelController',
  bindings: {
    channel: '<',
    context: '<',
  },
});
