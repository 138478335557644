<div class="hol-clickable occ-decision-item" (click)="openDetailModal($event, item)">
  <app-hol-list-item [item]="item" [isReadOnly]="isReadOnly" [utcDisplay]="utcDisplay">
    <div class="header-addon">
      <div>
        <app-tag-chips [tags]="item.tags"></app-tag-chips>
      </div>
      <div *ngIf="item['function'] && item['function'].objectId" style="margin-bottom: 5px">
        <app-ecl-user-function-detail [function]="item['function']"></app-ecl-user-function-detail>
      </div>
    </div>

    <div class="hol-spaced-line addon">
      <div class="col-attachements">
        <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
      </div>
    </div>
  </app-hol-list-item>
</div>
