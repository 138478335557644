import { MccAircraft } from './mcc-aircraft.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import { HolObject } from '../../common/models/hol-object';
import { MccAtaCode } from './mcc-ata-code.model';

export class MccEvent extends HolObject {
  localisation: string;
  description: string;
  aircraft: MccAircraft;
  ataCode: MccAtaCode;
  toOCC: boolean;
  channelId: string;
  closeReason: number;
  closeReasonText: string;
  localisationUpdatedAt: Date;
  infos: HolNextInfo[];
  closeDate: Date;
  constructor(parseObject?: Parse.Object, infos?: HolNextInfo[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.localisation = parseObject.get('localisation');
    this.description = parseObject.get('description');
    this.aircraft = new MccAircraft(parseObject.get('aircraft'));
    this.ataCode = new MccAtaCode(parseObject.get('ataCode'));
    this.toOCC = parseObject.get('toOCC');
    this.channelId = parseObject.get('channelId');
    this.closeReason = parseObject.get('closeReason');
    this.closeReasonText = parseObject.get('closeReasonText');
    this.localisationUpdatedAt = parseObject.get('localisationUpdatedAt');
    this.closeDate = parseObject.get('closeDate');
    this.infos = infos;
  }
}
