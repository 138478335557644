import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // tslint:disable-next-line
  selector: 'app-chat-component',
})
export class ChatComponentDirective extends UpgradeComponent {
  @Input() context: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('chatComponent', elementRef, injector);
  }
}
