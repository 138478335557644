import { Injectable } from '@angular/core';
import { OclNotificationsService } from 'src/app/ocl/services/ocl-notifications-service/ocl-notifications.service';

@Injectable({
  providedIn: 'root',
})
export abstract class CrewNotificationsService extends OclNotificationsService {
  // tslint:disable:variable-name
  protected ParseNotifications = Parse.Object.extend('CrewNotification');
  // tslint:enabled
}
