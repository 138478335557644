import { OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN } from './../../modals/ocl-global-instruction-modal/ocl-global-instruction-modal.token';
import { MatDialog } from '@angular/material';
import { OclGlobalInstruction } from './../../models/ocl-global-instruction.model';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { cloneDeep } from 'lodash';
import { HolUTCDisplay } from '../../../common/enums/hol-utc-display.enum';
import { HolNotification } from 'src/app/common/models/hol-notification.model';

@Component({
  selector: 'app-ocl-global-instruction-item',
  templateUrl: './ocl-global-instruction-item.component.html',
  styleUrls: ['./ocl-global-instruction-item.component.scss'],
})
export class OclGlobalInstructionItemComponent {
  @Input() isReadOnly: boolean;
  @Input() utcDisplay: HolUTCDisplay;
  @Input() item: OclGlobalInstruction;
  @Input() userCanEditCards?: boolean;
  @Input() notifications?: HolNotification[];
  constructor(protected dialog: MatDialog, @Inject(OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN) protected globalInstructionModal) {}

  openDetailModal(event, item): void {
    event.stopPropagation();
    if (this.userCanEditCards || this.userCanEditCards === undefined) {
      this.dialog.open(this.globalInstructionModal, {
        data: {
          globalInstuction: cloneDeep(item),
          isReadOnly: this.isReadOnly,
          notifications: this.notifications,
        },
      });
    }
  }
}
