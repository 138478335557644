import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { OclEvent } from '../../models/ocl-event.model';
import {
  InitEvents,
  ResetEvents,
  UpdateEventsFromPooling,
  AddOneEvent,
  UpdateOneEvent,
  UpdateEventsFromLinkedValue,
  RemoveEventsFromLinkedValue,
  RemoveAllEventsLinked,
  DeleteOneEvent,
  UpdateManyEvents,
} from './ocl-events.actions';

@Injectable({
  providedIn: 'root',
})
export class OclEventsStoreManager {
  private _eventsState: Observable<OclEvent[]>;
  protected $currentOclModule: Observable<string>;

  constructor(private store: Store<AppState>) {
    this._eventsState = this.store.select('ocl', 'events');
    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  get eventsState(): Observable<OclEvent[]> {
    return this._eventsState;
  }

  initEvents(events: Array<OclEvent>): void {
    this.store.dispatch(new InitEvents(events));
  }

  updateEventsFromPooling(events: Array<OclEvent>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateEventsFromPooling(events));
    }
  }

  updateManyEvents(events: Array<OclEvent>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateManyEvents(events));
    }
  }

  updateEventsFromLinkedValue(events: Array<OclEvent>, linkUid: string): void {
    this.store.dispatch(new UpdateEventsFromLinkedValue(events, linkUid));
  }

  removeEventsFromLinkedValue(linkUid: string): void {
    this.store.dispatch(new RemoveEventsFromLinkedValue(linkUid));
  }

  removeAllEventsLinked(): void {
    this.store.dispatch(new RemoveAllEventsLinked());
  }

  addOneEvent(event: OclEvent): void {
    this.store.dispatch(new AddOneEvent(event));
  }

  updateOneEvent(event: OclEvent): void {
    this.store.dispatch(new UpdateOneEvent(event));
  }

  resetEvents(): void {
    this.store.dispatch(new ResetEvents());
  }

  deleteOneEvent(eventObjectId: string): void {
    this.store.dispatch(new DeleteOneEvent(eventObjectId));
  }
}
