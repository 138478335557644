import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisDecision } from './../models/erp-crisisDecision';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisDecisionTagService {
  private ParseDecision = Parse.Object.extend('GDCDecisions');
  private ParseTag = Parse.Object.extend('GDCTag');
  private ParseDecisionTag = Parse.Object.extend('GDCDecisionTag');

  public constructor(protected requestService: RequestService) {}

  public updateTags(decision: Partial<ErpCrisisDecision>): Promise<Parse.Object[]> {
    return this.deleteTags(decision).then(() => {
      return this.setTags(decision);
    });
  }

  private setTags(decision: Partial<ErpCrisisDecision>): Promise<Parse.Object[]> {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });

    if (decision.tags) {
      const currentParseTags = decision.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseDecisionTag();
        joinTableRecord.set('decision', parseDecision);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private deleteTags(decision: Partial<ErpCrisisDecision>): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseDecisionTag);
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    query.equalTo('decision', parseDecision);

    return this.requestService.performFindQuery(query).then(parseTags => {
      return this.requestService.performDestroyAllQuery(parseTags);
    });
  }
}
