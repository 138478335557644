import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { intersectionWith } from 'lodash';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OclGroupModalComponent } from '../../../ocl/modals/ocl-group-modal/ocl-group-modal.component';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent implements OnInit {
  data: any;
  matchingGroup: OclGroup<any>;

  @Input()
  public type: string;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public modeLinkIsActivate: boolean;
  @Input()
  public disabledLinks = {};

  @Input()
  public listGroup: OclGroup<any>[] = [];
  @Input()
  public linkedGroup: string[] = [];

  public linkedGroupDisplay: any[] = [];
  public linkedGroupArchived = [];

  @Output() groupChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(MAT_DIALOG_DATA) data: any, public moduleConfig: ModuleConfigService, protected dialog: MatDialog) {
    this.data = data;
  }

  ngOnInit() {
    this.findGroupLinked();
  }

  public findGroupLinked(): void {
    if (this.data.isCreatedByGroup) {
      this.checkIfGroupIsCreated();
    }
    this.listGroup.forEach(lgr => {
      if (this.data[this.type] && lgr.items.find(lg => lg.objectId === this.data[this.type].objectId)) {
        this.linkedGroupDisplay.push(lgr);
        this.linkedGroup.push(lgr.objectId);
      }
    });
    this.groupChange.emit(this.linkedGroup);
  }

  public checkIfGroupIsCreated(): void {
    if (this.data && this.data.group.objectId) {
      this.matchingGroup = this.listGroup.find(lgr => lgr.objectId === this.data.group.objectId);
      if (this.matchingGroup) {
        this.linkedGroupDisplay.push(this.data.group);
        this.linkedGroup.push(this.data.group.objectId);
      }
    }
  }

  public changelinkedGroup(selectedValues) {
    this.linkedGroupDisplay = [
      ...intersectionWith(this.listGroup, selectedValues, (i, j) => i.objectId === j),
      ...this.linkedGroupArchived,
    ];
    this.groupChange.emit(selectedValues);
  }

  public removeGroup(gr): void {
    this.linkedGroup = this.linkedGroup.filter(group => group !== gr.objectId);
    this.linkedGroupDisplay = this.linkedGroupDisplay.filter(group => group.objectId !== gr.objectId);
    this.groupChange.emit(this.linkedGroup);
  }

  addGroup(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: null,
        events: null,
        logBookItems: [],
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: [],
        type: this.type.toUpperCase(),
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(newGroup => {
        if (newGroup) {
          this.listGroup.push(newGroup);
          this.linkedGroup.push(newGroup.objectId);
          this.changelinkedGroup(this.linkedGroup);
        }
      });
  }
}
