import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclEvent } from '../../models/ocl-event.model';

@Injectable({
  providedIn: 'root',
})
export abstract class OclEventTagService {
  // tslint:disable:variable-name
  protected ParseEventTag;
  protected ParseEvents;
  protected ParseTag;
  // tslint:enable

  protected constructor(private requestService: RequestService) {}

  public updateEventWithTags(event: OclEvent): Promise<Parse.Object[]> {
    return this.deleteEventTagsForEvent(event).then(() => {
      return this.setTagsForEvent(event);
    });
  }

  private setTagsForEvent(event: OclEvent): Promise<Parse.Object[]> {
    const parseEvent = new this.ParseEvents({ id: event.objectId });

    if (event.tags) {
      const currentParseTags = event.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseEventTag();
        joinTableRecord.set('event', parseEvent);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private deleteEventTagsForEvent(event: OclEvent): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseEventTag);
    const parseEvent = new this.ParseEvents({ id: event.objectId });
    query.equalTo('event', parseEvent);

    return this.requestService.performFindAllQuery(query).then(parseOccEventTags => {
      return this.requestService.performDestroyAllQuery(parseOccEventTags);
    });
  }
}
