'use strict';

angular.module('gestiondecriseApp').controller('CrewAddUserModalCtrl', function ($scope, func, $mdDialog, CrewUsersService) {
  console.log('func', func);
  $scope.func = func;
  $scope.cancel = $mdDialog.cancel;
  $scope.user = {
    trigram: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  $scope.validate = function () {
    CrewUsersService.create($scope.user, $scope.func).then(function (newUser) {
      $mdDialog.hide(newUser);
    });
  };
});
