import { CommonStoreManager } from './../store/common.store-manager';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolBreakingNew } from '../models/hol-breaking-new.model';

@Injectable({
  providedIn: 'root',
})
export class BreakingNewsService {
  private breakingNewsParse = Parse.Object.extend('HOLBreakingNews');

  constructor(private requestService: RequestService, private commonStoreManager: CommonStoreManager) {}

  /**
   * Get all breaking news.
   */
  public async getAllBreakingNews(moduleName: string): Promise<HolBreakingNew[]> {
    const finalModuleName = this.getModuleName(moduleName);

    const query = new Parse.Query(this.breakingNewsParse);
    query.ascending('createdAt');
    if (finalModuleName) {
      query.equalTo('context', finalModuleName);
    }

    const results = await this.requestService.performFindQuery(query);
    return results.map(news => new HolBreakingNew(news));
  }

  /**
   * Delete all breaking news.
   */
  public async deleteAllBreakingNews() {
    try {
      const query = new Parse.Query(this.breakingNewsParse);
      const results = await this.requestService.performFindQuery(query);
      await this.requestService.performDestroyAllQuery(results);
    } catch (error) {
      console.error('Oops! Something went wrong: ' + error.message + ' (' + error.code + ')');
    }
  }

  public async fetchNewData(moduleName): Promise<HolBreakingNew[]> {
    const news = await this.getAllBreakingNews(moduleName);
    this.commonStoreManager.initBreakingNewsList(news);
    return news;
  }

  /**
   * Add new breaking news.
   * @param news the object to add
   * @param context module which the breaking news is created
   * @param updateStore should we update the redux store?
   */
  public async addBreakingNews(news: HolBreakingNew, context: string, updateStore = true): Promise<HolBreakingNew> {
    if (!news) {
      return;
    }
    const breakingNews = new this.breakingNewsParse();
    breakingNews.set('news', news);
    breakingNews.set('context', this.getModuleName(context));
    try {
      const newBreakingNews = await this.requestService.performSaveQuery(breakingNews, null);
      const item = new HolBreakingNew(newBreakingNews);
      if (updateStore) {
        this.commonStoreManager.addBreakingNewsItem(item);
      }
      return item;
    } catch (error) {
      throw new Error("Can't create a new breaking news " + JSON.stringify(error));
    }
  }

  /**
   * Update breaking news.
   * @param news
   * @param updateStore
   */
  public async updateBreakingNews(news: HolBreakingNew, updateStore = true): Promise<HolBreakingNew> {
    if (!news) {
      return;
    }
    // Check if the news has really been edited
    const query = new Parse.Query(this.breakingNewsParse);
    try {
      const breakingNewsObject = await this.requestService.performGetQuery(query, news.objectId);
      breakingNewsObject.set('news', news.news);
      const newsUpdated = await this.requestService.performSaveQuery(breakingNewsObject, null);
      const item = new HolBreakingNew(newsUpdated);
      if (updateStore) {
        this.commonStoreManager.updateBreakingNewsItem(item);
      }
      return item;
    } catch (error) {
      throw new Error("Can't update a breaking news " + JSON.stringify(error));
    }
  }

  /**
   * Delete breaking news.
   * @param news
   * @param updateStore
   */
  public async deleteBreakingNews(news: HolBreakingNew, updateStore = true): Promise<HolBreakingNew> {
    if (!news) {
      return;
    }
    const query = new Parse.Query(this.breakingNewsParse);
    try {
      const newsObject = await this.requestService.performGetQuery(query, news.objectId);
      const newsDeleted = await this.requestService.performDestroyQuery(newsObject);
      const item = new HolBreakingNew(newsDeleted);
      if (updateStore) {
        this.commonStoreManager.removeBreakingNewsItem(item);
      }
      return item;
    } catch (error) {
      throw new Error("Can't delete the breaking news " + JSON.stringify(error));
    }
  }
  private getModuleName(moduleName: string): string {
    return moduleName === 'crisis' ? 'erp' : moduleName;
  }
}
