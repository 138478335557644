import { HolFlight } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { OclDecision } from '../../ocl/models/ocl-decision.model';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { HolUser } from '../../common/models/hol-user.model';
import { FltDecision } from './flt-decision';

export class FltFlightInstruction extends OclDecision {
  decision: FltDecision;
  flight: HolFlight;
  archived: boolean;
  station: string;
  doneDateTime?: Date;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    if (parseObject && parseObject.get('decision')) {
      this.decision = new FltDecision(parseObject.get('decision'));
      this.createdBy = new HolUser(parseObject.get('decision').get('createdBy'));
      let jsonAttachments = parseObject.get('decision').get('attachments');
      if (typeof jsonAttachments === 'string') {
        jsonAttachments = JSON.parse(jsonAttachments);
      }
      if (jsonAttachments) {
        this.attachments = new HolAttachments(
          jsonAttachments.note,
          jsonAttachments.file,
          jsonAttachments.image,
          jsonAttachments.noteFile,
          jsonAttachments.link,
          jsonAttachments.files,
        );
      } else {
        this.attachments = new HolAttachments();
      }
    }
    this.flight = parseObject.get('flight') && new HolFlight(parseObject.get('flight'));
    this.archived = parseObject.get('archived');
    this.station = parseObject.get('station');
    this.doneDateTime = parseObject.get('doneDateTime');
  }

  protected getContentText(parseObject: Parse.Object) {
    return parseObject.get('decision') ? parseObject.get('decision').get('message') : parseObject.get('message');
  }
}
