import { BaseComponent } from 'src/app/common/components/base/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { HolTag } from '../../models/hol-tag';

@Component({
  selector: 'app-tag-chips',
  templateUrl: './tag-chips.component.html',
  styleUrls: ['./tag-chips.component.scss'],
})
export class TagChipsComponent extends BaseComponent implements OnInit {
  @Input() tags: HolTag[] = [];
  @Input() isShowName = false;

  ngOnInit() {
    if (this.tags) {
      this.tags = this.tags.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
    }
  }
}
