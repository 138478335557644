'use strict';
var env = require('../../environments/environment').environment;
Parse.initialize();
angular
  .module('gestiondecriseApp')
  .factory('customLoader', function ($http, $q, Raven, ReferentialServiceNew) {
    return function (options) {
      const deferred = $q.defer();
      const defaultTranslations = require('../../assets/i18n/locale-' + options.key + '.json');

      ReferentialServiceNew.getTranslations()
        .then(translations => {
          var mergedTranslations = _.merge(defaultTranslations, translations);
          deferred.resolve(mergedTranslations);
        })
        .catch(error => {
          console.warn('TRANSLATE: ', error.toString());
          deferred.resolve(defaultTranslations);
        });

      return deferred.promise;
    };
  })
  .config(function ($translateProvider) {
    $translateProvider.useLoader('customLoader');
    $translateProvider.preferredLanguage('fr_FR');
    $translateProvider.fallbackLanguage('fr_FR');
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
  });
