import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
})
export class ToasterComponent {
  private ypos: MatSnackBarVerticalPosition = 'top';
  private xpos: MatSnackBarHorizontalPosition = 'center';

  constructor(private _snackBar: MatSnackBar) {}

  show(content: string) {
    const params = {
      duration: 8000,
      horizontalPosition: this.xpos,
      verticalPosition: this.ypos,
    };

    this._snackBar.open(content, '', params);
  }
}
