<div class="hol-dialog-wrapper">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.OPERATIONS_STATUS_TITLE' | translate }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="matrix">
      <div class="matrix-legend legend-y">
        <span class="legend-item"></span>
        <span class="legend-item">{{ flightStatusAxis.yAxis1 }}</span>
        <span class="legend-item">{{ flightStatusAxis.yAxis2 }}</span>
        <span class="legend-item">{{ flightStatusAxis.yAxis3 }}</span>
      </div>
      <div class="matrix-row" *ngFor="let line of flightStatusMatrix; let lineIndex = index">
        <div
          class="matrix-cell"
          *ngFor="let status of line; let colIndex = index"
          [class.lowest-criticity]="status.criticity === 0"
          [class.low-criticity]="status.criticity === 1"
          [class.medium-criticity]="status.criticity === 2"
          [class.high-criticity]="status.criticity === 3"
          [class.highest-criticity]="status.criticity === 4"
          [class.disabled]="isReadOnly"
          (click)="addFlightStatusHistory(colIndex, lineIndex, status.criticity)"
        >
          <div *ngIf="flightStatus?.x === colIndex && flightStatus?.y === lineIndex">
            <app-ocl-criticality-loading [readonly]="isReadOnly" [flightStatus]="flightStatus" [noColor]="true">
            </app-ocl-criticality-loading>
          </div>
        </div>
      </div>
      <div class="matrix-legend legend-x">
        <span class="legend-item">{{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.EST_DELAYS' | translate }}</span>
        <span class="legend-item">{{ flightStatusAxis.xAxis1 }}</span>
        <span class="legend-item">{{ flightStatusAxis.xAxis2 }}</span>
        <span class="legend-item">{{ flightStatusAxis.xAxis3 }}</span>
      </div>
    </div>
  </mat-dialog-content>
</div>
