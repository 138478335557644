import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { HolNextInfo } from '../../models/hol-next-info.model';

@Component({
  selector: 'app-hol-next-info-item',
  templateUrl: './hol-next-info-item.component.html',
  styleUrls: ['./hol-next-info-item.component.scss'],
})
export class HolNextInfoItemComponent implements OnInit {
  @Input()
  public item: HolNextInfo;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public isUtc: boolean;
  @Input()
  public canBeDone: boolean;
  @Output()
  public markAsDone = new EventEmitter<HolNextInfo>();

  constructor(@Inject('CONSTANTS') private CONSTANTS) {}

  public ngOnInit(): void {
    if (this.isUtc === undefined) {
      this.isUtc = !this.isReadOnly;
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public triggerMarkAsDone($event): void {
    $event.stopPropagation();
    this.markAsDone.emit(this.item);
  }
}
