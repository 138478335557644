import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';
import { orderBy } from 'lodash';
import { MccAircraft } from '../models/mcc-aircraft.model';
import { MccMel } from '../models/mcc-mel.model';
import { MccMelService } from './mcc-mel.service';
import { MccEventService } from './mcc-event.service';
import { MccCabinService } from './mcc-cabin.service';
import { MccAircraftsStoreManager } from '../store/aircrafts/mcc-aircrafts.store-manager';

@Injectable({
  providedIn: 'root',
})
export class MccAircraftService {
  // tslint:disable-next-line:variable-name
  ParseMCCAirCraft = Parse.Object.extend('MCCAirCraft');

  constructor(
    private requestService: RequestService,
    @Inject('$rootScope') private $rootScope,
    private mccEventsService: MccEventService,
    private mccMELService: MccMelService,
    private mccCabinService: MccCabinService,
    private mccStoreManager: MccAircraftsStoreManager
  ) {}

  getAll(): Promise<MccAircraft[]> {
    const query = new Parse.Query(this.ParseMCCAirCraft);

    return this.requestService.performFindAllQuery(query).then(parseMCCAirCrafts => {
      return Promise.all([this.mccEventsService.getAll(), this.mccMELService.getAll(), this.mccCabinService.getAll()]).then(datas => {
        const parseEvents = datas[0];
        const parseMels = datas[1];
        const parseCabs = datas[2];

        const aircrafts = parseMCCAirCrafts.map(parseAircraft => {
          let events = parseEvents.filter(pe => {
            return pe.aircraft && pe.aircraft.objectId === parseAircraft.id;
          });

          events = orderBy(events, event => {
            const nextInfo = orderBy(event.infos, 'nextInfoTime', 'asc').find(e => !e.done);
            return nextInfo ? nextInfo.nextInfoTime : null;
          });

          const mels = parseMels.filter(pm => {
            return pm.aircraft && pm.aircraft === parseAircraft.get('registration');
          });

          const cabs = this.orderByMostRecentNextInfo(
            parseCabs.filter(pc => {
              return pc.aircraft && pc.aircraft === parseAircraft.get('registration');
            })
          );

          return new MccAircraft(parseAircraft, events, mels, cabs);
        });
        this.mccStoreManager.updateAircrafts(aircrafts);
        return aircrafts;
      });
    });
  }

  private getNextInfo(item) {
    return orderBy(item.infos, 'nextInfoTime', 'asc').find(i => !i.done);
  }

  private orderByMostRecentNextInfo(items) {
    return orderBy(items, item => {
      if (this.getNextInfo(item)) {
        return this.getNextInfo(item).nextInfoTime;
      }
      return null;
    });
  }

  getNearestDueDate(aircraft: MccAircraft): Date {
    if (aircraft.mels.length) {
      return orderBy(aircraft.mels, 'dueDate', 'asc')[0].dueDate;
    }

    return null;
  }

  getNearestMel(aircraft: MccAircraft): MccMel {
    if (aircraft.mels.length) {
      return orderBy(aircraft.mels, 'dueDate', 'asc')[0];
    }

    return null;
  }

  updateAircraftOperationnalStatus(aircraft) {

    const parseAircraft = new this.ParseMCCAirCraft({id: aircraft.objectId});
    parseAircraft.set('isOperational', aircraft.isOperational);

    return new Promise((resolve, reject) =>
      this.requestService.performSaveQuery(parseAircraft,
      null,
      result => {
        this.mccStoreManager.updateOperationalStatus(result);
        return resolve(new MccAircraft(result))
      },
      reject => console.error(reject)
    ));
  }
}
