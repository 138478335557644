import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import {
  InitGlobalInstructions,
  ResetGlobalInstructions,
  UpdateGlobalInstructionsFromPooling,
  AddOneGlobalInstruction,
  UpdateOneGlobalInstruction,
  AddManyGlobalInstructions,
  DeleteOneGlobalInstruction,
} from './ocl-global-instructions.actions';

@Injectable({
  providedIn: 'root',
})
export class OclGlobalInstructionsStoreManager {
  private _globalInstructionsState: Observable<OclGlobalInstruction[]>;
  protected $currentOclModule: Observable<string>;

  constructor(private store: Store<AppState>) {
    this._globalInstructionsState = this.store.select('ocl', 'globalInstructions');
    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  get globalInstructionsState(): Observable<OclGlobalInstruction[]> {
    return this._globalInstructionsState;
  }

  initGlobalInstructions(globalInstructions: Array<OclGlobalInstruction>): void {
    this.store.dispatch(new InitGlobalInstructions(globalInstructions));
  }

  updateGlobalInstructionsFromPooling(globalInstructions: Array<OclGlobalInstruction>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateGlobalInstructionsFromPooling(globalInstructions));
    }
  }

  addOneGlobalInstruction(logBook: OclGlobalInstruction): void {
    this.store.dispatch(new AddOneGlobalInstruction(logBook));
  }

  addManyGlobalInstruction(globalInstructions: OclGlobalInstruction[]): void {
    this.store.dispatch(new AddManyGlobalInstructions(globalInstructions));
  }

  updateOneGlobalInstruction(globalInstruction: OclGlobalInstruction): void {
    this.store.dispatch(new UpdateOneGlobalInstruction(globalInstruction));
  }

  resetGlobalInstructions(): void {
    this.store.dispatch(new ResetGlobalInstructions());
  }

  deleteOneGlobalInstruction(globalInstructionObjectId: string): void {
    this.store.dispatch(new DeleteOneGlobalInstruction(globalInstructionObjectId));
  }
}
