import { RequestService } from './../../common/services/request.service';
import { Injectable } from '@angular/core';
import { HolTask } from '../models/hol-task';

@Injectable({
  providedIn: 'root',
})
export abstract class TaskTagService {
  // tslint:disable:variable-name
  protected abstract ParseTask;
  protected abstract ParseTag;
  protected abstract ParseTaskTag;
  // tslint:enabled

  protected constructor(protected requestService: RequestService) {}

  public updateTags(task: HolTask): Promise<Parse.Object[]> {
    return this.deleteTags(task).then(() => {
      return this.setTags(task);
    });
  }

  private setTags(task: HolTask): Promise<Parse.Object[]> {
    const parseTask = new this.ParseTask({ id: task.objectId });

    if (task.tags) {
      const currentParseTags = task.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseTaskTag();
        joinTableRecord.set('task', parseTask);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private deleteTags(task: HolTask): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseTaskTag);
    const parseTask = new this.ParseTask({ id: task.objectId });
    query.equalTo('task', parseTask);

    return this.requestService.performFindQuery(query).then(parseTags => {
      return this.requestService.performDestroyAllQuery(parseTags);
    });
  }
}
