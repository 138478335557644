import { GocFlightEvent } from './goc-flight-event.model';
import { HolChecklistItem, HolFlight } from '../../common/models/hol-flight.model';
import { GocFlightLogbook } from './goc-flight-logbook.model';
import { GocFlightInstruction } from './goc-flight-instruction.model';
import { GocOfficer } from './goc.officer.model';
import { HolTag } from '../../common/models/hol-tag';

export class GocFlight extends HolFlight<GocFlightLogbook, GocFlightInstruction, GocFlightEvent> {
  mostCriticalInstructionTime: Date;
  hasUncheckedInstructions: boolean;
  doneInstructionSatusDep: string;
  doneInstructionSatusArr: string;
  allInstructionAreDoneArr: boolean;
  noInstructionAreDoneArr: boolean;
  atLeastOneInstructionisDoneArr: boolean;
  flightType: string;
  crewNbPnt: number;
  crewNbPnc: number;
  configuration: string;
  owner: string;
  legVipComments: string;
  naturalKey: string;
  fuelEco: string;
  fuelPolicy: string;
  loadForecast: string;
  gocOfficer: GocOfficer;
  arrAptCategory: string;
  depAptCategory: string;
  primaryArrAlternateAptIataCode: string;
  arrAlternateAptIataCode: string;
  primaryArrAlternateAptIcaoCode: string;
  arrAlternateAptIcaoCode: string;
  depAptRunway: string;
  arrAptRunway: string;
  takeoffEstimatedWeight: string;
  landingEstimatedWeight: string;
  lastLogbookEntry: GocFlightLogbook;
  checklistItemsDep: HolChecklistItem[];
  checklistItemsArr: HolChecklistItem[];
  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.flightType = parseObject.get('flightType');
    this.crewNbPnt = parseObject.get('crewNbPnt');
    this.crewNbPnc = parseObject.get('crewNbPnc');
    this.configuration = parseObject.get('configuration');
    this.owner = parseObject.get('owner');
    this.legVipComments = parseObject.get('legVipComments');
    this.naturalKey = parseObject.get('naturalKey');
    this.fuelEco = parseObject.get('fuelEco');
    this.fuelPolicy = parseObject.get('fuelPolicy');
    this.loadForecast = parseObject.get('loadForecast');
    this.gocOfficer = parseObject.get('gocOfficer') ? new GocOfficer(parseObject.get('gocOfficer')) : undefined;
    this.arrAptCategory = parseObject.get('arrAptCategory');
    this.depAptCategory = parseObject.get('depAptCategory');
    this.primaryArrAlternateAptIataCode = parseObject.get('primaryArrAlternateAptIataCode');
    this.arrAlternateAptIataCode = parseObject.get('arrAlternateAptIataCode');
    this.primaryArrAlternateAptIcaoCode = parseObject.get('primaryArrAlternateAptIcaoCode');
    this.arrAlternateAptIcaoCode = parseObject.get('arrAlternateAptIcaoCode');
    this.depAptRunway = parseObject.get('depAptRunway');
    this.arrAptRunway = parseObject.get('arrAptRunway');
    this.takeoffEstimatedWeight = parseObject.get('takeoffEstimatedWeight');
    this.landingEstimatedWeight = parseObject.get('landingEstimatedWeight');
    this.lastLogbookEntry = null;
    this.checklistItemsDep = parseObject.get('checklistItemsDep');
    this.checklistItemsArr = parseObject.get('checklistItemsArr');
  }
}
