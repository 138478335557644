import { Component, ElementRef, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TirednessType } from '../../models/tiredness-type.model';

@Component({
  selector: 'app-tiredness-indicator',
  templateUrl: './tiredness-indicator.component.html',
  styleUrls: ['./tiredness-indicator.component.scss'],
})
export class TirednessIndicatorComponent implements OnChanges {
  @Input()
  public noteGlobal: any;

  constructor(private el: ElementRef, @Inject('CrewOptionsService') private crewOptionsService) {}

  public getCrewUserTirednessType(): TirednessType {
    const thresholds = this.crewOptionsService.getTirednessThresholds();
    if (thresholds && this.noteGlobal) {
      if (this.noteGlobal < thresholds[0]) {
        return TirednessType.low;
      } else if (this.noteGlobal < thresholds[1] && this.noteGlobal >= thresholds[0]) {
        return TirednessType.medium;
      } else if (this.noteGlobal < thresholds[2] && this.noteGlobal >= thresholds[1]) {
        return TirednessType.high;
      } else {
        return TirednessType.critical;
      }
    }
    return TirednessType.unknown;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.el.nativeElement.style.backgroundColor = this.getCrewUserTirednessType();
  }
}
