import { OclGlobalInstructionsStoreManager } from './../../../ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../../ocl/models/ocl-global-instruction-group.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OclGlobalInstruction } from 'src/app/ocl/models/ocl-global-instruction.model';
import { intersectionBy, List, orderBy } from 'lodash';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { TagChangeService } from '../../../common/services/tag-change.service';
import { CrewNotificationsService } from '../../services/crew-notifications.services';
import { HolNotification } from 'src/app/common/models/hol-notification.model';

@Component({
  selector: 'app-crew-dashboard',
  templateUrl: './crew-dashboard.component.html',
  styleUrls: ['./crew-dashboard.component.sass'],
})
export class CrewDashboardComponent implements OnInit, OnDestroy {
  public globalInstructionGroups: OclGlobalInstructionGroup[];
  public globalInstructions: OclGlobalInstruction[];
  public notifications: HolNotification[];
  private instructionStoreSub: Subscription;
  private groupsStoreManagerSub: Subscription;

  storeSub: Subscription;

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject('$scope') protected $scope,
    public moduleConfig: ModuleConfigService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected groupsStoreManager: OclGroupsStoreManager,
    protected tagsChange: TagChangeService,
    protected notificationSerice: CrewNotificationsService,
  ) {}

  protected _showOnlyIsPinned = new BehaviorSubject<boolean>(false);
  protected _showOnlyIsNotDone = new BehaviorSubject<boolean>(false);

  async ngOnInit() {
    this.instructionStoreSub = this.globalInstructionsStoreManager.globalInstructionsState.subscribe(globalInstructions => {
      this.globalInstructions = orderBy(globalInstructions, 'createdAt', 'desc');
    });

    this.notifications = await this.notificationSerice.getAll();
    this.notifications = this.notifications.map(n => {
      n.sendByMail = true;
      n.sendBySms = true;
      n.disabled = false;
      return n;
    });
    this.groupsStoreManagerSub = this.groupsStoreManager.groupsState.subscribe(groups => {
      this.globalInstructionGroups = groups.globalInstructions;
    });

    this.storeSub = combineLatest(this.onInitCombineObservables()).subscribe(this.onInitSubscriptionFunction.bind(this));
  }

  protected onInitCombineObservables(): Observable<any>[] {
    return [
      this.tagsChange.tagsChanged,
      this._showOnlyIsPinned,
      this._showOnlyIsNotDone,
      this.globalInstructionsStoreManager.globalInstructionsState,
    ];
  }

  protected onInitSubscriptionFunction(objects: any[]) {
    const [tags, showOnlyIsPinned, showOnlyIsNotDone, globalInstructions] = objects;
    // 8 is the number of elements of the base subscription (see above)
    const otherCriteria = objects.slice(4);
    this.globalInstructions = orderBy(
      this.filterByTagOrByPinnedOrByDone(
        globalInstructions,
        tags,
        showOnlyIsPinned,
        showOnlyIsNotDone,
        'GLOBALINSTRUCTIONS',
        otherCriteria,
      ),
      'createdAt',
      'desc',
    );
  }

  protected filterByTagOrByPinnedOrByDone(
    arrayToFilter: any[],
    currentTagFilter: string | List<any>,
    showOnlyIsPinned: boolean,
    showOnlyIsNotDone: boolean,
    type: string,
    otherCriteria: any[] = [],
  ): any[] {
    let bufferDataFiltered =
      currentTagFilter.length && arrayToFilter.length
        ? arrayToFilter.filter(item => {
            const inter = intersectionBy(item.tags, currentTagFilter, (el: any) => {
              return el.objectId;
            });
            return inter.length > 0 && inter.length === currentTagFilter.length;
          })
        : arrayToFilter;

    if (type !== 'EVENTS' && type !== 'GLOBALINSTRUCTIONS' && type !== 'ASSETS') {
      if (showOnlyIsPinned) {
        bufferDataFiltered = bufferDataFiltered.filter(el => el.isPinned);
      }
      if (showOnlyIsNotDone) {
        bufferDataFiltered = bufferDataFiltered.filter(el => el.done === false);
      }
    }
    return this.filterByOtherCriteria(bufferDataFiltered, otherCriteria);
  }

  protected filterByOtherCriteria(arrayToFilter: any[], otherCriteria: any[]): any[] {
    return arrayToFilter;
  }

  ngOnDestroy(): void {
    this.instructionStoreSub.unsubscribe();
    this.groupsStoreManagerSub.unsubscribe();
  }
}
