<div class="next-info-container" *ngIf="nextInfo.nextInfoTime">
  <div [ngClass]="{ 'hol-success': nextInfo.nextInfoDone }" class="col-done">
    <button
      class="next-info-icon"
      [ngClass]="{ success: nextInfo.nextInfoDone }"
      (click)="markAsDone($event)"
      aria-label="mark as done"
      [disabled]="isReadOnly || !canUpdate"
      type="button"
    >
      <span class="hol-icon-done"></span>
    </button>
  </div>
  <div class="hol-spaced-line addon">
    <div class="col-attachements">
      <app-file-and-image-icons-display *ngIf="attachments" [attachments]="attachments"></app-file-and-image-icons-display>
    </div>
    <div class="hol-spaced-line">
      <div
        *ngIf="nextInfo.nextInfoTime"
        [ngClass]="{
          elapsed: isElapsed(nextInfo.nextInfoTime),
          'almost-elapsed': isAlmostElapsed(nextInfo.nextInfoTime),
          'hol-done': nextInfo.nextInfoDone
        }"
        class="col-next-info"
      >
        <span>{{ nextInfo.nextInfoTime | time: isUtc }}</span>
      </div>
    </div>
  </div>
</div>
