import { Injectable } from '@angular/core';

import { RequestService } from 'src/app/common/services/request.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { FltFlightEventService } from 'src/app/flt/services/flt-flight-event.service';

import { GocFlightEvent } from '../../models/goc-flight-event.model';

@Injectable({
  providedIn: 'root',
})
export class GocFlightEventService extends FltFlightEventService<GocFlightEvent> {
  // tslint:disable:variable-name
  protected ParseFlightEvent = Parse.Object.extend('GOCFlightEvents');
  protected ParseFlightEventTag = Parse.Object.extend('GOCFlightEventTag');
  protected ParseEvent = Parse.Object.extend('GOCEvents');
  protected ParseEventTag = Parse.Object.extend('GOCEventTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseEventInfos = Parse.Object.extend('GOCEventInfos');
  protected ParseScenario = Parse.Object.extend('GOCScenario');
  protected ParseFlight = Parse.Object.extend('GOCFlight');

  protected constructor(protected requestService: RequestService) {
    super(requestService);
  }

  public setAdditionalFields(flightEvent: GocFlightEvent, parseFlightEvent: Parse.Object) {
    parseFlightEvent.set('isPrivate', flightEvent.isPrivate);
  }

  protected newFlightEvent(parseObject?: Parse.Object, tags?: Parse.Object[]): GocFlightEvent {
    return new GocFlightEvent(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as GocFlightEvent;
  }
}
