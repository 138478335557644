/* eslint-disable no-case-declarations */
import { EclFunctionState } from '../../ecl.model';
import { EclFunctionActions, EclFunctionActionTypes } from './function.action';
import { cloneDeep } from 'lodash';
import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { EclCrisis } from '../../models/ecl-crisis';

const functionInitialState: Readonly<EclFunctionState> = {
  allUserFunctionsRef: [],
  functions: [],
  currentUserFunctions: [],
  selectedUserFunction: undefined,
  filterFunction: undefined,
  allUserFunctions: [],
  allEclUsers: [],
  isReferential: false,
};

export function eclFunctionsReducer(state: EclFunctionState = functionInitialState, action: EclFunctionActions): EclFunctionState {
  let newState: EclFunctionState;
  let index: number;
  switch (action.type) {
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION_REF:
      break;
    case EclFunctionActionTypes.INIT_ECL_FUNCTION:
      // eslint-disable-next-line no-case-declarations
      const { allFunctions, allUserFunctions, allUsers, currentUserId, allUserFunctionsRef, currentCrisis } = action.payload;
      return generateInitialState(state, allFunctions, allUserFunctions, allUsers, currentUserId, allUserFunctionsRef, currentCrisis);
    case EclFunctionActionTypes.UPDATE_ECL_FUNCTION_REFERENTIAL_MODE:
      return {
        ...cloneDeep(state),
        isReferential: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_CURRENT_USER_FUNCTION:
      return {
        ...cloneDeep(state),
        selectedUserFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_FILTER_FUNCTION:
      return {
        ...cloneDeep(state),
        filterFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_FUNCTION:
      return {
        ...cloneDeep(state),
        teamSelectedFunction: action.payload,
      };
    case EclFunctionActionTypes.UPDATE_ECL_TEAM_USER:
      return {
        ...cloneDeep(state),
        teamSelectedUser: action.payload,
      };
    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTION:
      newState = cloneDeep(state);
      const userFunction = action.payload.userFunction;
      const userId = action.payload.currentUserId;
      newState.allUserFunctions = [...newState.allUserFunctions, userFunction];
      if (userFunction.userId === userId) {
        newState.currentUserFunctions = [...newState.currentUserFunctions, userFunction.function];
      }
      return newState;

    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTIONS:
      newState = cloneDeep(state);
      let eclUserFunctions = action.payload.userFunctions;
      const myUserId = action.payload.currentUserId;

      newState.allUserFunctions = [...newState.allUserFunctions, ...eclUserFunctions];
      const filteredUF = action.payload.userFunctions.filter(uf => uf.userId === myUserId).map(uf => uf.function);
      newState.currentUserFunctions = [...newState.currentUserFunctions, ...filteredUF];

      return newState;

    case EclFunctionActionTypes.ADD_ECL_USER_FUNCTION_REF:
      newState = cloneDeep(state);
      const userFunctionRef = action.payload.userFunctionRef;
      //const currentUserId = action.payload.currentUserId;
      newState.allUserFunctionsRef = [...newState.allUserFunctionsRef, userFunctionRef];

      return newState;
    case EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION:
      newState = cloneDeep(state);
      newState.allUserFunctions = newState.allUserFunctions.filter(uf => uf.objectId !== action.payload.userFunction.objectId);
      //WHAT ??

      if (action.payload.userFunction.userId === action.payload.currentUserId) {
        newState.currentUserFunctions = newState.currentUserFunctions.filter(f => f.functionId !== action.payload.userFunction.functionId);
      }
      return newState;
    case EclFunctionActionTypes.DELETE_ECL_USER_FUNCTION_REF:
      newState = cloneDeep(state);
      newState.allUserFunctionsRef = newState.allUserFunctionsRef.filter(uf => uf.objectId !== action.payload.userFunctionRef.objectId);

      return newState;
    case EclFunctionActionTypes.ADD_ECL_FUNCTION:
      newState = cloneDeep(state);
      newState.functions.push(action.payload);
      return newState;
    case EclFunctionActionTypes.UPDATE_ECL_FUNCTION:
      newState = cloneDeep(state);
      const updatedFunction = action.payload;
      index = newState.functions.findIndex(f => f.functionId === updatedFunction.functionId);
      if (index >= 0) {
        newState.functions[index] = updatedFunction;
      }
      return newState;
    case EclFunctionActionTypes.ADD_ECL_USER:
      newState = cloneDeep(state);
      newState.allEclUsers.push(action.payload);
      return newState;

    case EclFunctionActionTypes.UPDATE_ECL_USER:
      newState = cloneDeep(state);
      const updatedUser = action.payload;
      index = newState.allEclUsers.findIndex(f => f.objectId === updatedUser.objectId);
      if (index >= 0) {
        newState.allEclUsers[index].firstName = updatedUser.firstName;
        newState.allEclUsers[index].lastName = updatedUser.lastName;
        newState.allEclUsers[index].phone = updatedUser.phone;
        newState.allEclUsers[index].isExternal = updatedUser.isExternal;
      }
      return newState;
    case EclFunctionActionTypes.UPDATE_MANY_ECL_USER:
      const eclUsers = state.allEclUsers;
      action.payload.forEach((users: HolUser) => {
        const index = eclUsers.findIndex(c => c.objectId === users.objectId); // pour chaqye crise
        if (index !== -1) {
          eclUsers[index].lastSeenAt = users.lastSeenAt; // user.lastSeenAt
        }
      });
      newState = cloneDeep(state);
      newState.allEclUsers = eclUsers;
      return newState;

    case EclFunctionActionTypes.CHANGE_CRISIS:
      newState = cloneDeep(state);
      const currentUserIdCrisis: string = Parse.User.current().get('userId');
      const functions = cloneDeep(action.payload.baseFs);
      const functionMap = new Map<string, EclFunction>(functions.map(f => [f.functionId, f]));
      const users = cloneDeep(newState.allEclUsers);
      const userMap = new Map<string, HolUser>(users.map(u => [u.userId, u]));
      let userFunctions = cloneDeep(action.payload.eclFunction);
      for (const uf of userFunctions) {
        uf.function = functionMap.get(uf.functionId);
        uf.user = userMap.get(uf.userId);
      }

      userFunctions = userFunctions.filter(uf => !!uf.user && !!uf.function);
      const currentUserFunctions = (userFunctions || [])
        .filter(uf => uf.userId === currentUserIdCrisis)
        .map(uf => uf.function)
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter(value => !value.deleted);

      if (newState.selectedUserFunction === null || newState.selectedUserFunction === undefined) {
        const selectedUserFunction = currentUserFunctions.length === 1 ? currentUserFunctions[0] : null;
        newState.selectedUserFunction = selectedUserFunction;
      } else if (
        currentUserFunctions.findIndex(value => {
          return newState.selectedUserFunction.functionId === value.functionId;
        }) == -1
      ) {
        const selectedUserFunction = currentUserFunctions.length === 1 ? currentUserFunctions[0] : null;
        newState.selectedUserFunction = selectedUserFunction;
      } else if (
        currentUserFunctions.findIndex(value => {
          return newState.selectedUserFunction.functionId === value.functionId;
        }) > -1
      ) {
      } else {
        newState.selectedUserFunction = null;
      }
      newState.currentUserFunctions = currentUserFunctions;
      newState.functions = functions;
      newState.allUserFunctions = userFunctions;

      return newState;
    default:
      return state;
  }
}

function generateInitialState(
  currentState: EclFunctionState,
  baseFs: EclFunction[],
  baseUserFs: EclUserFunction[],
  baseUsers: HolUserWithCompanies[],
  currentUserId: string,
  baseUserFsRef: EclUserFunctionRef[],
  currentCrisis: EclCrisis,
): EclFunctionState {
  const functions = cloneDeep(baseFs);
  const functionMap = new Map<string, EclFunction>(functions.map(f => [f.functionId, f]));
  const users = cloneDeep(baseUsers);
  const userMap = new Map<string, HolUser>(users.map(u => [u.userId, u]));
  let userFunctions = cloneDeep(baseUserFs);
  for (const uf of userFunctions) {
    uf.function = functionMap.get(uf.functionId);
    uf.user = userMap.get(uf.userId);
  }

  userFunctions = userFunctions.filter(uf => !!uf.user && !!uf.function);
  const currentUserFunctions = (userFunctions || [])
    .filter(uf => uf.userId === currentUserId)
    .map(uf => uf.function)
    .filter((value, index, array) => array.indexOf(value) === index)
    .filter(value => !value.deleted);

  //TODO rajouter les function de l'user autoriser à la crise

  const selectedUserFunction = currentUserFunctions.length === 1 ? currentUserFunctions[0] : currentState.selectedUserFunction;

  let userFunctionsRef = cloneDeep(baseUserFsRef);

  for (const uf of userFunctionsRef) {
    uf.function = functionMap.get(uf.functionId);
    uf.user = userMap.get(uf.userId);
  }

  return {
    allUserFunctionsRef: userFunctionsRef, //TODO
    functions: functions,
    allUserFunctions: userFunctions,
    currentUserFunctions: currentUserFunctions,
    selectedUserFunction: selectedUserFunction,
    filterFunction: undefined,
    allEclUsers: users,
    isReferential: currentState.isReferential,
  };
}
