import { Inject, Injectable } from '@angular/core';

import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';
import { MailTemplateService } from 'src/app/common/services/mail/mail-template.service';

import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export class OpsMailService extends OclMailService {
  protected constructor(
    protected readonly mailSenderService: MailSenderService,
    protected translatePipe: TranslatePipe,
    @Inject('$translate') protected $translate,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: OpsModuleConfigService,
  ) {
    super(mailSenderService, translatePipe, $translate, CONSTANTS, mailTemplateService, moduleConfig);
    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.translatePipe.transform('MAIL.OPS.SENDER_SUFFIX') || 'OPS';
    });
  }
}
