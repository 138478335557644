import { EclAssetState } from '../../ecl.model';
import { EclAssetAction, EclAssetActionTypes } from './asset.action';
import { EclAssetType } from '../../models/ecl-asset-type';

export const assetInitialState: Readonly<EclAssetState> = {
  assetTypes: [],
  assets: [],
  selectedAssetType: null,
  assetTypeParameter: [],
};

export function eclAssetReducer(state: EclAssetState = assetInitialState, action: EclAssetAction): EclAssetState {
  let selectedAssetType: EclAssetType = null;
  switch (action.type) {
    case EclAssetActionTypes.INIT_ECL_ASSET:
      // eslint-disable-next-line no-case-declarations
      if (action.assetTypes.length === 1) {
        selectedAssetType = action.assetTypes[0];
      }
      return {
        assetTypes: action.assetTypes,
        assets: action.assets,
        selectedAssetType: selectedAssetType,
        assetTypeParameter: action.assetTypeParameter,
      };
    case EclAssetActionTypes.INIT_ECL_ASSET_LIST_FROM_POLLING:
      return {
        ...state,
        assets: action.assetList,
      };
    case EclAssetActionTypes.SELECT_ECL_CURRENT_ASSET_TYPE:
      return {
        ...state,
        selectedAssetType: action.assetType,
      };
    case EclAssetActionTypes.CREATE_ECL_ASSET:
      return {
        ...state,
        assets: [...state.assets, action.asset],
      };
    case EclAssetActionTypes.UPDATE_ECL_ASSET:
      return {
        ...state,
        assets: [...state.assets.filter(a => a.objectId !== action.asset.objectId), action.asset],
      };
    default:
      return state;
  }
}
