import { RolesService } from 'src/app/common/services/roles.service';
import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { Subscription, BehaviorSubject } from 'rxjs';
import { OclChecklistEvent } from '../../../../ocl/store/ocl-tasks.selectors';
import { ModuleConfigService } from '../../../services/module-config/module-config.service';

@Component({
  selector: 'app-checklist-by-scenario',
  templateUrl: './checklist-by-scenario.component.html',
  styleUrls: ['./checklist-by-scenario.component.scss'],
})
export class ChecklistByScenarioComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public index: number;
  @Input()
  public isReadOnly: boolean;
  @Input()
  isHolder: boolean;

  @Input()
  set checklistInput(value: OclChecklistEvent) {
    this._checklist.next(value);
  }

  get checklistInput(): OclChecklistEvent {
    return this._checklist.getValue();
  }

  public checklist: OclChecklistEvent;
  public checklistSub: Subscription;
  private _checklist = new BehaviorSubject<any>(undefined);
  RolesService = RolesService;

  constructor(@Inject('$state') private $state, public moduleConfig: ModuleConfigService) {
    super();
  }

  public ngOnInit(): void {
    this.checklistSub = this._checklist.subscribe(result => {
      if (!result) {
        return;
      }
      this.checklist = result;
    });
  }

  public ngOnDestroy(): void {
    this.checklistSub.unsubscribe();
  }

  goToTask(taskId: string) {
    this.$state.go('app.' + this.moduleConfig.config.rootPath + '.tasks.details', { taskId });
  }
}
