import { Inject, Injectable } from '@angular/core';

import { UserService } from 'src/app/common/services/user.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FilesService } from 'src/app/common/services/files.service';

import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { FltApplicabilityService } from '../../../flt/services/flt-applicability.service';
import { FltLogbookService } from '../../../flt/services/flt-logbook.service';
import { GocFlightLogbookService } from '../../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { OpsFlightLogbookService } from '../ops-flight-logbook-service/ops-flight-logbook.service';
import { OpsFlightService } from '../ops-flight-service/ops-flight.service';
import { OpsHistoryService } from '../ops-history-service/ops-history.service';
import { OpsLogbookTagService } from '../ops-logbook-tag-service/ops-logbook-tag.service';
import { OpsMailService } from '../ops-mail-service/ops-mail.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';
import { OpsOptionsService } from '../ops-options-service/ops-options.service';
import { OpsSmsService } from '../ops-sms-service/ops-sms.service';

@Injectable({
  providedIn: 'root',
})
export class OpsLogbookService extends FltLogbookService {
  // tslint:disable:variable-name
  protected ParseLogbook = Parse.Object.extend('OPSLogBook');
  protected ParseLogbookTag = Parse.Object.extend('OPSLogBookTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseEvents = Parse.Object.extend('OPSEvents');
  protected ParseFlightLogbook = Parse.Object.extend('OPSFlightLogbook');
  // tslint:enabled

  public constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    protected readonly userService: UserService,
    protected readonly filesService: FilesService,
    protected optionsService: OpsOptionsService,
    protected historyService: OpsHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: OpsLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected logBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: OpsModuleConfigService,
    protected flightService: OpsFlightService,
    protected flightLogbookService: OpsFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected notificationsService: NotificationsService,
    protected smsService: OpsSmsService,
    protected mailService: OpsMailService,
    public commonStoreManager: CommonStoreManager,
  ) {
    super(
      $rootScope,
      requestService,
      filesService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      logBooksStoreManager,
      moduleConfig,
      flightService,
      flightLogbookService,
      markdownService,
      applicabilityService,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager,
    );
  }
}
