'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.component:teamComponent
 * @description
 * # TeamComponent
 */

angular.module('gestiondecriseApp').component('teamComponent', {
  templateUrl: 'views/team.html',
  controller: 'TeamCtrl',
  bindings: {
    context: '<',
  },
});
