<div
  (click)="openDetailsModal($event, item)"
  [style.background-color]="
    !item.linkedData ? '' : (item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]) + '52'
  "
  [style.border-color]="!item.linkedData ? '' : item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]"
  [style.border-style]="item.linkedData ? 'solid' : 'none'"
  [style.border-width]="!item.linkedData ? '' : item.linkedData?.isParent ? '4px' : '2px'"
  class="hol-clickable occ-logbook-item"
>
  <app-hol-list-item
    [fromModule]="fromModule"
    [group]="group"
    [isReadOnly]="isReadOnly"
    [item]="item"
    [toModule]="item.toGOC ? 'GOC' : item.toERP ? 'ERP' : null"
    [utcDisplay]="utcDisplay"
  >
    <button
      (click)="showLinkedItems($event)"
      *ngIf="!isHistory && linkedIds[item.objectId]"
      [disabled]="linkedIds[item.objectId] === 'DISABLED_BUTTON'"
      [ngClass]="{ selected: linkedUid }"
      class="occ-linked-button"
    >
      <img *ngIf="!linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-blue.svg" />
      <img *ngIf="linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-white.svg" />
    </button>
    <div class="header-addon">
      <div>
        <app-tag-chips *ngIf="!item.fromGOC && !item.fromCREW" [isShowName]="false" [tags]="item.tags"></app-tag-chips>
      </div>
      <div *ngIf="item.flight && item.flight.flightNumber" class="item-dasboard-occ-flight-linked">
        <span>{{ item.flight.flightNumber }}/{{ item.flight.std | date: 'ddMMM' }}</span>
      </div>
      <div class="header-right">
        <div *ngIf="item['function'] && item['function'].objectId" style="margin-bottom: 5px">
          <app-ecl-user-function-detail [function]="item['function']"></app-ecl-user-function-detail>
        </div>
        <div *ngIf="item.isPinned" class="pinned"><img class="pinned-item-icon-mini" src="/assets/images/pin--on.png" /></div>
        <div *ngIf="!item.isPinned" class="un-pinned"></div>
      </div>
    </div>

    <div class="hol-spaced-line addon">
      <div class="col-attachements">
        <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
      </div>
      <div
        *ngIf="!isReadOnly && !item.readOnly && canCheckLogbook && !item.fromGOC && !item.fromCREW && !isHistory"
        [class.hol-done]="item.done"
      >
        <strong (click)="toggleDone(item, $event)" *ngIf="item.done">
          {{ item.doneBy.monogram() }} - {{ item.updatedAt | time: isUtc }}
        </strong>
        <button (click)="toggleDone(item, $event)" aria-label="mark as done" class="mat-icon-button">
          <i class="icon icon-check"></i>
        </button>
      </div>
      <div *ngIf="(isReadOnly || item.readOnly) && canCheckLogbook && item.done" class="hol-done">
        <i class="icon icon-check"></i>
        <strong> {{ item.doneBy.monogram() }} - {{ item.updatedAt | time: isUtc }} </strong>
      </div>
      <div
        *ngIf="item.nextInfoDate"
        [class.almost-elapsed]="isAlmostElapsed(item.nextInfoDate)"
        [class.elapsed]="isElapsed(item.nextInfoDate)"
        [class.hol-done]="item.done"
        class="col-next-info"
      >
        <span>{{ item.nextInfoDate | time: isUtc }}</span>
      </div>
    </div>
  </app-hol-list-item>
</div>
