<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span>{{ 'ERP.TASKS.COMMENT_PLACEHOLDER' | translate }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <mat-form-field class="hol-modal-prompt-textarea">
      <textarea
        [formControl]="text"
        matInput
        placeholder="{{ 'ERP.TASKS.COMMENT_PLACEHOLDER' | translate }}"
        required
        rows="3"
        type="text"
      ></textarea>
      <mat-error>{{ 'ERP.TASKS.COMMENT_REQUIRED_ERROR' | translate }}</mat-error>
      <mat-error *ngIf="text.invalid">{{ getErrorMessage() }}</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button (click)="cancel()" class="hol-cancel" mat-raised-button>
      <span>{{ 'COMMON.NO' | translate | uppercase }}</span>
    </button>
    <div class="fill-space"></div>
    <button (click)="valid()" [disabled]="text.invalid" class="hol-validate-btn" mat-raised-button>
      <span>{{ 'COMMON.YES' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
