import { HolObject } from 'src/app/common/models/hol-object';

export class GocOfficer extends HolObject {
  lastName: string;
  firstName: string;
  officerUserId: string;
  userMonogram: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    this.lastName = parseObject.get('lastName');
    this.firstName = parseObject.get('firstName');
    this.officerUserId = parseObject.get('GocOfficerUserId');
    this.userMonogram =
      (this.firstName && this.firstName.length ? this.firstName[0] : '') + (this.lastName && this.lastName.length ? this.lastName[0] : '');
  }
}
