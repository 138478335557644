import { cloneDeep, values, merge, keyBy, isEqual, extend, find, intersectionWith } from 'lodash';
import { OclGlobalInstructionsActionTypes, GlobalInstructionsActions } from './ocl-global-instructions.actions';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';

const globalInstructionInitialState: Array<OclGlobalInstruction> = [];

export function oclGlobalInstructionsReducer(
  state: OclGlobalInstruction[] = cloneDeep(globalInstructionInitialState),
  action: GlobalInstructionsActions
): OclGlobalInstruction[] {
  switch (action.type) {
    case OclGlobalInstructionsActionTypes.INIT_GLOBALINSTRUCTIONS:
      return action.payload;
    case OclGlobalInstructionsActionTypes.ADD_MANY_GLOBALINSTRUCTION:
      const bufferStateForAddMany = cloneDeep(state);
      action.payload.forEach(globalInstruction => {
        if (bufferStateForAddMany.findIndex(el => el.objectId === globalInstruction.objectId) === -1) {
          bufferStateForAddMany.push(globalInstruction);
        }
      });
      return bufferStateForAddMany;
    case OclGlobalInstructionsActionTypes.UPDATE_MANY_GLOBALINSTRUCTIONS:
      return values(merge(keyBy(cloneDeep(state), 'objectId'), keyBy(action.payload, 'objectId')));
    case OclGlobalInstructionsActionTypes.UPDATE_GLOBALINSTRUCTIONS_FROM_POOLING:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        // console.log('UPDATE NEEDED : POOL AND STORE ARE DIFFERENT');
        // console.log('state', state);
        // console.log('payload', action.payload);
        return [...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId')))];
      }
    case OclGlobalInstructionsActionTypes.ADD_ONE_GLOBALINSTRUCTION:
      const bufferState = cloneDeep(state);
      bufferState.unshift(action.payload);
      return bufferState;
    case OclGlobalInstructionsActionTypes.UPDATE_ONE_GLOBALINSTRUCTION:
      return cloneDeep(state).map(globalInstruction =>
        globalInstruction.objectId === action.payload.objectId ? action.payload : globalInstruction
      );
    case OclGlobalInstructionsActionTypes.RESET_GLOBALINSTRUCTIONS:
      return cloneDeep(globalInstructionInitialState);
    case OclGlobalInstructionsActionTypes.DELETE_ONE_GLOBALINSTRUCTION:
      if (action.globalInstructionObjectId && cloneDeep(state).findIndex(el => el.objectId === action.globalInstructionObjectId) !== -1) {
        return cloneDeep(state).filter(el => el.objectId !== action.globalInstructionObjectId);
      } else {
        return cloneDeep(state);
      }
    default:
      return state;
  }
}
