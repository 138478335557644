import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../common/components/base/base.component';
import { EclFunction } from '../../models/ecl-function';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';

@Component({
  selector: 'app-ecl-user-function-detail',
  template: `<div class="box" [class.filter]="activeFilter" (click)="toggleSelected($event)">
    <span>{{ function.shortTitle || function.title }}</span>
  </div>`,
  styleUrls: ['./ecl-user-function-detail.component.scss'],
})
export class EclUserFunctionDetailComponent extends BaseComponent {
  @Input()
  public function?: EclFunction = undefined;
  private filterFunction?: EclFunction;

  constructor(private functionStoreManager: EclFunctionStoreManager) {
    super();
  }

  ngOnInit() {
    this.functionStoreManager.$filterFunction.subscribe(ff => (this.filterFunction = ff));
  }

  public get activeFilter(): boolean {
    return this.filterFunction && this.filterFunction.functionId === this.function.functionId;
  }

  public toggleSelected($event) {
    $event.stopPropagation();
    const newFunction: EclFunction = this.activeFilter ? undefined : this.function;
    this.functionStoreManager.updateFilterFunction(newFunction);
  }
}
