<div (click)="addAttachments($event)" class="item hol-clickable">
  <div class="item__container">
    <div class="left">
      <span class="item-label">{{ checklistItem.label }}</span>
      <div class="attachments">
        <app-file-and-image-icons-display [attachments]="checklistItem.attachments"></app-file-and-image-icons-display>
      </div>
    </div>
    <div class="right">
      <div *ngIf="!checklistItem.status; else statusDefinedTemplate" class="actions">
        <button
          *ngIf="!isReadOnly"
          class="item-checklist-icon"
          (click)="statusItemUpdate($event, VACATION_CHECKLIST_ITEM_STATUS.NOT_APPLICABLE)"
          type="button"
        >
          <span class="hol-icon-close"></span>
        </button>

        <button
          *ngIf="!isReadOnly"
          class="item-checklist-icon"
          (click)="statusItemUpdate($event, VACATION_CHECKLIST_ITEM_STATUS.DONE)"
          type="button"
        >
          <span class="hol-icon-done"></span>
        </button>
      </div>
      <ng-template #statusDefinedTemplate>
        <div
          class="actions"
          [ngClass]="[checklistItem.status === VACATION_CHECKLIST_ITEM_STATUS.DONE ? 'checklist-done' : 'not-applicable']"
        >
          <button [disabled]="isReadOnly" class="item-checklist-icon" (click)="statusItemUpdate($event, null)" type="button">
            <span [ngClass]="[checklistItem.status === VACATION_CHECKLIST_ITEM_STATUS.DONE ? 'hol-icon-done' : 'hol-icon-close']"></span>
          </button>
        </div>
        <div
          class="details"
          [ngClass]="[checklistItem.status === VACATION_CHECKLIST_ITEM_STATUS.DONE ? 'details-done' : 'details-not-applicable']"
        >
          <span> {{ checklistItem.updatedBy.monogram() }} </span>
          <span> {{ checklistItem.statusDate | time: isUtc }} </span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
