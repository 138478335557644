import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { HolUser } from '../../../common/models/hol-user.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import {
  HolManagerCompany,
  HolManagerFunction,
  HolManagersSelectors,
  HolUserFunctionWithUser,
} from '../../../common/store/hol-managers.selectors';
import { HolOptionsService } from '../../../common/services/hol-options.service';
import { EclOptionsService } from '../../services/ecl-options-service/ecl-options.service';
import { map } from 'rxjs/operators';
import { intersection } from 'lodash';
import { CrisisRolesRef } from '../../../common/services/functions.service';
import { EclCrisisDirector } from '../../common/ecl.entities';
import { HolUserWithFunctions } from '../../../erp/services/erp-users.service';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisDirectorStoreManager {
  private $currentUser: Observable<HolUser>;

  constructor(
    private store: Store<AppState>,
    private managersSelector: HolManagersSelectors,
    private holOptionsService: HolOptionsService,
    private eclOptionsService: EclOptionsService,
  ) {
    this.$currentUser = this.store.select('common', 'currentUser');
  }

  get $eclCrisisDirectorPermissions(): Observable<EclCrisisDirector[]> {
    return this.$managersFunctions(false).pipe(
      map(managerFunction =>
        this.getECLCrisisDirectors(managerFunction, this.holOptionsService.getFunctionsIdsForManagerByModule('ECL'), false),
      ),
    );
  }

  get currentUserEclCrisisRolesRef(): Observable<CrisisRolesRef> {
    return combineLatest([this.$currentUser, this.$eclCrisisDirectorPermissions]).pipe(
      map(([currentUser, directors]) => {
        const isDirector = directors.some(dir => dir.userId === currentUser.userId);
        return {
          isInCrisisDirectorTeam: isDirector,
          isInCrisisInitializerTeam: isDirector,
        };
      }),
    );
  }

  $managersFunctions(filterFromCompanyRoles: boolean): Observable<HolManagerFunction[]> {
    return this.managersSelector.getManagersFunctions(filterFromCompanyRoles, 'ecl');
  }

  $eclRealCrisisDirectors(filterFromCompanyRoles = false): Observable<EclCrisisDirector[]> {
    // First, verify that list defined in EclOptions intersects with HolOptions list
    //  const holList = this.holOptionsService.getFunctionsIdsForManagerByModule('ECL');
    const list = this.eclOptionsService.getFunctionIdCrisisForCrisisDirector();
    //  const list = intersection(holList, eclList);
    // Call getECLCrisisDirectors() function with given list
    return this.$managersFunctions(filterFromCompanyRoles).pipe(
      map(managerFunctions => (list.length ? this.getECLCrisisDirectors(managerFunctions, list, true) : [])),
    );
  }

  $eclRealCrisisDirectorsWithFunctions(filterFromCompanyRoles = false): Observable<HolUserWithFunctions[]> {
    // First, verify that list defined in EclOptions intersects with HolOptions list
    const holList = this.holOptionsService.getFunctionsIdsForManagerByModule('ECL');
    const eclList = this.eclOptionsService.getFunctionIdCrisisForCrisisDirector();
    const list = intersection(holList, eclList);
    // Call getECLCrisisDirectors() function with given list
    return this.$managersFunctions(filterFromCompanyRoles).pipe(
      map(managerFunctions => (list.length ? this.getECLRealCrisisDirectorsAndFunctions(managerFunctions, list) : [])),
    );
  }

  private getECLRealCrisisDirectorsAndFunctions(
    managerFunctions: HolManagerFunction[],
    moduleManagerFunctions: string[],
  ): HolUserWithFunctions[] {
    const intermediateArray: HolManagerFunction[] = managerFunctions.filter(mFunc => moduleManagerFunctions.includes(mFunc.functionId));
    //   .reduce((prev, curr) => [...prev, ...curr.companies], new Array<HolManagerCompany>());
    const map = new Map<string, HolUserWithFunctions>();

    for (const func of intermediateArray) {
      for (const company of func.companies) {
        for (const user of company.users.map(u => u.user)) {
          const userId = user.userId;
          let userWithFunctions = map.get(userId);
          if (!userWithFunctions) {
            userWithFunctions = {
              ...user,
              functions: [],
            };
          }
          if (!userWithFunctions.functions.some(f => f.functionId === func.functionId && f.company === company.name)) {
            userWithFunctions.functions.push({
              ...func,
              company: company.name,
              tasksSummary: '',
              otherUsers: company.users.filter(u => u.user && u.user.userId !== user.userId).map(u => u.user.fullName),
            });
          }
          map.set(user.userId, userWithFunctions);
        }
      }
    }
    return [...map.values()];
  }

  private getECLCrisisDirectors(
    managerFunctions: HolManagerFunction[],
    moduleManagerFunctions: string[],
    onlyHolders: boolean,
  ): EclCrisisDirector[] {
    const intermediateArray: HolManagerCompany[] = managerFunctions
      .filter(mFunc => moduleManagerFunctions.includes(mFunc.functionId))
      .reduce((prev, curr) => [...prev, ...curr.companies], new Array<HolManagerCompany>());
    const map = new Map<string, EclCrisisDirector>();

    for (const company of intermediateArray) {
      let usersToIterate: HolUserFunctionWithUser[] = [];
      const companyName = company.name;
      if (onlyHolders && company.holder) {
        usersToIterate = [company.holder];
      } else if (!onlyHolders) {
        usersToIterate = company.users || [];
      }
      for (const user of usersToIterate) {
        const userId = user.userId;
        let crisisDirector = map.get(userId);
        if (crisisDirector) {
          if (crisisDirector.companies && !crisisDirector.companies.includes(companyName)) {
            crisisDirector.companies.push(companyName);
          }
        } else {
          crisisDirector = user.user as EclCrisisDirector;
          crisisDirector.companies = [companyName];
          crisisDirector.functionId = user.functionId;
          map.set(userId, crisisDirector);
        }
      }
    }
    return [...map.values()];
  }
}
