import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { VacationService } from 'src/app/common/services/vacation.service';
import { HolVacationChecklistItem } from 'src/app/common/models/hol-vacation.model';

@Component({
  selector: 'app-checklists-item-edit',
  templateUrl: './checklists-item-edit.component.html',
  styleUrls: ['./checklists-item-edit.component.scss'],
})
export class ChecklistsItemEditComponent {
  modalTitle: string;
  item: HolVacationChecklistItem;
  context: { module: string; action: string };
  isReadOnly: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ChecklistsItemEditComponent>,
    private vacationService: VacationService
  ) {
    this.modalTitle = data.title;
    this.item = data.item;
    this.context = data.context;
    this.isReadOnly = data.isReadOnly;
  }

  save(): void {
    this.vacationService.attachmentsItemUpdate(this.item, this.item.attachments).then(result => {
      this.item = result;
      this.dialogRef.close(this.item);
    });
  }
}
