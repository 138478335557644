<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span *ngIf="isCreate" class="title">{{
        moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.ADD_INSTRUCTION' | translate
      }}</span>
      <!--<span *ngIf="!isCreate" class="title">{{ globalInstuction.createdAt | time: !isReadOnly }} : {{ globalInstuction.description }}</span>-->
      <span *ngIf="!isCreate" class="title"
        >{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.TITLE_INSTRUCTION' | translate }}
        {{ globalInstuction.createdAt | time: !isReadOnly }}</span
      >
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="hol-dialog-section" *ngIf="isCreate">
        <mat-form-field *ngIf="isCreate" class="full-width description-form-field">
          <textarea
            formControlName="description"
            maxlength="200"
            placeholder="{{ 'GOC.MODALS.COMMON.DESCRIPTION' | translate }}"
            type="text"
            mat-autosize
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
      <!-- TITLE -->
      <mat-form-field *ngIf="!isCreate" class="full-width">
        <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.TITLE_PLACEHOLDER' | translate }}</mat-label>
        <textarea matInput class="hol-modal-title" mat-autosize formControlName="description" type="text" maxlength="200"></textarea>
      </mat-form-field>
      <!-- LINKED GROUP -->
      <app-group-selector
        [type]="'globalInstuction'"
        [isReadOnly]="isReadOnly"
        [listGroup]="globalInstGroup"
        (groupChange)="updateLinkedGroup($event)"
      ></app-group-selector>

      <div class="hol-dialog-section">
        <app-file-and-image-upload
          [attachments]="attachments"
          [disabled]="isReadOnly"
          [context]="{
            module: moduleConfig.config.translateKey,
            category: 'GLOBALCONSIGNE',
            htmlTitle: form.value.description,
            htmlDate: globalInstuction?.createdAt,
            htmlTemplate: 'A'
          }"
        >
        </app-file-and-image-upload>
      </div>
      <div *ngIf="notifications && notifications.length > 0" class="hol-dialog-section">
        <div class="modal-column">
          <label>{{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}</label>
          <app-notification-table
            [notifications]="notifications"
            [context]="context"
            (saveNotifications)="saveNotifications($event)"
            [unselectAll]="true"
            [acl]="globalInstuction.acl"
          ></app-notification-table>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="bottom-modal">
      <app-acl-selector
        class="acl-full-width"
        *ngIf="!isReadOnly"
        [readOnly]="isReadOnly"
        [moduleName]="moduleConfig.config.moduleName"
        [object]="globalInstuction"
        [formGroup]="form"
        [limitTo]="limitTo && limitTo.acl"
      ></app-acl-selector>
      <!-- TAGS -->
      <div class="hol-dialog-section">
        <app-tag-selector [readOnly]="isReadOnly" [(selectedTags)]="tags" class="tag-selector"> </app-tag-selector>
      </div>
    </div>
    <div class="hol-modal__actions">
      <button mat-stroked-button color="warn" *ngIf="!isReadOnly && !isCreate && !globalInstuction.archived" (click)="archive()">
        <i class="icon fa fa-archive"></i>
        {{ 'COMMON.BUTTON.ARCHIVE' | translate }}
      </button>
      <div class="fill-space"></div>
      <button class="hol-validate-btn" *ngIf="!isReadOnly" mat-raised-button (click)="save()" [disabled]="form.invalid">
        <mat-icon>done</mat-icon>
        <span *ngIf="isCreate">{{ 'COMMON.BUTTON.ADD' | translate | uppercase }}</span>
        <span *ngIf="!isCreate">{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
      </button>
      <button class="hol-validate-btn" mat-raised-button *ngIf="isReadOnly" mat-dialog-close>
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-dialog-actions>
</div>
