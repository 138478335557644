import { RequestService } from 'src/app/common/services/request.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserFailingToLoginService {
  // tslint:disable-next-line:variable-name
  ParseUserFailingToLogin = Parse.Object.extend('UserFailingToLogin');

  constructor(private requestService: RequestService) {}

  checkUserFailingToLogin(username: string, error: any): Promise<Parse.Object | void> {
    return this.requestService.performCloudCode('checkUserFailingToLogin', { username: username, error: error });
  }

  clearUserFailingToLogin(username: string): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseUserFailingToLogin);
    query.equalTo('username', username);
    return this.requestService.performFindQuery(query).then(parseTags => {
      return this.requestService.performDestroyAllQuery(parseTags);
    });
  }
}
