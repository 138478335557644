import { HolUser } from './hol-user.model';
import { HolAttachments } from './hol-attachments.model';

export class HolNextInfo {
  objectId: string;
  message: string;
  nextInfoTime: Date;
  createdAt: Date;
  done: boolean;
  manual: boolean;
  updatedAt: Date;
  attachments: HolAttachments;
  createdBy: HolUser;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.message = parseObject.get('message');
    this.nextInfoTime = parseObject.get('nextInfoTime');
    this.createdAt = parseObject.get('createdAt');
    this.updatedAt = parseObject.get('updatedAt');
    this.done = parseObject.get('done') || false;
    this.manual = parseObject.get('manual') || false;

    let jsonAttachments = parseObject.get('attachments');
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(parseObject.get('attachments'));
    }

    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files
      );
    } else {
      this.attachments = new HolAttachments();
    }

    this.createdBy = new HolUser(parseObject.get('createdBy'));
  }
}
