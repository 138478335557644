import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import {
  AddEvent,
  UpdateOperationalStatus,
  UpdateAircrafts,
  UpdateEvent,
  UpdateMel,
  UpdateMelInfo,
  UpdateEventInfo,
  RemoveEvent,
  AddItemCabin,
  UpdateItemCabin,
  RemoveItemCabin,
  UpdateItemCabinInfo,
} from './mcc-aircrafts.actions';
import { Injectable } from '@angular/core';
import { MccAircraft } from '../../models/mcc-aircraft.model';
import { MccMel } from '../../models/mcc-mel.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { MccEvent } from '../../models/mcc-event.model';
import { MccCabin } from '../../models/mcc-cabin.model';

@Injectable({
  providedIn: 'root',
})
export class MccAircraftsStoreManager {
  protected _aircraftsState: Observable<MccAircraft[]>;

  protected constructor(protected store: Store<AppState>) {
    this._aircraftsState = store.select('mcc', 'aircrafts');
  }

  get aircraftsState(): Observable<MccAircraft[]> {
    return this._aircraftsState;
  }

  updateAircrafts(aircrafts: Array<MccAircraft>): void {
    this.store.dispatch(new UpdateAircrafts(aircrafts));
  }

  updateOperationalStatus(aircraft: MccAircraft): void {
    this.store.dispatch(new UpdateOperationalStatus(aircraft));
  }

  updateMel(mel: MccMel): void { 
    this.store.dispatch(new UpdateMel(mel));
  }

  updateMelInfo(info: HolNextInfo): void {
    this.store.dispatch(new UpdateMelInfo(info));
  }

  addEvent(event: MccEvent): void {
    this.store.dispatch(new AddEvent(event));
  }

  updateEvent(event: MccEvent) {
    this.store.dispatch(new UpdateEvent(event));
  }

  updateEventInfo(info: HolNextInfo) {
    this.store.dispatch(new UpdateEventInfo(info));
  }

  removeEvent(event: MccEvent) {
    this.store.dispatch(new RemoveEvent(event));
  }

  addItemCabin(cabin: MccCabin): void {
    this.store.dispatch(new AddItemCabin(cabin));
  }

  updateItemCabin(cabin: MccCabin) {
    this.store.dispatch(new UpdateItemCabin(cabin));
  }

  updateItemCabinInfo(info: HolNextInfo) {
    this.store.dispatch(new UpdateItemCabinInfo(info));
  }

  removeItemCabin(cabin: MccCabin) {
    this.store.dispatch(new RemoveItemCabin(cabin));
  }
}
