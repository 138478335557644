import { Inject, Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import * as moment from 'moment';

import { SmsSenderService, ISmsMessage } from './../../common/services/sms/sms-sender.service';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { HelperService } from '../../common/services/helper.service';
import { HolUser, HolUserWithCompanies } from '../../common/models/hol-user.model';
import { ErpUsersService, HolUserWithFunctions } from './erp-users.service';
import { ErpCrisis } from '../models/erp-crisis';
import { ErpCrisisTask } from 'src/app/erp/models/erp-crisisTask';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { ErpFunctionsService } from './erp-functions.service';

@Injectable({
  providedIn: 'root',
})
export class ErpSmsService {
  constructor(
    private readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') private CONSTANTS,
    @Inject('$translate') private $translate: any,
    private helperService: HelperService,
    private usersService: ErpUsersService,
    private commonStoreManager: CommonStoreManager,
    private erpFunctionsService: ErpFunctionsService,
  ) {}

  sendCrisisNewSms(crisisNew: ErpCrisisNew, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisNewMessage(crisisNew),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  sendCrisisAnnouncementSms(crisisAnnouncement: ErpCrisisAnnouncement, user, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisAnnouncementMessage(crisisAnnouncement, user),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  sendCrisisTaskDoneSms(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisTaskDoneMail(crisisTask, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisTaskDoneMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.CRISIS_TASK_TO_DONE.SUBJECT') + ' ';
    msg += this.$translate.instant('SMS.CRISIS_TASK_TO_DONE.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskTitle: crisisTask.taskTitle,
      functionTitle,
      taskResult: crisisTask.outputTitle,
    });
    if (comment) {
      msg += this.$translate.instant('SMS.CRISIS_TASK_TO_DONE.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }

  sendCrisisTaskFrozenSms(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisTaskFrozenMail(crisisTask, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisTaskFrozenMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.CRISIS_TASK_TO_FROZEN.SUBJECT') + ' ';
    msg += this.$translate.instant('SMS.CRISIS_TASK_TO_FROZEN.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskTitle: crisisTask.taskTitle,
      functionTitle,
      taskResult: crisisTask.outputTitle,
      comment,
    });
    return msg;
  }

  sendCrisisTaskBackTodoSms(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisTaskBackTodoMail(crisisTask, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisTaskBackTodoMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.CRISIS_TASK_TO_TODO.SUBJECT');
    msg += this.$translate.instant('SMS.CRISIS_TASK_TO_TODO.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskTitle: crisisTask.taskTitle,
      functionTitle,
      taskResult: crisisTask.outputTitle,
    });
    if (comment) {
      msg += this.$translate.instant('SMS.CRISIS_TASK_TO_TODO.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }

  sendCrisisTaskSms(isCreate: boolean, crisisTask: ErpCrisisTask, phoneNumbersToSend: string[], functionToNotify?: string) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisTaskSms(isCreate, crisisTask, functionToNotify),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisTaskSms(isCreate: boolean, crisisTask: ErpCrisisTask, functionToNotify?: string) {
    let msg = isCreate
      ? this.$translate.instant('SMS.CRISIS_NEW_TASK.NEW_ENTRY') + ' '
      : this.$translate.instant('SMS.CRISIS_TASK.NEW_ENTRY', { crisisNew: crisisTask.taskTitle });
    if (isCreate) {
      msg += this.$translate.instant('SMS.CRISIS_NEW_TASK.FISRT_PART', {
        firstName: crisisTask.createdBy.firstName,
        lastName: crisisTask.createdBy.lastName,
        taskTitle: crisisTask.taskTitle,
        functionToNotify,
      });
      if (crisisTask.nextInfoTime) {
        msg += this.$translate.instant('SMS.CRISIS_NEW_TASK.SECOND_PART') + ' ';
        msg += moment(crisisTask.nextInfoTime).utc().format('HH:mm[UTC] ddd[ ]DD');
      }
    }
    return msg;
  }

  sendCrisisDocumentSms(isCreate: boolean, crisisDocument: ErpCrisisTask, phoneNumbersToSend: string[], functionToNotify?: string) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisDocumentSms(isCreate, crisisDocument, functionToNotify),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisDocumentSms(isCreate: boolean, crisisTask: ErpCrisisTask, functionToNotify?: string): string {
    let msg = isCreate
      ? this.$translate.instant('SMS.CRISIS_DOCUMENT.NEW_ENTRY_SUBJECT') + ' '
      : this.$translate.instant('SMS.CRISIS_DOCUMENT.UPDATE_ENTRY_SUBJECT') + ' ';
    msg += isCreate
      ? this.$translate.instant('SMS.CRISIS_DOCUMENT.CREATED_BY', {
          firstName: crisisTask.createdBy.firstName,
          lastName: crisisTask.createdBy.lastName,
          title: crisisTask.outputDataLabel,
        })
      : this.$translate.instant('SMS.CRISIS_DOCUMENT.UPDATED_BY', {
          firstName: crisisTask.updatedBy.firstName,
          lastName: crisisTask.updatedBy.lastName,
          title: crisisTask.outputDataLabel,
        });
    if (functionToNotify) {
      msg += ' ';
      msg += this.$translate.instant('SMS.CRISIS_DOCUMENT.AFFECT_TO_FUNCTION', { functionToNotify });
    }
    if (crisisTask.nextInfoTime) {
      msg += ' ';
      msg += this.$translate.instant('SMS.CRISIS_DOCUMENT.NEXT_INFO') + ' ';
      msg += moment(crisisTask.nextInfoTime).utc().format('HH:mm[UTC] ddd[ ]DD');
    }
    return msg;
  }

  sendCrisisDecisionSms(isCreate: boolean, crisisDecision: ErpCrisisDecision, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getCrisisDecisionSms(isCreate, crisisDecision),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  getCrisisDecisionSms(isCreate: boolean, crisisDecision: ErpCrisisDecision): string {
    let msg = isCreate
      ? this.$translate.instant('SMS.CRISIS_DECISION.NEW_ENTRY_SUBJECT') + ' '
      : this.$translate.instant('SMS.CRISIS_DECISION.UPDATE_ENTRY_SUBJECT') + ' ';
    msg += isCreate
      ? this.$translate.instant('SMS.CRISIS_DECISION.CREATED_BY', {
          firstName: crisisDecision.createdBy.firstName,
          lastName: crisisDecision.createdBy.lastName,
          title: crisisDecision.title,
        })
      : this.$translate.instant('SMS.CRISIS_DECISION.UPDATED_BY', {
          firstName: crisisDecision.updatedBy.firstName,
          lastName: crisisDecision.updatedBy.lastName,
          title: crisisDecision.title,
        });
    if (crisisDecision.nextInfoTime) {
      msg += ' ';
      msg += this.$translate.instant('SMS.CRISIS_DECISION.NEXT_INFO') + ' ';
      msg += moment(crisisDecision.nextInfoTime).utc().format('HH:mm[UTC] ddd[ ]DD');
    }
    return msg;
  }

  sendNewCrisisInPreparationSms(crisis: ErpCrisis) {
    Promise.all([
      this.usersService.getAllCrisisDirectorsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(([crisisDirectorsToNotify, currentUser]) => {
      const messages: ISmsMessage[] = [];
      for (const recipient of crisisDirectorsToNotify) {
        if (recipient.phone) {
          messages.push({
            to: recipient.phone,
            text: this.$translate.instant('SMS.CRISIS_PREPARED', {
              crisisCreatorName: currentUser.fullName,
              phone: currentUser.phone,
              appUrl: location.origin,
            }),
          });
        }
      }
      this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
    });
  }

  sendNewCrisisSms(crisis: ErpCrisis, functionsIdsToNotify?: string[], usersToNotify?: HolUser[], companies?: string[]) {
    return Promise.all([
      this.usersService.getUsersWithFunctionsForCrisis(crisis, functionsIdsToNotify, usersToNotify, companies),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(async ([usersWithFunctions, currentUser]) => {
      const messages: ISmsMessage[] = [];
      for (const user of usersWithFunctions.users) {
        if (user.phone) {
          messages.push({
            to: user.phone,
            text: this.getNewCrisisMessage(crisis, user, currentUser),
          });
        }
      }
      await this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
      return this.erpFunctionsService.markAsNotified(usersWithFunctions.functionsIdsNotified);
    });
  }

  sendCrisisOverSms(crisis: ErpCrisis) {
    this.usersService.getUsersToSendMail().then((usersToNotify: HolUserWithCompanies[]) => {
      const companies = this.helperService.parseACL(crisis.acl);
      usersToNotify = usersToNotify.filter(d => !!d.companies.find(c => companies.includes(c.name)));

      const messages: ISmsMessage[] = [];
      for (const user of usersToNotify) {
        if (user.phone) {
          messages.push({
            to: user.phone,
            text: this.$translate.instant('SMS.CRISIS_OVER.CONTENT_TEXT'),
          });
        }
      }
      this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
    });
  }

  private getCrisisNewMessage(crisisNew: ErpCrisisNew): string {
    return this.$translate.instant('SMS.CRISIS_NEW.NEW_ENTRY', { crisisNew: crisisNew.content });
  }

  private getCrisisAnnouncementMessage(crisisAnnouncement: ErpCrisisAnnouncement, user: any): string {
    return this.$translate.instant('SMS.CRISIS_ANNOUNCEMENT.NEW_ENTRY', { crisisAnnouncement: crisisAnnouncement.message, user });
  }

  private getNewCrisisMessage(crisis: ErpCrisis, user: HolUserWithFunctions, currentUser: HolUser) {
    const separator = '\n';
    let msg =
      (crisis.isTraining ? this.$translate.instant('SMS.NEW_CRISIS.EXERCISE') : this.$translate.instant('SMS.NEW_CRISIS.NOT_EXERCISE')) +
      separator +
      separator;
    msg += this.$translate.instant('SMS.NEW_CRISIS.ACTIVATION', { erdUserName: currentUser.fullName, crisis }) + separator + separator;

    msg +=
      this.$translate.instant('SMS.NEW_CRISIS.FIRST_ACTIONS', {
        appUrl: location.origin,
        erdPhone: currentUser.phone || '',
        erdUserName: currentUser.fullName,
      }) +
      separator +
      separator;

    msg += this.$translate.instant('SMS.NEW_CRISIS.ADVICES', { appUrl: location.origin });
    return msg;
  }
}
