import { HolFlight } from '../../common/models/hol-flight.model';

export class FltApplicability {
  flights: HolFlight[];
  flightsNumbers: string[];
  flightsDirection: 'DEP' | 'ARR' | null;
  stations: string[];
  inOutStations: [string, string][];
  stationsDirection: 'IN' | 'OUT' | null;
  validFrom: Date;
  validTo: Date;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.flights = parseObject.get('applFlights') ? parseObject.get('applFlights').map(pf => new HolFlight(pf)) : [];
    this.flightsNumbers = parseObject.get('applFlightsNumber');
    this.stations = parseObject.get('applStations');
    this.flightsDirection = parseObject.get('applFlightsDirection');
    this.stationsDirection = parseObject.get('applStationsDirection');
    if (parseObject.get('applInOutStations')) {
      this.inOutStations = parseObject.get('applInOutStations').map(inOutStation => {
        return inOutStation.split('-');
      });
    }

    this.validFrom = parseObject.get('validFrom');
    this.validTo = parseObject.get('validTo');
  }

  public updateParseObject(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    if (this.flights && this.flights.length) {
      // tslint:disable-next-line:variable-name
      const ParseFlight = Parse.Object.extend('GOCFlight');
      parseObject.set(
        'applFlights',
        this.flights.map(f => new ParseFlight({ id: f.objectId }))
      );
    } else {
      parseObject.unset('applFlights');
    }
    if (this.inOutStations) {
      parseObject.set(
        'applInOutStations',
        this.inOutStations.map(s => s[0] + '-' + s[1])
      );
    } else {
      parseObject.unset('applInOutStations');
    }
    if (this.stations && this.stations.length) {
      parseObject.set('applStations', this.stations);
    } else {
      parseObject.unset('applStations');
    }
    if (this.flightsNumbers && this.flightsNumbers.length) {
      parseObject.set('applFlightsNumber', this.flightsNumbers);
    } else {
      parseObject.unset('applFlightsNumber');
    }
    parseObject.set('applFlightsDirection', this.flightsDirection);
    parseObject.set('applStationsDirection', this.stationsDirection);
    if (this.validFrom) {
      parseObject.set('validFrom', this.validFrom);
    } else {
      parseObject.unset('validFrom');
    }
    if (this.validTo) {
      parseObject.set('validTo', this.validTo);
    } else {
      parseObject.unset('validTo');
    }
  }
}
