'use strict';

angular.module('gestiondecriseApp').component('crewDashboardComponent', {
  templateUrl: 'views/crew/dashboard.html',
  controller: 'CrewDashboardCtrl',
  bindings: {
    instructions: '<',
    instructionGroups: '<',
    notifications: '<'
  }
});
