import { Action } from '@ngrx/store';
import { OclDecision } from '../../models/ocl-decision.model';

export enum OclDecisionsActionTypes {
  INIT_DECISIONS = '[OCL DECISIONS] Init Decisions',
  RESET_DECISIONS = '[OCL DECISIONS] Reset Decisions',
  UPDATE_ONE_DECISION = '[OCL DECISIONS] Update One Decision',
  UPDATE_MANY_DECISIONS = '[OCL DECISIONS] Update Many Decisions',
  UPDATE_DECISIONS_FROM_POOLING = '[OCL DECISIONS] Update Decisions From Pooling',
  UPDATE_DECISIONS_FROM_LINKED_VALUE = '[OCL DECISIONS] Update Decisions From Linked Value',
  REMOVE_DECISIONS_FROM_LINKED_VALUE = '[OCL DECISIONS] Remove Decisions From Linked Value',
  REMOVE_ALL_DECISIONS_LINKED = '[OCL DECISIONS] Remove ALl Decisions Linked',
  ADD_ONE_DECISION = '[OCL DECISIONS] Add One Decision',
  ADD_MANY_DECISIONS = '[OCL DECISIONS] Add Many Decision',
  DELETE_ONE_DECISION = '[OCL DECISIONS] Delete One Decision',
}

export class InitDecisions implements Action {
  readonly type = OclDecisionsActionTypes.INIT_DECISIONS;
  constructor(public payload: Array<OclDecision>) {}
}

export class UpdateOneDecision implements Action {
  readonly type = OclDecisionsActionTypes.UPDATE_ONE_DECISION;
  constructor(public payload: OclDecision) {}
}
export class AddOneDecision implements Action {
  readonly type = OclDecisionsActionTypes.ADD_ONE_DECISION;
  constructor(public payload: OclDecision) {}
}

export class AddManyDecisions implements Action {
  readonly type = OclDecisionsActionTypes.ADD_MANY_DECISIONS;
  constructor(public payload: Array<OclDecision>) {}
}

export class UpdateManyDecisions implements Action {
  readonly type = OclDecisionsActionTypes.UPDATE_MANY_DECISIONS;
  constructor(public payload: Array<OclDecision>) {}
}

export class UpdateDecisionsFromPooling implements Action {
  readonly type = OclDecisionsActionTypes.UPDATE_DECISIONS_FROM_POOLING;
  constructor(public payload: Array<OclDecision>) {}
}

export class UpdateDecisionsFromLinkedValue implements Action {
  readonly type = OclDecisionsActionTypes.UPDATE_DECISIONS_FROM_LINKED_VALUE;
  constructor(public payload: Array<OclDecision>, public linkedUid: string) {}
}

export class RemoveDecisionsFromLinkedValue implements Action {
  readonly type = OclDecisionsActionTypes.REMOVE_DECISIONS_FROM_LINKED_VALUE;
  constructor(public linkedUid: string) {}
}

export class RemoveAllDecisionsLinked implements Action {
  readonly type = OclDecisionsActionTypes.REMOVE_ALL_DECISIONS_LINKED;
  constructor() {}
}
export class ResetDecisions implements Action {
  readonly type = OclDecisionsActionTypes.RESET_DECISIONS;
}

export class DeleteOneDecision implements Action {
  readonly type = OclDecisionsActionTypes.DELETE_ONE_DECISION;
  constructor(public decisionObjectId: string) {}
}

export type DecisionsActions =
  | InitDecisions
  | ResetDecisions
  | UpdateOneDecision
  | AddOneDecision
  | AddManyDecisions
  | UpdateManyDecisions
  | UpdateDecisionsFromPooling
  | UpdateDecisionsFromLinkedValue
  | RemoveDecisionsFromLinkedValue
  | RemoveAllDecisionsLinked
  | DeleteOneDecision;
