import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolManagerUser } from '../models/hol-manager-user';

@Injectable({
  providedIn: 'root',
})
export class HolManagerUserService {
  // tslint:disable:variable-name
  protected ParseManagerUser = Parse.Object.extend('HOLManagerUsers');
  // tslint:enabled

  constructor(protected requestService: RequestService) {}

  public getAll(): Promise<HolManagerUser[]> {
    const query = new Parse.Query(this.ParseManagerUser);
    return this.requestService.performFindQuery(query).then(results => {
      return results.map(r => new HolManagerUser(r));
    });
  }
}
