import { cloneDeep, isEqual } from 'lodash';
import { LinkedItemsActions, OclLinkedItemsActionTypes } from './ocl-linked-items.actions';

const linkedItemsInitialState: { [key: string]: any } = {};

export function oclLinkedItemsReducer(state: any = cloneDeep(linkedItemsInitialState), action: LinkedItemsActions): { [key: string]: any } {
  switch (action.type) {
    case OclLinkedItemsActionTypes.INIT_LINKED_ITEMS:
      return cloneDeep(action.payload);
    case OclLinkedItemsActionTypes.UPDATE_LINKED_ITEMS_FROM_POOLING:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        // console.log('UPDATE NEEDED : POOL AND STORE ARE DIFFERENT');
        // console.log('state', state);
        // console.log('payload', action.payload);
        return cloneDeep(action.payload);
      }
    case OclLinkedItemsActionTypes.UPDATE_LINKED_ITEMS:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        return cloneDeep(action.payload);
      }
    case OclLinkedItemsActionTypes.RESET_LINKED_ITEMS:
      return cloneDeep(linkedItemsInitialState);
    default:
      return state;
  }
}
