import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { OptionsService } from '../../services/options.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { cloneDeep } from 'lodash';
import { ErpModuleConfigService } from '../../../erp/services/erp-module-config.service';
import { ErpCrisisTypeService } from '../../../erp/services/erp-crisis-type.service';
import { ErpCrisisService } from '../../../erp/services/erp-crisis.service';
import { ErpCrisisType } from '../../../erp/models/erp-crisisTypes';
import { ErpCrisis } from '../../../erp/models/erp-crisis';
import { EclCrisis, EclCrisisType } from '../../../ecl/models/ecl-crisis';
import { EclModuleConfigService } from '../../../ecl/services/ecl-module-config/ecl-module-config.service';
import { EclCrisisTypeService } from '../../../ecl/services/ecl-crisis-service/ecl-crisis-type.service';
import { MatSelectChange } from '@angular/material/select';
import { EclCrisisService } from '../../../ecl/services/ecl-crisis-service/ecl-crisis.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { CrisisRolesRef } from '../../services/functions.service';
import { EclOptionsService } from '../../../ecl/services/ecl-options-service/ecl-options.service';
import { EclCrisisStoreManager } from '../../../ecl/store/crisis/crisis.store-manager';
import { EclCrisisDirectorStoreManager } from '../../../ecl/store/crisis-director/ecl-crisis-director.store-manager';
import { HolCrisisParameter, HolCrisisParameterValue } from '../../models/hol-crisis-parameter';

type ModalCrisis = ErpCrisis | EclCrisis;
type ModalCrisisType = ErpCrisisType | EclCrisisType;
type ModalCrisisModule = 'erp' | 'ecl';

@Component({
  selector: 'app-crisis-modal',
  templateUrl: './crisis-modal.component.html',
  styleUrls: ['./crisis-modal.component.scss'],
  providers: [
    {
      provide: ErpModuleConfigService,
      useClass: ErpModuleConfigService,
    },
    {
      provide: EclModuleConfigService,
      useClass: EclModuleConfigService,
    },
  ],
})
export class CrisisModalComponent implements OnInit {
  selectedTypeList: ModalCrisisType[];
  selectedCrisis?: ModalCrisis = null;
  showModuleControls = false;
  // ERP-related attributes
  erpCrisis: ErpCrisis;
  currentErpCrisis: ErpCrisis;
  erpCrisisTypeList: ErpCrisisType[];
  erpCrisisRolesRef: CrisisRolesRef;
  // ECL-related attributes
  eclCrisis: EclCrisis;
  currentEclCrisis: EclCrisis;
  eclCrisisTypeList: EclCrisisType[];
  eclCrisisRolesRef: CrisisRolesRef;
  // Form related attributes
  form: FormGroup;
  params: HolCrisisParameterValue[] = [];
  isCreate = true;
  //Emitted ACL-selector
  isEnoughACLength = true;
  constructor(
    @Inject('$translate') private $translate,
    @Inject('$state') private $state,
    @Inject('$rootScope') private $rootScope,
    @Inject(MAT_DIALOG_DATA) private data,
    public erpModuleConfig: ErpModuleConfigService,
    public eclModuleConfig: EclModuleConfigService,
    private dialogRef: MatDialogRef<CrisisModalComponent>,
    private erpCrisisTypeService: ErpCrisisTypeService,
    private erpCrisisService: ErpCrisisService,
    private eclCrisisTypeService: EclCrisisTypeService,
    private eclCrisisService: EclCrisisService,
    private commonStoreManager: CommonStoreManager,
    private eclCrisisStoreManager: EclCrisisStoreManager,
    private erpOptionsService: OptionsService,
    private eclOptionsService: EclOptionsService,
    private eclCrisisDirectorStoreManager: EclCrisisDirectorStoreManager,
    private dialog: MatDialog,
  ) {
    this.erpCrisis = cloneDeep(data.erpCrisis) || { ...new ErpCrisis(), contentText: '' };
    this.eclCrisis = cloneDeep(data.eclCrisis) || { ...new EclCrisis(), contentText: '' };
    this.isCreate = !data.eclCrisis;
  }

  async ngOnInit() {
    this.form = new FormGroup({
      module: new FormControl(null, [Validators.required]),
      mainTitle: new FormControl('', [Validators.required]),
      crisisType: new FormControl('', [Validators.required]),
      exercise: new FormControl(null, [Validators.required]),
      subTitle: new FormControl('', [Validators.required]),
      // Not used at this moment
      // clockDeparture: new FormControl(''),
      // clockArrival: new FormControl(''),
      // clockOnSite: new FormControl(''),
    });
    const erpTypes = await this.erpCrisisTypeService.getAll();
    const eclTypes = await this.eclCrisisTypeService.getAll();
    this.currentErpCrisis = await this.erpCrisisService.get();
    this.currentEclCrisis = await this.eclCrisisService.getCurrentOrLastCrisis();
    if (!this.isCreate) {
      this.form.disable();
    }
    this.erpCrisisTypeList = erpTypes;
    this.eclCrisisTypeList = eclTypes;
    // We will add as many custom fields as the crisis type with the most parameters
    const customFieldsSize = Math.max(...[...erpTypes.map(t => t.parameters.length), ...eclTypes.map(t => t.parameters.length)]);
    for (let i = 1; i <= customFieldsSize; i++) {
      if (!this.form.get(`param${i}`)) {
        this.form.addControl(`param${i}`, new FormControl(''));
      }
    }
    this.restoreFormFromCrisis(this.selectedCrisis);

    this.commonStoreManager.eclInfos.pipe(take(1)).subscribe(eclInfos => {
      // Determine if module radio buttons are shown or not
      const hasErpAccess = this.$rootScope.accessRights.crisis !== 'UNAUTHORIZED';
      const hasEclAccess = this.$rootScope.accessRights.ecl !== 'UNAUTHORIZED' && eclInfos.moduleIsActivated;

      if (hasErpAccess && !hasEclAccess) {
        this.selectedModule = 'erp';
        this.showModuleControls = false;
        this.selectedTypeList = erpTypes;
        this.selectedCrisis = this.erpCrisis;
      } else if (hasEclAccess && !hasErpAccess) {
        this.selectedModule = 'ecl';
        this.showModuleControls = false;
        this.selectedTypeList = eclTypes;
        this.selectedCrisis = this.eclCrisis;
      } else {
        this.showModuleControls = true;
      }
    });

    combineLatest([
      this.commonStoreManager.currentUserCrisisRolesRef,
      this.eclCrisisDirectorStoreManager.currentUserEclCrisisRolesRef,
    ]).subscribe(([erpRoles, eclRoles]) => {
      this.erpCrisisRolesRef = erpRoles;
      this.eclCrisisRolesRef = eclRoles;
    });
  }

  viaCrisisModal(event: any) {
    this.isEnoughACLength = event;
  }
  get canActivateCrisis(): boolean {
    switch (this.selectedModule) {
      case 'erp':
        return (
          this.erpCrisisRolesRef.isInCrisisDirectorTeam ||
          (this.erpCrisisRolesRef.isInCrisisInitializerTeam && this.erpOptionsService.crisisInitializerCanActivate())
        );
      case 'ecl':
        return (
          this.eclCrisisRolesRef.isInCrisisDirectorTeam ||
          (this.eclCrisisRolesRef.isInCrisisInitializerTeam && this.eclOptionsService.getCrisisInitializerCanActivate())
        );
      default:
        return false;
    }
  }

  get canPrepareCrisis(): boolean {
    if (this.selectedModule === 'erp') {
      return this.canActivateCrisis;
    } else {
      return false;
    }
  }

  canCloseCrisis(module: ModalCrisisModule): boolean {
    switch (module) {
      case 'ecl':
        return this.eclCrisisRolesRef && this.eclCrisisRolesRef.isInCrisisDirectorTeam;
      case 'erp':
        return this.erpCrisisRolesRef && this.erpCrisisRolesRef.isInCrisisDirectorTeam;
    }
    return false;
  }

  get selectedCrisisType(): ModalCrisisType {
    return this.formField('crisisType');
  }

  get selectedModule(): ModalCrisisModule {
    return this.formField('module') as ModalCrisisModule;
  }

  set selectedModule(value: ModalCrisisModule) {
    this.form.get('module').setValue(value);
  }

  crisisInProgress(module: ModalCrisisModule) {
    switch (module) {
      case 'ecl':
        return this.currentEclCrisis && this.currentEclCrisis.inProgress;
      case 'erp':
        return this.currentErpCrisis && this.currentErpCrisis.inProgress;
    }
    return false;
  }

  private formField<T>(field: string): T {
    const f = this.form.get(field);
    return f && f.value;
  }

  saveCrisis(activate: boolean) {
    if (!this.isCreate) {
      this.erpCrisisService.updateCrisisAcl(this.selectedCrisis as ErpCrisis).then(() => {
        this.$state.go('app.crisis.dashboard', {}, { reload: true });
        this.dialogRef.close();
      });
      return;
    }

    this.setCrisisFromForm(this.selectedCrisis);
    const labelItem = this.selectedCrisis.isTraining ? 'EXERCISE' : 'CRISIS';
    const labelVerb = activate ? 'ACTIVATE' : 'PREPARE';
    const confirmTitle = this.$translate.instant(`CRISIS.MODAL.${labelVerb}_${labelItem}.${this.selectedModule.toUpperCase()}.TITLE`);
    const confirmContent = this.$translate.instant(`CRISIS.MODAL.${labelVerb}_${labelItem}.${this.selectedModule.toUpperCase()}.CONTENT`);
    const confirmQuestion = this.$translate.instant(`CRISIS.MODAL.${labelVerb}_${labelItem}.${this.selectedModule.toUpperCase()}.QUESTION`);
    this.selectedCrisis.isInPreparation = !activate;
    this.selectedCrisis.inProgress = activate;
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: confirmTitle,
          modalContent: confirmContent,
          modalQuestion: confirmQuestion,
        },
      })
      .afterClosed()
      .toPromise()
      .then(res => {
        if (res) {
          const progressModal = this.dialog.open(AlertModalComponent, {
            data: {
              modalTitle: this.$translate.instant('CRISIS.MODAL.CREATE_CRISIS.CREATION_PROGRESS'),
              modalContent: this.$translate.instant('CRISIS.MODAL.CREATE_CRISIS.CREATION_WARN') + '<br/>',
              inProgress: true,
            },
          });
          let subscription = new BehaviorSubject('');
          if (this.selectedModule === 'erp') {
            subscription = this.erpCrisisService.create(this.selectedCrisis as ErpCrisis);
          } else if (this.selectedModule === 'ecl') {
            subscription = this.eclCrisisService.create(this.selectedCrisis as EclCrisis);
          }
          return subscription.subscribe(
            s => {
              progressModal.componentInstance.modalContent += s;
            },
            e => {
              progressModal.componentInstance.modalContent += '❌ ' + JSON.stringify(e);
            },
            async () => {
              progressModal.componentInstance.inProgress = false;
              let path = '';
              if (this.selectedModule === 'erp') {
                const erpCrisis = await this.erpCrisisService.get();
                this.commonStoreManager.initErpCrisis(erpCrisis);
                path = 'app.crisis.dashboard';
              } else if (this.selectedModule === 'ecl') {
                // When creating a new crisis, we should update 2 stores:
                // CommonStoreManager contains the current crisis, and it should be updated
                // EclCrisisStoreManager contains the crisis list and the selected crisis and should be updated too.
                const eclCrisis = await this.eclCrisisService.getCurrentOrLastCrisis();
                this.eclCrisisStoreManager.createCrisis(eclCrisis);
                this.commonStoreManager.initEclCrisis(eclCrisis);
                path = 'app.ecl.dashboard';
              }
              if (path) {
                this.$state.go(path, {}, { reload: true });
              }
              setTimeout(() => {
                this.dialogRef.close();
                progressModal.close();
              }, 1500);
            },
          );
        }
      });
  }

  async closeCrisis(module: ModalCrisisModule) {
    const yes = await this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant(`CRISIS.MODAL.CLOSE_CRISIS.${module.toUpperCase()}.CONFIRM_TITLE`),
          modalContent: this.$translate.instant(`CRISIS.MODAL.CLOSE_CRISIS.${module.toUpperCase()}.CONFIRM_CONTENT`),
          modalType: 'info',
        },
      })
      .afterClosed()
      .toPromise();
    if (yes) {
      let savedCrisis;
      switch (module) {
        case 'erp':
          savedCrisis = await this.erpCrisisService.closeCrisis(this.currentErpCrisis);
          this.currentErpCrisis = null;
          this.commonStoreManager.initErpCrisis(savedCrisis);
          break;
        case 'ecl':
          savedCrisis = await this.eclCrisisService.closeCrisis(this.currentEclCrisis);
          this.currentEclCrisis = null;
          // When closing a new crisis, we should update 2 stores:
          // CommonStoreManager contains the current crisis, and it should be updated
          // EclCrisisStoreManager contains the crisis list and the selected crisis and should be updated too.
          this.commonStoreManager.initEclCrisis(savedCrisis);
          this.eclCrisisStoreManager.closeCrisis(savedCrisis);
          break;
      }
    }
  }

  crisisTypeChanged($event: MatSelectChange) {
    const newCrisisType = $event.value;
    if (newCrisisType) {
      const paramsCopiedFromForm = this.populateCrisisParamsWithForm(this.params);
      const paramList = this.generateParamListFromCrisisType(newCrisisType, paramsCopiedFromForm);
      this.params = paramList;
      this.populateFormWithCrisisParams(paramList);
    }
  }

  private generateParamListFromCrisisType(
    crisisType: ModalCrisisType,
    existingParamList: HolCrisisParameterValue[],
  ): HolCrisisParameterValue[] {
    return crisisType.parameters
      .sort((p1, p2) => p1.order - p2.order)
      .map(param => {
        let paramValue = existingParamList.find(pv => pv.crisisParameterId === param.crisisParameterId);
        if (!paramValue) {
          paramValue = this.newCrisisParamValue(param);
        }
        paramValue.order = param.order;
        return paramValue;
      });
  }

  private newCrisisParamValue(parameter: HolCrisisParameter, value: string = ''): HolCrisisParameterValue {
    const paramValue = new HolCrisisParameterValue();
    paramValue.crisisParameterId = parameter.crisisParameterId;
    paramValue.isDefault = true;
    paramValue.value = value;
    paramValue.order = parameter.order;
    return paramValue;
  }

  private populateFormWithCrisisParams(params: HolCrisisParameterValue[]) {
    params.forEach(param => {
      const formField = this.form.get(`param${param.order}`);
      if (formField) {
        formField.setValue(param.value || '');
      }
    });
  }

  private populateCrisisParamsWithForm(params: HolCrisisParameterValue[]): HolCrisisParameterValue[] {
    return params.map(param => {
      const formField = this.form.get(`param${param.order}`);
      if (formField) {
        param.value = formField.value;
      }
      return param;
    });
  }

  switchModule(module: ModalCrisisModule) {
    switch (module) {
      case 'erp':
        this.selectedTypeList = this.erpCrisisTypeList;
        // Store current form values to ECL crisis
        this.setCrisisFromForm(this.eclCrisis);
        // And take the values from ERP crisis
        this.restoreFormFromCrisis(this.erpCrisis);
        this.selectedCrisis = this.erpCrisis;
        break;
      case 'ecl':
        this.selectedTypeList = this.eclCrisisTypeList;
        // Store current form values to ERP crisis
        this.setCrisisFromForm(this.erpCrisis);
        // And take the values from ECL crisis
        this.restoreFormFromCrisis(this.eclCrisis);
        this.selectedCrisis = this.eclCrisis;
        break;
      default:
        this.selectedTypeList = [];
        this.selectedCrisis = null;
        break;
    }
  }

  private setCrisisFromForm(crisis: ModalCrisis) {
    if (!crisis) {
      return;
    }
    crisis.mainTitle = this.form.get('mainTitle').value;
    crisis.subTitle = this.form.get('subTitle').value;
    crisis.isTraining = this.form.get('exercise').value;
    crisis.type = this.form.get('crisisType').value;
    crisis.crisisTypeId = crisis.type && crisis.type.crisisTypeId;
    crisis.params = this.populateCrisisParamsWithForm(this.params);
  }

  private restoreFormFromCrisis(crisis: ModalCrisis) {
    if (!crisis) {
      return;
    }
    this.form.get('mainTitle').setValue(crisis.mainTitle || '');
    this.form.get('subTitle').setValue(crisis.subTitle || '');
    this.form.get('exercise').setValue(crisis.isTraining !== undefined ? crisis.isTraining : null);
    const crisisType = crisis.type;
    this.form.get('crisisType').setValue(crisisType || null);

    this.params = crisis.params;
    this.populateFormWithCrisisParams(crisis.params);
    // ACLs are managed from other component inside the modal
  }
}
