import { Component, Input } from '@angular/core';
import { HolChecklist } from '../../../common/models/hol-checklist';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Component({
  selector: 'app-ocl-file-and-image-display-check-list-task',
  templateUrl: './ocl-file-and-image-display-check-list-task.html',
  styleUrls: ['./ocl-file-and-image-display-check-list-task.scss'],
})
export class OclFileAndImageDisplayCheckListTask {
  @Input() data: HolChecklist;

  constructor(public moduleConfig: ModuleConfigService) {}
  public isEmptyAttachment?(): boolean {
    return !(
      this.data.attachments.note ||
      this.data.attachments.noteFile ||
      this.data.attachments.file ||
      this.data.attachments.image ||
      this.data.attachments.link ||
      (this.data.attachments.files && this.data.attachments.files.length)
    );
  }
}
