import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-crew-checklist',
  templateUrl: './crew-checklist.component.html',
  styleUrls: ['./crew-checklist.component.sass'],
})
export class CrewChecklistComponent implements OnInit {
  constructor(@Inject('$rootScope') public $rootScope) {}

  ngOnInit() {}
}
