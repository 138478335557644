import { Injectable } from '@angular/core';

import { RequestService } from './request.service';
import { HolUser } from '../models/hol-user.model';
import { CommonStoreManager } from '../store/common.store-manager';
import { take } from 'rxjs/operators';
import { FunctionsStoreManager } from '../../erp/store/functions/functions.store-manager';

/**
 * Ce service contient des méthodes permettant de manipuler l'utilisateur connecté.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(
    private requestService: RequestService,
    private commonStoreManager: CommonStoreManager,
    private functionsStoreManager: FunctionsStoreManager
  ) {}

  /**
   * Met à jour le store (common.currentUser) avec l'utilisateur actuellement connecté
   */
  public initCurrentUser(): Promise<HolUser> {
    return Parse.User.currentAsync().then(parseUser => {
      const user = new HolUser(parseUser);
      this.commonStoreManager.updateCurrentUser(user);
      return user;
    });
  }

  /**
   * Met a jour les informations de l'utilisateur qui vient de se connecter
   * (mise a jour du "lastSeenAt" + reset du flag "isAvailableOffline")
   */
  justLoggedIn(): Promise<HolUser> {
    return Parse.User.currentAsync().then(user => {
      user.set('isAvailableOffline', false);
      user.set('lastSeenAt', new Date());
      return this.requestService.performSaveQuery(user).then(parseUser => {
        const newUser = new HolUser(parseUser);
        this.commonStoreManager.updateCurrentUser(newUser);
        return newUser;
      });
    });
  }

  /**
   * Met a jour le "lastSeenAt" de l'utilisateur connecté
   */
  updateLastSeenAt(): Promise<HolUser> {
    return this.commonStoreManager.currentUser
      .pipe(take(1))
      .toPromise()
      .then(user => {
        if (user) {
          const parseUser = new Parse.User();
          parseUser.id = user.objectId;
          parseUser.set('lastSeenAt', new Date());
          return this.requestService.performSaveQuery(parseUser).then(pUser => {
            const newUser = new HolUser(pUser);
            this.commonStoreManager.updateCurrentUser(newUser);
            this.functionsStoreManager.updateOneUser(newUser);
            return newUser;
          });
        }
      });
  }
}
