import { Inject, Injectable } from '@angular/core';

import * as _ from 'lodash';
import { USER_RIGHTS } from 'src/app/app.module';

import { HolUser, HolUserWithCompanies } from '../models/hol-user.model';
import { FunctionsService } from './functions.service';
import { OptionsService } from './options.service';
import { ParseMapperService } from './parse-mapper.service';
import { RequestService } from './request.service';
import { RolesService } from './roles.service';
import { HolUserWithRoles } from '../../admin/pages/admin-roles/admin-roles.component';
import { MailSenderService } from './mail/mail-sender.service';
import { SmsSenderService } from './sms/sms-sender.service';
import { HelperService } from './helper.service';
import { emailProviders } from '../../../assets/emailProviders';
import { HolOptionsService } from './hol-options.service';

export interface IUserRights {
  occ: USER_RIGHTS;
  ecl: USER_RIGHTS;
  ops: USER_RIGHTS;
  crew: USER_RIGHTS;
  crisis: USER_RIGHTS;
  mcc: USER_RIGHTS;
  goc: USER_RIGHTS;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUserRights: IUserRights;
  private currentUserFunctions = []; // @cache functions of the current user
  private mustRefresh_currentUserFunctions = false;

  private users = []; // all users
  private mustRefresh_users = false;

  private mapUserFunctionsWithCriteria = {}; // @cache : map qui pour un JSON.stringify(criteria) donne des données cachées

  private UserFunction = Parse.Object.extend('GDCUserFunction');
  private GDCFunction = Parse.Object.extend('GDCFunction');

  constructor(
    @Inject('CONSTANTS') private CONSTANTS,
    private readonly functionsService: FunctionsService,
    private readonly requestService: RequestService,
    private readonly optionsService: OptionsService,
    private readonly rolesService: RolesService,
    private readonly parseMapperService: ParseMapperService,
    private mailSenderService: MailSenderService,
    private smsSenderService: SmsSenderService,
    private helperService: HelperService,
    private holOptionsService: HolOptionsService,
  ) {}

  getCurrentUser() {
    return (Parse.User.current().get('firstName') || '') + ' ' + (Parse.User.current().get('lastName') || '');
  }

  getCurrentUserObject() {
    return this.transformParseUserToObject(Parse.User.current());
  }

  getCurrentUserFunctions(forceToRefresh = false): Promise<
    {
      id: string;
      title: string;
      shortTitle: string;
      isHolder: boolean;
    }[]
  > {
    return new Promise((resolve, reject) => {
      this.functionsService.all(forceToRefresh, false).then(functions => {
        this.getCurrentUserFunctionsQuery(forceToRefresh).then((currentUserFunctions: any[]) => {
          const ufs = [];
          let theF: any;
          _.each(currentUserFunctions, uf => {
            theF = _.find(functions, { id: uf.functionId });
            if (theF) {
              ufs.push({
                id: uf.functionId,
                title: theF.title,
                shortTitle: theF.shortTitle,
                isHolder: uf.isHolder,
              });
            }
          });
          resolve(ufs);
        }, reject);
      }, reject);
    });
  }

  isCurrentUserInTheCrewManagerTeam(): Promise<boolean> {
    return this.isCurrentUserInTeam(this.optionsService.getCrewManagerShortTitlesList());
  }

  isCurrentUserInTheChatTeam(): Promise<boolean> {
    return this.isCurrentUserInTeam(this.optionsService.getChatShortTitlesList());
  }

  isCurrentUserInTeam(teamShortTitles: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const currentUser = Parse.User.current();
      if (!currentUser) {
        reject('No current user');
        return;
      }
      const functionsQuery = new Parse.Query(this.GDCFunction);
      functionsQuery.containedIn('shortTitle', teamShortTitles);

      const userFunctionQuery = new Parse.Query(this.UserFunction);
      userFunctionQuery.matchesKeyInQuery('functionId', 'functionId', functionsQuery);

      const userQuery = new Parse.Query(Parse.User);
      userQuery.matchesKeyInQuery('userId', 'userId', userFunctionQuery);
      userQuery.equalTo('objectId', currentUser.id);
      this.requestService.performFindQuery(
        userQuery,
        users => {
          resolve(users.length > 0);
        },
        reject,
      );
    });
  }

  clearCache() {
    this._currentUserRights = null;
  }

  getAccessRights(forceRefresh = false): Promise<IUserRights> {
    return new Promise((resolve, reject) => {
      if (this._currentUserRights && !forceRefresh) {
        resolve(this._currentUserRights);
        return;
      }
      this.rolesService
        .getCurrentUserRoles()
        .then(roles => {
          this._currentUserRights = {
            occ: roles.find(r => r.universe === 'OCC' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'OCC' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            ecl: roles.find(r => r.universe === 'ECL' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'ECL' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            ops: roles.find(r => r.universe === 'OPS' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'OPS' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            crew: roles.find(r => r.universe === 'CREW' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'CREW' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            crisis: roles.find(r => r.universe === 'ERP' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'ERP' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            mcc: roles.find(r => r.universe === 'MCC' && r.write)
              ? USER_RIGHTS.WRITE
              : roles.find(r => r.universe === 'MCC' && r.read)
              ? USER_RIGHTS.READ
              : USER_RIGHTS.UNAUTHORIZED,
            goc: undefined,
          };
          if (roles.find(r => r.universe === 'GOC' && r.write && !r.subCompany)) {
            this._currentUserRights.goc = USER_RIGHTS.WRITE;
          } else if (roles.find(r => r.universe === 'GOC' && r.read && !r.subCompany)) {
            this._currentUserRights.goc = USER_RIGHTS.READ;
          } else if (roles.find(r => r.universe === 'GOC' && r.write && !!r.subCompany)) {
            this._currentUserRights.goc = USER_RIGHTS.EXTERNAL_WRITE;
          } else if (roles.find(r => r.universe === 'GOC' && r.read && !!r.subCompany)) {
            this._currentUserRights.goc = USER_RIGHTS.EXTERNAL_READ;
          } else {
            this._currentUserRights.goc = USER_RIGHTS.UNAUTHORIZED;
          }
          resolve(this._currentUserRights);
        })
        .catch(reject);
    });
  }

  // @cache users
  getAllUsers(forceRefresh = false): Promise<HolUser[]> {
    return new Promise((resolve, reject) => {
      if (this.mustRefresh_users !== true && this.users.length != 0 && forceRefresh !== true) {
        resolve(this.users);
      } else {
        this.getAllUsersQuery().then(data => {
          this.mustRefresh_users = false;
          resolve(data);
        }, reject);
      }
    });
  }

  // ------------------------
  // private functions

  // @hasCache
  private getCurrentUserFunctionsQuery(forceToRefresh = false) {
    return new Promise((resolve, reject) => {
      const currentParseUser = Parse.User.current();
      if (currentParseUser == null) {
        reject();
        return;
      }

      if (
        this.mustRefresh_currentUserFunctions !== true &&
        (this.currentUserFunctions.length != 0 || Parse.User.current() == null) &&
        forceToRefresh !== true
      ) {
        resolve(this.currentUserFunctions);
      } else {
        this.getAllUserFunctions({ userId: currentParseUser.get('userId') }, forceToRefresh).then((retrievedUserFunctions: any[]) => {
          this.currentUserFunctions = _.cloneDeep(retrievedUserFunctions);
          this.mustRefresh_currentUserFunctions = false;
          resolve(this.currentUserFunctions);
        }, reject);
      }
    });
  }

  private getAllUsersQuery(): Promise<HolUser[]> {
    const query = new Parse.Query(Parse.User);
    query.limit(1000);
    return this.requestService.performFindQuery(query).then(parseUsers => {
      const users: HolUser[] = parseUsers.map(u => this.parseMapperService.userToObject(u));
      return users;
    });
  }

  // @hasCache
  private getAllUserFunctions(criterias, forceRefresh = false) {
    return new Promise((resolve, reject) => {
      if (
        Object.keys(this.mapUserFunctionsWithCriteria).length > 0 &&
        (forceRefresh == undefined || forceRefresh === false) &&
        this.mapUserFunctionsWithCriteria[JSON.stringify(criterias)]
      ) {
        resolve(this.mapUserFunctionsWithCriteria[JSON.stringify(criterias)]);
      } else {
        const query = new Parse.Query(this.UserFunction);
        if (criterias) {
          if (criterias.onlyHolders) {
            query.equalTo('isHolder', true);
          }
          if (criterias.userId) {
            query.equalTo('userId', criterias.userId);
          }
          if (criterias.functionId) {
            query.equalTo('functionId', criterias.functionId);
          } else {
            const functionWithTagQuery = new Parse.Query(this.GDCFunction);
            functionWithTagQuery.exists('tagId');
            functionWithTagQuery.notEqualTo('tagId', '');
            query.matchesKeyInQuery('functionId', 'functionId', functionWithTagQuery);
          }
        }
        this.requestService.performFindAllQuery(
          query,
          userFunctions => {
            const parsedUserFunctions = [];
            for (let i = 0; i < userFunctions.length; i++) {
              parsedUserFunctions.push(this.transformParseUserFunctionToObject(userFunctions[i]));
            }

            this.mapUserFunctionsWithCriteria[JSON.stringify(criterias)] = parsedUserFunctions;
            resolve(parsedUserFunctions);
          },
          error => {
            reject(error);
          },
        );
      }
    });
  }

  private transformParseUserFunctionToObject(parseObject) {
    return {
      objectId: parseObject.id,
      userId: parseObject.get('userId'),
      functionId: parseObject.get('functionId'),
      isHolder: parseObject.get('isHolder'),
      since: parseObject.get('createdAt'),
      updatedAt: parseObject.get('updatedAt'),
    };
  }

  private transformParseUserToObject(parseObject) {
    if (parseObject == null) return null;

    const user = {
      objectId: parseObject.id,
      userId: parseObject.get('userId'),
      firstName: parseObject.get('firstName'),
      lastName: parseObject.get('lastName'),
      displayName: parseObject.get('firstName') + ' ' + parseObject.get('lastName'),
      fullName: parseObject.get('firstName') + ' ' + parseObject.get('lastName'),
      username: parseObject.get('username'),
      email: parseObject.get('email') || parseObject.get('userEmail'),
      phone: parseObject.get('phone'),
      role: parseObject.get('role'),
      lastSeenAt: parseObject.get('lastSeenAt'),
      monogram: function () {
        return (
          (this.firstName && this.firstName.length ? this.firstName[0] : '') +
          (this.lastName && this.lastName.length ? this.lastName[0] : '')
        );
      },
      createdBy: undefined,
    };

    if (parseObject.get('createdBy') != null) {
      user.createdBy = this.transformParseUserToObject(parseObject.get('createdBy'));
    }

    return user;
  }

  fetchNewData() {
    const promises = [];
    promises.push(
      this.getCurrentUserFunctions().then(ufs => {
        // $rootScope.$broadcast('poolService-userFunctions', ufs);
      }),
    );

    return Promise.all(promises);
  }

  public getUsersForFunctionId(functionId): Promise<HolUser[]> {
    return new Promise((resolve, reject) => {
      const ufQuery = new Parse.Query(this.UserFunction);
      ufQuery.equalTo('functionId', functionId);
      this.requestService
        .performFindQuery(ufQuery)
        .then(userFunctions => {
          const userIds = userFunctions.map(uf => uf.get('userId'));
          const userQuery = new Parse.Query(Parse.User);
          userQuery.containedIn('userId', userIds);
          userQuery.ascending('userId');
          return this.requestService.performFindQuery(userQuery);
        })
        .then(users => {
          return resolve(users.map(u => this.parseMapperService.userToObject(u)));
        })
        .catch(reject);
    });
  }

  getUserInitials(user): string {
    let initials = '';
    if (user && user.firstName) {
      initials += user.firstName.substring(0, 1);
    }
    if (user && user.lastName) {
      initials += user.lastName.substring(0, 1);
    }
    return initials;
  }

  async createNewUser(userToCreate: Partial<HolUserWithRoles>, shouldNotify: boolean, totalUsers: number): Promise<HolUserWithCompanies> {
    const holOptions = await this.holOptionsService.get();
    const generatedPassword = this.helperService.generatePassword(holOptions.passwordLength);

    const newUserId = this.makeUserId(userToCreate.firstName, userToCreate.lastName, userToCreate.email, totalUsers);
    const user = new Parse.User();
    user.set('username', userToCreate.email);
    user.set('password', generatedPassword);
    user.set('email', userToCreate.email);
    user.set('userEmail', userToCreate.email);
    user.set('firstName', userToCreate.firstName);
    user.set('lastName', userToCreate.lastName);
    user.set('phone', userToCreate.phone);
    user.set('userId', newUserId);
    user.set('createdBy', Parse.User.current());
    user.set('isExternal', userToCreate.isExternal);
    const acl = new Parse.ACL();
    acl.setRoleReadAccess('Admin', true);
    acl.setRoleWriteAccess('Admin', true);
    acl.setPublicReadAccess(true);
    user.setACL(acl);

    const parseUser = await this.requestService.performSaveQuery(user);
    const newUser = new HolUserWithCompanies(parseUser);

    if (shouldNotify) {
      this.mailSenderService.sendMail(
        {
          recipients: [{ email: newUser.email }],
          subject: 'Account created',
          contentHtml: [
            '<strong>A new account has been created for you.</strong>',
            'Here are you credentials :<br/>' + 'Username : ' + newUser.email + '<br/>' + 'Access code : ' + generatedPassword,
            'You can login at ' + location.origin,
          ].join('<br/><br/>'),
        },
        true,
        true,
        true,
      );

      if (newUser.phone && newUser.phone.trim() !== '') {
        this.smsSenderService.sendSms(
          newUser.phone,
          [
            'A new account has been created for you. Here are you credentials :',
            'Username : ' + newUser.email + '\n' + 'Access code : ' + generatedPassword,
            'You can login at ' + location.origin,
          ].join('\n'),
          true,
          this.CONSTANTS.COMPANY_NAME,
        );
      }
    }

    return newUser;
  }

  public makeUserId(firstName: string, lastName: string, email: string, totalUsers: number): string {
    if (!firstName && !lastName) {
      return 'NONAME';
    }
    const domainFromEmail = email.split('@').pop();
    const domainPrefix = this.decodeEmailProvider(domainFromEmail);
    return domainPrefix + '-' + this.userPrefix(firstName, lastName) + '-' + String(totalUsers + 1);
  }

  public userPrefix(firstName: string, lastName: string): string {
    const initialFirstName = firstName
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '-')
      .slice(0, 1);
    const initialsLastName = lastName
      .toUpperCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '-')
      .slice(0, 3);
    return initialsLastName + initialFirstName;
  }

  public decodeEmailProvider(emailDomain: string) {
    // eslint-disable-next-line no-prototype-builtins
    if (emailProviders.hasOwnProperty(emailDomain)) {
      return emailProviders[emailDomain];
    } else {
      return emailDomain.replace(/[.-]/g, '').toUpperCase();
    }
  }

  public async changeisRandomCode(user: HolUser, value: boolean): Promise<HolUser> {
    const userToSave = new Parse.User();
    userToSave.id = user.objectId;
    userToSave.set('isRandomCode', value);

    const parseUser = await this.requestService.performSaveQuery(userToSave);
    const userUpdated = new HolUser(parseUser);
    return userUpdated;
  }

  public async updateLastSeenDateForCurrentUser() {
    const userToSave = Parse.User.current();
    userToSave.set('lastSeenAt', new Date());
    const parseUser = await this.requestService.performSaveQuery(userToSave);
    return parseUser;
  }
}
