import { FilesService } from 'src/app/common/services/files.service';
import { HolisFile, HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { FltFlightInstruction } from './../models/flt-flight-instruction';
import { FltEvent } from 'src/app/flt/models/flt-event';
import { FltLogbook } from './../models/flt-logbook';
import { FltDecision } from './../models/flt-decision';
import { FltFlightLogbook } from './../models/flt-flight-logbook';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { Injectable } from '@angular/core';
import { FltApplicability } from './../models/flt-applicability';
import * as moment from 'moment';
import { TranslatePipe } from 'src/app/common/pipes/translate/translate.pipe';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FltApplicabilityService {
  constructor(
    private readonly filesService: FilesService,
    private translatePipe: TranslatePipe,
    private markdownService: MarkdownService,
  ) {}

  isApplicabilityDatesValid = new BehaviorSubject<boolean>(true);

  public applicabilityHistoryInNotes(
    savedObject: FltDecision | FltLogbook | FltEvent | FltFlightInstruction | FltFlightLogbook,
    context: HolContext,
    addMdBreakLinesBefore = false,
    objectApplicability?: FltApplicability,
  ) {
    let content = '';
    let nameFile;
    const dateFormat = 'DD/MM/YY HH:mm[Z]';
    const applicabilityDate = moment.utc(savedObject.updatedAt ? savedObject.updatedAt : savedObject.createdAt).format(dateFormat);
    const applicability = objectApplicability
      ? objectApplicability
      : savedObject.hasOwnProperty('applicability')
      ? savedObject['applicability']
      : undefined;
    const attachments = savedObject.attachments ? savedObject.attachments : new HolAttachments();
    // const attachments = applicability.attachments ? applicability.attachments : new HolAttachments();
    const noteFile: HolisFile = new HolisFile();
    context.htmlTitle = context.htmlTitle ? context.htmlTitle : savedObject.contentText;

    if (applicability) {
      if (attachments && attachments.note) {
        content += attachments.note;
        nameFile = attachments.noteFile.fileName;
      } else {
        nameFile = `note-${context.module.toLocaleLowerCase()}-${context.category
          .substring(0, 3)
          .toLocaleLowerCase()
          .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
        addMdBreakLinesBefore = true;
      }

      content += addMdBreakLinesBefore ? '\n' + '\n' + '\n' : '';
      content += this.applicabilityHistoryTxt(applicability, applicabilityDate);

      const mdTemplate = content;

      const htmlContent = this.markdownService.parseMdToHtml(content);
      const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

      const blob = new Blob([htmlTemplate], { type: 'text/html' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
            url => {
              noteFile.url = url;
              noteFile.fileName = nameFile;
              attachments.note = mdTemplate;
              attachments.noteFile = noteFile;
              resolve(attachments);
            },
            err => {
              reject(err);
            },
          );
        };
      });
    } else {
      throw new Error('FltApplicabilityService::Applicabilité manquante');
    }
  }

  public applicabilityHistoryTxt(applicability: FltApplicability, applicabilityDate: string) {
    const dateFormat = 'DD/MM/YY HH:mm[Z]';

    let direction: string;
    if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
      direction = this.translatePipe.transform('APPLICABILITY.FROM_DEPARTURE');
    } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
      direction = this.translatePipe.transform('APPLICABILITY.FROM_DESTINATION');
    } else {
      direction = this.translatePipe.transform('APPLICABILITY.FROM_DEPARTURE_AND_DESTINATION');
    }

    let validity = '';
    validity += applicability.validFrom
      ? ' ' + this.translatePipe.transform('APPLICABILITY.STARTING') + ' ' + moment.utc(applicability.validFrom).format(dateFormat)
      : '';
    validity += applicability.validTo
      ? ' ' + this.translatePipe.transform('APPLICABILITY.UNTIL') + ' ' + moment.utc(applicability.validTo).format(dateFormat)
      : ' ' + this.translatePipe.transform('APPLICABILITY.INDEFINITE_PERIODE');

    let txt: string;
    if (applicability.flights && applicability.flights.length > 0) {
      const flightSingPlur =
        applicability.flights.length === 1
          ? this.translatePipe.transform('APPLICABILITY.THIS_FLIGHT')
          : this.translatePipe.transform('APPLICABILITY.THOSE_FLIGHTS');
      txt = this.translatePipe.transform('APPLICABILITY.APPLICABLE') + ' ' + direction + ' ' + flightSingPlur;
      applicability.flights.forEach(flight => {
        txt +=
          ' ' +
          flight.flightNumber +
          '/' +
          moment(flight.std).format('DDMMM').toUpperCase() +
          ' ' +
          flight.departure +
          ' -> ' +
          flight.destination;
      });
    }
    if (applicability.flightsNumbers && applicability.flightsNumbers.length > 0) {
      txt =
        this.translatePipe.transform('APPLICABILITY.APPLICABLE') +
        ' ' +
        direction +
        ' ' +
        this.translatePipe.transform('APPLICABILITY.THOSE_FLIGHTS') +
        ' ' +
        applicability.flightsNumbers.toString() +
        ' ' +
        validity;
    }
    if (applicability.stations && applicability.stations.length > 0) {
      const stationSingPlur =
        applicability.stations.length === 1
          ? this.translatePipe.transform('APPLICABILITY.THIS_STATION')
          : this.translatePipe.transform('APPLICABILITY.THOSE_STATIONS');
      txt =
        this.translatePipe.transform('APPLICABILITY.APPLICABLE') +
        ' ' +
        this.translatePipe.transform('APPLICABILITY.TO_FLIGHTS') +
        ' ' +
        direction +
        ' ' +
        stationSingPlur +
        ' ' +
        applicability.stations.toString() +
        ' ' +
        validity;
    }
    if (applicability.inOutStations && applicability.inOutStations.length > 0) {
      txt = this.translatePipe.transform('APPLICABILITY.APPLICABLE') + ' ' + this.translatePipe.transform('APPLICABILITY.TO_FLIGHTS');
      applicability.inOutStations.forEach(inOutStation => {
        txt += ' ' + inOutStation[0] + ' -> ' + inOutStation[1];
      });
      txt += ' ' + validity;
    }

    if (!txt) {
      txt = this.translatePipe.transform('APPLICABILITY.CANCELLED_APPLICABILITY');
    }

    return ' ' + '\n' + '**' + applicabilityDate + '** ' + '\n' + '\n' + txt + ' ' + '\n';
  }
}
