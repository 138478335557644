<div class="form-modal-component hol-dialog-wrapper hol-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span>{{ data.translateKey + '.TASKS.FORM_LABEL' | translate }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1" type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <app-task-form-io [formIoCredentials]="data.formIoCredentials" [isHolder]="data.isHolder" [task]="data.task"></app-task-form-io>
  </mat-dialog-content>
</div>
