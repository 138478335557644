import { cloneDeep } from 'lodash';
import { OclTasksActionTypes, TasksActions } from './ocl-tasks.actions';
import { OclTask } from '../../models/ocl-task';

const taskInitialState: OclTask[] = [];

export function oclTasksReducer(tasks: OclTask[] = cloneDeep(taskInitialState), action: TasksActions): OclTask[] {
  switch (action.type) {
    case OclTasksActionTypes.INIT_TASKS:
      return action.tasks;
    case OclTasksActionTypes.UPDATE_ONE_TASK:
      return tasks.map(task => (task.objectId === action.task.objectId ? action.task : task));

    default:
      return tasks;
  }
}
