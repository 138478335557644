import { RolesService } from 'src/app/common/services/roles.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { OclChecklistFunction } from '../../../../ocl/store/ocl-tasks.selectors';
import { HolManagerFunction, HolManagersSelectors } from '../../../store/hol-managers.selectors';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { CommonStoreManager } from '../../../store/common.store-manager';

@Component({
  selector: 'app-checklist-by-function',
  templateUrl: './checklist-by-function.component.html',
  styleUrls: ['./checklist-by-function.component.scss'],
})
export class ChecklistByFunctionComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public isReadOnly: boolean;

  managersSub: Subscription;
  @Input() public checklistGroupByFunction: OclChecklistFunction;
  managerFunction: HolManagerFunction;
  isHolder: boolean;
  RolesService = RolesService;

  constructor(
    private managersSelectors: HolManagersSelectors,
    public moduleConfig: ModuleConfigService,
    private commonStoreManager: CommonStoreManager
  ) {
    super();
  }

  public ngOnInit(): void {
    this.managersSub = combineLatest([this.managersSelectors.managersFunctions, this.commonStoreManager.currentUser]).subscribe(
      ([mfs, currentUser]) => {
        this.managerFunction = mfs.find(mf => mf.functionId === this.checklistGroupByFunction.func.functionId);
        this.isHolder =
          (this.managerFunction &&
            !!this.managerFunction.companies.find(c => {
              return c.holder && c.holder.user.userId === currentUser.userId;
            })) ||
          !!mfs.find(mf => mf.isManagerFunction && !!mf.companies.find(c => c.holder && c.holder.user.userId === currentUser.userId));
      }
    );
  }

  public ngOnDestroy(): void {
    this.managersSub.unsubscribe();
  }
}
