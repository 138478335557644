import { HolScenario } from 'src/app/common/models/hol-scenario';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store';
import { InitScenarios } from './ocl-scenarios.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OclScenariosStoreManager {
  protected _scenariosState: Observable<HolScenario[]>;

  protected constructor(private store: Store<AppState>) {
    this._scenariosState = store.select('ocl', 'scenarios');
  }

  get scenariosState(): Observable<HolScenario[]> {
    return this._scenariosState;
  }

  initScenarios(scenarios: HolScenario[]): void {
    this.store.dispatch(new InitScenarios(scenarios));
  }
}
