import { take } from 'rxjs/operators';
import { OclScenariosStoreManager } from '../../store/scenarios/ocl-scenarios.store-manager';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

import { cloneDeep, differenceWith, extend, isEqual, orderBy } from 'lodash';
import { EclInfos } from 'src/app/common/store/common.model';

import { HolAttachments } from '../../../common/models/hol-attachments.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { HolScenario } from '../../../common/models/hol-scenario';
import { HolTag } from '../../../common/models/hol-tag';
import { DialogService } from '../../../common/services/dialog/dialog.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { GocEventService } from '../../../goc/services/goc-event-service/goc-event.service';
import { MccEventService } from '../../../mcc/services/mcc-event.service';
import { OclEvent } from '../../models/ocl-event.model';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { OclScenarioService } from '../../services/ocl-scenario-service/ocl-scenario.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclEventCloseModalComponent } from '../ocl-event-close-modal/ocl-event-close-modal.component';
import { AlertModalComponent } from 'src/app/common/modals/alert-modal/alert-modal.component';
import { HolObject } from '../../../common/models/hol-object';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { EventInfos } from '../../../common/models/hol-event';
import { utc } from 'moment';
import { HolChecklist } from '../../../common/models/hol-checklist';

@Component({
  selector: 'app-ocl-event-modal',
  templateUrl: './ocl-event-modal.component.html',
  styleUrls: ['./ocl-event-modal.component.scss'],
})
export class OclEventModalComponent implements OnInit, OnDestroy {
  loading: boolean;
  isCreate: boolean;
  isUtc: boolean;
  isReadOnly: boolean;
  form: FormGroup = new FormGroup({});
  scenarios: HolScenario[];
  selectedTags: HolTag[] = [];
  attachments: HolAttachments = new HolAttachments();
  notifications: HolNotification[] = [];
  fromLogbook: any;
  fromDecision: any;
  event: OclEvent;
  nextInfos: EventInfos[] = [];
  isNextInfoMandatory: boolean;
  fromOtherUnivers = false;
  eclInfos: EclInfos;
  hasToActivateECL = false;
  hasToDeactivateECL = false;
  hasEclAccess;
  eclName = '';
  initialEvent: OclEvent = new OclEvent();
  initialACL: Parse.ACL;
  public eventGroup: OclEventGroup[] = [];
  public linkedGroup: string[] = [];
  private infoHasBeenAdded = false;
  placeHolderDescription: string;
  private commonStoreManagerSub: Subscription;
  context;
  public airportsCode: Array<string>;
  public applicabilityIsReadOnly: boolean;
  duplicateToOtherModule = false;
  oldDuplicateToOtherModuleValue = null;
  newDuplicateToOtherModuleValue = null;
  copyToGOC: boolean;
  copyFromGOC: boolean;
  public objectKeys = Object.keys;
  public scenariosByGroup = {};
  public checkListTasks: HolChecklist[] = [];
  protected readonly isFinite = isFinite;
  protected readonly utc = utc;
  public get showCheckboxes(): boolean {
    return true;
  }

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected dialogService: DialogService,
    protected dialogRef: MatDialogRef<OclEventModalComponent>,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected scenarioService: OclScenarioService,
    @Inject('$translate') protected $translate,
    protected optionsService: OclOptionsService,
    protected mccEventsService: MccEventService,
    protected gocEventService: GocEventService,
    protected flightsStatusService: OclFlightStatusService,
    protected eventService: OclEventService,
    protected dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    protected commonStoreManager: CommonStoreManager,
    protected scenarioStoreManager: OclScenariosStoreManager,
    protected groupStoreManager: OclGroupsStoreManager,
    protected groupService: OclGroupService,
  ) {
    this.airportsCode = this.data.airportsCode;
    this.hasEclAccess = $rootScope.accessRights.ecl !== 'UNAUTHORIZED';
    this.notifications = data.notifications;
    this.isUtc = data.isUtc;
    this.fromLogbook = data.fromLogbook;
    this.fromDecision = data.fromDecision;
    this.isCreate = !data.event;

    this.fromOtherUnivers =
      this.data.event && (this.data.event.fromMCC === true || this.data.event.fromGOC === true || this.data.event.fromOCC === true);

    this.isReadOnly = data.isReadOnly;
    this.form.addControl('scenario', new FormControl());
    this.form.addControl('description', new FormControl());
    this.form.addControl('hasNI', new FormControl());
    this.form.addControl('hasToActivateCheckLists', new FormControl());
    this.placeHolderDescription = this.isCreate
      ? this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DESCRIPTION_LABEL')
      : this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DESCRIPTION_LABEL_EDIT_MODE');
  }

  async ngOnInit() {
    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'EVENT',
      htmlTitle: this.form.value.description ? this.form.value.description : '',
      htmlDate: this.event && this.event.createdAt ? this.event.createdAt : undefined,
      htmlTemplate: 'A',
    };
    // Groups
    this.groupStoreManager.groupsState.subscribe(g => {
      this.eventGroup = g.events;
    });

    if (!this.isCreate) {
      this.initialEvent = cloneDeep(this.data.event);
      if (this.initialEvent.fromGOC) {
        this.form.addControl(
          'fromGOC',
          new FormControl({
            value: this.initialEvent.fromGOC ? true : false,
          }),
        );
      }
      if (this.moduleConfig.config.activateEventNotification) {
        this.form.addControl(
          'hasToActivateNotification',
          new FormControl({
            value: this.moduleConfig.config.forceEventNotification,
            disabled: this.moduleConfig.config.forceEventNotification,
          }),
        );
      }
    }
    this.copyToGOC = cloneDeep(this.initialEvent.toGOC);
    this.copyFromGOC = cloneDeep(this.initialEvent.fromGOC);
    this.form.addControl(
      'toGOC',
      new FormControl({
        value: this.initialEvent.toGOC ? this.initialEvent.toGOC : false,
        disabled: this.isReadOnly || this.initialEvent.closeReason,
      }),
    );
    this.commonStoreManagerSub = this.commonStoreManager.commonState.subscribe(commonState => {
      this.eclInfos = commonState.eclInfos;
    });
    this.isNextInfoMandatory =
      this.optionsService.getIsNextInfoMandatory() !== undefined ? this.optionsService.getIsNextInfoMandatory() : true;

    await this.initScenarios(this.data);

    if (!this.isCreate) {
      this.form.get('scenario').setValue(this.scenarios.find(s => s.code === this.initialEvent.scenario.code));
      this.form.get('description').setValue(this.initialEvent.description);
      this.selectedTags = cloneDeep(this.initialEvent.tags);
      this.attachments = cloneDeep(this.initialEvent.attachments);
      this.nextInfos = this.initialEvent.infos.map(i => {
        return { info: i, notifications: null };
      });
      this.form.get('hasToActivateCheckLists').setValue(this.initialEvent.checklistActivated);
      this.initialACL = cloneDeep(this.initialEvent.acl);
    } else {
      if (this.moduleConfig.config.forceEventChecklistActivation) {
        this.form.get('hasToActivateCheckLists').setValue(true);
      }
      if (this.optionsService.getDefaultScenario()) {
        const defaultScenario = this.scenarios.find(s => s.code === this.optionsService.getDefaultScenario());
        if (defaultScenario) {
          this.form.get('scenario').setValue(defaultScenario);
        }
      }
      if (this.fromDecision) {
        this.form.get('description').setValue(this.fromDecision.message);
        this.attachments = this.fromDecision.attachments;
        this.selectedTags = this.fromDecision.tags;
        if (this.fromDecision.acl) {
          this.initialEvent.acl = this.fromDecision.acl;
        }
      }
      if (this.fromLogbook) {
        this.form.get('description').setValue(this.fromLogbook.text);
        this.attachments = this.fromLogbook.attachments;
        this.selectedTags = this.fromLogbook.tags;
        if (this.fromLogbook.acl) {
          this.initialEvent.acl = this.fromLogbook.acl;
        }
      }
    }
    if (this.data.event) {
      this.getCheckListTasks().then();
    }
  }
  private async getCheckListTasks(): Promise<void> {
    this.loading = true;
    this.checkListTasks = await this.eventService.getCheckListTaskByEvent(this.data.event.objectId, this.context.module);
    this.loading = false;
  }
  private async initScenarios(data: { scenarios: HolScenario[] }) {
    if (data.scenarios) {
      // If scenarios are defined in dialog data
      this.scenarios = data.scenarios;
    } else {
      // If scenarios are not defined in dialog data
      this.scenarios = await this.scenarioStoreManager.scenariosState.pipe(take(1)).toPromise();
    }

    this.scenarios = orderBy(this.scenarios, 'code');

    if (this.moduleConfig.config.scenariosByGroup) {
      this.scenariosByGroup = this.scenarioService.orderByGroupingCode(this.scenarios);
    }
  }

  validate() {
    if (this.initialEvent.gocEvent && !this.form.get('fromGOC').value) {
      this.deleteGocEvent();
    } else {
      this.oldDuplicateToOtherModuleValue = this.initialEvent.toGOC;
      this.newDuplicateToOtherModuleValue = this.form.get('toGOC').value;
      this.duplicateToOtherModule = this.form.get('toGOC').value;
      this.initialEvent.toGOC = this.form.get('toGOC').value;

      if (this.isCreate) {
        this.createEvent();
      } else {
        this.updateEvent();
      }
    }
  }

  updateEvent() {
    if (this.fromOtherUnivers && this.moduleConfig.config.moduleName === 'goc' && !this.initialEvent.fromOCC) {
      return this.dialogRef.close(this.initialEvent);
    }

    const event = cloneDeep(this.initialEvent);
    event.description = this.form.get('description').value;
    event.scenario = this.form.get('scenario').value;
    event.attachments = this.attachments;
    event.infos = this.nextInfos.map(ni => ni.info);
    event.tags = this.selectedTags;
    event.checklistActivated = this.form.get('hasToActivateCheckLists').value;

    const isChecklistActivatedInEditMode = !this.initialEvent.checklistActivated && this.form.get('hasToActivateCheckLists').value;
    const isNotificationUpdateActivated =
      this.moduleConfig.config.activateEventNotification && this.form.get('hasToActivateNotification').value;
    // Next info ajoutée et activate checklists checked
    if (this.initialEvent.checklistActivated && this.infoHasBeenAdded && isNotificationUpdateActivated) {
      const newInfos = differenceWith(event.infos, this.data.event.infos, (a: HolNextInfo, b: HolNextInfo) => a.objectId === b.objectId);
      this.eventService.sendNewEventInfoChecklistActivatedNotifications(event, newInfos);
    }
    if (this.hasToActivateECL) {
      this.eventService.activateECLModule(this.eclInfos.eclOptionsId, this.eclName, event, []);
    }
    if (this.hasToDeactivateECL) {
      this.eventService.deactivateECLModule(this.eclInfos.eclOptionsId);
    }

    if (
      !isEqual(event, this.initialEvent) ||
      !isEqual(event.acl, this.initialACL) ||
      this.isApplicabilityChange() ||
      this.isDuplicateChange()
    ) {
      this.loading = true;
      this.eventService
        .update(
          event,
          isChecklistActivatedInEditMode,
          this.context,
          this.newDuplicateToOtherModuleValue,
          this.oldDuplicateToOtherModuleValue,
        )
        .then(() => {
          // activate checklist checked in edit mode
          if (isChecklistActivatedInEditMode) {
            this.eventService.sendNewEventInfoChecklistActivatedNotifications(this.event, this.event.infos);
          }
        })
        .finally(() => {
          this.loading = false;
          event.updateACL();
          this.dialogRef.close(event);
        });
    } else {
      this.dialogRef.close(event);
    }
    Promise.all(this.updateGroupSelected())
      .then(() => {
        this.groupService.fetchNewDataEventGroup();
      })
      .catch(error => console.error('Error creating OCLGroup', error))
      .finally(() => {
        this.loading = false;
      });
  }

  createEvent() {
    this.loading = true;

    const event = cloneDeep(this.initialEvent);
    event.description = this.form.get('description').value;
    event.scenario = this.form.get('scenario').value;
    event.attachments = this.attachments;
    event.tags = this.selectedTags;
    event.checklistActivated = this.form.get('hasToActivateCheckLists').value;

    this.eventService
      .create(
        event,
        this.nextInfos,
        this.notifications,
        this.hasToActivateECL,
        this.eclInfos,
        this.eclName,
        this.context,
        this.duplicateToOtherModule,
      )
      .then(newEvent => {
        const infoPromises = [];
        for (let i = this.nextInfos.length - 1; i >= 0; i--) {
          const tempInfo = this.nextInfos[i];
          infoPromises.push(
            this.eventService.addInfo(
              newEvent,
              tempInfo.info.message,
              tempInfo.info.nextInfoTime,
              tempInfo.notifications,
              tempInfo.info.attachments,
            ),
          );
        }
        Promise.all(infoPromises)
          .then(() => {
            this.occEventsStoreManager.addOneEvent(newEvent);
            this.dialogRef.close(newEvent);
            this.updateGroupSelected(newEvent);
          })
          .catch(error => console.error('Error creating OCCEventInfo', error))
          .finally(() => {
            this.groupService.fetchNewDataEventGroup().then();
            this.loading = false;
          });
      })
      .catch(error => {
        if (error && error.message) {
          this.dialog.open(AlertModalComponent, {
            data: {
              modalTitle: 'Error creating event',
              modalContent: error.message,
              modalType: 'info',
            },
          });
        }
        console.error('Error creating OCCEvents', error);
      })
      .finally(() => (this.loading = false));
  }

  openAddNextInfoModal() {
    const dialogRef = this.dialogService.openAddNextInfo({
      data: {
        title: this.getNextInfoTitle(),
        context: {
          module: this.moduleConfig.config.moduleName,
          category: 'NEXT_INFO',
          htmlTitle: this.form.controls.description.value,
          htmlScenario: this.getScenario(),
          htmlDate: this.initialEvent.createdAt,
          htmlTemplate: 'C',
        },
        isUtc: this.isUtc,
        notifications: this.isCreate ? [] : this.notifications,
        defaultNextInfoDelay: this.optionsService.getDefaultNextInfoDelay(),
        acl: cloneDeep(this.initialEvent.acl),
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(tempInfo => {
        if (tempInfo) {
          this.infoHasBeenAdded = true;
          if (this.isCreate) {
            this.nextInfos.unshift(tempInfo);
          } else {
            if (tempInfo) {
              if (this.initialEvent.fromMCC) {
                this.addMCCNextInfo(tempInfo);
                // } else if (this.initialEvent.fromGOC) {
                // this.addGOCNextInfo(tempInfo);
              } else {
                this.addNextInfo(tempInfo);
              }
            }
          }
        }
      });
  }

  openDetailsModal($event: MouseEvent, item: HolNextInfo) {
    this.dialogService
      .openDetailNextInfo({
        data: {
          info: item,
          title: this.getNextInfoTitle(),
          context: {
            module: this.moduleConfig.config.translateKey,
            category: 'NEXT_INFO',
            htmlTitle: this.form.controls.description.value,
            htmlScenario: this.getScenario(),
            htmlDate: this.initialEvent.createdAt,
            htmlTemplate: 'C',
          },
          isUtc: this.isUtc,
          isReadOnly: this.isReadOnly || this.fromOtherUnivers,
          canEditNi: this.moduleConfig.config.canEditNi,
        },
      })
      .afterClosed()
      .toPromise()
      .then(info => {
        if (info) {
          this.loading = true;
          this.eventService
            .updateInfo(info)
            .then(newInfo => {
              Object.assign(item, newInfo);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
  }

  markAsDone(item) {
    this.eventService.markInfoAsDone(item, this.initialEvent, !item.done, true).then(eventInfo => {
      extend(item, eventInfo);
      this.flightsStatusService.setNeedsUpdate(true);
    });
  }

  public getScenario() {
    if (this.initialEvent && this.initialEvent.scenario) {
      return this.initialEvent.scenario.code + this.initialEvent.order + ' - ' + this.initialEvent.scenario.title;
    }
    if (this.form.controls.scenario && this.form.controls.scenario.value) {
      return this.form.controls.scenario.value.code + ' - ' + this.form.controls.scenario.value.title;
    }
    return '';
  }

  archive() {
    this.dialog
      .open(OclEventCloseModalComponent, {
        data: {
          event: this.initialEvent,
          notifications: this.notifications,
          hasToDeactivateECL: this.eclInfos && this.eclInfos.occEventId === this.initialEvent.objectId,
          eclOptionsId: this.eclInfos ? this.eclInfos.eclOptionsId : undefined,
        },
      })
      .afterClosed()
      .toPromise()
      .then(reason => {
        if (reason !== undefined && reason !== '') {
          this.dialogRef.close(reason);
        }
      });
  }

  private addNextInfo(tempInfo) {
    this.eventService
      .addInfo(this.initialEvent, tempInfo.info.message, tempInfo.info.nextInfoTime, tempInfo.notifications, tempInfo.info.attachments)
      .then(newInfo => {
        this.nextInfos.unshift({ info: newInfo, notifications: null });
        this.flightsStatusService.setNeedsUpdate(true);
      })
      .catch(error => console.error('Add OCC next info failed', error));
  }

  private addMCCNextInfo(tempInfo) {
    this.mccEventsService
      .addInfo(
        this.initialEvent as any,
        tempInfo.info.message,
        tempInfo.info.nextInfoTime,
        tempInfo.notifications,
        tempInfo.info.attachments,
        null,
      )
      .then(() => {
        this.nextInfos = this.initialEvent.infos
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .map(i => {
            return { info: i, notifications: null };
          });
      })
      .catch(error => console.error('Add MCC next info failed', error));
  }

  private getNextInfoTitle() {
    let title;
    const scenario = this.form.get('scenario').value;
    if (this.initialEvent && this.initialEvent.scenario) {
      title = `${this.initialEvent.scenario.code || ''}${this.initialEvent.order || ''} - ${this.initialEvent.scenario.title}`;
    } else if (scenario) {
      title = `${scenario.code} - ${scenario.title}`;
    } else {
      title = 'NEXT INFO';
    }
    return title;
  }

  isApplicabilityChange(): boolean {
    return false;
  }

  isDuplicateChange(): boolean {
    return !isEqual(this.copyToGOC, this.initialEvent.toGOC) || !isEqual(this.copyFromGOC, this.initialEvent.fromGOC);
  }

  deleteGocEvent(): void {
    this.eventService.deleteDuplicateGocEvent(this.initialEvent);
    this.dialogRef.close();
  }

  saveNotifications(notifications: HolNotification[]) {
    this.notifications = cloneDeep(notifications);
  }

  ngOnDestroy(): void {
    this.commonStoreManagerSub.unsubscribe();
  }

  get limitTo(): HolObject | undefined {
    const value = this.form.get('scenario').value;
    return value;
  }

  public updateLinkedGroup(e) {
    this.linkedGroup = e;
  }

  protected updateGroupSelected(event?: OclEvent): Promise<any>[] {
    const listGroupPromise = [];

    // First we remove this item from all groups & then we add the item to the list of groups.
    this.eventGroup.forEach(lgr => {
      if (this.data.event) lgr.items = lgr.items.filter(lg => lg.objectId !== this.data.event.objectId);
      if (this.linkedGroup.includes(lgr.objectId)) {
        if (event) {
          lgr.items.push(event);
        } else {
          lgr.items.push(this.data.event);
        }
      }
      listGroupPromise.push(this.groupService.save(lgr, 'EVENT'));
    });

    return listGroupPromise;
  }
}
