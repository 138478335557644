import { OpsModuleConfigService } from 'src/app/ops/services/ops-module-config/ops-module-config.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OpsOptionsService } from '../ops-options-service/ops-options.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { Moment } from 'moment';
import { OpsFlightService } from '../ops-flight-service/ops-flight.service';
import * as moment from 'moment';
import { OclHistoryLog } from '../../../ocl/models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export class OpsHistoryService extends OclHistoryService<OclHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('OPSLogs');
  protected ParseEvents = Parse.Object.extend('OPSEvents');
  protected ParseDecisions = Parse.Object.extend('OPSDecisions');
  protected ParseLogbooks = Parse.Object.extend('OPSLogBook');
  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: OpsOptionsService,
    protected parseMapper: ParseMapperService,
    private opsFlightService: OpsFlightService,
    protected moduleConfig: OpsModuleConfigService,
  ) {
    super(requestService, $rootScope, optionsService, parseMapper, moduleConfig);
  }

  public getLogsByTimeSlots(forceToRefresh: boolean, fromDate?: Moment, toDate?: Moment): Promise<any[]> {
    return super.getLogsByTimeSlots(forceToRefresh, fromDate, toDate).then(timeSlots => {
      return this.opsFlightService.getByTimeSlots(forceToRefresh, fromDate, toDate).then(flights => {
        flights.forEach(flight => {
          const bufferIndex = timeSlots.findIndex(tm => moment(flight.std).isBetween(tm.timeEnd, tm.timeStart, undefined, '[)'));
          if (bufferIndex !== -1) {
            if (!timeSlots[bufferIndex].logs.flights) {
              timeSlots[bufferIndex].logs.flights = [];
            }
            timeSlots[bufferIndex].logs.flights.unshift(flight);
          }
        });
        return timeSlots;
      });
    });
  }
}
