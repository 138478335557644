import { HolObject } from './hol-object';

export class HolFunction extends HolObject {
  functionId: string;
  shortTitle: string;
  title: string;
  phone: string;
  email: string;
  minutesBeforeAlert: number;
  order: number;
  modules: string[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.functionId = parseObject.get('functionId');
    this.phone = parseObject.get('phone');
    this.title = parseObject.get('title');
    this.shortTitle = parseObject.get('shortTitle');
    this.email = parseObject.get('email');
    this.minutesBeforeAlert = parseObject.get('minutesBeforeAlert');
    this.order = parseObject.get('order');
    this.modules = parseObject.get('modules') ? parseObject.get('modules').split('|') : [];
  }
}
