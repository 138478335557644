<div *ngIf="vacation" class="new-holis-theme vacation">
  <div class="vacation__top">
    <div class="vacation__top-title">
      <span class="user-name">
        <span *ngFor="let company of vacation.companies">
          <app-acl-indicator [object]="{ companies: [company] }"></app-acl-indicator>
        </span>
        {{ vacation.user.firstName }} {{ vacation.user.lastName }}</span
      >
      &nbsp;&nbsp;
      <span class="date">
        {{ vacation.createdAt | time: !isReadOnly }}
      </span>
      <br />
      <span class="function-title">
        {{ vacation.holFunction?.title }}
      </span>
    </div>
    <button
      *ngIf="!isReadOnly && vacation.isHolder"
      type="button"
      (click)="onArchiveItem($event, vacation)"
      mat-stroked-button
      color="primary"
    >
      <span class="hol-icon-archive"></span>
      <span class="btn-label">{{ 'COMMON.BUTTON.TO_ARCHIVE' | translate }}</span>
    </button>
  </div>
  <div class="vacation__middle">
    <ng-container *ngFor="let checklist of vacation.checklists | vacationChecklistSort; index as index; trackBy: trackByFunction">
      <app-checklist-vac
        [checklistInput]="checklist"
        [index]="index"
        [isReadOnly]="isReadOnly"
        [mod]="mod"
        [vacationId]="vacation.objectId"
        [vacationTitle]="vacationTitle"
        [isHolder]="vacation.isHolder"
      ></app-checklist-vac>
    </ng-container>
  </div>
  <div class="vacation__bottom">
    <div *ngIf="vacation.completedAt" class="hol-done">
      <span>{{ 'TASKS.COMPLETED_AT' | translate }} {{ vacation.completedAt | time: isUtc }}</span>
    </div>
  </div>
</div>
