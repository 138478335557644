import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent implements OnInit {
  title: string;
  event: any;
  context: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data) {
    this.title = data.title;
    this.event = data.event;
    this.context = data.context;
  }

  ngOnInit() {}
}
