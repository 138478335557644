import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolObject } from '../models/hol-object';

class ClosableObject extends HolObject {
  closeReason?: number;
  closeReasonText?: string;
  closeDate?: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ArchiveService {
  constructor(private requestService: RequestService) {}

  close<T extends ClosableObject>(item: T, parseClassName: string, reason: string, reasonText: string): Promise<T> {
    const ParseClass = Parse.Object.extend(parseClassName);
    const parseItem = new ParseClass({ id: item.objectId });

    parseItem.set('closeReason', reason);
    parseItem.set('closeDate', new Date());

    if (reasonText) {
      parseItem.set('closeReasonText', reasonText);
    }

    return new Promise((resolve, reject) => {
      this.requestService.performSaveQuery(
        parseItem,
        null,
        parseData => {
          item.closeReason = parseData.get('closeReason');
          item.closeReasonText = parseData.get('closeReasonText');
          item.closeDate = parseData.get('closeDate');
          resolve(item);
        },
        error => reject(error)
      );
    });
  }
}
