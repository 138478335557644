import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclFlightStatusService } from '../../../ocl/services/ocl-flight-status-service/ocl-flight-status.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';

@Injectable({
  providedIn: 'root',
})
export class EclFlightStatusService extends OclFlightStatusService {
  // tslint:disable:variable-name
  protected FlightsStatus = Parse.Object.extend('ECLFlightsStatus');
  // tslint:enabled

  public constructor(
    protected optionsService: EclOptionsService,
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected historyService: EclHistoryService
  ) {
    super(optionsService, requestService, $rootScope, historyService);
  }
}
