import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';

import { EclSummary } from 'src/app/ecl/models/ecl-summary';

@Component({
  selector: 'app-ocl-summary',
  templateUrl: './ocl-summary.component.html',
  styleUrls: ['./ocl-summary.component.scss'],
})
export class OclSummaryComponent {
  @Input() summary: EclSummary;
  @Input() isInSummary;
  @Input() isInBriefing;
  @Input() invertedStyle = false;
  @Input() isReadOnly = false;

  @Output() isInSummaryChange = new EventEmitter();
  @Output() isInBriefingChange = new EventEmitter();

  constructor(public moduleConfig: ModuleConfigService) {}

  onModelChange(event, type) {
    this[`${type}Change`].emit(event);
    if (type === 'isInSummary' && event === false) {
      this[`isInBriefingChange`].emit(false);
    }
  }
}
