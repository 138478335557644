<div
  class="flights-status-indicator"
  [class.lowest-criticity]="flightStatus?.criticity === 0"
  [class.low-criticity]="flightStatus?.criticity === 1"
  [class.medium-criticity]="flightStatus?.criticity === 2"
  [class.high-criticity]="flightStatus?.criticity === 3"
  [class.highest-criticity]="flightStatus?.criticity === 4"
  [class.no-color]="noColor"
>
  <div class="flights-status-indicator" [class.animate]="!readonly && flightStatus?.needsUpdate"></div>
</div>
