import { Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';

@Injectable({
  providedIn: 'root',
})
export class ReferentialService {
  ParseReferentialFiles = Parse.Object.extend('ReferentialFiles');

  constructor(private requestService: RequestService) {}

  public uploadFile(file: File, option): Promise<Parse.Object> {
    return new Promise((resolve, reject) => {
      const refQuery = new Parse.Query(this.ParseReferentialFiles);
      this.requestService.performFirstQuery(
        refQuery,
        referentialFiles => {
          if (!referentialFiles) {
            referentialFiles = new this.ParseReferentialFiles();
          }
          referentialFiles.set(option.tableName, new Parse.File(file.name, file));
          this.requestService.performSaveQuery(referentialFiles, null, resolve, reject);
        },
        reject,
      );
    });
  }

  public getReferentialFiles(): Promise<Parse.Object> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(this.ParseReferentialFiles);
      this.requestService.performFirstQuery(query, resolve, reject);
    });
  }

  public getImgBackgroundFile(): Promise<Parse.Object> {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query('ReferentialFiles');
      query.select('backgroundImage');
      this.requestService.performFirstQuery(query).then(result => {
        console.log('result', result);

        const backgroundImageFile = result.get('backgroundImage');
        if (!backgroundImageFile) {
          reject('No background image');
          return;
        }
        resolve(backgroundImageFile.url());
      }, reject);
    });
  }

  public testFile(option: any): Promise<any> {
    return new Promise((resolve, reject) => this.requestService.performCloudCode('testFile', { option }, resolve, reject));
  }

  public getTranslations(): Promise<any> {
    return new Promise((resolve, reject) => this.requestService.performCloudCode('getTranslations', null, resolve, reject));
  }

  public importFile(option: any, startTime: number): Promise<any> {
    option.startTime = startTime;
    return new Promise((resolve, reject) => this.requestService.performCloudCode('importFile', { option }, resolve, reject));
  }
}
