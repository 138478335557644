export class MccAtaCode {
  objectId: string;
  code: string;
  description: string;
  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.code = parseObject.get('code');
    this.description = parseObject.get('description');
  }
}
