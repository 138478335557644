import { Injectable } from '@angular/core';
import { OclEventTagService } from '../../../ocl/services/ocl-event-tag-service/ocl-event-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OccEventTagService extends OclEventTagService {
  // tslint:disable:variable-name
  protected ParseEventTag = Parse.Object.extend('OCCEventTag');
  protected ParseEvents = Parse.Object.extend('OCCEvents');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enable
}
