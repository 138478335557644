import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclDecisionTagService } from '../../../ocl/services/ocl-decision-tag-service/ocl-decision-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OpsDecisionTagService extends OclDecisionTagService {
  // tslint:disable:variable-name
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseTag = Parse.Object.extend('OPSTag');
  // tslint:enabled
}
