import { Injectable } from '@angular/core';
import { OclGroupService } from '../../../ocl/services/ocl-group-service/ocl-group.service';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { RequestService } from '../../../common/services/request.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OpsGroupService extends OclGroupService {
  // tslint:disable:variable-name
  ParseEventGroup = Parse.Object.extend('OPSEventGroup');
  ParseDecisionGroup = Parse.Object.extend('OPSDecisionGroup');
  ParseLogbookGroup = Parse.Object.extend('OPSLogBookGroup');
  ParseGlobalInstructionGroup = Parse.Object.extend('OPSGlobalInstructionGroup');
  ParseEvent = Parse.Object.extend('OPSEvent');
  ParseDecisions = Parse.Object.extend('OPSDecisions');
  ParseLogbook = Parse.Object.extend('OPSLogBook');
  ParseGlobalInstruction = Parse.Object.extend('OPSGlobalInstruction');
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: OpsModuleConfigService
  ) {
    super(requestService, groupStoreManager, moduleConfig);
  }
}
