import { Injectable } from '@angular/core';
import { FltFlightLogbookService } from '../../../flt/services/flt-flight-logbook.service';
import { RequestService } from '../../../common/services/request.service';
import { OpsFlightService } from '../ops-flight-service/ops-flight.service';
import { OpsFlight } from '../../models/ops-flight.model';
import { FltFlightLogbook } from '../../../flt/models/flt-flight-logbook';
import { HolTag } from '../../../common/models/hol-tag';
import { groupBy, Dictionary } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class OpsFlightLogbookService extends FltFlightLogbookService {
  // tslint:disable:variable-name
  protected ParseFlightLogbook = Parse.Object.extend('OPSFlightLogbook');
  protected ParseFlightLogbookTag = Parse.Object.extend('OPSFlightLogbookTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  protected ParseLogbookTag = Parse.Object.extend('OPSLogBookTag');
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseLogbook = Parse.Object.extend('OPSLogBook');
  // tslint:enable
}
