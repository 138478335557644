angular.module('gestiondecriseApp').directive('crewEventItem', function () {
  return {
    restrict: 'EA',
    replace: false,
    templateUrl: 'scripts/directives/crew/crewEventItem/crewEventItem.html',
    scope: {
      event: '=',
      onlyHeader: '@',
    },
    controller: function ($scope, CrewEventsService) {
      $scope.isHotEvent = function (event) {
        //nb en attente + nb pas contactés <= nb besoins
        return (
          !$scope.isClosedEvent(event) &&
          CrewEventsService.getNbPotentiallyRemainingUser(event, 'WAITING') <=
            event.nbPersons - CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED')
        );
      };

      $scope.isClosedEvent = function (event) {
        return CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED') >= event.nbPersons;
      };

      $scope.getNbAccepted = function (event) {
        return CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED');
      };

      $scope.getNbWaiting = function (event) {
        return CrewEventsService.getNbUserBySituationStatus(event, 'WAITING');
      };

      $scope.getNbRefused = function (event) {
        return CrewEventsService.getNbUserBySituationStatus(event, 'REFUSED');
      };
    },
  };
});
