import { Injectable } from '@angular/core';

import { HolContext } from 'src/app/common/models/hol-context.model';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { RolesService } from 'src/app/common/services/roles.service';
import { HelperService } from 'src/app/common/services/helper.service';
import { FltApplicabilityService } from 'src/app/flt/services/flt-applicability.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { RequestService } from 'src/app/common/services/request.service';
import { HolTag } from 'src/app/common/models/hol-tag';
import { isEqual } from 'lodash';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';
import { FilesService } from 'src/app/common/services/files.service';

import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { FltApplicability } from '../../../flt/models/flt-applicability';
import { FltFlightLogbookService } from '../../../flt/services/flt-flight-logbook.service';
import { GocFlightLogbook } from '../../models/goc-flight-logbook.model';
import { GocSmsService } from '../goc-sms-service/goc-sms.service';
import { GocMailService } from '../goc-mail-service/goc-mail.service';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class GocFlightLogbookService extends FltFlightLogbookService<GocFlightLogbook> {
  // tslint:disable:variable-name
  protected ParseFlightLogbook = Parse.Object.extend('GOCFlightLogbook');
  protected ParseFlightLogbookTag = Parse.Object.extend('GOCFlightLogbookTag');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseDecisionTag = Parse.Object.extend('GOCDecisionTag');
  protected ParseLogbookTag = Parse.Object.extend('GOCLogBookTag');
  protected ParseDecision = Parse.Object.extend('GOCDecisions');
  protected ParseLogbook = Parse.Object.extend('GOCLogBook');

  private ParseOccLogbook = Parse.Object.extend('OCCLogBook');

  // tslint:enable
  protected constructor(
    protected readonly filesService: FilesService,
    protected requestService: RequestService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    private rolesService: RolesService,
    private helperService: HelperService,
    protected parseMapper: ParseMapperService,
    protected notificationService: NotificationsService,
    protected smsService: GocSmsService,
    protected mailService: GocMailService,
  ) {
    super(filesService, requestService, markdownService, applicabilityService, notificationService, smsService, mailService);
  }

  // public getAllToOcc(): Promise<GocLogbook[]> {
  //   return new Promise((resolve, reject) => {
  //     const date = moment(new Date()).utc().subtract(1, 'days');
  //     const query = new Parse.Query(this.ParseFlightLogbook);
  //     query.doesNotExist('closeReason');
  //     query.equalTo('toOCC', true);
  //     query.greaterThan('limitDateOCC', date.toDate());
  //     query.include('flight1');
  //     query.include('createdBy');
  //     query.include('flight2');
  //     this.requestService.performFindAllQuery(
  //       query,
  //       parseGocLogbooks => resolve(map(parseGocLogbooks, parseGocLogbook => new GocLogbook(parseGocLogbook))),
  //       error => reject(error)
  //     );
  //   });
  // }

  public setAdditionalFields(flightLogbook: GocFlightLogbook, parseFlightLogbook: Parse.Object) {
    parseFlightLogbook.set('isPrivate', flightLogbook.isPrivate);
    parseFlightLogbook.set('toOCC', flightLogbook.toOCC);
  }

  public create(
    flightLogbook: GocFlightLogbook,
    notifications?: HolNotification[],
    applicability?: FltApplicability,
    context?: HolContext,
    duplicateToOtherModule?: boolean,
  ): Promise<GocFlightLogbook> {
    return super.create(flightLogbook, notifications, applicability, context).then(savedFlightLogbook => {
      if (duplicateToOtherModule) {
        this.duplicateLogbookToOtherModule(savedFlightLogbook);
      }
      return savedFlightLogbook;
    });
  }

  public update(
    flightLogbook: GocFlightLogbook,
    applicability?: FltApplicability,
    context?: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean,
  ): Promise<GocFlightLogbook> {
    return super.update(flightLogbook, applicability, context).then(updatedFlightLogbook => {
      // Duplicate to an other module
      if (!isEqual(newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue)) {
        // Supprimer
        if (!newDuplicateToOtherModuleValue && oldDuplicateToOtherModuleValue) {
          this.deleteDuplicateLogbookFromModule(flightLogbook);
        }
        // Ajouter
        if (newDuplicateToOtherModuleValue && !oldDuplicateToOtherModuleValue) {
          this.duplicateLogbookToOtherModule(flightLogbook);
        }
      }
      return updatedFlightLogbook;
    });
  }

  deleteOccLogbook(flightLogbook: GocFlightLogbook): void {
    const parseGocLogbook = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
    const parseOccLogbook = new this.ParseOccLogbook({ id: flightLogbook.occLogbook.objectId });
    parseOccLogbook.set('toGOC', false);
    this.requestService.performSaveQuery(parseOccLogbook).then(() => {
      this.requestService.performDestroyQuery(
        parseGocLogbook,
        () => {
          return;
        },
        error => {
          console.log(error);
        },
      );
    });
  }

  protected newFlightLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): GocFlightLogbook {
    return new GocFlightLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag'))));
  }

  protected async duplicateLogbookToOtherModule(flightLogbook: GocFlightLogbook) {
    const parseOccLogbook = new this.ParseOccLogbook();
    parseOccLogbook.set('gocLogbook', new this.ParseFlightLogbook({ id: flightLogbook.objectId }));
    parseOccLogbook.set('flight', new this.ParseFlight({ id: flightLogbook.flight.objectId }));
    const companies = this.helperService.parseACL(flightLogbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['OCC'], companies);
    parseOccLogbook.setACL(acl);
    this.requestService.performSaveAllQuery(parseOccLogbook).then();
  }

  protected deleteDuplicateLogbookFromModule(flightLogbook: GocFlightLogbook) {
    const parseGocLogbook = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
    const query = new Parse.Query(this.ParseOccLogbook);
    query.equalTo('gocLogbook', parseGocLogbook);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const occLogbook = new OclLogbook(result);
        const parseOccLogbook = new this.ParseOccLogbook({ id: occLogbook.objectId });
        this.requestService.performDestroyQuery(parseOccLogbook).then();
      }
    });
  }
}
