import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { HolTag } from '../models/hol-tag';

@Injectable({
  providedIn: 'root',
})
export class TagChangeService {
  public tagsChanged = new Subject<HolTag[]>();
  private currentTagFilter: HolTag[] = [];

  getCurrentTagFilter() {
    return this.currentTagFilter.slice();
  }

  setCurrentTagFilter(filters: HolTag[]) {
    this.currentTagFilter = cloneDeep(filters);
    this.tagsChanged.next(this.currentTagFilter);
  }
}
