import { OclLogbook } from '../../../ocl/models/ocl-logbook.model';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { MccEvent } from 'src/app/mcc/models/mcc-event.model';
import { MccMel } from 'src/app/mcc/models/mcc-mel.model';
import { EventInfos, HolEvent } from '../../models/hol-event';
import { HolNextInfo } from '../../models/hol-next-info.model';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { OclDecision } from '../../../ocl/models/ocl-decision.model';
import { HolOptionsService } from '../hol-options.service';
import { OclTask } from '../../../ocl/models/ocl-task';
import { timeContent, messageWithAttachments, table, row, timeCell, cell, Styles, styleToString } from './mail-template.utils';
import { HolAttachments } from '../../models/hol-attachments.model';
import { OclGlobalInstruction } from 'src/app/ocl/models/ocl-global-instruction.model';

const P_SEPARATOR_HTML = '<br/><br/>';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateService {
  constructor(private translatePipe: TranslatePipe, private holOptionsService: HolOptionsService) {}

  public getDecisionMessage<T extends OclDecision>(decision: T): string {
    const cells = [];
    // Cell 1: Time info
    cells.push(timeCell(decision.createdAt));
    // Cell 2. Event
    if (decision.event) {
      const eventName = (decision.event.scenario ? decision.event.scenario.code : '') + decision.event.order;
      const spanStyles: Styles = {
        padding: '5px 10px',
        'background-color': '#F1F5F5',
        color: '#3F6185',
        'border-radius': '4px',
      };
      const span = `<span style=${styleToString(spanStyles)}>${eventName}</span>`;
      cells.push(cell(span, { 'text-align': 'center' }));
    }
    // Cell 3+4. Message and attachments
    cells.push(this.messageWithAttachments(decision.message, decision.attachments));
    // Cell 5. Next info
    if (decision.nextInfoTime) {
      cells.push(cell(timeContent(decision.nextInfoTime), { width: '1px', 'text-align': 'right' }));
    }
    return table([row(cells, false)]);
  }

  public getGlobalInstructionMessage<T extends OclGlobalInstruction>(globalInstruction: T): string {
    const cells = [];
    // Cell 1: Time info
    cells.push(timeCell(globalInstruction.createdAt));
    // Cell 2. Event

    cells.push(this.messageWithAttachments(globalInstruction.description, globalInstruction.attachments));
    // Cell 5. Next info

    return table([row(cells, false)]);
  }

  public getEclActivatedMessage(event: HolEvent, infos: EventInfos[], translateKey: string): string {
    if (!translateKey) {
      translateKey = 'OCC';
    }
    let msg = '';

    msg +=
      '<tr><td bgcolor="#ffffff"><table cellpadding="0" cellspacing="0" width="100%" style="width:100%!important" align="center"><tbody><tr><td width="35px"></td><td style="padding-top:0px;padding-bottom:30px;border-bottom:1px solid #e0e0e0"><table align="center" cellpadding="0" cellspacing="0" width="100%" style="width:100%!important"><tbody><tr>';
    msg += this.translatePipe.transform('MAIL.' + translateKey + '.ECL_ACTIVATED', { eclUrl: location.origin + '/#/ecl/dashboard' });
    msg += '</td></tr><tr><td align="center"></td></tr></tbody></table></td><td width="35px"></td></tr></tbody></table></td></tr>';
    msg += '<tr><td style="padding-bottom: 20px;"></td></tr>';
    msg +=
      '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

    msg += '<tr>';
    msg += '<td style="border-bottom: 1px solid #E7E7E7">';
    msg += '<table width="100%" style="width:100%!important;">';

    msg += '<tr style="color: #3F6185">';
    msg += '<td width="1px"><strong>' + (event.scenario ? event.scenario.code : '') + event.order + '</strong></td>';
    msg += '<td style="padding-left: 5px;"><strong>' + (event.scenario ? event.scenario.title : '') + '</strong></td>';
    msg += '</tr>';

    msg += '<tr>';
    msg += timeCell(event.createdAt);
    msg += this.messageWithAttachments(`<strong style="padding-left: 5px;">${event.description}</strong>`, event.attachments);
    msg += '</tr>';

    msg += '</table>';
    msg += '</td>';
    msg += '</tr>';

    if (infos && infos.length) {
      const info = infos[0].info;
      msg += '<tr>';
      msg += '<td>';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr>';
      msg += this.messageWithAttachments(info.message, info.attachments);
      msg += '</tr>';

      msg += '<tr>';
      msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
      msg += '</tr>';

      msg += '</table>';
    }

    msg += '</td></tr></table>';
    msg +=
      '<p style="color: black">' +
      this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT.BODY_END', { appUrl: location.origin }) +
      '</p>';

    if (event.infos.length) {
      msg += this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

      // Very weird to start the loop at 1 ?
      let info: HolNextInfo;
      for (let i = 1; i < event.infos.length; i++) {
        info = event.infos[i];
        msg += this.getEventInfoMessage(info);
      }
    }

    return msg;
  }

  public getEventMessage(event: HolEvent, infos: EventInfos[], isFromChecklistActivate: boolean, translateKey: string): string {
    if (!translateKey) {
      translateKey = 'OCC';
    }
    let msg =
      '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

    msg += '<tr>';
    msg += '<td style="border-bottom: 1px solid #E7E7E7">';
    msg += '<table width="100%" style="width:100%!important;">';

    msg += '<tr style="color: #3F6185">';
    msg += '<td width="1px"><strong>' + (event.scenario ? event.scenario.code : '') + event.order + '</strong></td>';
    msg += '<td style="padding-left: 5px;"><strong>' + (event.scenario ? event.scenario.title : '') + '</strong></td>';
    msg += '</tr>';

    msg += '<tr>';
    msg += timeCell(event.createdAt);
    msg += this.messageWithAttachments(`<strong style="padding-left: 5px;">${event.description}</strong>`, event.attachments);
    msg += '</tr>';

    if (isFromChecklistActivate) {
      msg +=
        '<td> <td style="padding-left: 5px;"><strong>' +
        this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT.CHECK_LIST_INFOS') +
        '</strong><br/></td></tr>';
    }

    msg += '</table>';
    msg += '</td>';
    msg += '</tr>';

    if (infos && infos.length) {
      const info = infos[0].info;
      msg += '<tr>';
      msg += '<td>';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr>';
      msg += this.messageWithAttachments(info.message, info.attachments);
      msg += '</tr>';

      msg += '<tr>';
      msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
      msg += '</tr>';

      msg += '</table>';
    }

    msg += '</td></tr></table>';
    msg +=
      '<p style="color: black">' +
      this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT.BODY_END', { appUrl: location.origin }) +
      '</p>';

    return msg;
  }

  public getNewEventInfoMessage(event: HolEvent, eventInfo: HolNextInfo, isFromChecklistActivate: boolean, translateKey: string): string {
    if (!translateKey) {
      translateKey = 'OCC';
    }
    let msg = 'A new information has been added to this event: ' + P_SEPARATOR_HTML;

    msg += this.getEventMessage(event, [], isFromChecklistActivate, translateKey);

    msg += this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_NEW_INFO');
    msg += this.getEventInfoMessage(eventInfo);

    if (event.infos && event.infos.length > 1) {
      msg += this.translatePipe.transform('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

      let info: HolNextInfo;
      for (let i = 1; i < event.infos.length; i++) {
        info = event.infos[i];
        msg += this.getEventInfoMessage(info);
      }
    }

    return msg;
  }

  public getCloseEventMessage(event: HolEvent, translateKey: string): string {
    if (!translateKey) {
      translateKey = 'OCC';
    }
    let closeReason;
    if (event.closeReason === 0) {
      closeReason = this.translatePipe.transform(translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
    } else if (event.closeReason === 1) {
      closeReason = this.translatePipe.transform(translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
    } else {
      closeReason = event.closeReasonText;
    }
    let msg = this.translatePipe.transform('MAIL.' + translateKey + '.CLOSE_EVENT.BODY_CLOSE_REASON', {
      closeReason: closeReason,
    });

    msg += this.getEventMessage(event, null, null, translateKey);

    if (event.infos.length) {
      msg += this.translatePipe.transform('MAIL.' + translateKey + '.CLOSE_EVENT.BODY_LAST_INFOS');
    }

    let info: HolNextInfo;
    for (let i = 0; i < event.infos.length; i++) {
      info = event.infos[i];
      msg += this.getEventInfoMessage(info);
    }

    return msg;
  }

  public getLogbookMessage<T extends OclLogbook>(logbook: T): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    msg += timeCell(logbook.createdAt);
    msg += this.messageWithAttachments(logbook.text, logbook.attachments);
    msg += '</tr></table>';

    return msg;
  }

  public getNewMELInfoMessage(mel: MccMel, melInfo: HolNextInfo): string {
    let msg = 'A new information has been added to this MEL: ' + P_SEPARATOR_HTML;

    msg += this.getMELMessage(mel);

    msg += this.translatePipe.transform('MAIL.MCC.NEW_MEL_INFO.BODY_NEW_INFO');
    msg += this.getEventInfoMessage(melInfo);

    if (mel.infos && mel.infos.length > 1) {
      msg += this.translatePipe.transform('MAIL.MCC.NEW_MEL_INFO.BODY_PREVIOUS_INFO');

      let info: HolNextInfo;
      for (let i = 1; i < mel.infos.length; i++) {
        info = mel.infos[i];
        msg += this.getEventInfoMessage(info);
      }
    }

    return msg;
  }

  public getSituationMessage(situation: any) {
    const translateKey = 'MAIL.CREW.NEW_SITUATION_' + situation.direction;

    return this.translatePipe.transform(translateKey, situation);
  }

  public getMCCEventMessage(event: MccEvent, infos?: EventInfos[]): string {
    let msg =
      '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

    msg += '<tr>';
    msg += '<td style="border-bottom: 1px solid #E7E7E7">';
    msg += '<table width="100%" style="width:100%!important;">';

    msg += '<tr style="color: #3F6185">';
    msg +=
      '<td><strong>' +
      (event.ataCode ? event.ataCode.code : '') +
      ' - ' +
      (event.ataCode ? event.ataCode.description : '') +
      '</strong></td>';
    msg +=
      '<td width="1px" style="padding-left: 5px; white-space: nowrap"><strong>' +
      (event.aircraft ? event.aircraft.registration : '') +
      '</strong></td>';
    msg += '</tr>';

    msg += '<tr>';
    msg += this.messageWithAttachments(`<strong style="padding-left: 5px;">${event.description}</strong>`, event.attachments);
    msg += '<td><strong>' + event.localisation + '</strong></td>';
    msg += '</tr>';

    msg += '</table>';
    msg += '</td>';
    msg += '</tr>';

    if (infos && infos.length) {
      const info = infos[0].info;

      msg += '<tr>';
      msg += '<td>';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr>';
      msg += this.messageWithAttachments(info.message, info.attachments);
      msg += '</tr>';

      msg += '<tr>';
      msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
      msg += '</tr>';

      msg += '</table></td></tr>';
    }

    msg += '</table>';

    return msg;
  }

  public getNewMCCEventInfoMessage(event: MccEvent, eventInfo: HolNextInfo): string {
    let msg = 'A new information has been added to this event: ' + P_SEPARATOR_HTML;

    msg += this.getMCCEventMessage(event);

    msg += this.translatePipe.transform('MAIL.OCC.NEW_EVENT_INFO.BODY_NEW_INFO');
    msg += this.getEventInfoMessage(eventInfo);

    if (event.infos && event.infos.length > 1) {
      msg += this.translatePipe.transform('MAIL.OCC.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

      let info: HolNextInfo;
      for (let i = 1; i < event.infos.length; i++) {
        info = event.infos[i];
        msg += this.getEventInfoMessage(info);
      }
    }

    return msg;
  }

  public getEventInfoMessage(info: HolNextInfo): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    msg += timeCell(info.createdAt);
    msg += this.messageWithAttachments(info.message, info.attachments);

    if (info.nextInfoTime) {
      msg += `<td style="width: 1px; text-align: right">►${timeContent(info.nextInfoTime)}</td>`;
    }
    msg += '</tr></table>';

    return msg;
  }

  public getMELMessage(mel: MccMel) {
    let msg =
      '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

    msg += '<tr>';
    msg += '<td style="border-bottom: 1px solid #E7E7E7">';
    msg += '<table width="100%" style="width:100%!important;">';

    msg += '<tr>';
    msg +=
      '<td  width="1px" style="white-space: nowrap">' +
      moment(mel.foundDate).utc().format('ddd DD/MM') +
      ' > ' +
      moment(mel.dueDate).utc().format('ddd DD/MM') +
      '</td>';
    msg += '<td style="padding-left: 5px;"><strong>' + mel.reference + ' (' + mel.faultType + ') ' + mel.faultName + '</strong><br/>';

    msg += '</td>';
    msg += '</tr>';

    msg += '</table>';
    msg += '</td>';
    msg += '</tr>';

    msg += '</table>';

    return msg;
  }

  // region Tasks
  public getTaskDoneMail(task: OclTask, translateKey: string, functionTitle: string, comment: string): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.' + translateKey + '.TASK_TO_DONE.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.translatePipe.transform('MAIL.' + translateKey + '.TASK_TO_DONE.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  public getTaskFrozenMail(task: OclTask, translateKey: string, functionTitle: string, comment: string): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.' + translateKey + '.TASK_TO_FROZEN.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
      comment,
    });
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  public getTaskBackTodoMail(task: OclTask, translateKey: string, functionTitle: string, comment: string): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.translatePipe.transform('MAIL.' + translateKey + '.TASK_TO_TODO.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.translatePipe.transform('MAIL.' + translateKey + '.TASK_TO_TODO.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }
  // endregion

  // region Private, auxiliary methods
  private get hideLinks(): boolean {
    return this.holOptionsService.getHideAttachmentLinksOnEmails();
  }

  private messageWithAttachments(message: string, attachments: HolAttachments) {
    return messageWithAttachments(message, attachments, this.hideLinks, this.translatePipe);
  }

  // endregion
}
