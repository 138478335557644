export const environment = {
  production: true,
  sentryDSN: 'https://48c4de9d9818410db836222227fbbe34@sentry.io/1391471',
  cockpitBaseUrl: 'https://cockpit2.holis-erp.com/api/',
  cockpitWSBaseUrl: 'wss://cockpit2.holis-erp.com:4010',
  parseServerUrl: 'http://0.0.0.0:1337/1/',
  parseAppId: 'PARSE_SERVER_APP_ID',
  parseJSKey: 'PARSE_SERVER_JAVASCRIPT_KEY',
  userConnectedStatusMaxTime: 300, // seconds
};
