'use strict';
angular.module('gestiondecriseApp').directive('crewEventFilterBox', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/crewEventFilterBox/crewEventFilterBox.html',
    scope: {
      currentFilter: '=',
      hideFunctionFilter: '=',
      isReadOnly: '=',
    },
    controller: function ($scope, CrewOptionsService, $rootScope, CrewFunctionsService, $mdDialog, TirednessIndicatorService) {
      $scope.filters = CrewOptionsService.getFilters();
      $scope.currentFilter = $rootScope.crewEventFilter;
      $scope.selectedFunction = $rootScope.crewSelectedFunction;
      $scope.functions = [];

      function init() {
        CrewFunctionsService.getAll().then(function (functions) {
          $scope.functions = functions;
          $scope.functions.unshift({ code: null });
        });
      }

      init();

      $scope.activateFunctionFilter = function (code) {
        $rootScope.crewSelectedFunction = code;
      };

      $scope.toggleFilter = function (filter) {
        if (filter === $scope.currentFilter) {
          $scope.currentFilter = null;
        } else {
          $scope.currentFilter = filter;
        }
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.openUserModal = function ($event, situation) {
        $mdDialog.show({
          controller: 'CrewUserModalCtrl',
          templateUrl: 'views/crew/modals/user.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            user: situation && situation.user,
            event: null,
            situation: situation,
            isReadOnly: $scope.isReadOnly,
            isArchivesOpened: false,
          },
        });
      };

      $scope.toggleFilter = function (filter) {
        $scope.currentFilter = filter;
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.showAllFilters = function () {
        $scope.currentFilter = undefined;
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.isSelected = function (filter) {
        return $scope.currentFilter && $scope.currentFilter === filter;
      };

      $scope.selectFRMFile = function () {
        $('#frm-uploader').click();
      };

      $scope.read = function (workbook) {
        $scope.loading = true;
        TirednessIndicatorService.parseXLSFile(workbook).then(
          function () {},
          function (error) {
            $scope.loading = false;
            console.error('[XLS_IMPORT]', error);
          }
        );
      };

      $scope.error = function (error) {
        console.error('[XLS_IMPORT]', error);
      };
    },
  };
});
