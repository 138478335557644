import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';
import { OclHistoryLog } from '../../../ocl/models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export class OccHistoryService extends OclHistoryService<OclHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('OCCLogs');
  protected ParseEvents = Parse.Object.extend('OCCEvents');
  protected ParseDecisions = Parse.Object.extend('OCCDecisions');
  protected ParseLogbooks = Parse.Object.extend('OCCLogBook');

  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: OccOptionsService,
    protected parseMapper: ParseMapperService,
    protected moduleConfig: OccModuleConfigService,
  ) {
    super(requestService, $rootScope, optionsService, parseMapper, moduleConfig);
  }
}
