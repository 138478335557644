import { Injectable } from '@angular/core';
import { TagsService } from '../../../common/services/tags.service';
import { EclCrisis } from '../../models/ecl-crisis';
import { HolTag } from '../../../common/models/hol-tag';
import { RequestService } from '../../../common/services/request.service';
import { HolCrisis } from '../../../common/models/hol-crisis';
import { HelperService } from '../../../common/services/helper.service';
import { OclTagsStoreManager } from '../../../ocl/store/tags/ocl-tags.store-manager';

@Injectable({
  providedIn: 'root',
})
export class EclTagsService extends TagsService {
  // tslint:disable-next-line:variable-name
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseTagRef = Parse.Object.extend('ECLTag_REF');
  protected ParseCrisis = Parse.Object.extend('ECLCrisis');
  protected requestService: RequestService;
  protected helperService: HelperService;
  protected tagsStoreManager: OclTagsStoreManager;

  constructor(requestService: RequestService, helperService: HelperService, tagsStoreManager: OclTagsStoreManager) {
    super(requestService);
    this.requestService = requestService;
    this.helperService = helperService;
    this.tagsStoreManager = tagsStoreManager;
  }

  public async fetchNewData() {
    return this.getAll(true).then(r => this.tagsStoreManager.updateTags(r));
  }

  public async getAllTagByCrisis(crisis: EclCrisis, force?: boolean): Promise<HolTag[]> {
    if (this._tags && !force) {
      return Promise.resolve(this._tags);
    } else {
      const query = new Parse.Query(this.ParseTag);
      this.setAdditionalFieldsToFilter(query, crisis);
      query.ascending('name');
      query.limit(1000);
      return this.requestService.performFindAllQuery(query).then(parseTags => {
        this._tags = parseTags.map(parseTag => new HolTag(parseTag));
        return this._tags;
      });
    }
  }

  public async cloneRefTagNewCrisis(crisis: HolCrisis) {
    // Get all tags ref to clone in new crisis
    const query = new Parse.Query(this.ParseTagRef);
    query.include('ACL');
    const parseTags = await this.requestService.performFindQuery(query);
    const tList = parseTags.filter(t => this.helperService.allowedACL(t.getACL(), crisis.acl)).map(parseTag => new HolTag(parseTag));

    const allAccessACL = new Parse.ACL();
    allAccessACL.setPublicWriteAccess(true);
    allAccessACL.setPublicReadAccess(true);

    const newList = tList.map(tl => {
      const newTag = new this.ParseTag({
        color: tl.color,
        name: tl.name,
        ACL: crisis.acl || tl.acl || allAccessACL,
      });

      this.setAdditionalFields(crisis, newTag);
      return newTag;
    });
    await this.requestService.performSaveAllQuery(newList);
  }

  protected setAdditionalFieldsToFilter(parseTag: Parse.Query, crisis: EclCrisis) {
    super.setAdditionalFieldsToFilter(parseTag, crisis);
    if (crisis) {
      const crisisPar = new this.ParseCrisis({ id: crisis.objectId });
      parseTag.equalTo('crisis', crisisPar);
    }
  }

  protected setAdditionalFields(crisis: HolCrisis, parseTag: Parse.Object) {
    super.setAdditionalFields(crisis, parseTag);
    parseTag.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
  }
}
