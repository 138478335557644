import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolUserFunction } from '../models/hol-user-function';

@Injectable({
  providedIn: 'root',
})
export class HolUserFunctionService {
  // tslint:disable:variable-name
  protected ParseHolUserFunction = Parse.Object.extend('HOLUserFunction');
  // tslint:enabled

  constructor(protected requestService: RequestService) {}

  public getAll(): Promise<HolUserFunction[]> {
    const query = new Parse.Query(this.ParseHolUserFunction);
    return this.requestService.performFindAllQuery(query).then(ufs => {
      return ufs.map(uf => new HolUserFunction(uf));
    });
  }
}
