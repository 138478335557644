angular.module('gestiondecriseApp').directive('crewEventFilterBoxPhone', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/crewEventFilterBoxPhone/crewEventFilterBoxPhone.template.html',
    scope: {
      currentFilter: '=',
    },
    controller: function ($scope, CrewOptionsService, $rootScope, CrewFunctionsService, $mdDialog) {
      $scope.filters = CrewOptionsService.getFilters();
      $scope.currentFilter = $rootScope.crewEventFilter;
      $scope.selectedFunction = $rootScope.crewSelectedFunction;
      $scope.functions = [];

      init();

      function init() {
        CrewFunctionsService.getAll().then(function (functions) {
          $scope.functions = functions;
          $scope.functions.unshift({ code: null });
        });
      }

      $scope.activateFunctionFilter = function (code) {
        $rootScope.crewSelectedFunction = code;
      };

      $scope.toggleFilter = function (filter) {
        if (filter === $scope.currentFilter) {
          $scope.currentFilter = null;
        } else {
          $scope.currentFilter = filter;
        }
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.openUserModal = function ($event, situation) {
        $mdDialog.show({
          controller: 'CrewUserModalCtrl',
          templateUrl: 'views/crew/modals/user.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            user: situation && situation.user,
            event: null,
            situation: situation,
            isReadOnly: $scope.isReadOnly,
            isArchivesOpened: false,
          },
        });
      };

      $scope.toggleFilter = function (filter) {
        $scope.currentFilter = filter;
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.showAllFilters = function (filter) {
        $scope.currentFilter = undefined;
        $rootScope.crewEventFilter = $scope.currentFilter;
      };

      $scope.isSelected = function (filter) {
        return $scope.currentFilter && $scope.currentFilter == filter;
      };
    },
  };
});
