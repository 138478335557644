'use strict';

const env = require('../../../environments/environment').environment;

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:ChangeUserStatusModalCtrl
 * @description
 * # ChangeUserStatusModalCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('ChangeUserStatusModalCtrl', function (
    ParseMapper,
    $scope,
    user,
    func,
    canChangeHolder,
    $timeout,
    $mdDialog,
    OptionsService,
    RequestService,
    $translate,
    isCurrentUserCrisisDirectoryHolder,
    currentUserId,
    AuthenticationService,
    ErpFunctionsService,
    FunctionsStoreManager
  ) {
    $scope.user = user;
    $scope.func = func;
    $scope.canChangeHolder = canChangeHolder;
    $scope.working = false;

    const init = function () {
      $scope.isHolder = !!($scope.func && $scope.func.holder && $scope.func.holder.userId === $scope.user.userId);
      $scope.isCurrentUser = $scope.user.userId === currentUserId;
      $scope.otherHeldFunctions = getOtherHeldFunctions();
      if ($scope.isHolder && $scope.isCurrentUser) {
        $scope.msg4HolderOnDutyOffline = $translate.instant('TEAM.MODAL.ACTION_TITLES.LOGOUT_AS_ON-DUTY_OFF-LINE');
        $scope.msg4HolderUnavailableOffline = $translate.instant('TEAM.MODAL.ACTION_TITLES.LOGOUT_AS_UNAVAILABLE_OFF-LINE');
      } else {
        $scope.msg4HolderOnDutyOfflinsetAvailableOfflinee = $translate.instant('TEAM.MODAL.ACTION_TITLES.DEACTIVATE_AS_ON-DUTY_OFF-LINE');
        $scope.msg4HolderUnavailableOffline = $translate.instant('TEAM.MODAL.ACTION_TITLES.DEACTIVATE_AS_UNAVAILABLE_OFF-LINE');
      }

      try {
        $scope.holdFunctionsCount = user.functions.filter(f => {
          return f.isHolder;
        }).length;
      } catch (e) {
        $scope.holdFunctionsCount = 0;
      }
    };

    $scope.isActivated = function () {
      return new Date() - $scope.user.lastSeenAt < 1000 * env.userConnectedStatusMaxTime; // 5min
    };

    $scope.isActivatedOffline = function () {
      return $scope.user.isAvailableOffline && !$scope.isActivated();
    };

    $scope.isDeactivated = function () {
      return !$scope.user.isAvailableOffline && !$scope.isActivated();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.sendMail = function (user) {
      window.open('mailto:' + user.email);
    };

    const confirmAction = function (confirmationMsg, ev) {
      return $mdDialog.show({
        templateUrl: 'views/team/confirmChangeUserStatusModal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          msg: confirmationMsg,
        },
        controller: [
          '$scope',
          'msg',
          function (confirmScope, msg) {
            confirmScope.msg = msg;
            confirmScope.validate = $mdDialog.hide;
            confirmScope.cancel = $mdDialog.cancel;
          },
        ],
      });
    };

    $scope.switchAvailableOffline = function () {
      $scope.working = true;

      if ($scope.isCurrentUser) {
        AuthenticationService.logout(false).then(function () {
          $mdDialog.hide(false);
        });
      } else {
        RequestService.performCloudCode('disconnectUser', { objectId: $scope.user.objectId, isAvailableOffline: true })
          .then(u => {
            FunctionsStoreManager.updateOneUser(ParseMapper.userToObject(u));
            console.log("User '" + u + "' disconnected");
          })
          .catch(error => {
            console.error('Error disconnecting user', $scope.user, error);
          });
        $mdDialog.hide(true);
      }
    };

    const _switchUnavailableOffline = function () {
      $scope.working = true;

      ErpFunctionsService.resetHolder($scope.user).finally(() => {
        $mdDialog.hide(false);
        if ($scope.isCurrentUser) {
          AuthenticationService.logout(false);
        } else {
          RequestService.performCloudCode('disconnectUser', { objectId: $scope.user.objectId, isAvailableOffline: false })
            .then(u => {
              FunctionsStoreManager.updateOneUser(ParseMapper.userToObject(u));
              console.log(`User '${$scope.user.fullName}' disconnected`);
            })
            .catch(error => {
              console.error('Error disconnecting user', $scope.user, error);
            });
        }
      });
    };

    $scope.switchUnavailableOffline = function (ev) {
      if ($scope.isHolder || $scope.holdFunctionsCount > 0) {
        // Si pas directeur de crise et qu'on essaye de se desactiver ou qu'on desactiver un user qui est titulaire d'une autre fonction => warning
        let msg;
        if ($scope.isCurrentUser) {
          msg = $translate.instant('TEAM.MODAL.CONFIRM_STATUS_CHANGE.UNAVAILABLE_OFFLINE_SELF');
          if ($scope.holdFunctionsCount > 1) {
            msg += $translate.instant('TEAM.MODAL.CONFIRM_STATUS_CHANGE.UNAVAILABLE_OFFLINE_SELF_IMPACT_OTHER', {
              functions: $scope.otherHeldFunctions,
            });
          }
        } else {
          msg = $translate.instant('TEAM.MODAL.CONFIRM_STATUS_CHANGE.UNAVAILABLE_OFFLINE', {
            functions: $scope.otherHeldFunctions,
          });
        }
        confirmAction(msg, ev).then(_switchUnavailableOffline);
      } else {
        _switchUnavailableOffline();
      }
    };

    const _switchOnDutyOffline = function () {
      $scope.working = true;
      ErpFunctionsService.setHolder($scope.func, $scope.user).then(() => {
        RequestService.performCloudCode('disconnectUser', { objectId: $scope.user.objectId, isAvailableOffline: true }).then(u => {
          FunctionsStoreManager.updateOneUser(ParseMapper.userToObject(u));
          $mdDialog.hide(false);
          if ($scope.isCurrentUser) {
            AuthenticationService.logout(false);
          }
        });
      });
    };

    $scope.switchOnDutyOffline = function (ev) {
      if (
        (!isCurrentUserCrisisDirectoryHolder && !$scope.isCurrentUser) ||
        (isCurrentUserCrisisDirectoryHolder && OptionsService.getCrisisDirectorShortTitlesList().indexOf($scope.func.shortTitle) > -1)
      ) {
        // Si pas directeur de crise et qu'on affecte la fonction a un autre user => warning
        // ou si c'est le directeur de crise qui s'enlève sa titularité sur la fonction de directeur de crise
        confirmAction($translate.instant('TEAM.MODAL.CONFIRM_STATUS_CHANGE.ON_DUTY'), ev).then(_switchOnDutyOffline);
      } else {
        _switchOnDutyOffline();
      }
    };

    const _switchOnDuty = function () {
      // Disable all buttons
      $scope.working = true;

      ErpFunctionsService.setHolder($scope.func, $scope.user).then(() => {
        $mdDialog.hide(true);
      });
    };

    $scope.switchOnDuty = function (ev) {
      if (
        (!isCurrentUserCrisisDirectoryHolder && !$scope.isCurrentUser) ||
        (isCurrentUserCrisisDirectoryHolder && OptionsService.getCrisisDirectorShortTitlesList().indexOf($scope.func.shortTitle) > -1)
      ) {
        // Si pas directeur de crise et qu'on affecte la fonction a un autre user => warning
        // ou si c'est le directeur de crise qui s'enlève sa titularité sur la fonction de directeur de crise
        confirmAction($translate.instant('TEAM.MODAL.CONFIRM_STATUS_CHANGE.ON_DUTY'), ev).then(_switchOnDuty);
      } else {
        _switchOnDuty();
      }
    };

    const getOtherHeldFunctions = function () {
      const otherHeldFunctions = $scope.user.functions.filter(f => {
        return f.isHolder && (!$scope.func || $scope.func.functionId !== f.functionId);
      });
      const formattedTitles = otherHeldFunctions.map(f => {
        return f.title + ' (' + f.shortTitle + ')';
      });
      if (formattedTitles.length === 0) {
        return ''; // doit pas arriver :()
      } else if (formattedTitles.length === 1) {
        return formattedTitles[0];
      } else {
        return formattedTitles.slice(0, -1).join(', ') + ', and ' + formattedTitles.slice(-1);
      }
    };

    init();

    // ui helpers

    $scope.holderSince = function (date) {
      return moment.duration(moment().diff(moment(date))).humanize();
    };
  });
