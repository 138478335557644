import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { groupBy } from 'lodash';
import { HolUserFunction } from 'src/app/common/models/hol-user-function';

import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { nextInfoType } from '../components/hol-next-info/hol-next-info.component';
import { HolFunction } from '../models/hol-function';
import { HolManagerUser } from '../models/hol-manager-user';
import { HolUser } from '../models/hol-user.model';
import { HolManagerUserService } from '../services/hol-manager-user.service';
import { HolOptionsService } from '../services/hol-options.service';
import { ModuleConfigService } from '../services/module-config/module-config.service';
import { RolesService } from '../services/roles.service';
import { UserService } from '../services/user.service';
import { CommonStoreManager } from './common.store-manager';

export interface HolUserFunctionWithUser extends HolUserFunction {
  expiredAtNextInfo: nextInfoType;
  user: HolManagerUser;
}

export interface HolManagerCompany {
  name: string;
  users: HolUserFunctionWithUser[];
  holder: HolUserFunctionWithUser;
}

export interface HolManagerFunction {
  modules: string[];
  title: string;
  shortTitle: string;
  phone: string;
  email: string;
  functionId: string;
  minutesBeforeAlert: number;
  isManagerFunction: boolean;
  companies: HolManagerCompany[];
}

@Injectable({
  providedIn: 'root',
})
export class HolManagersSelectors {
  protected constructor(
    protected store: Store<AppState>,
    protected userService: UserService,
    protected holManagerUserService: HolManagerUserService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected holOptionsService: HolOptionsService,
    protected moduleConfig: ModuleConfigService,
  ) {}

  get managersFunctions(): Observable<HolManagerFunction[]> {
    return this.getManagersFunctions(true);
  }

  getManagersFunctions(filterFromCompanyRoles: boolean, module?: string): Observable<HolManagerFunction[]> {
    const selectedModule = module || this.moduleConfig.config.moduleName;
    return combineLatest([
      this.store.select('common', 'holUserFunctions'),
      this.store.select('common', 'holFunctions'),
      this.userService.getAllUsers(),
      this.holManagerUserService.getAll(),
      this.rolesService.$companiesRolesFilter,
    ]).pipe(
      map(([userFunctions, functions, allUsers, holUsers]) => {
        const managerFunctions = this.holOptionsService.getFunctionsIdsForManagerByModule(selectedModule);
        const filteredUserFunctions = filterFromCompanyRoles ? this.rolesService.filterFromCompanyRoles(userFunctions) : userFunctions;
        functions = functions
          .filter(f => filteredUserFunctions.find(uf => uf.functionId === f.functionId && f.modules.includes(selectedModule.toUpperCase())))
          .sort((a, b) => a.order - b.order);
        return functions.map(f => {
          const ufsByCompany = groupBy(
            filteredUserFunctions.filter(uf => uf.functionId === f.functionId),
            uf => uf.companies[0],
          );
          return {
            ...f,
            isManagerFunction: !!managerFunctions.includes(f.functionId),
            companies: Object.entries(ufsByCompany)
              .map(([company, ufs]) => {
                const users: HolUserFunctionWithUser[] = ufs
                  .map(uf => {
                    const user = [...(allUsers as Array<HolManagerUser>), ...holUsers].find(u => u.userId === uf.userId) || new HolUser();
                    return { ...uf, user, expiredAtNextInfo: { nextInfoTime: uf.expiredAt } };
                  })
                  .sort((a, b) => a.userId.localeCompare(b.userId));
                return {
                  name: company,
                  users,
                  holder: users.find(u => u.isHolder),
                };
              })
              .sort((a, b) => a.name.localeCompare(b.name)),
          };
        });
      }),
    );
  }

  get holFunctions(): Observable<HolFunction[]> {
    return this.store.select('common', 'holFunctions');
  }

  get currentUserFunctions(): Observable<HolUserFunction[]> {
    return combineLatest([
      this.store.select('common', 'holUserFunctions'),
      this.commonStoreManager.currentUser,
      this.rolesService.$companiesRolesFilter,
    ]).pipe(
      map(([userFunctions, currentUser]) => {
        userFunctions = this.rolesService.filterFromCompanyRoles(userFunctions);
        return userFunctions.filter(uf => uf.userId === currentUser.userId);
      }),
    );
  }
}
