'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:DuplicateFunctionModalCtrl
 * @description
 * # DuplicateFunctionModalCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('DuplicateFunctionModalCtrl', function ($scope, $mdDialog, FunctionService, originalFunction, ErpFunctionsService) {
    $scope.originalFunction = originalFunction;
    $scope.newFunction = {
      shortTitle: undefined,
      title: undefined,
    };
    $scope.working = false;

    $scope.$watch('newFunction.shortTitle', function (n, o) {
      if (n && !_.isEmpty(n)) {
        $scope.newFunction.shortTitle = n.toUpperCase().replace(/ /g, '');
      }
    });

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.submitDisabled = function () {
      return _.isEmpty($scope.newFunction.shortTitle) || _.isEmpty($scope.newFunction.title);
    };

    $scope.doDuplicate = function () {
      if (!$scope.working) {
        $scope.error = undefined;
        $scope.working = true;

        if (_.isEmpty($scope.newFunction.title)) {
          $scope.error = 'Please provide a short title.';
          $scope.working = false;
        } else if (_.isEmpty($scope.newFunction.shortTitle)) {
          $scope.error = 'Please provide a complete title.';
          $scope.working = false;
        } else {
          FunctionService.all()
            .then(functions => {
              if (functions.find(f => f.shortTitle === $scope.newFunction.shortTitle)) {
                $scope.error = 'This short title already exists.';
                $scope.working = false;
              } else {
                ErpFunctionsService.duplicate(originalFunction, $scope.newFunction.shortTitle, $scope.newFunction.title)
                  .then(() => {
                    $scope.working = false;
                    $mdDialog.hide(true);
                  })
                  .catch(msg => {
                    $scope.error = 'Error duplicating (msg:' + msg + ')';
                    $scope.working = false;
                  });
                $mdDialog.hide(true);
              }
            })
            .catch(msg => {
              $scope.error = 'Error getting all functions to check uniqueness (msg:' + msg + ')';
              $scope.working = false;
            });
        }
      }
    };
  });
