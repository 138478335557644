import { Injectable } from '@angular/core';
import { OclGlobalInstructionTagService } from 'src/app/ocl/services/ocl-global-instruction-tag-service/ocl-global-instruction-tag.service';

@Injectable({
  providedIn: 'root',
})
export class GocGlobalInstructionTagService extends OclGlobalInstructionTagService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('GOCGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('GOCGlobalInstruction');
  protected ParseTag = Parse.Object.extend('GOCTag');
  // tslint:enable
}
