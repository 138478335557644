import { HolObject } from '../../common/models/hol-object';
import { EclCrisis } from './ecl-crisis';
import { HolTag } from '../../common/models/hol-tag';
import { isNumber } from 'util';

export class EclAsset extends HolObject {
  crisis: EclCrisis;
  assetTypeId: string;
  name: string;
  lat: number;
  long: number;
  latDirection: string;
  longDirection: string;

  latDegrees: number;
  latMinutes: number;
  latSeconds: number;
  longDegrees: number;
  longMinutes: number;
  longSeconds: number;

  color: string;
  parameters: EclAssetParameter[] = [];
  public tags: HolTag[] = [];

  constructor(parseObject?: Parse.Object, tags: HolTag[] = []) {
    super(parseObject);
    if (parseObject) {
      this.crisis = new EclCrisis(parseObject.get('crisis'));
      this.assetTypeId = parseObject.get('assetTypeId');
      this.name = parseObject.get('name');
      this.lat = parseObject.get('lat');
      this.long = parseObject.get('long');

      this.latDirection = parseObject.get('latDirection');
      this.latDegrees = parseObject.get('latDegrees');
      this.latMinutes = parseObject.get('latMinutes');
      this.latSeconds = parseObject.get('latSeconds');
      this.longDirection = parseObject.get('longDirection');
      this.longDegrees = parseObject.get('longDegrees');
      this.longMinutes = parseObject.get('longMinutes');
      this.longSeconds = parseObject.get('longSeconds');

      this.color = parseObject.get('color');
      this.tags = tags;
    }
  }

  get coordinates(): number[] | undefined {
    if (isNumber(this.lat) && isNumber(this.long)) return [this.lat, this.long];
    return undefined;
  }

  protected getContentText(parseObject: Parse.Object): string {
    return parseObject.get('name');
  }

  toString(): string {
    return (
      this.name +
      ' | ' +
      this.latDegrees +
      '°' +
      this.latMinutes +
      "'" +
      this.latSeconds +
      "''" +
      this.latDirection +
      ' | ' +
      this.parameters.toString()
    );
  }
}

export class EclAssetParameter extends HolObject {
  asset: EclAsset;
  parameterId: string; // Must a Foreign key to EclAssetTypeParameter if custom = false
  value: string;
  label: string; // only if custom = true
  custom: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      this.asset = new EclAsset(parseObject.get('asset'));
      this.parameterId = parseObject.get('parameterId');
      this.value = parseObject.get('value');
      this.label = parseObject.get('label');
      this.custom = parseObject.get('custom');
    }
  }

  toString(): string {
    return this.label + ': ' + this.value;
  }
}
