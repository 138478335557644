import { Action } from '@ngrx/store';
import { ErpFunctions } from '../../erp.model';
import { ErpFunctionCrisis } from '../../models/erp-functionCrisis';
import { ErpFunctionUser } from '../../models/erp-functionUser';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';

export enum FunctionsActionTypes {
  INIT_FUNCTIONS = '[ERP FUNCTIONS] Init Functions',
  UPDATE_FONCTIONS_FROM_POOLING = '[ERP FUNCTIONS] Update Functions From Pooling',
  RESET_FUNCTIONS = '[ERP FUNCTIONS] Reset Functions',
  UPDATE_ONE_FUNCTION_CRISIS = '[ERP FUNCTIONS] Update One Function Crisis',
  UPDATE_ONE_FUNCTION_ALL_USER = '[ERP FUNCTIONS] Update One Function All User',
  ADD_ONE_FUNCTION_CRISIS = '[ERP FUNCTIONS] Add One Function Crisis',
  ADD_ONE_FUNCTION_ALL_USER = '[ERP FUNCTIONS] Add One Function All User',
  REMOVE_ONE_FUNCTION_ALL_USER = '[ERP FUNCTIONS] Remove One Function All User',
  ADD_ONE_USER = '[ERP FUNCTIONS] Add One User',
  UPDATE_ONE_USER = '[ERP FUNCTIONS] Update One User',
}
export class InitFunctions implements Action {
  readonly type = FunctionsActionTypes.INIT_FUNCTIONS;
  constructor(public payload: Partial<ErpFunctions>) {}
}

export class UpdateFunctionsFromPooling implements Action {
  readonly type = FunctionsActionTypes.UPDATE_FONCTIONS_FROM_POOLING;
  constructor(public payload: Partial<ErpFunctions>) {}
}

export class UpdateOneFunctionCrisis implements Action {
  readonly type = FunctionsActionTypes.UPDATE_ONE_FUNCTION_CRISIS;
  constructor(public payload: ErpFunctionCrisis) {}
}

export class AddOneFunctionCrisis implements Action {
  readonly type = FunctionsActionTypes.ADD_ONE_FUNCTION_CRISIS;
  constructor(public payload: ErpFunctionCrisis) {}
}

export class UpdateOneFunctionAllUser implements Action {
  readonly type = FunctionsActionTypes.UPDATE_ONE_FUNCTION_ALL_USER;
  constructor(public payload: ErpFunctionUser) {}
}

export class AddOneFunctionAllUser implements Action {
  readonly type = FunctionsActionTypes.ADD_ONE_FUNCTION_ALL_USER;
  constructor(public payload: ErpFunctionUser) {}
}

export class RemoveOneFunctionAllUser implements Action {
  readonly type = FunctionsActionTypes.REMOVE_ONE_FUNCTION_ALL_USER;
  constructor(public payload: ErpFunctionUser) {}
}

export class ResetFunctions implements Action {
  readonly type = FunctionsActionTypes.RESET_FUNCTIONS;
}

export class AddOneUser implements Action {
  readonly type = FunctionsActionTypes.ADD_ONE_USER;
  constructor(public payload: HolUserWithCompanies) {}
}

export class UpdateOneUser implements Action {
  readonly type = FunctionsActionTypes.UPDATE_ONE_USER;
  constructor(public payload: HolUser) {}
}

export type FunctionsActions =
  | InitFunctions
  | ResetFunctions
  | UpdateOneFunctionAllUser
  | UpdateOneFunctionCrisis
  | AddOneFunctionCrisis
  | AddOneFunctionAllUser
  | RemoveOneFunctionAllUser
  | UpdateFunctionsFromPooling
  | AddOneUser
  | UpdateOneUser;
