import { HolScenario } from 'src/app/common/models/hol-scenario';
import { Action } from '@ngrx/store';

export enum OclScenariosActionTypes {
  INIT_TAGS = '[OCL Scenarios] Init Scenarios',
}

export class InitScenarios implements Action {
  readonly type = OclScenariosActionTypes.INIT_TAGS;
  constructor(public scenarios: HolScenario[]) {}
}

export type ScenariosActions = InitScenarios;
