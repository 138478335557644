import { Injectable } from '@angular/core';
import { OclLinkedItemsStoreManager } from '../../../ocl/store/linked-items/ocl-linked-items-store-manager.service';
import { RequestService } from '../../../common/services/request.service';
import { OclLinksService } from 'src/app/ocl/services/ocl-links-service/ocl-links.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OpsLinksService extends OclLinksService {
  // tslint:disable:variable-name
  protected ParseOclLogBook = Parse.Object.extend('OPSLogBook');
  protected ParseOclEvents = Parse.Object.extend('OPSEvents');
  protected ParseOclDecisions = Parse.Object.extend('OPSDecisions');
  protected ParseOclLinkLogBookDecision = Parse.Object.extend('OPSLinkLogBookDecision');
  protected ParseOclLinkDecisionEvent = Parse.Object.extend('OPSLinkDecisionEvent');
  protected ParseOclLinkLogBookEvent = Parse.Object.extend('OPSLinkLogBookEvent');
  // tslint:enabled
  public constructor(
    protected requestService: RequestService,
    protected linkedItemsStoreManager: OclLinkedItemsStoreManager,
    public moduleConfig: OpsModuleConfigService
  ) {
    super(requestService, linkedItemsStoreManager, moduleConfig);
  }
}
