import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(@Inject('$translate') private $translate) {}

  transform(value: any, args?: any): any {
    return this.$translate.instant(value, args);
  }
}
