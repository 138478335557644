import { HolAttachments } from '../../common/models/hol-attachments.model';
import { HolUser } from '../../common/models/hol-user.model';

export class GocLog {
  objectId: string;
  attachments?: HolAttachments;
  comment: string;
  type: string;
  subType: string;
  createdBy: HolUser;
  createdAt: Date;
  jsonObject: any;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    if (parseObject.get('attachments')) {
      this.attachments = parseObject.get('attachments');
    }
    this.comment = parseObject.get('comment');
    this.type = parseObject.get('type');
    this.subType = parseObject.get('subType');
    if (parseObject.get('createdBy')) {
      this.createdBy = new HolUser(parseObject.get('createdBy'));
    }
    this.createdAt = parseObject.get('createdAt');
    this.jsonObject = parseObject.get('jsonObject');
  }
}
