<!--PREVIEW used for pdf-->
<div class="attachment-preview" [ngClass]="{ 'is-pdf': isPdf }" *ngIf="isPdf">
  <div *ngIf="attachments.file" class="attachment-preview-item">
    <app-file-and-image-display-full
      [icon]="'clip'"
      [isPdf]="isPdf"
      [title]="attachments.file.title"
      [name]="attachments.file.fileName"
      [url]="attachments.file.url"
    ></app-file-and-image-display-full>
  </div>
  <div *ngIf="attachments.image" class="attachment-preview-item">
    <app-file-and-image-display-full
      [icon]="'picture'"
      [isPdf]="isPdf"
      [title]="attachments.image.title"
      [name]="attachments.image.fileName"
      [url]="attachments.image.url"
    ></app-file-and-image-display-full>
  </div>
  <div class="preview-note">
    <i *ngIf="attachments.note" class="icon fa fa-sticky-note-o"></i>
    <div class="preview-note__items">
      <app-file-and-image-display-full
        [isPdf]="isPdf"
        [isNote]="true"
        *ngIf="attachments.note && attachments.noteFile"
        [icon]="''"
        [name]="attachments.noteFile?.fileName"
        [url]="attachments.noteFile?.url"
      >
      </app-file-and-image-display-full>
      <ngx-md *ngIf="attachments.note" [data]="attachments.note" class="attachment-preview-item"></ngx-md>
    </div>
  </div>
</div>
<!--Link-->
<div class="new-holis-theme file-and-image-upload" *ngIf="!isPdf">
  <div class="file-and-image-upload__row" *ngIf="(!hideLink && disabled && attachments.link !== undefined) || (!hideLink && !disabled)">
    <div class="file-and-image-upload__row__left link">
      <span class="file-upload" [ngClass]="{ disabled: disabled }">
        <button
          (click)="openAttachmentModal('link')"
          type="button"
          [disabled]="disabled"
          class="hol-attachment-btn"
          mat-stroked-button
          color="primary"
        >
          <span class="hol-icon-link"></span>
          <span class="btn-label">{{ 'COMMON.LINK' | translate }}</span>
        </button>
      </span>
    </div>
    <div class="file-and-image-upload__row__right">
      <a
        *ngIf="attachments.link"
        href="{{ attachments.link.url }}"
        title="{{ attachments.link.title || attachments.link.url }}"
        target="_blank"
      >
        <span *ngIf="attachments.link.title">{{ attachments.link.title }}</span>
        <span *ngIf="!attachments.link.title">{{ 'COMMON.CONSULT' | translate }}</span>
      </a>
      <span (click)="removeLink($event)" *ngIf="attachments.link && !disabled" class="hol-icon-delete delete"></span>
    </div>
  </div>

  <!--File-->
  <div
    class="file-and-image-upload__row"
    *ngIf="(!hideFile && disabled && attachments.file !== undefined) || (!hideFile && !disabled && !multipleFileEnabled)"
  >
    <div class="file-and-image-upload__row__left">
      <div class="file-upload-btn-container">
        <button
          (click)="openAttachmentModal('file')"
          type="button"
          [disabled]="disabled"
          class="hol-attachment-btn"
          mat-stroked-button
          color="primary"
        >
          <span class="hol-icon-file"></span>
          <span class="btn-label">{{ 'COMMON.FILE' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="file-and-image-upload__row__right">
      <span *ngIf="attachments.file" class="attachment-preview-item">
        <app-file-and-image-display-full
          [icon]="'clip'"
          [name]="attachments.file.fileName"
          [url]="attachments.file.url"
          [title]="attachments.file.title"
        ></app-file-and-image-display-full>
      </span>
      <span (click)="removeFile($event)" *ngIf="attachments.file?.fileName && !disabled" class="hol-icon-delete delete"></span>
    </div>
  </div>

  <!--Files-->
  <div
    class="file-and-image-upload__row"
    *ngIf="
      (!hideFile && disabled && attachments.files !== undefined && attachments.files.length !== 0) ||
      (!hideFile && !disabled && multipleFileEnabled)
    "
  >
    <div class="file-and-image-upload__row__left">
      <div class="file-upload-btn-container">
        <button
          (click)="openAttachmentModal('files')"
          type="button"
          [disabled]="disabled"
          class="hol-attachment-btn"
          mat-stroked-button
          color="primary"
        >
          <span class="hol-icon-file"></span>
          <span class="btn-label">{{ 'COMMON.FILE' | translate }}</span>
        </button>
      </div>
    </div>
    <div class="file-and-image-upload__row__right">
      <div *ngIf="attachments.files && attachments.files.length" class="attachment-preview-item">
        <ng-container *ngFor="let file of attachments.files; index as i">
          <span class="files-item-row">
            <app-file-and-image-display-full
              [icon]="'clip'"
              [name]="file.fileName"
              [title]="file.title"
              [url]="file.url"
            ></app-file-and-image-display-full>
            &nbsp;&nbsp;
            <span
              (click)="removeFiles($event, i)"
              *ngIf="file.canBeDeleted !== false && attachments.files.length !== 0 && !disabled"
              class="hol-icon-delete delete"
            ></span>
          </span>
        </ng-container>
      </div>
    </div>
  </div>

  <!--Picture-->
  <div class="file-and-image-upload__col" *ngIf="(!hideImage && disabled && attachments.image !== undefined) || (!hideImage && !disabled)">
    <div class="file-and-image-upload__row">
      <div class="file-and-image-upload__row__left">
        <div class="file-upload-btn-container" [ngClass]="{ 'no-text': hideButtonTitle }">
          <button
            (click)="openAttachmentModal('image')"
            type="button"
            [disabled]="disabled"
            class="hol-attachment-btn"
            mat-stroked-button
            color="primary"
          >
            <span class="hol-icon-picture"></span>
            <span class="btn-label">{{ 'COMMON.IMAGE' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="file-and-image-upload__row__right">
        <div *ngIf="attachments.image" class="attachment-preview-item">
          <span class="image-item-row">
            <app-file-and-image-display-full
              [name]="attachments.image.fileName"
              [title]="attachments.image.title"
              [url]="attachments.image.url"
            ></app-file-and-image-display-full>
            <span (click)="removeImage($event)" *ngIf="!disabled" class="hol-icon-delete delete"></span>
          </span>
        </div>
      </div>
    </div>
    <div class="file-and-image-upload__row file-and-image-upload__row--center" *ngIf="attachments.image">
      <figure>
        <img [src]="attachments?.image.url" />
      </figure>
    </div>
  </div>

  <!--Note-->
  <div class="file-and-image-upload__col" *ngIf="(!hideNote && disabled && attachments.note !== undefined) || (!hideNote && !disabled)">
    <div class="file-and-image-upload__row">
      <div class="file-and-image-upload__row__left">
        <div class="file-upload-btn-container" [ngClass]="{ 'no-text': hideButtonTitle }">
          <div class="file-upload">
            <app-markdown-editor
              (noteAdded)="onNoteAdded($event)"
              [hideButtonTitle]="true"
              [noteFile]="attachments?.noteFile"
              [attachment]="attachments?.note"
              [context]="context"
              [label]="'COMMON.NOTE' | translate"
              [disabled]="disabled"
            >
            </app-markdown-editor>
          </div>
        </div>
      </div>
      <div class="file-and-image-upload__row__right">
        <div class="preview-note__items">
          <app-file-and-image-display-full
            *ngIf="attachments.note && attachments.noteFile"
            [icon]="''"
            [isNote]="true"
            [name]="attachments.noteFile?.fileName"
            [url]="attachments.noteFile?.url"
          >
          </app-file-and-image-display-full>
        </div>
        <span (click)="removeNote($event)" *ngIf="attachments.noteFile && !disabled" class="hol-icon-delete delete"></span>
      </div>
    </div>
    <div class="file-and-image-upload__row" *ngIf="attachments.note && !hideNotePreview">
      <ngx-md [data]="attachments.note" class="attachment-preview-item"></ngx-md>
    </div>
  </div>
</div>
