import { Component, Input, OnInit } from '@angular/core';
import { HolIndicatorStatusTypeEnum } from '../../../../enums/hol-indicator-status-type.enum';
import { FltFlightService } from '../../../../../flt/services/flt-flight.service';

@Component({
  selector: 'app-indicator-status-item',
  templateUrl: './indicator-status-item.component.html',
  styleUrls: ['./indicator-status-item.component.scss'],
})
export class IndicatorStatusItemComponent implements OnInit {
  @Input() flight;
  @Input() universeRight;

  IndicatorStatusType = HolIndicatorStatusTypeEnum;

  constructor(private flightService: FltFlightService) {}

  ngOnInit() {}

  valueChanged(value: HolIndicatorStatusTypeEnum) {
    this.flightService.setFlightIndicatorStatus(this.flight, this.universeRight.universe, value).catch(error => console.log(error));
  }

  universeToName(key: string) {
    switch (key) {
      case 'occ':
        return 'OCC';
      case 'mcc':
        return 'MCC';
      case 'crew':
        return 'CRW';
      case 'goc':
        return 'GOC';
      case 'ops':
        return 'OPS';
      default:
        return null;
    }
  }
}
