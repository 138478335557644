import { HolObject } from './hol-object';
import { EclCrisis } from '../../ecl/models/ecl-crisis';
import { ErpCrisis } from '../../erp/models/erp-crisis';

export class HolCrisisParameter extends HolObject {
  public crisisParameterId: string;
  public crisisTypeId: string;
  public title: string;
  public description: string;
  public placeholder: string;
  public order: number;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisisParameterId = parseObject.get('crisisParameterId');
    this.crisisTypeId = parseObject.get('crisisTypeId');
    this.title = parseObject.get('title');
    this.description = parseObject.get('description');
    this.placeholder = parseObject.get('placeholder');
    this.order = parseObject.get('order');
  }
}

export class HolCrisisParameterValue extends HolObject {
  public eclCrisis: EclCrisis;
  public erpCrisis: ErpCrisis;
  public crisisParameterId: string;
  public value: string;
  public order: number;
  public isDefault: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (parseObject) {
      // Before 2023-03 (March'23), crisis pointer (ECLCrisis) was stored in crisis attribute
      // Now we have 2 pointers: one for ECL crisis and one for ERP
      // To maintain compatibility with the past, we recover the crisis object if eclCrisis is not found
      const parseEclCrisis = parseObject.get('eclCrisis') || parseObject.get('crisis');
      if (parseEclCrisis) {
        this.eclCrisis = new EclCrisis(parseEclCrisis);
      }
      const parseErpCrisis = parseObject.get('erpCrisis');
      if (parseErpCrisis) {
        this.erpCrisis = new ErpCrisis(parseErpCrisis);
      }
      this.value = parseObject.get('value');
      this.crisisParameterId = parseObject.get('crisisParameterId');
      this.order = parseObject.get('order');
      this.isDefault = parseObject.get('isDefault');
    }
  }
}
