<div
  class="crew-event-item"
  (click)="openProperEventModal($event, event)"
  appFilterColor
  [date]="event.date"
  [isComplete]="event.closeReason !== undefined"
>
  <div class="event-item__title">
    <div class="">
      <span class="icon icon-icon_hotevent hol-error" *ngIf="isHotEvent(event)"></span>
      <span class="icon icon-check hol-done" *ngIf="isClosedEvent(event)"></span>
      <strong>{{ getNbAccepted(event) }}/{{ event.nbPersons }}&nbsp;{{ event.function.code }}</strong>
    </div>
    <div class="text-right">
      <strong class="date">{{ event.date | date: 'dd MMM' }}</strong>
      <span class="flight-number">
        <span class="icon icon-icon_plane"></span><strong>: {{ event.impact }}</strong>
      </span>
    </div>
  </div>
  <div class="event-item__details hol-spaced-line" *ngIf="!onlyHeader">
    <div>Potentiels : {{ event.users.length }}</div>
    <div>
      <span class="crew-event-counter hol-validate full"><span class="icon icon-check"></span>{{ getNbAccepted(event) }}</span>
      <span class="crew-event-counter hol-backtodo full"><span class="icon glyphicon glyphicon-time"></span>{{ getNbWaiting(event) }}</span>
      <span class="crew-event-counter hol-error full"><span class="icon icon-quit"></span>{{ getNbRefused(event) }}</span>
    </div>
  </div>
  <div class="last-info" *ngIf="event.notes.length">
    <strong>{{ nextInfoText }}&nbsp;{{ event.notes[event.notes.length - 1].createdAt | time }}</strong>
  </div>
</div>
