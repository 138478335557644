import * as angular from 'angular';
import './scripts/app.js';
import 'angular-animate';
import 'angular-cookies';
import 'angular-elastic';
import 'angular-js-xlsx';
import 'angular-material';
import 'angular-moment';
import 'angular-promise-extras';
import 'angular-marked'; // Used for CREW in eventModal
import 'angular-raven';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'angular-ui-bootstrap';
import 'brace';
import 'brace/mode/markdown';
import 'hammerjs'; // Used by "ngx-hm-carousel"

import { setAngularJSGlobal, UpgradeModule } from '@angular/upgrade/static';
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { UIRouter, UrlService } from '@uirouter/core';
import { environment } from './environments/environment';
import { APP_MSAL_CONFIG, MsalConfiguration } from './app/msal/hol-msal.model';
import { getMsalConfig } from './app/msal/hol-msal-config';
import { parseNode } from 'ol/xml';

// load non ES6 modules
require('node_modules/angular_page_visibility/dist/page_visibility');

getMsalConfig().then((config: MsalConfiguration) => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([{ provide: APP_MSAL_CONFIG, useValue: config }])
    .bootstrapModule(AppModule, { ngZone: new NgZone({}) })
    .then(platformRef => {
      console.log('Boostraping AngulrJS app...');
      const upgrade = platformRef.injector.get(UpgradeModule) as UpgradeModule;
      upgrade.bootstrap(document.body, ['gestiondecriseApp'], { uiView: true });
      setAngularJSGlobal(angular);

      // get() the UIRouter instance from DI to initialize the router
      const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

      // Instruct UIRouter to listen to URL changes
      function startUIRouter() {
        urlService.listen();
        urlService.sync();
      }

      platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
    })
    .catch(err => console.error(err));
});

function requireAll(r) {
  r.keys().forEach(a => {
    // do not require app.js as it is already included
    if (a !== './app.js') {
      r(a);
    }
  });
}

requireAll(require.context('./scripts/', true, /\.js$/));

// Add .html files to template cache
requireAll(
  require.context(
    '!ngtemplate-loader?module=gestiondecriseApp&relativeTo=/src/!html-loader?minimize=true&caseSensitive=true!./views/',
    true,
    /\.html$/,
  ),
);
requireAll(
  require.context(
    '!ngtemplate-loader?module=gestiondecriseApp&relativeTo=/src/!html-loader?minimize=true&caseSensitive=true!./scripts/',
    true,
    /\.html$/,
  ),
);
