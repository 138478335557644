import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HolVacationChecklist, VACATION_LOG_STATUS } from 'src/app/common/models/hol-vacation.model';
import { VacationService } from 'src/app/common/services/vacation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BaseComponent } from '../../base/base.component';

@Component({
  selector: 'app-checklist-vac',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
})
export class ChecklistComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public index: number;
  @Input()
  public vacationId: string;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public vacationTitle: string;
  @Input()
  isHolder: boolean;

  @Input()
  set checklistInput(value) {
    this._checklist.next(value);
  }

  get checklistInput() {
    return this._checklist.getValue();
  }

  public checklist: HolVacationChecklist;
  public checklistSub: Subscription;
  public checklistTitle: string;
  public countChecklistItemStatus: number;
  private _checklist = new BehaviorSubject<HolVacationChecklist>(undefined);

  constructor(private vacationService: VacationService, private commonStoreManager: CommonStoreManager) {
    super();
  }

  public ngOnInit(): void {
    this.checklistSub = this._checklist.subscribe(result => {
      if (!result) {
        return;
      }
      this.checklist = result;
      this.checklistTitle = this.vacationTitle + ' - Checklist ' + (this.index + 1) + ' - ' + this.checklist.title;
      this.countChecklistItemStatus = this.checklist.checklistItems.filter(item => item.status).length;
      if (this.countChecklistItemStatus === this.checklist.checklistItems.length && !this.checklist.completedAt) {
        this.completeChecklist(true);
      } else if (this.countChecklistItemStatus !== this.checklist.checklistItems.length && this.checklist.completedAt) {
        this.completeChecklist(false);
      }
    });
  }

  public ngOnDestroy(): void {
    this.checklistSub.unsubscribe();
  }

  public completeChecklist(status): void {
    this.vacationService.completedChecklistUpdate(this.checklist, status).then(result => {
      this.vacationService.logChecklist(
        this.mod,
        status ? VACATION_LOG_STATUS.COMPLETED_CHECKLIST : VACATION_LOG_STATUS.UNCOMPLETED_CHECKLIST,
        null,
        this.checklist,
        null,
        this.checklistTitle,
        null,
        this.vacationId
      );
      this.commonStoreManager.updateHolVacation(this.vacationId, this.checklist.objectId, null, { completedAt: result.completedAt });
    });
  }

  public enabledOrDisabledChecklistUpdate(checklist: HolVacationChecklist): void {
    this.vacationService.enabledOrDisabledChecklistUpdate(checklist, !checklist.disabled).then(result => {
      if (!result) {
        return;
      }
      this.commonStoreManager.updateHolVacation(this.vacationId, this.checklist.objectId, null, { disabled: result.disabled });
      this.vacationService.logChecklist(
        this.mod,
        result.disabled ? VACATION_LOG_STATUS.DEACTIVATE_CHECKLIST : VACATION_LOG_STATUS.REACTIVATE_CHECKLIST,
        null,
        this.checklist,
        null,
        this.checklistTitle,
        null,
        this.vacationId
      );
    });
  }
}
