import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'app-chat-channel',
})
export class ChatChannelComponentDirective extends UpgradeComponent {
  @Input() context?: string;
  @Input() channel: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('chatChannelComponent', elementRef, injector);
  }
}
