export interface Weather {
  date: Date;
  temperature: UnitAndValue;
  clouds: UnitAndValue;
  precipitation: UnitAndValue;
  wind: UnitAndValue;
  gust: UnitAndValue;
  humidity: UnitAndValue;
  pressure: UnitAndValue;
  waves: SeaWeather;
  isEndDate: boolean;
}

export interface SeaWeather {
  wavesHeight: UnitAndValue;
  wavesDirection: UnitAndValue;
  wavesPeriod: UnitAndValue;
  wavesWindHeight: UnitAndValue;
  wavesWindDirection: UnitAndValue;
  wavesWindPeriod: UnitAndValue;
}

export class UnitAndValue {
  private static readonly arrows = ['\u2192', '\u2197', '\u2191', '\u2196', '\u2190', '\u2199', '\u2193', '\u2198'];
  private readonly _value: number[];
  constructor(value: number | number[], public unit?: string) {
    this._value = Array.isArray(value) ? value : [value];
  }

  get value(): number {
    if (this._value.length === 1) {
      return this._value[0];
    } else {
      return Math.hypot(...this._value);
    }
  }

  get is2DVector(): boolean {
    return this._value.length === 2;
  }

  get angle(): number {
    if (this.is2DVector) {
      // In atan2, y comes first!!
      const [x, y] = this._value;
      const angle = Math.atan2(y, x) * (180.0 / Math.PI);
      return angle < 0.0 ? angle + 360.0 : angle;
    }
    return undefined;
  }

  get direction(): string {
    if (this.is2DVector) {
      const refAngle = Math.round(this.angle * 2 + 45.0) % 720;
      const index = Math.floor(refAngle / 90);
      return UnitAndValue.arrows[index];
    }
    return undefined;
  }
}
