'use strict';

angular
  .module('gestiondecriseApp')
  .controller('CrewSituationCloseModalCtrl', function ($scope, $mdDialog, situation, CrewSituationsService) {
    $scope.reasonText = undefined;
    $scope.situation = situation;

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.validateClose = function () {
      //if ($scope.situation.objectId && $scope.reasonText) {
      if ($scope.situation.objectId) {
        $scope.situation.isClosed = true;
        if ($scope.reasonText) {
          $scope.situation.closeReasonText = $scope.reasonText;
        }
        CrewSituationsService.updateStatus($scope.situation, true);
        CrewSituationsService.close($scope.situation).then(function (situation) {
          //_.extend(_.find($scope.situations, {objectId: situation.objectId}), situation);
          $scope.$root.$broadcast('crew-situation-updated', situation);
          $mdDialog.hide();
        });
      }
    };
  });
