import { ErpHistoryLog } from './erp-historyLog';
import { ErpCrisisNew } from './erp-crisisNew';
import { ErpCrisisTask } from './erp-crisisTask';
import { ErpCrisisType } from './erp-crisisTypes';
import { ErpCrisisData } from './erp-crisisData';
import { ErpCrisisAnnouncement } from './erp-crisisAnnouncement';
import { ErpCrisisDecision } from './erp-crisisDecision';
import { HolCrisis } from '../../common/models/hol-crisis';

export class ErpCrisis extends HolCrisis {
  news: ErpCrisisNew[];
  tasks: ErpCrisisTask[];
  type: ErpCrisisType;
  // TODO HolCrisis.params (class HolCrisisParameterValue) replaces data, but data attribute has been kept for compatibility with other modules
  data: ErpCrisisData[];
  announcements: ErpCrisisAnnouncement[];
  decisions: ErpCrisisDecision[];
  historyLogs: ErpHistoryLog[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.news = [];
    this.tasks = [];
    this.data = [];
    this.type = undefined;
    this.decisions = [];
    this.historyLogs = [];
  }
}
