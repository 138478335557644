import { Injectable } from '@angular/core';
import { OclLogbookTagService } from '../../../ocl/services/ocl-logbook-tag-service/ocl-logbook-tag.service';

@Injectable({
  providedIn: 'root',
})
export class EclLogbookTagService extends OclLogbookTagService {
  // tslint:disable:variable-name
  protected ParseLogBookTag = Parse.Object.extend('ECLLogBookTag');
  protected ParseLogBook = Parse.Object.extend('ECLLogBook');
  protected ParseTag = Parse.Object.extend('ECLTag');
  // tslint:enable
}
