import { HolObject } from 'src/app/common/models/hol-object';
import { ErpCrisis } from './erp-crisis';

export class ErpCrisisData extends HolObject {
  objectId?: string;
  title: string;
  value: string;
  order: number;
  isDefault: boolean;
  crisisObjectId: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.title = parseObject.get('title');
    this.value = parseObject.get('value');
    this.isDefault = parseObject.get('isDefault');
    this.order = parseObject.get('order');
    const crisis = new ErpCrisis(parseObject.get('crisis'));
    this.crisisObjectId = crisis.objectId;
  }
}
