<table>
  <thead>
    <tr>
      <th></th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_SMS' | translate }}</th>
      <th>{{ moduleConfig.config.translateKey + '.COMMON.BY_MAIL' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="notifyFunction">
      <th>
        {{ 'DASHBOARD.MODAL.NOTIFY_FUNCTION' | translate }}
      </th>
      <td>
        <mat-checkbox
          [disabled]="notifyFunction.disabled"
          [(ngModel)]="notifyFunction.sendBySms"
          (ngModelChange)="callSaveNotifyFunction()"
          [color]="color"
        >
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          [disabled]="notifyFunction.disabled"
          [(ngModel)]="notifyFunction.sendByMail"
          (ngModelChange)="callSaveNotifyFunction()"
          [color]="color"
        >
        </mat-checkbox>
      </td>
    </tr>
    <tr *ngFor="let notif of notifications">
      <th>{{ notif.name }}</th>
      <td>
        <mat-checkbox
          [(ngModel)]="notif.sendBySms"
          (ngModelChange)="callSaveNotifications()"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by SMS"
          [color]="color"
          id="notificationSMS{{ notif.objectId + type }}"
        >
        </mat-checkbox>
      </td>
      <td>
        <mat-checkbox
          [(ngModel)]="notif.sendByMail"
          (ngModelChange)="callSaveNotifications()"
          [disabled]="notif.disabled || notif.readOnly || notif.disabled === undefined"
          aria-label="Send Notifications by MAIL"
          [color]="color"
          id="notificationMail{{ notif.objectId + type }}"
        >
        </mat-checkbox>
      </td>
    </tr>
  </tbody>
</table>
