<div class="checklist-group-by-function">
  <div class="checklist-group-by-function__top">
    <span class="function-title title-card-vacation">
      {{ checklistGroupByFunction.func.title }}
    </span>
    <div class="holders" *ngIf="managerFunction">
      <span class="icon hol-icon-profile"></span>
      <div>
        <div *ngFor="let company of managerFunction.companies">
          <app-acl-indicator [object]="{ companies: [company.name] }"></app-acl-indicator>
          <span *ngIf="company.holder">{{ company.holder.user.fullName }}</span>
          <span *ngIf="!company.holder" class="hol-error">
            {{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.NOBODY' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="checklist-group-by-function__middle">
    <ng-container *ngFor="let checklist of checklistGroupByFunction.events; index as index; trackBy: trackByFunction">
      <app-checklist-by-scenario
        [checklistInput]="checklist"
        [index]="index"
        [isReadOnly]="isReadOnly"
        [mod]="mod"
        [isHolder]="isHolder"
      ></app-checklist-by-scenario>
    </ng-container>
    <span *ngIf="!(checklistGroupByFunction.events.length > 0)">{{
      moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.NO_RESULT_FILTER' | translate
    }}</span>
  </div>
</div>
