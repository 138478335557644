'use strict';

angular.module('gestiondecriseApp').controller('CrewTeamCtrl', function ($scope, CrewTeamService, $mdDialog, $rootScope) {
  $scope.loading = false;
  $scope.functions = [];
  $scope.isReadOnly = false;

  function init() {
    $scope.loading = true;
    CrewTeamService.getAllFunctionsWithUsers($scope.currentFilter)
      .then(function (functions) {
        $scope.functions = functions;
      })
      .finally(function () {
        $scope.loading = false;
      });
  }

  var unregisterWatchFilter = $rootScope.$watch(
    function () {
      return $rootScope.crewEventFilter;
    },
    function () {
      $scope.currentFilter = $rootScope.crewEventFilter;
      init();
    }
  );

  var unregisterSituationEvent = $scope.$on('crew-situation-updated', init);

  var unregisterPoolService = $scope.$on('crewPoolService-functions', function (e, functions) {
    $scope.functions = functions;
  });

  $scope.openUserModal = function ($event, user, event) {
    $mdDialog
      .show({
        controller: 'CrewUserModalCtrl',
        templateUrl: 'views/crew/modals/user.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          user: user,
          event: event,
          situation: null,
          isReadOnly: $scope.isReadOnly,
          isArchivesOpened: false,
        },
      })
      .then(function (retValue) {
        console.log('retValue', retValue);
        if (retValue) {
          init();
        }
      });
  };

  $scope.hasWaitingSituation = function (user) {
    return (
      _.find(user.events, function (event) {
        return event.lastSituation && event.lastSituation.status === 'WAITING';
      }) !== undefined
    );
  };

  $scope.openAddUserModal = function ($event, func) {
    $mdDialog
      .show({
        controller: 'CrewAddUserModalCtrl',
        templateUrl: 'views/crew/modals/addUser.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          func: func,
        },
      })
      .then(function (retValue) {
        if (retValue) {
          init();
        }
      });
  };

  $scope.$on('$destroy', function () {
    unregisterWatchFilter();
    unregisterSituationEvent();
    unregisterPoolService();
  });
});
