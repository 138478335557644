import { OclTagsStoreManager } from './../../ocl/store/tags/ocl-tags.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OpsSmsService } from './ops-sms-service/ops-sms.service';
import { OpsMailService } from './ops-mail-service/ops-mail.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { OpsTaskTagService } from './ops-task-tag.service';
import { OpsHistoryService } from './ops-history-service/ops-history.service';
import { OpsTagsService } from './ops-tags-service/ops-tags.service';
import { OpsModuleConfigService } from './ops-module-config/ops-module-config.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';

@Injectable({
  providedIn: 'root',
})
export class OpsTasksService extends OclTasksService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseTaskTag = Parse.Object.extend('OPSTaskTag');
  // tslint:enabled

  constructor(
    protected requestService: RequestService,
    protected taskTagService: OpsTaskTagService,
    protected historyService: OpsHistoryService,
    protected tagsService: OpsTagsService,
    protected moduleConfig: OpsModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected commonStoreManager: CommonStoreManager,
    protected mailService: OpsMailService,
    protected smsService: OpsSmsService,
    protected notificationsService: NotificationsService,
    protected tagsStoreManager: OclTagsStoreManager,
  ) {
    super(
      requestService,
      historyService,
      tagsService,
      taskTagService,
      moduleConfig,
      tasksStoreManager,
      commonStoreManager,
      mailService,
      smsService,
      notificationsService,
      tagsStoreManager,
    );
  }
}
