import { Injectable } from '@angular/core';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';

@Injectable({
  providedIn: 'root',
})
export class ErpModuleConfigService extends ModuleConfigService {
  public constructor() {
    super();
    this.config.canCreateTags = true;
    this.config.moduleName = 'erp';
    this.config.translateKey = 'ERP';
    this.config.rootPath = 'crisis';
  }
}
