import { Injectable } from '@angular/core';
import { OclLogbookTagService } from '../../../ocl/services/ocl-logbook-tag-service/ocl-logbook-tag.service';

@Injectable({
  providedIn: 'root',
})
export class OpsLogbookTagService extends OclLogbookTagService {
  // tslint:disable:variable-name
  protected ParseLogBookTag = Parse.Object.extend('OPSLogBookTag');
  protected ParseLogBook = Parse.Object.extend('OPSLogBook');
  protected ParseTag = Parse.Object.extend('OPSTag');
  // tslint:enable
}
