import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-crew-events-box',
  templateUrl: './crew-events-box.component.html',
  styleUrls: ['./crew-events-box.component.scss'],
})
export class CrewEventsBoxComponent implements OnInit {
  @Input() currentFilter: any;
  @Input() isReadOnly: boolean;

  eventFilter = '';

  constructor() {}

  ngOnInit() {}
}
