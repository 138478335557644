import { HolFlight } from './../../common/models/hol-flight.model';
import { FltApplicability } from './flt-applicability';
import { HolTag } from '../../common/models/hol-tag';
import { OclLogbook } from '../../ocl/models/ocl-logbook.model';

export class FltLogbook extends OclLogbook {
  applicability: FltApplicability = new FltApplicability();
  isFromFlight?: HolFlight;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    this.applicability = new FltApplicability(parseObject);
    this.isFromFlight = parseObject.get('isFromFlight') && new HolFlight(parseObject.get('isFromFlight'));
  }
}
