import { Injectable } from '@angular/core';
import { FltFlightInstructionService } from '../../../flt/services/flt-flight-instruction.service';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightInstructionService extends FltFlightInstructionService {
  // tslint:disable:variable-name
  protected ParseFlightInstruction = Parse.Object.extend('OPSFlightInstruction');
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseFlightInstructionTag = Parse.Object.extend('OPSFlightInstructionTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  // tslint:enable
}
