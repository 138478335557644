import { Injectable } from '@angular/core';

import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { Dictionary, groupBy } from 'lodash';
import * as moment from 'moment';
import { FilesService } from 'src/app/common/services/files.service';

import { HolContext } from '../../common/models/hol-context.model';
import { ENDED_STATUS, HolFlight } from '../../common/models/hol-flight.model';
import { HolNotification } from '../../common/models/hol-notification.model';
import { HolTag } from '../../common/models/hol-tag';
import { RequestService } from '../../common/services/request.service';
import { FltApplicability } from '../models/flt-applicability';
import { FltDecision } from '../models/flt-decision';
import { FltFlightLogbook } from '../models/flt-flight-logbook';
import { FltLogbook } from '../models/flt-logbook';
import { FltApplicabilityService } from './flt-applicability.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { OccMailService } from '../../occ/services/occ-mail-service/occ-mail.service';
import { OccSmsService } from '../../occ/services/occ-sms-service/occ-sms.service';
import { OclSmsService } from '../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OclMailService } from '../../ocl/services/ocl-mail-service/ocl-mail.service';

@Injectable({
  providedIn: 'root',
})
export abstract class FltFlightLogbookService<T extends FltFlightLogbook = FltFlightLogbook> {
  // tslint:disable:variable-name
  protected abstract ParseFlightLogbook;
  protected abstract ParseFlightLogbookTag;
  protected abstract ParseTag;
  protected abstract ParseDecisionTag;
  protected abstract ParseLogbookTag;
  protected abstract ParseDecision;
  protected abstract ParseLogbook;
  protected ParseFlight = Parse.Object.extend('GOCFlight');

  // tslint:enable

  protected constructor(
    protected readonly filesService: FilesService,
    protected requestService: RequestService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
  ) {}

  public create(flightLogbook: T, notifications?: HolNotification[], applicability?: FltApplicability, context?: HolContext): Promise<T> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);

    let addBreakLinesBefore = false;
    if (flightLogbook.attachments && flightLogbook.attachments.note) {
      addBreakLinesBefore = true;
    }
    const parseFLB = new this.ParseFlightLogbook();
    parseFLB.set('text', flightLogbook.text);
    parseFLB.set('station', flightLogbook.station);
    parseFLB.set('attachments', flightLogbook.attachments);
    parseFLB.set('createdBy', Parse.User.current());
    parseFLB.setACL(flightLogbook.acl);
    if (flightLogbook.flight) {
      parseFLB.set('flight', new this.ParseFlight({ id: flightLogbook.flight.objectId }));
    }
    if (applicability) {
      if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
        parseFLB.set('station', flightLogbook.flight.departure);
      } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
        parseFLB.set('station', flightLogbook.flight.destination);
      }
    }
    this.setAdditionalFields(flightLogbook, parseFLB);
    return this.requestService.performSaveQuery(parseFLB).then(async savedFlightLogbook => {
      const fltLogbookTags = await this.addTags(flightLogbook, savedFlightLogbook);
      const newSavedFlightLogbook = this.newFlightLogbook(savedFlightLogbook, fltLogbookTags);

      if (addressMailToSend.length) {
        this.mailService.sendNewLogbookMail(newSavedFlightLogbook, addressMailToSend);
      }
      if (phoneNumbersToSend.length) {
        this.smsService.sendNewLogbookSMS(newSavedFlightLogbook, phoneNumbersToSend);
      }

      if (applicability && !savedFlightLogbook.decision && !savedFlightLogbook.logbook) {
        return this.saveApplicabilityHistory(newSavedFlightLogbook, context, applicability, addBreakLinesBefore);
      } else {
        return newSavedFlightLogbook;
      }
    });
  }

  public update(flightLogbook: T, applicability?: FltApplicability, context?: HolContext): Promise<T> {
    let parseObject;
    let updateApplicabilityHistory = false;
    if (flightLogbook.logbook) {
      parseObject = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
      parseObject.setACL(flightLogbook.acl);
      parseObject.unset('attachments');
    } else if (flightLogbook.decision) {
      parseObject = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
      parseObject.setACL(flightLogbook.acl);
      parseObject.unset('attachments');
    } else {
      parseObject = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
      parseObject.set('attachments', flightLogbook.attachments);
      parseObject.setACL(flightLogbook.acl);
      if (applicability) {
        if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
          parseObject.set('station', flightLogbook.flight.departure);
          updateApplicabilityHistory = flightLogbook.station !== flightLogbook.flight.departure ? true : false;
        } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
          parseObject.set('station', flightLogbook.flight.destination);
          updateApplicabilityHistory = flightLogbook.station !== flightLogbook.flight.destination ? true : false;
        } else {
          parseObject.unset('station');
          updateApplicabilityHistory = flightLogbook.station !== undefined ? true : false;
        }
      }
    }
    this.setAdditionalFields(flightLogbook, parseObject);
    return this.requestService.performSaveQuery(parseObject).then(async savedFlightLogbook => {
      if (flightLogbook.logbook) {
        // await this.removeLogbookTags(flightLogbook.logbook);
        // await this.addLogbookTags(flightLogbook);
      } else if (flightLogbook.decision) {
        // await this.removeDecisionTags(flightLogbook.decision);
        // await this.addDecisionTags(flightLogbook);
      } else {
        // remove old tags
        await this.removeTags(parseObject);
        // add new tags
        await this.addTags(flightLogbook, savedFlightLogbook);
        // update applicability in note
        if (applicability && updateApplicabilityHistory) {
          return this.saveApplicabilityHistory(flightLogbook, context, applicability);
        }
      }

      return flightLogbook;
    });
  }

  public getForFlights(flights: HolFlight[], isPrivate?: boolean): Promise<Dictionary<T[]>> {
    const parseFlights = flights.map(f => new this.ParseFlight({ id: f.objectId }));
    const query = new Parse.Query(this.ParseFlightLogbook);
    query.containedIn('flight', parseFlights);
    query.include('logbook');
    query.include('decision');
    query.containedIn('flight', parseFlights);
    query.notEqualTo('archived', true);
    query.addDescending('createdAt');
    if (isPrivate) {
      query.notEqualTo('isPrivate', true);
    }
    query.include('occLogbook');
    query.include('occDecision');
    return this.requestService.performFindQuery(query).then(parseFlightLogbooks => {
      const parseDecisions = parseFlightLogbooks.filter(pi => pi.get('decision')).map(pi => pi.get('decision'));
      const parseLogbooks = parseFlightLogbooks.filter(pi => pi.get('logbook')).map(pi => pi.get('logbook'));

      const flightLogbookTagsQuery = new Parse.Query(this.ParseFlightLogbookTag);
      flightLogbookTagsQuery.containedIn('flightLogbook', parseFlightLogbooks);
      flightLogbookTagsQuery.include('tag');
      flightLogbookTagsQuery.descending('createdAt');

      const logbookTagsQuery = new Parse.Query(this.ParseLogbookTag);
      logbookTagsQuery.containedIn('logbook', parseLogbooks);
      logbookTagsQuery.include('tag');
      logbookTagsQuery.descending('createdAt');
      const decisionTagsQuery = new Parse.Query(this.ParseDecisionTag);
      decisionTagsQuery.containedIn('decision', parseDecisions);
      decisionTagsQuery.include('tag');
      decisionTagsQuery.descending('createdAt');

      return Promise.all([
        this.requestService.performFindQuery(flightLogbookTagsQuery),
        this.requestService.performFindQuery(logbookTagsQuery),
        this.requestService.performFindQuery(decisionTagsQuery),
      ]).then(([flts, lts, dts]) => {
        const logbooks = parseFlightLogbooks.map(log => this.newFlightLogbook(log));
        logbooks.forEach(logbook => {
          if (logbook.decision) {
            logbook.tags = dts.filter(dt => dt.get('decision').id === logbook.decision.objectId).map(dt => new HolTag(dt.get('tag')));
          } else if (logbook.logbook) {
            logbook.tags = lts.filter(lt => lt.get('logbook').id === logbook.logbook.objectId).map(lt => new HolTag(lt.get('tag')));
          } else {
            logbook.tags = flts.filter(flt => flt.get('flightLogbook').id === logbook.objectId).map(flt => new HolTag(flt.get('tag')));
          }
        });
        return groupBy(logbooks, l => l.flight.objectId);
      });
    });
  }

  public archive(flightLogbook: T): Promise<T> {
    const parseInstruction = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
    parseInstruction.set('archived', true);
    parseInstruction.set('archivedDate', new Date());
    return this.requestService.performSaveQuery(parseInstruction).then(async savedLogbook => {
      return this.newFlightLogbook(savedLogbook, savedLogbook.tags);
    });
  }

  public updateFlightLogBookLogbook(flightLogbook: T, logbook: FltLogbook): Promise<T> {
    const parseFlightLogbook = new this.ParseFlightLogbook({ id: flightLogbook.objectId });
    parseFlightLogbook.set('logbook', new this.ParseLogbook({ id: logbook.objectId }));
    parseFlightLogbook.unset('attachments');
    return this.requestService.performSaveQuery(parseFlightLogbook).then(async updatedFlightLogbook => {
      const fltLogbookTags = await this.addTags(flightLogbook, updatedFlightLogbook);
      const newSavedFlightLogbook = this.newFlightLogbook(updatedFlightLogbook, fltLogbookTags);
      return newSavedFlightLogbook;
    });
  }

  public async deleteFltLogbookFromCancelAppl(parentId) {
    const queryFlight = new Parse.Query(this.ParseFlight);
    queryFlight.greaterThanOrEqualTo('std', moment.utc().toDate());
    const query = Parse.Query.or(
      new Parse.Query(this.ParseFlightLogbook).equalTo('decision', new this.ParseDecision({ id: parentId })),
      new Parse.Query(this.ParseFlightLogbook).equalTo('logbook', new this.ParseLogbook({ id: parentId })),
    );
    query.matchesQuery('flight', queryFlight);
    const logbooksToDelete = await this.requestService.performFindAllQuery(query);
    return await this.requestService.performDestroyAllQuery(logbooksToDelete);
  }

  // private async addLogbookTags(flightLogbook: T) {
  //   if (flightLogbook.tags) {
  //     const parseTags = flightLogbook.tags.map(tag => {
  //       return new this.ParseLogbookTag({
  //         logbook: new this.ParseLogbook({ id: flightLogbook.logbook.objectId }),
  //         tag: new this.ParseTag({ id: tag && tag.objectId }),
  //       });
  //     });
  //     await this.requestService.performSaveAllQuery(parseTags);
  //   }
  // }

  // private async removeLogbookTags(logbook: FltLogbook) {
  //   const oldTagQuery = new Parse.Query(this.ParseLogbookTag);
  //   oldTagQuery.equalTo('logbook', new this.ParseLogbook({ id: logbook.objectId }));
  //   const oldTags = await this.requestService.performFindQuery(oldTagQuery);
  //   await this.requestService.performDestroyAllQuery(oldTags);
  // }

  // private async addDecisionTags(flightLogbook: T) {
  //   if (flightLogbook.tags) {
  //     const parseTags = flightLogbook.tags.map(tag => {
  //       return new this.ParseDecisionTag({
  //         decision: new this.ParseDecision({ id: flightLogbook.decision.objectId }),
  //         tag: new this.ParseTag({ id: tag && tag.objectId }),
  //       });
  //     });
  //     await this.requestService.performSaveAllQuery(parseTags);
  //   }
  // }

  // private async removeDecisionTags(decision: FltDecision) {
  //   const oldTagQuery = new Parse.Query(this.ParseDecisionTag);
  //   oldTagQuery.equalTo('decision', new this.ParseDecision({ id: decision.objectId }));
  //   const oldTags = await this.requestService.performFindQuery(oldTagQuery);
  //   await this.requestService.performDestroyAllQuery(oldTags);
  // }

  getAllFromLogbook(logbook: FltLogbook): Promise<Parse.Object[]> {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const parseLogbook = new this.ParseLogbook({ id: logbook.objectId });
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.notContainedIn('status', ENDED_STATUS);
    flightQuery.greaterThan('std', yesterday);
    const flightLogbookQuery = new Parse.Query(this.ParseFlightLogbook);
    flightLogbookQuery.equalTo('logbook', parseLogbook);
    flightLogbookQuery.notEqualTo('archived', true);
    flightLogbookQuery.include('flight');
    flightLogbookQuery.matchesQuery('flight', flightQuery);

    return this.requestService.performFindAllQuery(flightLogbookQuery);
  }

  getAllFromDecision(decision: FltDecision): Promise<Parse.Object[]> {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.greaterThan('std', yesterday);
    flightQuery.notContainedIn('status', ENDED_STATUS);
    const flightLogbookQuery = new Parse.Query(this.ParseFlightLogbook);
    flightLogbookQuery.equalTo('decision', parseDecision);
    flightLogbookQuery.notEqualTo('archived', true);
    flightLogbookQuery.include('flight');
    flightLogbookQuery.matchesQuery('flight', flightQuery);
    return this.requestService.performFindAllQuery(flightLogbookQuery);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected setAdditionalFields(flightLogbook: T, parseFlightLogbook: Parse.Object) {}

  protected newFlightLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltFlightLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  private async addTags(flightLogbook: T, savedFlightLogbook): Promise<Parse.Object[]> {
    if (flightLogbook.tags) {
      const parseTags = flightLogbook.tags.map(tag => {
        return new this.ParseFlightLogbookTag({
          flightLogbook: savedFlightLogbook,
          tag: new this.ParseTag({ id: tag && tag.objectId }),
        });
      });
      return this.requestService.performSaveAllQuery(parseTags);
    }
  }

  private async removeTags(parseFLB) {
    const oldTagQuery = new Parse.Query(this.ParseFlightLogbookTag);
    oldTagQuery.equalTo('flightLogbook', parseFLB);
    const oldTags = await this.requestService.performFindQuery(oldTagQuery);
    await this.requestService.performDestroyAllQuery(oldTags);
  }

  private saveApplicabilityHistory(
    savedFlightLogbook: FltFlightLogbook,
    context: HolContext,
    applicability: FltApplicability,
    addMdBreakLinesBefore = false,
  ) {
    return this.applicabilityService
      .applicabilityHistoryInNotes(savedFlightLogbook, context, addMdBreakLinesBefore, applicability)
      .then(attachments => {
        const parseFlightLogbook = new this.ParseFlightLogbook();
        parseFlightLogbook.id = savedFlightLogbook.objectId;
        parseFlightLogbook.set('attachments', attachments);
        return this.requestService.performSaveQuery(parseFlightLogbook).then(async fl => {
          const newSavedFlightLogbook = this.newFlightLogbook(fl);
          newSavedFlightLogbook.tags = savedFlightLogbook.tags;
          return newSavedFlightLogbook;
        });
      });
  }
}
