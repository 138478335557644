'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewHistoryService
 * @description
 * # CrewHistoryService
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('CrewHistoryService', function (
    $q,
    RequestService,
    ParseMapper,
    $rootScope,
    CrewOptionsService,
    CrewSituationsService,
    CrewEventsService,
    CrewLogsService
  ) {
    var that = this;

    this.getLogsByTimeSlots = function (forceToRefresh, fromDate) {
      var deferred = $q.defer();

      var timeSlotMinutes = CrewOptionsService.getHistoryTimeSlot() || 60;
      var startDate = moment(fromDate);
      startDate.seconds(0);
      if (startDate.get('minutes') % timeSlotMinutes !== 0) {
        // Set now to a multiple of 'timeSlotMinutes' eg: 10h07 -> 10h30 with timeSlotMinutes = 30
        startDate.add(timeSlotMinutes - (startDate.get('minutes') % timeSlotMinutes), 'minutes');
      }
      var endDate = startDate.clone().subtract(1, 'day');

      CrewLogsService.getAllLogs(startDate.toDate(), endDate.toDate()).then(function (logs) {
        var timeSlots = [];
        var timeSlotDateStart = startDate;
        while (timeSlotDateStart.diff(endDate, 'minutes') > 0) {
          var timeSlotDateEnd = timeSlotDateStart.clone().subtract(timeSlotMinutes, 'minutes');
          timeSlots.push({
            timeStart: timeSlotDateStart,
            timeEnd: timeSlotDateEnd,
            logs: _.filter(logs, function (log) {
              return moment(log.createdAt).isBetween(timeSlotDateEnd, timeSlotDateStart, null, '(]');
            }),
          });
          timeSlotDateStart = timeSlotDateEnd;
        }
        deferred.resolve(timeSlots);
      });

      return deferred.promise;
    };

    this.getCalendarDay = function (forceToRefresh, fromDate) {
      var deferred = $q.defer();

      var startDate = moment(fromDate).startOf('day');
      var endDate = startDate.clone().endOf('day');
      var dateLimits = {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      };
      $q.all([
        CrewSituationsService.getAllTodos(null, dateLimits),
        CrewEventsService.getAll(null, dateLimits),
        CrewSituationsService.getAllWithImpactDate(dateLimits),
      ]).then(function (results) {
        var todos = results[0];
        var events = results[1];
        var impacts = results[2];

        deferred.resolve({
          todos: todos,
          events: events,
          impacts: impacts,
          startDate: dateLimits.startDate,
        });
      }, deferred.reject);

      return deferred.promise;
    };

    this.fetchNewData = function () {
      return $q.all([
        that.getLogsByTimeSlots(true).then(function (logs) {
          $rootScope.$broadcast('crewPoolService-historyLogs', logs);
        }),

        that.getCalendarDay(true).then(function (logs) {
          $rootScope.$broadcast('crewPoolService-historyCalendar', logs);
        }),
      ]);
    };
  });
